/* eslint-disable */
import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import { useHistory } from "react-router-dom"
import styles from "./Chat.module.scss"
import {
  Chat_MessagesDocument,
  Chat_MessagesQuery,
  Message as MessageType,
  Message_MessageFragment,
  useAppSpaces_CreateAppSpaceMutation,
  useChat_ChatMutation,
  useChat_MessagesLazyQuery,
  useChat_OnMessageReceivedSubscription,
  useChat_OnMessageUpdatedSubscription,
  useChat_ReportReadActionMutation,
  useChat_ReportTypeActionMutation,
  useChatQuery,
  useSessions_CreateSessionMutation,
  useSessions_SelectSessionMutation,
  useSidebarChannelsQuery,
  useSessionTabsQuery
} from "generated/graphql"
import Message from "../message"
import Searchbar from "modules/shared/components/searchbar"
import { useApolloClient } from "@apollo/client"
import UserStatus from "../userChatStatus"
import { animateScroll } from "react-scroll"
import cn from "clsx"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import spinner from "modules/shared/styles/components/spinner.module.scss"
import ChatContext from "./ChatContext"
import { formatRelativeDate, handleGraphQlError, humanizeFileSize, isEnter, parseQueryString, uuid } from "utils/common"
import { topics } from "consts"
import pubsub from "pubsub-js"
import { DateTime } from "luxon"
import _ from "lodash"
import ChatHeading from "../chatHeading"
import { useManageQueryParams } from "./hooks"
import { isMobile } from "react-device-detect"
import PretzelTextEditor from "modules/shared/components/pretzelTextEditor"
import { useUploadImageIntoChat } from "./hooks/useUploadImageIntoChat"
import { useInputValue, useNonInitialEffect } from "utils/hooks"
import JumpMenu from "modules/shared/components/jumpMenu"
import SplitterContext from "modules/shared/components/splitterContext"
import SuspensePreloader from "modules/shared/components/suspensePreloader"
import { scheduleMessageDelete } from "modules/chat/components/message/components/messageOptions/MessageOptions"
import { currentTab, viewTabs } from "modules/app/components/sessionTabs/SessionTabs"
import LoadingFileListing from "../fileListing/components/loadingFileListing/LoadingFileListing"
import { changeUrlWithoutRerender } from "utils/common"
import { asyncListing } from "../message/Message"

export interface MessageGroup {
  date: Date
  messages: MessageType[]
  start: boolean
}
let logOnce = false

let preloadChannels = {} as any
if (typeof Storage !== "undefined") {
  // Prevent preload cache from growing too large
  if (localStorage.PRELOAD_CHANNELS) {
    if (Object.keys(localStorage.PRELOAD_CHANNELS).length > 30) {
      localStorage.PRELOAD_CHANNELS = "{}"
    } else preloadChannels = JSON.parse(localStorage.PRELOAD_CHANNELS)
  }
}

function useFilter(array: any[], decimate: (x: any) => any): [any[], (a: any) => void] {
  let d: any[] = []

  const filteredArray = array.filter((x) => !d.includes(decimate(x)))

  function updateFilter(addDel: any): void {
    d = [...d, addDel]
  }

  return [filteredArray, updateFilter]
}

const ChatGroupHeader: React.FC<{ date: Date; afterStart: boolean | undefined }> = ({ date, afterStart }) => {
  return (
    <>
      <div className={styles.chatGroupHeader}>
        <div className={styles.hrS} />
        <div className={styles.moment}>
          {new Date(date).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)
            ? afterStart
              ? "Start of the Conversation"
              : "Today"
            : formatRelativeDate(new Date(date))}
        </div>
        <div className={styles.hrL} />
      </div>
    </>
  )
}

export let doNotCall = false
export const setDoNotCall = (value: boolean) => (doNotCall = value)
let localDelete: (a: any) => void

const Chat: React.FC<{ urgent?: boolean }> = ({ urgent }) => {
  const history = useHistory()
  const apolloClient = useApolloClient()
  const [preDeleteMessageId, setPreDeleteMessageId] = useState("")
  const [currentMessageInFocus, setCurrentMessageInFocus] = useState("")
  const cannotLoadMore = useRef<boolean>(false)
  const firstLoad = useRef("pending")
  const [fileListingSession, setFileListingSession] = useState(false)

  const chatThread = useRef<HTMLDivElement>(null)
  const imagePasteFilename = useRef<HTMLInputElement>(null)

  const [searchText, setSearchText] = useInputValue()
  const [useRichText, setUseRichText] = useState(false)

  const { data: channels } = useSidebarChannelsQuery({
    onError: handleGraphQlError("Unable to get message")
  })

  const [callChat] = useChat_ChatMutation({
    onError: handleGraphQlError("Unable to send the message")
  })
  const [getMessages, chatMessagesQuery] = useChat_MessagesLazyQuery({
    onError: handleGraphQlError("Unable to get messages"),
    variables: {
      offset: 0
    }
  })

  const chatQuery = useChatQuery({
    variables: {},
    onError: handleGraphQlError("Unable to get chat history")
  })
  const [sendTypingStatus] = useChat_ReportTypeActionMutation({
    onError: handleGraphQlError("Unable to send typing status")
  })

  const [sendReadAction] = useChat_ReportReadActionMutation({
    onError: handleGraphQlError("Unable to send read status")
  })

  const [selectSession] = useSessions_SelectSessionMutation({
    onError: handleGraphQlError("Unable to select session")
  })

  const [createSession] = useSessions_CreateSessionMutation({
    onError: handleGraphQlError("Unable to create session")
  })

  // Deprecated, was too slow
  setTimeout(() => {
    let send = localStorage.initFileListing
    if (window.location.href.includes("?q") && currentTab?.groupId === null) {
      changeUrlWithoutRerender("/chat")
    }
    if (doNotCall === false && send.trim().length > 0 && currentTab?.groupId === null) {
      localStorage.initFileListing = ""
      initFiles(send)
    }
  }, 50)

  async function sendChat(
    message: string,
    silent?: boolean,
    type?: string,
    richtext?: boolean,
    attachments?: {
      id: string
      name: string
      size: number
      type: string
    }[],
    skipOptimistic = false
  ) {
    if (message.trim().toLowerCase() === "play chess") {
      createAppSpace({
        variables: {
          type: "chess",
          name: "Chess game"
        }
      })
    } else {
      callChat({
        variables: { message, silent, type, richtext, attachments },
        update: (_: any, { data }) => {
          if (data?.chat) {
            handleNewMessage(data?.chat)
          }
        },
        optimisticResponse: skipOptimistic
          ? undefined
          : {
              chat: {
                type: "html",
                textContent: message,
                editedAt: null,
                time: new Date(),
                isUnread: false,
                __typename: "Message",
                reactions: [],
                createdDate: new Date(),
                id: uuid(),
                obsolete: false,
                embed: null,
                fileListing: null,
                attachments: [],
                links: null,
                pdfs: null,
                searchResult: null,
                searchTerm: null,
                user: data?.me as any
              }
            }
      })
    }
  }

  function handleNewMessage(newMessage?: Message_MessageFragment | undefined) {
    if (!newMessage) {
      return
    }

    const currentData = apolloClient.readQuery({
      query: Chat_MessagesDocument
    })

    if (!currentData) {
      return
    }

    apolloClient.writeQuery({
      query: Chat_MessagesDocument,
      variables: { offset: -1 },
      data: {
        ...currentData,
        activeSession: {
          ...currentData.activeSession,
          chatHistory: [newMessage]
        }
      }
    })

    const existing = apolloClient.readQuery({
      query: Chat_MessagesDocument,
      variables: { offset: -1 }
    }) as Chat_MessagesQuery

    // obsolete old messages
    existing.activeSession.chatHistory
      .filter((c) => !c.obsolete && c.id !== newMessage.id)
      .forEach((message) => {
        if (
          message.type === newMessage.type &&
          ["fileListing", "searchResult", "images", "news", "videos", "bookmarks"].includes(message.type)
        ) {
          apolloClient.cache.modify({
            id: apolloClient.cache.identify(message),
            fields: {
              obsolete: () => true
            }
          })
        }
      })
  }

  const [loadMoreStatus, setLoadMoreStatus] = useState<"loading" | null>(null)
  const [fileListingExists, setFileListingExists] = useState(false)
  const [extracted, setExtracted] = useState("")

  const { data: backendTabs } = useSessionTabsQuery({})

  // Update the chat history when a new chat item arrived
  const { data: messageReceivedSubscriptionData } = useChat_OnMessageReceivedSubscription({
    variables: {
      session: chatQuery.data?.activeSession.id || ""
    },
    skip: !chatQuery.data?.activeSession.id
  })

  const { data: messageUpdatedSubscriptionData } = useChat_OnMessageUpdatedSubscription({
    variables: {
      session: chatQuery.data?.activeSession.id || ""
    },
    skip: !chatQuery.data?.activeSession.id
  })

  function initFiles(query: string) {
    if (doNotCall === false) {
      doNotCall = true // prevent double call
      callChat({
        variables: {
          message: query,
          silent: false,
          type: undefined,
          richtext: false,
          attachments: []
        }
      }).then(() => (doNotCall = true))
    }
  }

  useEffect(() => {
    const newMessage = messageReceivedSubscriptionData?.messageReceived
    handleNewMessage(newMessage)
  }, [messageReceivedSubscriptionData?.messageReceived?.id])
  const { data } = chatQuery
  const { data: messages, fetchMore, refetch: refetchChatMessages } = chatMessagesQuery
  useEffect(() => {
    refetchChatMessages && refetchChatMessages()
  }, [messageUpdatedSubscriptionData?.messageUpdated?.id])

  useEffect(() => {
    if (firstLoad.current === "pending") {
      getMessages({
        variables: {
          offset: 0
        }
      })
      firstLoad.current = "loading"
    }
  }, [getMessages])

  useEffect(() => {
    if (!currentTab?.groupId) {
      return
    }
    const unreadItems = _.uniqBy(
      messages?.activeSession.chatHistory.filter((m: any) => m.isUnread) || [],
      (m: any) => m.id
    )
    if (!unreadItems.length) {
      return
    }

    const groupId = currentTab?.groupId || ""
    sendReadAction({
      variables: {
        group: groupId,
        values: unreadItems.map((m: any) => m.id)
      },
      optimisticResponse: {
        reportUserAction: groupId
          ? {
              id: groupId,
              unreadCount: 0
            }
          : null
      },
      update: () => {
        unreadItems.forEach((message) => {
          apolloClient.cache.modify({
            id: apolloClient.cache.identify(message),
            fields: {
              isUnread: () => false
            }
          })
        })
      }
    })
  }, [messages])

  const queryProcessed = useRef<boolean>(false)
  useEffect(() => {
    if (queryProcessed.current || !data) {
      return
    }
    const { query, type } = parseQueryString(history.location.search) as {
      query: string
      type?: string
    }

    if (query && data) {
      history.replace({ search: "" })
      sendChat(query, false, type, undefined, undefined, true)
    }

    const token = pubsub.subscribe(
      topics.SIDEBAR_ACTION,
      async (_: string, d: { message: string; type?: string; silent?: boolean }) => {
        if (data) {
          if (currentTab?.groupId) {
            const nongroups = data.sessions.filter((x: any) => !x.session.groupId)

            if (nongroups.length) {
              await selectSession({
                variables: { id: nongroups[0].session.id }
              })
            } else {
              await createSession({})
            }
          }
        }

        if (d.message) {
          sendChat(d.message, d.silent || false, type)
        }
      }
    )

    queryProcessed.current = true

    return () => {
      pubsub.unsubscribe(token)
    }
  }, [queryProcessed, data])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const groupChat = (messages: any[]): MessageGroup[] => {
    if (messages.length > 0) {
      const dict = _.groupBy(Array.from(messages).reverse(), (m: Message_MessageFragment) => {
        const date = DateTime.fromJSDate(new Date(m.createdDate))
        return date.toISO().substring(0, 10)
      })
      return Object.keys(dict).map((key) => ({
        date: new Date(key),
        messages: _.orderBy(dict[key], ["asc", "createdDate"]),
        start: false
      }))
    }

    return []
  }

  const headlessType = (type: string) => {
    if (type === "attachments") {
      return "attachments:headless"
    }

    return type === "html" ? "html:headless" : "text:headless"
  }

  const mergeTextMessages = (groups: MessageGroup[]): MessageGroup[] => {
    return groups.map((group) => {
      const messages: MessageType[] = []

      group.messages.forEach((msg, i) => {
        if (i > 0) {
          const prev = group.messages[i - 1]
          const diff =
            DateTime.fromJSDate(new Date(msg.createdDate))
              .diff(DateTime.fromJSDate(new Date(prev.createdDate)), "seconds")
              .toObject().seconds || 0

          if (
            prev.user?.id === msg.user?.id &&
            ((prev.type.startsWith("text") && msg.type.startsWith("text")) ||
              msg.type === "attachments" ||
              (prev.type === "html" && msg.type === "html")) &&
            diff < 60
          ) {
            messages.push({
              ...msg,
              type: headlessType(msg.type)
            })
            return
          }
        }

        messages.push(msg)
      })
      group.messages = messages
      return group
    })
  }

  const ready = () => {
    if (urgent) return true
    if (!backendTabs || !messages || !currentTab) return false

    // Handle BACKEND * OR * CLIENT Chat (FIRST)
    if (currentTab?.groupId) {
      const locate = backendTabs.sessions.find((item: any) => item.session.groupId === currentTab?.groupId)
      if (
        locate?.session?.id === messages?.activeSession.id ||
        messages?.activeSession.id === backendTabs?.activeSession.id
      )
        return true
      else {
        console.warn("Render was blocked")
        return false
      }
    }

    // Client Pretzel session
    else if (currentTab?.type && messages?.activeSession.id === currentTab?.type?.split(":")[1]) return true
    // Backend Pretzel session (But capable of handing any BACKEND channel/DM)
    else if (messages?.activeSession.id === currentTab?.id) return true
    // Content not ready
    else return false
  }

  const useGroupOrSession = (c1: string, c2: string) => {
    if (c1 !== null) return c1
    if (c2 !== null) return c2
    return ""
  }

  /**
   * Always update the channel content for this session ID */
  if (ready() && messages?.activeSession.chatHistory) {
    if (useGroupOrSession(currentTab?.groupId, currentTab?.id) !== null) {
      preloadChannels[useGroupOrSession(currentTab?.groupId, currentTab?.id)] = messages?.activeSession.chatHistory
    }
  }
  /**
   * IS preloaded? : Use it, otherwise FALLBACK
   */
  const [, l] = useFilter(
    preloadChannels[useGroupOrSession(currentTab?.groupId, currentTab?.id)] ||
      messages?.activeSession.chatHistory ||
      [],
    (x) => x.id
  )
  localDelete = l

  const chatHistory = mergeTextMessages(
    groupChat(_.uniqBy(preloadChannels[useGroupOrSession(currentTab?.groupId, currentTab?.id)], "id") || [])
  )

  if (preloadChannels) localStorage.PRELOAD_CHANNELS = JSON.stringify(preloadChannels)

  const focusMessage = useManageQueryParams()

  useNonInitialEffect(() => {
    if (refetchChatMessages) {
      refetchChatMessages()
    }
  }, [data?.activeSession.id, refetchChatMessages])

  const uploadingImage = useUploadImageIntoChat()

  const [createAppSpace] = useAppSpaces_CreateAppSpaceMutation({
    onError: handleGraphQlError("Unable to create app space")
  })

  // if (!messages || !data) return null
  const { sessionLoadingState } = useContext(SplitterContext)

  const onExtractFromMessage = (message: string) => {
    setExtracted(message)
  }

  const handleSearch = (
    message: string,
    type?: string,
    silent?: boolean,
    richtext?: boolean,
    attachments?: {
      id: string
      name: string
      size: number
      type: string
    }[]
  ) => {
    setUseRichText(false)
    sendChat(message, silent, type, richtext, attachments)
  }

  const loadMore = async () => {
    if (data && !cannotLoadMore.current && !loadMoreStatus && fetchMore) {
      const previousScroll = chatThread.current?.scrollTop || 0
      const ofst = messages?.activeSession.chatHistory.length || 0
      setLoadMoreStatus("loading")

      try {
        const result = await fetchMore({
          variables: {
            offset: ofst
          }
        })

        if (ofst && !result.data.activeSession.chatHistory.length) {
          cannotLoadMore.current = true
        }
      } finally {
        setLoadMoreStatus(null)
      }

      if (!cannotLoadMore.current) {
        if (chatThread.current) {
          chatThread.current.scrollTop = previousScroll
        }
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onScrollHandle = (ev: any) => {
    const top = ev.target.scrollTop - ev.target.offsetHeight
    if (Math.abs(top + ev.target.scrollHeight) < 10) {
      loadMore()
    }
  }

  const deleteMessageLocal = (id: string) => {
    localDelete(id)
  }

  const completeLoadingCallback = (id: string) => {
    if (id !== currentMessageInFocus) {
      // scroll to the top of the last message
      const extraOffset = 0
      const chatThread = document.querySelector("#chatThread")
      const messages = document.querySelectorAll(".messageContainer")

      if (messages.length > 0) {
        const lastMessageHeight = messages[messages.length - 1].clientHeight
        const chatHeight = chatThread?.clientHeight || 1000
        const to = (chatHeight > lastMessageHeight ? 0 : chatHeight - lastMessageHeight) - extraOffset
        // to from top
        animateScroll.scrollTo(to, {
          containerId: "chatThread",
          duration: 250
        })
      }

      setCurrentMessageInFocus(id)
    }
  }

  const setFileSearchQuery = (q: string) => {
    pubsub.publish(topics.UPDATE_FILES_QUERY, q)
  }

  function handleTyping(typing: boolean) {
    if (currentTab?.groupId) {
      sendTypingStatus({
        variables: {
          group: currentTab?.groupId,
          values: typing ? ["typing"] : []
        }
      })
    }
  }

  if ("fileListing" in asyncListing && !logOnce) {
    logOnce = true
    console.log(
      "%c Successfully preloaded files asynchronously",
      "color: black; background: lime; font-size: 18px; font-weight: 500"
    )
    console.log(asyncListing)
  }

  // CONSOLE STATUS ONLY

  // if (preloadChannels[useGroupOrSession(currentTab?.groupId, currentTab?.id)]) {
  //   console.log(
  //     "%c Accessing PRELOADED CHANNEL HISTORY: " + useGroupOrSession(currentTab?.groupId, currentTab?.id),
  //     "background: lime; color: black"
  //   )
  // } else
  //   console.log(
  //     "%c Accessing QUERY CHANNEL HISTORY: " + useGroupOrSession(currentTab?.groupId, currentTab?.id),
  //     "background: orange; color: black"
  //   )

  return (
    <ChatContext.Provider
      value={{
        deleteMessageLocal,
        preDeleteMessageId,
        setPreDeleteMessageId,
        completeLoadingCallback,
        setFileSearchQuery,
        fileListingExists,
        setFileListingExists,
        userId: data?.me?.id,
        isGroup: !!currentTab?.groupId
      }}
    >
      <DndProvider backend={HTML5Backend}>
        <div className={styles.superRoot}>
          {ready() && (
            <>
              {!fileListingSession && <ChatHeading userId={data?.me?.id} sessionId={data?.activeSession.id || ""} />}
              <div className={styles.root}>
                <div className={cn(styles.root, isMobile ? styles.mobile : null, styles.hideScrollbar)}>
                  <div
                    className={cn([styles.chatLines, currentTab?.groupId ? styles.group : null])}
                    id="chatThread"
                    ref={chatThread}
                    onScroll={onScrollHandle}
                  >
                    {loadMoreStatus === "loading" && (
                      <div className={styles.centeredMoreLoader}>
                        <div className={cn(spinner.spinner, spinner.small)} />
                        <span>Loading more messages...</span>
                      </div>
                    )}
                    {chatHistory.reverse().map((group, i) => {
                      return group?.start ? null : (
                        <div className={styles.group} key={i}>
                          {currentTab?.groupId && (
                            <ChatGroupHeader key={i} date={group?.date} afterStart={chatHistory[i - 1]?.start} />
                          )}
                          {group?.messages.map((msg, j) => {
                            const last = i === 0 && j === group.messages.length - 1
                            /**
                             * REMOVE FILE LISTING MESSAGE FROM CHANNELS
                             */
                            if (
                              (msg.user?.fullName === "Pretzel" && msg.textContent === "view file") ||
                              (currentTab?.groupId &&
                                !document.title.includes("|") &&
                                msg.textContent?.toLocaleLowerCase() === "files")
                            )
                              return <></>
                            return (
                              <div
                                className={cn(["messageContainer", styles.messageContainer])}
                                key={msg.id}
                                style={{
                                  opacity: msg.id === preDeleteMessageId ? 0.5 : 1,
                                  display:
                                    scheduleMessageDelete.includes(msg.id) ||
                                    ["searchResult", "fileListing"].includes(msg.type)
                                      ? "none"
                                      : "block"
                                }}
                              >
                                <Message
                                  message={msg}
                                  scrollIntoView={last || focusMessage === msg.id}
                                  isFirst={j === 0}
                                  isLast={last}
                                  setFileListingSession={setFileListingSession}
                                  onExtract={onExtractFromMessage}
                                />
                              </div>
                            )
                          })}
                        </div>
                      )
                    })}
                  </div>

                  <div className={cn(styles.searchbarContainer, data?.activeAppSpace ? styles.appspace : null)}>
                    {uploadingImage.file.status && (
                      <div className={styles.imagePaste}>
                        {uploadingImage.file.url && (
                          <div className={styles.imageContainer}>
                            <img className={styles.image} src={uploadingImage.file.url} alt="Uploading" />
                            {uploadingImage.file.status === "uploading" && (
                              <div
                                className={styles.progress}
                                style={{ width: `${100 - uploadingImage.file.uploadProgress}%` }}
                              />
                            )}
                          </div>
                        )}

                        <div className={styles.meta}>
                          {!uploadingImage.file.renaming && (
                            <span className={styles.name}>{uploadingImage.file.name}</span>
                          )}
                          {uploadingImage.file.renaming && (
                            <input
                              ref={imagePasteFilename}
                              className={styles.filenameInput}
                              value={uploadingImage.file.name}
                              onChange={(e) => uploadingImage.changeName(e.target.value)}
                              onBlur={() => uploadingImage.doneRenaming(false)}
                              onKeyDown={(e) => {
                                if (isEnter(e)) {
                                  uploadingImage.doneRenaming(false)
                                }
                              }}
                            />
                          )}
                          <span className={styles.size}>{humanizeFileSize(uploadingImage.file.file.size)}</span>
                          <div className={styles.actions}>
                            {uploadingImage.file.status === "uploaded" && !uploadingImage.file.renaming && (
                              <div
                                className={styles.action}
                                onClick={() => {
                                  uploadingImage.startRenaming()
                                  setTimeout(() => {
                                    imagePasteFilename.current?.focus()
                                    imagePasteFilename.current?.select()
                                  }, 1)
                                }}
                              >
                                Rename
                              </div>
                            )}

                            {uploadingImage.file.status !== "uploading" && (
                              <div className={styles.action} onClick={() => uploadingImage.done(true)}>
                                Cancel
                              </div>
                            )}
                            {uploadingImage.file.status === "uploaded" && !uploadingImage.file.renaming && (
                              <div className={styles.action} onClick={() => uploadingImage.done(false)}>
                                Done
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}

                    {!!currentTab?.groupId && (
                      <PretzelTextEditor onSubmit={handleSearch} handleTyping={handleTyping} attachments={true} />
                    )}
                    {!currentTab?.groupId && (
                      <>
                        {useRichText && (
                          <PretzelTextEditor
                            onSubmit={handleSearch}
                            handleTyping={handleTyping}
                            attachments={true}
                            initialValue={searchText}
                          />
                        )}

                        <div style={{ marginLeft: 15 }}>
                          {!useRichText && (
                            <Searchbar
                              fullSize={false}
                              onSearch={handleSearch}
                              extracted={extracted}
                              handleTyping={handleTyping}
                              onFilePaste={uploadingImage.handleFilePaste}
                              searchText={searchText}
                              setSearchText={setSearchText}
                              switchEditorType={() => {
                                setUseRichText(true)
                                setSearchText(searchText + "<br><br>")
                              }}
                            />
                          )}

                          <JumpMenu fullSize={false} />
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <UserStatus />
              </div>
            </>
          )}
        </div>
      </DndProvider>
    </ChatContext.Provider>
  )
}

export default Chat
