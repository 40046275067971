/* eslint-disable */
import React from "react"
import Popup from "reactjs-popup"
import cn from "clsx"
import button from "modules/shared/styles/components/button.module.scss"
import menu from "modules/shared/styles/components/menu.module.scss"
import styles from "../../folderTreeView/FolderTreeView.module.scss"
import { useSessions_CloseSessionMutation, useSessionOptionsMenu_DeleteSessionMutation } from "generated/graphql"
import pubsub from "pubsub-js"
import { topics } from "consts"

import { Session } from "../../sessionsListing/SessionsListing"
import { handleGraphQlError } from "utils/common"

const SessionOptionsMenu: React.FC<{
  session: Session
  openable: boolean
  startRename: (name: string) => void
  openSession: (session: Session) => void
}> = ({ session, openable, startRename, openSession }) => {
  const [closeSession] = useSessions_CloseSessionMutation({
    onError: handleGraphQlError("Unable to close session")
  })
  const [deleteSession] = useSessionOptionsMenu_DeleteSessionMutation({
    onError: handleGraphQlError("Unable to delete session")
  })

  async function handleCloseSession(id: string) {
    await closeSession({
      variables: { id }
    })

    pubsub.publish(topics.SESSIONS_UPDATED)
  }

  async function handleDeleteSession() {
    await deleteSession({ variables: { sessionId: session.id } })
    pubsub.publish(topics.SESSIONS_UPDATED)
  }

  return (
    <Popup
      arrow={false}
      trigger={
        <button
          className={cn(button.button, button.primary, button.outline, button.small, styles.folderIconButton)}
          title="More Options"
        >
          <i className="fa fa-ellipsis-h fa-fw"></i>
        </button>
      }
      contentStyle={{ padding: 0 }}
      position="left top"
    >
      {
        ((close: () => void) => (
          <div className={cn(menu.menu, menu.leftTop)}>
            {openable && (
              <div
                className={menu.item}
                onClick={() => {
                  close()
                  openSession(session)
                }}
              >
                <i className="material-icons">launch</i>
                <span>Open Session</span>
              </div>
            )}

            {!openable && (
              <div
                className={menu.item}
                onClick={() => {
                  close()
                  handleCloseSession(session.id)
                }}
              >
                <i className="material-icons">close</i>
                <span>Close Session</span>
              </div>
            )}

            <div
              className={menu.item}
              onClick={() => {
                startRename(session.name)
                close()
              }}
            >
              <i className="material-icons">input</i>
              <span>Rename Session</span>
            </div>

            <div
              className={cn(menu.item, menu.danger)}
              onClick={() => {
                handleDeleteSession()
                close()
              }}
            >
              <i className="material-icons">delete</i>
              <span>Delete Session</span>
            </div>
          </div>
        )) as any
      }
    </Popup>
  )
}

export default SessionOptionsMenu
