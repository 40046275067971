/* eslint-disable @typescript-eslint/no-explicit-any */
let wiki = {
  id: "",
  board: "",
  name: "",
  users: [],
  edit: false
}
let wikiOpenTabs: string[] = []
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const setWiki = (value: any) => (wiki = value)
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const setWikiOpenTabs = (value: string[]) => (wikiOpenTabs = value)

export { wiki, setWiki, wikiOpenTabs, setWikiOpenTabs }
