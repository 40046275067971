import React, { useState, useRef, useEffect } from "react"
import styles from "./ImageViewer.module.scss"
import spinner from "modules/shared/styles/components/spinner.module.scss"
import cn from "clsx"
import SaveButton from "modules/chat/components/saveButton"

interface IState {
  content: string
  edited: (content: string, id: string) => void
  id: string
  type: string
  onInit: () => unknown
}

const ImageViewer: React.FC<IState> = ({ content, type, onInit }) => {
  const [loading, setLoading] = useState(true)
  const rootElem = useRef<HTMLDivElement>(null)
  const [saved, setSaved] = useState(false)

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) rootElem.current?.requestFullscreen()
    else document.exitFullscreen()
  }

  useEffect(() => {
    onInit()
  }, [])

  return (
    <div className={styles.root} ref={rootElem}>
      <div className={styles.container}>
        <div className={loading ? styles.loading : styles.loaded}>
          <img
            className={styles.image}
            onLoad={() => {
              setTimeout(() => {
                setLoading(false)
              }, 150)
            }}
            onError={() => {
              //
            }}
            src={content}
            alt={content}
          />
        </div>
        <div className={cn(styles.middle, loading ? styles.loaded : styles.loading)}>
          <div className={cn(spinner.spinner, spinner.dark)} />
        </div>
      </div>
      <div className={styles.topLeftButtonGroup}>
        <div className={styles.button} title="Toggle Fullscreen" onClick={toggleFullscreen}>
          <span className="material-icons">fullscreen</span>
        </div>
        {type === "imgPreview" && !saved && (
          <SaveButton type="image" url={content} onSaved={() => setSaved(true)} name="Image">
            <div className={styles.button} title="Save Image">
              <span className="material-icons">save</span>
            </div>
          </SaveButton>
        )}
      </div>
    </div>
  )
}

export default ImageViewer
