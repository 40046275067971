import { createContext } from "react"

const FileListingContext = createContext<{
  mobileChatPane: boolean
  pinnedEmbedHtml: string
  setPinnedEmbedHtml: (s: string) => void
  dragging: boolean
  chatWidth: number
  setChatWidth: (n: number) => void
  maximizeChat: boolean
  toggleMaximizeChat: () => void
  windowWidth: number
  sidebarUpdated: (b: boolean) => void
  sessionLoadingState: boolean
  setSessionLoadingState: (b: boolean) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}>({} as any)

export default FileListingContext
