import React from "react"
import { cloneElement, FunctionComponent, PropsWithChildren, ReactNode } from "react"

import { Tooltip, TooltipProps } from "react-tippy"

interface TooltipWrappedProps extends PropsWithChildren<TooltipProps> {
  children?: ReactNode
}

const TooltipWrapped: FunctionComponent<TooltipWrappedProps> = (props) => cloneElement(<Tooltip />, { ...props })

export default TooltipWrapped
