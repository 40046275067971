/* eslint-disable */
import React from "react"
import cn from "clsx"
import styles from "./JumpMenu.module.scss"
import { useHistory } from "react-router-dom"
import { recentTaskBoard, recentWiki } from "utils/common"
import {
  useSidebarChannelsQuery,
  useSidebar_UerInfoQuery,
  useAppSpaceQuery,
  useChat_ChatMutation
} from "generated/graphql"
import { handleGraphQlError } from "utils/common"
import { currentTab, newTab, updateTab } from "modules/app/components/sessionTabs/SessionTabs"
import { routes } from "consts"

const JumpMenu: React.FC<{ fullSize?: boolean }> = ({ fullSize }) => {
  const { data: directs } = useSidebarChannelsQuery({
    onError: handleGraphQlError("Unable to get message")
  })
  const { data: me } = useSidebar_UerInfoQuery({
    onError: handleGraphQlError("Unable to get user info")
  })
  const { data: openApps } = useAppSpaceQuery({
    onError: handleGraphQlError("Unable to get appspace data")
  })
  const [callChat] = useChat_ChatMutation({
    onError: handleGraphQlError("Unable to send the message")
  })
  const history = useHistory()

  const openChat = (cmd?: string) => {
    // TODO COHEN: createSession.then => use ID from new Pretzel session
    const originRoot = window.location.href.slice(0, -1) === window.location.origin

    // User is not in CHANNEL/DM AND is ROOT URL
    if (directs?.activeSession.groupId === null && originRoot) {
      const useActive = currentTab
      updateTab(useActive.id, "location", routes.chat)
      updateTab(useActive.id, "type", "pretzel:" + useActive?.type?.split(":")[1])

      if (history.location.pathname !== "/chat") history.push("/chat")
      if (cmd)
        callChat({
          variables: {
            message: cmd,
            silent: false,
            type: undefined,
            richtext: false,
            attachments: []
          }
        })
      return
    }
  }

  const DefaultLinks: React.FC = () => {
    return (
      <div className={cn([styles.jumpMenu, fullSize ? styles.home : null])}>
        <span
          onClick={() =>
            newTab({
              name: "Feeds",
              id: "",
              icon: "default",
              location: routes.dashboard,
              active: true,
              forceOpen: true
            })
          }
        >
          Feeds
        </span>
        <span onClick={() => openChat()}>Message</span>
        <span
          onClick={() =>
            newTab({
              name: "Tasks",
              id: "",
              icon: "tasks",
              location: me?.organization.name === "Pretzel startup" ? "/tasks/Mj8MWttf" : recentTaskBoard(),
              active: true,
              forceOpen: true
            })
          }
        >
          Tasks
        </span>
        <span
          onClick={() =>
            newTab({ name: "Wikis", id: "", icon: "wikis", location: recentWiki(), active: true, forceOpen: true })
          }
        >
          Wikis
        </span>
        <span
          onClick={() =>
            newTab({
              name: "People",
              id: "",
              icon: "default",
              location: "/people/organization",
              active: true,
              forceOpen: true
            })
          }
        >
          People
        </span>
        <span
          onClick={() =>
            newTab({
              name: "Organization",
              id: "",
              icon: "default",
              location: "/people",
              active: true,
              forceOpen: true
            })
          }
        >
          Organization
        </span>
        <span onClick={() => openChat("files")}>Files</span>
        <span
          onClick={() =>
            newTab({ name: "Notes", id: "", icon: "default", location: routes.notes, active: true, forceOpen: true })
          }
        >
          Notes
        </span>
        <span
          onClick={() =>
            newTab({
              name: "Journal",
              id: "",
              icon: "default",
              location: routes.journal,
              active: true,
              forceOpen: true
            })
          }
        >
          Journal
        </span>
        <span>News</span>
      </div>
    )
  }

  if (
    openApps?.activeAppSpace?.type !== "doc.msword" &&
    openApps?.activeAppSpace?.type !== "doc" &&
    openApps?.activeAppSpace?.type !== "sheet" &&
    openApps?.activeAppSpace?.type !== "image"
  )
    return <DefaultLinks />
  else return null
}

export default JumpMenu
