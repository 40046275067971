/* eslint-disable */
import React, { useEffect, useRef, useState } from "react"
import { fileTypeToColor } from "../fileListing/utils/fileTypeToColor"
import cn from "clsx"
import styles from "./ViewFiles.module.scss"
import {
  useFileListing_OpenFileMutation,
  useAppSpaces_CloseAppSpaceMutation,
  useRootQuery,
  useAppSpaceQuery,
} from "generated/graphql"
import { handleGraphQlError } from "utils/common"
import { setActiveView, setSpaceTabs } from "modules/appSpace/components/appSpaceTabs/AppSpaceTabs"
import { unfilteredFolders } from "../fileListing/components/fileListing/FileListing"
import { setGlobalFinishPreview } from "modules/app/components/root/Root"

export let viewTabs = [] as any
export const setViewTabs = (tabs: any) => viewTabs = tabs

export const viewingTab = (locator: any, previewing: boolean) => {
  const template = {
    __typename: "SessionAppSpace",
    id: Date.now(),
    isActive: false,
    appSpace: {
      __typename: "AppSpace",
      type: locator?.type,
      previewing: previewing,
      id: previewing ? "preview" : Date.now(),
      fileId: locator?.id,
      name: locator?.name,
    },
    showTooltip: false
  }

  if (previewing) {
    if (viewTabs?.length > 0) viewTabs[0] = template
    else viewTabs = [template]
  } else viewTabs = viewTabs.concat(template)

  setSpaceTabs(viewTabs)
}

export const removeViewTab = (id: string) => viewTabs = viewTabs.filter((item: any) => item.appSpace.id !== id)

const ViewFiles: React.FC<{ message: any }> = ({ message }) => {
  const [maxFiles, setMaxFiles] = useState(0)
  const [index, setIndex] = useState(0)
  const [locator, setLocator] = useState([] as any)

  const [closeAppSpace] = useAppSpaces_CloseAppSpaceMutation({})
  const [openFile] = useFileListing_OpenFileMutation({ onError: handleGraphQlError("Unable to open file") })
  const { data } = useRootQuery({ onError: handleGraphQlError("Unable to get data") })
  const { data: dataNew } = useAppSpaceQuery({
    onError: handleGraphQlError("Unable to get appspace data")
  })
  function nextFile(message: any, type: string) {
    const searching = message?.trim().toLocaleLowerCase().split("view ").pop()?.trim()
    let found = JSON.parse(localStorage.fileCache).filter((file: any) => file.name.toLowerCase().includes(searching))
  
    setMaxFiles(found.length)
  
    switch (type) {
      case "next":
        setIndex(index + 1)
        break
      case "prev":
        setIndex(index - 1)
        break
      case "first":
        setIndex(0)
        break
      case "last":
        setIndex(maxFiles - 1)
        break
    }
  
    setLocator(found)
    viewingTab(locator[index], true)
    setGlobalFinishPreview("")

    // Initial app space open
    if (found[index].id) {
      setActiveView("preview")
      // Don't create another app space if one is already open
      if (!data?.activeAppSpace?.id) openFile({ variables: { fileId: found[index].id } })
    }
  }
  
  // Handle closing app spaces, after opening a new one
  useEffect(() => {
    if (locator[index]?.id) openFile({ variables: { fileId: locator[index]?.id }}).then(() => {
      dataNew?.appSpaces?.forEach((appSpace: any) => {
        // if (appSpace.id !== data!.activeAppSpace!.id) 
        closeAppSpace({ variables: { appSpaceId: appSpace.appSpace.id }}) //.then(() => console.log("closed", appSpace.id)).catch(() => console.log("failed", appSpace))
      })
      // closeAppSpace({ variables: { appSpaceId: data!.activeAppSpace!.id }}).catch(() => {})
    })
  }, [index])

  useEffect(() => viewingTab(locator[index], true), [locator, index, message])
  useEffect(() => nextFile(message, "first"), [message])

  const keyShortcuts = (e: any) => {
    if (e.key === "ArrowRight") {
      if (index === maxFiles - 1) return
      nextFile(message, "next")
    }
    if (e.key === "ArrowLeft") {
      if (index === 0) return
      nextFile(message, "prev")
    }
    if (e.key === "Tab") {
      e.preventDefault()
      e.stopPropagation()
      viewingTab(locator[index], false)
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", keyShortcuts)
    return () => window?.removeEventListener("keydown", keyShortcuts)
  }, [index]);

  const buildFileDirectory = (current: string) => {
    if (current === "/") return current
    
    // Able to rebuild the path, based on active File Listing
    if (unfilteredFolders?.length > 0) {
      const foundFolder = unfilteredFolders.find((f: any) => f.name === locator[index]?.parent?.name)
      if (foundFolder) {
        let parentFolder = foundFolder.parent
        let folderPath = ["/", `/${foundFolder.name}`]
  
        // Iterate through parents until root is found
        while (parentFolder) {
          if (parentFolder.id === "root") break // Stop at root
          folderPath.push("/" + parentFolder.name)
          // Locate the next parent
          parentFolder = unfilteredFolders.find((f: any) => f.id === parentFolder.id).parent 
        }
        return folderPath.join("> ")
      }
      // Don't have the path, so just return the current
      else return "/ > "+current
    }
    // Don't have the path, so just return the current
    else return "/ > "+current
  }

  const bytesToReadableSize = (bytes: number) => {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"]
    if (bytes === 0) return "0 Byte"
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString())
    if (i === 0) return `${bytes} ${sizes[i]}`
    return `${(bytes / Math.pow(1024, i)).toFixed(1)} ${sizes[i]}`
  }
  
  const convertDate = (date: string) => {
    const d = new Date(date)
    const month = d.getMonth() + 1
    const day = d.getDate()
    const year = d.getFullYear()
    const hour = d.getHours()
    const minutes = d.getMinutes()
    const ampm = hour >= 12 ? "PM" : "AM"
    const hour12 = hour % 12 || 12
    return `${month}/${day}/${year} ${hour12}:${minutes} ${ampm}`
  }

  if (!locator) return null

  return (
    <div className="viewing_files">
      <br />
      <button
        className={cn(styles.navButton, styles.separate)}
        style={{ margin: "0 0.5rem 0 0", color: index === 0 ? "#BFC9DA" : "#314A81" }}
        onClick={() => {
          if (index === 0) return
          nextFile(message, "first")
        }}
      >
        <i className="material-icons-outlined">first_page</i>
        First
      </button>
      <button
        className={cn(styles.navButton, styles.navLeft)}
        style={{ color: index === 0 ? "#BFC9DA" : "#314A81" }}
        onClick={() => {
          if (index === 0) return
          nextFile(message, "prev")
        }}
      >
        <i className="material-icons-outlined">chevron_left</i>
        Prev
      </button>
      <button
        className={cn(styles.navButton, styles.navRight)}
        style={{ color: index === maxFiles - 1 ? "#BFC9DA" : "#314A81" }}
        onClick={() => {
          if (index === maxFiles - 1) return
          nextFile(message, "next")
        }}
      >
        <i className="material-icons-outlined">chevron_right</i>
        Next
      </button>
      <button
        className={cn(styles.navButton, styles.separate)}
        style={{ margin: "0 0 0 0.5rem", color: index === maxFiles - 1 ? "#BFC9DA" : "#314A81" }}
        onClick={() => {
          if (index === maxFiles - 1) return
          nextFile(message, "last")
        }}
      >
        <i className="material-icons-outlined">last_page</i>
        Last
      </button>
      <div className={styles.container}>
        <p className={styles.viewingFile}>
          Viewing ({index + 1} of {maxFiles})
        </p>
        <div className={styles.header}>
          <i className={cn("fa fa-fw fa-folder-open")} style={{ color: "orange"}} />
          <span className={styles.name}>
            {buildFileDirectory(locator[index]?.parent?.name)}
          </span>
        </div>  
        <div className={styles.details}>
          <div className={cn(fileTypeToColor(locator[index]?.type), styles.icon)}></div>
          <div className={styles.detailsContainer}>
            <p className={styles.fileName}>{locator[index]?.name}</p>
            <span className={styles.stat}>{convertDate(locator[index]?.updatedDate)}</span>
            <span className={styles.stat}>{bytesToReadableSize(locator[index]?.size)} </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ViewFiles
