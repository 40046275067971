import React from "react"
import styles from "./ImageItem.module.scss"
import cn from "clsx"
import BookmarkButton from "modules/shared/components/bookmarkButton"
import LazyImage from "modules/shared/components/lazyImage"
import SaveButton from "modules/chat/components/saveButton"
import InsertButton from "modules/chat/components/insertButton"
import { useAppSpaces_CreateAppSpaceMutation } from "generated/graphql"
import { handleGraphQlError } from "utils/common"

const ImageItem: React.FC<{
  src: string
  alt: string
  thumbnail: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  searchTerm: string
  videoMode?: boolean
}> = ({ src, alt, thumbnail, searchTerm, videoMode }) => {
  const [createAppSpace] = useAppSpaces_CreateAppSpaceMutation({
    onError: handleGraphQlError("Unable to create app space")
  })
  function openImage() {
    if (videoMode) {
      window.open(src, "_blank")
    } else {
      createAppSpace({
        variables: {
          type: "imgPreview",
          content: src,
          name: "Image Preview"
        }
      })
    }
  }

  return (
    <div className={styles.root} style={{ aspectRatio: "1" }}>
      <div onClick={openImage} className={styles.clickableImage} style={{ cursor: videoMode ? "pointer" : "initial" }}>
        <LazyImage src={thumbnail} alt={alt} />
      </div>
      <div className={styles.hoverOptions}>
        <BookmarkButton title={src} url={src}>
          <div className={cn(styles.hoverButton)} title="Bookmark Image">
            <i className="material-icons">bookmark_outline</i>
          </div>
        </BookmarkButton>
        {!videoMode && (
          <>
            <SaveButton type="image" url={src} name={searchTerm + " - Image"}>
              <div className={cn(styles.hoverButton)} title="Save Image as">
                <i className="material-icons">add</i>
              </div>
            </SaveButton>
            <InsertButton type="image" content={src} title={alt || "Image"}>
              <div className={cn(styles.hoverButton)} title="Insert Image">
                <i className="material-icons">arrow_forward</i>
              </div>
            </InsertButton>
          </>
        )}
      </div>

      {videoMode && (
        <div className={styles.playbtn}>
          <i className="material-icons">play_circle</i>
        </div>
      )}
    </div>
  )
}

export default ImageItem
