/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { encodeQueryString, parseQueryString } from "utils/common"

export function useManageQueryParams(): string | null {
  const history = useHistory()
  const [focusMessage, setFocusMessage] = useState<string | null>(null)

  useEffect(() => {
    const search = parseQueryString(history.location.search) as any

    if (!search.messageId) {
      return
    }

    const fm = search.messageId
    setTimeout(() => {
      delete search.messageId
      history.replace({
        ...history.location,
        search: encodeQueryString(search)
      })
    }, 100)

    setFocusMessage(fm)
  }, [history.location.search])

  return focusMessage
}
