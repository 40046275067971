/* eslint-disable */
import {
  OrganizationMemberSearchDocument,
  useChatHeadingQuery,
  useChatHeading_AddGroupMembersMutation,
  useChatHeading_LeaveGrupMutation,
  useChatHeading_UpdateGroupMutation
} from "generated/graphql"
import React, { useEffect, useState } from "react"
import Tooltip from "modules/shared/components/tooltip"
import styles from "./ChatHeading.module.scss"
import { handleGraphQlError, modalStyles } from "utils/common"
import cn from "clsx"
import Modal from "react-modal"
import Select from "react-select/async"

import PP from "modules/shared/components/profilePicture"
import modal from "modules/shared/styles/components/modal.module.scss"
import EditableInput from "modules/shared/components/editableInput"
import button from "modules/shared/styles/components/button.module.scss"
import { setSavedMessageField } from "modules/shared/components/pretzelTextEditor/hook"

import { useOnDemandQuery } from "utils/hooks"
import { channelRegex, topics } from "consts"

import input from "modules/shared/styles/components/input.module.scss"
import pubsub from "pubsub-js"

const AddGroupMembersModal: React.FC<{ handleClose: () => void; groupId: string }> = ({ handleClose, groupId }) => {
  const searchMembers = useOnDemandQuery(OrganizationMemberSearchDocument)
  const [addMembers] = useChatHeading_AddGroupMembersMutation({
    onError: handleGraphQlError("Unable to add members to the group")
  })

  function closeModal() {
    setSelectedMembers([])
    handleClose()
  }

  const [selectedMembers, setSelectedMembers] = useState<string[]>([])

  return (
    <Modal
      isOpen
      onRequestClose={() => handleClose()}
      style={{
        content: {
          ...modalStyles.content,
          overflow: "initial"
        },
        overlay: modalStyles.overlay
      }}
      contentLabel="Add new channel"
    >
      <div className={modal.modal}>
        <div className={modal.header}>
          <i className="material-icons-outlined">person_add</i>
          <h2 className={modal.title}>Add Members</h2>
          <p className={modal.description}>Add new members to the group</p>
        </div>

        <div className={modal.inputPButton}>
          <div className={styles.addEmailInput} style={{ marginBottom: 15 }}>
            <Select
              loadOptions={async (val, callback) => {
                const result = await searchMembers({
                  searchText: val,
                  skipGroupId: groupId
                })

                return result.data.organization.members.map((m: any) => ({
                  name: m.user.fullName,
                  id: m.user.id,
                  email: m.user.email
                }))
              }}
              isMulti
              placeholder="Search members..."
              value={selectedMembers}
              onChange={(v) => setSelectedMembers(v as any)}
              getOptionLabel={(a) => a.name}
              getOptionValue={(a) => a.id}
            />
          </div>

          <button
            className={cn([button.button, button.primary, button.noAnimation, button.big, styles.sidebarModalButton])}
            style={{ opacity: !selectedMembers?.length ? 0.75 : 1 }}
            disabled={!selectedMembers?.length}
            onClick={async () => {
              await addMembers({
                variables: {
                  members: selectedMembers.map((a) => (a as any).id),
                  group: groupId
                }
              })
              closeModal()
            }}
          >
            <i className="material-icons">add</i>
            <span>Add Members</span>
          </button>
        </div>
        <div className={modal.closeButton} onClick={() => closeModal()}>
          <i className="material-icons">close</i>
        </div>
      </div>
    </Modal>
  )
}

const ChatHeading: React.FC<{ sessionId: string; userId: string | undefined }> = ({ sessionId, userId }) => {
  const [sidebar, setSidebar] = useState(false)
  const [addingMembers, setAddingMembers] = useState(false)
  const [updateGroup] = useChatHeading_UpdateGroupMutation({
    onError: handleGraphQlError("Unable to update group")
  })
  const [validationMessage, setValidationMessage] = useState<string>("")

  const { data, refetch } = useChatHeadingQuery({
    variables: {
      session: sessionId
    },
    fetchPolicy: "cache-first"
  })

  useEffect(() => {
    const token = pubsub.subscribe(topics.CHAT_HEADER_UPDATE_PROFILE, () => {
      refetch()
    })
    return () => {
      pubsub.unsubscribe(token)
    }
  }, [])
  const [leaveGroup] = useChatHeading_LeaveGrupMutation({
    onError: handleGraphQlError("Unable to leave group")
  })

  useEffect(() => {
    const group = data?.session?.group
    if (group?.name) {
      if (window.location.origin + "/" === window.location.href) return
      // pubsub.publish(topics.SET_SESSION_TITLE, `${group?.name}`)
      setSavedMessageField(group?.name)
    }
  }, [data?.session])

  if (!data) {
    return null
  }

  const openSidebar = () => {
    setSidebar(true)
  }

  const openProfile = (id: string) => {
    pubsub.publish(topics.OPEN_PROFILE, id)
  }

  const closeSidebar = () => {
    setSidebar(false)
  }

  function fixName(name: string) {
    return name.replaceAll(" ", "-").toLowerCase()
  }

  function validateName(editingName: string) {
    if (!editingName) {
      return false
    }

    return !!editingName.match(channelRegex)
  }

  const session = data.session

  if (session?.group) {
    const group = session.group
    const isOwner = group.members.find((x) => x.id === userId || "")?.role === "owner"

    return (
      <>
        {addingMembers && (
          <AddGroupMembersModal
            groupId={session.group.id}
            handleClose={() => {
              setAddingMembers(false)
            }}
          />
        )}

        {group.type !== "1-1" && (
          <div className={styles.root}>
            <div className={styles.meta} onClick={openSidebar}>
              <div className={styles.name}>
                <span>{session.group.name}</span>
                <i className="material-icons">expand_more</i>
              </div>
              <p className={styles.description}>{group.description}</p>
            </div>

            <div className={styles.expand}></div>
            <div className={styles.actionsBar}>
              <label className={styles.lbl}>Members</label>
              <div className={styles.membersList}>
                {session.group.members.slice(0, 3).map((m) => (
                  <PP
                    size={"small"}
                    userName={m.name}
                    userId={m.id}
                    src={m.profilePicture}
                    key={m.id}
                    statusMode="dot"
                  />
                ))}
                {session.group.canEdit && (
                  <Tooltip title="Add member" delay={200} size={"big"}>
                    <div
                      className={styles.addMemberBtn}
                      onClick={() => {
                        setAddingMembers(true)
                      }}
                    >
                      <i className="material-icons">add</i>
                    </div>
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
        )}

        {group.type === "1-1" && group.otherMember && (
          <div className={styles.root}>
            <div className={styles.meta} onClick={() => openProfile(group.otherMember?.id || "")}>
              <div className={styles.name}>
                <PP
                  size={"small"}
                  userName={group.otherMember.name}
                  userId={group.otherMember.id}
                  src={group.otherMember.profilePicture}
                  statusMode={"dot"}
                />
                <div className={styles.oneToOneName}>
                  <span>{session.group.name}</span>
                </div>
                <i className="material-icons">expand_more</i>
              </div>
            </div>

            <div className={styles.expand}></div>
          </div>
        )}

        <div className={cn([styles.headerPopupContainer, sidebar ? styles.open : null])}>
          <div className={styles.bg} onClick={closeSidebar}></div>
          <div className={styles.headerPopupContent}>
            <div className={styles.header}>
              <div className={styles.meta}>
                <span className={styles.title}>{session.group.name}</span>
                <p className={styles.description}>{group.description}</p>
              </div>

              <div className={styles.closeButton} onClick={closeSidebar}>
                <i className="material-icons">close</i>
              </div>
            </div>

            <div className={styles.content}>
              <div className={styles.sectionTitle}>
                <label>About</label>
                <div></div>
              </div>

              <div className={styles.editableInputSections}>
                {group.type === "channel" && (
                  <>
                    <div className={styles.editableInputSection}>
                      <span className={styles.title}>Channel Name</span>
                      {
                        <EditableInput
                          value={session.group.name}
                          editable={group.canEdit}
                          isTextArea={false}
                          enhancer={fixName}
                          saveCallback={async (v) => {
                            if (!validateName(v)) {
                              setValidationMessage(
                                "Channel names cannot contain most punctuation. Try a different name"
                              )
                              return
                            }

                            setValidationMessage("")
                            await updateGroup({
                              variables: {
                                id: group.id,
                                name: v,
                                description: group.description
                              }
                            })
                          }}
                        />
                      }
                      {validationMessage && (
                        <p className={input.validationMessage} style={{ marginTop: 5 }}>
                          {validationMessage}
                        </p>
                      )}
                    </div>
                  </>
                )}
                <div className={styles.editableInputSection}>
                  <span className={styles.title}>Description</span>

                  <EditableInput
                    value={session.group.description || ""}
                    editable={group.canEdit}
                    isTextArea={true}
                    placeholder="Description not provided"
                    saveCallback={async (v) => {
                      await updateGroup({
                        variables: {
                          id: group.id,
                          name: group.name,
                          description: v
                        }
                      })
                    }}
                  />
                </div>
                <div className={styles.editableInputSection}>
                  <span className={styles.title}>Created By</span>
                  <p className={styles.value}>{session.group.members.find((x) => x.role === "owner")?.name}</p>
                </div>
              </div>

              <div className={styles.sectionTitle}>
                <label>Members</label>
                <div></div>
              </div>

              <div className={styles.membersList}>
                {session.group.members.map((member, i) => (
                  <div className={styles.member} key={member.id}>
                    <div className={styles.profile}>
                      <PP
                        size={"large"}
                        userName={member.name}
                        userId={member.id}
                        src={member.profilePicture}
                        statusMode="dot"
                      />
                    </div>
                    <div className={styles.meta}>
                      <span className={styles.value}>
                        <span>{member.name}</span>
                        {member.role === "owner" && <i className="material-icons-outlined">shield</i>}
                      </span>
                      <span className={styles.title}>Added {new Date().toLocaleString()}</span>
                    </div>
                  </div>
                ))}

                {isOwner && (
                  <div
                    className={styles.addButton}
                    onClick={() => {
                      setAddingMembers(true)
                    }}
                  >
                    <div className={styles.adder}>
                      <i className="material-icons">add</i>
                    </div>
                    <div className={styles.meta}>
                      <span className={styles.value}>
                        <span>Add Member</span>
                      </span>
                    </div>
                  </div>
                )}
                <div
                  className={cn([styles.addButton, styles.danger])}
                  onClick={() =>
                    leaveGroup({
                      variables: {
                        id: group.id
                      }
                    })
                  }
                >
                  <div className={styles.adder}>
                    <i className="material-icons">close</i>
                  </div>
                  <div className={styles.meta}>
                    <span className={styles.value}>
                      <span>Leave {group.type === "channel" ? "Channel" : "Group"}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  return <div></div>
}

export default ChatHeading
