export const routes = {
  home: "/",
  signin: "/signin",
  register: "/register",
  chat: "/chat",
  people: "/people",
  tasks: "/tasks",
  taskBoard: "/tasks/:boardId/:taskId?",
  wikis: "/wikis",
  wiki: "/wikis/:wikiId/:pageId?",
  notes: "/notes",
  journal: "/journal",
  dashboard: "/dashboard"
}

export const topics = {
  SESSIONS_UPDATED: "sessionsUpdated",
  UPDATE_FILES: "updateFiles",
  INSERT_CONTENT_TO_DOCUMENT: "insertContentToDocument",
  SIDEBAR_ACTION: "sidebarChatAction",
  SIDEBAR_UPDATE_PROFILE: "sidebarRefetchProfile",
  SIDEBAR_UPDATE_USERNAME: "sidebarRefetchUsername",
  CHAT_HEADER_UPDATE_PROFILE: "chatHeaderRefetchProfile",
  CHAT_MESSAGE_UPDATE_USERNAME: "chatMessageRefetchUsername",
  UPDATE_FILES_QUERY: "updateFileListingQuery",
  DELETE_FILE: "deleteFile",
  DESELECT_FILES: "deselectFile",

  OPEN_PROFILE: "openProfile",

  TASKS_OPEN_TASK: "tasks.openTask",
  TASKS_TASK_ARCHIVED: "tasks.taskArchived",

  MESSAGE_EDIT: "message.edit",
  OPEN_MESSAGE_EDITOR: "message.openMessageEditor",
  MESSAGE_UNREAD_NOTIFY: "message.unreadNotify",
  MESSAGE_READ_NOTIFY: "message.readNotify",
  SET_SESSION_TITLE: "session.setPageTitle",
  SESSION_OPEN: "session.open",
  APPSPACE_CREATE: "appspace.create",
  APPSPACE_RELOAD: "appspace.reload",

  NOTES_OPEN_NOTE: "notes.openNote",
  NOTES_OPEN_BYPOS: "notes.openByPos",
  NOTES_DELETE_NOTE: "notes.deleteNote",
  NOTES_DELETE_BYPOS: "notes.deleteByPos",

  JOURNAL_SELECT_FILE: "journal.selectFile",
  JOURNAL_ENTRIES_REFETCH: "journal.refetchEntries",

  DM_OPEN: "dm.open",

  FILE_LISTING_RELOAD: "fileListing.reloadFiles",

  OPEN_CREATE_NEW_DOCUMENT: "createNewDocument.openPanel"
}

export const KeyCodes = {
  Enter: 13,
  Backspace: 8,
  Tab: 9,
  Escape: 27,
  UpArrow: 38,
  DownArrow: 40,
  LeftArrow: 37,
  RightArrow: 39
}

export const channelRegex = /^[a-z0-9-]{1}[a-z0-9-]{0,50}$/gm

export const USER_ACTIVITY_COUNTER_DURATION = 5000

export const BOARD_PROFILE_BELT_LENGTH = 4
