import React from "react"
import button from "modules/shared/styles/components/button.module.scss"
import obsolete from "modules/shared/styles/components/obsolete.module.scss"
import cn from "clsx"

const CollapsePanel: React.FC<{
  text: string
  setObsoleteOverride: (arg: boolean) => void
}> = ({ text, setObsoleteOverride }) => {
  return (
    <div className={obsolete.collapsePanel}>
      <div className={cn(obsolete.center, "bottomCenter")}>
        <button
          className={cn(button.button, button.primary, button.iconButton, button.roundedButton)}
          onClick={() => setObsoleteOverride(false)}
        >
          <i className="fa fa-chevron-up"></i>
          {text}
        </button>
      </div>
    </div>
  )
}

export default CollapsePanel
