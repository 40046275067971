/* eslint-disable */
import React, { createContext, useContext, useEffect, useState } from "react"
import styles from "./SearchResult.module.scss"
import {
  Message_MessageFragment,
  SearchResult_WebItemFragment,
  SearchResult_SearchResultFragment,
  useAppSpaces_CreateAppSpaceMutation
} from "generated/graphql"
import { formatNumberToK, handleGraphQlError, pages_count, stringCleanup, textEllipsis } from "utils/common"
import cn from "clsx"
import fileListing from "./../fileListing/components/fileListing/FileListing.module.scss"
import DataPanel from "modules/shared/components/dataPanel"

import { useSearchResultLazyQuery } from "generated/graphql"

import WaitPreloader from "./components/waitPreloader"

import WebPage from "./renderItems/webPage"
import SearchPagination from "modules/shared/components/searchPagination"
import WikipediaSearchResult from "./components/wikipediaResult"

import BookmarkButton from "modules/shared/components/bookmarkButton"
import LazyImage from "modules/shared/components/lazyImage"
import SaveButton from "../saveButton"
import InsertButton from "../insertButton"

import SplitterContext from "modules/shared/components/splitterContext"
import ChatContext from "../chat/ChatContext"

const preprocessDescription = (description: string, query: string) => {
  const qs = query?.split(" ").map((x) => x.toLowerCase())
  description = description.substr(0, 150)
  return (
    <>
      {description.split(" ").map((word: string, i) => {
        if (qs?.includes(stringCleanup(word).toLowerCase())) return <strong key={i}>{word + " "}</strong>
        else return word + " "
      })}
    </>
  )
}

const renderItem = (item: SearchResult_WebItemFragment, q: string, i: number) => {
  switch (item._type) {
    case "WebPage":
      return <WebPage item={item} q={q} preprocessDescription={preprocessDescription} key={i} />

    default:
      return null
  }
}

const renderWebResult = (
  result: SearchResult_SearchResultFragment,
  handleChangeSearchTerm: (term: string) => unknown,
  setSelectedType: (type: string) => void,
  searchTerm: string
) => {
  return (
    <>
      <div className={styles.webLinks}>
        {result.web?.webResult?.map((w, i) => (w ? renderItem(w, result?.query || "", i) : null))}
      </div>
    </>
  )
}

const renderImages = (
  result: SearchResult_SearchResultFragment,
  searchTerm: string,
  createAppSpace: (type: string, name: string, content: string) => unknown
) => {
  function openImage(src: string) {
    createAppSpace("imgPreview", "Image Preview", src)
  }
  return (
    <>
      <div className={styles.webImages}>
        {result?.images?.map((img) => (
          <div key={img?.id || ""} className={styles.webImage}>
            <div className={styles.imageContainer}>
              {/* <img
                
                alt={img?.name || "Thumbnail"}
                src={img?.thumbnailUrl || ""}
              /> */}
              <div
                className={styles.imgContainer}
                onClick={() => {
                  if (img?.contentUrl) {
                    openImage(img?.contentUrl)
                  }
                }}
              >
                <LazyImage src={img?.thumbnailUrl || ""} alt={img?.name || ""} />
              </div>

              <div className={styles.imageInfo}>
                <span className={styles.imageName}>{img?.name} </span>
                <span className={styles.imageSize}>
                  ({img?.width}x{img?.height})
                </span>
                <p className={styles.imageDisplayUrl}>{new URL(img?.contentUrl || "").host}</p>
              </div>
            </div>

            <div className={styles.hoverOptions}>
              <BookmarkButton title={img?.name || ""} url={img?.contentUrl || ""}>
                <div className={cn(styles.hoverButton)}>
                  <i className="material-icons">bookmark_outline</i>
                  Bookmark
                </div>
              </BookmarkButton>
              <SaveButton type="image" url={img?.contentUrl || ""} name={searchTerm + " - Image"}>
                <div className={cn(styles.hoverButton)}>
                  <i className="material-icons">add</i>
                  Save
                </div>
              </SaveButton>
              <InsertButton title={img?.name || undefined} type="image" content={img?.contentUrl || ""}>
                <div className={cn(styles.hoverButton)}>
                  <i className="material-icons">arrow_forward</i>
                  Insert
                </div>
              </InsertButton>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

const renderVideos = (result: SearchResult_SearchResultFragment) => {
  return (
    <>
      <div className={styles.webVideos}>
        {result?.videos?.map(
          (vid) =>
            vid && (
              <div
                key={vid.id}
                className={styles.webVideo}
                onClick={() => window.open(vid.contentUrl as string, "_blank")}
              >
                {/* <img alt="Thumbnail" src={} /> */}
                <div className={styles.imgContainer}>
                  <LazyImage src={vid.thumbnailUrl as string} alt={vid.name as string} />
                </div>
                <div className={styles.videoInfo}>
                  <p className={styles.videoTitle}>{vid.name}</p>
                  <div className={styles.videoMeta}>
                    <p>{formatNumberToK(vid.viewCount as number)} views</p>
                    <p>{new URL(vid.contentUrl as string).host}</p>
                  </div>
                </div>
              </div>
            )
        )}
      </div>
    </>
  )
}

const renderNews = (result: SearchResult_SearchResultFragment) => {
  return (
    <>
      <div className={styles.webVideos}>
        {result?.news?.map(
          (news) =>
            news && (
              <div key={news.id} className={styles.webVideo} onClick={() => window.open(news.url as string, "_blank")}>
                {/* <img alt="Thumbnail" src={} /> */}
                <div className={styles.imgContainer}>
                  <LazyImage src={news.image?.thumbnail?.contentUrl as string} alt={news.name as string} />
                </div>
                <div className={styles.videoInfo}>
                  <p className={styles.videoTitle}>{news.name}</p>

                  <div className={styles.videoMeta}>
                    <p>{textEllipsis(news.description as string, 100)}</p>
                  </div>
                </div>
              </div>
            )
        )}
      </div>
    </>
  )
}

export const SearchResultContext = createContext<{
  setSelectedType: (type: string) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}>({} as any)

const SearchResult: React.FC<{
  message: Message_MessageFragment
  query: string
  setQuery: (s: string) => void
  isLast: boolean
  selectedType: string
  setSelectedType: (s: string) => void
}> = ({ message, isLast, query, setQuery, selectedType, setSelectedType }) => {
  const [getSearchResult, { data }] = useSearchResultLazyQuery({
    fetchPolicy: "no-cache",
    onError: handleGraphQlError("Unable to get search result")
  })
  const [searchResult, setSearchResult] = useState(message.searchResult)
  const count = 20
  const [offset, setOffset] = useState(message.searchResult?.offset || 0)
  const [waitForData, setWaitForData] = useState(false)
  const [create] = useAppSpaces_CreateAppSpaceMutation({
    onError: handleGraphQlError("Unable to create app space")
  })

  const { chatWidth, maximizeChat } = useContext(SplitterContext)
  const { completeLoadingCallback } = useContext(ChatContext)

  const createAppSpace = (type: string, name: string, content: string) => {
    create({
      variables: {
        type,
        name,
        content
      }
    })
  }

  useEffect(() => {
    if (!data) {
      return
    }
    const sr = data?.message?.searchResult
    setSearchResult(sr)
    setQuery(sr?.query || "")
    setOffset(sr?.offset || 0)
    setWaitForData(false)

    if (isLast) {
      completeLoadingCallback(message.id)
    }
  }, [data])

  useEffect(() => {
    if (message.obsolete) {
      return
    }
    setWaitForData(true)
    getSearchResult({
      variables: {
        count,
        messageId: message.id,
        offset: offset,
        type: selectedType,
        query
      }
    })
  }, [selectedType, query, offset])

  const delimiter = 40

  const searchResults = () => (
    <div className={cn(styles.searchResults)}>
      <WaitPreloader
        type={selectedType}
        waiting={
          (selectedType === "videos" && data?.message?.searchResult?.videos?.length === 0) ||
          (selectedType === "images" && data?.message?.searchResult?.images?.length === 0) ||
          (selectedType === "news" && data?.message?.searchResult?.news?.length === 0) ||
          (selectedType === "web" && data?.message?.searchResult?.web?.webResult?.length === 0) ||
          waitForData
        }
      />

      {searchResult && (
        <>
          {selectedType === "web" &&
            !waitForData &&
            renderWebResult(searchResult, setQuery, setSelectedType, message.searchTerm || "")}
          {selectedType === "images" &&
            !waitForData &&
            renderImages(searchResult, message.searchTerm || "", createAppSpace)}
          {selectedType === "videos" && !waitForData && renderVideos(searchResult)}
          {selectedType === "news" && !waitForData && renderNews(searchResult)}
        </>
      )}
    </div>
  )

  const dataPanels = (inline?: boolean) => (
    <>
      <div className={cn(styles.resources, inline ? styles.inline : "", "hide-m")}>
        {searchResult && (
          <>
            {searchResult?.type === "web" && !inline && (
              <DataPanel
                waitForData={waitForData}
                type="IMAGES"
                searchResult={searchResult}
                searchTerm={message.searchTerm || ""}
              />
            )}

            <WikipediaSearchResult waitForData={waitForData} messageId={message.id} query={query} />
            {searchResult?.type === "web" && !inline && (
              <DataPanel
                waitForData={waitForData}
                type="VIDEOS"
                searchResult={searchResult}
                searchTerm={message.searchTerm || ""}
              />
            )}
            {searchResult?.type === "web" && !inline && (
              <DataPanel
                waitForData={waitForData}
                type="NEWS"
                searchResult={searchResult}
                searchTerm={message.searchTerm || ""}
              />
            )}
          </>
        )}
      </div>
    </>
  )

  const inline = data?.activeAppSpace && chatWidth < delimiter

  const renderSearchModeSelect = (
    title: string,
    id: string,
    selectedMode: string,
    setSelectedMode: (s: string) => void
  ) => {
    return (
      <span
        onClick={() => setSelectedMode(id)}
        className={cn([fileListing.previewType, selectedMode === id ? fileListing.active : null])}
      >
        {title}
      </span>
    )
  }

  return (
    <SearchResultContext.Provider value={{ setSelectedType }}>
      <div className={cn([styles.root, isLast ? styles.isLast : null])}>
        <div className={fileListing.filePreviewType} style={{ float: "initial", marginBottom: 10 }}>
          {renderSearchModeSelect("Web", "web", selectedType, setSelectedType)}
          {renderSearchModeSelect("Images", "images", selectedType, setSelectedType)}
          {renderSearchModeSelect("Videos", "videos", selectedType, setSelectedType)}
          {renderSearchModeSelect("News", "news", selectedType, setSelectedType)}
        </div>
        {/* {searchResultRefiner(!(activeAppSpace && !maximizeChat))} */}
        {!searchResult && !query && (
          <div className={styles.emptyMessage}>
            <i className="material-icons">search</i>
            <span>Waiting for your search...</span>
          </div>
        )}
        <div
          className={cn(
            styles.searchResultSplit,
            data?.activeAppSpace && !maximizeChat ? styles.appSpace : null,
            inline ? styles.inline : ""
          )}
        >
          <>
            {searchResults()}
            {!(data?.activeAppSpace && selectedType !== "web") && dataPanels(inline === true)}
          </>
        </div>
        {searchResult && (
          <>
            {pages_count(searchResult.totalCount, searchResult.count) > 1 && (
              <SearchPagination
                currentPage={searchResult.offset < 10 ? 1 : Math.floor(searchResult.offset / 10)}
                goto={(page) => {
                  setOffset(page * count)
                }}
                total={pages_count(searchResult.totalCount, searchResult.count)}
              />
            )}
          </>
        )}
      </div>
    </SearchResultContext.Provider>
  )
}

export default SearchResult
