/* eslint-disable */
import React, { useContext, useEffect, useState } from "react"
import { FileListing_FileListingFragment, Message_MessageFragment, useSearchFileListingQuery } from "generated/graphql"
import SearchResult from "../searchResult"
import FileListing from "../fileListing"
import styles from "./SearchFileListing.module.scss"
import cn from "clsx"
import SearchControl from "../fileListing/components/searchControl"
import { useNonInitialEffect } from "utils/hooks"
import tableResult from "../tableResult/TableResult.module.scss"
import SplitterContext from "modules/shared/components/splitterContext"
import { handleGraphQlError } from "utils/common"

function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500)

    return () => {
      clearTimeout(timer)
    }
  }, [value, delay])

  return debouncedValue
}

const SearchFileListing: React.FC<{
  message: Message_MessageFragment
  isLast: boolean
  setFileListingSession: any
}> = ({ message, isLast, setFileListingSession }) => {
  const [query, setQuery] = useState(message.fileListing?.query || message.searchResult?.query || "")
  const [mode, setMode] = useState(message.type === "searchResult" ? "web" : "files")
  const { data } = useSearchFileListingQuery({
    onError: handleGraphQlError("Unabel to get file listing")
  })
  const [overrideMode, setOverrideMode] = useState<string>()

  const { toggleMaximizeChat, maximizeChat } = useContext(SplitterContext)

  const debouncedQuery = useDebounce<string>(query, 500)

  useEffect(() => {
    if (message?.fileListing) setFileListingSession(true)
  }, [message])

  useNonInitialEffect(() => {
    if (["web"].includes(mode)) {
      setOverrideMode("web")
    }

    if (["files", "sessions"].includes(mode)) {
      setOverrideMode("files")
    }
  }, [mode])

  // if (!data) return null

  const activeAppSpace = data?.activeAppSpace

  return (
    <div style={{ width: "800px" }}>
      <div style={{ width: "800px" }} className={cn([styles.topFileListingBar, styles.files])}>
        <div style={{ display: "flex", justifyContent: "space-between" }} className={cn(styles.modes)}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span className={cn(mode === "files" ? styles.active : null, styles.mode)} onClick={() => setMode("files")}>
              <i className="material-icons">folder</i>
              <span>Files</span>
            </span>
            <span
              className={cn(mode === "sessions" ? styles.active : null, styles.mode)}
              onClick={() => setMode("sessions")}
            >
              <i className="material-icons">grid_view</i>
              <span>Sessions</span>
            </span>
            <span
              className={cn(["web", "images", "videos", "news"].includes(mode) ? styles.active : null, styles.mode)}
              onClick={() => setMode("web")}
            >
              <i className="material-icons">language</i>
              <span>Web</span>
            </span>
          </div>
          <div
            style={{ position: activeAppSpace ? "relative" : undefined, left: activeAppSpace ? "-90px" : undefined }}
          >
            <SearchControl mode={mode} query={query} setQuery={setQuery} />
          </div>
        </div>
      </div>

      {(overrideMode ? overrideMode === "web" : message.type === "searchResult") && (
        <SearchResult
          selectedType={mode}
          setSelectedType={setMode}
          query={debouncedQuery}
          setQuery={setQuery}
          message={message}
          isLast={isLast}
        />
      )}
      {(overrideMode ? overrideMode === "files" : message.type === "fileListing" && message.fileListing) && (
        <FileListing
          listingMode={mode}
          setListingMode={setMode}
          query={debouncedQuery}
          setQuery={setQuery}
          message={message}
          fileListing={message.fileListing as FileListing_FileListingFragment}
          isLast={isLast}
        />
      )}
    </div>
  )
}

export default SearchFileListing
