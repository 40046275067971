import React from "react"
import spinner from "../../styles/components/spinner.module.scss"
import cn from "clsx"

const QueryPreloader: React.FC = () => {
  return null
  return (
    <div className={spinner.spinnerFlex}>
      <div className={cn(spinner.spinner, spinner.small)}></div>
      <span>Please wait...</span>
    </div>
  )
}

export default QueryPreloader
