/* eslint-disable */
import React, { createContext, useContext, useEffect, useRef, useState } from "react"
import cn from "clsx"
import { currentSession, formatNumberToK, handleGraphQlError } from "utils/common"
import {
  Sidebar_UerInfoQuery,
  useChatQuery,
  useSessions_SelectSessionMutation,
  usePeople_SelectOrganizationMutation,
  useSessions_CreateSessionMutation,
  useSidebar_GroupUpdatedSubscription,
  useSidebar_SignOutMutation,
  useSidebar_UerInfoQuery,
  useAppSpaces_CloseAppSpaceMutation,
  useSidebarChannelsQuery,
  useAppSpaces_CreateAppSpaceMutation,
  useOrganization_OrganizationQuery,
  useChat_ChatMutation
} from "generated/graphql"
import styles from "./Sidebar.module.scss"
import button from "modules/shared/styles/components/button.module.scss"
import LazyImage from "modules/shared/components/lazyImage"
import SidebarChannels from "./components/sidebarChannels"
import SidebarDMs from "./components/sidebarDMs"
import Tooltip from "modules/shared/components/tooltip"
import { recentTaskBoard, recentWiki } from "utils/common"
import pubsub from "pubsub-js"

import { useHistory } from "react-router-dom"
import { routes, topics } from "consts"
import ProfilePicture from "modules/shared/components/profilePicture"
import { unsubscribeFromNotifictions } from "../root/notificationRegistration"
import SidebarUnreadComments from "./components/sidebarUnreadComments"
import NotificationView from "modules/appSpace/components/notificationView"
import { isMobile } from "react-device-detect"
import { currentTab, newTab, setActiveTab, updateTab, viewTabs } from "../sessionTabs/SessionTabs"
import Chat from "modules/chat/components/chat/Chat"
import { asyncListing } from "modules/chat/components/message/Message"

let goOnce = false
let overrideLoad = false

const getInitials = (fullName: string) => {
  if (!fullName.includes(" ")) {
    return fullName
  }
  const [firstName] = fullName.split(" ")
  return `${firstName}`
}

export const SidebarContext = createContext<{
  mobile: boolean
  collapsed: boolean
  setCollapsed: (b: boolean) => void
  goto: (message: string, push?: boolean) => void
  selectOrganization: (options: any) => void
  handleLogout: () => void
  handleSignup: () => void
}>({} as any)

const Sidebar: React.FC<{ mobile: boolean }> = ({ mobile }) => {
  const [showExpanded, setShowExpanded] = useState("")
  const [selectedFolder, setSelectedFolder] = useState<string>("")
  const [fileDefault, setFileDefault] = useState<string>("")

  const [channelUnreads, setChannelUnreads] = useState(false)
  const [directUnreads, setDirectUnreads] = useState(false)

  const { data: me, refetch } = useSidebar_UerInfoQuery({
    onError: handleGraphQlError("Unable to get user info")
  })
  const { data: org } = useOrganization_OrganizationQuery({
    onError: handleGraphQlError("Unable to get organizations")
  })
  const [selectOrganization] = usePeople_SelectOrganizationMutation({
    onError: handleGraphQlError("Unable to select organization")
  })
  const [signOut] = useSidebar_SignOutMutation({
    onError: handleGraphQlError("Unable to sign out")
  })
  const [createSession] = useSessions_CreateSessionMutation({
    onError: handleGraphQlError("Unable to create session")
  })
  const [selectSession] = useSessions_SelectSessionMutation({
    onError: handleGraphQlError("Unable to select session")
  })
  const { data: directs } = useSidebarChannelsQuery({
    onError: handleGraphQlError("Unable to get message")
  })
  const [createAppSpace] = useAppSpaces_CreateAppSpaceMutation({
    onError: handleGraphQlError("Unable to create app space")
  })
  const [callChat] = useChat_ChatMutation({
    onError: handleGraphQlError("Unable to send the message")
  })
  const [closeAppSpace] = useAppSpaces_CloseAppSpaceMutation({})
  const chatQuery = useChatQuery({
    variables: {},
    onError: handleGraphQlError("Unable to get chat history")
  })
  useSidebar_GroupUpdatedSubscription({
    variables: {
      session: me?.activeSession.id || ""
    },
    skip: !me?.activeSession
  })

  useEffect(() => {
    callChat({
      variables: {
        message: "files",
        silent: false,
        type: undefined,
        richtext: false,
        attachments: []
      }
    })

    setFileDefault(me?.activeSession.id || "")
  }, [me])

  const history = useHistory()
  const organization = org?.organization
  const updateApps = ["OpenAI", "Microsoft Azure", "Amazon", "Heroku", "Spotify", "Heroku", "Quora", "Patreon"]
  const demoContacts = ["Samuel Richards", "Patricia Zhang", "Ed Cunningham", "Bill Riker", "Cassandra Ryan"]
  const dms = directs?.groups.filter((g: any) => g.type === "dm" || g.type === "1-1")

  useEffect(() => {
    const token = pubsub.subscribe(topics.SIDEBAR_UPDATE_PROFILE, () => {
      refetch()
    })
    return () => {
      pubsub.unsubscribe(token)
    }
  }, [])

  const publishMessage = (message: string, type?: string) => {
    pubsub.publish(topics.SIDEBAR_ACTION, { message, type })
  }

  const handleLogout = async () => {
    await unsubscribeFromNotifictions()
    await signOut()
    history.push(routes.signin)
  }

  const handleSignup = async () => {
    history.push(routes.register)
  }

  const goTo = (message: string, push?: boolean, type?: string) => () => {
    // if (mobile) {
    //   setExpanded(false)
    // }

    if (push) {
      history.push(message)
    } else {
      if (history.location.pathname !== "/chat") {
        history.push("/chat")

        setTimeout(() => {
          publishMessage(message, type)
        }, 500)

        return
      }

      publishMessage(message, type)
    }
  }

  function doesClassExist(className: string) {
    return document.getElementsByClassName(className).length > 0
  }

  const chatCmd = (cmd: string, creatingMode?: boolean) => {
    // TODO COHEN: createSession.then => use ID from new Pretzel session
    const useId = Date.now().toString()
    const originRoot = window.location.href.slice(0, -1) === window.location.origin

    if (
      (directs?.activeSession.groupId === null && originRoot) ||
      (directs?.activeSession.groupId === null && creatingMode && doesClassExist("newestFileListing"))
    ) {
      const useActive = currentTab
      updateTab(useActive.id, "location", routes.chat)
      updateTab(useActive.id, "type", "pretzel:" + useActive?.type?.split(":")[1])

      // Is this causing the failed blank session?
      // if (creatingMode && currentSession("/chat") && doesClassExist("newestFileListing")) return

      history.push(routes.chat)
      callChat({
        variables: {
          message: cmd,
          silent: false,
          type: undefined,
          richtext: false,
          attachments: []
        }
      })

      return
    }

    // Create new tab, and write to it
    newTab({ name: "", id: useId, type: "", icon: "default", location: routes.chat, active: true, forceOpen: true })
    createSession({}).then((res: any) => {
      const backendSession = res?.data?.createSession.activeSession.id
      updateTab(useId, "type", "pretzel:" + backendSession)
      if (backendSession) {
        selectSession({ variables: { id: backendSession } }).then(() => {
          if (history.location.pathname !== "/chat") {
            history.push("/chat")
          }
          callChat({
            variables: {
              message: cmd,
              silent: false,
              type: undefined,
              richtext: false,
              attachments: []
            }
          })
        })
      }
    })
  }

  useEffect(() => {
    const token = pubsub.subscribe(topics.OPEN_CREATE_NEW_DOCUMENT, (_: any, folderId: string) => {
      setSelectedFolder(folderId)
    })

    return () => {
      pubsub.unsubscribe(token)
    }
  }, [])

  const sidebarLoading = useRef<HTMLDivElement>(null)
  const checkLoaded = setInterval(() => {
    if (Object.keys(asyncListing).length === 0 && !overrideLoad) {
      if (sidebarLoading.current) {
        sidebarLoading.current.style.pointerEvents = "none"
        sidebarLoading.current.style.opacity = "0.5"
      }
    } else {
      if (sidebarLoading.current) {
        sidebarLoading.current.style.pointerEvents = "auto"
        sidebarLoading.current.style.opacity = "1"
      }
      clearInterval(checkLoaded)
      if (goOnce) return
      const tempStore = chatQuery.data?.activeSession.id
      if (tempStore) {
        goOnce = true
        newTab({ name: "", id: "", type: "", icon: "default", location: routes.home, active: true, forceOpen: true })
      }
    }
  }, 50)

  useEffect(() => {
    setTimeout(() => (overrideLoad = true), 10000)
  }, [])

  useEffect(() => {
    setShowExpanded("")
  }, [history.location.pathname])

  const SidebarButton: React.FC<{ icon: string; action: () => void; notice?: boolean; expandWith?: any }> = ({
    icon,
    action,
    notice,
    expandWith
  }) => {
    const extensionRef = useRef<HTMLDivElement>(null)
    return (
      <>
        <div
          onMouseOver={() => setShowExpanded(icon)}
          onClick={() => {
            extensionRef.current && extensionRef.current.classList.toggle(styles.expandListActive)
            action()
          }}
          className={styles.button}
        >
          {notice ? (
            <div>
              <i className="material-icons">{icon}</i>
              <div
                style={{
                  width: "1.3rem",
                  height: "1.3rem",
                  borderRadius: "100rem",
                  backgroundColor: "tomato",
                  margin: "-42px 0 0 2.5rem",
                  position: "absolute",
                  border: "2px solid white"
                }}
              ></div>
            </div>
          ) : (
            <i className="material-icons">{icon}</i>
          )}
        </div>

        <div
          ref={extensionRef}
          style={{ marginTop: showExpanded === "email" ? "-350px" : "-125px" }}
          className={styles.expandList}
        >
          <i
            className="material-icons"
            style={{ transform: "rotate(90deg)", fontSize: "4rem", marginRight: "-1.8rem", color: "#F4F5F6" }}
          >
            arrow_drop_down
          </i>
          <div className={styles.expandListPopup}>{expandWith}</div>
        </div>
      </>
    )
  }

  const SidebarItem: React.FC<{ name: string; icon: string; action: () => void }> = ({ name, icon, action }) => {
    return (
      <div id={icon === "folder" ? "sidebarFiles" : undefined} onClick={action} className={styles.item}>
        <i className="material-icons">{icon}</i>
        <span
          style={{
            marginTop: "6px",
            color: "black",
            display: "block"
          }}
        >
          {name}
        </span>
      </div>
    )
  }

  return (
    <>
      <SidebarContext.Provider
        value={{
          collapsed: false,
          mobile,
          setCollapsed: (b) => {},
          goto: goTo,
          handleLogout: handleLogout,
          selectOrganization: selectOrganization,
          handleSignup: handleSignup
        }}
      >
        {/* Preload the file listing to local variable */}
        <div style={{ display: "none" }}>
          <Chat />
        </div>
        <div ref={sidebarLoading} className={cn([styles.root])}>
          <SidebarButton
            icon="domain"
            action={() => {
              newTab({ name: "Org", id: "", icon: "default", location: "/people", active: true, forceOpen: true })
            }}
          />

          <div className={styles.segment}></div>
          <SidebarItem
            name="Dashboard"
            icon="dashboard"
            action={() => {
              newTab({
                name: "Feeds",
                id: "",
                icon: "default",
                location: routes.dashboard,
                active: true,
                forceOpen: true
              })
            }}
          />
          <SidebarItem
            name="Files"
            icon="folder"
            action={() => {
              // selectSession({ variables: { id: fileDefault } }).then(() => {
              const useActive = currentTab
              updateTab(useActive.id, "location", routes.chat)
              updateTab(useActive.id, "type", "pretzel:" + fileDefault)
              history.push(routes.chat)
              // callChat({
              //   variables: {
              //     message: "files",
              //     silent: false,
              //     type: undefined,
              //     richtext: false,
              //     attachments: []
              //   }
              // })
              // })
            }}
          />
          <SidebarItem
            name="Tasks"
            icon="task"
            action={() => {
              const ready = "Tasks"
              const containsSession = viewTabs.some((tab: { session: { name: string } }) => tab.session.name === ready)
              if (!containsSession)
                newTab({
                  name: ready,
                  id: "",
                  icon: ready.toLocaleLowerCase(),
                  location: me?.organization.name === "Pretzel startup" ? "/tasks/Mj8MWttf" : recentTaskBoard(),
                  active: true,
                  forceOpen: true
                })
              else {
                const existing = viewTabs.find((tab: { session: { name: string } }) => tab.session.name === ready)
                if (existing) {
                  setActiveTab(existing.session.id)
                  history.push(existing.session.location)
                }
              }
            }}
          />
          <SidebarItem
            name="Wikis"
            icon="auto_stories"
            action={() => {
              const ready = "Wikis"
              const containsSession = viewTabs.some((tab: { session: { name: string } }) => tab.session.name === ready)
              if (!containsSession)
                newTab({
                  name: ready,
                  id: "",
                  icon: ready.toLocaleLowerCase(),
                  location: recentWiki(),
                  active: true,
                  forceOpen: true
                })
              else {
                const existing = viewTabs.find((tab: { session: { name: string } }) => tab.session.name === ready)
                if (existing) {
                  setActiveTab(existing.session.id)
                  history.push(existing.session.location)
                }
              }
            }}
          />

          <div className={styles.segment}></div>

          <SidebarButton
            icon="groups"
            action={() => {}}
            notice={channelUnreads}
            expandWith={<SidebarChannels setChannelUnreads={setChannelUnreads} />}
          />
          <SidebarButton
            icon="group"
            action={() => {}}
            notice={directUnreads}
            expandWith={<SidebarDMs setDirectUnreads={setDirectUnreads} />}
          />
          <SidebarButton
            icon="email"
            action={() => {}}
            expandWith={
              <div className={styles.selectChats}>
                <div className={styles.heading}>
                  <span className={styles.title}>Org</span>
                </div>
                {organization?.members
                  .slice()
                  .sort((x: any) => (x.role !== "owner" ? 1 : -1))
                  .map((member: any, i: any) => (
                    <div
                      className={styles.item}
                      onClick={() => {
                        // pubsub.publish(topics.OPEN_PROFILE, member.user.id)
                        localStorage.webMail_user = member.user.fullName
                        newTab({
                          name: member.user.fullName + "@gmail.com",
                          id: "",
                          type: "",
                          icon: "chat",
                          active: true,
                          location: "/mail",
                          forceOpen: true
                        })
                      }}
                      key={i}
                    >
                      {member.user.fullName}
                    </div>
                  ))}
                <br />
                <br />
                <div className={styles.heading}>
                  <span className={styles.title}>Contacts</span>
                </div>
                {demoContacts.map((dm, i) => (
                  <div className={styles.item} key={i}>
                    {dm}
                  </div>
                ))}
                <br />
                <br />

                <div className={styles.heading}>
                  <span className={styles.title}>Groups</span>
                </div>

                <p className={styles.item}>Mom & Dad</p>

                <div className={styles.heading}>
                  <span className={styles.title}>Updates</span>
                </div>

                {updateApps.map((name, i) => (
                  <div
                    onClick={() => {
                      newTab({
                        name: "App Store",
                        id: "",
                        icon: "default",
                        location: "/apps",
                        active: true,
                        forceOpen: true
                      })
                    }}
                    key={i}
                    className={styles.item}
                  >
                    {name}
                  </div>
                ))}
              </div>
            }
          />

          <div className={styles.segment}></div>

          <SidebarButton
            icon="more_horiz"
            action={() => {}}
            expandWith={
              <div style={{ display: "flex", gap: "16px", alignItems: "center", padding: "1rem 1rem 0 1rem" }}>
                <SidebarItem
                  name="Notes"
                  icon="format_list_bulleted"
                  action={() => {
                    newTab({
                      name: "Notes",
                      id: "",
                      icon: "default",
                      location: routes.notes,
                      active: true,
                      forceOpen: true
                    })
                  }}
                />

                <SidebarItem
                  name="App Store"
                  icon="apps"
                  action={() => {
                    newTab({
                      name: "App Store",
                      id: "",
                      icon: "default",
                      location: "/apps",
                      active: true,
                      forceOpen: true
                    })
                  }}
                />

                <SidebarItem
                  name="Calendar"
                  icon="calendar_month"
                  action={() => {
                    newTab({
                      name: "Calendar",
                      id: "",
                      icon: "default",
                      location: "/calendar",
                      active: true,
                      forceOpen: true
                    })
                  }}
                />

                <SidebarItem
                  name="Favs"
                  icon="bookmark"
                  action={() => {
                    chatCmd("bookmarks")
                  }}
                />
              </div>
            }
          />
          {me && (
            <>
              <img
                className={styles.profile}
                onClick={() => {
                  pubsub.publish(topics.OPEN_PROFILE, me.me?.id || "")
                }}
                src={me.me?.profilePicture || ""}
              />

              <span>{getInitials(me.me?.fullName || "")}</span>
            </>
          )}
          {/* <SidebarUnreadComments /> */}
        </div>
      </SidebarContext.Provider>
    </>
  )
}

export default Sidebar
