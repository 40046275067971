import styles from "./MessageAttachments.module.scss"
import { fullscreenIcon } from "modules/shared/components/icons"
let modal: HTMLDivElement
let image: HTMLImageElement
let fullscreen: HTMLDivElement

const fullscreenButton = () => {
  fullscreen.style.top = image.getBoundingClientRect().top + 10 + "px"
  fullscreen.style.left = image.getBoundingClientRect().left + 10 + "px"
}
const toggleFullscreen = () => {
  if (!document.fullscreenElement) {
    document.body.requestFullscreen()
    modal.classList.add(styles.inFullScreen)
    image.classList.add(styles.inFullScreen)
  } else {
    document.exitFullscreen()
    modal.classList.remove(styles.inFullScreen)
    image.classList.remove(styles.inFullScreen)
    document.location.reload()
  }
}
const isFileImage = ({ fileName }: { fileName: string }) => {
  fileName = fileName.split("/").pop() || "false"
  const imagesExtension = ["png", "jpg", "jpeg", "gif", "bmp", "webp"]
  if (imagesExtension.indexOf(fileName) !== -1) return true
  else return false
}
export const PopupModal = function (img: string, format: string): void {
  if (!isFileImage({ fileName: format })) {
    window.open(img, "_blank")
    return
  }
  ;[modal, image] = [document.createElement("div"), document.createElement("img")]
  modal.className = styles.modal
  image.src = img
  image.onload = () => image.classList.add(styles.loaded)
  image.onmouseover = () => fullscreen.classList.add(styles.fullscreen)
  image.onmouseout = () => fullscreen.classList.remove(styles.fullscreen)

  fullscreen = document.createElement("div")
  fullscreen.className = styles.fullscreenButton
  fullscreen.innerHTML = fullscreenIcon
  fullscreen.onclick = () => toggleFullscreen()

  document.body.appendChild(modal)
  modal.appendChild(image)
  document.body.appendChild(fullscreen)

  modal.onclick = () => {
    image.classList.remove(styles.loaded)
    setTimeout(() => {
      document.body.removeChild(modal)
      document.body.removeChild(fullscreen)
      if (document.fullscreenElement) {
        document.exitFullscreen()
        document.location.reload()
      }
    }, 200)
  }
  setInterval(fullscreenButton, 10)
}
