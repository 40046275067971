import React, { useContext, useState } from "react"
import { FilePreviewView_FileFragment as File } from "generated/graphql"
import fileStyles from "../fileFolderTableView/FileFolderTableView.module.scss"
import { fileTypeToColor } from "../../utils/fileTypeToColor"
import { humanizeFileSize } from "utils/common"
import styles from "./FilePreviewView.module.scss"
import cn from "clsx"
import EmptyFilesWarning from "../emptyFilesWarning"
import { FileListingContext } from "../fileListing/FileListing"
import spinner from "modules/shared/styles/components/spinner.module.scss"

export const LazyPreviewImage: React.FC<{ src: string; alt: string }> = ({ src, alt }) => {
  const [loaded, setLoaded] = useState(false)
  const [_src, setSrc] = useState(src)

  const reloadLater = () => {
    setTimeout(() => {
      setSrc(src + "&s=" + new Date().getTime())
    }, 4000)
  }

  return (
    <div className={styles.previewRoot}>
      {_src !== "pending" && (
        <>
          <img
            onLoad={() => setLoaded(true)}
            className={cn([loaded ? styles.loaded : null])}
            src={_src}
            alt={alt}
            onError={reloadLater}
          />
          <div className={cn([loaded ? styles.loaded : null, styles.spinner, spinner.spinner, spinner.small])}></div>
        </>
      )}
      {_src === "pending" && <div className={styles.previewPending}>Generating preview...</div>}
    </div>
  )
}

const FilePreviewView: React.FC<{
  files: File[]
}> = ({ files }) => {
  const { loading, openAppSpace } = useContext(FileListingContext)

  return (
    <div>
      {files.length > 0 && (
        <>
          {files.map((file) => {
            if (file.type === "taskBoard") return <></>
            return (
              <div key={file.id} className={styles.root} onClick={() => openAppSpace(file)}>
                <div className={cn(fileStyles.filename)}>
                  <div className={fileTypeToColor(file.type)} />
                  <span className={styles.fileMeta}>
                    <span className={styles.filename}>{file.name}</span>
                    <span className={styles.filesize}>{humanizeFileSize(file.size)}</span>
                    <span className={styles.filesize}>{file.type.toUpperCase()}</span>
                  </span>
                </div>
                <div className={styles.thumbnail}>
                  {file.previewUrl && !["other"].includes(file.type) && (
                    <LazyPreviewImage src={file.previewUrl} alt="Preview" />
                  )}

                  {!file.previewUrl ||
                    (file.type === "other" && (
                      <div className={cn(styles.noPreview, styles[file.type])}>
                        <i className="material-icons">info_outline</i>
                        <span>No preview available</span>
                      </div>
                    ))}
                </div>
              </div>
            )
          })}
        </>
      )}
      {files.length === 0 && !loading && (
        <table className={styles.fullWidth}>
          <tbody>
            <EmptyFilesWarning />
          </tbody>
        </table>
      )}
    </div>
  )
}

export default FilePreviewView
