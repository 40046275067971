/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from "react"
import styles from "modules/shared/styles/components/popupPanel.module.scss"
import PopupButton from "modules/shared/components/popupButton"
import cn from "clsx"
import input from "modules/shared/styles/components/input.module.scss"
import button from "modules/shared/styles/components/button.module.scss"
import spinner from "modules/shared/styles/components/spinner.module.scss"

import {
  useSaveButton_CreateAppSpaceFromImageMutation,
  useSaveButton_CreateAppSpaceFromUrlMutation,
  useSaveButton_CreateAppSpaceFromContentMutation
} from "generated/graphql"
import { KeyCodes } from "consts"
import { handleGraphQlError } from "utils/common"

const ImageSaveButton: React.FC<{
  type: string
  url?: string
  onSaved?: () => void
  name?: string
  from?: string
  advanced?: boolean,
  children?: React.ReactNode
}> = ({ children, type, url, onSaved, name, from, advanced }) => {
  const [createAppSpaceFromImage, { loading: loadingFromImage }] = useSaveButton_CreateAppSpaceFromImageMutation({
    onError: handleGraphQlError("Unable to create app space")
  })
  const [createAppSpaceFromUrl, { loading: loadingFromUrl }] = useSaveButton_CreateAppSpaceFromUrlMutation({
    onError: handleGraphQlError("Unable to create app space")
  })
  const [createAppSpaceFromContent, { loading: loadingFromContent }] = useSaveButton_CreateAppSpaceFromContentMutation({
    onError: handleGraphQlError("Unable to create app space")
  })

  const [title, setTitle] = useState(name)
  useEffect(() => {
    setTitle(name || "")
  }, [name])
  const loading = loadingFromImage || loadingFromUrl || loadingFromContent

  function handleSave(close?: () => unknown | null) {
    const update = () => {
      if (close) close()

      if (onSaved) {
        onSaved()
      }
    }

    if (type === "image") {
      createAppSpaceFromImage({
        variables: {
          url: url || "",
          name: title || "Image"
        },
        update
      })
    } else if (type === "contentFromUrl") {
      createAppSpaceFromUrl({
        variables: {
          url: url || "",
          name: title || name
        },
        update
      })
    } else if (type === "content") {
      createAppSpaceFromContent({
        variables: {
          from: from || "",
          name: title || name
        },
        update
      })
    }
  }

  const hanndleKeyDown = async (e: { keyCode: number }, close: () => unknown) => {
    if (e.keyCode === KeyCodes.Enter) {
      handleSave(close)
    }
  }

  if (!url && !from) {
    return null
  }

  const getContent = (close: () => void) => {
    const isContent = type === "contentFromUrl" || type === "content"

    return (
      <div className={styles.popupRoot}>
        <div className={styles.popupTitle}>
          <i className="fa fa-save"></i>

          <p>{isContent ? "Save Content" : "Save Image"}</p>
          <span className={cn("material-icons", styles.popupClose)} onClick={close}>
            close
          </span>
        </div>

        <div className={styles.popupForm}>
          <label>{isContent ? "Name" : "Image Name"}</label>
          <input
            type="text"
            placeholder="Page Title"
            defaultValue={"Image"}
            required
            disabled={loading}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className={cn(input.input, input.block)}
            onKeyDown={(e) => hanndleKeyDown(e, close)}
          />

          {!loading && (
            <button
              className={cn([
                button.button,
                button.primary,
                button.big,
                button.lowAnimation,
                loading ? button.loadingButton : ""
              ])}
              onClick={() => handleSave(close)}
              onKeyDown={(e) => hanndleKeyDown(e, close)}
            >
              {isContent ? "Save" : "Save Image"}
            </button>
          )}

          {loading && (
            <button
              className={cn([
                button.button,
                button.primary,
                button.big,
                button.lowAnimation,
                loading ? button.loadingButton : ""
              ])}
            >
              <div className={cn(spinner.spinner, spinner.small)}></div>
            </button>
          )}
        </div>
      </div>
    )
  }

  if (advanced) {
    return <PopupButton trigger={<div className={styles.root}>{children}</div>} content={getContent} />
  }

  return (
    <div className={styles.root}>
      {!loading && (
        <div
          onClick={(ev) => {
            handleSave()
            ev.preventDefault()
            ev.stopPropagation()
          }}
        >
          {!loading && children}
        </div>
      )}
      {loading && <div className={styles.loading}>{children}</div>}
    </div>
  )
}

export default ImageSaveButton
