import React, { useRef, useState } from "react"
import cn from "clsx"
import styles from "./EditorHeader.module.scss"
import { handleGraphQlError, textEllipsis } from "utils/common"
import { KeyCodes, topics } from "consts"
import { useEditorHandlerQuery, useAppSpaces_RenameAppSpaceMutation } from "generated/graphql"
import pubsub from "pubsub-js"
import { useDependentState } from "utils/hooks"

const EditorHeader: React.FC<{ centerMenu?: JSX.Element; rightMenu?: JSX.Element }> = ({ centerMenu, rightMenu }) => {
  const { data } = useEditorHandlerQuery({
    onError: handleGraphQlError("Unable to get data")
  })
  const [isRenaming, setIsRenaming] = useState(false)
  const [appSpaceName, setAppSpaceName] = useDependentState(data?.activeAppSpace?.name || "Document")
  const input = useRef<HTMLInputElement>(null)
  const [renameAppSpace] = useAppSpaces_RenameAppSpaceMutation({
    onError: handleGraphQlError("Unable to rename app space")
  })

  if (!data) {
    return null
  }

  const appSpace = data.activeAppSpace

  if (!appSpace) {
    return null
  }

  // //if appSpaceName is "Document" or any other values different than appSpace.name then rename it as appSpace.name using mutation function
  const updateAppSpaceName = async () => {
    if (appSpace) {
      if (appSpaceName && appSpaceName !== appSpace.name) {
        await renameAppSpace({
          variables: { newName: appSpaceName, id: appSpace.id }
        })
        pubsub.publish(topics.UPDATE_FILES)
      }
    }
  }

  // //When user input new name and hit enter, then call updateAppSpaceName function
  const handleInputKeyDown = (e: { keyCode: number }) => {
    if (e.keyCode === KeyCodes.Enter) {
      updateAppSpaceName()
      setIsRenaming(false)
    }
  }

  const handleInputBlur = () => {
    updateAppSpaceName()
    setIsRenaming(false)
  }

  return (
    <div className={styles.editorTopbar}>
      <div className={styles.left}>
        {isRenaming && (
          <input
            onKeyUp={handleInputKeyDown}
            ref={input}
            className={styles.nameInput}
            maxLength={50}
            onBlur={handleInputBlur}
            value={appSpaceName}
            onChange={(e) => setAppSpaceName(e.target.value)}
          />
        )}
        {!isRenaming && (
          <span
            onClick={() => {
              setIsRenaming(true)
              setTimeout(() => {
                input.current?.select()
              }, 1)
            }}
            className={styles.filename}
          >
            {textEllipsis(appSpaceName, 50)}
          </span>
        )}
      </div>

      {centerMenu && <div className={cn(["hide-m", styles.menu])}>{centerMenu}</div>}
      {rightMenu && <div className={styles.right}>{rightMenu}</div>}
    </div>
  )
}

export default EditorHeader
