import React from "react"
import styles from "./WaitPreloader.module.scss"
import cn from "clsx"
import _ from "lodash"

import shimmer from "modules/shared/styles/components/shimmer.module.scss"

const ImageCards: React.FC = () => {
  return (
    <div className={styles.waitItemContainer}>
      <div className={cn(styles.cardTitle, shimmer.shimmer)}></div>

      <div className={styles.cards}>
        <div className={cn(styles.cardContent, shimmer.shimmer)}></div>
        <div className={cn(styles.cardContent, shimmer.shimmer)}></div>
        <div className={cn(styles.cardContent, shimmer.shimmer)}></div>
        <div className={cn(styles.cardContent, shimmer.shimmer)}></div>
      </div>

      <div className={cn(styles.cardMore, shimmer.shimmer)}></div>
    </div>
  )
}

const NewsCards: React.FC = () => {
  const height1 = Math.floor(Math.random() * (50 - 35 + 1) + 35)
  const height2 = Math.floor(Math.random() * (50 - 35 + 1) + 35)

  return (
    <div className={styles.waitItemContainer}>
      <div className={cn(styles.cardTitle, shimmer.shimmer)}></div>

      <div className={styles.newsCards}>
        <div style={{ height: height1 }} className={cn(styles.cardContent, shimmer.shimmer)}></div>
        <div style={{ height: height2 }} className={cn(styles.cardContent, shimmer.shimmer)}></div>
      </div>

      <div className={cn(styles.cardMore, shimmer.shimmer)}></div>
    </div>
  )
}

const WebLink: React.FC = () => {
  const descriptionHeight = Math.floor(Math.random() * (50 - 25 + 1) + 25)
  const descriptionWidth = Math.floor(Math.random() * (700 - 350 + 1) + 350)
  const titleWidth = Math.floor(Math.random() * (350 - 100 + 1) + 100)
  const urlWidth = Math.floor(Math.random() * (400 - 150 + 1) + 150)

  return (
    <div className={styles.waitItemContainer}>
      <div style={{ maxWidth: titleWidth }} className={cn(styles.title, shimmer.shimmer)}></div>
      <div style={{ maxWidth: urlWidth }} className={cn(styles.url, shimmer.shimmer)}></div>
      <div
        style={{ maxHeight: descriptionHeight, maxWidth: descriptionWidth }}
        className={cn(styles.description, shimmer.shimmer)}
      ></div>
    </div>
  )
}

const WaitPreloader: React.FC<{ type: string; waiting: boolean }> = ({ type, waiting }) => {
  let imagesCount = 0
  let newsCount = 0

  if (waiting && type === "web")
    return (
      <div className={styles.root}>
        {_.times(12, (i) => {
          if (Math.random() > 0.8 && imagesCount < 2) {
            imagesCount++
            return <ImageCards key={i} />
          } else if (Math.random() > 0.9 && newsCount < 1) {
            newsCount++
            return <NewsCards key={i} />
          } else return <WebLink key={i} />
        })}
      </div>
    )
  else if (waiting && (type === "images" || type === "videos" || type === "news"))
    return (
      <div className={styles.root}>
        <div className={styles.imagesGrid}>
          {_.times(12, (i) => (
            <div key={i} className={cn(styles.imageItem, shimmer.shimmer)}></div>
          ))}
        </div>
      </div>
    )
  else return null
}

export default WaitPreloader
