/* eslint-disable */
import ReactDOM from "react-dom/client"
import "styles/reset.scss"
import "styles/main.scss"
import App from "modules/app/components/app"
import reportWebVitals from "./reportWebVitals"

import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink, split } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { onError } from "@apollo/client/link/error"
import { resizingSplitPane } from "modules/app/state"
import { WebSocketLink } from "@apollo/client/link/ws"
import { getMainDefinition } from "@apollo/client/utilities"
import "react-notifications-component/dist/theme.css"
import { ReactNotifications } from "react-notifications-component"
import Bugsnag from "@bugsnag/js"
import BugsnagPluginReact from "@bugsnag/plugin-react"
import React from "react"
import { runWhen } from "utils/common"
import styles from "./index.module.scss"
import NoInternet from "images/icons/no-internet.svg"
import { isProd } from "utils/env"

Bugsnag.start({
  releaseStage: isProd() ? "production" : "develpment",
  apiKey: "21ea8b34e12a67702f1cc88a9223cc92",
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ["production"]
})

const httpLink = createHttpLink({
  uri: import.meta.env.VITE_GRAPHQL_URL,
  credentials: "include"
})

const authLink = setContext((_, { headers }) => {
  const tzo = new Date().getTimezoneOffset() * -1

  return {
    headers: {
      ...headers,
      tzo,
      selection: localStorage.getItem("activeAppSpaceSelected"),
      lastMessage: localStorage.getItem("activeLastMessageId")
    }
  }
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      if (message === "Context creation failed: Invalid token") {
        window.location.href = "/signin"
      }
    })
  }

  // tslint:disable-next-line:no-console
  if (networkError) console.log(`[Network error]: ${networkError}`)
})

const wsLink = new WebSocketLink({
  uri: import.meta.env.VITE_GRAPHQL_SUB_URL || "",
  options: {
    reconnect: true,
    connectionParams: () => {
      return {
        tzo: new Date().getTimezoneOffset() * -1,
        credentials: "include",
        selection: localStorage.getItem("activeAppSpaceSelected"),
        lastMessage: localStorage.getItem("activeLastMessageId")
      }
    }
  }
})

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return definition.kind === "OperationDefinition" && definition.operation === "subscription"
  },
  wsLink,
  errorLink.concat(authLink).concat(httpLink)
)

const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache({
    typePolicies: {
      Session: {
        fields: {
          chatHistory: {
            keyArgs: [],
            merge(existing = [], incoming, a) {
              const offset = (a.args as any).offset as number
              if (offset === -1) {
                // from mutation
                return [...incoming, ...existing]
              } else if (offset === -2) {
                return existing.filter((e: any) => e.__ref !== incoming[0].__ref)
              }

              return [...existing, ...incoming]
            }
          }
        }
      },
      Query: {
        fields: {
          resizingSplitPane: {
            read: () => resizingSplitPane()
          },
          appSpaces: {
            merge: (existing, incoming) => {
              return incoming
            }
          }
        }
      }
    }
  })
})

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React)

function renderApp() {
  const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

  root.render(
     <ErrorBoundary>
      <ApolloProvider client={client}>
        <ReactNotifications />
        <App />
      </ApolloProvider>
     </ErrorBoundary>
  )
}

if (!window.navigator.onLine) {
  const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

  root.render(
    <div className={styles.noInternet}>
      <img src={NoInternet} alt="No Internet Connection" />
      <h1>No Internet Connection!</h1>
      <p>Pretzel needs an active internet connecion to work. Please connect to the Internet.</p>
    </div>
  )

  runWhen(() => window.navigator.onLine, renderApp)
} else {
  renderApp()
}

reportWebVitals()
