/* eslint-disable */
import React, { useContext } from "react"
import Popup from "reactjs-popup"
import cn from "clsx"
import button from "modules/shared/styles/components/button.module.scss"
import menu from "modules/shared/styles/components/menu.module.scss"
import styles from "../../folderTreeView/FolderTreeView.module.scss"
import pubsub from "pubsub-js"
import { topics } from "consts"
import { FileListingContext } from "../../fileListing/FileListing"
import { openable } from "utils/common"

const FileOptionsMenu: React.FC<{
  self: { id: string; type: string; name: string }
  onRename: () => unknown
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedItems?: any
  isConverted?: boolean
}> = ({ self, onRename, selectedItems, isConverted }) => {
  const { openAppSpace, downloadAppSpace, deleteItem } = useContext(FileListingContext)
  const isSelectedMultipleFiles = selectedItems
    ? selectedItems["dataBranchIds"].length + selectedItems["dataFileIds"].length
    : 0
  return (
    <Popup
      arrow={false}
      trigger={
        <button
          className={cn(button.button, button.primary, button.outline, button.small, styles.folderIconButton)}
          title="More Options"
        >
          <i className="fa fa-ellipsis-h fa-fw"></i>
        </button>
      }
      contentStyle={{ padding: 0 }}
      position="left top"
    >
      {
        ((close: () => void) => (
          <div className={cn(menu.menu, menu.leftTop)}>
            {openable(self) && !isSelectedMultipleFiles && (
              <div
                className={menu.item}
                onClick={() => {
                  openAppSpace(self)
                  close()
                }}
              >
                <i className="material-icons">launch</i>
                <span>Open File</span>
              </div>
            )}

            <div
              className={menu.item}
              onClick={() => {
                downloadAppSpace(self)
                close()
              }}
            >
              <i className="material-icons">file_download</i>
              <span>{!isSelectedMultipleFiles ? "Download File" : "Download Selected Files"} </span>
            </div>

            {!isSelectedMultipleFiles && (
              <div
                className={menu.item}
                onClick={() => {
                  onRename()
                  close()
                }}
              >
                <i className="material-icons">input</i>
                <span>Rename File</span>
              </div>
            )}
            {isConverted && (
              <div
                className={menu.item}
                onClick={() => {
                  onRename()
                  close()
                }}
              >
                <i className="fa fa-download fa-fw" />
                <span>Download Original</span>
              </div>
            )}
            <div className={menu.separator}></div>
            <div
              className={cn(menu.item, menu.danger)}
              onClick={() => {
                if (isSelectedMultipleFiles) {
                  ;[...selectedItems["dataBranchIds"], ...selectedItems["dataFileIds"]].forEach((fileId: string) => {
                    deleteItem(fileId, "appSpace")
                  })
                } else {
                  deleteItem(self.id, "appSpace")
                }
                pubsub.publish(topics.DESELECT_FILES)
                pubsub.publish(topics.FILE_LISTING_RELOAD)
                close()
              }}
            >
              <i className="material-icons">delete</i>
              <span>{!isSelectedMultipleFiles ? "Delete File" : "Delete Selected Files"} </span>
            </div>
          </div>
        )) as any
      }
    </Popup>
  )
}

export default FileOptionsMenu
