import React, { useState } from "react"
import { SearchResult_WebItemFragment } from "generated/graphql"
import cn from "clsx"
import button from "modules/shared/styles/components/button.module.scss"
import styles from "../../SearchResult.module.scss"
import BookmarkButton from "modules/shared/components/bookmarkButton"
import SaveButton from "modules/chat/components/saveButton"
import PopupContext from "modules/shared/components/popupButton/PopupContext"
import { textEllipsis } from "utils/common"

const WebPage: React.FC<{
  item: SearchResult_WebItemFragment
  preprocessDescription: (d: string, q: string) => JSX.Element
  q: string
}> = ({ item, preprocessDescription, q }) => {
  const [isActive, setIsActive] = useState(false)

  return (
    <div className={styles.webLink} key={item.id}>
      <a className={styles.webLinkTitle} href={item.url || ""} target="_blank" rel="noreferrer">
        {preprocessDescription(item.name || "", q)}
      </a>
      <p className={styles.webLinkDisplayUrl}>
        <span className={styles.webLinkUrl}>{textEllipsis(item.displayUrl || "", 50)}</span>
      </p>
      <div className={styles.webLinkDescription}>
        {preprocessDescription(item.snippet || "", q)}...
        <div className={cn(styles.hoverMenu, isActive ? styles.hover : null)}>
          <PopupContext.Provider value={{ isActive, setIsActive }}>
            <BookmarkButton title={item.name || ""} url={item.url || ""}>
              <span className={cn(styles.hoverButton, button.iconButton)}>
                <i className="material-icons">bookmark_outline</i>
                Bookmark
              </span>
            </BookmarkButton>
            <SaveButton type="contentFromUrl" url={item.url || ""} name={item.name || ""}>
              <span className={cn(styles.hoverButton, button.iconButton)}>
                <i className="material-icons">add</i>
                Save Page
              </span>
            </SaveButton>

            <span className={cn(styles.hoverButton, button.iconButton)}>
              <i className="material-icons">post_add</i>
              Save Content
            </span>
            <span className={cn(styles.hoverButton, button.iconButton)}>
              <i className="material-icons">arrow_forward</i>
              Insert Page
            </span>
          </PopupContext.Provider>
        </div>
      </div>
    </div>
  )
}

export default WebPage
