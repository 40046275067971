/* eslint-disable */
// @ts-ignore
import React, { useRef, useState, lazy, useContext, Suspense, useEffect } from "react"
import { Message_MessageFragment, useMessage_ToggleReactionMutation, useSidebar_UerInfoQuery } from "generated/graphql"
import Pretzel from "images/pret.png"
import styles from "./Message.module.scss"
import { format } from "date-fns"
import cn from "clsx"
import obsolete from "modules/shared/styles/components/obsolete.module.scss"
import button from "modules/shared/styles/components/button.module.scss"
import CollapsePanel from "modules/shared/components/collapsePanel"
import ObsoletePanel from "modules/shared/components/obsoletePanel"
import { MoreButton } from "modules/shared/components/moreButton"
import SaveButton from "../saveButton"
import InsertButton from "../insertButton"
import SplitterContext from "modules/shared/components/splitterContext"
import MessageOptions from "./components/messageOptions"
import spinner from "modules/shared/styles/components/spinner.module.scss"
import { ErrorBoundary } from "react-error-boundary"
import MessageFallbackComponent from "./components/messageFallbackComponent"
import ProfilePicture from "modules/shared/components/profilePicture"
import ChatContext from "../chat/ChatContext"
import { handleGraphQlError } from "utils/common"
import EmojiSelector from "modules/shared/components/emojiSelector/EmojiSelector"
import Popup from "reactjs-popup"
import Tooltip from "modules/shared/components/tooltip"
import AttachmentResult from "./components/messageAttachments"
import SearchFileListing from "../searchFileListing"
import ViewFiles from "../viewFiles/ViewFiles"
import { localSheetData } from "modules/appSpace/components/sheetDocument/SheetDocument"

const InlineContentResult = lazy(() => import("../inlineContentResult"))
const ImageResult = lazy(() => import("../imageResult"))
const VideoResult = lazy(() => import("../videoResult"))
const NewsResult = lazy(() => import("../newsResult"))
const TableResult = lazy(() => import("../tableResult"))
const SingleImageResult = lazy(() => import("../singleImageResult"))
const ChartResult = lazy(() => import("../chartResult"))
const Bookmarks = lazy(() => import("../bookmarks"))
const LinksResult = lazy(() => import("../linksResult"))
const VideoEmbedResult = lazy(() => import("../videoEmbedResult"))
const FlashCards = lazy(() => import("../flashCards"))
const NewsFeeds = lazy(() => import("../newsfeeds"))
const ListEditor = lazy(() => import("../listEditor"))
const ListsResult = lazy(() => import("../listsResult"))
const PDFResult = lazy(() => import("../pdfResult"))
const MultiChartResult = lazy(() => import("../multiChartResult"))
const AppSpacePreviewResult = lazy(() => import("../appSpacePreviewResult"))
const RPCHandler = lazy(() => import("../rpcHandler"))
// const SearchFileListing = lazy(() => import("../searchFileListing"))
const Map = lazy(() => import("../map"))
export let asyncListing: any = {}

interface IProps {
  message: Message_MessageFragment
  scrollIntoView: boolean
  isFirst: boolean
  isLast: boolean
  setFileListingSession: any
  onExtract: (arg: string) => void
}

interface DataRow {
  [key: string]: {
    value: number | string
  }
}

const Predict: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const [result, setResult] = useState<JSX.Element | null>(null)

  useEffect(() => {
    const canvas = canvasRef.current
    if (!canvas) return
    const ctx = canvas.getContext("2d")
    canvas.width = 450
    canvas.height = 300
    if (!ctx) return

    const data: DataRow = {} // remove for testing
    // const data: DataRow = {
    //   "0": {
    //     "0": {
    //       value: "Price"
    //     },
    //     "1": {
    //       value: "Living Area sqft"
    //     }
    //   },
    //   "1": {
    //     "0": {
    //       value: 0
    //     },
    //     "1": {
    //       value: 1180
    //     }
    //   },
    //   "2": {
    //     "0": {
    //       value: 538000
    //     },
    //     "1": {
    //       value: 2570
    //     }
    //   },
    //   "3": {
    //     "0": {
    //       value: 180000
    //     },
    //     "1": {
    //       value: 770
    //     }
    //   },
    //   "4": {
    //     "0": {
    //       value: 604000
    //     },
    //     "1": {
    //       value: 1960
    //     }
    //   },
    //   "5": {
    //     "0": {
    //       value: 510000
    //     },
    //     "1": {
    //       value: 1680
    //     }
    //   },
    //   "6": {
    //     "0": {
    //       value: 1225000
    //     },
    //     "1": {
    //       value: 5420
    //     }
    //   },
    //   "7": {
    //     "0": {
    //       value: 305000
    //     },
    //     "1": {
    //       value: 880
    //     }
    //   },
    //   "8": {
    //     "0": {
    //       value: 1050000
    //     },
    //     "1": {
    //       value: 2930
    //     }
    //   },
    //   "9": {
    //     "0": {
    //       value: 910000
    //     },
    //     "1": {
    //       value: 2570
    //     }
    //   },
    //   "10": {
    //     "0": {
    //       value: 385000
    //     },
    //     "1": {
    //       value: 1360
    //     }
    //   },
    //   "11": {
    //     "0": {
    //       value: 590000
    //     },
    //     "1": {
    //       value: 2070
    //     }
    //   },
    //   "12": {
    //     "0": {
    //       value: 285000
    //     },
    //     "1": {
    //       value: 1610
    //     }
    //   },
    //   "13": {
    //     "0": {
    //       value: 385000
    //     },
    //     "1": {
    //       value: 1060
    //     }
    //   },
    //   "14": {
    //     "0": {
    //       value: 395000
    //     },
    //     "1": {
    //       value: 1730
    //     }
    //   },
    //   "15": {
    //     "0": {
    //       value: 590000
    //     },
    //     "1": {
    //       value: 2450
    //     }
    //   },
    //   "16": {
    //     "0": {
    //       value: 660000
    //     },
    //     "1": {
    //       value: 2210
    //     }
    //   },
    //   "17": {
    //     "0": {
    //       value: 840000
    //     },
    //     "1": {
    //       value: 2880
    //     }
    //   },
    //   "18": {
    //     "0": {
    //       value: 1050000
    //     },
    //     "1": {
    //       value: 3530
    //     }
    //   },
    //   "19": {
    //     "0": {
    //       value: 420000
    //     },
    //     "1": {
    //       value: 1790
    //     }
    //   }
    // }

    const inputColumns: string[] = Object.values(data["0"]).map((col: any) => col.value as string)
    const xData: number[][] = []
    const yData: number[] = []

    Object.values(data).forEach((row, index) => {
      if (index === 0) return // Skip the header row

      const inputValues = Object.values(row)
        .slice(0, -1)
        .map((col: any) => Number(col.value))

      // @ts-ignore
      const targetValue = Number(row[inputColumns.length - 1].value)
      if (!isNaN(targetValue)) {
        xData.push(inputValues)
        yData.push(targetValue)
      }
    })

    // Linear Regression with multiple input columns
    const n = xData.length
    const numXColumns = xData[0].length
    const XTX: number[][] = Array.from({ length: numXColumns + 1 }, () => Array(numXColumns + 1).fill(0))
    const XTY: number[] = Array(numXColumns + 1).fill(0)

    xData.forEach((row, rowIndex) => {
      for (let i = 0; i < numXColumns; i++) {
        for (let j = 0; j < numXColumns; j++) {
          XTX[i][j] += row[i] * xData[rowIndex][j]
        }
        XTX[i][numXColumns] += row[i]
        XTX[numXColumns][i] += row[i]
        XTY[i] += row[i] * yData[rowIndex]
      }
      XTX[numXColumns][numXColumns] += 1
      XTY[numXColumns] += yData[rowIndex]
    })

    const inverseXTX = invertMatrix(XTX)

    const slopes: number[] = multiplyMatrices(inverseXTX, XTY)

    const predictY = (x: number[]): number => {
      let prediction = 0
      for (let i = 0; i < numXColumns; i++) {
        prediction += slopes[i] * x[i]
      }
      prediction += slopes[numXColumns] // Adding the intercept
      return prediction
    }

    function invertMatrix(matrix: number[][]): number[][] {
      // Implementation of invertMatrix (unchanged)
      const n = matrix.length
      const identity: number[][] = []
      for (let i = 0; i < n; i++) {
        identity.push(new Array(n).fill(0))
        identity[i][i] = 1
      }

      for (let i = 0; i < n; i++) {
        let pivot = matrix[i][i]

        if (!pivot) {
          for (let j = i + 1; j < n; j++) {
            if (matrix[j][i]) {
              for (let k = 0; k < n; k++) {
                matrix[i][k] += matrix[j][k]
                identity[i][k] += identity[j][k]
              }
              break
            }
          }
          pivot = matrix[i][i]
          if (!pivot) {
            throw new Error("Matrix is singular.")
          }
        }

        for (let j = 0; j < n; j++) {
          matrix[i][j] /= pivot
          identity[i][j] /= pivot
        }

        for (let j = 0; j < n; j++) {
          if (i !== j) {
            const ratio = matrix[j][i]
            for (let k = 0; k < n; k++) {
              matrix[j][k] -= ratio * matrix[i][k]
              identity[j][k] -= ratio * identity[i][k]
            }
          }
        }
      }

      return identity
    }

    function multiplyMatrices(matrixA: number[][], matrixB: number[]): number[] {
      // Implementation of multiplyMatrices (unchanged)
      const numRowsA = matrixA.length
      const numColsA = matrixA[0].length
      const numColsB = matrixB.length

      if (numColsA !== numColsB) {
        throw new Error("Number of columns in Matrix A must match the number of rows in Matrix B.")
      }

      const result: number[] = Array(numRowsA).fill(0)

      for (let i = 0; i < numRowsA; i++) {
        for (let j = 0; j < numColsB; j++) {
          result[i] += matrixA[i][j] * matrixB[j]
        }
      }

      return result
    }

    const arbitraryInputValues = [3000, 150]
    const predictedY = predictY(arbitraryInputValues)

    setResult(
      <>
        <p>Predicted Next Plot</p>
        <br />
        <table style={{ border: "1px solid #ccc", width: "100%", textAlign: "center" }}>
          <thead>
            <tr>
              <th style={{ padding: "8px", borderBottom: "1px solid #ccc" }}>{inputColumns[0]}</th>
              <th style={{ padding: "8px", borderBottom: "1px solid #ccc" }}>{inputColumns[1]}</th>
              <th style={{ padding: "8px", borderBottom: "1px solid #ccc" }}>
                Predicted {inputColumns[inputColumns.length - 1]}
              </th>
            </tr>
          </thead>
          <tbody>
            {xData.map((row, index) => (
              <tr key={index}>
                <td style={{ padding: "8px" }}>{row[0]}</td>
                <td style={{ padding: "8px" }}>{row[1]}</td>
                <td style={{ padding: "8px" }}>{predictY(row).toFixed(2)}</td>
              </tr>
            ))}
            <tr>
              <td style={{ padding: "8px" }}>{arbitraryInputValues[0]}</td>
              <td style={{ padding: "8px" }}>{arbitraryInputValues[1]}</td>
              <td style={{ padding: "8px" }}>{predictedY.toFixed(2)}</td>
            </tr>
          </tbody>
        </table>
      </>
    )
  }, [])

  return (
    <div
      style={{
        marginTop: "1rem",
        width: "100%",
        background: "#f9f9f9",
        borderRadius: "10px",
        padding: "10px"
      }}
    >
      <canvas ref={canvasRef}></canvas>
      <div style={{ marginTop: "1rem", fontSize: "20px" }}>{result}</div>
    </div>
  )
}

const Message: React.FC<IProps> = ({ message, isFirst, isLast, setFileListingSession }) => {
  const rootRef = useRef<HTMLDivElement>(null)
  const [obsoleteOverride, setObsoleteOverride] = useState(false)
  const [, updateClientSideReaction] = useState("")
  const { setPinnedEmbedHtml, pinnedEmbedHtml } = useContext(SplitterContext)
  const { userId, isGroup } = useContext(ChatContext)
  const [isInViewport, setIsInViewport] = useState(false)
  const divRef = useRef<HTMLDivElement | null>(null)
  const [toggleReaction] = useMessage_ToggleReactionMutation({
    onError: handleGraphQlError("Unable to add reaction")
  })
  const { data: me, refetch } = useSidebar_UerInfoQuery({
    onError: handleGraphQlError("Unable to get user info")
  })

  if (["searchResult", "fileListing"].includes(message.type) && "fileListing" in message) {
    asyncListing = message
  }

  const date = new Date(message.time)
  const time = format(date, "p")

  const getButtonText = (action: string) => {
    const readable: { [id: string]: string } = {
      searchResult: "Search Results",
      fileListing: "Files",
      images: "Images",
      table: "Table(s)",
      "table.withHeaders": "Table(s)",
      bookmarks: "Bookmarks",
      "html.info": "Text"
    }
    return `${action} ${readable[message.type]}`
  }

  if (message.embed)
    if (pinnedEmbedHtml === (message.embed || [])[0].embedHtml || "") {
      return null
    }

  const headless = message.type.endsWith(":headless")

  function chatMeta() {
    return (
      <>
        {!headless && (
          <div className={styles.chatMeta}>
            <div style={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
              <span className={styles.name} key={message.user?.id}>
                {message.user?.fullName}
              </span>
              <span className={styles.time}>{time}</span>

              {message.user?.isPretzel && message.type.startsWith("html.info") && (
                <div className={styles.buttonsGroup}>
                  <SaveButton
                    name={message.searchTerm || "Info"}
                    from={"messagetextContent:" + message.id}
                    type="content"
                  >
                    <button
                      className={cn(button.button, button.primary, button.small, button.iconButton, "hide-m")}
                      title="Save as"
                    >
                      <i className="material-icons-outlined">save</i>
                      <span>Save</span>
                    </button>
                  </SaveButton>
                  <InsertButton content={message.textContent || ""} type="html">
                    <button
                      className={cn(button.button, button.primary, button.small, button.iconButton, "hide-m")}
                      title="Insert into"
                    >
                      <i className="material-icons">arrow_forward</i>
                      <span>Insert into</span>
                    </button>
                  </InsertButton>
                </div>
              )}

              {message.user?.isPretzel && message.type === "embed" && (
                <div className={styles.buttonsGroup}>
                  <button
                    className={cn(button.button, button.primary, button.small, button.iconButton, "hide-m")}
                    onClick={() => setPinnedEmbedHtml((message.embed || [])[0].embedHtml || "")}
                    title="Pin Video"
                  >
                    <i className="fa fa-thumb-tack"></i>
                    <span>Pin Video</span>
                  </button>
                </div>
              )}
            </div>
            <MessageOptions
              message={message}
              owner={!isGroup || message.user?.id === userId}
              react={(key: string) => {
                toggleReaction({
                  variables: {
                    messageId: message.id,
                    value: key
                  }
                })
              }}
              selected={message.reactions}
              canReact={isGroup}
              canEdit={isGroup && message.user?.id === userId}
            />
          </div>
        )}
      </>
    )
  }

  if (message.type.startsWith("RPC")) {
    return <RPCHandler message={message} isLast={isLast} />
  }

  const checkIsInViewport = () => {
    if (divRef.current) {
      const element = divRef.current
      const rect = element.getBoundingClientRect()

      const inViewport =
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)

      setIsInViewport(inViewport)
    }
  }

  // useEffect(() => {
  //   // Initial check
  //   checkIsInViewport()

  //   // Set an interval to periodically check if the element is in the viewport
  //   const intervalId = setInterval(checkIsInViewport, 500)

  //   return () => {
  //     // Clear the interval when the component unmounts
  //     clearInterval(intervalId)
  //   }
  // }, [])

  useEffect(() => {
    console.log(localSheetData?.sheets?.Sheet1?.data?.dataTable)
    const lastFL = document.querySelectorAll(".lastFL")
    for (let i = 0; i < lastFL.length - 1; i++) {
      lastFL[i].remove()
    }
  }, [history])

  useEffect(() => {
    const scrollContainer = rootRef.current?.parentElement
    if (scrollContainer) {
      scrollContainer.scrollTop = scrollContainer.scrollHeight
    }
  }, [rootRef.current])

  return (
    <Suspense
      fallback={
        <div className={styles.suspenseContainer}>
          <div className={cn(spinner.spinner, spinner.small)}></div>
          <span>Loading mesage...</span>
        </div>
      }
    >
      <div className={message.textContent === "files" ? "lastFL" : "newestFileListing"}>
        <div
          className={cn([
            styles.message,
            headless ? styles.headless : null,
            !isFirst && !headless ? styles.borderTop : null,
            isLast && message.user?.id !== userId ? styles.new : null,
            message.isUnread ? styles.unread : null
          ])}
          style={{
            position: "relative",
            top: message.type === "fileListing" || message.textContent === "files" ? "5.3em" : undefined,
            height: message.type === "fileListing" || message.textContent === "files" ? "90vh" : undefined,
            overflowY: message.type === "fileListing" || message.textContent === "files" ? "scroll" : undefined,
            overflowX: message.type === "fileListing" || message.textContent === "files" ? "hidden" : undefined,
            background: message.user?.fullName === me?.me?.fullName || "" ? "#f7fcff" : "white"
          }}
          ref={rootRef}
        >
          {!headless && (
            <>
              {message.user?.isPretzel && (
                <img src={Pretzel} alt="Pretzel Profile" className={styles.avatar} key={message.user?.id} />
              )}
              {!message.user?.isPretzel && (
                <div className={styles.userProfileContainer}>
                  <ProfilePicture
                    userId={message.user?.id || ""}
                    userName={message.user?.fullName || ""}
                    src={message.user?.profilePicture || ""}
                    size="large"
                    statusMode={"none"}
                  />
                </div>
              )}
            </>
          )}
          <div className={styles.chatData}>
            {chatMeta()}
            {/* Message Content */}
            <ErrorBoundary FallbackComponent={MessageFallbackComponent}>
              <div className={styles.content}>
                <div
                  className={cn([
                    obsolete.root,
                    message.obsolete ? obsolete.obsolete : "",
                    obsoleteOverride ? obsolete.override : ""
                  ])}
                >
                  {message.textContent !== "files" &&
                    (message.type.startsWith("html") ||
                      message.type === "text" ||
                      message.type.startsWith("text") ||
                      message.type === "groupStatus") && <InlineContentResult message={message} isLast={isLast} />}

                  {message.type.startsWith("links") && (
                    <MoreButton defaultCount={5}>
                      <LinksResult message={message} isLast={isLast} />
                    </MoreButton>
                  )}

                  {message.type.startsWith("image") && (
                    <SingleImageResult
                      message={message}
                      url={message.textContent || ""}
                      searchTerm={message.searchTerm || ""}
                      isLast={isLast}
                    />
                  )}

                  {message.type === "newsfeeds" && <NewsFeeds message={message} isLast={isLast} />}

                  {message.type.startsWith("chart") && (
                    <ChartResult
                      message={message}
                      url={message.textContent || ""}
                      type={message.type}
                      isLast={isLast}
                    />
                  )}

                  {message.type === "list" && <ListEditor message={message} isLast={isLast} />}

                  {message.type === "lists" && <ListsResult message={message} isLast={isLast} />}

                  {message.type === "map" && <Map message={message} isLast={isLast} />}

                  <div className={obsolete.content}>
                    {message.type.startsWith("table") && (
                      <TableResult message={message} type={message.type} isLast={isLast} />
                    )}

                    {message.type.startsWith("bookmarks") && (
                      <MoreButton defaultCount={5}>
                        <Bookmarks message={message} isLast={isLast} />
                      </MoreButton>
                    )}

                    {message.type === "embed" && <VideoEmbedResult message={message} isLast={isLast} />}

                    {message.type === "videos" && <VideoResult message={message} isLast={isLast} />}

                    {message.type === "news" && <NewsResult message={message} isLast={isLast} />}

                    {message.type === "images" && <ImageResult message={message} isLast={isLast} />}

                    {message.type === "pdf" && <PDFResult message={message} isLast={isLast} />}

                    {message.textContent?.includes("<item>Q:") && <FlashCards message={message.textContent} />}

                    {/* {["searchResult", "fileListing"].includes(message.type) && (
                      <SearchFileListing
                        setFileListingSession={setFileListingSession}
                        message={message}
                        isLast={isLast}
                      />
                    )} */}

                    {/* {isInViewport ? ( <></>) : null} */}

                    {message.textContent === "files" && (
                      <div ref={divRef}>
                        <SearchFileListing
                          setFileListingSession={setFileListingSession}
                          message={asyncListing as Message_MessageFragment}
                          isLast={isLast}
                        />
                      </div>
                    )}

                    {message?.textContent?.toLocaleLowerCase().startsWith("view ") && (
                      <ViewFiles message={message.textContent} />
                    )}

                    {message?.textContent?.toLocaleLowerCase() === "predict" && <Predict />}

                    {message.type === "appSpacePreview" && <AppSpacePreviewResult message={message} isLast={isLast} />}

                    {message.type === "flashcards" && <FlashCards message={message} />}
                    {message.type.startsWith("multichart") && <MultiChartResult isLast={isLast} message={message} />}
                  </div>
                  <ObsoletePanel text={getButtonText("Expand")} setObsoleteOverride={setObsoleteOverride} />
                  <CollapsePanel text={getButtonText("Collapse")} setObsoleteOverride={setObsoleteOverride} />
                </div>

                {headless && isGroup && (
                  <div className={styles.headlessOptionsContainer}>
                    <MessageOptions
                      message={message}
                      owner={!isGroup || message.user?.id === userId}
                      react={(key: string) => {
                        toggleReaction({
                          variables: {
                            messageId: message.id,
                            value: key
                          }
                        })
                      }}
                      selected={message.reactions}
                      canReact={isGroup}
                      canEdit={isGroup && message.user?.id === userId}
                    />
                  </div>
                )}
              </div>
            </ErrorBoundary>

            {/* Attachments */}
            {message.attachments && <AttachmentResult message={message} isLast={isLast} />}

            {/* Reactions */}
            {message.reactions.length > 0 && isGroup && (
              <div className={styles.reactionLine}>
                {message.reactions.map((reaction) => (
                  <Tooltip
                    title={`${reaction.reactors} reacted with :${reaction.value}:`}
                    trigger="mouseenter"
                    size="big"
                    delay={200}
                    key={reaction.id}
                  >
                    <span
                      className={styles.reactionItem}
                      onClick={() => {
                        toggleReaction({
                          variables: {
                            messageId: message.id,
                            value: reaction.value
                          }
                        })
                        // console.log("Message toggle reaction")
                        updateClientSideReaction(reaction.value)
                      }}
                      key={message.id + reaction.value}
                    >
                      <img src={reaction.icon} alt={reaction.name} />
                      {reaction.reactors.length > 1 && <span>{reaction.reactors.length}</span>}
                    </span>
                  </Tooltip>
                ))}
                <Popup
                  arrow={true}
                  trigger={
                    <span className={styles.reactionItem}>
                      <i className="material-icons-outlined">add_reaction</i>
                    </span>
                  }
                  contentStyle={{ padding: 0, minWidth: 205 }}
                  repositionOnResize
                  position={[
                    "top center",
                    "top left",
                    "right top",
                    "right center",
                    "right bottom",
                    "left top",
                    "left center",
                    "left bottom"
                  ]}
                >
                  {
                    ((close: () => void) => (
                      <EmojiSelector
                        select={(key: string) => {
                          toggleReaction({
                            variables: {
                              messageId: message.id,
                              value: key
                            }
                          })
                          close()
                          // console.log("Message from comment reaction popup")
                          updateClientSideReaction(key)
                        }}
                        selected={message.reactions}
                      />
                    )) as any
                  }
                </Popup>
              </div>
            )}
          </div>
        </div>
      </div>
    </Suspense>
  )
}

export default Message
