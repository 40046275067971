/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react"

import "tinymce/tinymce"
import "tinymce/icons/default"
import "tinymce/themes/silver"
import "tinymce/plugins/autolink"
import "tinymce/plugins/lists"
import "tinymce/plugins/anchor"
import "tinymce/plugins/autoresize"
import "tinymce/plugins/link"
import "tinymce/plugins/paste"

import Popup from "reactjs-popup"

const DynamicPositionedPopup: React.FC<{
  open: boolean
  setOpen: (b: boolean) => void
  selector: string
  id: string
  content: (close: () => void) => JSX.Element
}> = ({ open, setOpen, selector, id, content }) => {
  const [left, setLeft] = useState(0)
  const [top, setTop] = useState(0)

  const doAfter = async (check: () => boolean, action: () => void) => {
    while (!check()) {
      await new Promise((resolve) => setTimeout(resolve, 500))
    }
    action()
  }

  useEffect(() => {
    doAfter(
      () => document.querySelector(selector)?.getBoundingClientRect() !== undefined,
      () => {
        const pos = document.querySelector(selector)?.getBoundingClientRect()

        if (pos) {
          setLeft(pos.left)
          setTop(pos.top)
        }
      }
    )
  }, [])

  return (
    <Popup
      open={open}
      repositionOnResize
      contentStyle={{ zIndex: 99999, minWidth: 205 }}
      position={[
        "top center",
        "top left",
        "right top",
        "right center",
        "right bottom",
        "left top",
        "left center",
        "left bottom"
      ]}
      trigger={
        <p id={id} style={{ visibility: "hidden", position: "fixed", left: left, top: top }}>
          Action
        </p>
      }
      closeOnDocumentClick
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
    >
      {content(() => setOpen(false)) as any}
    </Popup>
  )
}

export default DynamicPositionedPopup
