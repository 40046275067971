import React from "react"
import DesignerComp from "./designerComp/DesignerComp"
import GC from "@grapecity/spread-sheets"

// https://www.grapecity.com/blogs/how-to-license-spreadjs-11-in-your-app
GC.Spread.Sheets.LicenseKey =
  "app.pretzel.space,553379915413553#B0yXCiNVNo9GW6o7ZiVGR88ES8VWV0lHcjRmTRNzZLB7YS9EbKVGNvcVQtpkZrVTVzVHTRNWUxoWNwh6KvBjTYJ4TvJkULJ6R6h7VQFTWT3UaX5URxQmQvdkMPNWSEhjWoRDRLF7dw26VGdjQodjcqJ6K5dWU8wkTqdlSm3GenVmN9YzLqFjWBBDaClncycUOygHWy9kcJx4Tx3yL7s6MTVjenBFUV3mephkYyBncI9Ucjl5VxgDTVxWOlNUWMlUbsJHd7MkT8E4MCl4SFNFcZl6S6tUa8N7R7kUOlRWdzhEcMBTOmdWcFtCTBR7bxYUepFlI0IyUiwiI6MzM5EDMwIjI0ICSiwCN6gzN6MDNxETM0IicfJye&Qf35VfiUURJZlI0IyQiwiI4EjL6BCITpEIkFWZyB7UiojIOJyebpjIkJHUiwiI7MDO5MDMgkDM8ATMyAjMiojI4J7QiwiIlNWYwNnLsVme4Vmcw9CcwFmI0IyctRkIsISeodWYsxWYQBCb5FGUiojIh94QiwiIzUTNzEDN5ETO9czMzUTNiojIklkIs4XXbpjInxmZiwSZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34zZ7YmVN3mcatSOpBHR9JVZHdjN4RWU7FlYCVUdDF7SvgVdhhnWwokRpVnVrt6KCdzNk9kR9F4a6MFcG9UbTNEevpEUPVUZLBXY9M4Mj3WNxVjW8siWNBlbQxWRGB7L6hTTSCWvv"
interface IState {
  content: string
  edited: (content: string, id: string) => void
  id: string
  oninit: () => void
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export let localSheetData: any = {}
const SheetDocument: React.FC<IState> = ({ content, edited, id, oninit }) => {
  localSheetData = JSON.parse(content)
  const handleEdit = (updatedContent: string) => {
    edited(updatedContent, id)
  }

  return <DesignerComp id={id} key={id} oninit={oninit} initContent={content || "{}"} handleEdit={handleEdit} />
}

export default SheetDocument
