/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useRef, useState } from "react"
import styles from "./TaskSelector.module.scss"
import { useWikisCardQuery, useTaskSelectorQuery, useSidebarChannelsQuery } from "generated/graphql"
import { handleGraphQlError } from "utils/common"
import cn from "clsx"
import pubsub from "pubsub-js"
import spinner from "modules/shared/styles/components/spinner.module.scss"
import TextEditorPopup from "modules/shared/components/pretzelTextEditor/textEditorPopup"
import striptags from "striptags"
import { replace } from "lodash"
import { textEllipsis } from "utils/common"

const TaskSelector: React.FC = () => {
  const [searchText, setSearchText] = useState<string>("")
  const [focused, setFocused] = useState(0)
  const tasksContainer = useRef<HTMLDivElement>(null)

  const { data, refetch } = useTaskSelectorQuery()
  const rawData = useSidebarChannelsQuery({
    onError: handleGraphQlError("Unable to get channels")
  })
  const channels = rawData?.data?.groups.filter((g) => g.type === "channel")

  const wikiData = useWikisCardQuery()
  const wikiPages = wikiData?.data?.recentWikiPages

  const filtered = useMemo(
    () =>
      data
        ? data.allTasks.filter((t) => {
            if (!searchText) {
              return true
            }

            return (
              t.task.idNumber.toString() === searchText || t.task.title.toLowerCase().includes(searchText.toLowerCase())
            )
          })
        : [],
    [data, searchText]
  )

  useEffect(() => {
    if (tasksContainer.current) {
      tasksContainer.current.children[focused].scrollIntoView({
        behavior: "smooth"
      })
    }
  }, [focused])

  const filteredWikiPages = useMemo(
    () =>
      wikiPages
        ? wikiPages.filter((page) => {
            if (!searchText) {
              return true
            }

            return page.wikiPage.name.toLowerCase().includes(searchText.toLowerCase())
          })
        : [],
    [wikiPages, searchText]
  )

  const filteredChannels = useMemo(
    () =>
      channels
        ? channels.filter((channel) => {
            if (!searchText) {
              return true
            }

            return channel.name.toLowerCase().includes(searchText.toLowerCase())
          })
        : [],
    [channels, searchText]
  )

  return (
    <TextEditorPopup
      filtered={filtered}
      searchText={searchText}
      setSearchText={setSearchText}
      topic={"taskSelector"}
      focused={focused}
      setFocused={setFocused}
      onDataShouldRefresh={refetch}
    >
      {(hide: () => void) => (
        <>
          <div className={styles.tasks} ref={tasksContainer}>
            {!data && (
              <div className={styles.loading}>
                <div className={cn([spinner.spinner, spinner.small])}></div>
                <span>Please wait...</span>
              </div>
            )}
            <div className={styles.container}>
              <div style={{ width: "33%" }}>
                <div className={styles.heading}>
                  <i style={{ color: "#8095ff" }} className="material-icons">
                    dashboard
                  </i>
                  Tasks
                </div>
                <div className={styles.linkContainer}>
                  {data && (
                    <div>
                      {filtered.map(({ task, boardIdStr }, i) => (
                        <div
                          className={cn([styles.task, i === focused ? styles.focused : null])}
                          key={task.id}
                          onClick={() => {
                            pubsub.publish("userSelector.select", {
                              id: task.id,
                              idNumber: task.idNumber,
                              color: task.state.color,
                              title: task.title,
                              boardId: boardIdStr + "/" + task.idNumber + "type:task"
                            })
                            hide()
                          }}
                        >
                          <div className={styles.colorIdNumber} style={{ backgroundColor: task.state.color }}>
                            #{task.idNumber}
                          </div>

                          <div className={styles.text}>
                            <span className={styles.title}>{task.title}</span>
                            <span className={styles.description}>
                              {task.description && textEllipsis(replace(striptags(task.description), "&nbsp;", ""), 25)}
                              {!task.description && <em>No description</em>}
                            </span>
                          </div>

                          <div className={styles.board}></div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div style={{ width: "33%" }}>
                <div className={styles.heading}>
                  <i style={{ color: "#8095ff" }} className="material-icons">
                    chat
                  </i>
                  Channels
                </div>
                <div className={styles.linkContainer}>
                  {filteredChannels?.map((channel, i) => (
                    <div
                      className={cn([styles.task, i === focused ? styles.focused : null])}
                      key={channel.id}
                      onClick={() => {
                        pubsub.publish("userSelector.select", {
                          id: "",
                          idNumber: "",
                          color: "#D3E8F1",
                          title: channel.name,
                          boardId: channel.id + "type:channel"
                        })
                        hide()
                      }}
                    >
                      <i style={{ fontSize: "1.2em", color: "#8095ff" }} className="material-icons">
                        chat
                      </i>
                      {channel.name}
                    </div>
                  ))}
                </div>
              </div>
              <div style={{ width: "33%" }}>
                <div className={styles.heading}>
                  <i style={{ color: "#8095ff" }} className="material-icons">
                    auto_stories
                  </i>
                  Wikis
                </div>
                <div className={styles.linkContainer}>
                  {filteredWikiPages?.map((page, i) => (
                    <div
                      className={cn([styles.task, i === focused ? styles.focused : null])}
                      key={page.wikiPage.id}
                      onClick={() => {
                        pubsub.publish("userSelector.select", {
                          id: "",
                          idNumber: page.wiki.name,
                          color: "#D3E8F1",
                          title: page.wikiPage.name,
                          boardId: page.wiki.idStr + "/" + page.wikiPage.id + "type:wiki"
                        })
                        hide()
                      }}
                    >
                      <i style={{ fontSize: "1.2em", color: "#8095ff" }} className="material-icons">
                        auto_stories
                      </i>
                      {page.wikiPage.name}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.bottom}>
            <div className={styles.instruct}>
              <kbd>&#8593;</kbd>
              <kbd>&#8595;</kbd>
              <span>to select</span>
            </div>
            <div className={styles.instruct}>
              <kbd>
                <i className="material-icons">subdirectory_arrow_left</i>
              </kbd>
              <span>to mention</span>
            </div>
            <div className={styles.instruct}>
              <kbd>Esc</kbd>
              <span>to dismiss</span>
            </div>
            {searchText && (
              <div style={{ marginLeft: "1rem" }} className={styles.top}>
                <span>
                  Showing <b>#{searchText}</b>
                </span>
              </div>
            )}
          </div>
        </>
      )}
    </TextEditorPopup>
  )
}

export default TaskSelector
