/* eslint-disable */
import React, { useContext, useEffect, useState } from "react"
import Popup from "reactjs-popup"
import menu from "modules/shared/styles/components/menu.module.scss"
import styles from "./MessageOptions.module.scss"
import messageStyles from "../../Message.module.scss"
import cn from "clsx"
import { Message_MessageFragment } from "generated/graphql"
import { useMessageOptions_DeleteMessageMutation } from "generated/graphql"
import ChatContext from "../../../chat/ChatContext"
import EmojiSelector from "modules/shared/components/emojiSelector"
import { handleGraphQlError } from "utils/common"
import pubsub from "pubsub-js"
import { topics } from "consts"
import { undoMessageHint, undoMessageDelete, defaultDuration } from "utils/notification"
import { useHistory } from "react-router-dom"

export const scheduleMessageDelete: string[] = []
const MessageOptions: React.FC<{
  message: Message_MessageFragment
  owner: boolean
  react: (key: string) => void
  selected: { icon: string }[]
  canReact: boolean
  canEdit: boolean
}> = ({ message, owner, react, selected, canReact, canEdit }) => {
  const history = useHistory()
  const [open, setOpen] = useState(false)
  const [deleteMessage] = useMessageOptions_DeleteMessageMutation({
    onError: handleGraphQlError("Unable to delete message")
  })
  const [reformatNotification] = useState(false)
  const [, updateClientSideReaction] = useState("")

  const { deleteMessageLocal, setPreDeleteMessageId } = useContext(ChatContext)

  const handleDelete = (close: () => void) => {
    close()
    setPreDeleteMessageId(message.id)
    deleteMessage({ variables: { id: message.id } }).then(() => {
      deleteMessageLocal(message.id)
    })
  }
  const handleEdit = (close: () => void) => {
    close()
    pubsub.publish(topics.OPEN_MESSAGE_EDITOR, message.id)
  }
  useEffect(() => {
    const notificationContainer = document.querySelector(".rnc__notification-message")
    if (notificationContainer) {
      const notificationMessage = notificationContainer?.innerHTML
      const parser = new DOMParser()
      if (notificationMessage && !reformatNotification) {
        const notificationBodyText = parser.parseFromString(notificationMessage, "text/html")?.body?.textContent
        if (notificationBodyText) {
          const notificationTags = [...parser.parseFromString(notificationBodyText, "text/html").querySelectorAll("p")]
          notificationContainer.childNodes.forEach((c) => c.nodeType === Node.TEXT_NODE && c.remove())
          for (const notificationTag of notificationTags) {
            notificationContainer?.appendChild(notificationTag)
          }
        }
      }
    }
  }, [])

  const deleteChatMessage = async (id: string) => {
    undoMessageHint(
      `Deleted ${
        (message?.textContent || "").length > 8
          ? (message?.textContent || "").substring(0, 8) + "..."
          : message?.textContent || ""
      }`,
      `<p>Click to undo</p>`
    )
    scheduleMessageDelete.push(id)
    history.push(history.location.pathname)
    setTimeout(() => {
      if (undoMessageDelete === true) return

      deleteMessage({ variables: { id: id } }).then(() => {
        deleteMessageLocal(id)
      })
    }, defaultDuration + 2000)
  }
  return (
    <div className={cn([styles.messageOptions, messageStyles.messageOptions, open ? messageStyles.force : null])}>
      {canReact && (
        <Popup
          arrow={false}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          trigger={
            <button className={cn([messageStyles.messageActionsTrigger])}>
              <i className="material-icons-outlined">add_reaction</i>
            </button>
          }
          contentStyle={{ padding: 0, minWidth: 205 }}
          position={["top center", "top left", "right top", "right center", "left top", "left center", "left bottom"]}
        >
          {
            ((close: () => void) => (
              <EmojiSelector
                select={(key: string) => {
                  react(key)
                  close()
                  // console.log("Message from the popup")
                  updateClientSideReaction(key)
                }}
                selected={selected}
              />
            )) as any
          }
        </Popup>
      )}
      {owner && (
        <button onClick={() => deleteChatMessage(message.id)} className={cn([messageStyles.messageActionsTrigger])}>
          <div className={cn([messageStyles.messageActionsColor])}>
            <i className="material-icons">close</i>
          </div>
        </button>
      )}
      <Popup
        arrow={true}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        trigger={
          <button className={cn([messageStyles.messageActionsTrigger])}>
            <i className="fa fa-ellipsis-h"></i>
          </button>
        }
        contentStyle={{ padding: 0 }}
        position="bottom right"
      >
        {
          ((close: () => void) => (
            <div className={cn(menu.menu, menu.leftCenter)}>
              {owner && (
                <>
                  {canEdit && (
                    <div className={cn(menu.item)} onClick={() => handleEdit(close)}>
                      <i className="material-icons">edit</i>
                      <span>Edit Message</span>
                    </div>
                  )}
                  <div
                    className={cn(menu.item, menu.danger)}
                    onClick={() => {
                      deleteChatMessage(message.id)
                    }}
                  >
                    <i className="material-icons">delete</i>
                    <span>Delete Message</span>
                  </div>
                </>
              )}
              {!owner && (
                <>
                  <div
                    className={cn(menu.item, menu.disabled)}
                    onClick={() => {
                      deleteChatMessage(message.id)
                    }}
                  >
                    <i className="material-icons">clear</i>
                    <span>No actions</span>
                  </div>
                </>
              )}
            </div>
          )) as any
        }
      </Popup>
    </div>
  )
}

export default MessageOptions
