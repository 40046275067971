/* eslint-disable */
import React, { useContext, useState } from "react"
import styles from "../../Sidebar.module.scss"
import Modal from "react-modal"
import {
  OrganizationMemberSearchDocument,
  useSidebarChannelsQuery,
  useSidebarChannels_CreateChannelMutation,
  useSidebarChannels_SelectChannelMutation
} from "generated/graphql"
import cn from "clsx"
import { useHistory } from "react-router-dom"
import { channelRegex, routes } from "consts"
import { handleGraphQlError, modalStyles, textEllipsis } from "utils/common"
import Tooltip from "modules/shared/components/tooltip"
import { SidebarContext } from "../../Sidebar"
import Select from "react-select/async"
import modal from "modules/shared/styles/components/modal.module.scss"
import input from "modules/shared/styles/components/input.module.scss"
import button from "modules/shared/styles/components/button.module.scss"
import { useOnDemandQuery } from "utils/hooks"
// import { isMobile } from "react-device-detect"
import { newTab, setActiveTab, viewTabs } from "modules/app/components/sessionTabs/SessionTabs"

const CreateChannelModal: React.FC<{ creating: boolean; setCreating: (val: boolean) => void }> = ({
  creating,
  setCreating
}) => {
  const [newChannelName, setNewChannelName] = useState("")
  const [newChannelDescription, setNewChannelDescription] = useState("")
  const [createChannel] = useSidebarChannels_CreateChannelMutation({
    onError: handleGraphQlError("Unable to create channel")
  })
  const searchMembers = useOnDemandQuery(OrganizationMemberSearchDocument)
  // const history = useHistory()

  function closeModal() {
    setNewChannelName("")
    setSelectedMembers([])
    setCreating(false)
  }

  function fixName(name: string) {
    return name.replaceAll(" ", "-").toLowerCase()
  }

  function validateName() {
    if (!newChannelName) {
      return false
    }

    return !!newChannelName.match(channelRegex)
  }

  const [selectedMembers, setSelectedMembers] = useState<string[]>([])
  const [validationMessage, setValidationMessage] = useState<string>("")

  return (
    <Modal
      isOpen={creating}
      onRequestClose={() => setCreating(false)}
      style={{
        content: {
          ...modalStyles.content,
          overflow: "initial"
        },
        overlay: modalStyles.overlay
      }}
      contentLabel="Add new channel"
    >
      <div className={modal.modal}>
        <div className={styles.modalHeader}>
          <i className="material-icons-outlined">group_add</i>
          <h2 className={styles.title}>Create a channel</h2>
          <p className={styles.description}>Channels is where your team communicates.</p>
        </div>

        <br />

        <div className={modal.popupSubsection}>
          <label>Channel name</label>
          <div></div>
        </div>

        <div className={styles.sidebarModalInput}>
          <input
            className={cn([input.input, input.big, input.iconInput])}
            placeholder="New channel name"
            value={newChannelName}
            maxLength={50}
            onChange={(e) => setNewChannelName(fixName(e.target.value))}
          />
          <span className="material-icons">drive_file_rename_outline</span>
        </div>
        {validationMessage && <p className={input.validationMessage}>{validationMessage}</p>}

        <div className={styles.sidebarModalInput}>
          <textarea
            className={cn([input.input, input.big, input.textarea])}
            placeholder="Description"
            rows={5}
            value={newChannelDescription}
            onChange={(e) => setNewChannelDescription(e.target.value)}
          />
        </div>

        <div className={modal.popupSubsection}>
          <label>Add Members</label>
          <div></div>
        </div>

        <Select
          loadOptions={async (val) => {
            const result = await searchMembers({
              searchText: val
            })

            return result.data.organization.members.map((m: any) => ({
              name: m.user.fullName,
              id: m.user.id,
              email: m.user.email
            }))
          }}
          isMulti
          placeholder="Search members..."
          value={selectedMembers}
          onChange={(v) => setSelectedMembers(v as any)}
          getOptionLabel={(a) => a.name}
          getOptionValue={(a) => a.id}
        />

        <button
          className={cn([button.button, button.primary, button.noAnimation, button.big, styles.sidebarModalButton])}
          style={{ opacity: !newChannelName ? 0.75 : 1 }}
          disabled={!newChannelName}
          onClick={async () => {
            if (!validateName()) {
              setValidationMessage("Channel names cannot contain most punctuation. Try a different name")
              return
            }
            setValidationMessage("")
            await createChannel({
              variables: {
                name: newChannelName,
                members: selectedMembers.map((a) => (a as any).id),
                type: "channel",
                description: newChannelDescription
              }
            })
            closeModal()
            // history.push(routes.chat)
          }}
        >
          <i className="material-icons">add</i>
          <span>Create Channel</span>
        </button>
        <div className={modal.closeButton} onClick={() => closeModal()}>
          <i className="material-icons">close</i>
        </div>
      </div>
    </Modal>
  )
}

const SidebarChannels: React.FC<{ setChannelUnreads: any }> = ({ setChannelUnreads }) => {
  const { collapsed } = useContext(SidebarContext)
  const { data } = useSidebarChannelsQuery({
    onError: handleGraphQlError("Unable to get channels")
  })
  const [selectChannel] = useSidebarChannels_SelectChannelMutation({
    onError: handleGraphQlError("Unable to select channel")
  })
  const [creating, setCreating] = useState(false)
  const history = useHistory()

  if (!data) {
    return null
  }

  const channels = data.groups.filter((g) => g.type === "channel")

  function displayUnreadCount(data: any) {
    // Temp fix for unread count -- Cohen
    const channelData = {
      name: data.name,
      unreadCount: data.unreadCount
    }

    if (channelData.unreadCount > 0) setChannelUnreads(true)

    if (channelData.name === "dev") {
      channelData.unreadCount = channelData.unreadCount - 1
      return (
        <>
          {channelData.unreadCount > 0 && (
            <div className={styles.badge}>
              <span>{channelData.unreadCount}</span>
            </div>
          )}
        </>
      )
      // if (channelData.unreadCount <= 1) return <></>
      // if (channelData.unreadCount > 1) {
      //   return (
      //     <div className={styles.badge}>
      //       <span>{channelData.unreadCount - 1}</span>
      //     </div>
      //   )
      // }
    } else {
      return (
        <>
          {data.unreadCount > 0 && (
            <div className={styles.badge}>
              <span>{data.unreadCount}</span>
            </div>
          )}
        </>
      )
    }
  }

  return (
    <div className={styles.section}>
      <div className={styles.heading}>
        <span className={styles.title}>Channels</span>
        <div className={styles.plusIcon} onClick={() => setCreating(true)}>
          <i className="material-icons">add</i>
        </div>
      </div>
      <CreateChannelModal creating={creating} setCreating={setCreating} />

      <div className={styles.selectChats}>
        {channels.map((channel) => (
          <Tooltip
            title={channel.name}
            disabled={!collapsed}
            position="right"
            distance={-65}
            delay={50}
            trigger="mouseenter"
            size="big"
            key={channel.id}
            animation="shift"
          >
            <div
              key={channel.id}
              className={cn(
                styles.item,
                channel.id === data.activeSession.groupId ? styles.activeItem : null,
                channel.unreadCount > 0 ? styles.unreadCollapsed : null
              )}
              onClick={() => {
                // if (isMobile) setCollapsed(true)
                setTimeout(() => setChannelUnreads(false), 4000)

                if (channel.id === data.activeSession.groupId) {
                  if (!window.location.href.includes("/chat")) history.push(routes.chat)
                  return
                }
                const ready = channel.name
                const containsSession = viewTabs.some(
                  (tab: { session: { name: string } }) => tab.session.name === ready
                )
                if (!containsSession) {
                  newTab({
                    name: channel.name,
                    id: "",
                    icon: "chat",
                    groupId: channel.id,
                    location: routes.chat,
                    type: "channel:" + channel.id,
                    active: true,
                    forceOpen: true
                  })
                } else {
                  const existing = viewTabs.find((tab: { session: { name: string } }) => tab.session.name === ready)
                  if (existing) setActiveTab(existing.session.id)
                }
                if (!window.location.href.includes("/chat")) history.push(routes.chat)
                selectChannel({ variables: { id: channel.id } })
              }}
            >
              {/* <div className={styles.icon}>
                <i className="material-icons">message</i>
              </div> */}
              <span className={styles.name}>#{textEllipsis(channel.name, 25)}</span>
              {displayUnreadCount(channel)}
              {/* {channel.unreadCount > 0 && (
                <div className={styles.badge}>
                  <span>{channel.unreadCount}</span>
                </div>
              )} */}
            </div>
          </Tooltip>
        ))}

        {(collapsed || !channels.length) && (
          <Tooltip
            title={"Create Channel"}
            disabled={!collapsed}
            position="right"
            distance={-65}
            delay={50}
            trigger="mouseenter"
            size="big"
          >
            <div className={cn([styles.item, styles.plusButton])} onClick={() => setCreating(true)}>
              <div className={styles.icon}>
                <i className="material-icons">add</i>
              </div>
              <span className={styles.name}>Create Channel</span>
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  )
}

export default SidebarChannels
