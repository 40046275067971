/* eslint-disable */
import React, { useCallback, useEffect, useRef, useState } from "react"
import Tabs from "../tabs"
import Sidebar from "../sidebar"
import styles from "./Root.module.scss"
import AppSpace from "modules/appSpace/components/AppSpace"
import { resizingSplitPane } from "modules/app/state"
import SplitterLayout from "react-splitter-layout"
import "react-splitter-layout/lib/index.css"
import classNames from "clsx"
import SplitterContext from "modules/shared/components/splitterContext"
import PinnedVideo from "../pinnedVideo"
import _ from "lodash"
import { useNonInitialEffect } from "utils/hooks"
import { KeyCodes, routes, topics } from "consts"
import {
  useRootQuery,
  useSidebarChannels_SelectChannelMutation,
  useSessions_CreateSessionMutation,
  useFileListing_OpenFileMutation,
  useAppSpaces_CloseAppSpaceMutation,
  useAppSpaceTabsQuery
} from "generated/graphql"
import { currentSession, handleGraphQlError } from "utils/common"
import { useHistory } from "react-router-dom"
import ProfileView from "./../profileView"
import pubsub from "pubsub-js"
import { useNotificationRegistation } from "./notificationRegistration"
import { useManageQueryParams } from "./hooks"

import UserSelector from "modules/shared/components/pretzelTextEditor/textEditorPopup/userSelector"
import EmojiSelector from "modules/shared/components/pretzelTextEditor/textEditorPopup/emojiSelector"
import TaskSelector from "modules/shared/components/pretzelTextEditor/textEditorPopup/taskSelector"
import { currentTab } from "../sessionTabs/SessionTabs"
import { viewingTab, viewTabs } from "modules/chat/components/viewFiles/ViewFiles"
import { setActiveView, wikiDocTabs } from "modules/appSpace/components/appSpaceTabs/AppSpaceTabs"

let preloadFiles: any
let fileIndex = 0
if (localStorage.fileCache && localStorage.fileCache !== "undefined") {
  preloadFiles = JSON.parse(localStorage.fileCache)?.filter((file: any) => file.type !== "taskBoard")
}
export let setGlobalFinishPreview: any
export let globalActiveAppSpace = false

const Root: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { data } = useRootQuery({
    onError: handleGraphQlError("Unable to get data")
  })
  const { data: wikiApps, refetch } = useAppSpaceTabsQuery({
    onError: handleGraphQlError("Unable to get app spaces")
  })
  const [createSession] = useSessions_CreateSessionMutation({
    onError: handleGraphQlError("Unable to create session")
  })
  const [selectChannel] = useSidebarChannels_SelectChannelMutation({
    onError: handleGraphQlError("Unable to select channel")
  })
  const [openFile] = useFileListing_OpenFileMutation({
    onError: handleGraphQlError("Unable to open file")
  })
  const [closeAppSpace] = useAppSpaces_CloseAppSpaceMutation({})
  globalActiveAppSpace = !!data?.activeAppSpace?.id

  const splitterContainer = useRef<HTMLDivElement>(null)
  const chatPane = useRef<HTMLDivElement>(null)
  const [mobileChatPane, setMobileChatPane] = useState(false)
  const [dragging, setDragging] = useState(false)
  const [pinnedEmbedHtml, setPinnedEmbedHtml] = useState("")
  const [mobile, setMobile] = useState(false)
  const [windowWidth, setWindowWidth] = useState(1000)
  const [chatWidth, setChatWidth] = useState(100)
  const [maximizeChat, setMaximizeChat] = useState(false)
  const [profileView, setProfileView] = useState(false)
  const [profileId, setProfileId] = useState<string | null>(null)
  const [sessionLoadingState, setSessionLoadingState] = useState<boolean>(true)

  const [finishPreview, setFinishPreview] = useState("")
  setGlobalFinishPreview = setFinishPreview

  const history = useHistory()
  const useActiveView = document.querySelector(".viewing_files")

  useEffect(() => {
    if (currentTab?.id !== finishPreview) {
      setFinishPreview("")
    }
  }, [data?.activeSession.id])

  if (!currentSession("wikis") && wikiApps?.activeAppSpace?.type === "taskBoard") {
    closeAppSpace({
      variables: {
        appSpaceId: wikiApps.activeAppSpace.id
      }
    }).catch(() => /* GFY */ {})
  }

  const keyShortcuts = (e: any) => {
    const activeElement = document.activeElement as HTMLElement
    if (activeElement.tagName === "INPUT" || activeElement.tagName === "TEXTAREA") return

    if (e.key === "ArrowRight" || e.key === "ArrowLeft" || e.key === "Tab") {
      setActiveView("preview")
      if (e.key === "ArrowRight") {
        if (fileIndex === preloadFiles.length - 1) fileIndex = 0
        else fileIndex = fileIndex + 1
      }
      if (e.key === "ArrowLeft") {
        if (fileIndex === 0) fileIndex = preloadFiles.length - 1
        else fileIndex = fileIndex - 1
      }
      if (e.key === "Tab") {
        e.preventDefault()
        e.stopPropagation()
        viewingTab(preloadFiles[fileIndex], false)
        return
      }

      viewingTab(preloadFiles[fileIndex], true)
      openFile({ variables: { fileId: preloadFiles[fileIndex].id } }).then(() => {
        data?.activeAppSpace?.id &&
          closeAppSpace({ variables: { appSpaceId: data?.activeAppSpace?.id } }).catch(() => {})
      })
    }
  }

  useEffect(() => {
    if (wikiApps?.activeAppSpace?.name === "Untitled") {
      closeAppSpace({
        variables: {
          appSpaceId: wikiApps.activeAppSpace.id
        }
      }).catch(() => {})
    }
  }, [wikiApps?.activeAppSpace])

  useEffect(() => {
    // View CMD is not active
    if (useActiveView === null) window.addEventListener("keydown", keyShortcuts)
    return () => window?.removeEventListener("keydown", keyShortcuts)
  }, [useActiveView])

  const closeProfileView = () => setProfileView(false)
  const setWidthDebounced = (n: number) => {
    return _.debounce(() => {
      setWindowWidth(n)
      setMobile(n < 768)
    }, 200)
  }

  if (typeof Storage !== "undefined") {
    if (!localStorage.PRELOAD_TASKS) localStorage.PRELOAD_TASKS = ""
  }

  /**
   * Preload task states */
  // if (localStorage.taskBoardId) {
  //   useTaskBoardBoardViewQuery({
  //     variables: { boardId: localStorage.taskBoardId },
  //     onCompleted(data) {
  //       console.warn("%c Successfully Preloaded Task Lanes", "font-size: 2rem")
  //       localStorage.PRELOAD_TASK_STATES = JSON.stringify(data)

  //       const d = data.taskBoard.states.map((item: any) => item.id)
  //       setTaskLanes(d)
  //       expectedLanes = d.length
  //     },
  //     onError: () => console.error("Failed to preload task states")
  //   })
  // }

  const debouncedSetChatWidth = _.debounce(setChatWidth, 200)
  const deboncedSetMobileChatPane = _.debounce(setMobileChatPane, 200)

  const handleWindowSizeChange = () => {
    setWidthDebounced(window.innerWidth)()

    if (chatPane.current) {
      setMobileChatPane(chatPane.current.clientWidth < 800)
    }
  }

  useEffect(() => {
    handleWindowSizeChange()

    if (!data?.activeAppSpace) {
      setChatWidth(100)
    }
  }, [data?.activeAppSpace])

  useEffect(() => {
    if (window.location.href === window.location.origin + "/") return
    localStorage.cachedURL = window.location.pathname
  }, [window.location.pathname])

  const handleDragStart = () => {
    resizingSplitPane(true)
    setDragging(true)
  }
  const handleDragEnd = () => {
    resizingSplitPane(false)
    setDragging(false)
  }

  const onPanelWidthChange = (v: number) => {
    const splitWidth = splitterContainer.current?.offsetWidth || 2000
    const _chatWidth = 100 - v
    debouncedSetChatWidth(_chatWidth)
    deboncedSetMobileChatPane((splitWidth / 100) * _chatWidth < 800)
  }

  useEffect(() => {
    handleWindowSizeChange()
    window.addEventListener("resize", handleWindowSizeChange)
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange)
    }
  }, [])

  useNonInitialEffect(() => {
    const selector = "." + styles.split + ">.splitter-layout>.layout-pane:last-child"
    const appSpace = document.querySelector(selector)
    if (appSpace) {
      if (maximizeChat) {
        appSpace.setAttribute("style", "width: 0")
      } else {
        appSpace.setAttribute("style", "width: 50%")
      }
    }
  }, [maximizeChat])

  const keyHandler = (ev: { keyCode: number; key: string }) => {
    if (ev.keyCode === KeyCodes.Escape) {
      pubsub.publish("rootEsc")
      setMaximizeChat(false)
    }
  }

  useEffect(() => {
    // Clear appspace cache, use loaded from backend
    localStorage.setItem("appspace_cache", "")

    /**
     * RETURN TO PRETZEL = ALWAYS OPEN HOME TAB
     */

    history.push(routes.home)
  }, [])

  const sidebarUpdated = () => {
    //
  }

  const openProfileView = useCallback(
    (id: string) => {
      setProfileId(id)
      setProfileView(true)
    },
    [setProfileId, setProfileView]
  )

  useEffect(() => {
    if (navigator.serviceWorker) {
      navigator.serviceWorker.ready.then(() => {
        navigator.serviceWorker.controller?.postMessage({
          type: "ACTIVE_GROUP",
          id: data?.activeSession.groupId || ""
        })
      })
    }
  }, [data?.activeSession.groupId])

  // const updateSessionTitle = (title: string) => {
  //   if (!title || !data?.activeSession.id) {
  //     return
  //   }

  //   renameSession({
  //     variables: {
  //       newName: title,
  //       sessionId: data?.activeSession.id
  //     },
  //     optimisticResponse: {
  //       renameSession: {
  //         id: data.activeSession.id,
  //         name: title
  //       }
  //     }
  //   })

  //   window.document.title = "Pretzel - " + title
  // }

  if (currentSession("login")) history.push("../")

  // useEffect(() => {
  //   const tokenTitle = pubsub.subscribe(topics.SET_SESSION_TITLE, (_: any, title: string) => {
  //     updateSessionTitle(title)
  //   })

  //   return () => {
  //     pubsub.unsubscribe(tokenTitle)
  //   }
  // }, [updateSessionTitle, data?.activeSession.id])

  useEffect(() => {
    const token = pubsub.subscribe(topics.OPEN_PROFILE, (_: any, data: any) => {
      openProfileView(data)
    })

    return () => {
      pubsub.unsubscribe(token)
    }
  }, [openProfileView])

  async function handleClick(e: any) {
    if (e && e.className === "p-user-mention") {
      const id = e.getAttribute("data-id")
      if (id) {
        pubsub.publish(topics.OPEN_PROFILE, id)
      }
    }
    if (e && e.className === "p-task-mention") {
      const board = e.getAttribute("data-board")

      if (board.includes("type:wiki")) {
        const wikiName = e.getAttribute("data-id")
        await createSession({
          variables: {
            name: wikiName
          }
        })
        setTimeout(() => (window.location.href = `/wikis/${board.replace("type:wiki", "")}`), 50)
      }
      if (board.includes("type:task")) {
        await createSession({})
        setTimeout(() => history.push(`/tasks/${board.replace("type:task", "")}`), 100)
      }
      if (board.includes("type:channel")) {
        await selectChannel({
          variables: {
            id: board.replace("type:channel", "")
          }
        })
        setTimeout(() => history.push(`/chat`), 50)
      }
    }
  }

  useNotificationRegistation()
  useManageQueryParams()

  if (!data) {
    return null
  }

  function shouldDisplayAppSpace() {
    if (finishPreview === currentTab?.id) return false
    if (viewTabs.length > 0) return true

    // No appSpaces open, don't show
    if (data?.activeAppSpace === null) return false
    // User is in channel/dm, don't show
    if (currentTab && currentTab.groupId) return false
    if (
      currentSession("tasks") ||
      currentSession("wikis") ||
      currentSession("apps") ||
      currentSession("people") ||
      currentSession("notes") ||
      currentSession("dashboard")
    )
      return false

    // If none of the conditions above are met, show app space in Pretzel chat sessions
    if (currentSession("/chat") || currentSession("/mail")) return true
    else return false
  }

  return (
    <div
      className={classNames(styles.root, data.activeAppSpace ? styles.splitted : null)}
      onKeyDown={keyHandler}
      onClick={(e) => handleClick(e.target)}
    >
      <ProfileView id={profileId} open={profileView} close={closeProfileView} />

      <>
        <UserSelector />
        <EmojiSelector />
        <TaskSelector />
      </>

      <div className={styles.line2}>
        <Sidebar mobile={mobile} />
        <SplitterContext.Provider
          value={{
            mobileChatPane,
            pinnedEmbedHtml,
            setPinnedEmbedHtml,
            dragging,
            chatWidth,
            setChatWidth,
            maximizeChat,
            toggleMaximizeChat: () => {
              setMaximizeChat(!maximizeChat)
            },
            windowWidth,
            sidebarUpdated,
            sessionLoadingState,
            setSessionLoadingState
          }}
        >
          <div className={styles.contentContainer}>
            <Tabs finishPreview={finishPreview} setFinishPreview={setFinishPreview} />
            <div className={styles.splitContainer}>
              <div ref={splitterContainer} className={styles.split}>
                <SplitterLayout
                  onDragStart={handleDragStart}
                  onDragEnd={handleDragEnd}
                  onSecondaryPaneSizeChange={onPanelWidthChange}
                  percentage={true}
                  primaryMinSize={(500 / window.innerWidth) * 100}
                  secondaryMinSize={maximizeChat ? 0 : 30}
                  secondaryInitialSize={50}
                >
                  {!mobile && (
                    <div className={styles.innerSplit} ref={chatPane}>
                      <SplitterLayout
                        vertical={true}
                        onDragStart={handleDragStart}
                        onDragEnd={handleDragEnd}
                        secondaryInitialSize={25}
                        secondaryMinSize={1}
                        percentage={true}
                      >
                        <div style={{ height: "100%" }}>{children}</div>
                        {pinnedEmbedHtml !== "" && <PinnedVideo />}
                      </SplitterLayout>
                    </div>
                  )}

                  {mobile && <div style={{ height: "100%" }}>{children}</div>}
                  {shouldDisplayAppSpace() && <AppSpace />}
                </SplitterLayout>
              </div>
            </div>
          </div>
        </SplitterContext.Provider>
      </div>
    </div>
  )
}

export default Root
