import React, { useState, useRef } from "react"
import { isEnter } from "utils/common"
import styles from "./EditableInput.module.scss"

const EditableInput: React.FC<{
  value: string
  editable: boolean
  saveCallback: (v: string) => void
  isTextArea: boolean
  enhancer?: (str: string) => string
  placeholder?: string
  alwaysEditVisible?: boolean
}> = ({ alwaysEditVisible, placeholder, value, editable, saveCallback, isTextArea, enhancer = (a) => a }) => {
  const [editing, setEditing] = useState<string | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  const save = async () => {
    if (!editing) {
      return
    }
    await saveCallback(editing)
    setEditing(null)
  }

  function edit() {
    setEditing(value)

    setTimeout(() => {
      ;[inputRef, textAreaRef].forEach((i) => {
        if (i.current) {
          i.current.focus()
          i.current.select()
        }
      })
    })
  }

  return (
    <>
      {editing === null && (
        <div className={styles.defaultView} onClick={edit}>
          {isTextArea && (
            <p className={styles.value}>
              {placeholder && !value && <span style={{ color: "#aaa" }}>{placeholder}</span>}
              {!(placeholder && !value) && <span>{value}</span>}
            </p>
          )}
          {!isTextArea && (
            <span className={styles.value}>
              {placeholder && !value && <span style={{ color: "#aaa" }}>{placeholder}</span>}
              {!(placeholder && !value) && <span>{value}</span>}
            </span>
          )}
          {editable && <div className={alwaysEditVisible ? styles.saveButton : styles.editButton}>Edit</div>}
        </div>
      )}

      {editing !== null && (
        <div className={styles.defaultView}>
          {isTextArea && (
            <textarea
              className={styles.input}
              ref={textAreaRef}
              value={editing as string}
              onChange={(e) => setEditing(enhancer(e.target.value))}
              onBlur={async () => {
                await save()
              }}
              onKeyUp={(e) => {
                if (isEnter(e)) {
                  save()
                }
              }}
            />
          )}
          {!isTextArea && (
            <input
              className={styles.input}
              ref={inputRef}
              value={editing as string}
              onChange={(e) => setEditing(enhancer(e.target.value))}
              onBlur={async () => {
                await save()
              }}
              onKeyUp={(e) => {
                if (isEnter(e)) {
                  save()
                }
              }}
            />
          )}
          <div className={styles.saveButton}>Save</div>
        </div>
      )}
    </>
  )
}

export default EditableInput
