import React from "react"

import styles from "./SearchPagination.module.scss"
import button from "modules/shared/styles/components/button.module.scss"
import cn from "clsx"

const generatePages = (count: number, current: number): string[] => {
  const shownPages = 3
  const result: string[] = []
  if (current > count - shownPages) {
    result.push((count - 2).toString(), (count - 1).toString(), count.toString())
  } else {
    result.push(current.toString(), (current + 1).toString(), (current + 2).toString(), "...", count.toString())
  }
  return result
}

const SearchPagination: React.FC<{
  total: number
  currentPage: number
  goto: (page: number) => void
  compact?: boolean
}> = ({ total, currentPage, goto, compact }) => {
  const pages = generatePages(total, currentPage)

  return (
    <div className={cn(styles.pagination, compact ? styles.small : null)}>
      {!pages.includes("1") && (
        <button
          onClick={() => goto(currentPage - 1)}
          className={cn(button.button, button.roundedButton, button.primary)}
        >
          <i className="fa fa-chevron-left fa-fw"></i>
        </button>
      )}
      {pages.map((pagenum, i) => (
        <React.Fragment key={i}>
          {pagenum !== "0" && (
            <span
              onClick={pagenum !== "..." ? () => goto(parseInt(pagenum)) : undefined}
              className={currentPage.toString() === pagenum ? styles.active : ""}
              key={i}
            >
              {pagenum !== "..." ? parseInt(pagenum).toLocaleString() : pagenum}
            </span>
          )}
        </React.Fragment>
      ))}
      {currentPage < total && (
        <button
          onClick={() => goto(currentPage + 1)}
          className={cn(button.button, button.roundedButton, button.primary)}
        >
          <i className="fa fa-chevron-right fa-fw"></i>
        </button>
      )}
    </div>
  )
}

export default SearchPagination
