import React from "react"
import cn from "clsx"
import styles from "./ProfilePicture.module.scss"
import pubsub from "pubsub-js"
import { topics } from "consts"
import {
  useProfilePictureQuery,
  // useProfilePicture_StatusSubscription,
  useProfilePicture_UserQuery
} from "generated/graphql"
import { handleGraphQlError } from "utils/common"

/**
 *
 * @param src Src of the profile picture image
 * @param userId Id of the user
 * @param userName Name for accessibility
 * @param size Size of the profile picture
 * @param circle Is the profile picture circle
 * @param statusMode How the profile picture must show user active status (default: dot)
 */
const ProfilePicture: React.FC<{
  src: string
  userId: string
  userName: string
  unclickable?: boolean
  size: "large" | "small" | "larger"
  circle?: boolean
  statusMode?: "outline" | "dot" | "none"
}> = ({ userName, src, userId, unclickable, size, circle, statusMode }) => {
  let profileSize: unknown
  const { data: sessionData } = useProfilePictureQuery({
    onError: handleGraphQlError("Unable to get active session")
  })

  const { data } = useProfilePicture_UserQuery({
    onError: handleGraphQlError("Unable to get profile picture data"),
    variables: {
      userId
    }
  })

  // useProfilePicture_StatusSubscription({
  //   variables: {
  //     sessionId: sessionData?.activeSession?.id || "",
  //     userId: userId
  //   },
  //   skip: !sessionData?.activeSession?.id || !statusMode || statusMode === "none"
  // })

  if (!sessionData || !data) {
    return null
  }

  const renderStatus = () => {
    if (statusMode === "outline") {
      return <div className={cn([styles.outline, data.user?.isActive ? styles.active : null])}></div>
    }

    if (statusMode === "dot") {
      return <div className={cn([styles.dot, data.user?.isActive ? styles.active : null])}></div>
    }

    return <></>
  }
  if (size === "small") profileSize = null
  if (size === "large") profileSize = styles.large
  if (size === "larger") profileSize = styles.larger
  return (
    <div
      onClick={() => {
        if (!unclickable) {
          pubsub.publish(topics.OPEN_PROFILE, userId)
        }
      }}
      className={cn([styles.pp, circle ? styles.circle : null, profileSize])}
    >
      <div className={styles.picture}>
        <img src={src} alt={userName} />
      </div>
      {renderStatus()}
    </div>
  )
}

export default ProfilePicture
