/* eslint-disable prettier/prettier */
import React, { useContext, useEffect, useRef, useState } from "react"
import cn from "clsx"
import styles from "./FileFolderTableView.module.scss"
import {
  FileFolderTableView_FileFragment as File,
  FileFolderTableView_FolderFragment as Folder
} from "generated/graphql"
import input from "modules/shared/styles/components/input.module.scss"
import { useDrag, useDrop } from "react-dnd"

import DirectoryOptionsMenu from "../optionsButtons/directoryOptionsMenu/DirectoryOptionsMenu"
import FileOptionsMenu from "../optionsButtons/fileOptionsMenu/FileOptionsMenu"
import { FileListingContext } from "../fileListing/FileListing"

import EmptyFilesWarning from "../emptyFilesWarning"

import { fileTypeToColor } from "../../utils/fileTypeToColor"
import { appSpaceTypeToKind, humanizeFileSize } from "utils/common"
import { KeyCodes } from "consts"
import SplitterContext from "modules/shared/components/splitterContext"

import ExpandableRecentsList from "modules/shared/components/expandableRecentsList"

const FileComp: React.FC<{ file: File; recentMode?: boolean }> = ({ file, recentMode }) => {
  const time = new Date(file.createdDate)
  const { mobileChatPane } = useContext(SplitterContext)
  const [{ opacity }, connectDragSource] = useDrag(() => ({
    type: "file",
    item: {
      type: "file",
      id: file.id
    },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.5 : 1
    })
  }))

  const [isRenaming, setIsRenaming] = useState(false)
  const [expandedVersions, setExpandedVersions] = useState(true)
  const [currentRenamingName, setCurrentRenamingName] = useState(file.name)
  const renameInput = useRef<HTMLInputElement>(null)
  const { renameFile, openAppSpace } = useContext(FileListingContext)

  function endRenaming() {
    if (currentRenamingName) {
      setIsRenaming(false)
      renameFile(file.id, currentRenamingName)
    }
  }

  useEffect(() => {
    if (isRenaming) {
      renameInput.current?.focus()
    }
  }, [isRenaming])

  const fileRow = () => (
    <tr
      className={styles.filetr}
      onClick={
        !isRenaming
          ? () => {
              openAppSpace(file)
              setExpandedVersions(true)
            }
          : undefined
      }
      key={file.id}
      style={{ opacity }}
    >
      <td>
        <div className={cn(styles.filename, recentMode ? styles.fileRowAlt : styles.fileRow)}>
          {(file.versions || []).length > 0 && (
            <i
              style={{ marginLeft: 7 }}
              onClick={(e) => {
                setExpandedVersions(!expandedVersions)
                e.preventDefault()
                e.stopPropagation()
              }}
              className={cn(
                styles.plusIcon,
                styles.transformPlusIcon,
                !expandedVersions ? "fa fa-plus-square" : "fa fa-minus-square"
              )}
            />
          )}
          <div className={fileTypeToColor(file.type)}></div>
          {isRenaming && (
            <input
              onBlur={() => setIsRenaming(false)}
              ref={renameInput}
              value={currentRenamingName}
              className={cn(input.input, input.small, styles.renameInput)}
              type="text"
              onChange={(e) => setCurrentRenamingName(e.target.value)}
              onKeyDown={(e: { keyCode: number }) => {
                if (e.keyCode === KeyCodes.Enter) endRenaming()
              }}
            ></input>
          )}
          {!isRenaming && <span className={styles.filenameWrap}>{file.name}</span>}
        </div>
      </td>
      {!mobileChatPane && (
        <>
          <td className="hide-m">
            <em>{time?.toLocaleDateString()}</em>
          </td>
          <td className="hide-m">
            <em>{time?.toLocaleTimeString()}</em>
          </td>
          <td className="hide-m">
            <em>{file.kind || appSpaceTypeToKind(file.type)}</em>
          </td>
          <td className="hide-m">
            <em>{file.origin?.toUpperCase() || "NATIVE"}</em>
          </td>
        </>
      )}
      <td className="hide-m">
        <em>{humanizeFileSize(file.size)}</em>
      </td>
      <td className="hide-m">
        <em>/{file.parent?.name}</em>
      </td>
      <td style={{ width: 40 }} className={styles.folderIconButtonGroup}>
        <FileOptionsMenu self={file} onRename={() => setIsRenaming(!isRenaming)} />
      </td>
    </tr>
  )

  if (!expandedVersions) {
    return connectDragSource(fileRow())
  } else {
    return (
      <>
        {connectDragSource(fileRow())}
        <ExpandableRecentsList
          itemTypeName="versions"
          items={file.versions || []}
          itemRenderer={(version) => (
            <tr
              className={cn([styles.extraFileTr, styles.filetr])}
              onClick={!isRenaming ? () => openAppSpace(version) : undefined}
              key={version.id}
            >
              <td>
                <div className={styles.filenameTd} style={{ paddingLeft: recentMode ? 29 : 47 }}>
                  <div className={fileTypeToColor(version.type)}></div>
                  <span>{version.name}</span>
                </div>
              </td>
              <td>
                <em>{new Date(version.createdDate).toLocaleDateString()}</em>
              </td>
              <td>
                <em>{new Date(version.createdDate).toLocaleTimeString()}</em>
              </td>
              <td className="hide-m">
                <em>{version.kind || appSpaceTypeToKind(version.type)}</em>
              </td>
              <td className="hide-m">
                <em>{version.origin?.toUpperCase() || "EXPORT"}</em>
              </td>
              <td className="hide-m">
                <em>{humanizeFileSize(version.size)}</em>
              </td>
              <td></td>
              <td style={{ width: 40 }} className={styles.folderIconButtonGroup}>
                <FileOptionsMenu self={version} onRename={() => setIsRenaming(!isRenaming)} />
              </td>
            </tr>
          )}
          showMoreLessLabel={(text, toggle) => (
            <tr onClick={toggle}>
              <td className={styles.loadAllButton} style={{ paddingLeft: 25, paddingBottom: 5 }}>
                <span>{text}</span>
              </td>
            </tr>
          )}
        />
      </>
    )
  }
}

const renderFileList = (files: File[], loading: boolean, recentMode?: boolean) => {
  if (files.length > 0) {
    if (recentMode) {
      return (
        <>
          {files.map((file) => (
            <FileComp recentMode={recentMode} key={file.id} file={file} />
          ))}
        </>
      )
    } else {
      return (
        <ExpandableRecentsList
          itemRenderer={(file) => <FileComp recentMode={recentMode} key={file.id} file={file} />}
          itemTypeName="files"
          items={files}
          modifiedDate={true}
          showMoreLessLabel={(text, toggle) => (
            <tr onClick={toggle}>
              <td className={styles.loadAllButton}>
                <span>{text}</span>
              </td>
            </tr>
          )}
        />
      )
    }
  } else return !loading ? <EmptyFilesWarning /> : null
}

const FolderComp: React.FC<{
  folder: Folder
  time: Date
}> = ({ folder, time }) => {
  const [renaming, setRenaming] = useState(false)
  const renameInput = useRef<HTMLInputElement>(null)
  const [currentRenamingName, setCurrentRenamingName] = useState(folder.name)
  const { newFolder, renameFolder, moveThingToFolder, expandFolder, loading, query } = useContext(FileListingContext)

  function endRenaming() {
    if (currentRenamingName) {
      setRenaming(false)
      renameFolder(folder.id, currentRenamingName)
    }
  }

  const [{ isOver }, connectDropTarget] = useDrop(() => ({
    accept: ["folder", "file"],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    drop: (a: any) => {
      moveThingToFolder(a.id as string, folder.id, a.type)
    },
    canDrop: (item, m) =>
      item.id !== folder.id && // Cannot drop on itself
      item.parentId !== folder.id && // Cannot drop if already the parent
      m.isOver({ shallow: true }),
    collect: (m) => ({ isOver: !!m.isOver({ shallow: true }) }),
    type: "any"
  }))

  const canExpand = folder.files.length === 0 && !!folder.hasFiles

  if (query && canExpand) return null

  return (
    <>
      {connectDropTarget(
        <tr
          key={folder.id}
          className={cn(styles.filetr, styles.folderNameRow)}
          style={{
            opacity: isOver ? "0.8" : "1",
            pointerEvents: "auto"
          }}
          onClick={() => {
            expandFolder(folder.id)
          }}
        >
          <td className={styles.folderName}>
            {canExpand && <i className={cn(styles.plusIcon, "fa fa-plus-square")} />}
            {!folder.hasFiles && <i className={cn(styles.plusIcon, "fa fa-plus-square", styles.hide)} />}
            {folder.files.length > 0 && <i className={cn(styles.plusIcon, "fa fa-minus-square")} />}
            {folder.files.length === 0 && <i className="fa-fw fa fa-folder" />}
            {folder.files.length > 0 && <i className="fa fa-fw fa-folder-open" />}
            {renaming && (
              <input
                onBlur={() => endRenaming()}
                ref={renameInput}
                value={currentRenamingName}
                className={cn(input.input, input.small, styles.renameInput)}
                type="text"
                onChange={(e) => setCurrentRenamingName(e.target.value)}
                onKeyDown={(e: { keyCode: number }) => {
                  if (e.keyCode === KeyCodes.Enter) endRenaming()
                }}
              ></input>
            )}
            {!renaming && <p>{folder.name}</p>}
          </td>
          <td className="hide-m">
            <em>{time?.toLocaleDateString()}</em>
          </td>
          <td className="hide-m">
            <em>{time?.toLocaleTimeString()}</em>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <em>/{folder.parent?.name}</em>
          </td>
          <td className={styles.folderIconButtonGroup}>
            <DirectoryOptionsMenu
              folderId={folder.id}
              startRenaming={() => setRenaming(true)}
              isRoot={folder.parent === null}
              newFolder={() => newFolder(folder.id, "New Folder")}
            />
          </td>
        </tr>
      )}
      {!!folder.files.length && renderFileList(folder.files, loading, false)}
    </>
  )
}

const FileFolderTableView: React.FC<{
  files: File[]
  folders: Folder[]
  displayType: string
}> = ({ files, folders, displayType }) => {
  const { loading } = useContext(FileListingContext)
  const { mobileChatPane } = useContext(SplitterContext)

  if (!displayType) {
    return null
  }

  return (
    <>
      <table className={styles.filesTable}>
        <thead>
          <tr className={styles.filesTableHeader}>
            <th style={{ paddingLeft: 20 }}>Name</th>
            {!mobileChatPane && (
              <>
                <th className="hide-m">Date</th>
                <th className="hide-m">Time</th>
                <th className="hide-m">Kind</th>
                <th className="hide-m">Origin</th>
              </>
            )}
            <th className="hide-m">Size</th>
            {displayType === "recent" && <th className="hide-m">Folder</th>}
            {displayType === "folders" && <th>Parent</th>}
          </tr>
        </thead>
        <tbody>
          {displayType === "recent" && renderFileList(files, loading, true)}
          {displayType === "folders" &&
            folders.map((folder) => <FolderComp folder={folder} time={new Date(folder.createdDate)} key={folder.id} />)}
        </tbody>
      </table>
    </>
  )
}

export default React.memo(FileFolderTableView)
