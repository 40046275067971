/* eslint-disable */
import React from "react"
import messagePanel from "modules/shared/styles/components/messagePanel.module.scss"
import button from "modules/shared/styles/components/button.module.scss"
import styles from "./MessageFallbackComponent.module.scss"
import cn from "clsx"

const MessageFallbackComponent: React.FC<{ error: Error; resetErrorBoundary: () => void }> = ({
  error,
  resetErrorBoundary
}) => {
  return (
    <div className={messagePanel.root}>
      <div className={styles.messageFallbackRoot}>
        <div className={styles.messageFallback}>
          <i className="material-icons">error</i>
          <div className={styles.meta}>
            <span className={styles.title}>Oops!</span>
            <span className={styles.description}>We are having some issues showing this result...</span>
          </div>
          <button className={cn(button.button, button.primary, button.noAnimation)} onClick={resetErrorBoundary}>
            <i className="material-icons">refresh</i>
            <span>Reload Result</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default MessageFallbackComponent
