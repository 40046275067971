/* eslint-disable */
import React, { useContext } from "react"
import Popup from "reactjs-popup"
import cn from "clsx"
import button from "modules/shared/styles/components/button.module.scss"
import menu from "modules/shared/styles/components/menu.module.scss"
import styles from "../../folderTreeView/FolderTreeView.module.scss"
import { FileListingContext } from "../../fileListing/FileListing"
import pubsub from "pubsub-js"
import { topics } from "consts"

const DirectoryOptionsMenu: React.FC<{
  startRenaming?: () => void
  folderId: string
  isRoot: boolean
  newFolder?: () => void
}> = ({ startRenaming, folderId, isRoot, newFolder }) => {
  const { deleteItem, startUploading } = useContext(FileListingContext)
  return (
    <Popup
      arrow={false}
      trigger={
        <button
          className={cn(button.button, button.primary, button.outline, button.small, styles.folderIconButton)}
          title="More Options"
        >
          <i className="fa fa-ellipsis-h fa-fw"></i>
        </button>
      }
      position="left top"
      contentStyle={{ padding: 0 }}
    >
      {((close: () => void) => (
        <div className={cn(menu.menu, menu.leftTop)}>
          <div
            className={menu.item}
            onClick={() => {
              if (newFolder) newFolder()
              close()
            }}
          >
            <i className="material-icons">folder</i>
            <span>Create New Folder</span>
          </div>
          <div
            className={menu.item}
            onClick={() => {
              startUploading(folderId)
              close()
            }}
          >
            <i className="material-icons">file_upload</i>
            <span>Upload into Folder</span>
          </div>
          <div
            className={menu.item}
            onClick={() => {
              pubsub.publish(topics.OPEN_CREATE_NEW_DOCUMENT, folderId)
              close()
            }}
          >
            <i className="fa fa-file-text-o"></i>
            <span>Create New Document</span>
          </div>
          {!isRoot && (
            <div
              className={menu.item}
              onClick={() => {
                if (startRenaming) startRenaming()
                close()
              }}
            >
              <i className="material-icons">input</i>
              <span>Rename Folder</span>
            </div>
          )}
          {!isRoot && (
            <>
              <div className={menu.separator}></div>
              <div
                className={cn(menu.item, menu.danger)}
                onClick={() => {
                  deleteItem(folderId, "folder")
                }}
              >
                <i className="material-icons">delete</i>
                <span>Delete Folder</span>
              </div>
            </>
          )}
        </div>
      )) as any}
    </Popup>
  )
}

export default DirectoryOptionsMenu
