import { Store } from "react-notifications-component"
export let undoMessageDelete = false
export const defaultDuration = 3000
/**
 * Show notification message
 * @param title Title of the message.
 * @param message Message (content) of the message
 */
export function showInfoNotification(title: string, message: string): void {
  Store.addNotification({
    title,
    message,
    type: "info",
    insert: "top",
    container: "top-right",
    dismiss: {
      duration: defaultDuration,
      onScreen: true
    }
  })
}
/**
 * Show error notification.
 * @param title Error title.
 * @param message Error message.
 */
export function showErrorNotification(title: string, message: string): void {
  Store.addNotification({
    title,
    message,
    type: "danger",
    insert: "top",
    container: "top-right",
    dismiss: {
      duration: defaultDuration,
      onScreen: true
    }
  })
}
/**
 * Creates a undo message notification.
 * Allows a deleted message to be retracted.
 * @param title Error title.
 * @param message Error message.
 */
export function undoMessageHint(title: string, message: string): void {
  Store.addNotification({
    title,
    message,
    type: "warning",
    insert: "top",
    container: "top-right",
    dismiss: {
      duration: defaultDuration + 2000, // 5 seconds
      onScreen: true
    },
    onRemoval: (id: string, removalFlag: string) => {
      if (removalFlag === "click") {
        window.location.reload()
        undoMessageDelete = true
      }
      setTimeout(() => (undoMessageDelete = false), defaultDuration + 2000) // 5 seconds
    }
  })
}
