/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { lazy, Suspense } from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import { routes } from "consts"
import Root from "../root"
import SuspensePreloader from "modules/shared/components/suspensePreloader"
import Chat from "modules/chat/components/chat"

const Home = lazy(() => import("modules/home/components/home"))
const Register = lazy(() => import("modules/auth/components/register"))
// const Chat = lazy(() => import("modules/chat/components/chat"))
const SignIn = lazy(() => import("modules/auth/components/signIn"))
const People = lazy(() => import("modules/people/components/people"))
const Tasks = lazy(() => import("modules/tasks/components/tasksHome"))
const TaskBoard = lazy(() => import("modules/tasks/components/taskBoard"))
const Wikis = lazy(() => import("modules/wiki/components/wikisHome"))
const Wiki = lazy(() => import("modules/wiki/components/wiki"))
const Notes = lazy(() => import("modules/notes/components/notes"))
const Journal = lazy(() => import("modules/journal/components/journal"))
const Dashboard = lazy(() => import("modules/dashboard/components/dashboard"))
const WebMail = lazy(() => import("modules/webMail/components/Mail"))
const Calendar = lazy(() => import("modules/calendar/components/Calendar"))
const Apps = lazy(() => import("modules/appStore/components/Apps"))
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// const DocumentEditor = lazy(() => import("@txtextcontrol/tx-react-ds-document-editor"))

const Suspended = (Component: React.ComponentType<any>) =>
  function Comp() {
    return (
      <Suspense fallback={<SuspensePreloader />}>
        <Component />
      </Suspense>
    )
  }

const renderChild = (Component: React.ComponentType, props: any) => <Component {...props} key={props.location.key} />

export const WrappedRoute: React.FC<{
  component: React.ComponentType
  isolated?: boolean
  path: string
}> = ({ component: Component, isolated = false, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (isolated ? renderChild(Component, props) : <Root>{renderChild(Component, props)}</Root>)}
    />
  )
}
// const TXEditor: React.FC = () => {
//   return (
//     <DocumentEditor
//       style={{ width: "100%", height: "calc(100vh - 25px)" }}
//       serviceURL="https://textcontrol.pretzel.space/DocumentServices"
//       authSettings={{
//         clientId: "dsserver.HpaFaquwJVx4RV6iqm39o8UWzSxckryw",
//         clientSecret: "LRHX9006cCKfnZTMIidf3b84a6rTHtSV"
//       }}
//     ></DocumentEditor>
//   )
// }
const App: React.FC = () => {
  return (
    <Router>
      <Switch>
        <WrappedRoute path={routes.signin} component={Suspended(SignIn)} isolated />
        <WrappedRoute path={routes.register} component={Suspended(Register)} isolated />
        <WrappedRoute path={routes.chat} component={Suspended(Chat)} />
        <WrappedRoute path={routes.people} component={Suspended(People)} />
        <WrappedRoute path={routes.taskBoard} component={Suspended(TaskBoard)} />
        <WrappedRoute path={routes.tasks} component={Suspended(Tasks)} />
        <WrappedRoute path={routes.wiki} component={Suspended(Wiki)} />
        <WrappedRoute path={routes.wikis} component={Suspended(Wikis)} />
        <WrappedRoute path={routes.notes} component={Suspended(Notes)} />
        <WrappedRoute path={routes.journal} component={Suspended(Journal)} />
        <WrappedRoute path={routes.dashboard} component={Suspended(Dashboard)} />
        <WrappedRoute path={"/mail"} component={Suspended(WebMail)} />
        <WrappedRoute path={"/calendar"} component={Suspended(Calendar)} />
        <WrappedRoute path={"/apps"} component={Suspended(Apps)} />
        {/* <WrappedRoute path={"/tx"} component={Suspended(TXEditor)} /> */}
        <WrappedRoute path={routes.home} component={Suspended(Home)} />
      </Switch>
    </Router>
  )
}

export default App
