/* eslint-disable */
import { useCallback, useState } from "react"
import { usePretzelTextEditor_UploadTempFileMutation } from "generated/graphql"
import { showErrorNotification } from "utils/notification"
import { handleGraphQlError, uploadFileToS3 } from "utils/common"
import { useChat_FinishImageUploadMutation, useFileListing_OpenFileMutation } from "generated/graphql"

type UploadFileType = {
  file: File
  status: "pending" | "uploading" | "uploaded" | null
  name: string
  uploadProgress: number
  uploadId: string
  url: string
  renaming: boolean
}

export function useUploadImageIntoChat(): {
  handleFilePaste: (file: File) => unknown
  file: UploadFileType
  startRenaming: () => unknown
  changeName: (name: string) => unknown
  doneRenaming: (cancel: boolean) => unknown
  done: (cancel: boolean) => unknown
} {
  const [uploadingImage, setUploadingImage] = useState<UploadFileType>({} as any)

  const [uploadTempFile] = usePretzelTextEditor_UploadTempFileMutation({
    onError: handleGraphQlError("Unable to upload")
  })
  const [finishImageUpload] = useChat_FinishImageUploadMutation({
    onError: handleGraphQlError("Unable to finish upload")
  })
  const [openFile] = useFileListing_OpenFileMutation({
    onError: handleGraphQlError("Unable to open file")
  })
  const changeFileName = useCallback(
    (name: string) => {
      setUploadingImage((s) => ({
        ...s,
        name: name
      }))
    },
    [setUploadingImage]
  )

  const startRenaming = useCallback(() => setUploadingImage((s) => ({ ...s, renaming: true })), [setUploadingImage])
  const doneRenaming = useCallback(
    (cancel: boolean) => {
      if (cancel) {
        setUploadingImage((s) => ({ ...s, name: s.file.name, renaming: false }))
      } else {
        setUploadingImage((s) => {
          if (s.name) {
            return { ...s, renaming: false }
          } else {
            return { ...s, name: s.file.name, renaming: false }
          }
        })
      }
    },
    [setUploadingImage]
  )

  const done = useCallback(
    async (cancel: boolean) => {
      if (!cancel) {
        const result = await finishImageUpload({
          variables: {
            name: uploadingImage.name,
            size: uploadingImage.file.size,
            uploadId: uploadingImage.uploadId
          }
        })

        const id = result.data?.finishUploadImage.id
        if (id) {
          await openFile({
            variables: {
              fileId: id
            }
          })
        }
      }
      setUploadingImage((s) => ({ ...s, status: null }))
    },
    [finishImageUpload, setUploadingImage, uploadingImage]
  )

  const handleSelectFile = useCallback(
    async (file: File) => {
      if (!file.type.startsWith("image") || file.size > 30000000) {
        return
      }
      const uploadData = await uploadTempFile({
        variables: {
          fileName: file.name,
          fileType: file.type,
          fileSize: file.size
        }
      })

      if (uploadData.errors) {
        showErrorNotification("Unable to upload file: " + file.name, "")
      }

      const dataUrl = await new Promise<string>((resolve, reject) => {
        const fr = new FileReader()
        fr.onloadend = () => {
          resolve(fr.result as string)
        }
        fr.readAsDataURL(file)
      })

      setUploadingImage({
        file,
        status: "pending",
        name: file.name,
        uploadProgress: 0,
        uploadId: "",
        url: dataUrl,
        renaming: false
      })

      setUploadingImage((state) => ({
        ...state,
        uploadId: uploadData.data?.uploadTempFile?.uploadId || "",
        status: "uploading"
      }))

      uploadFileToS3(uploadData.data?.uploadTempFile?.postData, file, (progress) => {
        setUploadingImage((a) => ({
          ...a,
          uploadProgress: progress
        }))
      }).then(() => {
        setUploadingImage((a) => ({
          ...a,
          progress: 100,
          status: "uploaded"
        }))
      })
    },
    [uploadingImage, setUploadingImage]
  )

  return {
    handleFilePaste: handleSelectFile,
    changeName: changeFileName,
    file: uploadingImage,
    startRenaming,
    doneRenaming,
    done
  }
}
