// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function template(title: string, sub?: string): any {
  return JSON.stringify({
    version: "14.1.2",
    customList: [],
    sheets: {
      Sheet1: {
        name: "Sheet1",
        isSelected: true,
        activeCol: 1,
        frozenTrailingRowStickToEdge: true,
        frozenTrailingColumnStickToEdge: true,
        theme: "Office",
        data: {
          dataTable: {
            "0": {
              "0": {
                value: title,
                style: {
                  font: "bold 11pt Calibri"
                }
              },
              "1": sub
                ? {
                    value: sub || "",
                    style: {
                      foreColor: "Background 1 -50",
                      font: "bold 11pt Calibri"
                    }
                  }
                : {}
            },
            "1": {
              "0": {
                value: "",
                style: {
                  foreColor: "Background 1 -50",
                  font: "bold 11pt Calibri"
                }
              },
              "1": sub
                ? {
                    value: "",
                    style: {
                      foreColor: "Background 1 -50",
                      font: "bold 11pt Calibri"
                    }
                  }
                : {}
            }
          },
          defaultDataNode: {
            style: {
              themeFont: "Body"
            }
          }
        },
        rowHeaderData: {
          defaultDataNode: {
            style: {
              themeFont: "Body"
            }
          }
        },
        colHeaderData: {
          defaultDataNode: {
            style: {
              themeFont: "Body"
            }
          }
        },
        columns: [
          {
            size: 147.137939453125
          },
          {
            size: 106.137939453125
          }
        ],
        leftCellIndex: 0,
        topCellIndex: 0,
        selections: {
          "0": {
            row: 0,
            rowCount: 1,
            col: 1,
            colCount: 1
          },
          length: 1
        },
        rowOutlines: {
          items: []
        },
        columnOutlines: {
          items: []
        },
        cellStates: {},
        states: {},
        outlineColumnOptions: {},
        autoMergeRangeInfos: [],
        printInfo: {
          paperSize: {
            width: 850,
            height: 1100,
            kind: 1
          }
        },
        shapeCollectionOption: {
          snapMode: 0
        },
        index: 0
      }
    }
  })
}
