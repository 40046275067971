/* eslint-disable */
import React, { useEffect, useRef, useState } from "react"
import styles from "./TextDocument.module.scss"
import editorHeader from "./../editorHeader/EditorHeader.module.scss"

/* Tiny but not so tiny*/
import "tinymce/tinymce"
import "tinymce/icons/default"
import "tinymce/themes/silver"
import "tinymce/plugins/advlist"
import "tinymce/plugins/autolink"
import "tinymce/plugins/lists"
import "tinymce/plugins/link"
import "tinymce/plugins/image"
import "tinymce/plugins/charmap"
import "tinymce/plugins/print"
import "tinymce/plugins/preview"
import "tinymce/plugins/anchor"
import "tinymce/plugins/searchreplace"
import "tinymce/plugins/visualblocks"
import "tinymce/plugins/code"
import "tinymce/plugins/fullscreen"
import "tinymce/plugins/insertdatetime"
import "tinymce/plugins/media"
import "tinymce/plugins/table"
import "tinymce/plugins/paste"
import "tinymce/plugins/code"
import "tinymce/plugins/help"
import "tinymce/plugins/wordcount"

import pubsub from "pubsub-js"
import cn from "clsx"

import menu from "modules/shared/styles/components/menu.module.scss"

import {
  useTextDocument_UploadImageMutation,
  useAppSpaces_UpgradeMutation,
  useAppSpaces_DowngradeMutation,
  useAppSpaces_ExportFileMutation,
  useAppSpaces_SetReadOnlyMutation,
  useAppSpaces_FinishUploadExportedAppSpaceMutation,
  useFileListing_UploadFileMutation,
  useChat_ChatMutation,
  FileListing_DownloadFileDocument,
  useTextDocuementQuery,
  useFileListing_RenameFileMutation
} from "generated/graphql"

import { Editor } from "@tinymce/tinymce-react"

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import DocumentEditor from "@txtextcontrol/tx-react-ds-document-editor"
import { ITextData } from "../../helpers/IData"
import Popup from "reactjs-popup"

import EditorHeader from "../editorHeader"
import { topics } from "consts"
import { useApolloClient } from "@apollo/client"
import { handleGraphQlError, uploadFileToS3 } from "utils/common"

interface IState {
  edited: (content: string, id: string) => void
  content: string
  id: string
  oninit: () => void
  kind: string
  readOnly: boolean
  convertType?: string
}

const TextDocument: React.FC<IState> = ({ convertType, content, edited, id, oninit, kind, readOnly }) => {
  const [localContent, setLocalContent] = useState(content)
  const [editorMode, setEditorMode] = useState(kind === "TEXTX" ? "tx" : "mce")
  const [readOnlyMode, setReadOnlyMode] = useState(readOnly || false)

  const [exporting, setExporting] = useState(false)
  const [upgrading, setUpgrading] = useState(false)
  const [autoRenameDoc, setAutoRenameDoc] = useState("")
  const [debouncedTitle, setDebouncedTitle] = useState(autoRenameDoc)

  const [upgradeDoc] = useAppSpaces_UpgradeMutation({
    onError: handleGraphQlError("Unable to upgrade document")
  })
  const [downgradeDoc] = useAppSpaces_DowngradeMutation({
    onError: handleGraphQlError("Unable to upgrade document")
  })
  const [exportFile] = useAppSpaces_ExportFileMutation({
    onError: handleGraphQlError("Unable to export app space")
  })
  const [gqUpdateReadOnlyMode] = useAppSpaces_SetReadOnlyMutation({
    onError: handleGraphQlError("Unable to make app space read-only")
  })
  const [uploadAppSpace] = useFileListing_UploadFileMutation({
    onError: handleGraphQlError("Unable to upload file")
  })
  const [finishUploadExportedAppSpace] = useAppSpaces_FinishUploadExportedAppSpaceMutation({
    onError: handleGraphQlError("Unable to upload file")
  })
  const [chat] = useChat_ChatMutation({
    onError: handleGraphQlError("Unable to send the message")
  })
  const [renameFile] = useFileListing_RenameFileMutation({
    onError: handleGraphQlError("Unable to rename file")
  })
  const { data: txtData } = useTextDocuementQuery({
    onError: handleGraphQlError("Unable to get text")
  })

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      if (autoRenameDoc !== debouncedTitle) {
        setDebouncedTitle(autoRenameDoc)
      }
    }, 800)
    return () => clearTimeout(debounceTimer)
  }, [autoRenameDoc, debouncedTitle])

  useEffect(() => {
    if (debouncedTitle) {
      renameFile({
        variables: { fileId: id, newName: String(debouncedTitle) }
      }).then(() => {
        pubsub.publish(topics.FILE_LISTING_RELOAD)
        setTimeout(() => pubsub.publish(topics.FILE_LISTING_RELOAD), 1500)
        setTimeout(() => pubsub.publish(topics.FILE_LISTING_RELOAD), 2000)
      })
    }
  }, [debouncedTitle])

  useEffect(() => {
    if (editorMode === "mce") {
      setLocalContent(content)
    }
  }, [content])

  // console.log(content, convertType)
  const [uploadImage] = useTextDocument_UploadImageMutation({
    onError: handleGraphQlError("Unable to upload image")
  })

  const editor = useRef<Editor>(null)

  const afterTXControlInit = async (fn: (tx: any) => void) => {
    // !Object.prototype.hasOwnProperty.call(window as any, "TXTextControl")
    while (!(window as any).TXTextControl) {
      await new Promise((resolve) => setTimeout(resolve, 1000))
    }
    fn((window as any).TXTextControl)
  }

  const afterTinyMCEInit = async (fn: (tinymce: any) => void) => {
    const tmc = (window as any).tinymce
    if (tmc) {
      fn(tmc)
    }
  }

  // Handles content insertion into text editors
  async function insertContent(content: string) {
    if (editorMode === "mce") {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      afterTinyMCEInit((tinymce) => {
        tinymce.execCommand("mceInsertContent", false, content)
      })
    } else {
      afterTXControlInit((txControl) => {
        txControl.appendDocument(txControl.StreamType.HTMLFormat, btoa(content))
      })
    }
  }

  // Activates read only mode on both text editors
  async function updateReadOnlyMode() {
    if (editorMode === "tx") {
      afterTXControlInit((txControl) => {
        txControl.setEditMode(readOnlyMode ? txControl.EditMode.ReadOnly : txControl.EditMode.Edit)
        // txControl.setEditMode(readOnlyMode ? "view" : "edit")
      })
    }

    if (editorMode === "mce") {
      afterTinyMCEInit((tinymce) => {
        if (tinymce.activeEditor) {
          tinymce.activeEditor.mode.set(readOnlyMode ? "readonly" : "design")
        }
      })
    }
  }

  // Render read only mode on change
  useEffect(() => {
    updateReadOnlyMode()
  }, [readOnlyMode])

  // Insertion of data into Text Document
  const appendTitle = (data: ITextData, content: string): string => {
    if (data.title) {
      if (data.type === "image" || data.type === "content") return `<h3>${data.title}</h3>` + content
    }

    return content
  }

  const getInnerContent = (data: ITextData): string => {
    switch (data.type) {
      case "image":
        return `<img width="500" src="${data.content}" alt="Content" />`
      case "content":
        return data.content
      case "table":
        return ""
      default:
        return `<br>${data.content}`
    }
  }

  const processContent = (data: ITextData) => {
    return appendTitle(data, getInnerContent(data))
  }

  const apolloClient = useApolloClient()

  useEffect(() => {
    if (editorMode) {
      if (editorMode !== "tx") {
        oninit()
      }
    }

    const token = pubsub.subscribe(topics.INSERT_CONTENT_TO_DOCUMENT, (_: string, data: ITextData) => {
      if (data.type === "image") {
        insertContent(processContent(data))
      } else {
        insertContent(processContent(data))
      }
    })

    afterTXControlInit((txControl) => {
      oninit()

      if (txControl) {
        txControl.addEventListener("textControlLoaded", () => {
          if (convertType) {
            let stype = txControl.StreamType.WordprocessingML

            if (convertType === "DOCX") {
              stype = txControl.StreamType.WordprocessingML
            } else if (convertType === "DOC") {
              stype = txControl.StreamType.MSWord
            }

            apolloClient
              .query({
                query: FileListing_DownloadFileDocument,
                variables: {
                  id: id
                }
              })
              .then((x) => {
                const url = x.data.downloadFile.url

                fetch(url, {
                  method: "GET",
                  redirect: "manual"
                })
                  .then((response) => response.blob())
                  .then((blob) => {
                    const reader = new FileReader()
                    reader.readAsDataURL(blob)
                    reader.onloadend = function () {
                      const base64data = reader.result
                      txControl.loadDocument(stype, (base64data as string).split(",")[1])
                    }
                  })
                  .catch((error) => console.log("error", error))
              })
          } else {
            txControl.loadDocument(txControl.StreamType.HTMLFormat, btoa(content))
          }
          updateReadOnlyMode()

          txControl.addEventListener("textControlChanged", () => {
            updateReadOnlyMode()
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            txControl.saveDocument(txControl.StreamType.HTMLFormat, (e: any) => {
              edited(e.data, id)
            })
          })

          txControl.addEventListener("ribbonTabsLoaded", () => {
            updateReadOnlyMode()
          })
        })
      }
    })

    return () => {
      pubsub.unsubscribe(token)
    }
  }, [])

  useEffect(() => {
    if (editorMode === "tx") {
      initTX()
      return cleanup
    }
  }, [])

  // Initialize the TX Text Control
  const initTX = () => {
    afterTXControlInit((txControl) => {
      if (txControl) {
        txControl.addEventListener("textControlLoaded", () => {
          txControl.loadDocument(txControl.StreamType.HTMLFormat, btoa(content))

          txControl.addEventListener("textControlChanged", () => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            txControl.saveDocument(txControl.StreamType.HTMLFormat, (e: any) => {
              edited(e.data, id)
            })
          })
        })
      }
    })
  }

  // Cleans up after TX Text Control
  const cleanup = () => {
    afterTXControlInit((txControl) => {
      if (txControl) {
        txControl.removeFromDom()
        ;(window as any).TXTextControl = null
      }
    })
  }

  // TinyMCE Upload image handler
  const upload = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    blob: Blob,
    success: (url: string) => void,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    failure: (err: string, options?: any) => void,
    progress?: ((percent: number) => void) | undefined
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    uploadImage({ variables: { filename: null, fileType: "image/png" } }).then((res: any) => {
      const data = res.data?.uploadImage
      if (!data) {
        return
      }

      const formData = new FormData()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const postData = JSON.parse(data.postData) as any
      Object.keys(postData.fields).forEach((key) => {
        formData.append(key, postData.fields[key])
      })

      // Actual file has to be appended last.
      formData.append("file", blob)
      const xhr = new XMLHttpRequest()
      xhr.open("POST", postData.url, true)

      xhr.upload.onprogress = function (e) {
        if (progress) progress((e.loaded / e.total) * 100)
      }

      xhr.onload = function () {
        if (xhr.status === 403) {
          failure("HTTP Error: " + xhr.status, { remove: true })
          return
        }

        if (xhr.status < 200 || xhr.status >= 300) {
          failure("HTTP Error: " + xhr.status)
          return
        }

        success(data.getData)
      }

      xhr.send(formData)
    })
  }

  // TinyMCE Upload image handler container
  const uploadHandle = async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    blobInfo: any,
    success: (url: string) => void,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    failure: (err: string, options?: any) => void,
    progress: ((percent: number) => void) | undefined
  ) => {
    upload(blobInfo.blob(), success, failure, progress)
  }

  const b64toBlob = (b64Data: string, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data)
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize)

      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }

    return new Blob(byteArrays, { type: contentType })
  }

  const handleExportPDF = () => {
    if (editorMode === "mce") {
      exportFile({ variables: { id: id, content: localContent, targetType: "pdf" } }).then(() => {
        setExporting(false)
        pubsub.publish(topics.UPDATE_FILES)
      })
    }

    if (editorMode === "tx") {
      afterTXControlInit((txControl) => {
        if (txControl) {
          txControl.saveDocument(txControl.StreamType.AdobePDF, (e: any) => {
            const blob = b64toBlob(e.data, "application/pdf")
            uploadDocument(blob, "application/pdf")
          })
        }
      })
    }
  }

  const handleExportMSWord = () => {
    if (editorMode === "mce") {
      //asd
    }

    if (editorMode === "tx") {
      afterTXControlInit((txControl) => {
        if (txControl) {
          txControl.saveDocument(txControl.StreamType.MSWord, (e: any) => {
            const blob = b64toBlob(e.data, "application/msword")
            uploadDocument(blob, "application/msword")
          })
        }
      })
    }
  }

  const uploadDocument = async (blob: Blob, type: string) => {
    const appSpace = txtData?.activeAppSpace
    const addition = type === "application/pdf" ? ".pdf" : ".doc"

    const r = await uploadAppSpace({
      variables: {
        fileName: (appSpace?.name || "Exported File") + addition,
        fileType: type,
        folder: null,
        fileSize: blob.size
      }
    })

    const data = r.data?.uploadFile
    if (!data) {
      return
    }

    await uploadFileToS3(data.postData, blob)

    // finish upload exported appspace
    await finishUploadExportedAppSpace({
      variables: { uploadId: data.uploadId, masterId: appSpace?.id || "" }
    })

    pubsub.publish(topics.UPDATE_FILES)
    setExporting(false)
  }

  const exportDocument = (type: string) => {
    setExporting(true)

    if (type === "pdf") {
      handleExportPDF()
    }
    if (type === "msword") {
      handleExportMSWord()
    }
  }

  // Changes text editor mode
  const changeEditorMode = async (mode: string) => {
    if (editorMode === "mce" && mode === "tx") {
      setUpgrading(true)
      await upgradeDoc({ variables: { id: id } })
      pubsub.publish(topics.UPDATE_FILES)
    }

    if (editorMode === "tx" && mode === "mce") {
      setUpgrading(true)
      await downgradeDoc({ variables: { id: id } })
      pubsub.publish(topics.UPDATE_FILES)
    }

    if (mode === "mce") {
      cleanup()
    }

    if (mode === "tx") {
      initTX()
    }

    setEditorMode(mode)
    setUpgrading(false)
  }

  const toggleReadOnlyMode = () => {
    if (convertType === "DOC" || convertType === "DOCX") {
      setUpgrading(true)
      afterTXControlInit((txControl: any) => {
        txControl.saveDocument(txControl.StreamType.HTMLFormat, (e: any) => {
          const html = e.data
          gqUpdateReadOnlyMode({ variables: { id: id, readOnly: !readOnlyMode, html } }).then((d: any) => {
            const appSpace = d.data?.updateReadOnly

            if (appSpace) {
              // pubsub.publish(topics.UPDATE_FILES)
              edited(html, appSpace.id)
              // setTimeout(() => {
              //   selectAppSpace({
              //     variables: {
              //       id: appSpace.id
              //     }
              //   })
              // }, 250)
            }
          })
        })
      })
    } else {
      gqUpdateReadOnlyMode({ variables: { id: id, readOnly: !readOnlyMode } }).then(() => {
        // selectAppSpace({
        //   variables: {
        //     id
        //   }
        // })
      })
      setReadOnlyMode(!readOnlyMode)
    }
  }
  // reference from Sidebar and Chat components
  const handleCommands = (message: string) => {
    pubsub.publish(topics.SIDEBAR_ACTION, { message, silent: true })
  }

  const exportToChatLane = () => {
    chat({
      variables: {
        message: "",
        silent: true,
        type: "appSpacePreview"
      }
    })
  }

  const autoRename = (e: string) => {
    const sanitize = (html: string) => {
      let plain = new DOMParser().parseFromString(html, "text/html")
      return plain.body.textContent || ""
    }
    const documentTitle = e.match(/<h1>(.*?)<\/h1>/)
    if (documentTitle) {
      const content = sanitize(documentTitle[1])
      setAutoRenameDoc(content)
    }
  }

  return (
    <div className={styles.root} key={editorMode}>
      <EditorHeader
        key={id}
        centerMenu={
          <>
            <Popup
              arrow={false}
              trigger={
                <div
                  className={editorHeader.item}
                  style={{ opacity: exporting ? 0.5 : 1, pointerEvents: exporting ? "none" : undefined }}
                >
                  <i className="material-icons">import_export</i>
                  <span>Export as</span>

                  <i className="material-icons">expand_more</i>
                </div>
              }
              contentStyle={{ padding: 0 }}
              position="bottom left"
            >
              {
                ((close: () => void) => (
                  <div className={cn(menu.menu, menu.leftTop)}>
                    <div
                      className={menu.item}
                      onClick={() => {
                        exportDocument("pdf")
                        close()
                      }}
                    >
                      <i className="material-icons">picture_as_pdf</i>
                      Export as PDF
                    </div>
                    <div
                      className={cn([menu.item, editorMode === "mce" ? menu.disabled : null])}
                      onClick={() => {
                        exportDocument("msword")
                        close()
                      }}
                    >
                      <i className="material-icons">description</i>
                      Export as Word Document
                    </div>
                  </div>
                )) as any
              }
            </Popup>
            <div
              className={editorHeader.item}
              style={{ opacity: exporting ? 0.5 : 1, pointerEvents: exporting ? "none" : undefined }}
              onClick={exportToChatLane}
            >
              <i className="material-icons">arrow_back</i>
              <span>Add to Chat Lane</span>
            </div>
            <Popup
              arrow={false}
              trigger={
                <div
                  className={editorHeader.item}
                  style={{ opacity: exporting ? 0.5 : 1, pointerEvents: exporting ? "none" : undefined }}
                >
                  <i className="material-icons">memory</i>
                  <span>Smart</span>

                  <i className="material-icons">expand_more</i>
                </div>
              }
              contentStyle={{ padding: 0 }}
              position="bottom left"
            >
              {
                ((close: () => void) => (
                  <div className={cn(menu.menu, menu.leftTop)}>
                    <div
                      className={menu.item}
                      onClick={() => {
                        handleCommands("referencing")
                        close()
                      }}
                    >
                      <i className="material-icons">source</i>
                      Find bibliographical refs
                    </div>
                    <div
                      className={menu.item}
                      onClick={() => {
                        handleCommands("summarize")
                        close()
                      }}
                    >
                      <i className="material-icons">assignment</i>
                      Summarize
                    </div>
                    <div
                      className={menu.item}
                      onClick={() => {
                        handleCommands("extract table")
                        close()
                      }}
                    >
                      <i className="material-icons">table_chart</i>
                      Extract tables
                    </div>
                    <div
                      className={menu.item}
                      onClick={() => {
                        handleCommands("flash cards")
                        close()
                      }}
                    >
                      <i className="material-icons">style</i>
                      Create flash cards
                    </div>
                  </div>
                )) as any
              }
            </Popup>
          </>
        }
        rightMenu={
          <>
            <span className={editorHeader.label}>Mode </span>
            <div className={cn([editorHeader.switcher, upgrading ? editorHeader.upgrading : null])}>
              {/* <span className={cn([readOnlyMode ? editorHeader.active : null])} onClick={toggleReadOnlyMode}>
                Read only
              </span> */}
              <span style={{ opacity: 0.5 }}> Read only </span>

              <span
                onClick={() => changeEditorMode("mce")}
                className={editorMode !== "tx" ? editorHeader.active : undefined}
              >
                Quick edit
              </span>

              <span
                onClick={() => changeEditorMode("tx")}
                className={editorMode === "tx" ? editorHeader.active : undefined}
              >
                Paginated edit
              </span>
            </div>
          </>
        }
      />

      <div className={styles.editorContainer}>
        {editorMode === "mce" && (
          <Editor
            ref={editor}
            init={{
              base_url: import.meta.env.VITE_BASE_URL,
              menubar: false,
              height: "100%",
              plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
                "image paste"
              ],
              setup: (ed) => {
                ed.on("init", () => {
                  setTimeout(() => {
                    ed.mode.set(readOnlyMode ? "readonly" : "design")
                  }, 250)
                })
              },
              automatic_uploads: true,
              convert_urls: false,
              paste_data_images: true,
              images_upload_handler: uploadHandle,
              toolbar:
                "undo redo | styleselect | fontselect | fontsizeselect | forecolor | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent | link image | code | table"
            }}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            value={localContent}
            onEditorChange={(c) => {
              setLocalContent(c)
              edited(c, id)
              autoRename(c)
            }}
            onSelectionChange={() => {
              afterTinyMCEInit((tinymce) => {
                const range = tinymce.activeEditor.selection.getRng()
                localStorage.setItem("activeAppSpaceSelected", `${range.startOffset},${range.endOffset}`)
              })
            }}
          />
        )}
        {editorMode === "tx" && (
          <DocumentEditor
            style={{ width: "100%", height: "calc(100vh - 25px)" }}
            serviceURL="https://textcontrol.pretzel.space/DocumentServices"
            authSettings={{
              clientId: "dsserver.HpaFaquwJVx4RV6iqm39o8UWzSxckryw",
              clientSecret: "LRHX9006cCKfnZTMIidf3b84a6rTHtSV"
            }}
          ></DocumentEditor>
        )}
      </div>
    </div>
  )
}

export default TextDocument
