import React from "react"
import pubsub from "pubsub-js"
import { useInsertButtonQuery } from "generated/graphql"
import { topics } from "consts"

const InsertButton: React.FC<{
  type: string
  content?: string
  title?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getContent?: () => any
  children: React.ReactNode
}> = ({ children, type, title, content, getContent }) => {
  const { data } = useInsertButtonQuery()

  if (!data) {
    return null
  }
  const activeSpace = data?.activeAppSpace

  if (
    !activeSpace ||
    (activeSpace.type !== "sheet" && activeSpace.type !== "doc") ||
    (type === "table" && activeSpace?.type !== "sheet") ||
    (type !== "table" && activeSpace.type === "sheet")
  ) {
    return null
  }
  function handleClick() {
    pubsub.publish(topics.INSERT_CONTENT_TO_DOCUMENT, {
      type,
      content: content || (getContent ? getContent() : ""),
      title
    })
  }
  return (
    <div
      onClick={(e) => {
        handleClick()
        e.preventDefault()
        e.stopPropagation()
      }}
      style={{ display: "inline" }}
    >
      {children}
    </div>
  )
}

export default InsertButton
