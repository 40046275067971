/* eslint-disable @typescript-eslint/no-explicit-any */
import { modeType } from "modules/chat/components/fileListing/components/fileListing/FileListing"
import React, { useState } from "react"
import { searchQuery } from "modules/chat/components/fileListing/components/searchControl/SearchControl"

const ExpandableRecentsList: React.FC<{
  items: any[]
  itemRenderer: (item: any, i: number) => JSX.Element
  showMoreLessLabel: (text: string, toggle: () => void) => JSX.Element
  itemTypeName: string
  disableSort?: boolean
  modifiedDate?: boolean
}> = ({ items, itemRenderer, showMoreLessLabel, itemTypeName }) => {
  const [all, setAll] = useState(false)
  const moreLessLabel = () => {
    if (modeType === "all") return `Show ${items.filter((x: any) => x.type !== "taskBoard").length} more`
    return `Show all ${items.filter((x: any) => x.type !== "taskBoard").length} ${itemTypeName}`
  }
  const expandLabel = () => {
    return (
      (all || showMaxFiles.length < items.length) && (
        <>
          {showMoreLessLabel(all ? `Show recent ${itemTypeName} only` : moreLessLabel(), () => {
            setAll(!all)
          })}
        </>
      )
    )
  }
  const limit_3_files = (files: any[], orderModeRecent: boolean | undefined): any[] => {
    if (files.length > 3) {
      if (orderModeRecent) {
        return files.slice(0, 3)
      } else return files
    } else {
      return files
    }
  }
  let showMaxFiles = limit_3_files(items, !all)

  // Display all files if user has opened a folder or user is searching
  if (searchQuery) showMaxFiles = items

  return (
    <>
      {all === true ? (
        <div style={{ display: showMaxFiles.length < items.length ? "none" : "block" }}>{expandLabel()}</div>
      ) : null}

      {showMaxFiles.map((x, i) => itemRenderer(x, i))}

      {all === false ? (
        <div style={{ display: showMaxFiles.length < items.length ? "block" : "none" }}>{expandLabel()}</div>
      ) : null}
    </>
  )
}

export default ExpandableRecentsList
