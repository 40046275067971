/* eslint-disable */
import React, { useCallback, useRef, useState, useEffect } from "react"
import { Editor as ReactEditor } from "@tinymce/tinymce-react"
import styles from "./PretzelTextEditor.module.scss"
import { EditorEvent } from "tinymce"
import pubsub from "pubsub-js"
import { useEnhancedEditor } from "./hook"
import { v4 as uuid } from "uuid"

import "tinymce/tinymce"
import "tinymce/icons/default"
import "tinymce/themes/silver"
import "tinymce/plugins/autolink"
import "tinymce/plugins/lists"
import "tinymce/plugins/anchor"
import "tinymce/plugins/autoresize"
import "tinymce/plugins/link"
import "tinymce/plugins/paste"

import EmojiSelector from "modules/shared/components/emojiSelector"
import { debounce } from "lodash"
import { isMobile } from "react-device-detect"
import { topics } from "consts"
import cn from "clsx"
import { savedMessageField } from "./hook"

import { mentionIcon, emojiIcon, sendIcon, applyIcon, attachmentIcon } from "modules/shared/components/icons"
import { humanizeFileSize, textEllipsis, uploadFileToS3 } from "utils/common"
import { showErrorNotification } from "utils/notification"
import { usePretzelTextEditor_UploadTempFileMutation } from "generated/graphql"
import DynamicPositionedPopup from "./dynamicPositionedPopup"

type FileType = {
  file: File
  name: string
  size: number
  state: "uploading" | "initial" | "uploaded"
  uploadId?: string
  progress: number
}

const PretzelTextEditor: React.FC<{
  onSubmit: (
    query: string,
    type?: string,
    silent?: boolean,
    richtext?: boolean,
    attachments?: { id: string; name: string; size: number; type: string }[]
  ) => unknown
  handleTyping?: (typing: boolean) => unknown
  placeholder?: string
  initialValue?: string | any
  attachments?: boolean
  apply?: boolean
  onCancel?: () => unknown
}> = ({ onSubmit, handleTyping, apply, attachments, initialValue, placeholder, onCancel }) => {
  const editor = useRef<ReactEditor>(null)
  const [emojiOpen, setEmojiOpen] = useState(false)
  const inputFiles = useRef<HTMLInputElement>(null)
  const [drag, setDrag] = useState(false)
  const unique = useRef(uuid())

  const tempAttachments = useRef<FileType[]>([])

  const [currentAttachments, setCurrentAttachments] = useState<FileType[]>([])
  const [uploadTempFile] = usePretzelTextEditor_UploadTempFileMutation()

  function setAttachments() {
    setCurrentAttachments(tempAttachments.current.map((a) => a))
  }

  function mapAttachment(file: File): FileType {
    return {
      file: file,
      state: "initial" as any,
      progress: 0,
      size: file.size,
      name: file.name
    }
  }

  async function addAttachment(files: FileList | null | undefined) {
    if (!files?.length) {
      return
    }
    const newlyAdded = Array.from(files)

    const selected: FileType[] = []
    for (const file of newlyAdded) {
      if (file.size > 30000000) {
        showErrorNotification(
          "File too large!",
          `We cannot upload files larger than 30 mb. The file you selected (${file.name}) is ${humanizeFileSize(
            file.size
          )}`
        )
      } else {
        selected.push(mapAttachment(file))
      }
    }

    const uploading: FileType[] = []
    for (const file of selected) {
      const uploadData = await uploadTempFile({
        variables: {
          fileName: file.file.name,
          fileType: file.file.type,
          fileSize: file.file.size
        }
      })

      if (uploadData.errors) {
        showErrorNotification("Unable to upload file: " + file.name, "")
      }

      file.uploadId = uploadData.data?.uploadTempFile?.uploadId
      file.state = "uploading"

      uploadFileToS3(uploadData.data?.uploadTempFile?.postData, file.file, (progress) => {
        tempAttachments.current = tempAttachments.current.map((a) => ({
          ...a,
          progress: a.uploadId === file.uploadId ? progress : a.progress
        }))
        setAttachments()
      }).then(() => {
        tempAttachments.current = tempAttachments.current.map((a) => ({
          ...a,
          progress: 100,
          state: "uploaded"
        }))
        setAttachments()
      })

      uploading.push(file)
    }

    tempAttachments.current = [...tempAttachments.current, ...uploading]
    setAttachments()
  }

  const changeHandler = async (event: any) => {
    addAttachment(inputFiles.current?.files)
  }

  function handleSubmit(q: string) {
    const content = q.trim()

    if (!content) {
      return
    }

    const uploading = tempAttachments.current.some((a) => a.state !== "uploaded")

    if (uploading) {
      return
    }

    if (q.trim() !== "") {
      onSubmit(
        q,
        undefined,
        undefined,
        true,
        (tempAttachments.current || []).map((a) => ({
          id: a.uploadId || "",
          name: a.name,
          size: a.size,
          type: a.file.type
        }))
      )

      setCurrentAttachments([])
      tempAttachments.current = []

      if (editor.current) {
        editor.current.editor?.setContent("")
      }
    }
  }

  const [onSetup] = useEnhancedEditor(
    (e: EditorEvent<KeyboardEvent>) => {
      if (!isMobile) {
        if (!(e.shiftKey || e.ctrlKey) && editor.current?.editor) {
          handleSubmit(editor.current.editor.getContent())
          e.preventDefault()
          e.stopPropagation()
        }
      }
    },
    () => {
      if (onCancel) {
        onCancel()
      }
    }
  )

  const timeout = useRef<NodeJS.Timeout>(0 as any)
  const handleKeyboard = useCallback(
    debounce(() => {
      if (handleTyping) {
        if (timeout.current) {
          clearTimeout(timeout.current)
        }
        timeout.current = setTimeout(() => {
          handleTyping(false)
        }, 1000)
        handleTyping(true)
      }
    }, 300),
    [handleTyping]
  )

  const firstRender = useRef(true)
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      const setField = setInterval(() => {
        if (
          editor.current?.editor &&
          !initialValue &&
          window.location.href.includes("/chat") &&
          savedMessageField.toUpperCase()
        ) {
          editor.current.editor.setContent(
            localStorage.getItem(`messageCache_${savedMessageField.toUpperCase()}`) || ""
          )
        }
      }, 10)
      setTimeout(() => clearInterval(setField), 400)
    }
  }, [initialValue])

  return (
    <div
      className={styles.container}
      onDrag={(evt) => {
        evt.preventDefault()
      }}
      onDragOver={(evt) => {
        setDrag(true)
        evt.preventDefault()
      }}
      onDragExit={(evt) => {
        setDrag(false)
        evt.preventDefault()
      }}
      onDragLeave={(evt) => {
        setDrag(false)
        evt.preventDefault()
      }}
      onDrop={(evt) => {
        setDrag(false)
        addAttachment(evt.dataTransfer.files)
      }}
    >
      <div className={styles.dropZone} />
      <DynamicPositionedPopup
        id={`emojiBtnActionPositioner_${unique}`}
        selector={`.${styles.root}[data-id="${unique}"] .tox-editor-container button[title="Add Emoji"]`}
        open={emojiOpen}
        setOpen={setEmojiOpen}
        content={(close: () => void) => (
          <EmojiSelector
            select={(key: string, icon: string) => {
              editor.current?.editor?.execCommand(
                "mceInsertContent",
                false,
                `<img class="p-emoji" style="transform: scale(1.4) translateY(3px); margin: 0 5px" src="${icon}" alt="${key}" width="17px" height="17px" />`
              )
              close()
            }}
            selected={[]}
          />
        )}
      />
      {attachments && (
        <input
          type="file"
          multiple
          ref={inputFiles}
          onChange={changeHandler}
          style={{ display: "none" }}
          accept="*.*"
        />
      )}
      <div className={cn([styles.root, drag ? styles.drag : null])} data-id={unique}>
        <div className={styles.attachments}>
          {currentAttachments.map((attachment, i) => (
            <div className={styles.attachment} key={attachment.uploadId}>
              {attachment.state === "uploading" && (
                <div className={styles.progress}>
                  <div className={styles.handle} style={{ maxWidth: attachment.progress + "%" }}></div>
                </div>
              )}
              <i className="material-icons-outlined">attachment</i>
              <span className={styles.filename}>{textEllipsis(attachment.name, 25)}</span>
              {attachment.state === "uploaded" && <h4>Uploaded</h4>}
              {attachment.state === "uploading" && <h4>{`${attachment.progress}%`}</h4>}
              <span className={styles.filesize}>({humanizeFileSize(attachment.size)})</span>
              <div
                className={styles.close}
                onClick={() => {
                  const temp = [...tempAttachments.current]
                  temp.splice(i, 1)
                  tempAttachments.current = temp
                  setAttachments()
                }}
              >
                <i className="material-icons">close</i>
              </div>
            </div>
          ))}
        </div>

        <div className={styles.dragHover}>
          <i className="material-icons-outlined">file_upload</i>
          <div className={styles.meta}>
            <span className={styles.main}>Attachments</span>
            <span className={styles.sub}>Drop files to attach them</span>
          </div>
        </div>
        <ReactEditor
          ref={editor}
          onDrag={(evt) => {
            evt.preventDefault()
          }}
          onDragOver={(evt) => {
            setDrag(true)
            evt.preventDefault()
          }}
          onDrop={(evt) => {
            setDrag(false)
            evt.preventDefault()
            evt.stopPropagation()
            addAttachment(evt.dataTransfer?.files)
          }}
          // onChange={() => {
          //   if (editor.current?.editor) {
          //     const content = editor.current.editor.getContent()
          //     localStorage.setItem(`messageCache_${savedMessageField.toUpperCase()}`, content)
          //   }
          // }}
          onKeyUp={(e) => {
            if (
              editor.current?.editor &&
              !initialValue &&
              window.location.href.includes("/chat") &&
              savedMessageField.toUpperCase()
            ) {
              if (e.key === "Enter") {
                localStorage.removeItem(`messageCache_${savedMessageField.toUpperCase()}`)
                return
              }
              const content = editor.current.editor.getContent()
              localStorage.setItem(`messageCache_${savedMessageField.toUpperCase()}`, content)
            }
          }}
          onPaste={(evt) => {
            if (evt.clipboardData?.types.includes("Files")) {
              evt.preventDefault()
            }
          }}
          init={{
            base_url: import.meta.env.VITE_BASE_URL,
            menubar: false,
            auto_focus: true,
            deprecation_warnings: false,
            statusbar: false,
            resize: "both",
            min_height: 50,
            height: 100,
            max_height: 400,
            plugins: ["autolink lists anchor autoresize link paste"],
            content_style: `
              a.p-user-mention {
                color: #005690;
                background-color: #d2e9f1;
                padding-left: 2px;
                padding-right: 2px;
                border-radius: 3px;
                text-decoration: none;
                cursor: pointer;
              }
              a.p-task-mention {
                color: white;
                padding-left: 2px;
                padding-right: 2px;
                border-radius: 3px;
                text-decoration: none;
                cursor: pointer;
                font-weight: normal;
                text-decoration: none;
              }
              p {
                margin: 0;
                line-height: 1.5;
              }  
						`,

            setup: (editor) => {
              editor.ui.registry.addIcon("mentionIcon", mentionIcon)
              editor.ui.registry.addIcon("emojiIcon", emojiIcon)
              if (apply) {
                editor.ui.registry.addIcon("applyIcon", applyIcon)
              } else {
                editor.ui.registry.addIcon("sendIcon", sendIcon)
              }
              editor.ui.registry.addIcon("attachmentIcon", attachmentIcon)

              editor.on("click", (e) => {
                if (e.target && e.target.className === "p-user-mention") {
                  const id = e.target.getAttribute("data-id")
                  if (id) {
                    pubsub.publish(topics.OPEN_PROFILE, id)
                  }
                }
              })

              editor.ui.registry.addButton("send", {
                icon: apply ? "applyIcon" : "sendIcon",
                tooltip: apply ? "Apply" : "Send Message",
                onAction: (_) => {
                  handleSubmit(editor.getContent())
                }
              })

              editor.ui.registry.addButton("emoji", {
                icon: "emojiIcon",
                tooltip: "Add Emoji",
                onAction: (_) => {
                  setEmojiOpen(true)
                }
              })

              editor.ui.registry.addButton("attach", {
                icon: "attachmentIcon",
                tooltip: "Attach File",
                onAction: (_) => {
                  inputFiles.current?.click()
                }
              })

              editor.on("keydown", handleKeyboard)

              editor.on("paste", (e) => {
                const files = e.clipboardData?.files
                if (files?.length) {
                  addAttachment(files)
                }
              })

              onSetup(editor)
            },

            automatic_uploads: false,
            toolbar: `bold italic strikethrough numlist bullist link | ${
              attachments ? "attach" : ""
            } emoji mention send`,
            placeholder: placeholder ? placeholder : "Type message...",
            forced_root_block: false,
            block_unsupported_drop: false,
            default_link_target: "_blank",
            init_instance_callback: () => {
              if (initialValue) {
                const doc = new DOMParser().parseFromString(initialValue, "text/html")
                const newHref = "/" + doc.body.querySelector("a")?.getAttribute("href")
                doc.body.querySelector("a")?.setAttribute("href", newHref)
                initialValue = doc.body.innerHTML
                setTimeout(() => {
                  editor.current?.editor?.insertContent(initialValue)
                }, 10)
              }
            }
          }}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        />
        {!isMobile && (
          <p className={styles.chatBoxInfo}>
            Press Ctrl + Enter for a new line, Shift + Enter for a new paragraph. Enter to send.
          </p>
        )}
        {isMobile && <p className={styles.chatBoxInfo}>Enter to new line, Press send to send the message</p>}
      </div>
    </div>
  )
}

export default PretzelTextEditor
