/* eslint-disable */
import { useEffect, useRef } from "react"
import { useHistory } from "react-router-dom"
import { parseQueryString } from "utils/common"
import { useNonInitialEffect } from "utils/hooks"
import { Location } from "history"

type SessionLocationsType = { [id: string]: Location }
const localStorageKey = "sessionLocations"
let __sessionLocations: SessionLocationsType = {}

function sessionLocations(newVal?: SessionLocationsType): SessionLocationsType {
  if (newVal) {
    __sessionLocations = newVal || {}
    localStorage.setItem(localStorageKey, JSON.stringify(__sessionLocations))
  }
  return __sessionLocations || {}
}

export function useSessionLocationsRoot(activeSessionId: string): void {
  const history = useHistory()

  useEffect(() => {
    sessionLocations(JSON.parse(localStorage.getItem(localStorageKey) || "{}"))
  }, [])

  // const isInitial = useRef(true)
  // useNonInitialEffect(() => {
  //   if (isInitial.current) {
  //     isInitial.current = false
  //     return
  //   }
  //   if (!activeSessionId) {
  //     return
  //   }

  //   const location = sessionLocations()[activeSessionId]

  //   if (location) {
  //     const { query } = parseQueryString(history.location.search) as {
  //       query: string
  //     }

  //     if (!query) {
  //       history.push(location)
  //     }
  //   }
  // }, [activeSessionId])

  useNonInitialEffect(() => {
    if (activeSessionId) {
      sessionLocations({ ...sessionLocations(), [activeSessionId]: history.location })
    }
  }, [history.location.pathname, history.location.hash, history.location.search, activeSessionId])
}

export function getSessionLocation(sessionId: string): Location {
  return sessionLocations()[sessionId]
}
