/* eslint-disable */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useState } from "react"
import {
  useWikipediaResult_WikiLazyQuery,
  WikipediaResult_WikiQuery,
  WikipediaSection,
  WikipediaSectionContent
} from "generated/graphql"
import dataPanel from "modules/shared/components/dataPanel/DataPanel.module.scss"
import shimmer from "modules/shared/styles/components/shimmer.module.scss"
import button from "modules/shared/styles/components/button.module.scss"
import cn from "clsx"
import _ from "lodash"
import wikiIcon from "images/icons/wikipedia-logo.png"

import BookmarkButton from "modules/shared/components/bookmarkButton"
import SaveButton from "modules/chat/components/saveButton"
import InsertButton from "modules/chat/components/insertButton"
import LazyImage from "modules/shared/components/lazyImage"
import Tooltip from "modules/shared/components/tooltip"
import TabGroup from "modules/shared/components/tabGroup"
import SplitterContext from "modules/shared/components/splitterContext"
// import WikipediaResultMoreMenu from "./components/wikipediaResultMoreMenu"
import { handleGraphQlError } from "utils/common"
import { useNonInitialEffect } from "utils/hooks"

import cloneIcon from "images/icons-new/clone.svg"
import insertIcon from "images/icons-new/insert.svg"
import insertSheetIcon from "images/icons-new/insert-sheet.svg"
import multiboardIcon from "images/icons-new/multiboard.svg"
import multiboardSheetIcon from "images/icons-new/multiboard-sheet.svg"

function renderContent(content: WikipediaSectionContent | undefined) {
  if (!content) {
    return null
  }
  return content.paragraphs?.slice(0, 5).map((p, i) => (
    <span key={i} className={dataPanel.paragraph}>
      {p?.sentences?.map((s, ii) => (
        <span key={ii}>{s}</span>
      ))}
    </span>
  ))
}

function getContentHtml(content: WikipediaSectionContent | undefined) {
  return content?.paragraphs?.map((p) => `<p>${p?.sentences}</p>`).join("")
}

function countContentLength(content: WikipediaSectionContent | undefined) {
  if (!content) return 0
  let counter = 0
  content.paragraphs?.forEach((p, i) => {
    p?.sentences?.forEach((s, ii) => {
      counter += s?.length || 0
    })
  })
  return counter
}

const WikipediaTextPanel: React.FC<{
  data: WikipediaResult_WikiQuery | undefined
  loading: boolean
  messageId: string
}> = ({ data, loading, messageId }) => {
  const { chatWidth } = useContext(SplitterContext)
  const sections = data?.message?.wiki?.sections || []
  const [activeTab, setActiveTab] = useState(sections.length ? sections[0].title : null)

  const [expanded, setExpanded] = useState(false)
  const [activeSection, setActiveSection] = useState<WikipediaSection>()

  useEffect(() => {
    setActiveSection(sections.find((s) => s.title === activeTab))
  }, [activeTab])

  useEffect(() => {
    if (data && !activeTab && sections?.length) {
      setActiveTab(sections[0].title)
    }
  }, [data])

  if (!data || loading) {
    return (
      <div className={cn(dataPanel.dataPanelPreloader)}>
        <div className={cn(dataPanel.title, shimmer.shimmer)}></div>
        <div className={cn(dataPanel.sub, shimmer.shimmer)}></div>

        <div className={dataPanel.tabs}>
          <div className={cn(dataPanel.tabItem, shimmer.shimmer)}></div>
          <div className={cn(dataPanel.tabItem, shimmer.shimmer)}></div>
          <div className={cn(dataPanel.tabItem, shimmer.shimmer)}></div>
          <div className={cn(dataPanel.tabItem, shimmer.shimmer)}></div>
        </div>

        <div className={cn(dataPanel.description, shimmer.shimmer)}></div>
      </div>
    )
  }

  if (!sections.length) {
    return null
  }

  const tabs = sections.map((s) => s.title || "")

  const contentCollapsible = countContentLength(activeSection?.content as WikipediaSectionContent) > 100

  return (
    <div className={cn(dataPanel.dataPanel, dataPanel.TEXT)}>
      <div className={dataPanel.header}>
        <div className={dataPanel.wikiHeader}>
          {!data.activeAppSpace && (
            <div className={dataPanel.imageContainer}>
              <LazyImage src={data.message?.wiki?.image?.thumbnail || ""} alt={data.message?.wiki?.image?.alt || ""} />
            </div>
          )}
          <div className={dataPanel.wikiMeta}>
            <span className={dataPanel.title}>{data.message?.wiki?.title}</span>
            <span className={dataPanel.slug}>
              <img alt="Wikipedia icon" className={dataPanel.wikiIcon} src={wikiIcon} />
              <span>Wikipedia/{data.message?.wiki?.title}</span>
            </span>
            {chatWidth >= 50 && <span className={dataPanel.category}>{data.message?.wiki?.category}</span>}
          </div>
          <div className={dataPanel.buttonsContainer}>
            <div className={dataPanel.buttonsGroup}>
              {activeSection && (
                <Tooltip title="Open and Save" trigger="mouseenter" size="big" delay={200}>
                  <SaveButton
                    type="content"
                    name={data?.message?.wiki?.title + " | " + activeSection?.title}
                    from={"messageWikiSection:" + messageId + "||" + activeSection?.title}
                  >
                    <button className={cn(dataPanel.sideButton)}>
                      <i className="material-icons">arrow_forward</i>
                    </button>
                  </SaveButton>
                </Tooltip>
              )}
              <Tooltip title="Insert into Current Document" trigger="mouseenter" size="big" delay={200}>
                <InsertButton
                  content={getContentHtml(activeSection?.content || undefined) || ""}
                  type="content"
                  title={activeSection?.title || undefined}
                >
                  <button className={cn(dataPanel.sideButton)}>
                    <img src={insertIcon} alt="Insert" />
                  </button>
                </InsertButton>
              </Tooltip>
              <Tooltip title="Copy to Multi-clipboard" trigger="mouseenter" size="big" delay={200}>
                <button className={cn(dataPanel.sideButton)}>
                  <img src={multiboardIcon} alt="Copy to Multi Clipboard" />
                </button>
              </Tooltip>
              <Tooltip title="Clone Page" trigger="mouseenter" size="big" delay={200}>
                <button className={cn(dataPanel.sideButton)}>
                  <img src={cloneIcon} alt="Clone" />
                </button>
              </Tooltip>
              <Tooltip title="Bookmark This" trigger="mouseenter" size="big" delay={200}>
                <BookmarkButton title={data.message?.wiki?.title || ""} url={data.message?.wiki?.url || ""}>
                  <button className={cn(dataPanel.sideButton)}>
                    <i className="material-icons">bookmark_outline</i>
                  </button>
                </BookmarkButton>
              </Tooltip>
              <Tooltip title="Open in new Tab" trigger="mouseenter" size="big" delay={200}>
                <button
                  onClick={() => {
                    window.open(data.message?.wiki?.url || "", "_blank")
                  }}
                  className={cn(dataPanel.sideButton)}
                >
                  <i className="material-icons">open_in_new</i>
                </button>
              </Tooltip>
              {/* <WikipediaResultMoreMenu theme="doc" /> */}
            </div>
          </div>
        </div>
      </div>

      <TabGroup tabs={tabs} activeTab={activeTab || ""} setActiveTab={setActiveTab} />

      <div className={dataPanel.content}>
        <div className={dataPanel.contentContainer}>
          <p className={cn(dataPanel.contentDescription, expanded ? dataPanel.expanded : "")}>
            {renderContent(activeSection?.content as WikipediaSectionContent)}
          </p>

          <div className={cn(dataPanel.fadeDown, expanded || !contentCollapsible ? dataPanel.expanded : "")}></div>
        </div>
        <div
          className={cn(dataPanel.readmoreContainer, !expanded && contentCollapsible ? dataPanel.visible : "")}
          onClick={() => setExpanded(!expanded)}
        >
          {!expanded && <i className="material-icons">expand_more</i>}
          {expanded && <i className="material-icons">expand_less</i>}
          {expanded ? "SHOW LESS" : "SHOW MORE"}
        </div>
      </div>
    </div>
  )
}

function renderTable(data: any[], headers: any[]) {
  if (!data.length) {
    return null
  }
  if (!headers.length) {
    return null
  }

  function renderTd(value: { text?: string; number?: number }, key: number) {
    if (value?.text) {
      return <td key={key}>{value.text}</td>
    }
    return <td key={key}>{value?.number || ""}</td>
  }

  return (
    <>
      <div className={dataPanel.dataTableHeader}>
        <div className={cn(dataPanel.buttonsGroup, button.green)}></div>
      </div>

      <div className={cn(dataPanel.dataTableContainer, "header-fixed")}>
        <table className={dataPanel.dataTable}>
          <thead>
            <tr>
              {headers.map((h, i) => (
                <th key={i}>{h}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, i) => (
              <tr key={i}>{headers.map((h, hi) => renderTd(row[h], hi))}</tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

const WikipediaDataPanel: React.FC<{
  data: WikipediaResult_WikiQuery | undefined
  loading: boolean
  messageId: string
}> = ({ data, loading, messageId }) => {
  const dataItems = data?.message?.wiki?.data || []
  const [activeTab, setActiveTab] = useState(dataItems.length > 0 && dataItems[0] ? dataItems[0]?.title : null)

  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    if (data && !activeTab && dataItems?.length) {
      setActiveTab(dataItems[0]?.title)
    }
  }, [data])

  if (!dataItems.length && !loading) {
    return null
  }

  const tabs = dataItems.map((s) => s?.title || "")
  const activeContent = dataItems.find((d) => d?.title === activeTab)

  const expandable = (activeContent?.tables?.length || 1) > 1

  const section = activeContent?.title || ""
  const title = data?.message?.wiki?.title || ""
  const tableIndex = 0
  const _data = JSON.parse((activeContent?.tables || [])[0] || "{}") as any[]
  const headers = _.uniq(_.flatMap(_data, (d) => Object.keys(d || {})))

  const getContent = () => {
    return {
      headers,
      data: _data.map((row) =>
        headers.map((h, hi) => {
          const cell = row[h]
          return cell.text || cell.number
        })
      )
    }
  }

  if (!data && loading)
    return (
      <div style={{ marginTop: 20 }} className={cn(dataPanel.dataPanelPreloader)}>
        <div className={cn(dataPanel.title, shimmer.shimmer)}></div>
        <div className={cn(dataPanel.sub, shimmer.shimmer)}></div>

        <div className={dataPanel.tabs}>
          <div className={cn(dataPanel.tabItem, shimmer.shimmer)}></div>
          <div className={cn(dataPanel.tabItem, shimmer.shimmer)}></div>
          <div className={cn(dataPanel.tabItem, shimmer.shimmer)}></div>
          <div className={cn(dataPanel.tabItem, shimmer.shimmer)}></div>
          <div className={cn(dataPanel.tabItem, shimmer.shimmer)}></div>
        </div>

        <div className={cn(dataPanel.tabs, dataPanel.grow)} style={{ marginTop: 20 }}>
          <div className={cn(dataPanel.tabItem, shimmer.shimmer)}></div>
          <div className={cn(dataPanel.tabItem, shimmer.shimmer)}></div>
          <div className={cn(dataPanel.tabItem, shimmer.shimmer)}></div>
          <div className={cn(dataPanel.tabItem, shimmer.shimmer)}></div>
          <div className={cn(dataPanel.tabItem, shimmer.shimmer)}></div>
        </div>

        <div className={cn(dataPanel.description, shimmer.shimmer)}></div>
      </div>
    )
  else {
    return (
      <div className={cn(expanded ? dataPanel.expanded : "", dataPanel.dataPanel, dataPanel.SHEET)}>
        <div className={dataPanel.header}>
          <div className={dataPanel.wikiHeader}>
            <div className={dataPanel.icon}>
              <span className="material-icons">table_view</span>
            </div>
            <div className={dataPanel.wikiMeta}>
              <span className={dataPanel.title}>{data?.message?.wiki?.title}</span>
              <span className={dataPanel.slug}>
                <img className={dataPanel.wikiIcon} src={wikiIcon} alt="Wikipedia Icon" />
                <span>Wikipedia/{data?.message?.wiki?.title}</span>
              </span>
            </div>
            <div className={dataPanel.buttonsContainer}>
              <div className={dataPanel.buttonsGroup}>
                <Tooltip title="Open and Save" trigger="mouseenter" size="big" delay={200}>
                  <SaveButton
                    type="content"
                    name={title + " | " + section}
                    from={`tableFromMessage:wiki||${messageId}||${section}||${tableIndex}`}
                  >
                    <button className={cn(dataPanel.sideButton)}>
                      <i className="material-icons">arrow_forward</i>
                    </button>
                  </SaveButton>
                </Tooltip>
                <Tooltip title="Insert Data" trigger="mouseenter" size="big" delay={200}>
                  <InsertButton type="table" getContent={() => getContent()} title={section}>
                    <button className={cn(dataPanel.sideButton)}>
                      <img src={insertSheetIcon} alt="Insert" />
                    </button>
                  </InsertButton>
                </Tooltip>
                <Tooltip title="Copy to Multi-clipboard" trigger="mouseenter" size="big" delay={200}>
                  <button className={cn(dataPanel.sideButton)}>
                    <img src={multiboardSheetIcon} alt="Copy to Multi Clipboard" />
                  </button>
                </Tooltip>
                <Tooltip title="Bookmark This" trigger="mouseenter" size="big" delay={200}>
                  <BookmarkButton title={data?.message?.wiki?.title || ""} url={data?.message?.wiki?.url || ""}>
                    <button className={cn(dataPanel.sideButton)}>
                      <i className="material-icons">bookmark_outline</i>
                    </button>
                  </BookmarkButton>
                </Tooltip>
                <Tooltip title="Open in new Tab" trigger="mouseenter" size="big" delay={200}>
                  <button
                    onClick={() => {
                      window.open(data?.message?.wiki?.url || "", "_blank")
                    }}
                    className={cn(dataPanel.sideButton)}
                  >
                    <i className="material-icons">open_in_new</i>
                  </button>
                </Tooltip>
                {/* <WikipediaResultMoreMenu theme="sheet" /> */}
              </div>
            </div>
          </div>
        </div>

        <TabGroup tabs={tabs} theme="sheet" activeTab={activeTab || ""} setActiveTab={setActiveTab} />

        <div className={dataPanel.content}>
          <div className={cn(dataPanel.contentContainer, expanded ? dataPanel.expanded : "")}>
            {expanded && (
              <div className={dataPanel.innerContentContainer}>
                {activeContent?.tables?.map((t, i) => (
                  <div key={i}>{renderTable(_data, headers)}</div>
                ))}
                <button
                  className={cn([
                    button.button,
                    button.primary,
                    button.roundedButton,
                    button.iconButton,
                    dataPanel.collapseExpandButton
                  ])}
                  onClick={() => setExpanded(false)}
                >
                  <i className="fa fa-chevron-up"></i>
                  <span>Collapse data tables</span>
                </button>
              </div>
            )}
            {!expanded &&
              activeContent?.tables?.slice(0, 1).map((t, i) => (
                <div key={i} className={dataPanel.innerContentContainer}>
                  <div>{renderTable(_data, headers)}</div>
                  {expandable && (
                    <button
                      className={cn([
                        button.button,
                        button.primary,
                        button.roundedButton,
                        button.iconButton,
                        dataPanel.collapseExpandButton
                      ])}
                      onClick={() => setExpanded(true)}
                    >
                      <i className="fa fa-chevron-down"></i>
                      <span>Show {(activeContent?.tables?.length || 1) - 1} more tables</span>
                    </button>
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
    )
  }
}

const WikipediResult: React.FC<{
  messageId: string
  query: string
  waitForData: boolean
}> = ({ messageId, query, waitForData }) => {
  const [getData, { data, loading }] = useWikipediaResult_WikiLazyQuery({
    variables: {
      messageId,
      query
    },
    onError: handleGraphQlError("Unable to get Wikipedia result")
  })

  useNonInitialEffect(() => {
    if (!waitForData) {
      getData()
    }
  }, [waitForData])

  return (
    <>
      <WikipediaTextPanel data={data} loading={loading} messageId={messageId} />
      <WikipediaDataPanel data={data} loading={loading} messageId={messageId} />
    </>
  )
}

export default WikipediResult
