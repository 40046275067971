/* eslint-disable */
import React, { useEffect, useRef, useState, useCallback } from "react"
import { ReactComponent as TimesIcon } from "images/icons/times.svg"
import cn from "clsx"
import {
  AppSpace,
  AppSpaceTabs_AppSpaceFragment,
  useAppSpaceTabsQuery,
  useAppSpaces_SelectAppSpaceMutation,
  useAppSpaces_CreateAppSpaceMutation,
  useAppSpaces_CloseAppSpaceMutation,
  useAppSpaces_RenameAppSpaceMutation,
  useFileListing_OpenFileMutation
} from "generated/graphql"
import styles from "./AppSpaceTabs.module.scss"
import { getFromLocalStorage } from "utils/localStorage"

import Popup from "reactjs-popup"
import pubsub from "pubsub-js"
import { currentSession } from "utils/common"

import { handleGraphQlError } from "utils/common"
import { KeyCodes, topics } from "consts"
import { fileTypeToColor } from "../../../chat/components/fileListing/utils/fileTypeToColor"
import defaultAppSpaceContent from "../AppSpace/utils/defaultAppSpaceContent"

import menu from "modules/shared/styles/components/menu.module.scss"
import input from "modules/shared/styles/components/input.module.scss"
import popupPanel from "modules/shared/styles/components/popupPanel.module.scss"
import button from "modules/shared/styles/components/button.module.scss"
import spinner from "modules/shared/styles/components/spinner.module.scss"
import { useHistory } from "react-router-dom"

import NotificationView from "../notificationView"
import { isMobile } from "react-device-detect"
import Tooltip from "modules/shared/components/tooltip"
import { setWikiOpenTabs } from "modules/shared/components/wikiData"
import { removeViewTab, viewTabs, setViewTabs } from "modules/chat/components/viewFiles/ViewFiles"
import { currentTab } from "modules/app/components/sessionTabs/SessionTabs"

export let wikiDocTabs = [] as any
export let activeTab: {
  id: any
  isActive?: boolean
  appSpace?: { __typename: string; type: string; id: string; name: string }
  showTooltip?: boolean
}

export const createWikiTab = (value: string) => {
  let exists = false
  wikiDocTabs.forEach((tab: any) => {
    if (tab.appSpace.name.split("##")[1] === value.split("##")[1]) exists = true
  })

  if (exists) {
    wikiDocTabs.forEach((item: any) => (item.isActive = false))
    wikiDocTabs.forEach((item: any) => {
      if (item.appSpace.name.split("##")[1] === value.split("##")[1]) {
        item.isActive = true
        activeTab = item
      }
    })
    return
  }

  wikiDocTabs.push({
    __typename: "SessionAppSpace",
    id: value.split("##")[1],
    isActive: false,
    appSpace: {
      __typename: "AppSpace",
      type: "taskBoard",
      id: value.split("##")[1] + "1",
      name: value
    },
    showTooltip: false
  })
  wikiDocTabs.forEach((item: any) => (item.isActive = false))
  wikiDocTabs[wikiDocTabs.length - 1].isActive = true
  activeTab = wikiDocTabs[wikiDocTabs.length - 1]
}

interface IAppSpaceName {
  activeAppSpace: string
  appSpace: { id: string; name: string; type: string; previewing?: boolean; fileId?: string }
  activeViewingTab: any
  setActiveViewingTab: any
  handleClick: (appSpace: { id: string; name: string; fileId?: string }) => unknown
  handleClose: (appSpace: { id: string; name: string }) => unknown
  canRename: boolean
  activateAppSpaceTabs?: (activateStatus: boolean) => void
  adjustMinWidth: boolean
  appSpaces: any
  toggleTooltip: any
}

const AppSpaceName: React.FC<IAppSpaceName> = ({
  activeAppSpace,
  appSpace,
  activeViewingTab,
  setActiveViewingTab,
  handleClick,
  handleClose,
  canRename,
  appSpaces,
  adjustMinWidth,
  toggleTooltip
}) => {
  const input = useRef<HTMLInputElement>(null)
  const [isRenaming, setIsRenaming] = useState(false)
  const [appSpaceName, setAppSpaceName] = useState(appSpace.name)
  const appSpaceNameRef = useRef<any>()
  const [renameAppSpace] = useAppSpaces_RenameAppSpaceMutation({
    onError: handleGraphQlError("Unable to rename app space")
  })

  const handleDoubleClick = (s: { id: string; name: string }) => {
    if (activeAppSpace === s.id && !isRenaming && canRename) {
      setIsRenaming(true)
      setAppSpaceName(s.name)
      setTimeout(() => {
        if (input.current) {
          input.current?.focus()
        }
      }, 10)
    }
  }

  const updateAppSpaceName = async () => {
    await renameAppSpace({
      variables: { newName: appSpaceName, id: appSpace.id }
    })
    pubsub.publish(topics.UPDATE_FILES)
  }

  const handleInputKeyDown = (e: { keyCode: number }) => {
    if (e.keyCode === KeyCodes.Enter) {
      updateAppSpaceName()
      setIsRenaming(false)
    }
  }

  const handleInputBlur = () => {
    updateAppSpaceName()
    setIsRenaming(false)
  }
  useEffect(() => {
    if (appSpaceNameRef && appSpaceNameRef.current) {
      toggleTooltip(appSpace.id, appSpaceNameRef.current.offsetWidth < appSpaceNameRef.current.scrollWidth)
    }
  }, [appSpaceNameRef, appSpaces.length])

  return (
    <div
      key={appSpace?.id}
      onClick={() => handleClick(appSpace as AppSpace)}
      onDoubleClick={() => handleDoubleClick(appSpace)}
      onMouseDown={(e) => {
        if (e.button === 1) {
          e.stopPropagation()
          handleClose(appSpace)
        }
      }}
      className={
        appSpace.id === activeAppSpace || activeViewingTab === appSpace?.id
          ? cn([styles.appSpaceActive, adjustMinWidth && styles.adjustMinWidth])
          : cn([styles.appSpace, adjustMinWidth && styles.adjustMinWidth])
      }
    >
      {currentSession("/wikis") ? (
        <div style={{ background: "#9CAEE6" }} className={cn([styles.appSpaceIcon])} />
      ) : (
        <div className={cn([styles.appSpaceIcon, fileTypeToColor(appSpace?.type)])} />
      )}
      {isRenaming && (
        <input
          onKeyUp={handleInputKeyDown}
          ref={input}
          className={styles.input}
          maxLength={50}
          onBlur={handleInputBlur}
          value={appSpaceName}
          onChange={(e) => setAppSpaceName(e.target.value)}
        />
      )}
      {!isRenaming && (
        <p
          className={cn([
            styles.appSpaceName,
            styles.appSpaceNameTruncate,
            adjustMinWidth && appSpace?.name?.length >= 9 && styles.maxCharacterLength
          ])}
          ref={appSpaceNameRef}
        >
          {appSpace?.name}
        </p>
      )}

      <div
        className={styles.appSpaceClose}
        onClick={(e) => {
          e.stopPropagation()
          handleClose(appSpace)
        }}
      >
        <TimesIcon />
      </div>
    </div>
  )
}

const AppSpaceCreator: React.FC = () => {
  const [createAppSpace, { loading }] = useAppSpaces_CreateAppSpaceMutation({
    onError: handleGraphQlError("Unable to create app space")
  })

  const [togglePopup, setTogglePopup] = useState(false)
  const [selectedFolder, setSelectedFolder] = useState<string>("")

  const select = () => {
    if (appSpaceName) {
      setTogglePopup(true)
      appSpaceName.current?.focus()
      appSpaceName.current?.select()
    }
  }
  useEffect(() => {
    const token = pubsub.subscribe(topics.OPEN_CREATE_NEW_DOCUMENT, (_: any, folderId: string) => {
      setSelectedFolder(folderId)
      select()
    })

    return () => {
      pubsub.unsubscribe(token)
    }
  }, [])
  const appSpaceName = useRef<HTMLInputElement>(null)

  const handleCreateAppSpace = async (type: string, close: () => void) => {
    await createAppSpace({
      variables: {
        type: type,
        name: appSpaceName.current?.value,
        content: defaultAppSpaceContent(appSpaceName.current?.value, type),
        folderId: selectedFolder
      }
    })
    pubsub.publish(topics.FILE_LISTING_RELOAD)
    close()
  }

  function createDoc() {
    document.getElementById("sidebarFiles")?.click()

    setTimeout(() => {
      createAppSpace({
        variables: {
          type: "doc",
          content: "<h1>Document</h1>",
          name: "Document",
          folderId: selectedFolder
        }
      }).then(() => {
        pubsub.publish(topics.FILE_LISTING_RELOAD)
        setTimeout(() => pubsub.publish(topics.FILE_LISTING_RELOAD), 1000)
        setTimeout(() => pubsub.publish(topics.FILE_LISTING_RELOAD), 1500)
        setTimeout(() => pubsub.publish(topics.FILE_LISTING_RELOAD), 2000)
      })
    }, 200)
  }

  function createSheet() {
    document.getElementById("sidebarFiles")?.click()

    setTimeout(() => {
      createAppSpace({
        variables: {
          type: "sheet",
          content: "",
          name: "Spreadsheet",
          folderId: selectedFolder
        }
      }).then(() => {
        pubsub.publish(topics.FILE_LISTING_RELOAD)
        setTimeout(() => pubsub.publish(topics.FILE_LISTING_RELOAD), 1000)
        setTimeout(() => pubsub.publish(topics.FILE_LISTING_RELOAD), 1500)
        setTimeout(() => pubsub.publish(topics.FILE_LISTING_RELOAD), 2000)
      })
    }, 200)
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "c") {
      const handleDKeyPress = (event: KeyboardEvent) => {
        if (event.key === "d") {
          createDoc()
        }
        window.removeEventListener("keydown", handleDKeyPress)
      }

      const handleSPress = (event: KeyboardEvent) => {
        if (event.key === "s") {
          createSheet()
        }
        window.removeEventListener("keydown", handleSPress)
      }

      window.addEventListener("keydown", handleDKeyPress)
      window.addEventListener("keydown", handleSPress)
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown)

    return () => {
      window.removeEventListener("keydown", handleKeyDown)
    }
  }, [])

  return (
    <Popup
      arrow={true}
      open={togglePopup}
      trigger={
        <div className={styles.appSpaceManager} onClick={select}>
          Create
          <i className="material-icons">expand_more</i>
        </div>
      }
      contentStyle={{ padding: 0 }}
      position="bottom right"
    >
      {
        ((close: () => void) => (
          <div className={popupPanel.popupRoot} style={{ padding: 0 }}>
            <div className={popupPanel.popupTitle} style={{ padding: 10 }}>
              <p>Create File</p>
              <span onClick={close} className={cn("material-icons", popupPanel.popupClose)}>
                close
              </span>
            </div>

            <div className={popupPanel.popupForm} style={{ display: "none", padding: "5px 10px", margin: 0 }}>
              <label className={styles.label}>Document Name:</label>
              <input
                type="text"
                ref={appSpaceName}
                defaultValue="Document"
                placeholder="App Space Name"
                required
                className={cn(input.input, input.block)}
              />
              <label className={styles.label}>Select Document Type:</label>
            </div>
            {!loading && (
              <div className={cn(styles.createMenu)}>
                <div
                  className={styles.menuItem}
                  onClick={() => {
                    createDoc()
                    close()
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "1rem",
                      alignItems: "center"
                    }}
                  >
                    <i className="fa fa-file-text-o" />
                    <span>Text Document</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "4px",
                      alignItems: "center"
                    }}
                  >
                    <kbd>c</kbd>
                    <kbd>d</kbd>
                  </div>
                </div>
                <div
                  className={styles.menuItem}
                  onClick={() => {
                    createSheet()
                    close()
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "1rem",
                      alignItems: "center"
                    }}
                  >
                    <i className="fa fa-file-excel-o" />
                    <span>Spreadsheet</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "4px",
                      alignItems: "center"
                    }}
                  >
                    <kbd>c</kbd>
                    <kbd>s</kbd>
                  </div>
                </div>
              </div>
            )}

            {loading && (
              <button
                className={cn([
                  button.button,
                  button.primary,
                  button.big,
                  button.lowAnimation,
                  loading ? button.loadingButton : "",
                  styles.createMenuPreloader
                ])}
              >
                <div className={cn(spinner.spinner, spinner.small)} />
              </button>
            )}
          </div>
        )) as any
      }
    </Popup>
  )
}
interface IAppSpaceTabs {
  hideAppSpaceTabs: boolean
  activateAppSpaceTabs: (activateStatus: boolean) => void
  setFinishPreview: any
}

export let setSpaceTabs: any
export let setActiveView: any

const AppSpaceTabs: React.FC<IAppSpaceTabs> = ({
  setFinishPreview,
  hideAppSpaceTabs,
  activateAppSpaceTabs
}: IAppSpaceTabs) => {
  const { data, refetch } = useAppSpaceTabsQuery({
    onError: handleGraphQlError("Unable to get app spaces")
  })
  const history = useHistory()
  const [activeViewingTab, setActiveViewingTab] = useState("preview")
  setActiveView = setActiveViewingTab

  const [selectAppSpace] = useAppSpaces_SelectAppSpaceMutation({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onError: function () {}
  })
  const [closeAppSpace] = useAppSpaces_CloseAppSpaceMutation({
    // onError: handleGraphQlError("Unable to close app space")
  })
  const [openFile] = useFileListing_OpenFileMutation({
    onError: handleGraphQlError("Unable to open file")
  })
  const [createAppSpace] = useAppSpaces_CreateAppSpaceMutation({
    onError: handleGraphQlError("Unable to create app space")
  })
  const [appSpaces, setAppSpaces] = useState<any>()
  setSpaceTabs = setAppSpaces

  if (wikiDocTabs.length && !currentSession("wikis")) {
    if (data?.activeAppSpace?.id) {
      wikiDocTabs = []
      closeAppSpace({
        variables: {
          appSpaceId: data.activeAppSpace.id
        }
      }).catch(() => {
        //
      })
    }
  }

  useEffect(() => {
    const token = pubsub.subscribe(topics.APPSPACE_CREATE, (_: any, data: { type: string; name: string }) => {
      createAppSpace({
        variables: {
          type: data.type,
          name: data.name
        }
      })
    })

    return () => {
      pubsub.unsubscribe(token)
    }
  }, [])

  useEffect(() => {
    const token = pubsub.subscribe(topics.APPSPACE_RELOAD, () => {
      refetch()
      if (currentSession("wikis")) setAppSpaces(wikiDocTabs)
      else if (viewTabs.length > 0) setAppSpaces(viewTabs)
      else setAppSpaces(data?.appSpaces)
    })

    return () => {
      pubsub.unsubscribe(token)
    }
  }, [])

  useEffect(() => {
    if (!data?.appSpaces?.length) {
      activateAppSpaceTabs(false)
      setAppSpaces(data?.appSpaces)
    } else {
      if (currentSession("wikis")) setAppSpaces(wikiDocTabs)
      else if (viewTabs.length > 0) setAppSpaces(viewTabs)
      else setAppSpaces(data?.appSpaces)
      activateAppSpaceTabs(true)
    }
  }, [data])
  const toggleTooltip = useCallback((appSpaceId: string, showTooltip: boolean) => {
    if (currentSession("wikis")) setAppSpaces(wikiDocTabs)
    else {
      setAppSpaces((appSpaceData: any) => {
        return appSpaceData.map((appSpaceTab: any) => {
          if (appSpaceTab?.appSpace?.id === appSpaceId) {
            return { ...appSpaceTab, showTooltip: showTooltip }
          }
          return { ...appSpaceTab }
        })
      })
    }
  }, [])
  if (!data) {
    activateAppSpaceTabs(false)
    return null
  }

  const activeAppSpace = data.activeAppSpace
  const handleClick = async (appSpace: { id: string; name: string; fileId?: string }) => {
    const appSpaceName = appSpaces.find((appSpaceTab: any) => appSpaceTab?.appSpace?.id === appSpace.id)?.appSpace?.name
    const appSpaceID = appSpaceName?.split("##")?.pop()
    if (currentSession("wikis") && activeTab?.id === appSpaceID) return
    if (activeAppSpace?.id === appSpace.id) return
    if (window.location.href.includes("wikis")) {
      if (window.document.location.href.split("/").length - 1 === 4) {
        history.push(`${String(getFromLocalStorage("RECENT_WIKI")).replaceAll('"', "")}/${appSpaceID}`)
        return
      }
      history.push(
        `../${String(getFromLocalStorage("RECENT_WIKI")).replace("/wikis/", "").replaceAll('"', "")}/${appSpaceID}`
      )
    }

    if (currentSession("wikis")) {
      wikiDocTabs.forEach((item: any) => (item.isActive = false))
      wikiDocTabs.forEach((item: any) => {
        if (item.appSpace.name.split("##")[1] === appSpaceID) {
          item.isActive = true
          activeTab = item
        }
      })
    } else if (viewTabs.length > 0) {
      setActiveViewingTab(appSpace?.id)
      openFile({ variables: { fileId: appSpace?.fileId || "" } })
    } else selectAppSpace({ variables: { id: appSpace.id } })
  }

  if (appSpaces) {
    const names: string[] = []
    appSpaces?.forEach((tab: { appSpace: { name: string } }) => {
      names.push(tab.appSpace.name)
    })
    setWikiOpenTabs(names)
  } else setWikiOpenTabs([])

  return (
    <div className={cn([styles.appSpaces, appSpaces?.length > 8 && styles.overflowAppSpaces])}>
      {!isMobile && <NotificationView />}
      <AppSpaceCreator />

      {appSpaces?.length > 5 && (
        <AppSpacesManager appSpaces={appSpaces} activeAppSpace={data.activeAppSpace?.id || ""} />
      )}
      {appSpaces?.map((appSpace: any) => {
        if (hideAppSpaceTabs === false) return null
        return (
          <Tooltip
            title={appSpace?.appSpace?.name?.split("##")?.shift()}
            delay={500}
            size="big"
            disabled={!appSpace?.showTooltip}
            trigger="mouseenter"
            distance={35}
            position="top"
            key={appSpace?.id}
            className={
              appSpace?.id === (currentSession("wikis") ? activeTab?.id : activeAppSpace?.id)
                ? cn([styles.appSpaceActive, appSpaces.length > 8 && styles.adjustMinWidth])
                : cn([styles.appSpace, appSpaces.length > 8 && styles.adjustMinWidth])
            }
            style={
              appSpace?.id === (currentSession("wikis") ? activeTab?.id : activeAppSpace?.id)
                ? { padding: "0.7rem 1rem" }
                : { padding: "0.3rem 0.3rem" }
            }
          >
            <AppSpaceName
              activeAppSpace={(currentSession("wikis") ? activeTab?.id : activeAppSpace?.id) || ""}
              appSpace={{
                id: appSpace.appSpace.id,
                name: appSpace?.appSpace?.name?.split("##")?.shift(),
                type: appSpace.appSpace.type,
                previewing: appSpace.appSpace.previewing,
                fileId: appSpace.appSpace.fileId
              }}
              activeViewingTab={activeViewingTab}
              setActiveViewingTab={setActiveViewingTab}
              handleClick={handleClick}
              handleClose={(apps) => {
                if (window.location.href.includes("wikis") && window.document.location.href.split("/").length - 1 === 5)
                  history.push(
                    `../${String(getFromLocalStorage("RECENT_WIKI")).replace("/wikis/", "").replaceAll('"', "")}`
                  )
                if (currentSession("wikis")) {
                  wikiDocTabs.forEach((item: any) => {
                    if (item.appSpace.id === apps.id) wikiDocTabs.splice(wikiDocTabs.indexOf(item), 1)
                  })
                  if (wikiDocTabs.length === 0) return
                  wikiDocTabs[wikiDocTabs.length - 1].isActive = true
                  activeTab = wikiDocTabs[wikiDocTabs.length - 1]
                  setAppSpaces(wikiDocTabs)
                  history.push(
                    `${String(getFromLocalStorage("RECENT_WIKI")).replace("/wikis/", "").replaceAll('"', "")}/${
                      wikiDocTabs[wikiDocTabs.length - 1].id
                    }`
                  )
                  return
                }

                // Handle closing of file tabs from view command
                if (viewTabs.length > 0) {
                  removeViewTab(apps.id)

                  setAppSpaces(viewTabs)
                  setActiveViewingTab("preview")

                  // Close all if no more tabs
                  viewTabs.length === 0 && closeAppSpace({ variables: { appSpaceId: data?.activeAppSpace?.id || "" } })
                  if (viewTabs.length === 0) {
                    setFinishPreview(currentTab?.id)
                    setViewTabs([])
                    activateAppSpaceTabs(false)
                  }
                  return
                }

                // Default close app space
                closeAppSpace({ variables: { appSpaceId: appSpace.appSpace.id } })
              }}
              adjustMinWidth={appSpaces.length > 8}
              canRename={appSpace.appSpace?.type !== "imgPreview"}
              appSpaces={appSpaces}
              toggleTooltip={toggleTooltip}
            />
          </Tooltip>
        )
      })}
    </div>
  )
}

const AppSpacesManager: React.FC<{ appSpaces: AppSpaceTabs_AppSpaceFragment[]; activeAppSpace: string }> = ({
  appSpaces,
  activeAppSpace
}) => {
  const [search, setSearch] = useState("")
  const [selectAppSpace] = useAppSpaces_SelectAppSpaceMutation({
    onError: handleGraphQlError("Unable to select app space")
  })
  const filtered = appSpaces
    .map((a) => a.appSpace)
    .filter((appSpace) => appSpace?.name?.toLowerCase()?.includes(search.toLowerCase()))
    .slice(0, 10)

  const handleClick = (appSpace: { id: string }) => {
    selectAppSpace({
      variables: { id: appSpace.id }
    })
  }

  return (
    <Popup
      arrow={true}
      trigger={
        <div className={styles.appSpaceManager}>
          <i className="material-icons">expand_more</i>
        </div>
      }
      contentStyle={{ padding: 0 }}
      position="bottom center"
    >
      {
        ((close: () => void) => (
          <div className={cn(menu.menu, menu.leftCenter)}>
            <div className={styles.searchAppSpaces}>
              <i className="material-icons">search</i>
              <input
                className={cn(input.input, input.small)}
                value={search}
                onChange={(ev) => {
                  setSearch(ev.target.value)
                }}
                placeholder="Search Documents"
              />
            </div>
            {filtered.length > 0 && (
              <>
                {filtered.map((appSpace) => {
                  if (activeAppSpace !== appSpace.id) {
                    return (
                      <div
                        className={cn(menu.item, styles.appSpaceItem)}
                        key={appSpace.id}
                        onClick={() => {
                          close()
                          handleClick(appSpace)
                        }}
                      >
                        <div className={cn(fileTypeToColor(appSpace.type), styles.fileColorBox)} />
                        <span>{appSpace.name}</span>
                      </div>
                    )
                  } else {
                    return null
                  }
                })}
              </>
            )}

            {filtered.length === 0 && (
              <div className={styles.searchSessionsEmpty}>
                <p>No documents found for &quot;{search}&quot;</p>
              </div>
            )}
          </div>
        )) as any
      }
    </Popup>
  )
}

export default AppSpaceTabs
