/* eslint-disable */
import { OperationVariables, QueryHookOptions, QueryResult, useApolloClient, useQuery } from "@apollo/client"
import { DocumentNode } from "graphql"
import _ from "lodash"
import React, { DependencyList, Dispatch, EffectCallback, SetStateAction, useEffect } from "react"
import { ChangeEvent, useState, useRef } from "react"

/**
 * can be used directly in an input event
 * @param defaultValue the default value
 */
export function useInputValue(defaultValue = ""): [string, (e: ChangeEvent<HTMLInputElement> | string) => void] {
  const [value, setValue] = useState(defaultValue)

  return [
    value,
    (e: any) => {
      if (e.target) {
        setValue(e.target.value)
      } else {
        setValue(e)
      }
    }
  ]
}

// export function useDebounce<T extends (...args: any[]) => void>(fn: T, time: number): T {
//   const id = useRef(0 as any as NodeJS.Timeout)
//
//   return ((...args: any[]) => {
//     if (id.current) {
//       clearTimeout(id.current)
//     }
//
//     id.current = setTimeout(() => {
//       fn(...args)
//     }, time)
//   }) as any
// }

/**
 * Small wrapper around `useQuery` so that we can use it imperatively.
 *
 * @see Credit: https://github.com/apollographql/react-apollo/issues/3499#issuecomment-586039082
 *
 * @example
 * const callQuery = useImperativeQuery(query, options)
 * const handleClick = async () => {
 *   const{ data, error } = await callQuery()
 * }
 */
export default function useImperativeQuery<TData = any, TVariables extends OperationVariables = OperationVariables>(
  query: DocumentNode,
  options: QueryHookOptions<TData, TVariables> = {}
): QueryResult<TData, TVariables>["refetch"] {
  const { refetch } = useQuery<TData, TVariables>(query, {
    ...options,
    skip: true
  })

  return (queryVariables: TVariables) => {
    return refetch(queryVariables)
  }
}

export function useLazyQuery<TData = any, TVariables extends OperationVariables = OperationVariables>(query: DocumentNode) {
  const client = useApolloClient()
  return React.useCallback(
    (variables: TVariables) =>
      client.query<TData, TVariables>({
        query: query,
        variables: variables
      }),
    [client]
  )
}

export function useNonInitialEffect(effect: EffectCallback, deps?: DependencyList, except?: boolean) {
  const initial = useRef(true)

  return useEffect(() => {
    if (!initial.current || except) {
      effect()
    }
    initial.current = false
  }, deps)
}

export function useDependentState<T>(value: T): [T, Dispatch<SetStateAction<T>>] {
  const [state, setState] = useState(value)
  useEffect(() => {
    setState(value)
  }, [value])

  return [state, setState]
}

export function useOnDemandQuery<TData = any, TVariables extends OperationVariables = OperationVariables>(query: DocumentNode) {
  const client = useApolloClient()
  return React.useCallback(
    (variables: TVariables) =>
      client.query<TData, TVariables>({
        query: query,
        variables: variables
      }),
    [client]
  )
}

/**
 * Checks if the component has inner focus or not, calls cancel function when there is no focus
 * @param ref Array of references to be checked for focus and blur
 * @param cancel Function to call when none of the inputs have focus
 */
export function useInnerFocus(
  ref: (React.RefObject<HTMLInputElement> | React.RefObject<HTMLTextAreaElement>)[],
  cancel: () => void
): void {
  let blurTimeout: NodeJS.Timer

  useEffect(() => {
    if (_.every((ref: any) => ref.current)) {
      // check if all the references are valid
      ref.forEach((input) => {
        // on all references
        input.current?.addEventListener("blur", () => {
          if (!input.current?.value) {
            blurTimeout = setTimeout(cancel, 100)
          }
        })

        input.current?.addEventListener("focus", () => {
          clearTimeout(blurTimeout)
        })
      })
    }
  }, ref)
}

/**
 * Return filtered array
 * @param array array
 * @param decimate decimate function for comparer
 * @returns
 */
export function useFilter(array: any[], decimate: (x: any) => any): [any, (a: any) => void] {
  const [d, setD] = useState<any[]>([])

  return [
    array.filter((x) => !d.includes(decimate(x))),
    (addDel: any) => {
      setD((d) => [...d, addDel])
    }
  ]
}
