import React from "react"
import styles from "./EmojiSelector.module.scss"
import { useEmojiSelectorQuery, useEmojiSelector_UpdateSkinTonePreferenceMutation } from "generated/graphql"
import _, { groupBy } from "lodash"
import { handleGraphQlError } from "utils/common"
import cn from "clsx"

const EmojiSelector: React.FC<{
  select: (key: string, icon: string) => void
  selected: { icon: string }[]
}> = ({ select, selected }) => {
  const { data, refetch } = useEmojiSelectorQuery()
  const [changeSkinTone] = useEmojiSelector_UpdateSkinTonePreferenceMutation({
    onError: handleGraphQlError("Unable to select skin-tone"),
    onCompleted: () => {
      refetch()
    }
  })

  if (!data) {
    return <div className={styles.root}></div>
  }

  const checkSelected = (icon: string) => selected.findIndex((x) => x.icon === icon) > -1

  const emojis = groupBy(data.emoticons, "category")

  return (
    <div className={styles.root}>
      <div className={styles.emojiSelector}>
        {Object.keys(emojis).map((category) => (
          <div className={styles.category} key={category}>
            <div className={styles.title}>
              <span>{category}</span>
              <hr />
            </div>

            <div className={styles.emojis}>
              {emojis[category].map((emoji) => (
                <span
                  key={emoji.id}
                  onClick={() => {
                    select(emoji.code, emoji.icon)
                  }}
                  className={cn([checkSelected(emoji.icon) ? styles.active : null])}
                >
                  <img src={emoji.icon} alt={emoji.name} />
                </span>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className={styles.skinToneSwitcher}>
        <hr style={{ marginBottom: 10 }} />
        <span className={styles.title}>Switch Skin Tone</span>

        <div className={styles.tones}>
          {_.times(6, (i) => (
            <button
              key={i}
              className={data.me?.skinTonePreference === i ? styles.active : ""}
              onClick={() => changeSkinTone({ variables: { tone: i } })}
            >
              <img
                src={
                  i > 0
                    ? `https://s3.ap-southeast-2.amazonaws.com/media.pretzel.space/emoji/thumbs_up-st${i}.svg`
                    : `https://s3.ap-southeast-2.amazonaws.com/media.pretzel.space/emoji/thumbs_up.svg`
                }
                alt={`Skin Tone ${i}`}
              />
            </button>
          ))}
        </div>
      </div>
    </div>
  )
}

export default EmojiSelector
