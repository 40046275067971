/* eslint-disable */
import React, { useCallback, useEffect, useRef, useState } from "react"
import styles from "./SessionTabs.module.scss"
import sessionStyles from "../sessionTab/SessionTab.module.scss"
import cn from "clsx"
import input from "modules/shared/styles/components/input.module.scss"
import button from "modules/shared/styles/components/button.module.scss"
import {
  useSessionTabsQuery,
  useSessions_RenameSessionMutation,
  useSessions_CreateSessionMutation,
  useSessions_SelectSessionMutation,
  useSessions_CloseSessionMutation,
  useSessions_ReopenSessionMutation,
  useSessionTabs_OtherSessionsLazyQuery
} from "generated/graphql"
import SessionTab from "../sessionTab"
import { getDate, getFormattedDate, groupSessionsByAge, handleGraphQlError } from "utils/common"
import { KeyCodes, topics, routes } from "consts"
import { useHistory } from "react-router-dom"
import Popup from "reactjs-popup"
import menu from "modules/shared/styles/components/menu.module.scss"
import GroupSelector from "modules/shared/components/groupSelector"
import ExpandableRecentsList from "modules/shared/components/expandableRecentsList"
import pubsub from "pubsub-js"
import { getSessionLocation, useSessionLocationsRoot } from "modules/app/helpers/sessionLocationHelpers"
import Tooltip from "modules/shared/components/tooltip"
const c = console.log.bind(document)
const t = console.table.bind(document)

export let viewTabs: any
export let writeTabs: any
export let setActiveTab: any
export let currentTab: any

let TabContext = {
  fetchOnce: false,
  selectOnce: false,
  history: null as any
}

export function updateTab(id: string, itemKey: string, newValue: any): void {
  const itemIndex = viewTabs.findIndex((item: any) => item.session.id === id)
  if (itemIndex === -1) return
  const newArray = [...viewTabs]
  newArray[itemIndex] = {
    ...newArray[itemIndex],
    session: {
      ...newArray[itemIndex].session,
      [itemKey]: newValue
    }
  }
  writeTabs(newArray)
}

export function newTab({
  name,
  id,
  icon,
  type,
  groupId,
  location,
  active,
  forceOpen
}: {
  name?: string
  id?: string
  icon: string
  type?: string
  groupId?: string
  location: string
  active: boolean
  forceOpen: boolean
}) {
  let newSession
  if (localStorage.sessionNumber) {
    newSession = localStorage.sessionNumber.split(":")[1]
    newSession = Number(newSession) + 1
    localStorage.sessionNumber = localStorage.sessionNumber.split(":")[0] + ":" + newSession
  }
  if (!name) name = getFormattedDate() + " | " + newSession || "1"
  if (!id) id = Date.now().toString()

  writeTabs(
    viewTabs.concat({
      __typename: "AppSessionSession",
      id: id + id.slice(3, 5),
      isActive: false,
      session: {
        __typename: "Session",
        id: id,
        name,
        icon,
        type,
        location,
        createdDate: getDate(),
        isActive: false,
        isClosed: false,
        groupId: groupId || null
      }
    })
  )
  setTimeout(() => {
    if (active) setActiveTab(id)
    if (forceOpen) TabContext.history.push(location)
  }, 30)
}

interface iState {
  hasAppSpaceTabs: boolean
}
const SessionTabs: React.FC<iState> = ({ hasAppSpaceTabs }: iState) => {
  const { data: result } = useSessionTabsQuery({
    onError: handleGraphQlError("Unable to get sessions!")
  })
  const [renameSession] = useSessions_RenameSessionMutation({
    onError: handleGraphQlError("Unable to rename session")
  })
  const [createSession] = useSessions_CreateSessionMutation({
    onError: handleGraphQlError("Unable to create session")
  })
  const [selectSession] = useSessions_SelectSessionMutation({
    onError: handleGraphQlError("Unable to select session")
  })
  const [closeSession] = useSessions_CloseSessionMutation({})

  const [activeSessionId, setActiveSessionId] = useState<string>()
  const [manageSessionVisible, setManageSessionVisible] = useState(false)
  const [sessionRenameInput, setSessionRenameInput] = useState("")
  const [tabs, setTabs] = useState<any>()
  const dragItem = React.useRef<any>(null)
  const dragOverItem = React.useRef<any>(null)

  setActiveTab = setActiveSessionId
  TabContext.history = useHistory()
  writeTabs = setTabs
  viewTabs = tabs

  useEffect(() => {
    // need to open a session with the given path
    const token = pubsub.subscribe(topics.SESSION_OPEN, async (_: any, data: { path: string; name: string }) => {
      // check if there is alreay a session with that path
      const existing =
        result && result.sessions
          ? result.sessions
              .map((s: any) => ({ s: s.session, l: getSessionLocation(s.session.id) }))
              .filter((s: any) => !!s.l)
              .find((s: any) => s.l.pathname === data.path)
          : null

      if (existing) {
        // if the session is not the active one, select it.
        if (!existing.s.isActive) {
          selectSession({ variables: { id: existing.s.id } })
        }
      } else {
        await createSession({ variables: { name: data.name } })
        TabContext.history.push(data.path)
      }
    })
    return () => {
      pubsub.unsubscribe(token)
    }
  }, [result?.sessions, selectSession])

  useEffect(() => {
    if (result?.sessions && !TabContext.fetchOnce) {
      writeTabs(result.sessions)
      TabContext.fetchOnce = true
    }
  }, [result?.sessions, tabs])

  // Only leave open one session upon startup
  useEffect(() => {
    if (result?.sessions.length === 2) {
      const sessionsToClose = result?.sessions.filter((s: any) => s.session.id !== activeSessionId)[0]
      closeSession({ variables: { id: sessionsToClose.session.id } })
    } else {
      result?.sessions.forEach((session: any) => {
        if (!session.session.isActive) {
          closeSession({ variables: { id: session.session.id } })
        }
      })
    }
  }, [result?.sessions])

  useSessionLocationsRoot(activeSessionId || "")
  const toggleTooltip = useCallback((sessionId: string, showTooltip: boolean) => {
    writeTabs((sessionData: any) => {
      return sessionData.map((sessionTab: any) => {
        if (sessionTab?.session?.id === sessionId) {
          return { ...sessionTab, showTooltip: showTooltip }
        }
        return { ...sessionTab }
      })
    })
  }, [])

  useEffect(() => {
    if (result?.activeSession && !TabContext.selectOnce) {
      TabContext.selectOnce = true
      setActiveSessionId(result?.activeSession.id)
    }
  }, [result?.activeSession])

  if (!result || !activeSessionId) {
    return null
  }

  const updateSessionName = async () => {
    if (sessionRenameInput) {
      await renameSession({
        variables: { newName: sessionRenameInput, sessionId: activeSessionId },
        optimisticResponse: {
          renameSession: { id: activeSessionId, name: sessionRenameInput }
        }
      })

      setManageSessionVisible(false)
    }
  }

  const handleRenameInputKeyDown = async (e: { keyCode: number }) => {
    if (e.keyCode === KeyCodes.Enter) {
      await updateSessionName()
    }
  }

  const handleNewSession = async () => {
    const useId = Date.now().toString()
    newTab({ name: "", id: useId, type: "", icon: "default", location: routes.home, active: true, forceOpen: true })
    createSession({}).then((res: any) => {
      const backendSession = res?.data?.createSession.activeSession.id
      updateTab(useId, "type", "home:" + backendSession)
      if (backendSession) selectSession({ variables: { id: backendSession } })
    })
  }

  const changeSessionSelect = async (event: any) => {
    const id = event.target.value

    if (id === "CREATE_SESSION") {
      handleNewSession()
    } else if (id === "RENAME_SESSION") {
      setSessionRenameInput(result.activeSession.name || "")
      setManageSessionVisible(true)
    } else {
      if (activeSessionId !== id) {
        selectSession({ variables: { id } })
      }
    }
  }

  async function handleCloseSession(id: string) {
    await closeSession({
      variables: { id }
    })
  }

  const getSessionManager = () => {
    if (manageSessionVisible) {
      return (
        <div className={cn(styles.sessionManager, "modal")}>
          <div className="modal-bg" />
          <div className="modal-content">
            <span className="modal-title">Rename Session</span>

            <i className={cn("material-icons", "modal-close-button")} onClick={() => setManageSessionVisible(false)}>
              close
            </i>
            <br />
            <br />
            <input
              type="text"
              className={cn(input.input, input.big)}
              value={sessionRenameInput}
              onChange={(e) => setSessionRenameInput(e.target.value)}
              onKeyDown={handleRenameInputKeyDown}
            />

            <div className="modal-buttons">
              <button
                className={cn(button.button, button.primary, button.roundedButton, button.big)}
                onClick={() => updateSessionName()}
              >
                Rename
              </button>
              <button
                className={cn(button.button, button.secondary, button.roundedButton, button.big)}
                onClick={() => setManageSessionVisible(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )
    } else return <></>
  }

  const getSessionSwitcher = () => {
    const current = tabs.find((s: any) => s.session.id === activeSessionId)

    if (current) {
      return (
        <div className={styles.sessionSwitcher}>
          <label className={styles.sessionSelectLabel}>ACTIVE SESSION</label>
          <span className={styles.sessionsCount}>
            <span>{tabs.length}</span>
          </span>
          <div className={styles.deleteSessionButton} onClick={() => handleCloseSession(current.session.id)}>
            <span>
              <i className="fa fa-times" />
              CLOSE SESSION
            </span>
          </div>
          <select
            className={styles.sessionSelect}
            name="sessionSelection"
            id="sessionSelection"
            onChange={changeSessionSelect}
            value={current.session.id}
          >
            {tabs.map((session: any) => (
              <option value={session.session.id} key={session.session.id}>
                {session.session.name}
              </option>
            ))}
            <option value="CREATE_SESSION" key="create-session">
              Create new session
            </option>
            <option value="RENAME_SESSION" key="rename-session">
              Rename session
            </option>
          </select>
        </div>
      )
    } else {
      return <></>
    }
  }

  const reorderTabs = () => {
    let reorganize = [...viewTabs]
    const draggedItemContent = reorganize.splice(dragItem.current, 1)[0]
    reorganize.splice(dragOverItem.current, 0, draggedItemContent)
    dragItem.current = null
    dragOverItem.current = null
    writeTabs(reorganize)
  }

  return (
    <>
      <div
        className={
          !hasAppSpaceTabs
            ? cn([styles.sessions, tabs.length > 8 && styles.overflowSessions])
            : cn([styles.sessions, styles.halfSpace, tabs.length > 8 && styles.overflowSessions])
        }
      >
        {tabs.map((session: any, index: number) => {
          if (session.session.id === activeSessionId) {
            currentTab = session.session
          }

          return (
            <Tooltip
              title={session.session.name}
              delay={500}
              size="big"
              disabled={!session?.showTooltip}
              trigger="mouseenter"
              position="top"
              key={session.id}
              distance={tabs.length > 8 ? 35 : 5}
              className={
                session.id === activeSessionId
                  ? cn([sessionStyles.sessionActive, tabs.length > 8 && sessionStyles.adjustMinWidth])
                  : cn([sessionStyles.session, tabs.length > 8 && sessionStyles.adjustMinWidth])
              }
              style={
                session.id === activeSessionId
                  ? {
                      padding: index === 0 ? 0 : "0.7rem 1rem",
                      height: index === 0 ? 0 : "100%",
                      width: index === 0 ? 0 : undefined,
                      opacity: index === 0 ? 0 : 1
                    }
                  : {
                      padding: index === 0 ? 0 : "0.3rem 0.3rem",
                      height: index === 0 ? 0 : "100%",
                      width: index === 0 ? 0 : undefined,
                      opacity: index === 0 ? 0 : 1
                    }
              }
            >
              <div
                draggable={true}
                onDragStart={(e) => {
                  dragItem.current = index
                }}
                onDragEnter={(e) => {
                  dragOverItem.current = index
                }}
                onDragEnd={reorderTabs}
                style={{ height: "100%" }}
                onDragOver={(e) => {
                  e.preventDefault()
                }}
              >
                <SessionTab
                  active={activeSessionId}
                  setActive={(id) => {
                    setActiveSessionId(id)
                  }}
                  only={tabs.length === 2}
                  session={{
                    id: session.session.id,
                    name: session.session.name,
                    icon: session.session.icon || null,
                    type: session.session.type || null,
                    location: session.session.location || null,
                    groupId: session.session.groupId
                  }}
                  adjustMinWidth={tabs.length > 8}
                  toggleTooltip={toggleTooltip}
                />
              </div>
            </Tooltip>
          )
        })}
        <>
          <div className={styles.newSession} onClick={handleNewSession}>
            <i className="material-icons">add</i>
          </div>
          <SessionManager />
        </>
      </div>
      {getSessionSwitcher()}
      {getSessionManager()}
    </>
  )
}

const SessionManager: React.FC = () => {
  const [search, setSearch] = useState("")
  const [getOtherSessions, { data: otherSessions }] = useSessionTabs_OtherSessionsLazyQuery({
    fetchPolicy: "no-cache"
  })
  const [reopenSession] = useSessions_ReopenSessionMutation({
    onError: handleGraphQlError("Unable to reopen session")
  })

  const handleClick = (session: any) => {
    reopenSession({ variables: { sessionId: session.id } })
  }

  const sessions = otherSessions?.otherSessions.filter((session: any) =>
    session.name.toLowerCase().includes(search.toLowerCase())
  )
  const groups = groupSessionsByAge(sessions || [])

  return (
    <Popup
      arrow={true}
      trigger={
        <div className={styles.newSession}>
          <i className="material-icons">expand_more</i>
        </div>
      }
      contentStyle={{ padding: 0 }}
      position="bottom center"
      onOpen={() => getOtherSessions()}
    >
      {
        ((close: () => void) => (
          <div className={cn(menu.menu, menu.leftCenter)}>
            <div className={styles.searchSessions}>
              <i className="material-icons">search</i>
              <input
                className={cn(input.input, input.small)}
                value={search}
                onChange={(ev) => {
                  setSearch(ev.target.value)
                }}
                placeholder="Search sessions"
              />
            </div>

            <GroupSelector
              groups={groups}
              defaultGroup={"Today"}
              content={(group: any, setSelectedGroup: (s: string) => void, otherGroups: string[]) => (
                <>
                  <ExpandableRecentsList
                    itemRenderer={(session: any) => (
                      <div
                        className={cn(menu.item)}
                        key={session.id}
                        onClick={() => {
                          close()
                          handleClick(session)
                        }}
                      >
                        <i className="material-icons">space_dashboard</i>
                        <span>{session.name}</span>
                      </div>
                    )}
                    items={group.items}
                    itemTypeName="sessions"
                    showMoreLessLabel={(text: string, toggle: () => void) => (
                      <div key={text} className={cn(menu.item, menu.centered, menu.gray)} onClick={toggle}>
                        {text}
                      </div>
                    )}
                  />
                  <div className={menu.separator} />
                  {otherGroups.map((og) => (
                    <div key={og} className={cn([menu.item])} onClick={() => setSelectedGroup(og)}>
                      <i className="material-icons">event</i>
                      <span>Sessions made {og.toLowerCase()}</span>
                    </div>
                  ))}
                </>
              )}
            />

            {(sessions || []).length === 0 && (
              <div className={styles.searchSessionsEmpty}>
                <p>No sessions found for &quot;{search}&quot;</p>
              </div>
            )}
          </div>
        )) as any
      }
    </Popup>
  )
}

export default SessionTabs
