/* eslint-disable */
import React, { createContext, useState } from "react"

const MoreButtonContext = createContext<{
  defaultCount: number
  more: boolean
  setMore: (b: boolean) => void
}>({} as any)

const MoreButton: React.FC<{ defaultCount: number; children: React.ReactNode }> = ({ defaultCount, children }) => {
  const [more, setMore] = useState(false)

  return (
    <MoreButtonContext.Provider
      value={{
        defaultCount,
        more,
        setMore
      }}
    >
      {children}
    </MoreButtonContext.Provider>
  )
}

export { MoreButton, MoreButtonContext }
