import React from "react"
import styles from "./EmptyFilesWarning.module.scss"
import button from "modules/shared/styles/components/button.module.scss"
import cn from "clsx"

const EmptyFilesWarning: React.FC<{
  mode?: string
  newFolder?: () => void
}> = ({ mode, newFolder }) => {
  if (mode === "tree")
    return (
      <tr>
        <td className={cn([styles.emptyFilesWarning, styles.center])} colSpan={5}>
          <i className="fa fa-info"></i>
          <span>No files or folders found</span>

          <button
            className={cn(button.button, button.primary, button.iconButton)}
            onClick={() => {
              if (newFolder) newFolder()
            }}
          >
            <i className="fa fa-plus"></i>
            Create New Folder
          </button>
        </td>
      </tr>
    )
  else
    return (
      <tr>
        <td className={styles.emptyFilesWarning} colSpan={7}>
          <i className="fa fa-info"></i>
          <span>No files found</span>
        </td>
      </tr>
    )
}

export default EmptyFilesWarning
