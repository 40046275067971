import React, { useRef, useState } from "react"
import Modal from "react-modal"
import { fixName, fixPhone, handleGraphQlError, modalStyles, textEllipsis, userOnlineStatus } from "utils/common"
import styles from "./ProfileView.module.scss"
import modal from "modules/shared/styles/components/modal.module.scss"
import anchor from "modules/shared/styles/components/anchor.module.scss"
import ProfilePicture from "modules/shared/components/profilePicture"
import cn from "clsx"
import {
  useProfileQuery,
  useSidebarChannels_SelectChannelMutation,
  useUpdateProfileMutation,
  useUploadProfilePictureMutation
} from "generated/graphql"
import EditableInput from "modules/shared/components/editableInput"
import shimmer from "modules/shared/styles/components/shimmer.module.scss"
import { routes, topics } from "consts"
import pubsub from "pubsub-js"
import { useHistory } from "react-router-dom"

const ProfileView: React.FC<{ id: string | null; open: boolean; close: () => void }> = ({ id, open, close }) => {
  const { data, refetch, loading } = useProfileQuery({
    variables: { id: id || "" },
    fetchPolicy: "network-only"
  })

  const inputFile = useRef<HTMLInputElement>(null)
  const [uploadInProgress, setUploadInProgress] = useState(false)
  const [updateProfile] = useUpdateProfileMutation({
    onError: handleGraphQlError("Unable to update profile")
  })
  const [uploadProfilePicture] = useUploadProfilePictureMutation({
    onError: handleGraphQlError("Unable to upload profile picture")
  })
  const [selectDM] = useSidebarChannels_SelectChannelMutation({
    onError: handleGraphQlError("Unable to select message")
  })

  const history = useHistory()

  if (!data) {
    return (
      <Modal
        isOpen={open}
        onRequestClose={close}
        contentLabel="Profile View"
        style={{
          content: {
            ...modalStyles.content,
            padding: 0
          },
          overlay: modalStyles.overlay
        }}
      >
        <div className={cn([modal.modal, styles.shimmer, styles.root])}>
          <div className={styles.header}>
            <div className={cn([styles.pp, shimmer.shimmer])}></div>
            <div className={styles.meta}>
              <div className={cn([styles.name, shimmer.shimmer])}></div>
              <div className={cn([styles.details, shimmer.shimmer])}></div>
            </div>
            <div className={styles.actions}>
              <div className={cn([shimmer.shimmer, styles.action])}></div>
              <div className={cn([shimmer.shimmer, styles.action])}></div>
            </div>
          </div>

          <div className={styles.content}>
            <div className={styles.sectionTitle}>
              <label className={cn([shimmer.shimmer, styles.text])}></label>
              <div></div>
            </div>

            <div className={cn([styles.data])}>
              <div className={styles.col}>
                <div className={cn([shimmer.shimmer, styles.icon])}></div>
                <div className={cn([shimmer.shimmer, styles.text])} style={{ width: "30%" }}></div>
              </div>
            </div>

            <div className={styles.sectionTitle}>
              <label className={cn([shimmer.shimmer, styles.text])} style={{ width: 120 }}></label>
              <div></div>
            </div>

            <div className={cn([styles.data])}>
              <div className={styles.col}>
                <div className={cn([shimmer.shimmer, styles.icon])}></div>
                <div className={cn([shimmer.shimmer, styles.text])} style={{ width: "50%" }}></div>
              </div>
              <div className={styles.col}>
                <div className={cn([shimmer.shimmer, styles.icon])}></div>
                <div className={cn([shimmer.shimmer, styles.text])} style={{ width: "70%" }}></div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }

  const saveProfile = async (input: { name?: string; phone?: string; role?: string }) => {
    await updateProfile({
      variables: {
        name: input.name || data.profile?.name,
        phone: input.phone || data.profile?.phone,
        role: input.role || data.profile?.role
      }
    })
    refetch()
  }

  const editable = data.profile?.isCurrentUser
  const dms = data.profile?.groups?.filter((x) => x?.type === "dm" || x?.type === "1-1")
  const channels = data.profile?.groups?.filter((x) => x?.type === "channel")

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const startUpload = async (event: any) => {
    try {
      const files = event.target.files as File[]
      if (!files || !files.length) {
        return
      }

      setUploadInProgress(true)
      const file = files[0]

      const r = await uploadProfilePicture()
      const data = r.data?.uploadProfilePicture

      if (!data) {
        return
      }

      const formData = new FormData()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const postData = JSON.parse(data) as any
      Object.keys(postData.fields).forEach((key) => {
        formData.append(key, postData.fields[key])
      })

      // Actual file has to be appended last.
      await new Promise<void>((resolve, reject) => {
        formData.append("file", file)
        const xhr = new XMLHttpRequest()
        xhr.open("POST", postData.url, true)
        xhr.send(formData)
        xhr.onload = function () {
          this.status === 204 ? resolve() : reject(this.responseText)
        }
      })

      refetch()
      pubsub.publish(topics.SIDEBAR_UPDATE_PROFILE)
      pubsub.publish(topics.CHAT_HEADER_UPDATE_PROFILE)
      // window.location.reload()
    } finally {
      setUploadInProgress(false)
    }
  }

  const currentUser = () => {
    return (
      <>
        <div className={styles.header}>
          <div className={styles.profileContainer}>
            <ProfilePicture
              size={"larger"}
              unclickable={true}
              userId={data.profile?.id || ""}
              userName={data.profile?.name || ""}
              src={data.profile?.profilePicture || ""}
              statusMode={"dot"}
            />
          </div>
          <div className={styles.meta}>
            <span className={styles.name}>{data.profile?.name}</span>
            <span className={cn([styles.description, styles.empty])}>Update Profile</span>
          </div>
        </div>

        <div className={styles.content}>
          <div className={styles.sectionTitle}>
            <label>Basic Information</label>
            <div></div>
          </div>

          <input
            type="file"
            multiple
            ref={inputFile}
            onChange={startUpload}
            style={{ display: "none" }}
            accept="image/*"
          />

          <div className={styles.updatableSections} style={{ opacity: loading ? 0.5 : 1 }}>
            <div
              className={cn([styles.section, styles.clickable])}
              onClick={() => {
                inputFile.current?.click()
              }}
              style={{ opacity: uploadInProgress ? 0.5 : 1, pointerEvents: uploadInProgress ? "none" : "all" }}
            >
              <ProfilePicture
                size={"large"}
                unclickable={true}
                userId={data.profile?.id || ""}
                userName={data.profile?.name || ""}
                src={data.profile?.profilePicture || ""}
              />
              <div className={styles.meta}>
                <span className={styles.field}>Profile Picture</span>
                <span className={styles.value}>Click to choose</span>
              </div>
            </div>
            <div className={cn([styles.section])}>
              <i className="material-icons-outlined">face</i>
              <div className={styles.meta}>
                <span className={styles.field}>Full Name</span>
                <EditableInput
                  value={data.profile?.name || ""}
                  editable={true}
                  isTextArea={false}
                  enhancer={fixName}
                  alwaysEditVisible={true}
                  saveCallback={async (v) => {
                    if (v) {
                      saveProfile({
                        name: v
                      })
                      pubsub.publish(topics.CHAT_MESSAGE_UPDATE_USERNAME)
                    }
                  }}
                />
              </div>
            </div>
            <div className={cn([styles.section])}>
              <i className="material-icons-outlined">mail</i>
              <div className={styles.meta}>
                <span className={styles.field}>Email Address</span>
                <span>{data.profile?.email || ""}</span>
              </div>
            </div>
            <div className={cn([styles.section])}>
              <i className="material-icons-outlined">phone</i>
              <div className={styles.meta}>
                <span className={styles.field}>Phone</span>
                <EditableInput
                  value={data.profile?.phone || ""}
                  placeholder="Phone not provided"
                  editable={true}
                  isTextArea={false}
                  enhancer={fixPhone}
                  alwaysEditVisible={true}
                  saveCallback={(v) => {
                    if (v) {
                      // validate
                      saveProfile({
                        phone: v
                      })
                    }
                  }}
                />
              </div>
            </div>
            <div className={cn([styles.section])}>
              <i className="material-icons">work_outline</i>
              <div className={styles.meta}>
                <span className={styles.field}>Role</span>
                <EditableInput
                  value={data.profile?.role || ""}
                  placeholder="Role not provided"
                  alwaysEditVisible={true}
                  editable={true}
                  isTextArea={false}
                  enhancer={fixName}
                  saveCallback={(v) => {
                    if (v) {
                      // validate
                      saveProfile({
                        role: v
                      })
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={modal.closeButton} onClick={close}>
          <i className="material-icons">close</i>
        </div>
      </>
    )
  }

  const startChat = () => {
    pubsub.publish(topics.DM_OPEN, data.profile?.id)
    close()
  }

  const otherUser = () => {
    return (
      <>
        <div className={styles.header}>
          <div className={styles.profileContainer}>
            <ProfilePicture
              size={"larger"}
              unclickable={true}
              userId={data.profile?.id || ""}
              userName={data.profile?.name || ""}
              src={data.profile?.profilePicture || ""}
              statusMode={"dot"}
            />
          </div>
          <div className={styles.meta}>
            <span className={styles.name}>{data.profile?.name}</span>
            {data.profile?.lastSeen && (
              <span className={styles.description}>{userOnlineStatus(data.profile?.lastSeen).caption}</span>
            )}
          </div>
          <div className={styles.actions}>
            <button className={styles.action} onClick={startChat}>
              <i className="material-icons-outlined">chat_bubble_outline</i>
              <span>Message</span>
            </button>
            <button className={styles.action}>
              <i className="material-icons-outlined">block</i>
              <span>Block</span>
            </button>
          </div>
        </div>

        <div className={styles.content}>
          <div className={styles.sectionTitle}>
            <label>About</label>
            <div></div>
          </div>

          <div className={cn([styles.data, styles.horiz])}>
            <div className={styles.col}>
              <i className="material-icons">mail</i>
              <a
                href={`mailto:${data.profile?.email}`}
                className={cn([styles.value, anchor.anchor, anchor.nounderline])}
              >
                {data.profile?.email}
              </a>
            </div>
            {data.profile?.phone && (
              <div className={styles.col}>
                <i className="material-icons">phone</i>
                <a
                  href={`tel:${data.profile?.phone}`}
                  className={cn([styles.value, anchor.anchor, anchor.nounderline])}
                >
                  {data.profile?.phone}
                </a>
              </div>
            )}
          </div>
          {channels && channels.length > 0 && (
            <>
              <div className={styles.sectionTitle}>
                <label>Channels</label>
                <div></div>
              </div>

              <div className={cn([styles.data])}>
                {channels?.map((group, i) => (
                  <div className={cn([styles.channelItem])} key={i}>
                    <i className="material-icons">chat_bubble_outline</i>
                    <div className={styles.meta}>
                      <span className={styles.name}>{group?.name}</span>
                      {group?.description && <p className={styles.description}>{group?.description}</p>}
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}

          {dms && dms.length > 0 && (
            <>
              <div className={styles.sectionTitle}>
                <label>Direct Messaging</label>
                <div></div>
              </div>

              <div className={cn([styles.data])}>
                {dms?.map((group, i) => (
                  <div
                    className={cn([styles.channelItem])}
                    key={i}
                    onClick={async () => {
                      await selectDM({
                        variables: {
                          id: group?.id || ""
                        }
                      })

                      history.push(routes.chat)
                      close()
                    }}
                  >
                    <ProfilePicture
                      size={"small"}
                      userId={group?.members[1].id || ""}
                      userName={group?.name || ""}
                      src={data.profile?.profilePicture || ""}
                    />
                    <div className={styles.meta}>
                      <span className={styles.name}>{textEllipsis(group?.name || "", 40)}</span>

                      {group?.type !== "1-1" && group?.description && (
                        <p className={styles.description}>{group?.description}</p>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>

        <div className={modal.closeButton} onClick={close}>
          <i className="material-icons">close</i>
        </div>
      </>
    )
  }

  if (!open) {
    return null
  }

  return (
    <Modal
      isOpen
      onRequestClose={close}
      contentLabel="Profile View"
      style={{
        content: {
          ...modalStyles.content,
          padding: 0
        },
        overlay: modalStyles.overlay
      }}
    >
      <div className={cn([modal.modal, styles.root])} key={data.profile?.id}>
        {editable && currentUser()}
        {!editable && otherUser()}
      </div>
    </Modal>
  )
}

export default ProfileView
