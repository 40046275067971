/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { lazy, Suspense, useCallback, useContext, useMemo, useRef, useState } from "react"

import ImageViewer from "../imageViewer"
import spinner from "modules/shared/styles/components/spinner.module.scss"

import cn from "clsx"
import styles from "./AppSpace.module.scss"
import {
  useAppSpaces_UpdateFileContentMutation,
  AppSpaceDocument,
  useAppSpaceQuery,
  useAppSpaces_RenameAppSpaceMutation, //,
  useAppSpaces_SelectAppSpaceMutation,
  useAppSpaces_CloseAppSpaceMutation
} from "generated/graphql"
import { resizingSplitPane } from "modules/app/state"
import { useApolloClient } from "@apollo/client"
import { KeyCodes } from "consts"
import SuspensePreloader from "modules/shared/components/suspensePreloader"
import SplitterContext from "modules/shared/components/splitterContext"

import { ErrorBoundary } from "react-error-boundary"

import button from "modules/shared/styles/components/button.module.scss"
import MultiTableView from "modules/chat/components/tableResult/multiTableView"
import { TableContainer } from "modules/chat/components/tableResult/models"
import { handleGraphQlError } from "utils/common"

import TextDocument from "../textDocument"
const SheetDocument = lazy(() => import("../sheetDocument"))
const PdfViewer = lazy(() => import("../pdfViewer"))
const HTMLViewer = lazy(() => import("../htmlViewer"))
const ChessGame = lazy(() => import("../chessGame"))
const Wiki = lazy(() => import("../wikiApp"))

type PushHandler = (id: string, content: string) => unknown
type CacheItem = { content: string; timeout: NodeJS.Timeout }

export class ContentHandler {
  nltc = false
  /**
   * Create a new content handler
   * @param pushHandler Callback to send data to the backend
   * @param nltc No Long Term Cache
   */
  constructor(pushHandler: PushHandler, nltc = false) {
    this.nltc = true
    this.cache = nltc ? {} : JSON.parse(localStorage.getItem("appspace_cache") || "{}") || {}
    this.pushHandler = pushHandler
  }

  private cache: { [key: string]: CacheItem }
  private pushHandler: PushHandler

  public set(id: string, content: string, noPush = false): string {
    const fromCache = this.cache[id]
    if (fromCache) {
      clearTimeout(fromCache.timeout)
    }

    const timeout = setTimeout(() => {
      if (!noPush) {
        this.pushHandler(id, this.cache[id].content)
      }
    }, 500)
    this.cache[id] = { content, timeout }
    if (!this.nltc) {
      try {
        localStorage.setItem("appspace_cache", JSON.stringify(this.cache))
      } catch (e) {
        // usually the file is too long
        localStorage.setItem("appspace_cache", "{}")
      }
    }
    return content
  }

  public get(id: string): string {
    return this.cache[id]?.content
  }
}

const AppSpaceErrorFallback: React.FC<{ error: Error; resetErrorBoundary: () => void }> = ({ resetErrorBoundary }) => {
  return (
    <div className={styles.errorFallbackRoot}>
      <i className="material-icons">error</i>
      <div className={styles.meta}>
        <span className={styles.title}>We&apos;re Sorry</span>
        <span className={styles.description}>We are having some issues opening this App Space</span>
        <button className={cn(button.button, button.primary, button.noAnimation)} onClick={resetErrorBoundary}>
          <i className="material-icons">refresh</i>
          <span>Retry</span>
        </button>
      </div>
    </div>
  )
}

const AppSpaceController: React.FC = () => {
  const { data: dataNew } = useAppSpaceQuery({
    onError: handleGraphQlError("Unable to get appspace data")
  })
  const { dragging } = useContext(SplitterContext)

  const [isRenaming, setIsRenaming] = useState(false)
  const [appSpaceName, setAppSpaceName] = useState("")
  const [loaded, setLoaded] = useState(false)

  const [updateFileContent] = useAppSpaces_UpdateFileContentMutation({
    onError: handleGraphQlError("Unable to update content")
  })
  const [renameAppSpace] = useAppSpaces_RenameAppSpaceMutation({
    onError: handleGraphQlError("Unbale to rename app space")
  })
  const [selectAppSpace] = useAppSpaces_SelectAppSpaceMutation({})
  const [closeAppSpace] = useAppSpaces_CloseAppSpaceMutation({
    onError: handleGraphQlError("Unable to close app space")
  })

  const [collapsed, setCollapsed] = useState(false)

  const apolloClient = useApolloClient()

  const updateHandler = useCallback(async (id: string, content: string) => {
    const existing = apolloClient.readQuery({
      query: AppSpaceDocument,
      variables: { id }
    })

    apolloClient.writeQuery({
      query: AppSpaceDocument,
      variables: { id },
      data: {
        ...existing,
        appSpace: { ...(existing || {}).activeAppSpace, content }
      }
    })

    await updateFileContent({
      variables: {
        id,
        content
      }
    })
  }, [])

  const handler = useMemo(() => new ContentHandler(updateHandler), [])

  const input = useRef<HTMLInputElement>(null)

  if (!dataNew) {
    return (
      <div className={styles.root}>
        <div className={cn(styles.appSpacePreloader)}>
          <div className={styles.center}>
            <div className={cn(spinner.spinner, spinner.small)} />
            <span>Please wait...</span>
          </div>
        </div>
      </div>
    )
  }

  const appSpaceNew = dataNew.activeAppSpace
  const appSpacesNew = dataNew.appSpaces
  const file = appSpaceNew?.file

  if (!appSpaceNew || !appSpacesNew) {
    return null
  }

  const editHandler = (content: string, id: string) => {
    handler.set(id, content)
  }

  const updateAppSpaceName = () => {
    if (!appSpaceNew) {
      return
    }
    if (appSpaceName && appSpaceName !== appSpaceNew.name) {
      renameAppSpace({
        variables: { newName: appSpaceName, id: appSpaceNew.id },
        optimisticResponse: {
          renameAppSpace: {
            id: appSpaceNew.id,
            name: appSpaceName
          }
        }
      })
    }
  }

  const closeCurrentAppSpace = async () => {
    if (dataNew.activeAppSpace) {
      closeAppSpace({
        variables: {
          appSpaceId: dataNew.activeAppSpace?.id
        }
      })
    }
  }

  const changeAppSpace = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newId = event.target.value
    selectAppSpace({ variables: { id: newId } })
  }

  const handleInputKeyDown = (e: { keyCode: number }) => {
    if (e.keyCode === KeyCodes.Enter) {
      updateAppSpaceName()
      setIsRenaming(false)
    }
  }

  const handleInputBlur = () => {
    updateAppSpaceName()
    setIsRenaming(false)
  }

  const onInit = () => setLoaded(true)

  const processDocContent = () => {
    if (appSpaceNew.file) {
      const get = handler.get(appSpaceNew.file.id)
      return get !== undefined ? get : appSpaceNew.file.content || ""
    }
    return ""
  }

  return (
    <>
      <div
        className={cn([
          styles.root,
          dragging ? styles.dragging : "",
          collapsed ? "" : styles.show,
          appSpaceNew.file?.type === "sheet" ? styles.sheetFix : ""
        ])}
      >
        {isRenaming && (
          <input
            onKeyUp={handleInputKeyDown}
            ref={input}
            className={cn(styles.nameInput, "hide-m")}
            maxLength={50}
            onBlur={handleInputBlur}
            value={appSpaceName}
            onChange={(e) => setAppSpaceName(e.target.value)}
          />
        )}
        <label className={cn(styles.appSpaceSelectorLabel, "hide-d")} htmlFor="appSpaceSelector">
          ACTIVE APP SPACE
        </label>

        <select
          id="appSpaceSelector"
          className={cn(styles.appSpaceSelector, "hide-d")}
          value={appSpaceNew.id}
          onChange={changeAppSpace}
        >
          {appSpacesNew?.map((space) => (
            <option key={space?.id} value={space?.id}>
              {space.appSpace.name}
            </option>
          ))}
        </select>

        <div className={styles.mobileControlButtons}>
          {collapsed && (
            <div className={styles.mobileControlButton} onClick={() => setCollapsed(false)}>
              <i className="fa fa-chevron-up fa-fw" />
            </div>
          )}
          {!collapsed && (
            <div className={styles.mobileControlButton} onClick={() => setCollapsed(true)}>
              <i className="fa fa-minus fa-fw" />
            </div>
          )}
          <div className={styles.mobileControlButton} onClick={closeCurrentAppSpace}>
            <i className="fa fa-times fa-fw" />
          </div>
        </div>

        <ErrorBoundary FallbackComponent={AppSpaceErrorFallback}>
          <div className={cn(styles.appSpaceContent, loaded ? styles.loaded : "")}>
            {/* This is to prevent the split pane acting crazy when the cursor reachs the editor.
                This happens because when cursor is on top of the editor, it tries to take control and it clashes with the split-pane. */}
            {resizingSplitPane() && <div className={styles.overflow} />}
            {file?.type === "doc" && (
              <TextDocument
                key={file.id}
                content={processDocContent()}
                edited={editHandler}
                id={file?.id}
                oninit={onInit}
                kind={file.kind || "TEXT"}
                readOnly={file?.readOnly || false}
              />
            )}
            {file?.type.startsWith("doc.") && (
              <TextDocument
                key={file.id}
                content={handler.get(file.id) || file.content || ""}
                edited={editHandler}
                id={file.id}
                oninit={onInit}
                kind={"TEXTX"}
                readOnly={true}
                convertType={file.type === "doc.msword" ? "DOCX" : "DOC"}
              />
            )}
            {file?.type === "DB" && typeof JSON.parse(file?.content || "null") === typeof {} && (
              <Suspense fallback={<SuspensePreloader />}>
                <MultiTableView
                  container={JSON.parse(file?.content || "null") as TableContainer}
                  message={{ textContent: file.content }}
                  appSpaceMode={true}
                  oninit={onInit}
                />
              </Suspense>
            )}
            {file?.type === "sheet" && (
              <Suspense fallback={<SuspensePreloader />}>
                <div style={{ margin: "-2px 0 0 -2px" }}>
                  <SheetDocument
                    content={handler.get(file.id) || file.content || ""}
                    edited={editHandler}
                    id={file.id}
                    oninit={onInit}
                  />
                </div>
              </Suspense>
            )}
            {dataNew?.activeAppSpace?.name === "Ear Anatomy" && (
              <ImageViewer
                key={Date.now()}
                id={dataNew?.activeAppSpace?.file?.id || ""}
                edited={editHandler}
                content={"https://i.ibb.co/RpkwB6P/ear-anatomy.jpg"}
                type={"image"}
                onInit={onInit}
              />
            )}
            {file?.type === "image" && dataNew?.activeAppSpace?.name !== "Ear Anatomy" && (
              <ImageViewer
                key={file.id}
                id={file.id}
                edited={editHandler}
                content={file?.content || ""}
                type={file.type}
                onInit={onInit}
              />
            )}

            {appSpaceNew?.type === "imgPreview" && (
              <ImageViewer
                key={appSpaceNew.id}
                id={appSpaceNew.id}
                edited={editHandler}
                content={appSpaceNew.meta || ""}
                type={appSpaceNew.type}
                onInit={onInit}
              />
            )}

            {file?.type === "pdf" && (
              <Suspense fallback={<SuspensePreloader />}>
                <PdfViewer key={file.content} url={file.content || ""} onInit={onInit} />
              </Suspense>
            )}

            {file?.type === "html" && (
              <Suspense fallback={<SuspensePreloader />}>
                <HTMLViewer content={file?.content || ""} onInit={onInit} />
              </Suspense>
            )}

            {appSpaceNew.type === "taskBoard" && (
              <Suspense fallback={<SuspensePreloader />}>
                <Wiki onInit={onInit} />
              </Suspense>
            )}

            {/* {appSpaceNew.type === "taskBoard" && (
              <Suspense fallback={<SuspensePreloader />}>
                <TaskBoard
                  insideAppSpace
                  taskId={JSON.parse(appSpaceNew.meta || "{}").taskId}
                  boardId={JSON.parse(appSpaceNew.meta || "{}").boardId}
                  onInit={onInit}
                />
              </Suspense>
            )} */}
            {appSpaceNew.type === "chess" && (
              <Suspense fallback={<SuspensePreloader />}>
                <ChessGame onInit={onInit} />
              </Suspense>
            )}
          </div>
        </ErrorBoundary>
        <div className={cn(styles.appSpacePreloader, loaded ? styles.loaded : "")}>
          <div className={styles.center}>
            <div className={cn(spinner.spinner, spinner.small)} />
            <span>Please wait...</span>
          </div>
        </div>
      </div>
      <div className={cn(styles.mobileDarkener, collapsed ? styles.hide : "")} />
    </>
  )
}

export default AppSpaceController
