/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from "react"
import "@grapecity/spread-sheets-designer-resources-en"
import "@grapecity/spread-sheets-designer/styles/gc.spread.sheets.designer.min.css"
import "@grapecity/spread-sheets/styles/gc.spread.sheets.excel2013white.css"
import pubsub from "pubsub-js"
import GC from "@grapecity/spread-sheets"
import { ISheetData } from "../../../helpers/IData"
import GCD from "@grapecity/spread-sheets-designer"
import { topics } from "consts"
import styles from "../../textDocument/TextDocument.module.scss"
import editorHeader from "../../editorHeader/EditorHeader.module.scss"

import Popup from "reactjs-popup"
import EditorHeader from "../../editorHeader"
import cn from "clsx"
import menu from "modules/shared/styles/components/menu.module.scss"
import {
  useFileListing_UploadFileMutation,
  useAppSpaces_FinishUploadExportedAppSpaceMutation,
  useAppSpaces_ExportFileMutation,
  useDesignerCompQuery
} from "generated/graphql"
import { handleGraphQlError, uploadFileToS3 } from "utils/common"
import { Designer } from "@grapecity/spread-sheets-designer-react"

const key =
  "app.pretzel.space,457378169513262#B0w0WTidDUiJUWkVWa8o7bRhlMopGRrsSMhNTUylDT62WRmZWOMJHU03EaONTdmRGMOdFN4gFSUlnd9ZzRtFTMwM4LO3yV8Z7MEVXdL9mc0JXRnxEWlRlclFVc6MXb5ImQM94VLJkS93SSjplekxmRM3UVK5EZk96UJZ4K7g5KhhGTqNjZZJlbulDbIZjYppXWatGewZDN9lXcQZXcyR6QGZVVaBXek56MxJkS62WSmhzVml4TVJGS6tWb9pWYyc6VzQGc4FTeiF5STJ4c7ZUQ9QWbjl6Szcma6ZlR9UzS7FVTypmWrAldhdmI0IyUiwiI4EkNDJEMwMjI0ICSiwCMyIjNyQjMxYTM0IicfJye35XX3JSW6U4NiojIDJCLiQTMuYHIu3GZkFULyVmbnl6clRULTpEZhVmcwNlI0IiTis7W0ICZyBlIsIiM5QDMyADI4IDOwEjMwIjI0ICdyNkIsISZjFGcz9CblpHdlJHcuAHchJiOiMXbEJCLikHanFGbsFGUgwWdhBlI0ISYONkIsIiM6IzMxUTO6EDO7MzN5QjI0ICZJJye0ICRiwiI34TUEhkdXJUUnNnW6RWY6dEa9pUOwE4KLBFe7FFarYTevUTcyYWWBlXWy2GUvJTRQNlMXpVMqRlcoNUU9siThRkUaZWUvNETJBXNMN6YHZFTBd4UKlXdrkHUTZjZ8EFeT3SaxkXStN4QjhEUuxyS"
GC.Spread.Sheets.LicenseKey = key
GCD.Spread.Sheets.Designer.LicenseKey = key

const Events = GC.Spread.Sheets.Events

const DesignerComp: React.FC<{
  id: string
  initContent: string
  handleEdit: (content: string) => void
  oninit: () => void
}> = ({ id, initContent, handleEdit, oninit }) => {
  const [exporting, setExporting] = useState(false)
  const { data: cd } = useDesignerCompQuery({
    onError: handleGraphQlError("Unable to get text data")
  })
  const [uploadAppSpace] = useFileListing_UploadFileMutation({
    onError: handleGraphQlError("Unable to upload file")
  })
  const [exportFile] = useAppSpaces_ExportFileMutation({
    onError: handleGraphQlError("Unable to export app space")
  })
  const [finishUploadExportedAppSpace] = useAppSpaces_FinishUploadExportedAppSpaceMutation({
    onError: handleGraphQlError("Unable to export app space")
  })

  const currentWorkbook = useRef<any>(null)
  const designerInitializedCallback = (designer: any) => {
    const workbook = designer.getWorkbook()
    workbook.fromJSON(JSON.parse(initContent))

    const events = [
      Events.ActiveSheetChanged,
      Events.CellChanged,
      Events.ColumnChanged,
      Events.ColumnWidthChanged,
      Events.CommentChanged,
      Events.CommentRemoved,
      Events.DragDropBlockCompleted,
      Events.DragFillBlockCompleted,
      Events.DragMerged,
      Events.EditEnded,
      Events.FloatingObjectChanged,
      Events.FloatingObjectLoaded,
      Events.FloatingObjectRemoved,
      Events.LeftColumnChanged,
      Events.RowChanged,
      Events.ShapeChanged,
      Events.ShapeRemoved,
      Events.SheetChanged,
      Events.SheetMoved,
      Events.SheetNameChanged,
      Events.TableColumnsChanged,
      Events.TableRowsChanged,
      Events.TopRowChanged,
      Events.UserFormulaEntered,
      Events.ValueChanged,
      Events.ClipboardPasted
    ]

    events.map((e) => workbook.bind(e, () => handleEdit(JSON.stringify(workbook.toJSON()))))

    currentWorkbook.current = workbook

    oninit()
  }

  const populateSheet = (sheet: any, data: ISheetData) => {
    const colIndex = sheet.getActiveColumnIndex() || 0
    const rowIndex = sheet.getActiveRowIndex() || 0

    if (data.title) sheet.name(data.title)

    const content = data.content
    if (!content.data && content.length) {
      sheet.setArray(rowIndex, colIndex, content)
    } else if (content.headers) {
      sheet.setArray(rowIndex, colIndex, [content.headers])
      sheet.getRange(rowIndex, colIndex, 1, content.headers.length).backColor("#9cc3e5")
      sheet.setArray(rowIndex + 1, colIndex, content.data)
    } else if (content.data) {
      sheet.setArray(rowIndex, colIndex, content.data)
    }
  }

  const exportPDF = () => {
    currentWorkbook.current.savePDF((blob: Blob) => {
      if (blob) {
        setExporting(true)
        uploadDocument(blob, "application/pdf")
      }
    })
  }

  const exportAsDB = async () => {
    setExporting(true)

    await exportFile({
      variables: {
        id: id,
        content: JSON.stringify(currentWorkbook.current.toJSON()),
        targetType: "DB"
      }
    })

    pubsub.publish(topics.UPDATE_FILES)
    setExporting(false)
  }

  const uploadDocument = async (blob: Blob, type: string) => {
    const file = cd?.activeAppSpace?.file
    const addition = type === "application/pdf" ? ".pdf" : ".doc"

    const r = await uploadAppSpace({
      variables: {
        fileName: (file?.name || "Exported File") + addition,
        fileType: type,
        folder: null,
        fileSize: blob.size
      }
    })

    const data = r.data?.uploadFile
    if (!data) {
      return
    }

    await uploadFileToS3(data.postData, blob)

    // // finish upload exported appspace
    await finishUploadExportedAppSpace({
      variables: { uploadId: data.uploadId, masterId: file?.id || "" }
    })

    pubsub.publish(topics.UPDATE_FILES)
    setExporting(false)
  }

  useEffect(() => {
    const token = pubsub.subscribe(
      topics.INSERT_CONTENT_TO_DOCUMENT,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (_: string, data: ISheetData) => {
        if (data.type !== "table") {
          return
        }
        const wb = currentWorkbook.current
        let sheet = wb.getActiveSheet()

        const selection = sheet.getCell(sheet.getActiveColumnIndex() || 0, sheet.getActiveRowIndex() || 0)

        if (selection.text() !== "") {
          wb.addSheet(wb.sheets.length)
          wb.setActiveSheetIndex(wb.sheets.length - 1)
          sheet = wb.sheets[wb.sheets.length - 1]
        }

        if (!sheet) {
          return
        }

        populateSheet(sheet, data)
      }
    )

    currentWorkbook?.current?.focus(false)

    return () => {
      pubsub.unsubscribe(token)
    }
  }, [])

  const handleCommands = (message: string) => {
    pubsub.publish(topics.SIDEBAR_ACTION, { message, silent: true })
  }

  return (
    <div className={styles.root}>
      <div style={{ pointerEvents: exporting ? "none" : "initial", opacity: exporting ? "0.5" : "1" }}>
        <EditorHeader
          key={id}
          centerMenu={
            <>
              <Popup
                arrow={false}
                trigger={
                  <div className={cn([editorHeader.item, editorHeader.sheet])}>
                    <i className="material-icons">import_export</i>
                    <span>Export as</span>

                    <i className="material-icons">expand_more</i>
                  </div>
                }
                contentStyle={{ padding: 0 }}
                position="bottom left"
              >
                {
                  ((close: () => void) => (
                    <div className={cn(menu.menu, menu.leftTop)}>
                      <div
                        className={cn([menu.item, menu.sheet])}
                        onClick={() => {
                          exportPDF()
                          close()
                        }}
                      >
                        <i className="material-icons">picture_as_pdf</i>
                        Export as PDF
                      </div>
                      <div
                        className={cn([menu.item, menu.sheet])}
                        onClick={() => {
                          exportAsDB()
                          close()
                        }}
                      >
                        <i className="material-icons">table_chart</i>
                        Export as DB
                      </div>
                    </div>
                  )) as any
                }
              </Popup>
              <Popup
                arrow={false}
                trigger={
                  <div
                    className={cn([editorHeader.item, editorHeader.sheet])}
                    style={{ opacity: exporting ? 0.5 : 1, pointerEvents: exporting ? "none" : undefined }}
                  >
                    <i className="material-icons">memory</i>
                    <span>Smart</span>

                    <i className="material-icons">expand_more</i>
                  </div>
                }
                contentStyle={{ padding: 0 }}
                position="bottom left"
              >
                {
                  ((close: () => void) => (
                    <div className={cn(menu.menu, menu.leftTop)}>
                      <div
                        className={cn([menu.item, menu.sheet])}
                        onClick={() => {
                          handleCommands("plot")
                          close()
                        }}
                      >
                        <i className="material-icons">insights</i>
                        Smart Plot
                      </div>
                    </div>
                  )) as any
                }
              </Popup>
            </>
          }
        />
      </div>
      <div className={styles.editorContainer}>
        <Designer
          designerInitialized={designerInitializedCallback}
          styleInfo={{ width: "100%", height: "calc(100vh - 50px)" }}
        />
      </div>
    </div>
  )
}

export default DesignerComp
