/* eslint-disable */
import {
  FileListing_FileListingFragment,
  Message_MessageFragment,
  useFileListingLazyQuery,
  useFileListing_MoveFolderMutation,
  useFileListing_MoveFileToFolderMutation,
  useFileListing_RenameFolderMutation,
  useFileListing_DeleteFolderMutation,
  useFileListing_NewFolderMutation,
  useFileListing_UploadFileMutation,
  AppSpaceDocument,
  useFileListing_FinishUploadFileMutation,
  FileListing_DownloadFileDocument,
  useFileListing_SessionsLazyQuery,
  useSessions_CreateSessionMutation,
  useFileListing_OpenFileMutation,
  useFileListing_RenameFileMutation,
  useFileListing_DeleteFileMutation,
  useSearchFileListingQuery
} from "generated/graphql"
import React, { createContext, useContext, useEffect, useRef, useState } from "react"
import styles from "./FileListing.module.scss"
import button from "modules/shared/styles/components/button.module.scss"
import messagePanel from "modules/shared/styles/components/messagePanel.module.scss"
import spinner from "modules/shared/styles/components/spinner.module.scss"
import cn from "clsx"

import FolderTreeView, {
  clearUsedFolders,
  cycleFolder,
  cycleInUse2,
  cycleRoot,
  folderCycle,
  resetCycle,
  resetCycleInUse2,
  rootCycle,
  setFolderCycle
} from "../folderTreeView/FolderTreeView"
import FileFolderTableView from "../fileFolderTableView"
import FilePreviewView from "../filePreviewView"
import SessionsListing from "../sessionsListing"

import { useApolloClient, useLazyQuery } from "@apollo/client"
import { setFilterByBranch, filterByBranch } from "../folderTreeView/FolderTreeView"
import QueryPreloader from "modules/shared/components/queryPreloader"
import SplitterContext from "modules/shared/components/splitterContext"
import { showInfoNotification } from "utils/notification"

import pubsub from "pubsub-js"
import ChatContext from "modules/chat/components/chat/ChatContext"
import { handleGraphQlError, uploadFileToS3 } from "utils/common"
import { topics } from "consts"
import { useNonInitialEffect } from "utils/hooks"
import _ from "lodash"
import sampleFiles from "./sampleFiles.json"
import sampleFolders from "./sampleFolder.json"
import { clearSearch } from "../searchControl/SearchControl"

// Paul sample file listing
const samples: any = {
  files: sampleFiles,
  folders: sampleFolders
}

export let unfilteredFolders: any
let monthIndex = 0

export const FileListingContext = createContext<{
  reloadData: () => void
  reloadSessionData: () => void
  expandFolder: (id: string, mustExpand?: boolean) => boolean
  renameFolder: (id: string, newName: string) => void
  moveThingToFolder: (target: string, to: string, type: string) => Promise<void>
  newFolder: (parent: string, name: string) => void
  deleteItem: (id: string, type: string) => unknown
  openAppSpace: (appSpace: { type: string; id: string; name: string }) => unknown
  downloadAppSpace: (appSpace: { type: string; id: string; name: string }) => unknown
  renameFile: (id: string, newName: string) => void
  startUploading: (id: string) => void
  loading: boolean
  orderMode: string
  limitMode: string
  query: string | null | undefined
}>({} as any)

export let setListingTip = () => {
  return null
}

function selectedPreviewTypeClassName(mode: string, selectedMode: string, styles: any): string {
  return mode === selectedMode ? cn(styles.previewType, styles.active) : styles.previewType
}

const latestFileListing = (files: any, query: string) => {
  if (query || modeType === "recent") return files.files
  let recent7Days = files.files.files
  recent7Days = recent7Days.filter((file: any) => {
    let date = new Date(file.updatedDate)
    let today = safeDate()
    let diff = today.getTime() - date.getTime()
    let diffDays = Math.ceil(diff / (1000 * 3600 * 24))
    return diffDays <= 7 && file.type !== "taskBoard"
  })
  return { ...files.files, files: recent7Days }
}

const renderFileModeSelect = (
  title: string,
  mode: string,
  selected: string,
  icon: string,
  mobileChatPane: boolean,
  rounded?: any
) => {
  return (
    <span
      onClick={() => {
        if (mode === "previews") return
        // Use custom a-z file listing for "All" mode
        if (mode === "all" || mode === "tree") return
      }}
      style={rounded}
      className={cn(selectedPreviewTypeClassName(mode, selected, styles), styles.recentHeader)}
    >
      {icon && (
        <i style={{ verticalAlign: "middle", marginRight: "0.5rem" }} className="material-icons">
          {icon}
        </i>
      )}
      {title}
    </span>
  )
}

export const safeDate = () => {
  // Prevents file listing from crashing when the date is 31st
  let d = new Date()
  if (d.getDate() === 31) d.setDate(30)
  return d
}

const date = safeDate()
date.setMonth(date.getMonth())
export const currentMonth = date.toLocaleString("default", { month: "short", year: "numeric" })
export let useMonth = date.toLocaleString("default", { month: "short", year: "numeric" })
export const setUseMonth = (branch: string) => (useMonth = branch)
export let modeType: string
export let isLatest: boolean
export function resetCycleInUse1() {
  cycleInUse1 = false
}
export let cycleInUse1 = false
const FL: React.FC<{
  fileListing: FileListing_FileListingFragment
  message: Message_MessageFragment
  isLast: boolean
  query: string
  setQuery: (s: string) => void
  listingMode: string
  setListingMode: (s: string) => void
}> = ({ fileListing, message, isLast, query, setQuery, listingMode }) => {
  const [displayType, setDisplayType] = useState("tree")
  const [mode, setMode] = useState("recent")
  const [files, setFiles] = useState(fileListing.files)
  const [folders, setFolders] = useState(fileListing.folders)
  const [page, setPage] = useState(fileListing.pageNumber)
  const [expandedFolders, setExpandedFolders] = useState(fileListing.folders.expandedFolders)
  const pageSize = fileListing.pageSize
  const [pageCount, setPageCount] = useState(Math.ceil(fileListing.count / pageSize))
  const [orderMode, setOrderMode] = useState("recent")
  const [newFilterByBranch, setNewFilterByBranch] = useState(false)
  const [sortBy, setSortBy] = useState("date")
  const [latest, setLatest] = useState(true)
  const [listingTip, setListingTip] = useState("")

  const { toggleMaximizeChat, maximizeChat } = useContext(SplitterContext)

  useEffect(() => {
    modeType = mode
    isLatest = latest
    clearUsedFolders()
    localStorage.fileCache = JSON.stringify(fileListing.files.files)
    if (latest && filterByBranch !== "root") setFiles(latestFileListing(fileListing, query))
    else setFiles(fileListing.files)
  }, [mode, latest])

  useEffect(() => {
    // Reset all filters, don't save them
    if (latest) {
      monthIndex = 0
      setMode("recent")
      getMonth("reset")
      setSortBy("date")
      setLatest(true)
      setOrderMode("recent")
      setFolderCycle(1)
      setFilterByBranch("root")
      clearSearch()
    }
  }, [])

  const { completeLoadingCallback, setFileListingExists } = useContext(ChatContext)

  const [getFiles, { data: filesData, loading: filesLoading }] = useFileListingLazyQuery({
    fetchPolicy: "no-cache",
    onError: handleGraphQlError("Unable to get files")
  })

  const [getSessions, { data: sessionsData, loading: sessionsLoading }] = useFileListing_SessionsLazyQuery({
    fetchPolicy: "no-cache",
    onError: handleGraphQlError("Unable to get sessions")
  })

  const gotoPage = (page: number) => () => {
    setPage(page)
  }

  const getMonth = (positive?: any) => {
    const date = safeDate()
    if (positive !== false && positive !== true) {
      date.setMonth(date.getMonth())
      useMonth = date.toLocaleString("default", { month: "short", year: "numeric" })
      return date.toLocaleString("default", { month: "short", year: "numeric" })
    }
    if (positive === "reset") {
      monthIndex = 0
      setLatest(true)
      return currentMonth
    }
    if (positive === 0) monthIndex = 0
    if (positive) monthIndex++
    if (!positive) monthIndex--

    date.setMonth(date.getMonth() - monthIndex)
    useMonth = date.toLocaleString("default", { month: "short", year: "numeric" })
    return date.toLocaleString("default", { month: "short", year: "numeric" })
  }

  const reloadData = () => {
    getFiles({
      variables: {
        messageId: message.id,
        pageNumber: page,
        pageSize: pageSize,
        type: displayType,
        expandedFolders: expandedFolders,
        orderMode: orderMode,
        listingMode: listingMode || "files",
        limitMode: "all",
        query: query || ""
      }
    })
  }
  useEffect(() => {
    const token = pubsub.subscribe(topics.FILE_LISTING_RELOAD, () => {
      reloadData()
    })
    return () => {
      pubsub.unsubscribe(token)
    }
  }, [])

  useEffect(() => {
    if (!message.obsolete) {
      const token = pubsub.subscribe(topics.UPDATE_FILES, () => {
        reloadData()
      })

      const token2 = pubsub.subscribe(topics.UPDATE_FILES_QUERY, (_: any, q: string) => {
        setQuery(q)
      })

      setFileListingExists(true)

      return () => {
        pubsub.unsubscribe(token)
        pubsub.unsubscribe(token2)
      }
    }
  }, [])

  useEffect(() => {
    // finished rendering when here
    setTimeout(() => {
      if (isLast) {
        completeLoadingCallback(message.id)
      }
    }, 100)
  }, [files, folders])

  useNonInitialEffect(
    () => {
      if (message.obsolete) {
        return
      }

      reloadData()
    },
    [displayType, page, expandedFolders, orderMode, listingMode, "all"],
    displayType === "folders"
  )

  if (window.location.href.includes("testing")) {
    unfilteredFolders = samples.folders
  } else unfilteredFolders = folders.folders

  const processQuery = (files: any) => {
    if (query?.trim() !== "") {
      if (files.appSpaces) {
        const appSpaces = [
          ...files.appSpaces.filter((x: any) => x.name.toLowerCase().includes(query?.toLowerCase() || ""))
        ]
        return {
          count: appSpaces.length,
          appSpaces
        }
      }
    }

    return files
  }

  const debouncedReloadData = _.debounce(reloadData, 200, { trailing: true })

  useEffect(() => {
    debouncedReloadData()
  }, [query])

  useEffect(() => {
    if (message.obsolete) {
      return
    }

    const listing = filesData?.message?.fileListing
    if (!listing) {
      return
    }
    let validListings = processQuery(listing.files)
    validListings = {
      ...validListings,
      files: validListings.files.filter((x: any) => x.type !== "taskBoard")
    }
    localStorage.fileCache = JSON.stringify(filesData?.message?.fileListing?.files.files)

    if (latest && filterByBranch !== "root") setFiles(latestFileListing(listing, query))
    else {
      if (displayType !== "previews") setFiles(validListings)
    }
    setFolders(listing.folders)
    setPageCount(Math.ceil(listing.count / pageSize))
    const equal =
      expandedFolders.every((ef) => listing.folders.expandedFolders.includes(ef)) &&
      listing.folders.expandedFolders.every((f) => expandedFolders.includes(f))
    if (!equal) {
      setExpandedFolders(listing.folders.expandedFolders)
    }
  }, [filesData?.message?.fileListing, latest, mode])

  useEffect(() => {
    if (listingMode === "sessions") {
      getSessions()
    }
  }, [listingMode])

  const handleExpandFolder = (id: string, mustExpand = false) => {
    const alreadyExpanded = expandedFolders.indexOf(id) > -1
    if (alreadyExpanded) {
      if (mustExpand) {
        return false
      } else {
        setExpandedFolders(expandedFolders.filter((ef) => ef !== id))
      }
    } else {
      setExpandedFolders([...expandedFolders, id])
    }

    return true
  }

  const [moveFolder] = useFileListing_MoveFolderMutation({
    onError: handleGraphQlError("Unable to move folder")
  })
  const [moveFile] = useFileListing_MoveFileToFolderMutation({
    onError: handleGraphQlError("Unable to move file")
  })
  const [renameFolder] = useFileListing_RenameFolderMutation({
    onError: handleGraphQlError("Unable to rename folder")
  })
  const [deleteFile] = useFileListing_DeleteFileMutation({
    onError: handleGraphQlError("Unable to delete file")
  })
  const [deleteFolder] = useFileListing_DeleteFolderMutation({
    onError: handleGraphQlError("Unable to delete folder")
  })
  const [newFolder] = useFileListing_NewFolderMutation({
    onError: handleGraphQlError("Unable to create new folder")
  })
  const [renameFile] = useFileListing_RenameFileMutation({
    onError: handleGraphQlError("Unable to rename file")
  })
  const [getDownloadUrl, { data: currentDownloadUrl }] = useLazyQuery(FileListing_DownloadFileDocument)

  useEffect(() => {
    if (!currentDownloadUrl) {
      return
    }
    if (!currentDownloadUrl.downloadFile) {
      return
    }

    setTimeout(() => {
      window.open(currentDownloadUrl.downloadFile.url, "_blank")
    }, 200)
  }, [currentDownloadUrl])

  setTimeout(() => {
    setNewFilterByBranch(!newFilterByBranch)
    if (monthIndex === 0) setListingTip("This month")
    if (monthIndex > 0) setListingTip(useMonth)
    if (latest) setListingTip("This week")
    if ((rootCycle === 1 && modeType === "all") || (modeType === "recent" && filterByBranch !== "root"))
      setListingTip("Listing top-level. Click Browse to see deeper.")
    if (rootCycle === 2 || folderCycle === 2)
      setListingTip("Listing next level too. Click Browse again to see everything.")
    if (rootCycle === 3 || folderCycle === 3) setListingTip("Click Browse again to collapse listing.")
  }, 100)
  const apolloClient = useApolloClient()

  const inputFile = useRef<HTMLInputElement>(null)

  async function moveThingToFolder(target: string, to: string, type: string): Promise<void> {
    if (type === "folder") {
      await moveFolder({ variables: { folder: target, toFolder: to } })
    } else if (type === "file") {
      await moveFile({ variables: { file: target, toFolder: to } })
    }

    if (!handleExpandFolder(to, true)) {
      reloadData()
    }
  }

  async function handleRenameFolder(id: string, name: string) {
    await renameFolder({ variables: { id, newName: name } })
    reloadData()
  }

  async function handleRenameAppSpace(id: string, name: string) {
    await renameFile({
      variables: { fileId: id, newName: name }
    })

    const existing = apolloClient.readQuery({
      query: AppSpaceDocument,
      variables: { id }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }) as any

    apolloClient.writeQuery({
      query: AppSpaceDocument,
      variables: { id },
      data: {
        ...existing,
        appSpace: { ...(existing || {}).appSpace, name }
      }
    })

    reloadData()
  }

  async function handleDelete(id: string, type: string) {
    if (type === "appSpace") {
      await deleteFile({ variables: { fileId: id } })
    } else if (type === "folder") {
      await deleteFolder({ variables: { id } })
    }
    pubsub.publish(topics.DELETE_FILE, id)

    reloadData()
  }

  async function handleNewFolder(parentId: string, name: string) {
    await newFolder({ variables: { parentId, name: name || "New Folder" } })
    if (!handleExpandFolder(parentId, true)) {
      reloadData()
    }
  }

  const selectedUploadFolder = useRef<string>("")
  async function handleStartUploading(folderId?: string) {
    selectedUploadFolder.current = folderId || ""
    inputFile.current?.click()
  }
  const { data: fileListingQuery } = useSearchFileListingQuery({
    onError: handleGraphQlError("Unabel to get file listing")
  })
  const [uploadFile] = useFileListing_UploadFileMutation({
    onError: handleGraphQlError("Unable to upload file")
  })
  const [finishUpload] = useFileListing_FinishUploadFileMutation({
    onError: handleGraphQlError("Unable to upload file")
  })

  const [openFile] = useFileListing_OpenFileMutation({
    onError: handleGraphQlError("Unable to open file")
  })
  const [uploadInProgress, setUploadInProgress] = useState(false)

  const { mobileChatPane } = useContext(SplitterContext)

  const determineType = (filename: string): string => {
    const ext = _.last(filename.split("."))

    switch (ext) {
      case "csv":
        return "text/csv"
      case "xlsx":
        return "application/vnd.ms-excel"
      case "docx":
        return "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    }

    return ""
  }

  const changeHandler = async (event: any) => {
    try {
      const files = event.target.files as File[]
      if (!files || !files.length) {
        return
      }
      setUploadInProgress(true)
      for (const file of files) {
        const r = await uploadFile({
          variables: {
            fileName: file.name,
            fileType: file.type ? file.type : determineType(file.name),
            fileSize: file.size,
            folder: selectedUploadFolder.current || null
          }
        })
        const data = r.data?.uploadFile
        if (!data) {
          return
        }
        await uploadFileToS3(data.postData, file)
        const finished = await finishUpload({
          variables: {
            uploadId: data.uploadId
          }
        })
        if (finished?.data?.finishFileUpload) {
          reloadData()
          openFile({
            variables: {
              fileId: data.uploadId
            }
          })
        }
      }
    } finally {
      setUploadInProgress(false)
    }
  }

  const appSpaceOpenHandle = async (appSpace: { type: string; id: string; name: string }) => {
    openFile({
      variables: {
        fileId: appSpace.id
      }
    })
  }

  const appSpaceDownloadHandle = async (appSpace: { type: string; id: string; name: string }) => {
    showInfoNotification("Downloading file...", appSpace.name)
    getDownloadUrl({ variables: { id: appSpace.id } })
  }

  const [createSession] = useSessions_CreateSessionMutation({
    onError: handleGraphQlError("Unable to create session")
  })
  if (!fileListingQuery) {
    return null
  }
  const activeAppSpace = fileListingQuery.activeAppSpace
  const folderView = () => {
    if (filterByBranch !== "root") {
      return ["#5A7699", "white"]
    } else {
      return [mode === "all" ? "#5A7699" : "#D3DDEB", mode === "all" ? "white" : "#324B81"]
    }
  }
  const getColor1 = () => {
    if (modeType === "all" || query) return "#BFC9DB"
    return filterByBranch !== "root" ? "#BFC9DB" : "#324B81"
  }
  const getColor2 = () => {
    if (modeType === "all" || query) return "#BFC9DB"
    return latest ? "#BFC9DB" : "#324B81"
  }

  function isRecentMonth() {
    const today = safeDate()
    const day = today.getDate()
    return day <= 7
  }
  return (
    <>
      <div
        style={{ marginTop: "-5px" }}
        className={cn(styles.filesView, messagePanel.root, isLast ? styles.isLast : null)}
      >
        <FileListingContext.Provider
          value={{
            reloadData,
            limitMode: "all",
            reloadSessionData: getSessions,
            expandFolder: handleExpandFolder,
            renameFolder: handleRenameFolder,
            moveThingToFolder,
            newFolder: handleNewFolder,
            deleteItem: handleDelete,
            openAppSpace: appSpaceOpenHandle,
            downloadAppSpace: appSpaceDownloadHandle,
            renameFile: handleRenameAppSpace,
            startUploading: handleStartUploading,
            loading: filesLoading,
            orderMode,
            query
          }}
        >
          <input
            type="file"
            multiple
            ref={inputFile}
            onChange={changeHandler}
            style={{ display: "none" }}
            accept="*.*"
          />

          <div style={{ zoom: 0.8, paddingBottom: listingMode === "files" ? 40 : 20 }}>
            {listingMode === "files" && (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <>
                  {displayType === "tree" && (
                    <>
                      <div className={styles.filePreviewType} style={{ marginRight: 10 }}>
                        <div
                          className={cn(styles.fileMonthNavLeft, styles.recentHeader)}
                          style={{ padding: "0 1.5rem", color: getColor1() }}
                          onClick={() => {
                            clearUsedFolders()

                            setFolderCycle(1)
                            if (isRecentMonth()) {
                              setLatest(false)
                              getMonth(true)
                            } else if (useMonth === currentMonth && latest) {
                              setLatest(false)
                            } else {
                              getMonth(true)
                            }
                          }}
                        >
                          <i className="material-icons">chevron_left</i>
                        </div>
                        <div
                          onClick={() => {
                            monthIndex = 0
                            setMode("recent")
                            getMonth("reset")
                            setSortBy("date")
                            setOrderMode("recent")
                            setLatest(true)
                            setFolderCycle(1)
                            clearUsedFolders()
                          }}
                          style={{
                            color: filterByBranch !== "root" || modeType === "all" || query ? "#BFC9DA" : "white",
                            background:
                              filterByBranch !== "root" || modeType === "all" || query ? "#D3DDEB" : "#5A7699",
                            borderRadius: "0",
                            width: 65,
                            cursor: "pointer",
                            textAlign: "center",
                            padding: "0.5rem 1.5rem"
                          }}
                        >
                          {latest || modeType === "all" ? "Latest" : useMonth}
                        </div>
                        <div
                          style={{
                            padding: "0 1.5rem",
                            color: getColor2(),
                            borderRadius: "0 5px 5px 0"
                          }}
                          className={cn(styles.fileMonthNavRight, styles.recentHeader)}
                          onClick={() => {
                            clearUsedFolders()

                            setFolderCycle(1)
                            if (isRecentMonth() === true) {
                              if (monthIndex === 1) {
                                getMonth("reset")
                                setLatest(true)
                                monthIndex = 0
                                return
                              }
                              if (!latest) getMonth(false)
                              return
                            } else {
                              if (currentMonth === useMonth && !latest) {
                                setLatest(true)
                                return
                              }
                              if (latest) return
                              getMonth(false)
                            }
                          }}
                        >
                          <i className="material-icons">chevron_right</i>
                        </div>
                        <div
                          onClick={() => {
                            monthIndex = 0
                            setMode("recent")
                            getMonth("reset")
                            setSortBy("date")
                            setOrderMode("recent")
                            setLatest(true)
                            setFilterByBranch("root")
                            setFolderCycle(1)
                            clearSearch()
                            clearUsedFolders()
                          }}
                          style={{ marginLeft: "0.5rem" }}
                        >
                          {renderFileModeSelect("Recent", "all", "all", "access_time", false, {
                            borderRadius: "5px 0 0 5px",
                            background: "#D3DDEB",
                            color: !latest || mode === "all" ? "#324B81" : "#BEC8DA"
                          })}
                        </div>
                        <div
                          onClick={() => {
                            if (modeType === "all") {
                              if (cycleInUse2) {
                                cycleInUse1 = false
                                resetCycleInUse2()
                                resetCycle()
                              }
                              cycleInUse1 = true
                              cycleRoot()
                              return
                            }
                            if (filterByBranch !== "root") {
                              if (cycleInUse2) {
                                cycleInUse1 = false
                                resetCycleInUse2()
                                resetCycle()
                              }
                              cycleInUse1 = true
                              cycleFolder()
                              return
                            }

                            setFolderCycle(1)
                            setFilterByBranch("root")
                            setMode("all")
                            setLatest(false)
                            clearSearch()
                            clearUsedFolders()
                          }}
                        >
                          {renderFileModeSelect("Browse", "all", "all", "account_tree", false, {
                            background: folderView()[0],
                            color: folderView()[1],
                            borderRadius: "0",
                            marginLeft: "-0.1rem"
                          })}
                        </div>
                        <div
                          onClick={() => {
                            setFilterByBranch("root")
                            clearUsedFolders()
                            setFolderCycle(1)
                            setDisplayType("previews")
                            setSortBy("date")
                            setOrderMode("recent")
                            setMode("recent")
                            getMonth(1)
                            clearSearch()
                          }}
                        >
                          {renderFileModeSelect("Previews", "previews", displayType, "view_stream", false, {
                            borderRadius: "0 5px 5px 0",
                            background: "#D3DDEB",
                            color: "#324B81"
                          })}
                        </div>
                        <div
                          className={cn(styles.fileBackButton, styles.backButton)}
                          style={{
                            background: "#FFE5BC",
                            margin: "0 0 0 0.5rem",
                            display: filterByBranch !== "root" ? "block" : "none"
                          }}
                          onClick={() => {
                            setFilterByBranch("root")
                            clearUsedFolders()
                            setFolderCycle(1)
                          }}
                        >
                          <i
                            style={{
                              padding: "0 1.5rem",
                              marginTop: "0.3rem",
                              boxSizing: "border-box",
                              fontSize: "1.4em",
                              color: "#EDA033"
                            }}
                            className="material-icons"
                          >
                            arrow_back
                          </i>
                        </div>
                        <div
                          style={{
                            background: sortBy === "date" ? "#5A7699" : "#D3DDEB",
                            color: sortBy === "date" ? "#fff" : "#324B81",
                            marginLeft: "0.5rem"
                          }}
                          onClick={() => {
                            setSortBy("date")
                            setOrderMode("recent")
                          }}
                          className={cn(styles.maxButton, styles.recentHeader)}
                        >
                          <span>
                            <i className="material-icons">date_range</i>
                            Date
                          </span>
                        </div>
                        <div
                          style={{
                            marginLeft: "-0.3em",
                            borderRadius: "0 5px 5px 0",
                            background: sortBy === "alpha" ? "#5A7699" : "#D3DDEB",
                            color: sortBy === "alpha" ? "#fff" : "#324B81"
                          }}
                          onClick={() => {
                            setSortBy("alpha")
                            setOrderMode("all")
                          }}
                          className={cn(styles.maxButton, styles.recentHeader)}
                        >
                          <span>
                            <i className="material-icons">sort_by_alpha</i>
                            A-Z
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                  <div className={styles.filePreviewType}>
                    <span>
                      {displayType === "previews" && (
                        <>
                          <span
                            onClick={() => {
                              setDisplayType("tree")
                              setTimeout(() => {
                                monthIndex = 0
                                setMode("recent")
                                getMonth("reset")
                                setSortBy("date")
                                setOrderMode("recent")
                                setLatest(true)
                              }, 1500)
                            }}
                          >
                            {renderFileModeSelect("Tree", "tree", "all", "account_tree", true, {
                              background: "#D3DDEB",
                              color: "#324B81",
                              borderRadius: "5px 0 0 5px",
                              marginRight: "0"
                            })}
                          </span>
                          <span
                            onClick={() => {
                              setDisplayType("previews")
                              setSortBy("date")
                              setOrderMode("recent")
                              setMode("recent")
                              getMonth(1)
                            }}
                          >
                            {renderFileModeSelect("Previews", "previews", displayType, "view_stream", false, {
                              borderRadius: displayType === "previews" ? "0 5px 5px 0" : "0 5px 5px 0",
                              background: displayType === "previews" ? "#5A7699" : "#D3DDEB",
                              color: displayType === "previews" ? "white" : "#324B81",
                              marginLeft: "-0.2rem",
                              marginRight: "1rem"
                            })}
                          </span>
                        </>
                      )}
                    </span>
                  </div>

                  <div
                    style={{
                      position: activeAppSpace ? "relative" : undefined,
                      left: activeAppSpace ? "-100px" : undefined,
                      display: "flex",
                      justifyContent: "space-between"
                    }}
                  >
                    {activeAppSpace && (
                      <div
                        style={{ background: "#D3DDEB" }}
                        className={cn(styles.maxButton, styles.recentHeader)}
                        onClick={toggleMaximizeChat}
                      >
                        {!maximizeChat && (
                          <>
                            <i className="material-icons">fullscreen</i>
                          </>
                        )}
                        {maximizeChat && (
                          <>
                            <i className="material-icons">fullscreen_exit</i>
                          </>
                        )}
                      </div>
                    )}
                    <div
                      style={{ background: "#D3DDEB" }}
                      onClick={() => handleStartUploading()}
                      className={cn(styles.maxButton, styles.recentHeader)}
                    >
                      {!uploadInProgress && (
                        <>
                          <i className="fa fa-upload" style={{ marginTop: "0.5rem", fontSize: "inherit" }} />
                        </>
                      )}
                      {uploadInProgress && (
                        <>
                          <div className={cn(spinner.spinner, spinner.smaller)} />
                          <span>Please wait...</span>
                        </>
                      )}
                    </div>
                  </div>
                </>
              </div>
            )}

            {listingMode === "sessions" && (
              <div
                className={cn(styles.buttonsGrp)}
                style={{ float: "initial", color: "ASD" }}
                onClick={() => {
                  createSession().then(() => {
                    pubsub.publish(topics.SESSIONS_UPDATED)
                  })
                }}
              >
                <span
                  className={cn([
                    button.button,
                    button.iconButton,
                    button.primary,
                    button.spinner,
                    sessionsLoading ? button.loadingButton : null
                  ])}
                >
                  <i className="fa fa-plus" />
                  <span>New Session</span>
                </span>
              </div>
            )}
            <div
              style={{
                marginTop: "0.5em",
                marginBottom: "0.5em",
                fontSize: "1.3em",
                position: activeAppSpace ? "relative" : undefined,
                left: activeAppSpace ? "-100px" : undefined
              }}
            >
              {query?.trim() !== "" ? (
                <p style={{ fontStyle: "italic", float: "right", color: "#5A7699" }}>
                  Search hits for &quot;{query}&quot;
                </p>
              ) : (
                <p style={{ fontStyle: "italic", float: "right", color: "#5A7699" }}>{listingTip}</p>
              )}
            </div>
          </div>
          {listingMode === "files" && (
            <div className={cn(styles.content)} key={orderMode}>
              {(displayType === "recent" || displayType === "folders") && (
                <FileFolderTableView files={files?.files} folders={folders?.folders} displayType={displayType} />
              )}
              {displayType === "tree" && (
                // DEBUGGING sample data
                <FolderTreeView
                  files={window.location.href.includes("testing") ? samples.files : files?.files}
                  folders={window.location.href.includes("testing") ? samples.folders : folders?.folders}
                />
              )}
              {displayType === "previews" && <FilePreviewView files={files?.files} />}
            </div>
          )}

          {listingMode === "sessions" && <SessionsListing sessions={sessionsData?.allSessions} query={query} />}

          {(filesLoading || sessionsLoading) && <QueryPreloader />}
          <hr />
          <div className={styles.paginationContent}>
            {page > 1 && <a onClick={gotoPage(1)}>First</a>}
            {page > 1 && <a onClick={gotoPage(page - 1)}>Previous</a>}
            {pageCount > page && <a onClick={gotoPage(page + 1)}>Next</a>}
            {page < pageCount && <a onClick={gotoPage(pageCount)}>Last</a>}
          </div>
        </FileListingContext.Provider>
      </div>
    </>
  )
}

export default FL
