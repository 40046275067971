import cn from "clsx"
import styles from "./fileTypeToColor.module.scss"

function fileTypeSubFormat(type: string): string {
  if (type?.includes(".")) return `_${type.split(".")[0]}`
  else return type
}

export function fileTypeToColor(type: string): string {
  switch (fileTypeSubFormat(type)?.toUpperCase()) {
    case "DOC":
      return cn([styles.TEXT, styles.flexCenter])
    case "PDF":
      return cn([styles.PDF, styles.flexCenter])
    case "SHEET":
      return cn([styles.SHEET, styles.flexCenter])
    case "IMAGE":
      return cn([styles.IMAGE, styles.flexCenter])
    case "HTML":
      return cn([styles.HTML, styles.flexCenter])
    case "DB":
      return cn([styles.DATABASE, styles.flexCenter])
    case "_SHEET":
      return cn([styles.LightSHEET, styles.flexCenter])
    case "_DOC":
      return cn([styles.LightTEXT, styles.flexCenter])
    case "TMS":
      return cn([styles.TMS, styles.flexCenter])
    default:
      return cn([styles.DEFAULT, styles.flexCenter])
  }
}
