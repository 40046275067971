export const mentionIcon = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="17px" height="17px"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M17.76,11.18c0,1.15,0,2.3,0,3.46A3,3,0,0,0,18,16.07a2,2,0,0,0,2.13,1.05,1.9,1.9,0,0,0,1.62-1.8,21.82,21.82,0,0,0-.12-5,9.33,9.33,0,0,0-7-7.75A9.26,9.26,0,0,0,4.53,5.7,9.26,9.26,0,0,0,3,15.9a9.42,9.42,0,0,0,8.62,5.93,2.94,2.94,0,0,1,.51.05A1.06,1.06,0,0,1,12,24a10.13,10.13,0,0,1-4.11-.68A12,12,0,1,1,24,11.78c0,1.15,0,2.31,0,3.46A4.16,4.16,0,0,1,16.07,17c-.22-.47-.39-.53-.83-.24a5.57,5.57,0,0,1-5.92.38A5.56,5.56,0,0,1,6.2,12.2,5.78,5.78,0,0,1,15,7.13c.3.2.47.19.65-.17A1,1,0,0,1,17,6.32a1,1,0,0,1,.76,1.11v3.75ZM12,15.59A3.58,3.58,0,1,0,8.44,12,3.57,3.57,0,0,0,12,15.59Z"/></g></g></svg>`

export const emojiIcon = `<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="20px" viewBox="0 0 24 24" width="24px"><rect fill="none" height="24" width="24"/><path d="M7,9.5C7,8.67,7.67,8,8.5,8S10,8.67,10,9.5c0,0.83-0.67,1.5-1.5,1.5S7,10.33,7,9.5z M12,17.5c2.33,0,4.31-1.46,5.11-3.5 H6.89C7.69,16.04,9.67,17.5,12,17.5z M15.5,11c0.83,0,1.5-0.67,1.5-1.5C17,8.67,16.33,8,15.5,8S14,8.67,14,9.5 C14,10.33,14.67,11,15.5,11z M22,1h-2v2h-2v2h2v2h2V5h2V3h-2V1z M20,12c0,4.42-3.58,8-8,8s-8-3.58-8-8c0-4.42,3.58-8,8-8 c1.46,0,2.82,0.4,4,1.08V2.84C14.77,2.3,13.42,2,11.99,2C6.47,2,2,6.48,2,12c0,5.52,4.47,10,9.99,10C17.52,22,22,17.52,22,12 c0-1.05-0.17-2.05-0.47-3h-2.13C19.78,9.93,20,10.94,20,12z"/></svg>`

export const sendIcon = `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px"><path d="M0 0h24v24H0z" fill="none"/><path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z"/></svg>`

export const applyIcon = `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="24px"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg>`

export const attachmentIcon = `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M2 12.5C2 9.46 4.46 7 7.5 7H18c2.21 0 4 1.79 4 4s-1.79 4-4 4H9.5C8.12 15 7 13.88 7 12.5S8.12 10 9.5 10H17v2H9.41c-.55 0-.55 1 0 1H18c1.1 0 2-.9 2-2s-.9-2-2-2H7.5C5.57 9 4 10.57 4 12.5S5.57 16 7.5 16H17v2H7.5C4.46 18 2 15.54 2 12.5z"/></svg>`

export const fullscreenIcon = `<svg xmlns="http://www.w3.org/2000/svg" style="color: white; width: 100%; height: 100%" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3"></path></svg>`
