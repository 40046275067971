import React, { useState, useRef, useEffect } from "react"
import styles from "./TabGroup.module.scss"
import cn from "clsx"

function renderTab(title: string, index: number, active: boolean, handleClick: (title: string) => unknown) {
  return (
    <div className={cn(styles.tab, active ? styles.active : "")} onClick={() => handleClick(title)} key={index}>
      {title}
    </div>
  )
}

const TabGroup: React.FC<{
  tabs: string[]
  activeTab: string
  setActiveTab: (tab: string) => void
  theme?: string
}> = ({ tabs, activeTab, setActiveTab, theme }) => {
  const [scrollable, setScrollable] = useState(false)
  const [leftScroll, setLeftScroll] = useState(false)
  const [rightScroll, setRightScroll] = useState(true)
  const tabsContainer = useRef<HTMLDivElement>(null)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onScrollHandle = (e: any) => {
    setLeftScroll(e.target.scrollLeft > 0)
    setRightScroll(e.target.scrollLeft <= 0)
  }

  const rightHandle = () => {
    if (tabsContainer.current) tabsContainer.current.scrollLeft = tabsContainer.current?.scrollLeft || 0 + 100
  }

  const leftHandle = () => {
    if (tabsContainer.current) tabsContainer.current.scrollLeft = 0
  }

  useEffect(() => {
    setScrollable((tabsContainer.current?.scrollWidth || 0) > (tabsContainer.current?.clientWidth || 0))
  }, [])

  return (
    <React.Fragment>
      <div className={cn(styles.tabsContainer, theme ? styles[theme] : null)}>
        <div className={styles.tabs} ref={tabsContainer} onScroll={onScrollHandle}>
          {tabs.slice(0, tabs.length).map((t, i) => renderTab(t, i, t === activeTab, (tab) => setActiveTab(tab)))}
        </div>
        {scrollable && leftScroll && (
          <div className={styles.leftArrow} onClick={leftHandle}>
            <span className="material-icons">chevron_left</span>
          </div>
        )}
        {scrollable && rightScroll && (
          <div className={styles.rightArrow} onClick={rightHandle}>
            <span className="material-icons">chevron_right</span>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default TabGroup
