import spreadjsTemplate from "./spreadjsTemplate"

export default function defaultAppSpaceContent(filename: string | undefined, type: string): string {
  const match = filename?.match(/(?!(?<=[1-4])D)[A-Z]{2,}$/)
  let sub = ""
  if (match) {
    if (match.length > 0) {
      sub = match[0]
    }
  }

  if (type === "doc") {
    if (sub !== "") {
      return `<h1>${filename?.substr(
        0,
        filename.length - sub.length
      )} <span style="color: #ced4d9;" data-mce-style="color: #ced4d9;">${sub}</span></h1><p></p>`
    }

    if (filename) {
      if (filename !== "") {
        return `<h1>${filename}</h1><p></p>`
      }
    }

    return `<h1>Text file</h1><p></p>`
  } else {
    if (sub !== "") {
      return spreadjsTemplate(filename?.substr(0, filename.length - sub.length) || "Spreadsheet", sub)
    }

    return spreadjsTemplate(filename || "Spreadsheet")
  }
}
