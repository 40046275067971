import { useEffect } from "react"
import { useRoot_RegisterForNotificationMutation } from "generated/graphql"
import { isProd } from "utils/env"

/* eslint-disable no-useless-escape */
function urlBase64ToUint8Array(base64String: string) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/")

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

function registerPushNotifications(reg: ServiceWorkerRegistration): Promise<PushSubscription> {
  const subscribeOptions = {
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array(
      "BEqD1z_rcgXJYR-uRhIxh9NO6ZTny0MBIyO1pwpd5ke9gB6EuwOdkjweAymQdHp0w4Z4MCHb6c0_7_3DNNNfJgM"
    )
  }

  return reg.pushManager.subscribe(subscribeOptions)
}

function askPermission(): Promise<void> {
  return new Promise((resolve, reject) => {
    const permissionResult = Notification.requestPermission((result) => {
      resolve(result)
    })

    if (permissionResult) {
      permissionResult.then(resolve, reject)
    }
  }).then((permissionResult) => {
    if (permissionResult !== "granted") {
      throw new Error("We weren't granted permission.")
    }
  })
}

export async function useNotificationRegistation(): Promise<void> {
  const [register] = useRoot_RegisterForNotificationMutation()
  useEffect(() => {
    ;(async () => {
      if (!navigator.serviceWorker) {
        return
      }

      const reg = await navigator.serviceWorker.ready
      const sub = await reg.pushManager.getSubscription()

      async function registeSub(): Promise<void> {
        const ps = await registerPushNotifications(reg)
        register({
          variables: {
            sub: JSON.stringify(ps)
          }
        })
      }

      if (!sub) {
        try {
          await askPermission()
          await registeSub()
        } catch {
          /* */
        }
      } else {
        await registeSub()
      }
    })()
  }, [])
}

export async function unsubscribeFromNotifictions(): Promise<void> {
  if (!isProd()) {
    return
  }
  if (!navigator.serviceWorker) {
    return
  }

  const reg = await navigator.serviceWorker.ready
  if (!reg) {
    return
  }
  const sub = await reg.pushManager.getSubscription()

  if (sub) {
    sub.unsubscribe()
  }
}
