import React from "react"
import styles from "./NewsList.module.scss"
import { textEllipsis } from "utils/common"
import LazyImage from "modules/shared/components/lazyImage"
import cn from "clsx"
import _ from "lodash"

class NewsRef {
  name: string
  url: string
  thumbnailUrl: string
  description: string | null

  constructor(name: string, url: string, thumbnailUrl: string, description: string | null) {
    this.name = name
    this.url = url
    this.thumbnailUrl = thumbnailUrl
    this.description = description
  }
}

const NewsList: React.FC<{ news: NewsRef[]; feed?: boolean }> = ({ news, feed }) => {
  if (feed) {
    return (
      <div className={styles.root}>
        {_.take(news, 1).map((item, i) => (
          <div
            key={i}
            onClick={() => {
              window.open(item.url, "_blank")
            }}
            className={cn(styles.newsItem, styles.feed)}
          >
            <div className={styles.thumbContainer}>
              <LazyImage src={item.thumbnailUrl} alt={item.name} />
            </div>

            <div className={styles.newsDetails}>
              <span className={styles.newsTitle}>{textEllipsis(item.name, 100)}</span>
              {item.description && (
                <span className={styles.newsDescription}>{textEllipsis(item.description, 100)}</span>
              )}
              <span className={styles.newsUrl}>{textEllipsis(item.url, 100)}</span>
            </div>
          </div>
        ))}
        <div className={styles.newsGrid}>
          {_.slice(news, 1).map((item, i) => (
            <div
              onClick={() => {
                window.open(item.url, "_blank")
              }}
              className={cn(styles.newsItem, styles.feed)}
              key={i}
            >
              <div className={styles.thumbContainer}>
                <LazyImage src={item.thumbnailUrl} alt={item.name} />
              </div>

              <div className={styles.newsDetails}>
                <span className={styles.newsTitle}>{textEllipsis(item.name, 100)}</span>
                {item.description && (
                  <span className={styles.newsDescription}>{textEllipsis(item.description, 100)}</span>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }

  const handleDrag = (ev: React.DragEvent, item: NewsRef) => {
    ev.dataTransfer.setData(
      "text/html",
      `<a href="${item.url || "#"}">${item.name || "New Title"}</a><p>${item.description || "News Description"}</p>`
    )
  }

  return (
    <div className={styles.root}>
      {news.map((item, i) => (
        <div
          onClick={() => {
            window.open(item.url, "_blank")
          }}
          className={styles.newsItem}
          key={i}
          draggable={true}
          onDragStart={(ev) => handleDrag(ev, item)}
        >
          <div className={styles.imageContainer}>
            <LazyImage src={item.thumbnailUrl} alt={item.name} />
            {/* <img src={item.thumbnailUrl} alt={item.name} /> */}
          </div>
          <div className={styles.newsDetails}>
            <span className={styles.newsTitle}>{textEllipsis(item.name, 100)}</span>
            {item.description && <span className={styles.newsDescription}>{textEllipsis(item.description, 100)}</span>}
            <span className={styles.newsUrl}>{textEllipsis(item.url, 100)}</span>
          </div>
        </div>
      ))}
    </div>
  )
}

export { NewsList, NewsRef }
