import React, { useEffect, useState } from "react"
import Popup from "reactjs-popup"
import styles from "./NotificationView.module.scss"
import {
  NotificationFragment,
  NotificationViewDocument,
  useNotificationViewQuery,
  useSessions_CreateSessionMutation,
  useNotificationView_ClickNotificationMutation,
  useNotificationView_NotificationsSubscription
} from "generated/graphql"
import menu from "modules/shared/styles/components/menu.module.scss"
import cn from "clsx"
import { DateTime } from "luxon"
import { useHistory } from "react-router-dom"
import { textEllipsis } from "utils/common"
import striptags from "striptags"
import { useApolloClient } from "@apollo/client"
import { isMobile } from "react-device-detect"
import { useLocation } from "react-router-dom"
import { routes } from "consts"
import { handleGraphQlError } from "utils/common"

const Notification: React.FC<{
  notification: NotificationFragment
  read: (id: string) => void
  onClick: () => void
}> = ({ notification, onClick, read }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [createSession] = useSessions_CreateSessionMutation({
    onError: handleGraphQlError("Unable to create session")
  })
  return (
    <div
      className={cn([styles.notification, notification.isRead ? styles.read : null])}
      onClick={async () => {
        await createSession({})
        setTimeout(onClick, 250)
      }}
    >
      <div className={styles.icon}>
        <img src={notification.image || ""} alt="" />
        {notification.type === "taskAssigneeAdd" && (
          <div className={cn([styles.fa, styles.assignee])}>
            <i className="material-icons">person_add</i>
          </div>
        )}
        {notification.type === "taskAssigneeRemove" && (
          <div className={cn([styles.fa, styles.archived])}>
            <i className="material-icons">person_remove</i>
          </div>
        )}
        {notification.type === "taskArchived" && (
          <div className={cn([styles.fa, styles.archived])}>
            <i className="material-icons">archive</i>
          </div>
        )}
        {(notification.type === "wikiComment" || notification.type === "taskComment") && (
          <div className={cn([styles.fa, styles.comment])}>
            <i className="material-icons">comment</i>
          </div>
        )}
        {notification.type === "taskMention" && (
          <div className={cn([styles.fa, styles.comment])}>
            <i className="material-icons">alternate_email</i>
          </div>
        )}
        {notification.type === "taskboardRemove" && (
          <div className={cn([styles.fa, styles.archived])}>
            <i className="material-icons">person_remove</i>
          </div>
        )}
        {notification.type === "taskboardAdd" && (
          <div className={cn([styles.fa, styles.archived])}>
            <i className="material-icons">person_add</i>
          </div>
        )}
      </div>
      <div className={styles.text}>
        <p className={styles.title}>
          <b>{notification.title}</b>
          <p>{notification.message}</p>
        </p>

        {notification.type?.includes("task") &&
          !notification.type?.includes("taskboard") &&
          notification.task?.description && (
            <span className={styles.description}>
              {textEllipsis(striptags(notification.task?.description || ""), 50)}
            </span>
          )}

        <span className={styles.time}>{DateTime.fromJSDate(new Date(notification.createdDate)).toRelative()}</span>
      </div>
      <Popup
        arrow={true}
        open={menuOpen}
        onOpen={() => setMenuOpen(true)}
        onClose={() => setMenuOpen(false)}
        trigger={
          <div className={cn([styles.menu, menuOpen ? styles.focus : null])}>
            <i className="material-icons">more_horiz</i>
          </div>
        }
        contentStyle={{ padding: 0 }}
        position="bottom right"
      >
        {
          ((close: () => void) => (
            <div className={cn(menu.menu, menu.leftCenter)}>
              <div
                className={cn(menu.item)}
                onClick={() => {
                  onClick()
                  close()
                }}
              >
                <i className="material-icons">launch</i>
                <span>Open</span>
              </div>
              <div
                className={cn(menu.item)}
                onClick={() => {
                  read(notification.id)
                  close()
                }}
              >
                <i className="material-icons">done</i>
                <span>Mark as read</span>
              </div>
            </div>
          )) as unknown as React.ReactNode
        }
      </Popup>
    </div>
  )
}

const NotificationView: React.FC<{ isDashboard?: boolean }> = ({ isDashboard }) => {
  const [open, setOpen] = useState(false)
  const { pathname } = useLocation()
  const take = 10
  const skip = 0

  const { data, fetchMore } = useNotificationViewQuery({
    variables: {
      take: take,
      skip: skip
    }
  })

  const history = useHistory()
  const [click] = useNotificationView_ClickNotificationMutation()

  const { data: subsData } = useNotificationView_NotificationsSubscription()
  const client = useApolloClient()

  useEffect(() => {
    if (subsData && data) {
      client.writeQuery({
        query: NotificationViewDocument,
        variables: {
          skip,
          take
        },
        data: {
          notifications: [subsData.notifications, ...data.notifications],
          unreadNotifications: data.unreadNotifications + 1
        }
      })
    }
  }, [subsData])

  if (!data) {
    return null
  }

  const available = data.unreadNotifications

  const onScrollHandle = async (ev: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const t = ev.currentTarget
    if (t.scrollTop + t.clientHeight === t.scrollHeight) {
      if (data) {
        await fetchMore({
          variables: {
            skip: data.notifications.length
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          updateQuery: (prev: any, { fetchMoreResult }) => {
            return {
              unreadNotifications: prev.unreadNotifications,
              notifications: [...prev.notifications, ...(fetchMoreResult?.notifications || [])]
            }
          }
        })
      }
    }
  }

  if (isDashboard) {
    return (
      <div className={cn([styles.notificationContainer, styles.dashboardMode])}>
        <div className={styles.view}>
          <div className={styles.header}>Notifications</div>

          <div className={styles.list} onScroll={onScrollHandle}>
            {!data.notifications.length && (
              <div className={styles.empty}>
                <i className="material-icons">inbox</i>
                <span>No notifications</span>
              </div>
            )}

            {data.notifications.map((notification) => (
              <Notification
                key={notification.id}
                notification={notification}
                read={async () => {
                  await click({
                    variables: {
                      id: notification.id
                    }
                  })
                }}
                onClick={async () => {
                  const { path } = JSON.parse(notification.link)
                  history.push(path)
                  await click({
                    variables: {
                      id: notification.id
                    }
                  })
                  setOpen(false)
                }}
              />
            ))}
          </div>
        </div>
      </div>
    )
  }

  return (
    <Popup
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      arrow={false}
      trigger={
        <div
          className={cn([
            styles.trigger,
            open ? styles.active : null,
            available ? styles.available : null,
            pathname === routes.dashboard ? styles.dashboard : null
          ])}
        >
          {!!available && (
            <div className={styles.count}>
              <span>{available}</span>
            </div>
          )}
          <i className={open || !!available ? "material-icons" : "material-icons-outlined"}>notifications</i>
        </div>
      }
      className={"notificationShadeContainer"}
      position="bottom right"
      offsetX={6}
      nested={true}
      offsetY={isMobile ? 15 : 0}
    >
      <div className={styles.notificationContainer}>
        <div className={styles.view}>
          <div className={styles.header}>Notifications</div>

          <div className={styles.list} onScroll={onScrollHandle}>
            {!data.notifications.length && (
              <div className={styles.empty}>
                <i className="material-icons">inbox</i>
                <span>No notifications</span>
              </div>
            )}

            {data.notifications.map((notification) => (
              <Notification
                key={notification.id}
                notification={notification}
                read={async () => {
                  await click({
                    variables: {
                      id: notification.id
                    }
                  })
                }}
                onClick={async () => {
                  const { path } = JSON.parse(notification.link)
                  history.push(path)
                  await click({
                    variables: {
                      id: notification.id
                    }
                  })
                  setOpen(false)
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default NotificationView
