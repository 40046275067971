/* eslint-disable */
import React, { useEffect, useRef, useState } from "react"
import styles from "./TextEditorPopup.module.scss"
import pubsub from "pubsub-js"

/**
 * A wrapper for text editor popups, to implement new ones edit this and hook.ts
 * @param topic Topic for the Popup (emojiSelector, taskSelector, etc...)
 * @param filtered Filterered items
 */
const TextEditorPopup: React.FC<{
  topic: string
  filtered: any[]
  searchText: string
  setSearchText: (s: string) => void
  focused: number
  setFocused: React.Dispatch<React.SetStateAction<number>>
  children: (hide: () => void) => JSX.Element
  onDataShouldRefresh?: () => unknown
}> = ({ topic, children, filtered, searchText, setSearchText, focused, setFocused, onDataShouldRefresh }) => {
  const root = useRef<HTMLDivElement>(null)
  const [position, setPosition] = useState<null | any>(null)

  function handleCtrlKey(key: string) {
    if (key === "ArrowUp") {
      setFocused((f) => (f === 0 ? 0 : f - 1))
    } else if (key === "ArrowDown") {
      setFocused((f) => f + 1)
    } else if (key === "Enter") {
      if (focused > -1) {
        const current = filtered[focused]
        if (current) {
          let data = {}

          if (topic === "userSelector") {
            data = { id: current.id, fullName: current.fullName }
          }
          if (topic === "taskSelector") {
            data = {
              id: current.task.id,
              idNumber: current.task.idNumber,
              color: current.task.state.color,
              title: current.task.title,
              boardId: current.boardIdStr
            }
          }
          if (topic === "emojiSelector") {
            data = {
              id: current.id,
              code: current.code,
              name: current.name,
              icon: current.icon
            }
          }

          pubsub.publish("userSelector.select", data)
          hide()
        }
      }
    } else if (key === "Escape") {
      hide()
    }
  }

  function hide() {
    setPosition(null)
    setFocused(-1)
    setSearchText("")
    pubsub.publish(`${topic}.closed`, null)
  }

  useEffect(() => {
    const tokens: string[] = []
    const sub = (func: string, handler: any) =>
      tokens.push(pubsub.subscribe(`${topic}.` + func, (_: any, val: any) => handler(val)))

    sub("show", (p: any) => {
      setPosition(p)
      if (onDataShouldRefresh) {
        onDataShouldRefresh()
      }
    })
    sub("search", (value: string) => {
      setSearchText(value)
      setFocused(0)
    })
    sub("ctrlKey", (k: any) => handleCtrlKey(k))
    sub("hide", () => hide())

    return () => {
      tokens.forEach(pubsub.unsubscribe)
    }
  }, [filtered, focused])

  useEffect(() => {
    if (focused > filtered.length - 1) {
      setFocused(0)
    }
  }, [focused, filtered])

  if (!position) {
    return null
  }

  return (
    <div
      className={styles.root}
      style={{
        width: topic === "taskSelector" ? "60rem" : "fit-content",
        left: position.left,
        top: position.top - 300
      }}
      ref={root}
      onKeyDown={(e) => handleCtrlKey(e.key)}
    >
      {children(hide)}
    </div>
  )
}

export default TextEditorPopup
