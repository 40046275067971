/* eslint-disable */
import React, { useContext, useEffect, useRef, useState } from "react"
import styles from "./FolderTreeView.module.scss"
import fileStyles from "../fileFolderTableView/FileFolderTableView.module.scss"
import { FolderTreeView_FileFragment as File, FolderTreeView_FolderFragment as Folder } from "generated/graphql"
import { fileTypeToColor } from "../../utils/fileTypeToColor"
import cn from "clsx"
import { useDrag, useDrop } from "react-dnd"
import input from "modules/shared/styles/components/input.module.scss"
import pubsub from "pubsub-js"
import { topics } from "consts"

import DirectoryOptionsMenu from "../optionsButtons/directoryOptionsMenu/DirectoryOptionsMenu"
import FileOptionsMenu from "../optionsButtons/fileOptionsMenu/FileOptionsMenu"
import {
  FileListingContext,
  isLatest,
  modeType,
  useMonth,
  unfilteredFolders,
  cycleInUse1,
  resetCycleInUse1,
  safeDate
} from "../fileListing/FileListing"

import EmptyFilesWarning from "../emptyFilesWarning"
import { searchQuery } from "../searchControl/SearchControl"

import { humanizeFileSize } from "utils/common"
import { KeyCodes } from "consts"
import SplitterContext from "modules/shared/components/splitterContext"
import ExpandableRecentsList from "modules/shared/components/expandableRecentsList"
// import RecentAccessFolders from "./components/recentAccessFolders"
import { isMobile } from "react-device-detect"
import { setGlobalFinishPreview } from "modules/app/components/root/Root"
export let cycleInUse2 = false

export function resetCycleInUse2() {
  cycleInUse2 = false
}
export let cycleRoot: () => void
export let cycleFolder: () => void
export let [folderCycle, rootCycle] = [1, 1]
export function resetCycle() {
  rootCycle = 1
  folderCycle = 1
}
let blankFolder = false
export const setFolderCycle = (value: number) => {
  rootCycle = value
  folderCycle = value
}
interface Branch {
  files: any
  folders: Branch[]
  itself: Folder
  selectedItems?: any
  level: number
}

export let filterByBranch = "root"
export const setFilterByBranch = (branch: string) => (filterByBranch = branch)
export let extendedFolders: string[] = []

const FileComp: React.FC<{ file: any; branch: Branch }> = ({ file, branch }) => {
  const [{ opacity }, connectDragSource] = useDrag(() => ({
    type: "file",
    item: { type: "file", id: file.id, parentId: file.parent?.id },
    collect: (monitor) => {
      return {
        opacity: monitor.isDragging() ? 0.5 : 1
      }
    },
    canDrag: !isMobile
  }))
  const [expandedVersions, setExpandedVersions] = useState(true)
  const [isRenaming, setIsRenaming] = useState(false)
  const [currentRenamingName, setCurrentRenamingName] = useState(file.name)
  const renameInput = useRef<HTMLInputElement>(null)
  const { renameFile, openAppSpace } = useContext(FileListingContext)
  function endRenaming() {
    if (currentRenamingName) {
      setIsRenaming(false)
      renameFile(file.id, currentRenamingName)
    }
  }

  useEffect(() => {
    if (isRenaming) {
      renameInput.current?.focus()
    }
  }, [isRenaming])

  const { mobileChatPane } = useContext(SplitterContext)

  const truncFileName = (name: string) => {
    return name.length > 50 ? name.slice(0, 50) + "..." : name
  }

  return connectDragSource(
    <div className="selectableFile" data-file-id={file.id}>
      <div
        className={cn(fileStyles.filename, fileStyles.filetr, styles.fileRow)}
        key={file.id}
        onClick={
          !isRenaming
            ? () => {
                openAppSpace(file)
                setGlobalFinishPreview("")
              }
            : undefined
        }
        style={{ opacity }}
      >
        {(file.versions || []).length > 0 && (
          <i
            className={cn(
              fileStyles.plusIcon,
              !expandedVersions ? "fa fa-fw fa-plus-square" : "fa fa-fw fa-minus-square"
            )}
            style={{ marginRight: 0, opacity: modeType === "recent" ? 0 : 0.5 }}
            onClick={(ev) => {
              setExpandedVersions(!expandedVersions)
              ev.preventDefault()
              ev.stopPropagation()
            }}
          />
        )}
        {(file.versions || []).length === 0 && <i className="fa fa-fw" />}

        <div className={fileTypeToColor(file.type)} style={{ overflowX: "hidden" }}>
          {/* DO NOT DELETE THIS !!! */}
          {/* <img src={file?.previewUrl} alt="" className={fileTypeToColor(file.type)} style={{ maxHeight: "100%" }} /> */}
        </div>
        {/* Allow file name wrap */}
        {/* {!isRenaming && <span className={styles.filenameWrap}>{file.name}</span>} */}
        {!isRenaming && (
          <div
            className="fileItem"
            style={{
              paddingRight: "6rem",
              minWidth: "fit-content",
              width: "fit-content",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "clip"
            }}
          >
            {truncFileName(file.name)}
          </div>
        )}
        {isRenaming && (
          <input
            onBlur={() => setIsRenaming(false)}
            ref={renameInput}
            value={currentRenamingName}
            className={cn(input.input, input.small, styles.renameInput)}
            type="text"
            onChange={(e) => setCurrentRenamingName(e.target.value)}
            onKeyDown={(e: { keyCode: number }) => {
              if (e.keyCode === KeyCodes.Enter) endRenaming()
            }}
          />
        )}
        <div className={cn(styles.test, styles.hoverButtonsGroup, fileStyles.hoverGroup)}>
          <FileOptionsMenu
            self={file}
            onRename={() => setIsRenaming(!isRenaming)}
            selectedItems={branch.selectedItems}
          />
        </div>
        {!mobileChatPane && (
          <>
            <span style={{ marginLeft: "6rem" }} className={styles.hover}>
              {new Date(file.updatedDate).toLocaleString()}
            </span>
            <span className={styles.hover}>{humanizeFileSize(file.size)}</span>
          </>
        )}
        {mobileChatPane && <span />}
      </div>
      {(file.isConverted || file.type === "doc.msword") && (
        <div className={fileStyles.backupLine} style={{ marginLeft: 35 }}>
          <span className={cn("material-icons", fileStyles.icon)} style={{ transform: "rotateZ(90deg)" }}>
            subdirectory_arrow_left
          </span>
          {/* <div className={cn(fileTypeToColor("_" + file.type), fileStyles.colorBlock)} /> */}
          <span className={fileStyles.name}>{file.name + " (Original)"}</span>
        </div>
      )}
      {expandedVersions && (
        <ExpandableRecentsList
          itemRenderer={(version, _) => (
            <div
              key={version.id}
              className={cn(fileStyles.filename, fileStyles.filetr, styles.fileRow)}
              onClick={!isRenaming ? () => openAppSpace(version) : undefined}
              style={{ opacity: 0.5, marginLeft: 25, paddingLeft: 17.5 }}
            >
              <div style={{}} className={fileTypeToColor(version.type)} />
              <span className={styles.filenameWrap}>{version.name}</span>

              {!mobileChatPane && (
                <>
                  <span className={styles.hover}>{new Date(version.createdDate).toLocaleString()}</span>
                  <span className={styles.hover}>{humanizeFileSize(version.size)}</span>
                </>
              )}
              {mobileChatPane && <span />}

              <div className={cn(styles.hoverButtonsGroup, fileStyles.hoverGroup)}>
                <FileOptionsMenu
                  self={version}
                  onRename={() => setIsRenaming(!isRenaming)}
                  selectedItems={branch.selectedItems}
                />
              </div>
            </div>
          )}
          itemTypeName="versions"
          items={file.versions || []}
          showMoreLessLabel={(text, toggle) => (
            <tr onClick={toggle}>
              <td className={styles.loadAllButton} style={{ paddingLeft: 68, paddingBottom: 5 }}>
                <span>{text}</span>
              </td>
            </tr>
          )}
        />
      )}
    </div>
  )
}

let allowListings: any[] = []
let rootListings: any[] = []
let usedFolders: any[] = []
export const clearUsedFolders = () => (usedFolders = [])

const BranchComp: React.FC<{
  branch: Branch
  handleClick: (id: string) => unknown
}> = ({ branch, handleClick }) => {
  const { renameFolder, moveThingToFolder, orderMode, query, limitMode } = useContext(FileListingContext)
  const [namingNewFolder, setNamingNewFolder] = useState(false)
  const [expandFolderItem, setExpandFolderItem] = useState(false)
  const [extendFolder, setExtendFolder] = useState("")

  let filesByMonth = branch?.files?.filter((f: { updatedDate: string; type: string }) => {
    let readFormat = new Date(f.updatedDate).toString()
    let month = useMonth.split(" ")[0]
    if (month.length > 3) month = month.slice(0, 3)
    if (
      (readFormat.includes(` ${month}`) && // month
      readFormat.includes(` ${useMonth.split(" ")[1]}`) && // year
        f.type !== "taskBoard") || // exclude other types
      searchQuery
    )
      return f
  })

  const [{ opacity }, connectDragSource] = useDrag(() => ({
    type: "folder",
    item: {
      type: "folder",
      id: branch.itself.id,
      parentId: branch.itself.parent?.id
    },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.5 : 1
    }),
    canDrag: branch.itself.parent !== null // Root cannot be dragged
  }))

  if (document.getElementsByClassName("fileItem").length === 0 && modeType === "recent") blankFolder = true
  else blankFolder = false

  const [{ isOver }, connectDropTarget] = useDrop(() => ({
    accept: ["folder", "file"],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    drop: (a: any) => {
      // Removed updation date from file move event
      moveThingToFolder(a.id as string, branch.itself.id, a.type)
    },
    canDrop: (item, m) =>
      item.id !== branch.itself.id && // Cannot drop on itself
      item.parentId !== branch.itself.id && // Cannot drop if already the parent
      m.isOver({ shallow: true }),
    collect: (m) => ({ isOver: m.isOver({ shallow: true }) })
  }))

  const [renaming, setRenaming] = useState(false)
  const renameInput = useRef<HTMLInputElement>(null)
  const [currentRenamingName, setCurrentRenamingName] = useState(branch.itself.name || "")

  function endRenaming() {
    if (currentRenamingName) {
      setRenaming(false)
      renameFolder(branch.itself.id, currentRenamingName)
    }
  }

  const sum = branch?.folders?.length + branch?.files?.length
  const canExpand = sum === 0 //&&
  //!!branch.itself?.hasChildren

  const startRenaming = () => {
    setRenaming(true)
    setTimeout(() => renameInput.current?.focus(), 200)
  }

  const expanded = sum > 0

  if ((query || limitMode === "recent") && !hasChildrenBelow(branch) && branch.itself.parent) {
    return null
  }

  function hasChildrenBelow(b: Branch): boolean {
    if (!b.itself.parent) {
      return true // root
    }

    if (b.files.length > 0) {
      return true
    }

    if (b.folders.length > 0) {
      let hasChildren = false

      b.folders.forEach((c) => {
        if (hasChildrenBelow(c)) {
          hasChildren = true
        }
      })

      return hasChildren
    }

    return false
  }
  // exclude the root folder
  if (branch.itself.name === "/root") {
    const elem = (
      <div style={{ marginLeft: "-2.5rem" }} key={branch.itself.id}>
        {!!filesByMonth.length && (
          <>
            {orderMode === "recent" && (
              <ExpandableRecentsList
                itemRenderer={(f, _) => (
                  <div style={{ marginLeft: (branch.level + 1) * 25 }} key={f.id}>
                    <FileComp branch={branch} file={f} />
                  </div>
                )}
                itemTypeName="files"
                items={filesByMonth}
                modifiedDate={true}
                showMoreLessLabel={(text, toggle) => (
                  <div
                    onClick={() => {
                      toggle()
                    }}
                  >
                    <div className={styles.loadAllButton} style={{ marginLeft: (branch.level + 2) * 24 }}>
                      <span>{text}</span>
                    </div>
                  </div>
                )}
              />
            )}
            {orderMode !== "recent" && (
              <>
                {filesByMonth.map((f: any) => (
                  <div style={{ marginLeft: (branch.level + 1) * 25 }} key={f.id}>
                    <FileComp branch={branch} file={f} />
                  </div>
                ))}
              </>
            )}
          </>
        )}
        {!!branch?.folders?.length &&
          branch?.folders?.map((b) => {
            if (b.itself) {
              return (
                <div style={{ display: blankFolder && filterByBranch === "root" ? "none" : "block" }}>
                  <BranchComp key={b.itself.id} branch={b} handleClick={handleClick} />
                </div>
              )
            } else return null
          })}
        {blankFolder && filterByBranch === "root" && (
          <>
            <div style={{ background: "transparent", marginLeft: "4.7rem" }} className={styles.folderRow}>
              <i className={cn("fa fa-fw fa-folder-open", styles.folderIcon)} />
              <span className={styles.folderName}>/</span>
            </div>
            <p style={{ margin: "0.6rem 0 0 8rem", color: "#999" }}>
              {isLatest ? "No files recently modified" : `No files found for "${useMonth}"`}
            </p>
          </>
        )}
      </div>
    )
    return connectDragSource(connectDropTarget(elem))
  }
  if (
    searchQuery ||
    (branch.itself.name.startsWith("SHARED") && modeType === "recent" && isLatest) ||
    (branch.itself.name.startsWith("SHARED") && modeType === "all")
  ) {
    filesByMonth = branch?.files.filter((f: any) => f.type !== "taskBoard")
  } else {
    // Override the filesByMonth to show all files
    if (modeType === "all" || (modeType === "recent" && filterByBranch !== "root")) {
      if (filterByBranch === "root") {
        filesByMonth = branch?.files?.filter((f: any) => {
          if ((f.parent.id === "root" || searchQuery || f.parent.id === extendFolder) && f.type !== "taskBoard")
            return true
        })
      }
      // Within folder browse
      else {
        filesByMonth = branch?.files?.filter((f: any) => {
          if ((allowListings.includes(f.parent.id) && folderCycle === 2) || searchQuery || f.parent.id === extendFolder)
            return true
          if (f.parent.id === filterByBranch || searchQuery || f.parent.id === extendFolder || folderCycle === 3)
            return true
        })
      }
      if (extendFolder || rootCycle === 3) filesByMonth = branch?.files
      if (rootCycle === 2) {
        filesByMonth = branch?.files?.filter((f: any) => {
          if ((f.parent.id === "root" || searchQuery || rootListings.includes(f.parent.id)) && f.type !== "taskBoard")
            return true
        })
      }
    }
    // Allow listing algo to work on Latest
    if (isLatest && filterByBranch === "root" && modeType === "recent") {
      if (searchQuery || (filterByBranch !== "root" && modeType === "recent")) {
        filesByMonth = branch?.files.filter((f: any) => f.type !== "taskBoard")
      } else {
        const recentOnly = branch?.files?.filter((file: any) => {
          let date = new Date(file.updatedDate)
          let today = safeDate()
          let diff = today.getTime() - date.getTime()
          let diffDays = Math.ceil(diff / (1000 * 3600 * 24))
          return diffDays <= 7 && file.type !== "taskBoard"
        })
        filesByMonth = recentOnly
      }
    }
    if (modeType === "recent" && filterByBranch !== "root" && (rootCycle === 3 || folderCycle === 3)) {
      filesByMonth = branch?.files.filter((f: any) => f.type !== "taskBoard")
    }

    if (searchQuery.length > 0 || (filterByBranch !== "root" && rootCycle > 1 && modeType === "all")) {
      if (branch.level > 2) {
      } else filesByMonth = branch?.files.filter((f: any) => f.type !== "taskBoard")
    }
  }

  const expandIcon = () => {
    if (modeType === "all" && filterByBranch === branch.itself.id) {
      return (
        <i style={{ opacity: modeType === "all" ? "0.5" : "" }} className={cn(styles.plusIcon, "fa fa-minus-square")} />
      )
    }
    if (modeType === "recent") {
      return (
        <>
          {canExpand && <i className={cn(styles.plusIcon, "fa fa-plus-square")} />}
          {!canExpand && !expanded && <i className={cn(styles.plusIcon, styles.hide, "fa fa-plus-square")} />}
          {expanded && <i className={cn(styles.plusIcon, "fa fa-minus-square")} />}
        </>
      )
    } else {
      if (
        extendFolder === branch.itself.id ||
        branch.itself.id === "root" ||
        branch.itself.name.startsWith("SHARED") ||
        folderCycle !== 1 ||
        rootCycle !== 1
      )
        return (
          <i
            style={{ opacity: modeType === "all" ? "0.5" : "" }}
            className={cn(styles.plusIcon, "fa fa-minus-square")}
          />
        )
      return (
        <i style={{ opacity: modeType === "all" ? "0.5" : "" }} className={cn(styles.plusIcon, "fa fa-plus-square")} />
      )
    }
  }
  cycleRoot = () => {
    rootCycle++
    if (rootCycle > 3) {
      rootCycle = 1
    }
  }
  cycleFolder = () => {
    folderCycle++
    if (folderCycle > 3) {
      folderCycle = 1
    }
  }

  const irrelevantBranch = () => {
    /**
     * The remove blank folder algo is now removing the root/ folder directory,
     * this is then causing none of the recent files within deep subfolders to be shown,
     * due to the root folder being hidden, as it does not contain any recent folders.   */

    if (modeType === "recent" && isLatest && branch.itself.name === "/") return "block"
    return !usedFolders.includes(branch.itself.name) && modeType === "recent" && filterByBranch === "root"
      ? "none"
      : "block"
  }

  const elem = (
    <div
      style={{
        opacity: isOver ? "0.8" : opacity,
        pointerEvents: "auto",
        zIndex: branch.level + branch.level,
        display: irrelevantBranch()
      }}
      key={branch.itself.id}
    >
      <div
        className={cn([styles.folderRow, branch.itself.name !== "/" && "selectableFile"])}
        data-bid={branch.itself.id}
        onClick={() => {
          if (modeType === "all" && branch.itself.id === "root") {
            if (cycleInUse1) {
              cycleInUse2 = false
              resetCycleInUse1()
              resetCycle()
            }
            cycleInUse2 = true
            cycleRoot()
            return
          }
          if (filterByBranch === branch.itself.id) {
            if (cycleInUse1) {
              cycleInUse2 = false
              resetCycleInUse1()
              resetCycle()
            }
            cycleInUse2 = true
            if (modeType === "recent") cycleFolder()
            else cycleRoot()
            return
          }
          extendedFolders = []
          setExtendFolder("")
          if (branch.itself.id === filterByBranch) {
            setExpandFolderItem(true)
            return
          }
          filterByBranch = branch.itself.id
        }}
      >
        <i style={{ marginLeft: branch.level * 25 }} />

        <span
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            setExtendFolder(branch.itself.id)
            extendedFolders.push(branch.itself.id)
            if (extendFolder === branch.itself.id) {
              setExtendFolder("")
              extendedFolders = extendedFolders.filter((f: string) => f !== branch.itself.id)
            }
          }}
        >
          {expandIcon()}
        </span>

        {!branch?.itself?.name?.startsWith("SHARED") ? (
          <>
            {!expanded && <i className={cn("fa fa-fw fa-folder", styles.folderIcon)} />}
            {expanded && <i className={cn("fa fa-fw fa-folder-open", styles.folderIcon)} />}
          </>
        ) : (
          <span style={{ fontSize: "20px" }} className={cn("material-icons", styles.folderIcon)}>
            folder_shared
          </span>
        )}

        {!renaming && <span className={styles.folderName}>{branch.itself.name}</span>}
        {renaming && (
          <input
            onBlur={() => endRenaming()}
            ref={renameInput}
            value={currentRenamingName}
            className={cn(input.input, input.small, styles.renameInput)}
            type="text"
            onChange={(e) => setCurrentRenamingName(e.target.value)}
            onKeyDown={(e: { keyCode: number }) => {
              if (e.keyCode === KeyCodes.Enter) endRenaming()
            }}
          />
        )}
        <div className={styles.hoverButtonsGroup}>
          <DirectoryOptionsMenu
            folderId={branch.itself.id}
            startRenaming={startRenaming}
            isRoot={branch.itself.parent === null}
            newFolder={() => setNamingNewFolder(true)}
          />
        </div>
      </div>
      {namingNewFolder && <TempNewFolder parent={branch} cancel={() => setNamingNewFolder(false)} />}

      {!!filesByMonth?.length && (
        <>
          {orderMode === "recent" && (
            <ExpandableRecentsList
              itemRenderer={(f, _) => (
                <div style={{ marginLeft: (branch.level + 1) * 25 }} key={f.id}>
                  <FileComp branch={branch} file={f} />
                </div>
              )}
              itemTypeName="files"
              items={filesByMonth}
              modifiedDate={true}
              showMoreLessLabel={(text, toggle) => {
                if (expandFolderItem) {
                  setTimeout(() => {
                    toggle()
                    setExpandFolderItem(false)
                  }, 300)
                }
                return (
                  <div
                    onClick={() => {
                      toggle()
                    }}
                  >
                    <div className={styles.loadAllButton} style={{ marginLeft: (branch.level + 2) * 24 }}>
                      <span>{text}</span>
                    </div>
                  </div>
                )
              }}
            />
          )}
          {orderMode !== "recent" && (
            <div>
              {filesByMonth.map((f: any) => {
                if (filesByMonth.length === 0) return <></>
                return (
                  <div style={{ marginLeft: (branch.level + 1) * 25 }} key={f.id}>
                    <FileComp branch={branch} file={f} />
                  </div>
                )
              })}
            </div>
          )}
        </>
      )}
      {!!branch?.folders?.length &&
        branch?.folders?.map((b) => <BranchComp key={b.itself.id} branch={b} handleClick={handleClick} />)}
    </div>
  )

  /**
   * Exclude irrelevant folders from the listing
   * Finding (Used) folders (irrespective of hierarchy, only the folder the file is in)
   * After render, if branch is not in usedFolders, remove it from the DOM
   */

  filesByMonth?.forEach((element: { parent: { name: any } }) => usedFolders.push(element.parent.name))
  let foldersWithFiles = usedFolders?.filter((v, i, a) => a.indexOf(v) === i)

  // Build back the path from each folder back to the root
  foldersWithFiles.forEach((folder: string) => {
    let folderPath = []

    const foundFolder = unfilteredFolders?.find((f: any) => f.name === folder)
    if (foundFolder) {
      let parentFolder = foundFolder.parent

      // Iterate through parents until root is found
      while (parentFolder) {
        if (parentFolder.id === "root") break // Stop at root
        folderPath.push(parentFolder.name)
        // Locate the next parent
        parentFolder = unfilteredFolders?.find((f: any) => f.id === parentFolder.id).parent
      }
    }
    usedFolders = usedFolders.concat(folderPath) // Add the path to the used folders
  })

  // Remove duplicate IDs
  usedFolders = usedFolders.filter((v, i, a) => a.findIndex((t: any) => (t.id || t) === (v.id || v)) === i)
  // Clean up array
  usedFolders = usedFolders.filter((v, i, a) => a.indexOf(v) === i)

  // Traversal via +/- folder icons
  if (modeType === "recent" && filterByBranch !== "root" && (rootCycle === 3 || folderCycle === 3))
    return connectDragSource(connectDropTarget(elem))
  if (folderCycle === 2 || folderCycle === 3) return connectDragSource(connectDropTarget(elem))

  // Upon folder additional click
  if (unfilteredFolders) {
    let filter = unfilteredFolders.filter((f: any) => {
      try {
        if (f.parent.id === filterByBranch) return f.id
      } catch (e) {}
    })
    allowListings = filter
  }
  allowListings = allowListings.map((folder) => folder.id)

  // Upon Root Directory additional click
  if (rootCycle === 2) {
    if (branch.level > 3) return <></>
    if (unfilteredFolders) {
      let filter = unfilteredFolders.filter((f: any) => {
        try {
          if (f.parent.id === "root") return f.id
        } catch (e) {}
      })
      rootListings = filter
    }
    rootListings = rootListings.map((folder) => folder.id)
  }
  // Show top level folders by default
  else if (extendedFolders.includes(branch?.itself?.parent?.id || "")) return connectDragSource(connectDropTarget(elem))
  else if (branch.level > 2 && !searchQuery && rootCycle === 1 && modeType === "all") return <></>
  // Show top level folders for recent mode
  else if (modeType === "recent" && filterByBranch !== "root" && branch.level > 2 && !searchQuery) return <></>
  else {
  }

  return connectDragSource(connectDropTarget(elem))
}

const TempNewFolder: React.FC<{ parent: Branch; cancel: () => void }> = ({ parent, cancel }) => {
  const { newFolder } = useContext(FileListingContext)
  const [newFolderName, setNewFolderName] = useState("New Folder")

  const handleNewFolder = () => {
    newFolder(parent.itself.id, newFolderName)
    cancel()
  }

  const newFolderText = useRef<HTMLInputElement>(null)

  useEffect(() => {
    newFolderText.current?.focus()
    newFolderText.current?.select()
  }, [])

  return (
    <div>
      <div className={styles.folderRow} key={parent.itself.id}>
        <i style={{ marginLeft: (parent.level + 1) * 25 }} />

        <i className={cn(styles.plusIcon, "fa fa-plus-square")} style={{ opacity: 0.1 }} />
        <i className={cn("fa fa-fw fa-folder", styles.folderIcon)} style={{ opacity: 0.5 }} />

        <input
          ref={newFolderText}
          onBlur={() => cancel()}
          value={newFolderName}
          className={cn(input.input, input.small, styles.renameInput)}
          type="text"
          onChange={(e) => setNewFolderName(e.target.value)}
          onKeyDown={(e: { keyCode: number }) => {
            if (e.keyCode === KeyCodes.Enter) handleNewFolder()
            if (e.keyCode === KeyCodes.Escape) cancel()
          }}
        />
      </div>
    </div>
  )
}

const FolderTreeView: React.FC<{
  files: File[]
  folders: Folder[]
}> = ({ files, folders }) => {
  const [tree, setTree] = useState<Branch>()
  const { expandFolder, loading } = useContext(FileListingContext)
  const [emptyCreateNewFolder, setEmptyCreateFolder] = useState(false)
  const [filters, setFilters] = useState<string[]>([])

  const { query } = useContext(FileListingContext)

  const root = folders.find((f) => !f.parent)
  const emptyFilesOnRoot = !root?.hasChildren
  const elementsContainerRef = useRef<HTMLDivElement | null>(null)
  const [selectedIndexes, setSelectedIndexes] = useState<number[]>([])
  const [selectedItems, setSelectedItems] = useState<any>({
    dataBranchIds: [],
    dataFileIds: []
  })
  useEffect(() => {
    const token = pubsub.subscribe(topics.DESELECT_FILES, () => {
      setSelectedItems({
        dataBranchIds: [],
        dataFileIds: []
      })
    })

    return () => {
      pubsub.unsubscribe(token)
    }
  }, [])

  useEffect(() => {
    setFilters([filterByBranch])
  }, [filterByBranch])

  useEffect(() => {
    if (elementsContainerRef.current) {
      try {
        elementsContainerRef.current.children[0].querySelectorAll(".selectableFile").forEach((item) => {
          const { left, top, width, height } = item.getBoundingClientRect()
        })
      } catch (err) {}
    }
  }, [elementsContainerRef.current !== null])
  useEffect(() => {
    if (elementsContainerRef.current) {
      if (selectedIndexes.length) {
        for (const index of selectedIndexes) {
          const selectedElement = elementsContainerRef.current.children[0].querySelectorAll(".selectableFile")[index]
          if (selectedElement) {
            const oldData = { ...selectedItems }
            selectedElement.getAttribute("data-bid") &&
              !oldData["dataBranchIds"].includes(selectedElement.getAttribute("data-bid")) &&
              oldData["dataBranchIds"].push(selectedElement.getAttribute("data-bid"))
            selectedElement.getAttribute("data-file-id") &&
              !oldData["dataFileIds"].includes(selectedElement.getAttribute("data-file-id")) &&
              oldData["dataFileIds"].push(selectedElement.getAttribute("data-file-id"))
            setSelectedItems({ ...oldData })
            selectedElement.setAttribute(
              "style",
              selectedElement.getAttribute("style") + " background: hsl(220, 70%, 97%);"
            )
          }
        }
      } else {
        setSelectedItems({
          dataBranchIds: [],
          dataFileIds: []
        })
        for (const childElement of elementsContainerRef?.current.children[0].querySelectorAll(".selectableFile")) {
          const previousElementStyle = childElement.getAttribute("style")
          if (previousElementStyle) {
            childElement.setAttribute("style", previousElementStyle.replaceAll(" background: hsl(220, 70%, 97%);", ""))
          }
        }
      }
    }
  }, [selectedIndexes.length])

  useEffect(() => {
    if (filters.length > 0) {
      // generate branches for selected folders
      let branches: Branch[] = filters.map((fid) => {
        const root = folders.find((f) => f.id === fid) || folders[0]
        return createBranch({ itself: root, folders: [], files: [], level: 1 })
      })

      if (filterByBranch === "root" && branches.length === 1) {
        const copiedOrgFolder = branches[0]?.folders?.filter((f) => f?.itself?.name?.startsWith("SHARED"))
        const bypassToRoot = {
          ...copiedOrgFolder[0],
          level: 1,
          itself: {
            ...copiedOrgFolder[0]?.itself,
            parent: {
              id: "root",
              name: "/",
              __typename: "Folder"
            }
          }
        } as Branch
        branches[0].folders = branches[0]?.folders?.filter((f) => !f?.itself?.name?.startsWith("SHARED"))
        branches?.unshift(bypassToRoot)
      }

      setTree({
        itself: {
          id: "root",
          name: "/root",
          hasChildren: true,
          lastAccess: null,
          parent: null
        },
        files: [],
        folders: branches,
        level: 0
      })
    } else {
      // no filters generate tree like normal
      const root = folders.find((f) => !f.parent)
      if (root) {
        const t = createBranch({ itself: root, folders: [], files: [], level: 0 })
        setTree(t)
      }
    }
  }, [files, folders, filters])

  if (!tree) {
    return null
  }
  function createBranch(current: Branch): Branch {
    const subFolders = folders.filter((f) => f.parent?.id === current.itself.id)
    try {
      current.files = files.filter((f) => f.parent?.id === current.itself.id)
    } catch (err) {
      current.files = files
    }

    current.folders = subFolders.map((s) =>
      createBranch({
        itself: s,
        files: [],
        folders: [],
        level: current.level + 1
      })
    )
    return current
  }

  if (!emptyFilesOnRoot)
    if (files.length === 0 && query) {
      return (
        <table className={cn(styles.fullWidth, styles.fade)}>
          <EmptyFilesWarning newFolder={() => setEmptyCreateFolder(true)} />
        </table>
      )
    } else {
      return (
        <>
          <div className={cn(styles.folderTable, styles.fade)} id="nice">
            {/* <RecentAccessFolders filters={filters} setFilters={setFilters} folders={folders} /> */}
            <div ref={elementsContainerRef}>
              <BranchComp branch={{ ...tree, selectedItems: selectedItems }} handleClick={expandFolder} />
            </div>
          </div>
        </>
      )
    }
  else if (!emptyCreateNewFolder)
    return loading ? null : (
      <table className={cn(styles.fullWidth, styles.fade)}>
        <EmptyFilesWarning mode="tree" newFolder={() => setEmptyCreateFolder(true)} />
      </table>
    )
  else
    return loading ? null : (
      <div className={cn(styles.folderTable, styles.fade)}>
        <div className={styles.folderRow}>
          <i className={cn(styles.plusIcon, "fa fa-minus-square")} />
          <i className={cn("fa fa-fw fa-folder-open", styles.folderIcon)} />

          <span className={styles.folderName}>/</span>
        </div>
        <TempNewFolder cancel={() => setEmptyCreateFolder(false)} parent={tree} />
      </div>
    )
}

export default FolderTreeView
