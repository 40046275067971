/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef } from "react"
import { Editor, EditorEvent } from "tinymce"
import pubsub from "pubsub-js"
import { isEnter, isEscape, refToFunc, replaceAt } from "utils/common"
import { v4 as uuid } from "uuid"

function getOffset(el: any) {
  const rect = el.getBoundingClientRect()
  return {
    left: rect.left + window.scrollX,
    top: rect.top + window.scrollY
  }
}

export let savedMessageField = ""
export const setSavedMessageField = (value: string): string => (savedMessageField = value)

export function useEnhancedEditor(
  handleEnter: (e: EditorEvent<KeyboardEvent>) => unknown,
  handleEscape: (e: EditorEvent<KeyboardEvent>) => unknown
): [(editor: Editor) => unknown] {
  const mentionType = refToFunc(useRef<string>("user"))
  const mentionStartedAt = refToFunc(useRef<number>(-1))
  const currentValue = refToFunc(useRef<string>("<p></p>"))
  const trackingIndex = refToFunc(useRef<number>(-1))

  function cancelMention() {
    mentionStartedAt(-1)
    trackingIndex(-1)
    currentValue("<p></p>")
  }

  const getTopic = (type: string): string => {
    switch (type) {
      case "user":
        return "userSelector"
      case "task":
        return "taskSelector"
      case "emoji":
        return "emojiSelector"
    }

    return "userSelector"
  }

  function ptus(func: string, val: any): void {
    pubsub.publish(`${getTopic(mentionType())}.${func}`, val)
  }

  function pd(e: any) {
    e.preventDefault()
    e.stopPropagation()
  }

  function stus(func: string, handler: (val: any) => unknown): string {
    return pubsub.subscribe("userSelector." + func, (_: any, val: any) => handler(val))
  }

  function sanitize(str: string) {
    return str.replaceAll("&nbsp;", " ")
  }

  function handleKeyDown(e: EditorEvent<KeyboardEvent>, editor: Editor) {
    const content = sanitize(editor.getContent())
    if (content) {
      currentValue(content)
    }
    if (mentionStartedAt() === -1) {
      if (isEnter(e)) {
        handleEnter(e)
      }

      if (isEscape(e) && handleEscape) {
        handleEscape(e)
      }
      return
    }
    if (isEnter(e)) {
      ptus("ctrlKey", e.key)
      pd(e)
    } else if ((e.key || e.code) === "ArrowUp" || (e.key || e.code) === "ArrowDown") {
      ptus("ctrlKey", e.key)
      pd(e)
    } else if (isEscape(e)) {
      ptus("ctrlKey", e.key)
      pd(e)
      if (handleEscape) {
        handleEscape(e)
      }
    }
  }

  function handleInput(_: EditorEvent<InputEvent>, editor: Editor) {
    const newValue = sanitize(editor.getContent())
    const current = currentValue() || ""

    const shown = mentionStartedAt() > -1
    const removed = current.length > newValue.length
    const added = current.length < newValue.length

    const difference = newValue.length - current.length

    // Yes, this is complicated. But I could not find a way around :|
    // Find the changed index and handle accordingly
    for (let i = 0, l = newValue.length; i < l; i++) {
      const val = current.length - 1 < i ? "" : current[i]
      const newVal = newValue.length - 1 < i ? "" : newValue[i]

      // the character in this index changed
      if (val !== newVal) {
        if (mentionStartedAt() > -1) {
          // If mentioning has started
          if (i < mentionStartedAt()) {
            if (removed) {
              // shift down
              mentionStartedAt(mentionStartedAt() - 1)
              if (trackingIndex() > -1) {
                trackingIndex(trackingIndex() + difference)
              }
            } else if (added) {
              // Shift one up
              mentionStartedAt(mentionStartedAt() + 1)
              if (trackingIndex() > -1) {
                trackingIndex(trackingIndex() + difference)
              }
            }
          } else if (i > mentionStartedAt()) {
            if (removed && i <= trackingIndex()) {
              trackingIndex(trackingIndex() + difference)
            } else if (added && i >= trackingIndex()) {
              trackingIndex(trackingIndex() + difference)
            }
          } else {
            cancelMention()
            ptus("hide", true)
          }
        } else {
          if (newVal === "@") {
            // @ for users
            mentionType("user")
            mentionStartedAt(i)
            trackingIndex(i)
          }
          if (newVal === "#") {
            // # for tasks (for now, later maybe other types of stuff)
            mentionType("task")
            mentionStartedAt(i)
            trackingIndex(i)
          }
          if (newVal === ":") {
            // : for emoji selector
            mentionType("emoji")
            mentionStartedAt(i)
            trackingIndex(i)
          }
        }
        break
      }
    }

    // show the user selector
    if (!shown && mentionStartedAt() > -1) {
      const tiny = getOffset(editor.getContainer())
      const selection = getOffset(editor.selection.getNode())

      const absPosition = {
        top: tiny.top + selection.top,
        left: tiny.left + selection.left
      }

      ptus("show", absPosition)
    }

    if (mentionStartedAt() > -1) {
      const text = newValue.substr(mentionStartedAt() + 1, trackingIndex() - mentionStartedAt())
      ptus("search", text)
    }
  }

  const selectUser = (user: { id: string; fullName: string }, editor: Editor) => {
    if (!editor || mentionStartedAt() < 0) {
      return
    }

    const tempId = uuid().replaceAll("-", "").substr(0, 5)

    const content = replaceAt(
      sanitize(editor.getContent()),
      mentionStartedAt(),
      trackingIndex(),
      `<a data-id="${user.id}" title="${user.fullName}" class="p-user-mention">@${user.fullName}</a>&nbsp;<span class="${tempId}" >U</span>`
    )

    editor.setContent(content)
    const elem = editor.getBody().getElementsByClassName(tempId)[0]
    editor.selection.select(elem)
    elem.remove()
  }

  const selectTask = (
    task: { id: string; idNumber: number; color: string; title: string; boardId: string },
    editor: Editor
  ) => {
    if (!editor || mentionStartedAt() < 0) {
      return
    }

    const tempId = uuid().replaceAll("-", "").substr(0, 5)

    const content = replaceAt(
      sanitize(editor.getContent()),
      mentionStartedAt(),
      trackingIndex(),
      `<a data-id="${task.idNumber}" data-board="${task.boardId}" title="${task.title}" style="background-color: ${
        task.color
      }; color: ${task.color === "#D3E8F1" ? "#015690" : "white"}; padding: 0.1rem 0.2rem" class="p-task-mention"><b>#${
        task.idNumber
      }</b>${task.idNumber ? " " : ""}${task.title}</a>&nbsp;<span class="${tempId}" >U</span>`
    )

    editor.setContent(content)
    const elem = editor.getBody().getElementsByClassName(tempId)[0]
    editor.selection.select(elem)
    elem.remove()
  }

  const selectEmoji = (emoji: { id: string; code: string; name: string; icon: string }, editor: Editor) => {
    if (!editor || mentionStartedAt() < 0) {
      return
    }

    const tempId = uuid().replaceAll("-", "").substr(0, 5)

    const content = replaceAt(
      sanitize(editor.getContent()),
      mentionStartedAt(),
      trackingIndex(),
      `<img class="p-emoji" style="transform: scale(1.4) translateY(3px); margin: 0 5px" src="${emoji.icon}" alt="${emoji.code}" width="17px" height="17px" />&nbsp;<span class="${tempId}" >U</span>`
    )

    editor.setContent(content)
    const elem = editor.getBody().getElementsByClassName(tempId)[0]
    editor.selection.select(elem)
    elem.remove()
  }

  const onSetup = (editor: Editor) => {
    if (!editor) {
      return
    }

    editor.on("keydown", (e) => handleKeyDown(e, editor))
    editor.on("input", (e) => handleInput(e, editor))

    const tokens: string[] = []
    tokens.push(
      stus("select", (data) => {
        if (mentionType() === "user") {
          selectUser(data, editor)
        }
        if (mentionType() === "task") {
          selectTask(data, editor)
        }
        if (mentionType() === "emoji") {
          selectEmoji(data, editor)
        }
        cancelMention()
      })
    )
    tokens.push(
      stus("closed", () => {
        cancelMention()
      })
    )
  }

  return [onSetup]
}
