import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A DateTime representation in ISO format */
  DateTime: any;
  /** The `Long` scalar type represents 52-bit integers */
  Long: any;
  /** Nothing */
  Void: any;
};


export type AllTaskResult = {
  __typename?: 'AllTaskResult';
  task: Task;
  boardIdStr: Scalars['String'];
};

export type AppSessionSession = {
  __typename?: 'AppSessionSession';
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  session: Session;
};

export type AppSpace = {
  __typename?: 'AppSpace';
  id: Scalars['ID'];
  name: Scalars['String'];
  type: Scalars['String'];
  createdDate?: Maybe<Scalars['DateTime']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  file?: Maybe<File>;
  meta?: Maybe<Scalars['String']>;
};

export type AppSpaceSettings = {
  __typename?: 'AppSpaceSettings';
  appSpaceLimitPerSession: Scalars['Int'];
};

export type AttachmentInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
  size: Scalars['Int'];
};

export type Bookmark = {
  __typename?: 'Bookmark';
  id: Scalars['ID'];
  url: Scalars['String'];
  title: Scalars['String'];
};

export type CountResult = {
  __typename?: 'CountResult';
  day: Scalars['String'];
  count: Scalars['Int'];
};

export type CreateFileResult = {
  __typename?: 'CreateFileResult';
  parent: Folder;
  file: File;
};

export type CreateNoteResult = {
  __typename?: 'CreateNoteResult';
  note?: Maybe<Note>;
  query?: Maybe<Query>;
};

export type CreateTaskBoardResult = {
  __typename?: 'CreateTaskBoardResult';
  created: TaskBoard;
  query: Query;
};

export type CreateTaskResult = {
  __typename?: 'CreateTaskResult';
  task: Task;
  query: Query;
};

export type CreateWikiResult = {
  __typename?: 'CreateWikiResult';
  created: Wiki;
  query: Query;
};


export type DeleteFolderResult = {
  __typename?: 'DeleteFolderResult';
  updatedFolders: Array<Folder>;
  deletedFiles: Array<Scalars['String']>;
  query: Query;
};

export type Emoticon = {
  __typename?: 'Emoticon';
  id: Scalars['ID'];
  code: Scalars['String'];
  icon: Scalars['String'];
  category: Scalars['String'];
  name: Scalars['String'];
};

export type File = {
  __typename?: 'File';
  id: Scalars['ID'];
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  parent?: Maybe<Folder>;
  createdDate?: Maybe<Scalars['DateTime']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
  size: Scalars['Int'];
  previewUrl: Scalars['String'];
  isConverted?: Maybe<Scalars['Boolean']>;
  master?: Maybe<File>;
  versions?: Maybe<Array<File>>;
  origin?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  readOnly?: Maybe<Scalars['Boolean']>;
  ancestors: Array<Scalars['ID']>;
  editors: Array<User>;
  wikiComments: Array<WikiComment>;
  wikiCommentCount: Scalars['Int'];
};

export type FileDownloadInfo = {
  __typename?: 'FileDownloadInfo';
  url: Scalars['String'];
};

export type FileListing = {
  __typename?: 'FileListing';
  id: Scalars['ID'];
  files: FilesListing;
  folders: FolderListing;
  type: Scalars['String'];
  count: Scalars['Int'];
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  orderMode: Scalars['String'];
  listingMode?: Maybe<Scalars['String']>;
  limitMode?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
};

export type FileUploadOutput = {
  __typename?: 'FileUploadOutput';
  uploadId: Scalars['String'];
  postData: Scalars['String'];
};

export type FilesListing = {
  __typename?: 'FilesListing';
  id: Scalars['ID'];
  files: Array<File>;
  count?: Maybe<Scalars['Int']>;
};

export type Folder = {
  __typename?: 'Folder';
  id: Scalars['ID'];
  name: Scalars['String'];
  parent?: Maybe<Folder>;
  createdDate: Scalars['DateTime'];
  lastAccess?: Maybe<Scalars['DateTime']>;
  files: Array<File>;
  folders: Array<Folder>;
  hasChildren?: Maybe<Scalars['Boolean']>;
  hasFiles?: Maybe<Scalars['Boolean']>;
};

export type FolderListing = {
  __typename?: 'FolderListing';
  id: Scalars['ID'];
  folders: Array<Folder>;
  count?: Maybe<Scalars['Int']>;
  expandedFolders: Array<Scalars['String']>;
};

export type Group = {
  __typename?: 'Group';
  type: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  unreadCount: Scalars['Int'];
  members: Array<GroupMember>;
  description?: Maybe<Scalars['String']>;
  canEdit: Scalars['Boolean'];
  otherMember?: Maybe<GroupMember>;
};

export type GroupMember = {
  __typename?: 'GroupMember';
  id: Scalars['String'];
  name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  role: Scalars['String'];
  profilePicture: Scalars['String'];
};

export type ImageUploadOutput = {
  __typename?: 'ImageUploadOutput';
  postData: Scalars['String'];
  getData: Scalars['String'];
};

export type JournalEntry = {
  __typename?: 'JournalEntry';
  id: Scalars['ID'];
  createdDate?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  date: Scalars['String'];
  file: File;
};

export type List = {
  __typename?: 'List';
  id: Scalars['ID'];
  index: Scalars['Int'];
  title: Scalars['String'];
  parent?: Maybe<List>;
  parentId?: Maybe<Scalars['ID']>;
  children?: Maybe<Array<List>>;
  properties?: Maybe<Array<ListProperty>>;
  hasChildren?: Maybe<Scalars['Boolean']>;
  isEditable?: Maybe<Scalars['Boolean']>;
  globalList?: Maybe<Scalars['Boolean']>;
};

export type ListEditorResult = {
  __typename?: 'ListEditorResult';
  lists: Array<List>;
};

export type ListProperty = {
  __typename?: 'ListProperty';
  list: List;
  key: Scalars['String'];
  value: Scalars['String'];
};


export type MakeChildTaskResult = {
  __typename?: 'MakeChildTaskResult';
  tasks: Array<Task>;
  query: Query;
};

export type Message = {
  __typename?: 'Message';
  id: Scalars['ID'];
  time: Scalars['DateTime'];
  type: Scalars['String'];
  editedAt?: Maybe<Scalars['DateTime']>;
  createdDate: Scalars['DateTime'];
  textContent?: Maybe<Scalars['String']>;
  searchTerm?: Maybe<Scalars['String']>;
  searchResult?: Maybe<SearchResult>;
  fileListing?: Maybe<FileListing>;
  images?: Maybe<Array<WebSearchResultItem>>;
  videos?: Maybe<Array<WebSearchResultItem>>;
  news?: Maybe<Array<WebSearchResultItem>>;
  user?: Maybe<User>;
  obsolete: Scalars['Boolean'];
  wiki?: Maybe<WikipediaResult>;
  bookmarks?: Maybe<Array<Bookmark>>;
  links?: Maybe<Array<WebLink>>;
  embed?: Maybe<Array<WebSearchResultItem>>;
  replyForQuery?: Maybe<Scalars['String']>;
  pdfs?: Maybe<Array<PdfResult>>;
  isUnread: Scalars['Boolean'];
  reactions: Array<MessageReaction>;
  attachments?: Maybe<Array<MessageAttachment>>;
};


export type MessageSearchResultArgs = {
  type: Scalars['String'];
  offset: Scalars['Int'];
  count: Scalars['Int'];
  query?: Maybe<Scalars['String']>;
};


export type MessageFileListingArgs = {
  type?: Maybe<Scalars['String']>;
  pageSize: Scalars['Int'];
  pageNumber: Scalars['Int'];
  expandedFolders?: Maybe<Array<Scalars['String']>>;
  orderMode?: Maybe<Scalars['String']>;
  listingMode?: Maybe<Scalars['String']>;
  limitMode?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
};


export type MessageImagesArgs = {
  count: Scalars['Int'];
};


export type MessageVideosArgs = {
  count: Scalars['Int'];
};


export type MessageNewsArgs = {
  count: Scalars['Int'];
};


export type MessageWikiArgs = {
  query: Scalars['String'];
};

export type MessageAttachment = {
  __typename?: 'MessageAttachment';
  id: Scalars['ID'];
  type: Scalars['String'];
  name: Scalars['String'];
  size: Scalars['Int'];
  url: Scalars['String'];
};

export type MessageReaction = {
  __typename?: 'MessageReaction';
  id: Scalars['ID'];
  value: Scalars['String'];
  icon: Scalars['String'];
  reactors: Array<Scalars['String']>;
  name: Scalars['String'];
};

export type MoveFileToFolderResult = {
  __typename?: 'MoveFileToFolderResult';
  updatedFolders: Array<Folder>;
  updatedFiles: Array<File>;
};

export type MoveFolderToFolderResult = {
  __typename?: 'MoveFolderToFolderResult';
  updatedFolders: Array<Folder>;
};

export type MoveTaskResult = {
  __typename?: 'MoveTaskResult';
  task: Task;
  updatedTasks: Array<Task>;
  query: Query;
};

export type MoveTaskToTaskResult = {
  __typename?: 'MoveTaskToTaskResult';
  updatedTasks: Array<Task>;
  query: Query;
};

export type Mutation = {
  __typename?: 'Mutation';
  signUp?: Maybe<Scalars['Void']>;
  signin?: Maybe<Scalars['Void']>;
  signOut?: Maybe<Scalars['Void']>;
  selectSession: Query;
  createSession: Query;
  closeSession: Query;
  renameSession: Session;
  reopenSession: Query;
  deleteSession: Query;
  selectAppSpace: Query;
  createAppSpace: Query;
  renameAppSpace: AppSpace;
  deleteAppSpace: Query;
  createAppSpaceFromUrl: Query;
  createAppSpaceFromImage: Query;
  createAppSpaceFromContent: Query;
  grabFileAsAppSpace: Query;
  closeAppSpace: Query;
  openAppSpace: Query;
  chat?: Maybe<Message>;
  editMessage: Message;
  deleteMessage?: Maybe<Scalars['Void']>;
  createGroup: Query;
  selectGroup: Query;
  addGroupMembers: Group;
  updateGroup: Group;
  reportUserAction?: Maybe<Group>;
  leaveGroup: Query;
  toggleMessageReaction: Message;
  createBookmark?: Maybe<Bookmark>;
  updateBookmark?: Maybe<Bookmark>;
  deleteBookmark?: Maybe<Scalars['Void']>;
  createList?: Maybe<List>;
  updateListTitle?: Maybe<Scalars['Void']>;
  deleteList?: Maybe<Scalars['Void']>;
  indentList?: Maybe<Scalars['Void']>;
  unindentList?: Maybe<Scalars['Void']>;
  renameOrganization: Organization;
  addMemberToOrganization: Organization;
  removeMemberFromOrganization: Organization;
  createOrganization: Array<Organization>;
  selectOrganization: Query;
  createSpace: Organization;
  renameSpace: Space;
  addTeamToSpace: Space;
  removeTeamFromSpace: Space;
  removeSpace: Organization;
  createTeam: Organization;
  renameTeam: Team;
  addMemberToTeam: Team;
  removeMemberFromTeam: Team;
  removeTeam: Organization;
  selectSpace: Query;
  uploadOrganizationProfilePicture: Scalars['String'];
  uploadProfilePicture: Scalars['String'];
  updateProfile?: Maybe<Scalars['Void']>;
  updateSkinTonePreference: Query;
  registerForNotifications?: Maybe<Scalars['Boolean']>;
  markNotificationClicked: Query;
  uploadTempFile?: Maybe<FileUploadOutput>;
  markTaskComplete?: Maybe<Scalars['Void']>;
  makeChildTask: MakeChildTaskResult;
  removeTaskParent: RemoveTaskParentResult;
  toggleTaskAssignee: Task;
  toggleTaskCommentReaction: Task;
  createTaskComment: Task;
  createTask: CreateTaskResult;
  createTaskBoard: CreateTaskBoardResult;
  updateTask: Task;
  createTaskState: TaskBoard;
  updateTaskState?: Maybe<TaskState>;
  updateTaskBoard: TaskBoard;
  moveTaskToState: MoveTaskResult;
  updateTaskDescription?: Maybe<Task>;
  reorderTask: Array<Task>;
  archiveTask: Query;
  getTaskId: Scalars['ID'];
  toggleCompletedState: Query;
  archiveTaskState: Query;
  reorderTaskState: Array<TaskState>;
  updateTaskBoardName: TaskBoard;
  deleteTaskComment: Task;
  updateTaskComment?: Maybe<UpdateTaskCommentResult>;
  removeTaskAttachment: Task;
  moveTaskToTask: MoveTaskToTaskResult;
  updateFileContent: File;
  uploadImage?: Maybe<ImageUploadOutput>;
  uploadFile?: Maybe<FileUploadOutput>;
  upgradeDocument: File;
  exportFile: Query;
  updateReadOnly?: Maybe<File>;
  finishUploadExportedFile: Query;
  moveFolderToFolder: MoveFolderToFolderResult;
  moveFileToFolder: MoveFileToFolderResult;
  renameFolder?: Maybe<Folder>;
  deleteFolder: DeleteFolderResult;
  createFolder: Folder;
  finishFileUpload: Query;
  openFile: Query;
  renameFile?: Maybe<RenameFileResult>;
  deleteFile: Folder;
  createFile: CreateFileResult;
  reportUserFileAction?: Maybe<Scalars['Void']>;
  finishUploadImage: File;
  createWikiPageComment: File;
  deleteWikiPageComment: File;
  updateWikiPageComment?: Maybe<UpdateWikiCommentResult>;
  removeWikiPageAttachment?: Maybe<WikiComment>;
  toggleWikiPageCommentReaction: WikiComment;
  createWiki: CreateWikiResult;
  updateWiki: Wiki;
  updateWikiName: Wiki;
  createNote: CreateNoteResult;
  renameNote: Note;
  createNoteLabel: Query;
  toggleNoteLabel: Note;
  deleteNote?: Maybe<Scalars['Void']>;
  createJournalEntry?: Maybe<Scalars['Void']>;
  renameJournalEntry: JournalEntry;
  deleteJournalEntry?: Maybe<Scalars['Void']>;
  clickNotification: NotificationQuery;
};


export type MutationSignUpArgs = {
  fullName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  guest: Scalars['Boolean'];
};


export type MutationSigninArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationSelectSessionArgs = {
  id: Scalars['ID'];
};


export type MutationCreateSessionArgs = {
  name?: Maybe<Scalars['String']>;
};


export type MutationCloseSessionArgs = {
  id: Scalars['ID'];
};


export type MutationRenameSessionArgs = {
  sessionId: Scalars['ID'];
  newName: Scalars['String'];
};


export type MutationReopenSessionArgs = {
  sessionId: Scalars['ID'];
};


export type MutationDeleteSessionArgs = {
  sessionId: Scalars['ID'];
};


export type MutationSelectAppSpaceArgs = {
  id: Scalars['ID'];
};


export type MutationCreateAppSpaceArgs = {
  type: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  folderId?: Maybe<Scalars['String']>;
};


export type MutationRenameAppSpaceArgs = {
  id?: Maybe<Scalars['ID']>;
  newName: Scalars['String'];
};


export type MutationDeleteAppSpaceArgs = {
  id: Scalars['ID'];
};


export type MutationCreateAppSpaceFromUrlArgs = {
  url: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};


export type MutationCreateAppSpaceFromImageArgs = {
  url: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};


export type MutationCreateAppSpaceFromContentArgs = {
  from: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};


export type MutationGrabFileAsAppSpaceArgs = {
  name: Scalars['String'];
  type: Scalars['String'];
  url: Scalars['String'];
};


export type MutationCloseAppSpaceArgs = {
  appSpaceId: Scalars['ID'];
};


export type MutationOpenAppSpaceArgs = {
  type: Scalars['String'];
  name: Scalars['String'];
  meta: Scalars['String'];
};


export type MutationChatArgs = {
  message: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  silent?: Maybe<Scalars['Boolean']>;
  richtext?: Maybe<Scalars['Boolean']>;
  attachments?: Maybe<Array<AttachmentInput>>;
};


export type MutationEditMessageArgs = {
  id: Scalars['ID'];
  content: Scalars['String'];
};


export type MutationDeleteMessageArgs = {
  id: Scalars['String'];
};


export type MutationCreateGroupArgs = {
  type: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  members: Array<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
};


export type MutationSelectGroupArgs = {
  id: Scalars['ID'];
};


export type MutationAddGroupMembersArgs = {
  group: Scalars['ID'];
  members: Array<Scalars['ID']>;
};


export type MutationUpdateGroupArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};


export type MutationReportUserActionArgs = {
  group: Scalars['ID'];
  type?: Maybe<Scalars['String']>;
  values: Array<Maybe<Scalars['String']>>;
};


export type MutationLeaveGroupArgs = {
  id: Scalars['ID'];
};


export type MutationToggleMessageReactionArgs = {
  messageId: Scalars['ID'];
  value: Scalars['String'];
};


export type MutationCreateBookmarkArgs = {
  url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};


export type MutationUpdateBookmarkArgs = {
  bookmarkId: Scalars['ID'];
  url: Scalars['String'];
  title: Scalars['String'];
};


export type MutationDeleteBookmarkArgs = {
  bookmarkId: Scalars['ID'];
};


export type MutationCreateListArgs = {
  parentId: Scalars['String'];
  name: Scalars['String'];
};


export type MutationUpdateListTitleArgs = {
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};


export type MutationDeleteListArgs = {
  id: Scalars['String'];
};


export type MutationIndentListArgs = {
  childId: Scalars['String'];
  parentId: Scalars['String'];
};


export type MutationUnindentListArgs = {
  childId: Scalars['String'];
  parentId: Scalars['String'];
};


export type MutationRenameOrganizationArgs = {
  name: Scalars['String'];
};


export type MutationAddMemberToOrganizationArgs = {
  userEmail: Scalars['String'];
};


export type MutationRemoveMemberFromOrganizationArgs = {
  id: Scalars['ID'];
};


export type MutationCreateOrganizationArgs = {
  name: Scalars['String'];
};


export type MutationSelectOrganizationArgs = {
  id: Scalars['ID'];
};


export type MutationCreateSpaceArgs = {
  name: Scalars['String'];
};


export type MutationRenameSpaceArgs = {
  id: Scalars['ID'];
  newName: Scalars['String'];
};


export type MutationAddTeamToSpaceArgs = {
  id: Scalars['ID'];
  teamId: Scalars['ID'];
};


export type MutationRemoveTeamFromSpaceArgs = {
  id: Scalars['ID'];
  teamId: Scalars['ID'];
};


export type MutationRemoveSpaceArgs = {
  id: Scalars['ID'];
};


export type MutationCreateTeamArgs = {
  name: Scalars['String'];
};


export type MutationRenameTeamArgs = {
  id: Scalars['ID'];
  newName: Scalars['String'];
};


export type MutationAddMemberToTeamArgs = {
  id: Scalars['ID'];
  memberId: Scalars['ID'];
};


export type MutationRemoveMemberFromTeamArgs = {
  id: Scalars['ID'];
  memberId: Scalars['ID'];
};


export type MutationRemoveTeamArgs = {
  id: Scalars['ID'];
};


export type MutationSelectSpaceArgs = {
  id: Scalars['ID'];
};


export type MutationUploadOrganizationProfilePictureArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateProfileArgs = {
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};


export type MutationUpdateSkinTonePreferenceArgs = {
  tone: Scalars['Int'];
};


export type MutationRegisterForNotificationsArgs = {
  subObj: Scalars['String'];
};


export type MutationMarkNotificationClickedArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationUploadTempFileArgs = {
  fileName: Scalars['String'];
  fileType: Scalars['String'];
  fileSize: Scalars['Int'];
};


export type MutationMarkTaskCompleteArgs = {
  taskId: Scalars['ID'];
};


export type MutationMakeChildTaskArgs = {
  taskId: Scalars['ID'];
  parentId: Scalars['ID'];
};


export type MutationRemoveTaskParentArgs = {
  taskId: Scalars['ID'];
};


export type MutationToggleTaskAssigneeArgs = {
  taskId: Scalars['ID'];
  assigneeId: Scalars['ID'];
};


export type MutationToggleTaskCommentReactionArgs = {
  commentId: Scalars['ID'];
  value: Scalars['String'];
};


export type MutationCreateTaskCommentArgs = {
  taskId: Scalars['ID'];
  content?: Maybe<Scalars['String']>;
  attachments?: Maybe<Array<AttachmentInput>>;
};


export type MutationCreateTaskArgs = {
  stateId: Scalars['ID'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['ID']>;
};


export type MutationCreateTaskBoardArgs = {
  name: Scalars['String'];
  description: Scalars['String'];
  states: Array<Maybe<TaskStateInput>>;
  teams: Array<Scalars['ID']>;
  users: Array<Scalars['ID']>;
};


export type MutationUpdateTaskArgs = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  attachments?: Maybe<Array<AttachmentInput>>;
  hours?: Maybe<Scalars['Int']>;
};


export type MutationCreateTaskStateArgs = {
  boardId: Scalars['ID'];
  name: Scalars['String'];
  color: Scalars['String'];
};


export type MutationUpdateTaskStateArgs = {
  stateId: Scalars['ID'];
  name: Scalars['String'];
  color: Scalars['String'];
};


export type MutationUpdateTaskBoardArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  states: Array<Maybe<TaskStateInput>>;
  teams: Array<Scalars['ID']>;
  users: Array<Scalars['ID']>;
};


export type MutationMoveTaskToStateArgs = {
  taskId: Scalars['ID'];
  stateId: Scalars['ID'];
  toTask?: Maybe<Scalars['ID']>;
};


export type MutationUpdateTaskDescriptionArgs = {
  taskId: Scalars['ID'];
  description: Scalars['ID'];
};


export type MutationReorderTaskArgs = {
  taskId: Scalars['ID'];
  toTask: Scalars['ID'];
  withinParent: Scalars['Boolean'];
};


export type MutationArchiveTaskArgs = {
  taskId: Scalars['ID'];
};


export type MutationGetTaskIdArgs = {
  idNumber: Scalars['String'];
  boardId: Scalars['ID'];
};


export type MutationToggleCompletedStateArgs = {
  stateId: Scalars['ID'];
};


export type MutationArchiveTaskStateArgs = {
  stateId: Scalars['ID'];
};


export type MutationReorderTaskStateArgs = {
  stateId: Scalars['ID'];
  toState: Scalars['ID'];
};


export type MutationUpdateTaskBoardNameArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationDeleteTaskCommentArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateTaskCommentArgs = {
  id: Scalars['ID'];
  content?: Maybe<Scalars['String']>;
  attachments?: Maybe<Array<AttachmentInput>>;
};


export type MutationRemoveTaskAttachmentArgs = {
  attachmentId: Scalars['ID'];
};


export type MutationMoveTaskToTaskArgs = {
  from: Scalars['ID'];
  to: Scalars['ID'];
  mode: Scalars['String'];
};


export type MutationUpdateFileContentArgs = {
  id: Scalars['ID'];
  content: Scalars['String'];
};


export type MutationUploadImageArgs = {
  filename?: Maybe<Scalars['String']>;
  fileType: Scalars['String'];
};


export type MutationUploadFileArgs = {
  fileName: Scalars['String'];
  fileType: Scalars['String'];
  fileSize: Scalars['Int'];
  folder?: Maybe<Scalars['String']>;
};


export type MutationUpgradeDocumentArgs = {
  id: Scalars['String'];
  upgrade: Scalars['Boolean'];
};


export type MutationExportFileArgs = {
  id: Scalars['String'];
  content: Scalars['String'];
  targetType: Scalars['String'];
};


export type MutationUpdateReadOnlyArgs = {
  id: Scalars['String'];
  readOnly: Scalars['Boolean'];
  html?: Maybe<Scalars['String']>;
};


export type MutationFinishUploadExportedFileArgs = {
  uploadId: Scalars['String'];
  masterId: Scalars['String'];
};


export type MutationMoveFolderToFolderArgs = {
  folder: Scalars['ID'];
  toFolder: Scalars['ID'];
};


export type MutationMoveFileToFolderArgs = {
  file: Scalars['ID'];
  toFolder: Scalars['ID'];
};


export type MutationRenameFolderArgs = {
  id: Scalars['ID'];
  newName: Scalars['String'];
};


export type MutationDeleteFolderArgs = {
  id: Scalars['ID'];
};


export type MutationCreateFolderArgs = {
  parentId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  wikiId?: Maybe<Scalars['ID']>;
};


export type MutationFinishFileUploadArgs = {
  uploadId: Scalars['String'];
};


export type MutationOpenFileArgs = {
  fileId: Scalars['ID'];
};


export type MutationRenameFileArgs = {
  fileId: Scalars['ID'];
  newName: Scalars['String'];
};


export type MutationDeleteFileArgs = {
  fileId: Scalars['ID'];
};


export type MutationCreateFileArgs = {
  parentId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  type: Scalars['String'];
  wikiId?: Maybe<Scalars['ID']>;
};


export type MutationReportUserFileActionArgs = {
  fileId: Scalars['ID'];
  type: Scalars['String'];
};


export type MutationFinishUploadImageArgs = {
  uploadId: Scalars['String'];
  name: Scalars['String'];
  size: Scalars['Int'];
};


export type MutationCreateWikiPageCommentArgs = {
  wikiId: Scalars['ID'];
  fileId: Scalars['ID'];
  content?: Maybe<Scalars['String']>;
  attachments?: Maybe<Array<AttachmentInput>>;
};


export type MutationDeleteWikiPageCommentArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateWikiPageCommentArgs = {
  id: Scalars['ID'];
  content?: Maybe<Scalars['String']>;
  attachments?: Maybe<Array<AttachmentInput>>;
};


export type MutationRemoveWikiPageAttachmentArgs = {
  attachmentId: Scalars['ID'];
};


export type MutationToggleWikiPageCommentReactionArgs = {
  commentId: Scalars['ID'];
  value: Scalars['String'];
};


export type MutationCreateWikiArgs = {
  name: Scalars['String'];
  description: Scalars['String'];
  teams: Array<Scalars['ID']>;
  users: Array<Scalars['ID']>;
};


export type MutationUpdateWikiArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  teams: Array<Scalars['ID']>;
  users: Array<Scalars['ID']>;
};


export type MutationUpdateWikiNameArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationCreateNoteArgs = {
  content: Scalars['String'];
  labels: Array<Scalars['ID']>;
  title: Scalars['String'];
};


export type MutationRenameNoteArgs = {
  id: Scalars['ID'];
  title: Scalars['String'];
};


export type MutationCreateNoteLabelArgs = {
  name: Scalars['String'];
  color: Scalars['String'];
};


export type MutationToggleNoteLabelArgs = {
  noteId: Scalars['ID'];
  labelId: Scalars['ID'];
};


export type MutationDeleteNoteArgs = {
  noteId: Scalars['ID'];
};


export type MutationCreateJournalEntryArgs = {
  title: Scalars['String'];
  date: Scalars['String'];
};


export type MutationRenameJournalEntryArgs = {
  id: Scalars['ID'];
  title: Scalars['String'];
};


export type MutationDeleteJournalEntryArgs = {
  id: Scalars['ID'];
};


export type MutationClickNotificationArgs = {
  id: Scalars['ID'];
};

export type Note = {
  __typename?: 'Note';
  id: Scalars['ID'];
  title: Scalars['String'];
  createdDate: Scalars['DateTime'];
  file: File;
  labels: Array<NoteLabel>;
};

export type NoteLabel = {
  __typename?: 'NoteLabel';
  id: Scalars['ID'];
  name: Scalars['String'];
  color: Scalars['String'];
};

export type Notification = {
  __typename?: 'Notification';
  id: Scalars['ID'];
  relatedUser?: Maybe<User>;
  title: Scalars['String'];
  message: Scalars['String'];
  link: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  clickedDate?: Maybe<Scalars['DateTime']>;
  closedDate?: Maybe<Scalars['DateTime']>;
  createdDate: Scalars['DateTime'];
  isRead: Scalars['Boolean'];
  task?: Maybe<Task>;
  taskboard?: Maybe<TaskBoard>;
};

export type NotificationQuery = {
  __typename?: 'NotificationQuery';
  notification: Notification;
  query: Query;
};

export type Organization = {
  __typename?: 'Organization';
  id: Scalars['ID'];
  name: Scalars['String'];
  role: Scalars['String'];
  members: Array<OrganizationMember>;
  spaces: Array<Space>;
  teams: Array<Team>;
  memberCount: Scalars['Int'];
  profilePicture: Scalars['String'];
};


export type OrganizationMembersArgs = {
  searchText?: Maybe<Scalars['String']>;
  skipGroupId?: Maybe<Scalars['ID']>;
  includePretzel?: Maybe<Scalars['Boolean']>;
};


export type OrganizationTeamsArgs = {
  searchText?: Maybe<Scalars['String']>;
};

export type OrganizationMember = {
  __typename?: 'OrganizationMember';
  user: User;
  role: Scalars['String'];
  addedAt: Scalars['DateTime'];
  id: Scalars['ID'];
};

export type PdfResult = {
  __typename?: 'PDFResult';
  id: Scalars['ID'];
  local: Scalars['Boolean'];
  appSpaceId?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  name: Scalars['String'];
  thumbnail: Scalars['String'];
  snippet?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
  language?: Maybe<Scalars['String']>;
};

export type Paragraph = {
  __typename?: 'Paragraph';
  sentences?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Profile = {
  __typename?: 'Profile';
  id: Scalars['String'];
  name: Scalars['String'];
  email: Scalars['String'];
  groups?: Maybe<Array<Maybe<Group>>>;
  role?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  isCurrentUser: Scalars['Boolean'];
  profilePicture: Scalars['String'];
  lastSeen?: Maybe<Scalars['DateTime']>;
};

export type Query = {
  __typename?: 'Query';
  me?: Maybe<User>;
  sessionSettings: SessionSettings;
  sessions: Array<AppSessionSession>;
  session?: Maybe<Session>;
  otherSessions: Array<Session>;
  activeSession: Session;
  allSessions: Array<Session>;
  appSpaces: Array<SessionAppSpace>;
  activeAppSpace?: Maybe<AppSpace>;
  appSpaceSettings: AppSpaceSettings;
  appSpace?: Maybe<AppSpace>;
  appSpaceKind: AppSpace;
  unreadMessages: Array<UnreadThreadResult>;
  message?: Maybe<Message>;
  groups: Array<Group>;
  group?: Maybe<Group>;
  potentialGroupMembers: Array<User>;
  user?: Maybe<User>;
  bookmarks: Array<Bookmark>;
  bookmark?: Maybe<Bookmark>;
  list: ListEditorResult;
  getGlobalList?: Maybe<List>;
  allLists?: Maybe<Array<List>>;
  organization: Organization;
  organizations: Array<Organization>;
  activeSpace?: Maybe<Space>;
  space: Space;
  team: Team;
  profile?: Maybe<Profile>;
  emoticons: Array<Emoticon>;
  unreadComments: Array<UnreadComment>;
  recentTasksToDo: Array<RecentTaskToDoResult>;
  allTasks: Array<AllTaskResult>;
  taskBoards: Array<TaskBoard>;
  taskBoard: TaskBoard;
  task: Task;
  taskState: TaskState;
  downloadFile: FileDownloadInfo;
  folder?: Maybe<Folder>;
  file?: Maybe<File>;
  recentWikiPages: Array<RecentWikiPageResult>;
  wikies: Array<Wiki>;
  wikiPage: File;
  wiki: Wiki;
  notes: Array<Note>;
  noteLabels: Array<Maybe<NoteLabel>>;
  journal: JournalEntry;
  journalEntries: Array<JournalEntry>;
  journalCountForDays: Array<CountResult>;
  unreadNotifications: Scalars['Int'];
  notifications: Array<Notification>;
};


export type QuerySessionArgs = {
  id: Scalars['ID'];
};


export type QueryAppSpaceArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryAppSpaceKindArgs = {
  id: Scalars['String'];
};


export type QueryMessageArgs = {
  id: Scalars['ID'];
};


export type QueryGroupArgs = {
  id: Scalars['ID'];
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryBookmarkArgs = {
  id: Scalars['ID'];
};


export type QueryListArgs = {
  expandedLists: Array<Scalars['String']>;
};


export type QueryGetGlobalListArgs = {
  name?: Maybe<Scalars['String']>;
};


export type QuerySpaceArgs = {
  id: Scalars['ID'];
};


export type QueryTeamArgs = {
  id: Scalars['ID'];
};


export type QueryProfileArgs = {
  id: Scalars['ID'];
};


export type QueryTaskBoardsArgs = {
  searchText?: Maybe<Scalars['String']>;
};


export type QueryTaskBoardArgs = {
  idStr?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};


export type QueryTaskArgs = {
  id?: Maybe<Scalars['ID']>;
  idNumber?: Maybe<Scalars['Int']>;
  boardId?: Maybe<Scalars['ID']>;
};


export type QueryTaskStateArgs = {
  id: Scalars['ID'];
};


export type QueryDownloadFileArgs = {
  id: Scalars['ID'];
};


export type QueryFolderArgs = {
  id: Scalars['ID'];
};


export type QueryFileArgs = {
  id: Scalars['ID'];
};


export type QueryWikiesArgs = {
  searchText?: Maybe<Scalars['String']>;
};


export type QueryWikiPageArgs = {
  id: Scalars['ID'];
};


export type QueryWikiArgs = {
  idStr?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};


export type QueryNotesArgs = {
  offset: Scalars['Int'];
  take: Scalars['Int'];
  label?: Maybe<Scalars['ID']>;
};


export type QueryJournalArgs = {
  date: Scalars['String'];
};


export type QueryJournalEntriesArgs = {
  date: Scalars['String'];
};


export type QueryJournalCountForDaysArgs = {
  days: Array<Maybe<Scalars['String']>>;
};


export type QueryNotificationsArgs = {
  take: Scalars['Int'];
  skip: Scalars['Int'];
};

export type RecentTaskToDoResult = {
  __typename?: 'RecentTaskToDoResult';
  task: Task;
  board: TaskBoard;
};

export type RecentWikiPageResult = {
  __typename?: 'RecentWikiPageResult';
  wikiPage: File;
  wiki: Wiki;
};

export type RemoveTaskParentResult = {
  __typename?: 'RemoveTaskParentResult';
  parent: Task;
  child: Task;
};

export type RenameFileResult = {
  __typename?: 'RenameFileResult';
  file: File;
  appSpaces: Array<AppSpace>;
};

export type SearchResult = {
  __typename?: 'SearchResult';
  query?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<WebSearchResultItem>>>;
  videos?: Maybe<Array<Maybe<WebSearchResultItem>>>;
  news?: Maybe<Array<Maybe<WebSearchResultItem>>>;
  web?: Maybe<WebSearchResult>;
  offset: Scalars['Int'];
  count: Scalars['Int'];
  type: Scalars['String'];
  totalCount: Scalars['Long'];
};

export type SearchResultFile = {
  __typename?: 'SearchResultFile';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  dir?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
};

export type Session = {
  __typename?: 'Session';
  id: Scalars['ID'];
  name: Scalars['String'];
  createdDate: Scalars['DateTime'];
  appSpaces: Array<SessionAppSpace>;
  isActive: Scalars['Boolean'];
  isClosed: Scalars['Boolean'];
  startMessageId?: Maybe<Scalars['String']>;
  chatHistory: Array<Message>;
  groupId?: Maybe<Scalars['ID']>;
  group?: Maybe<Group>;
};


export type SessionChatHistoryArgs = {
  offset: Scalars['Int'];
};

export type SessionAppSpace = {
  __typename?: 'SessionAppSpace';
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  appSpace: AppSpace;
};

export type SessionSettings = {
  __typename?: 'SessionSettings';
  maximumSessionCount: Scalars['Int'];
};

export type Space = {
  __typename?: 'Space';
  id: Scalars['ID'];
  name: Scalars['String'];
  teams: Array<Team>;
};

export type Subscription = {
  __typename?: 'Subscription';
  messageReceived: Message;
  userChatStatusChanged?: Maybe<UserChatStatus>;
  groupUpdated: Group;
  messageUpdated: Message;
  unreadComments: Query;
  taskBoardUpdated: TaskBoard;
  taskStatesUpdated: Array<TaskState>;
  taskUpdated: Array<Task>;
  taskDetailsUpdated: Task;
  fileEditors: File;
  fileContent: Scalars['String'];
  folderContent: Folder;
  wikiUpdated: Wiki;
  wikiPageCommentsUpdated: File;
  notifications?: Maybe<Notification>;
};


export type SubscriptionMessageReceivedArgs = {
  session: Scalars['ID'];
};


export type SubscriptionUserChatStatusChangedArgs = {
  session: Scalars['ID'];
};


export type SubscriptionGroupUpdatedArgs = {
  session: Scalars['ID'];
};


export type SubscriptionMessageUpdatedArgs = {
  session: Scalars['ID'];
};


export type SubscriptionUnreadCommentsArgs = {
  session: Scalars['ID'];
};


export type SubscriptionTaskBoardUpdatedArgs = {
  sessionId: Scalars['ID'];
  boardId: Scalars['ID'];
};


export type SubscriptionTaskStatesUpdatedArgs = {
  sessionId: Scalars['ID'];
  boardId: Scalars['ID'];
};


export type SubscriptionTaskUpdatedArgs = {
  sessionId: Scalars['ID'];
  boardId: Scalars['ID'];
};


export type SubscriptionTaskDetailsUpdatedArgs = {
  sessionId: Scalars['ID'];
  taskId: Scalars['ID'];
};


export type SubscriptionFileEditorsArgs = {
  session: Scalars['ID'];
  fileId: Scalars['ID'];
};


export type SubscriptionFileContentArgs = {
  session: Scalars['ID'];
  fileId: Scalars['ID'];
};


export type SubscriptionFolderContentArgs = {
  session: Scalars['ID'];
  folderId: Scalars['ID'];
};


export type SubscriptionWikiUpdatedArgs = {
  sessionId: Scalars['ID'];
  wikiId: Scalars['ID'];
};


export type SubscriptionWikiPageCommentsUpdatedArgs = {
  fileId: Scalars['ID'];
  sessionId: Scalars['ID'];
};

export type Task = {
  __typename?: 'Task';
  id: Scalars['ID'];
  title: Scalars['String'];
  description: Scalars['String'];
  creator: User;
  progress: TaskProgress;
  boardId: Scalars['ID'];
  commentCount: Scalars['Int'];
  attachmentCount: Scalars['Int'];
  idNumber: Scalars['Int'];
  comments: Array<TaskComment>;
  attachments: Array<TaskAttachment>;
  assignees: Array<User>;
  state: TaskState;
  createdDate: Scalars['DateTime'];
  children: Array<Task>;
  hasParent: Scalars['Boolean'];
  stateOrder: Scalars['Int'];
  parentOrder: Scalars['Int'];
  isCompleted: Scalars['Boolean'];
  breadcrumbs: Array<TaskBreadcrumb>;
  isArchived: Scalars['Boolean'];
  log: Array<TaskLog>;
  hours?: Maybe<Scalars['Int']>;
};

export type TaskAttachment = {
  __typename?: 'TaskAttachment';
  id: Scalars['ID'];
  type: Scalars['String'];
  name: Scalars['String'];
  size: Scalars['Int'];
  commentId?: Maybe<Scalars['ID']>;
  url: Scalars['String'];
  canRemove: Scalars['Boolean'];
};

export type TaskBoard = {
  __typename?: 'TaskBoard';
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  states: Array<TaskState>;
  teams: Array<Team>;
  users: Array<User>;
  allUsers: Array<User>;
  isStarred: Scalars['Boolean'];
  idStr: Scalars['String'];
  canEdit: Scalars['Boolean'];
  rootTasks: Array<Task>;
  archivedTasks: Array<Task>;
};

export type TaskBreadcrumb = {
  __typename?: 'TaskBreadcrumb';
  id: Scalars['ID'];
  title: Scalars['String'];
  idNumber: Scalars['Int'];
  state: TaskState;
};

export type TaskComment = {
  __typename?: 'TaskComment';
  id: Scalars['ID'];
  content: Scalars['String'];
  commentor: User;
  reactions: Array<TaskCommentReaction>;
  attachments: Array<TaskAttachment>;
  createdDate: Scalars['DateTime'];
  canEdit: Scalars['Boolean'];
};

export type TaskCommentReaction = {
  __typename?: 'TaskCommentReaction';
  id: Scalars['ID'];
  value: Scalars['String'];
  icon: Scalars['String'];
  reactors: Array<Scalars['String']>;
  name: Scalars['String'];
};

export type TaskLog = {
  __typename?: 'TaskLog';
  log: Scalars['String'];
  user: User;
  task: Task;
  createdDate: Scalars['DateTime'];
};

export type TaskProgress = {
  __typename?: 'TaskProgress';
  completed: Scalars['Int'];
  total: Scalars['Int'];
};

export type TaskState = {
  __typename?: 'TaskState';
  id: Scalars['ID'];
  name: Scalars['String'];
  color: Scalars['String'];
  tasks: Array<Task>;
  order: Scalars['Int'];
  isComplete: Scalars['Boolean'];
  isArchived: Scalars['Boolean'];
};

export type TaskStateInput = {
  name: Scalars['String'];
  isComplete: Scalars['Boolean'];
  color: Scalars['String'];
  order: Scalars['Int'];
};

export type Team = {
  __typename?: 'Team';
  id: Scalars['ID'];
  name: Scalars['String'];
  members: Array<TeamMember>;
};

export type TeamMember = {
  __typename?: 'TeamMember';
  user: User;
  id: Scalars['ID'];
};

export type UnreadComment = {
  __typename?: 'UnreadComment';
  id: Scalars['ID'];
  title: Scalars['String'];
  targetPath: Array<Maybe<Scalars['String']>>;
  relatedUsers: Array<User>;
  relatedNotificationIds: Array<Scalars['String']>;
  type: Scalars['String'];
  unreadCount: Scalars['Int'];
};

export type UnreadThreadResult = {
  __typename?: 'UnreadThreadResult';
  message: Message;
  group: Group;
  count: Scalars['Int'];
};

export type UpdateTaskCommentResult = {
  __typename?: 'UpdateTaskCommentResult';
  comment: TaskComment;
  task: Task;
};

export type UpdateWikiCommentResult = {
  __typename?: 'UpdateWikiCommentResult';
  comment: WikiComment;
  file: File;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  fullName: Scalars['String'];
  email: Scalars['String'];
  isGuest: Scalars['Boolean'];
  isPretzel: Scalars['Boolean'];
  profilePicture: Scalars['String'];
  skinTonePreference: Scalars['Int'];
  isActive: Scalars['Boolean'];
  lastSeen?: Maybe<Scalars['DateTime']>;
};

export type UserChatStatus = {
  __typename?: 'UserChatStatus';
  status?: Maybe<Scalars['String']>;
};

export type UserStatus = {
  __typename?: 'UserStatus';
  user?: Maybe<User>;
  profile?: Maybe<Profile>;
};


export type WebLink = {
  __typename?: 'WebLink';
  name: Scalars['String'];
  description: Scalars['String'];
  url: Scalars['String'];
};

export type WebSearchResult = {
  __typename?: 'WebSearchResult';
  webResult?: Maybe<Array<Maybe<WebSearchResultItem>>>;
  imagesPreview?: Maybe<Array<Maybe<WebSearchResultItem>>>;
  videosPreview?: Maybe<Array<Maybe<WebSearchResultItem>>>;
  newsPreview?: Maybe<Array<Maybe<WebSearchResultItem>>>;
};

export type WebSearchResultItem = {
  __typename?: 'WebSearchResultItem';
  _type?: Maybe<Scalars['String']>;
  accentColor?: Maybe<Scalars['String']>;
  allowHttpsEmbed?: Maybe<Scalars['Boolean']>;
  allowMobileEmbed?: Maybe<Scalars['Boolean']>;
  bingId?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  contentSize?: Maybe<Scalars['String']>;
  contentUrl?: Maybe<Scalars['String']>;
  contractualRules?: Maybe<Array<Maybe<WebSearchResultItem>>>;
  creator?: Maybe<WebSearchResultItem>;
  datePublished?: Maybe<Scalars['String']>;
  deepLinks?: Maybe<Array<Maybe<WebSearchResultItem>>>;
  description?: Maybe<Scalars['String']>;
  displayText?: Maybe<Scalars['String']>;
  displayUrl?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
  embedHtml?: Maybe<Scalars['String']>;
  encodingFormat?: Maybe<Scalars['String']>;
  entityPresentationInfo?: Maybe<Array<Maybe<WebSearchResultItem>>>;
  entityScenario?: Maybe<Scalars['String']>;
  entityTypeDisplayHint?: Maybe<Array<Maybe<Scalars['String']>>>;
  entityTypeHint?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  hostPageDisplayUrl?: Maybe<Scalars['String']>;
  hostPageUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<WebSearchResultItem>;
  items?: Maybe<Array<Maybe<WebSearchResultItem>>>;
  licenseNotice?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  motionThumbnailId?: Maybe<Scalars['String']>;
  motionThumbnailUrl?: Maybe<Scalars['String']>;
  mustBeCloseToContent?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  primaryImageOfPage?: Maybe<WebSearchResultItem>;
  searchLink?: Maybe<Scalars['String']>;
  searchTags?: Maybe<Array<Maybe<WebSearchResultItem>>>;
  snippet?: Maybe<Scalars['String']>;
  targetPropertyName?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<WebSearchResultItem>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  videoId?: Maybe<Scalars['String']>;
  viewCount?: Maybe<Scalars['Int']>;
  webSearchUrl?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  wordCount?: Maybe<Scalars['Int']>;
};

export type Wiki = {
  __typename?: 'Wiki';
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  teams: Array<Team>;
  users: Array<User>;
  allUsers: Array<User>;
  idStr: Scalars['String'];
  canEdit: Scalars['Boolean'];
  rootFolderId: Scalars['ID'];
  pageCount: Scalars['Int'];
  rootFolder: Folder;
  commentCount: Scalars['Int'];
};

export type WikiAttachment = {
  __typename?: 'WikiAttachment';
  id: Scalars['ID'];
  type: Scalars['String'];
  name: Scalars['String'];
  size: Scalars['Int'];
  commentId?: Maybe<Scalars['ID']>;
  url: Scalars['String'];
  canRemove: Scalars['Boolean'];
};

export type WikiComment = {
  __typename?: 'WikiComment';
  id: Scalars['ID'];
  content: Scalars['String'];
  commentor: User;
  reactions: Array<WikiCommentReaction>;
  attachments: Array<WikiAttachment>;
  createdDate: Scalars['DateTime'];
  canEdit: Scalars['Boolean'];
};

export type WikiCommentReaction = {
  __typename?: 'WikiCommentReaction';
  id: Scalars['ID'];
  value: Scalars['String'];
  icon: Scalars['String'];
  reactors: Array<Scalars['String']>;
  name: Scalars['String'];
};

export type WikipediaData = {
  __typename?: 'WikipediaData';
  title?: Maybe<Scalars['String']>;
  tables?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type WikipediaImage = {
  __typename?: 'WikipediaImage';
  url?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  alt?: Maybe<Scalars['String']>;
};

export type WikipediaResult = {
  __typename?: 'WikipediaResult';
  sections?: Maybe<Array<WikipediaSection>>;
  title?: Maybe<Scalars['String']>;
  data?: Maybe<Array<Maybe<WikipediaData>>>;
  url?: Maybe<Scalars['String']>;
  pageId?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  image?: Maybe<WikipediaImage>;
};

export type WikipediaSection = {
  __typename?: 'WikipediaSection';
  title?: Maybe<Scalars['String']>;
  content?: Maybe<WikipediaSectionContent>;
};

export type WikipediaSectionContent = {
  __typename?: 'WikipediaSectionContent';
  paragraphs?: Maybe<Array<Maybe<Paragraph>>>;
};

export type Sessions_SelectSessionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type Sessions_SelectSessionMutation = { __typename?: 'Mutation', selectSession: { __typename?: 'Query', sessions: Array<(
      { __typename?: 'AppSessionSession' }
      & SessionTab_AppSessionSessionFragment
    )>, appSpaces: Array<(
      { __typename?: 'SessionAppSpace' }
      & AppSpaceTabs_AppSpaceFragment
    )>, groups: Array<(
      { __typename?: 'Group' }
      & SidebarChannels_ChannelFragment
    )>, activeSession: (
      { __typename?: 'Session' }
      & SessionTab_ActiveSessionFragment
      & Sidebar_ActiveSessionFragment
    ), organization: { __typename?: 'Organization', id: string, name: string }, potentialGroupMembers: Array<{ __typename?: 'User', id: string, fullName: string, profilePicture: string }>, activeAppSpace?: Maybe<{ __typename?: 'AppSpace', type: string, id: string, name: string }> } };

export type Sessions_CloseSessionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type Sessions_CloseSessionMutation = { __typename?: 'Mutation', closeSession: { __typename?: 'Query', sessions: Array<(
      { __typename?: 'AppSessionSession' }
      & SessionTab_AppSessionSessionFragment
    )>, activeSession: (
      { __typename?: 'Session' }
      & SessionTab_ActiveSessionFragment
      & Sidebar_ActiveSessionFragment
    ), organization: { __typename?: 'Organization', id: string, name: string }, appSpaces: Array<(
      { __typename?: 'SessionAppSpace' }
      & AppSpaceTabs_AppSpaceFragment
    )>, activeAppSpace?: Maybe<{ __typename?: 'AppSpace', type: string, id: string, name: string }> } };

export type Sessions_RenameSessionMutationVariables = Exact<{
  sessionId: Scalars['ID'];
  newName: Scalars['String'];
}>;


export type Sessions_RenameSessionMutation = { __typename?: 'Mutation', renameSession: { __typename?: 'Session', id: string, name: string } };

export type Sessions_CreateSessionMutationVariables = Exact<{
  name?: Maybe<Scalars['String']>;
}>;


export type Sessions_CreateSessionMutation = { __typename?: 'Mutation', createSession: { __typename?: 'Query', sessions: Array<(
      { __typename?: 'AppSessionSession' }
      & SessionTab_AppSessionSessionFragment
    )>, activeSession: (
      { __typename?: 'Session' }
      & SessionTabs_ActiveSessionFragment
      & Sidebar_ActiveSessionFragment
    ), organization: { __typename?: 'Organization', id: string, name: string }, appSpaces: Array<(
      { __typename?: 'SessionAppSpace' }
      & AppSpaceTabs_AppSpaceFragment
    )>, activeAppSpace?: Maybe<{ __typename?: 'AppSpace', type: string, id: string, name: string }> } };

export type Sessions_ReopenSessionMutationVariables = Exact<{
  sessionId: Scalars['ID'];
}>;


export type Sessions_ReopenSessionMutation = { __typename?: 'Mutation', reopenSession: { __typename?: 'Query', sessions: Array<(
      { __typename?: 'AppSessionSession' }
      & SessionTab_AppSessionSessionFragment
    )>, activeSession: (
      { __typename?: 'Session' }
      & SessionTabs_ActiveSessionFragment
      & Sidebar_ActiveSessionFragment
    ), organization: { __typename?: 'Organization', id: string, name: string }, appSpaces: Array<(
      { __typename?: 'SessionAppSpace' }
      & AppSpaceTabs_AppSpaceFragment
    )>, activeAppSpace?: Maybe<{ __typename?: 'AppSpace', type: string, id: string, name: string }> } };

export type ProfileQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ProfileQuery = { __typename?: 'Query', profile?: Maybe<{ __typename?: 'Profile', id: string, email: string, name: string, phone?: Maybe<string>, role?: Maybe<string>, isCurrentUser: boolean, profilePicture: string, lastSeen?: Maybe<any>, groups?: Maybe<Array<Maybe<{ __typename?: 'Group', id: string, type: string, name: string, description?: Maybe<string>, members: Array<{ __typename?: 'GroupMember', id: string, name: string, profilePicture: string }> }>>> }> };

export type Profile_DmsQueryVariables = Exact<{ [key: string]: never; }>;


export type Profile_DmsQuery = { __typename?: 'Query', groups: Array<(
    { __typename?: 'Group' }
    & SidebarChannels_ChannelFragment
  )> };

export type UpdateProfileMutationVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
}>;


export type UpdateProfileMutation = { __typename?: 'Mutation', updateProfile?: Maybe<any> };

export type UploadProfilePictureMutationVariables = Exact<{ [key: string]: never; }>;


export type UploadProfilePictureMutation = { __typename?: 'Mutation', uploadProfilePicture: string };

export type RootQueryVariables = Exact<{ [key: string]: never; }>;


export type RootQuery = { __typename?: 'Query', activeAppSpace?: Maybe<{ __typename?: 'AppSpace', id: string }>, organization: { __typename?: 'Organization', id: string }, activeSession: { __typename?: 'Session', id: string, groupId?: Maybe<string> } };

export type Root_RegisterForNotificationMutationVariables = Exact<{
  sub: Scalars['String'];
}>;


export type Root_RegisterForNotificationMutation = { __typename?: 'Mutation', registerForNotifications?: Maybe<boolean> };

export type Root_MarkNotificationClickedMutationVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type Root_MarkNotificationClickedMutation = { __typename?: 'Mutation', markNotificationClicked: { __typename?: 'Query', activeSession: { __typename?: 'Session', id: string } } };

export type SessionTab_ActiveSessionFragment = { __typename?: 'Session', id: string };

export type SessionTab_AppSessionSessionFragment = { __typename?: 'AppSessionSession', id: string, isActive: boolean, session: { __typename?: 'Session', id: string, name: string, createdDate: any, isActive: boolean, isClosed: boolean, groupId?: Maybe<string> } };

export type SessionTabs_ActiveSessionFragment = { __typename?: 'Session', id: string, name: string };

export type SesssionTabs_OtherSessionFragment = { __typename?: 'Session', id: string, name: string, createdDate: any };

export type SessionTabsQueryVariables = Exact<{ [key: string]: never; }>;


export type SessionTabsQuery = { __typename?: 'Query', sessions: Array<(
    { __typename?: 'AppSessionSession' }
    & SessionTab_AppSessionSessionFragment
  )>, activeSession: (
    { __typename?: 'Session' }
    & SessionTabs_ActiveSessionFragment
  ), sessionSettings: { __typename?: 'SessionSettings', maximumSessionCount: number } };

export type SessionTabs_OtherSessionsQueryVariables = Exact<{ [key: string]: never; }>;


export type SessionTabs_OtherSessionsQuery = { __typename?: 'Query', otherSessions: Array<(
    { __typename?: 'Session' }
    & SesssionTabs_OtherSessionFragment
  )> };

export type Sidebar_UerInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type Sidebar_UerInfoQuery = { __typename?: 'Query', me?: Maybe<{ __typename?: 'User', id: string, fullName: string, isGuest: boolean, profilePicture: string }>, activeSession: { __typename?: 'Session', id: string }, activeSpace?: Maybe<{ __typename?: 'Space', id: string, name: string }>, organization: { __typename?: 'Organization', name: string, id: string, profilePicture: string, spaces: Array<{ __typename?: 'Space', id: string, name: string }> }, organizations: Array<{ __typename?: 'Organization', id: string, name: string, memberCount: number, profilePicture: string }> };

export type Sidebar_SignOutMutationVariables = Exact<{ [key: string]: never; }>;


export type Sidebar_SignOutMutation = { __typename?: 'Mutation', signOut?: Maybe<any> };

export type Sidebar_GroupUpdatedSubscriptionVariables = Exact<{
  session: Scalars['ID'];
}>;


export type Sidebar_GroupUpdatedSubscription = { __typename?: 'Subscription', groupUpdated: (
    { __typename?: 'Group', description?: Maybe<string> }
    & SidebarChannels_ChannelFragment
  ) };

export type Sidebar_ActiveSessionFragment = { __typename?: 'Session', id: string, groupId?: Maybe<string> };

export type SidebarChannels_ChannelFragment = { __typename?: 'Group', id: string, name: string, unreadCount: number, type: string, members: Array<{ __typename?: 'GroupMember', id: string, name: string, profilePicture: string }> };

export type SidebarChannelsQueryVariables = Exact<{ [key: string]: never; }>;


export type SidebarChannelsQuery = { __typename?: 'Query', activeSession: (
    { __typename?: 'Session' }
    & Sidebar_ActiveSessionFragment
  ), me?: Maybe<{ __typename?: 'User', id: string }>, groups: Array<(
    { __typename?: 'Group' }
    & SidebarChannels_ChannelFragment
  )>, potentialGroupMembers: Array<{ __typename?: 'User', id: string, fullName: string, profilePicture: string }> };

export type SidebarChannels_CreateChannelMutationVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  members: Array<Scalars['ID']> | Scalars['ID'];
  type: Scalars['String'];
  description?: Maybe<Scalars['String']>;
}>;


export type SidebarChannels_CreateChannelMutation = { __typename?: 'Mutation', createGroup: { __typename?: 'Query', groups: Array<(
      { __typename?: 'Group' }
      & SidebarChannels_ChannelFragment
    )>, sessions: Array<(
      { __typename?: 'AppSessionSession' }
      & SessionTab_AppSessionSessionFragment
    )>, activeSession: (
      { __typename?: 'Session' }
      & SessionTabs_ActiveSessionFragment
      & Sidebar_ActiveSessionFragment
    ), potentialGroupMembers: Array<{ __typename?: 'User', id: string, fullName: string, profilePicture: string }> } };

export type SidebarChannels_SelectChannelMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SidebarChannels_SelectChannelMutation = { __typename?: 'Mutation', selectGroup: { __typename?: 'Query', sessions: Array<(
      { __typename?: 'AppSessionSession' }
      & SessionTab_AppSessionSessionFragment
    )>, activeSession: (
      { __typename?: 'Session' }
      & SessionTabs_ActiveSessionFragment
      & Sidebar_ActiveSessionFragment
    ) } };

export type SidebarUnreadComments_UnreadFragment = { __typename?: 'UnreadComment', id: string, title: string, targetPath: Array<Maybe<string>>, relatedNotificationIds: Array<string>, type: string, unreadCount: number, relatedUsers: Array<{ __typename?: 'User', id: string, fullName: string, profilePicture: string }> };

export type SidebarUnreadCommentsQueryVariables = Exact<{ [key: string]: never; }>;


export type SidebarUnreadCommentsQuery = { __typename?: 'Query', activeSession: { __typename?: 'Session', id: string }, unreadComments: Array<(
    { __typename?: 'UnreadComment' }
    & SidebarUnreadComments_UnreadFragment
  )> };

export type SidebarUnreadComments_MarkNotificationClickedMutationVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type SidebarUnreadComments_MarkNotificationClickedMutation = { __typename?: 'Mutation', markNotificationClicked: { __typename?: 'Query', unreadComments: Array<(
      { __typename?: 'UnreadComment' }
      & SidebarUnreadComments_UnreadFragment
    )> } };

export type SidebarUnreadComments_UnreadCommentsSubscriptionVariables = Exact<{
  session: Scalars['ID'];
}>;


export type SidebarUnreadComments_UnreadCommentsSubscription = { __typename?: 'Subscription', unreadComments: { __typename?: 'Query', unreadComments: Array<(
      { __typename?: 'UnreadComment' }
      & SidebarUnreadComments_UnreadFragment
    )> } };

export type AppSpace_ActiveAppSpaceFragment = { __typename?: 'AppSpace', type: string, id: string, name: string, meta?: Maybe<string> };

export type AppSpaceQueryVariables = Exact<{ [key: string]: never; }>;


export type AppSpaceQuery = { __typename?: 'Query', activeAppSpace?: Maybe<(
    { __typename?: 'AppSpace', file?: Maybe<{ __typename?: 'File', id: string, content?: Maybe<string>, type: string, kind?: Maybe<string>, readOnly?: Maybe<boolean> }> }
    & AppSpace_ActiveAppSpaceFragment
  )>, appSpaces: Array<{ __typename?: 'SessionAppSpace', id: string, isActive: boolean, appSpace: { __typename?: 'AppSpace', id: string, name: string } }> };

export type AppSpaces_UpdateFileContentMutationVariables = Exact<{
  id: Scalars['ID'];
  content: Scalars['String'];
}>;


export type AppSpaces_UpdateFileContentMutation = { __typename?: 'Mutation', updateFileContent: { __typename?: 'File', id: string } };

export type AppSpaces_RenameAppSpaceMutationVariables = Exact<{
  id: Scalars['ID'];
  newName: Scalars['String'];
}>;


export type AppSpaces_RenameAppSpaceMutation = { __typename?: 'Mutation', renameAppSpace: { __typename?: 'AppSpace', id: string, name: string } };

export type AppSpaces_SelectAppSpaceMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AppSpaces_SelectAppSpaceMutation = { __typename?: 'Mutation', selectAppSpace: { __typename?: 'Query', appSpaces: Array<(
      { __typename?: 'SessionAppSpace' }
      & AppSpaceTabs_AppSpaceFragment
    )>, activeAppSpace?: Maybe<{ __typename?: 'AppSpace', type: string, id: string, name: string }> } };

export type AppSpaces_CreateAppSpaceMutationVariables = Exact<{
  type: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  folderId?: Maybe<Scalars['String']>;
}>;


export type AppSpaces_CreateAppSpaceMutation = { __typename?: 'Mutation', createAppSpace: { __typename?: 'Query', appSpaces: Array<(
      { __typename?: 'SessionAppSpace' }
      & AppSpaceTabs_AppSpaceFragment
    )>, activeAppSpace?: Maybe<{ __typename?: 'AppSpace', type: string, id: string, name: string }> } };

export type AppSpaces_CloseAppSpaceMutationVariables = Exact<{
  appSpaceId: Scalars['ID'];
}>;


export type AppSpaces_CloseAppSpaceMutation = { __typename?: 'Mutation', closeAppSpace: { __typename?: 'Query', appSpaces: Array<(
      { __typename?: 'SessionAppSpace' }
      & AppSpaceTabs_AppSpaceFragment
    )>, activeAppSpace?: Maybe<{ __typename?: 'AppSpace', type: string, id: string, name: string }> } };

export type AppSpaces_UpgradeMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type AppSpaces_UpgradeMutation = { __typename?: 'Mutation', upgradeDocument: { __typename?: 'File', id: string, kind?: Maybe<string> } };

export type AppSpaces_DowngradeMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type AppSpaces_DowngradeMutation = { __typename?: 'Mutation', upgradeDocument: { __typename?: 'File', id: string, kind?: Maybe<string> } };

export type AppSpaces_ExportFileMutationVariables = Exact<{
  id: Scalars['String'];
  content: Scalars['String'];
  targetType: Scalars['String'];
}>;


export type AppSpaces_ExportFileMutation = { __typename?: 'Mutation', exportFile: { __typename?: 'Query', appSpaces: Array<(
      { __typename?: 'SessionAppSpace' }
      & AppSpaceTabs_AppSpaceFragment
    )>, activeAppSpace?: Maybe<(
      { __typename?: 'AppSpace' }
      & AppSpace_ActiveAppSpaceFragment
    )> } };

export type AppSpaces_SetReadOnlyMutationVariables = Exact<{
  id: Scalars['String'];
  readOnly: Scalars['Boolean'];
  html?: Maybe<Scalars['String']>;
}>;


export type AppSpaces_SetReadOnlyMutation = { __typename?: 'Mutation', updateReadOnly?: Maybe<{ __typename?: 'File', id: string, name: string, type: string }> };

export type AppSpaces_FinishUploadExportedAppSpaceMutationVariables = Exact<{
  uploadId: Scalars['String'];
  masterId: Scalars['String'];
}>;


export type AppSpaces_FinishUploadExportedAppSpaceMutation = { __typename?: 'Mutation', finishUploadExportedFile: { __typename?: 'Query', appSpaces: Array<(
      { __typename?: 'SessionAppSpace' }
      & AppSpaceTabs_AppSpaceFragment
    )>, activeAppSpace?: Maybe<(
      { __typename?: 'AppSpace' }
      & AppSpace_ActiveAppSpaceFragment
    )> } };

export type AppSpaces_DeleteAppSpaceMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AppSpaces_DeleteAppSpaceMutation = { __typename?: 'Mutation', deleteAppSpace: { __typename?: 'Query', appSpaces: Array<(
      { __typename?: 'SessionAppSpace' }
      & AppSpaceTabs_AppSpaceFragment
    )> } };

export type AppSpaces_OpenAppSpaceMutationVariables = Exact<{
  type: Scalars['String'];
  meta: Scalars['String'];
  name: Scalars['String'];
}>;


export type AppSpaces_OpenAppSpaceMutation = { __typename?: 'Mutation', openAppSpace: { __typename?: 'Query', appSpaces: Array<(
      { __typename?: 'SessionAppSpace' }
      & AppSpaceTabs_AppSpaceFragment
    )>, activeAppSpace?: Maybe<{ __typename?: 'AppSpace', type: string, id: string, name: string }> } };

export type AppSpaceTabs_AppSpaceFragment = { __typename?: 'SessionAppSpace', id: string, isActive: boolean, appSpace: { __typename?: 'AppSpace', type: string, id: string, name: string } };

export type AppSpaceTabsQueryVariables = Exact<{ [key: string]: never; }>;


export type AppSpaceTabsQuery = { __typename?: 'Query', appSpaces: Array<(
    { __typename?: 'SessionAppSpace' }
    & AppSpaceTabs_AppSpaceFragment
  )>, activeAppSpace?: Maybe<{ __typename?: 'AppSpace', type: string, id: string, name: string }> };

export type EditorHandlerQueryVariables = Exact<{ [key: string]: never; }>;


export type EditorHandlerQuery = { __typename?: 'Query', activeAppSpace?: Maybe<{ __typename?: 'AppSpace', id: string, name: string }> };

export type NotificationFragment = { __typename?: 'Notification', id: string, title: string, message: string, link: string, image?: Maybe<string>, type?: Maybe<string>, clickedDate?: Maybe<any>, closedDate?: Maybe<any>, createdDate: any, isRead: boolean, relatedUser?: Maybe<{ __typename?: 'User', id: string, fullName: string, email: string, profilePicture: string }>, task?: Maybe<{ __typename?: 'Task', title: string, description: string }>, taskboard?: Maybe<{ __typename?: 'TaskBoard', name: string, description: string }> };

export type NotificationViewQueryVariables = Exact<{
  skip: Scalars['Int'];
  take: Scalars['Int'];
}>;


export type NotificationViewQuery = { __typename?: 'Query', unreadNotifications: number, notifications: Array<(
    { __typename?: 'Notification' }
    & NotificationFragment
  )> };

export type NotificationView_ClickNotificationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type NotificationView_ClickNotificationMutation = { __typename?: 'Mutation', clickNotification: { __typename?: 'NotificationQuery', notification: { __typename?: 'Notification', id: string, isRead: boolean }, query: { __typename?: 'Query', unreadNotifications: number } } };

export type NotificationView_NotificationsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type NotificationView_NotificationsSubscription = { __typename?: 'Subscription', notifications?: Maybe<(
    { __typename?: 'Notification' }
    & NotificationFragment
  )> };

export type DesignerCompQueryVariables = Exact<{ [key: string]: never; }>;


export type DesignerCompQuery = { __typename?: 'Query', activeAppSpace?: Maybe<{ __typename?: 'AppSpace', type: string, id: string, name: string, file?: Maybe<{ __typename?: 'File', id: string, name: string }> }> };

export type TextDocuementQueryVariables = Exact<{ [key: string]: never; }>;


export type TextDocuementQuery = { __typename?: 'Query', activeAppSpace?: Maybe<{ __typename?: 'AppSpace', id: string, name: string }> };

export type TextDocument_UploadImageMutationVariables = Exact<{
  filename?: Maybe<Scalars['String']>;
  fileType: Scalars['String'];
}>;


export type TextDocument_UploadImageMutation = { __typename?: 'Mutation', uploadImage?: Maybe<{ __typename?: 'ImageUploadOutput', postData: string, getData: string }> };

export type Register_RegisterMutationVariables = Exact<{
  fullName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  guest: Scalars['Boolean'];
}>;


export type Register_RegisterMutation = { __typename?: 'Mutation', signUp?: Maybe<any> };

export type SignInMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type SignInMutation = { __typename?: 'Mutation', signin?: Maybe<any> };

export type Bookmarks_BookmarkFragment = { __typename?: 'Bookmark', id: string, title: string, url: string };

export type Bookmarks_BookmarksQueryVariables = Exact<{
  message: Scalars['ID'];
}>;


export type Bookmarks_BookmarksQuery = { __typename?: 'Query', message?: Maybe<{ __typename?: 'Message', bookmarks?: Maybe<Array<(
      { __typename?: 'Bookmark' }
      & Bookmarks_BookmarkFragment
    )>> }> };

export type Bookmarks_UpdateBookmarkMutationVariables = Exact<{
  bookmarkId: Scalars['ID'];
  url: Scalars['String'];
  title: Scalars['String'];
}>;


export type Bookmarks_UpdateBookmarkMutation = { __typename?: 'Mutation', updateBookmark?: Maybe<(
    { __typename?: 'Bookmark' }
    & Bookmarks_BookmarkFragment
  )> };

export type Bookmarks_DeleteBookmarkMutationVariables = Exact<{
  bookmarkId: Scalars['ID'];
}>;


export type Bookmarks_DeleteBookmarkMutation = { __typename?: 'Mutation', deleteBookmark?: Maybe<any> };

export type Chat_ChatMutationVariables = Exact<{
  message: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  silent?: Maybe<Scalars['Boolean']>;
  richtext?: Maybe<Scalars['Boolean']>;
  attachments?: Maybe<Array<AttachmentInput> | AttachmentInput>;
}>;


export type Chat_ChatMutation = { __typename?: 'Mutation', chat?: Maybe<(
    { __typename?: 'Message' }
    & Message_MessageFragment
  )> };

export type Chat_MessagesQueryVariables = Exact<{
  offset: Scalars['Int'];
}>;


export type Chat_MessagesQuery = { __typename?: 'Query', activeSession: { __typename?: 'Session', id: string, chatHistory: Array<(
      { __typename?: 'Message' }
      & Message_MessageFragment
    )> } };

export type Chat_GroupQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type Chat_GroupQuery = { __typename?: 'Query', group?: Maybe<{ __typename?: 'Group', id: string, unreadCount: number }> };

export type ChatQueryVariables = Exact<{ [key: string]: never; }>;


export type ChatQuery = { __typename?: 'Query', me?: Maybe<{ __typename?: 'User', id: string, fullName: string, isGuest: boolean, isPretzel: boolean, profilePicture: string }>, activeAppSpace?: Maybe<{ __typename?: 'AppSpace', id: string }>, activeSession: { __typename?: 'Session', id: string, groupId?: Maybe<string> }, sessions: Array<{ __typename?: 'AppSessionSession', id: string, session: { __typename?: 'Session', id: string, groupId?: Maybe<string> } }> };

export type Chat_ReportTypeActionMutationVariables = Exact<{
  group: Scalars['ID'];
  values: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
}>;


export type Chat_ReportTypeActionMutation = { __typename?: 'Mutation', reportUserAction?: Maybe<{ __typename?: 'Group', id: string }> };

export type Chat_ReportReadActionMutationVariables = Exact<{
  group: Scalars['ID'];
  values: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
}>;


export type Chat_ReportReadActionMutation = { __typename?: 'Mutation', reportUserAction?: Maybe<{ __typename?: 'Group', id: string, unreadCount: number }> };

export type Chat_FinishImageUploadMutationVariables = Exact<{
  uploadId: Scalars['String'];
  name: Scalars['String'];
  size: Scalars['Int'];
}>;


export type Chat_FinishImageUploadMutation = { __typename?: 'Mutation', finishUploadImage: { __typename?: 'File', id: string } };

export type Chat_OnMessageReceivedSubscriptionVariables = Exact<{
  session: Scalars['ID'];
}>;


export type Chat_OnMessageReceivedSubscription = { __typename?: 'Subscription', messageReceived: (
    { __typename?: 'Message' }
    & Message_MessageFragment
  ) };

export type Chat_OnMessageUpdatedSubscriptionVariables = Exact<{
  session: Scalars['ID'];
}>;


export type Chat_OnMessageUpdatedSubscription = { __typename?: 'Subscription', messageUpdated: { __typename?: 'Message', id: string, reactions: Array<{ __typename?: 'MessageReaction', value: string, reactors: Array<string>, icon: string }>, attachments?: Maybe<Array<(
      { __typename?: 'MessageAttachment' }
      & MessageAttachments_MessageAttachmentFragment
    )>> } };

export type ChatHeadingQueryVariables = Exact<{
  session: Scalars['ID'];
}>;


export type ChatHeadingQuery = { __typename?: 'Query', session?: Maybe<{ __typename?: 'Session', id: string, group?: Maybe<{ __typename?: 'Group', name: string, description?: Maybe<string>, type: string, id: string, unreadCount: number, canEdit: boolean, members: Array<{ __typename?: 'GroupMember', id: string, email?: Maybe<string>, name: string, role: string, profilePicture: string }>, otherMember?: Maybe<{ __typename?: 'GroupMember', id: string, email?: Maybe<string>, name: string, profilePicture: string }> }> }> };

export type ChatHeading_AddGroupMembersMutationVariables = Exact<{
  group: Scalars['ID'];
  members: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type ChatHeading_AddGroupMembersMutation = { __typename?: 'Mutation', addGroupMembers: { __typename?: 'Group', id: string, name: string, members: Array<{ __typename?: 'GroupMember', id: string, email?: Maybe<string>, name: string, role: string, profilePicture: string }> } };

export type ChatHeading_UpdateGroupMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
}>;


export type ChatHeading_UpdateGroupMutation = { __typename?: 'Mutation', updateGroup: { __typename?: 'Group', id: string, name: string, description?: Maybe<string> } };

export type ChatHeading_LeaveGrupMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ChatHeading_LeaveGrupMutation = { __typename?: 'Mutation', leaveGroup: { __typename?: 'Query', groups: Array<(
      { __typename?: 'Group' }
      & SidebarChannels_ChannelFragment
    )>, potentialGroupMembers: Array<{ __typename?: 'User', id: string, fullName: string, profilePicture: string }>, sessions: Array<(
      { __typename?: 'AppSessionSession' }
      & SessionTab_AppSessionSessionFragment
    )>, activeSession: (
      { __typename?: 'Session' }
      & SessionTabs_ActiveSessionFragment
      & Sidebar_ActiveSessionFragment
    ) } };

export type FileFolderTableView_FileFragment = { __typename?: 'File', id: string, name: string, type: string, createdDate?: Maybe<any>, updatedDate?: Maybe<any>, isConverted?: Maybe<boolean>, size: number, kind?: Maybe<string>, origin?: Maybe<string>, versions?: Maybe<Array<{ __typename?: 'File', id: string, name: string, type: string, kind?: Maybe<string>, size: number, origin?: Maybe<string>, createdDate?: Maybe<any> }>>, parent?: Maybe<{ __typename?: 'Folder', id: string, name: string }> };

export type FileFolderTableView_FolderFragment = { __typename?: 'Folder', id: string, name: string, createdDate: any, lastAccess?: Maybe<any>, hasChildren?: Maybe<boolean>, hasFiles?: Maybe<boolean>, files: Array<(
    { __typename?: 'File' }
    & FileFolderTableView_FileFragment
  )>, folders: Array<{ __typename?: 'Folder', id: string, name: string, createdDate: any }>, parent?: Maybe<{ __typename?: 'Folder', id: string, name: string }> };

export type FileListing_FileListingFragment = { __typename?: 'FileListing', id: string, type: string, pageNumber: number, pageSize: number, count: number, orderMode: string, listingMode?: Maybe<string>, limitMode?: Maybe<string>, query?: Maybe<string>, files: { __typename?: 'FilesListing', count?: Maybe<number>, files: Array<(
      { __typename?: 'File' }
      & FileFolderTableView_FileFragment
      & FolderTreeView_FileFragment
      & FilePreviewView_FileFragment
    )> }, folders: { __typename?: 'FolderListing', id: string, count?: Maybe<number>, expandedFolders: Array<string>, folders: Array<(
      { __typename?: 'Folder' }
      & FileFolderTableView_FolderFragment
      & FolderTreeView_FolderFragment
    )> } };

export type FileListingQueryVariables = Exact<{
  messageId: Scalars['ID'];
  type: Scalars['String'];
  pageSize: Scalars['Int'];
  pageNumber: Scalars['Int'];
  expandedFolders: Array<Scalars['String']> | Scalars['String'];
  orderMode: Scalars['String'];
  listingMode: Scalars['String'];
  query: Scalars['String'];
  limitMode: Scalars['String'];
}>;


export type FileListingQuery = { __typename?: 'Query', message?: Maybe<{ __typename?: 'Message', id: string, fileListing?: Maybe<(
      { __typename?: 'FileListing' }
      & FileListing_FileListingFragment
    )> }>, activeSession: { __typename?: 'Session', id: string } };

export type FileListing_DownloadFileQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FileListing_DownloadFileQuery = { __typename?: 'Query', downloadFile: { __typename?: 'FileDownloadInfo', url: string } };

export type FileListing_SessionsQueryVariables = Exact<{ [key: string]: never; }>;


export type FileListing_SessionsQuery = { __typename?: 'Query', allSessions: Array<{ __typename?: 'Session', id: string, name: string, createdDate: any, isClosed: boolean, isActive: boolean }> };

export type FileListing_MoveFolderMutationVariables = Exact<{
  folder: Scalars['ID'];
  toFolder: Scalars['ID'];
}>;


export type FileListing_MoveFolderMutation = { __typename?: 'Mutation', moveFolderToFolder: { __typename?: 'MoveFolderToFolderResult', updatedFolders: Array<{ __typename?: 'Folder', id: string }> } };

export type FileListing_MoveFileToFolderMutationVariables = Exact<{
  file: Scalars['ID'];
  toFolder: Scalars['ID'];
}>;


export type FileListing_MoveFileToFolderMutation = { __typename?: 'Mutation', moveFileToFolder: { __typename?: 'MoveFileToFolderResult', updatedFolders: Array<{ __typename?: 'Folder', id: string }> } };

export type FileListing_RenameFolderMutationVariables = Exact<{
  id: Scalars['ID'];
  newName: Scalars['String'];
}>;


export type FileListing_RenameFolderMutation = { __typename?: 'Mutation', renameFolder?: Maybe<{ __typename?: 'Folder', id: string, name: string }> };

export type FileListing_DeleteFolderMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FileListing_DeleteFolderMutation = { __typename?: 'Mutation', deleteFolder: { __typename?: 'DeleteFolderResult', query: { __typename?: 'Query', appSpaces: Array<(
        { __typename?: 'SessionAppSpace' }
        & AppSpaceTabs_AppSpaceFragment
      )> } } };

export type FileListing_NewFolderMutationVariables = Exact<{
  parentId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
}>;


export type FileListing_NewFolderMutation = { __typename?: 'Mutation', createFolder: (
    { __typename?: 'Folder' }
    & FileFolderTableView_FolderFragment
  ) };

export type FileListing_UploadFileMutationVariables = Exact<{
  fileName: Scalars['String'];
  fileType: Scalars['String'];
  fileSize: Scalars['Int'];
  folder?: Maybe<Scalars['String']>;
}>;


export type FileListing_UploadFileMutation = { __typename?: 'Mutation', uploadFile?: Maybe<{ __typename?: 'FileUploadOutput', uploadId: string, postData: string }> };

export type FileListing_FinishUploadFileMutationVariables = Exact<{
  uploadId: Scalars['String'];
}>;


export type FileListing_FinishUploadFileMutation = { __typename?: 'Mutation', finishFileUpload: { __typename?: 'Query', appSpaces: Array<(
      { __typename?: 'SessionAppSpace' }
      & AppSpaceTabs_AppSpaceFragment
    )>, activeAppSpace?: Maybe<(
      { __typename?: 'AppSpace' }
      & AppSpace_ActiveAppSpaceFragment
    )> } };

export type FileListing_OpenFileMutationVariables = Exact<{
  fileId: Scalars['ID'];
}>;


export type FileListing_OpenFileMutation = { __typename?: 'Mutation', openFile: { __typename?: 'Query', appSpaces: Array<(
      { __typename?: 'SessionAppSpace' }
      & AppSpaceTabs_AppSpaceFragment
    )>, activeAppSpace?: Maybe<(
      { __typename?: 'AppSpace' }
      & AppSpace_ActiveAppSpaceFragment
    )> } };

export type FileListing_RenameFileMutationVariables = Exact<{
  fileId: Scalars['ID'];
  newName: Scalars['String'];
}>;


export type FileListing_RenameFileMutation = { __typename?: 'Mutation', renameFile?: Maybe<{ __typename?: 'RenameFileResult', file: { __typename?: 'File', id: string, name: string }, appSpaces: Array<{ __typename?: 'AppSpace', id: string, name: string }> }> };

export type FileListing_DeleteFileMutationVariables = Exact<{
  fileId: Scalars['ID'];
}>;


export type FileListing_DeleteFileMutation = { __typename?: 'Mutation', deleteFile: (
    { __typename?: 'Folder' }
    & FileFolderTableView_FolderFragment
    & FolderTreeView_FolderFragment
  ) };

export type FilePreviewView_FileFragment = { __typename?: 'File', id: string, name: string, size: number, type: string, previewUrl: string };

export type FolderTreeView_FileFragment = { __typename?: 'File', id: string, name: string, type: string, createdDate?: Maybe<any>, updatedDate?: Maybe<any>, isConverted?: Maybe<boolean>, size: number, kind?: Maybe<string>, origin?: Maybe<string>, versions?: Maybe<Array<{ __typename?: 'File', id: string, name: string, type: string, kind?: Maybe<string>, size: number, origin?: Maybe<string>, createdDate?: Maybe<any> }>>, parent?: Maybe<{ __typename?: 'Folder', id: string, name: string }> };

export type FolderTreeView_FolderFragment = { __typename?: 'Folder', id: string, name: string, lastAccess?: Maybe<any>, hasChildren?: Maybe<boolean>, parent?: Maybe<{ __typename?: 'Folder', id: string }> };

export type FolderTreeView_NewFolderMutationVariables = Exact<{
  parentId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
}>;


export type FolderTreeView_NewFolderMutation = { __typename?: 'Mutation', createFolder: (
    { __typename?: 'Folder' }
    & FolderTreeView_FolderFragment
  ) };

export type SessionOptionsMenu_DeleteSessionMutationVariables = Exact<{
  sessionId: Scalars['ID'];
}>;


export type SessionOptionsMenu_DeleteSessionMutation = { __typename?: 'Mutation', deleteSession: { __typename?: 'Query', sessions: Array<(
      { __typename?: 'AppSessionSession' }
      & SessionTab_AppSessionSessionFragment
    )>, activeSession: (
      { __typename?: 'Session' }
      & SessionTabs_ActiveSessionFragment
      & Sidebar_ActiveSessionFragment
    ) } };

export type ImageResultQueryVariables = Exact<{
  messageId: Scalars['ID'];
  count: Scalars['Int'];
}>;


export type ImageResultQuery = { __typename?: 'Query', message?: Maybe<{ __typename?: 'Message', images?: Maybe<Array<{ __typename?: 'WebSearchResultItem', id: string, _type?: Maybe<string>, thumbnailUrl?: Maybe<string>, contentUrl?: Maybe<string>, webSearchUrl?: Maybe<string>, hostPageUrl?: Maybe<string>, contentSize?: Maybe<string>, name?: Maybe<string>, width?: Maybe<number>, height?: Maybe<number> }>> }> };

export type InlineContent_EditMessageMutationVariables = Exact<{
  id: Scalars['ID'];
  content: Scalars['String'];
}>;


export type InlineContent_EditMessageMutation = { __typename?: 'Mutation', editMessage: { __typename?: 'Message', id: string, editedAt?: Maybe<any>, textContent?: Maybe<string> } };

export type InsertButtonQueryVariables = Exact<{ [key: string]: never; }>;


export type InsertButtonQuery = { __typename?: 'Query', activeAppSpace?: Maybe<{ __typename?: 'AppSpace', id: string, type: string }> };

export type ListEditor_ListFragment = { __typename?: 'List', id: string, title: string, index: number, parentId?: Maybe<string>, hasChildren?: Maybe<boolean>, isEditable?: Maybe<boolean>, globalList?: Maybe<boolean>, properties?: Maybe<Array<(
    { __typename?: 'ListProperty' }
    & ListEditor_ListPropertyFragment
  )>> };

export type ListEditor_ListPropertyFragment = { __typename?: 'ListProperty', key: string, value: string };

export type ListEditor_ListEditorResultFragment = { __typename?: 'ListEditorResult', lists: Array<(
    { __typename?: 'List' }
    & ListEditor_ListFragment
  )> };

export type ListQueryVariables = Exact<{
  expandedLists: Array<Scalars['String']> | Scalars['String'];
}>;


export type ListQuery = { __typename?: 'Query', list: (
    { __typename?: 'ListEditorResult' }
    & ListEditor_ListEditorResultFragment
  ) };

export type List_GetGlobalListQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type List_GetGlobalListQuery = { __typename?: 'Query', getGlobalList?: Maybe<(
    { __typename?: 'List', children?: Maybe<Array<{ __typename?: 'List', id: string, title: string, index: number }>> }
    & ListEditor_ListFragment
  )> };

export type ListEditor_CreateListMutationVariables = Exact<{
  parentId: Scalars['String'];
  name: Scalars['String'];
}>;


export type ListEditor_CreateListMutation = { __typename?: 'Mutation', createList?: Maybe<{ __typename?: 'List', id: string }> };

export type ListEditor_UpdateListTitleMutationVariables = Exact<{
  id: Scalars['String'];
  title: Scalars['String'];
}>;


export type ListEditor_UpdateListTitleMutation = { __typename?: 'Mutation', updateListTitle?: Maybe<any> };

export type ListEditor_DeleteListMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type ListEditor_DeleteListMutation = { __typename?: 'Mutation', deleteList?: Maybe<any> };

export type ListEditor_IndentListMutationVariables = Exact<{
  childId: Scalars['String'];
  parentId: Scalars['String'];
}>;


export type ListEditor_IndentListMutation = { __typename?: 'Mutation', indentList?: Maybe<any> };

export type ListEditor_UnindentListMutationVariables = Exact<{
  childId: Scalars['String'];
  parentId: Scalars['String'];
}>;


export type ListEditor_UnindentListMutation = { __typename?: 'Mutation', unindentList?: Maybe<any> };

export type ListsResult_AllQueryVariables = Exact<{ [key: string]: never; }>;


export type ListsResult_AllQuery = { __typename?: 'Query', allLists?: Maybe<Array<(
    { __typename?: 'List', children?: Maybe<Array<{ __typename?: 'List', id: string }>> }
    & ListEditor_ListFragment
  )>> };

export type Message_MessageFragment = { __typename?: 'Message', id: string, type: string, textContent?: Maybe<string>, time: any, editedAt?: Maybe<any>, createdDate: any, searchTerm?: Maybe<string>, obsolete: boolean, isUnread: boolean, user?: Maybe<{ __typename?: 'User', id: string, fullName: string, isPretzel: boolean, profilePicture: string }>, searchResult?: Maybe<(
    { __typename?: 'SearchResult' }
    & SearchResult_SearchResultFragment
  )>, fileListing?: Maybe<(
    { __typename?: 'FileListing' }
    & FileListing_FileListingFragment
  )>, links?: Maybe<Array<{ __typename?: 'WebLink', name: string, description: string, url: string }>>, embed?: Maybe<Array<{ __typename?: 'WebSearchResultItem', contentUrl?: Maybe<string>, embedHtml?: Maybe<string>, allowHttpsEmbed?: Maybe<boolean>, allowMobileEmbed?: Maybe<boolean> }>>, pdfs?: Maybe<Array<{ __typename?: 'PDFResult', id: string, appSpaceId?: Maybe<string>, name: string, url: string, snippet?: Maybe<string>, language?: Maybe<string>, thumbnail: string, local: boolean, size?: Maybe<number> }>>, reactions: Array<{ __typename?: 'MessageReaction', id: string, value: string, reactors: Array<string>, icon: string, name: string }>, attachments?: Maybe<Array<(
    { __typename?: 'MessageAttachment' }
    & MessageAttachments_MessageAttachmentFragment
  )>> };

export type Message_ToggleReactionMutationVariables = Exact<{
  messageId: Scalars['ID'];
  value: Scalars['String'];
}>;


export type Message_ToggleReactionMutation = { __typename?: 'Mutation', toggleMessageReaction: { __typename?: 'Message', id: string, reactions: Array<{ __typename?: 'MessageReaction', id: string, value: string, reactors: Array<string>, icon: string, name: string }> } };

export type MessageAttachments_MessageAttachmentFragment = { __typename?: 'MessageAttachment', id: string, type: string, name: string, size: number, url: string };

export type MessageOptions_DeleteMessageMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type MessageOptions_DeleteMessageMutation = { __typename?: 'Mutation', deleteMessage?: Maybe<any> };

export type NewsResultQueryVariables = Exact<{
  messageId: Scalars['ID'];
  count: Scalars['Int'];
}>;


export type NewsResultQuery = { __typename?: 'Query', message?: Maybe<{ __typename?: 'Message', news?: Maybe<Array<{ __typename?: 'WebSearchResultItem', name?: Maybe<string>, url?: Maybe<string>, description?: Maybe<string>, image?: Maybe<{ __typename?: 'WebSearchResultItem', thumbnail?: Maybe<{ __typename?: 'WebSearchResultItem', contentUrl?: Maybe<string> }> }> }>> }> };

export type PdfResult_SearchQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PdfResult_SearchQuery = { __typename?: 'Query', message?: Maybe<{ __typename?: 'Message', pdfs?: Maybe<Array<{ __typename?: 'PDFResult', id: string, appSpaceId?: Maybe<string>, name: string, url: string, snippet?: Maybe<string>, language?: Maybe<string>, thumbnail: string, local: boolean, size?: Maybe<number> }>> }> };

export type PdfResult_GrabFileMutationVariables = Exact<{
  name: Scalars['String'];
  type: Scalars['String'];
  url: Scalars['String'];
}>;


export type PdfResult_GrabFileMutation = { __typename?: 'Mutation', grabFileAsAppSpace: { __typename?: 'Query', appSpaces: Array<(
      { __typename?: 'SessionAppSpace' }
      & AppSpaceTabs_AppSpaceFragment
    )> } };

export type SaveButton_CreateAppSpaceFromImageMutationVariables = Exact<{
  url: Scalars['String'];
  name?: Maybe<Scalars['String']>;
}>;


export type SaveButton_CreateAppSpaceFromImageMutation = { __typename?: 'Mutation', createAppSpaceFromImage: { __typename?: 'Query', appSpaces: Array<(
      { __typename?: 'SessionAppSpace' }
      & AppSpaceTabs_AppSpaceFragment
    )>, activeAppSpace?: Maybe<(
      { __typename?: 'AppSpace' }
      & AppSpace_ActiveAppSpaceFragment
    )> } };

export type SaveButton_CreateAppSpaceFromUrlMutationVariables = Exact<{
  url: Scalars['String'];
  name?: Maybe<Scalars['String']>;
}>;


export type SaveButton_CreateAppSpaceFromUrlMutation = { __typename?: 'Mutation', createAppSpaceFromUrl: { __typename?: 'Query', appSpaces: Array<(
      { __typename?: 'SessionAppSpace' }
      & AppSpaceTabs_AppSpaceFragment
    )>, activeAppSpace?: Maybe<(
      { __typename?: 'AppSpace' }
      & AppSpace_ActiveAppSpaceFragment
    )> } };

export type SaveButton_RenameAppSpaceMutationVariables = Exact<{
  id: Scalars['ID'];
  newName: Scalars['String'];
}>;


export type SaveButton_RenameAppSpaceMutation = { __typename?: 'Mutation', renameAppSpace: { __typename?: 'AppSpace', id: string, name: string } };

export type SaveButton_CreateAppSpaceFromContentMutationVariables = Exact<{
  from: Scalars['String'];
  name?: Maybe<Scalars['String']>;
}>;


export type SaveButton_CreateAppSpaceFromContentMutation = { __typename?: 'Mutation', createAppSpaceFromContent: { __typename?: 'Query', appSpaces: Array<(
      { __typename?: 'SessionAppSpace' }
      & AppSpaceTabs_AppSpaceFragment
    )>, activeAppSpace?: Maybe<(
      { __typename?: 'AppSpace' }
      & AppSpace_ActiveAppSpaceFragment
    )> } };

export type SearchFileListingQueryVariables = Exact<{ [key: string]: never; }>;


export type SearchFileListingQuery = { __typename?: 'Query', activeAppSpace?: Maybe<{ __typename?: 'AppSpace', id: string }> };

export type SearchResult_WebItemFragment = { __typename?: 'WebSearchResultItem', _type?: Maybe<string>, id: string, name?: Maybe<string>, url?: Maybe<string>, description?: Maybe<string>, thumbnailUrl?: Maybe<string>, text?: Maybe<string>, contentUrl?: Maybe<string>, width?: Maybe<number>, height?: Maybe<number>, displayUrl?: Maybe<string>, snippet?: Maybe<string>, content?: Maybe<string>, wordCount?: Maybe<number>, viewCount?: Maybe<number>, items?: Maybe<Array<Maybe<{ __typename?: 'WebSearchResultItem', _type?: Maybe<string>, thumbnailUrl?: Maybe<string>, contentUrl?: Maybe<string>, id: string, text?: Maybe<string>, url?: Maybe<string>, description?: Maybe<string>, displayText?: Maybe<string>, viewCount?: Maybe<number>, name?: Maybe<string>, duration?: Maybe<string>, image?: Maybe<{ __typename?: 'WebSearchResultItem', thumbnail?: Maybe<{ __typename?: 'WebSearchResultItem', contentUrl?: Maybe<string>, width?: Maybe<number>, height?: Maybe<number> }> }> }>>>, image?: Maybe<{ __typename?: 'WebSearchResultItem', _type?: Maybe<string> }>, thumbnail?: Maybe<{ __typename?: 'WebSearchResultItem', _type?: Maybe<string> }> };

export type SearchResult_SearchResultFragment = { __typename?: 'SearchResult', query?: Maybe<string>, type: string, offset: number, count: number, totalCount: any, web?: Maybe<{ __typename?: 'WebSearchResult', webResult?: Maybe<Array<Maybe<(
      { __typename?: 'WebSearchResultItem' }
      & SearchResult_WebItemFragment
    )>>>, imagesPreview?: Maybe<Array<Maybe<{ __typename?: 'WebSearchResultItem', contentUrl?: Maybe<string>, thumbnailUrl?: Maybe<string> }>>>, videosPreview?: Maybe<Array<Maybe<{ __typename?: 'WebSearchResultItem', contentUrl?: Maybe<string>, thumbnailUrl?: Maybe<string> }>>>, newsPreview?: Maybe<Array<Maybe<{ __typename?: 'WebSearchResultItem', name?: Maybe<string>, url?: Maybe<string>, description?: Maybe<string>, image?: Maybe<{ __typename?: 'WebSearchResultItem', thumbnail?: Maybe<{ __typename?: 'WebSearchResultItem', contentUrl?: Maybe<string> }> }> }>>> }>, images?: Maybe<Array<Maybe<{ __typename?: 'WebSearchResultItem', id: string, _type?: Maybe<string>, thumbnailUrl?: Maybe<string>, contentUrl?: Maybe<string>, webSearchUrl?: Maybe<string>, name?: Maybe<string>, width?: Maybe<number>, height?: Maybe<number> }>>>, videos?: Maybe<Array<Maybe<{ __typename?: 'WebSearchResultItem', id: string, thumbnailUrl?: Maybe<string>, name?: Maybe<string>, viewCount?: Maybe<number>, contentUrl?: Maybe<string> }>>>, news?: Maybe<Array<Maybe<{ __typename?: 'WebSearchResultItem', id: string, name?: Maybe<string>, url?: Maybe<string>, datePublished?: Maybe<string>, description?: Maybe<string>, image?: Maybe<{ __typename?: 'WebSearchResultItem', thumbnail?: Maybe<{ __typename?: 'WebSearchResultItem', contentUrl?: Maybe<string> }> }> }>>> };

export type SearchResultQueryVariables = Exact<{
  messageId: Scalars['ID'];
  count: Scalars['Int'];
  offset: Scalars['Int'];
  type: Scalars['String'];
  query?: Maybe<Scalars['String']>;
}>;


export type SearchResultQuery = { __typename?: 'Query', message?: Maybe<{ __typename?: 'Message', searchResult?: Maybe<(
      { __typename?: 'SearchResult' }
      & SearchResult_SearchResultFragment
    )> }>, activeAppSpace?: Maybe<{ __typename?: 'AppSpace', id: string }> };

export type WikipediaResult_WikiQueryVariables = Exact<{
  messageId: Scalars['ID'];
  query: Scalars['String'];
}>;


export type WikipediaResult_WikiQuery = { __typename?: 'Query', message?: Maybe<{ __typename?: 'Message', wiki?: Maybe<{ __typename?: 'WikipediaResult', title?: Maybe<string>, url?: Maybe<string>, pageId?: Maybe<string>, category?: Maybe<string>, sections?: Maybe<Array<{ __typename?: 'WikipediaSection', title?: Maybe<string>, content?: Maybe<{ __typename?: 'WikipediaSectionContent', paragraphs?: Maybe<Array<Maybe<{ __typename?: 'Paragraph', sentences?: Maybe<Array<Maybe<string>>> }>>> }> }>>, data?: Maybe<Array<Maybe<{ __typename?: 'WikipediaData', title?: Maybe<string>, tables?: Maybe<Array<Maybe<string>>> }>>>, image?: Maybe<{ __typename?: 'WikipediaImage', thumbnail?: Maybe<string>, url?: Maybe<string>, alt?: Maybe<string> }> }> }>, activeAppSpace?: Maybe<{ __typename?: 'AppSpace', id: string }> };

export type ImagesQueryVariables = Exact<{ [key: string]: never; }>;


export type ImagesQuery = { __typename?: 'Query', activeAppSpace?: Maybe<{ __typename?: 'AppSpace', id: string }> };

export type VideosQueryVariables = Exact<{ [key: string]: never; }>;


export type VideosQuery = { __typename?: 'Query', activeAppSpace?: Maybe<{ __typename?: 'AppSpace', id: string }> };

export type UserChatStatus_UserStatusFragment = { __typename?: 'UserChatStatus', status?: Maybe<string> };

export type UserChatStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type UserChatStatusQuery = { __typename?: 'Query', activeAppSpace?: Maybe<{ __typename?: 'AppSpace', id: string }>, activeSession: { __typename?: 'Session', id: string } };

export type UserChatStatus_OnUserChatStatusChangedSubscriptionVariables = Exact<{
  session: Scalars['ID'];
}>;


export type UserChatStatus_OnUserChatStatusChangedSubscription = { __typename?: 'Subscription', userChatStatusChanged?: Maybe<(
    { __typename?: 'UserChatStatus' }
    & UserChatStatus_UserStatusFragment
  )> };

export type VideoResultQueryVariables = Exact<{
  messageId: Scalars['ID'];
  count: Scalars['Int'];
}>;


export type VideoResultQuery = { __typename?: 'Query', message?: Maybe<{ __typename?: 'Message', videos?: Maybe<Array<{ __typename?: 'WebSearchResultItem', id: string, thumbnailUrl?: Maybe<string>, description?: Maybe<string>, text?: Maybe<string>, viewCount?: Maybe<number>, contentUrl?: Maybe<string>, embedHtml?: Maybe<string> }>> }> };

export type MessageCardUserFragment = { __typename?: 'GroupMember', id: string, profilePicture: string, fullName: string };

export type MessageCardQueryVariables = Exact<{ [key: string]: never; }>;


export type MessageCardQuery = { __typename?: 'Query', unreadMessages: Array<{ __typename?: 'UnreadThreadResult', count: number, message: { __typename?: 'Message', id: string, type: string, textContent?: Maybe<string>, createdDate: any, user?: Maybe<{ __typename?: 'User', profilePicture: string, fullName: string }> }, group: { __typename?: 'Group', id: string, name: string, type: string, otherMember?: Maybe<(
        { __typename?: 'GroupMember' }
        & MessageCardUserFragment
      )>, members: Array<(
        { __typename?: 'GroupMember' }
        & MessageCardUserFragment
      )> } }> };

export type TasksCardQueryVariables = Exact<{ [key: string]: never; }>;


export type TasksCardQuery = { __typename?: 'Query', recentTasksToDo: Array<{ __typename?: 'RecentTaskToDoResult', task: { __typename?: 'Task', id: string, title: string, description: string, idNumber: number, createdDate: any, assignees: Array<{ __typename?: 'User', id: string, profilePicture: string, fullName: string }>, state: { __typename?: 'TaskState', id: string, name: string, color: string } }, board: { __typename?: 'TaskBoard', id: string, idStr: string } }> };

export type TasksCard_MarkTaskCompleteMutationVariables = Exact<{
  taskId: Scalars['ID'];
}>;


export type TasksCard_MarkTaskCompleteMutation = { __typename?: 'Mutation', markTaskComplete?: Maybe<any> };

export type WikisCardQueryVariables = Exact<{ [key: string]: never; }>;


export type WikisCardQuery = { __typename?: 'Query', recentWikiPages: Array<{ __typename?: 'RecentWikiPageResult', wikiPage: { __typename?: 'File', id: string, name: string, content?: Maybe<string>, createdDate?: Maybe<any> }, wiki: { __typename?: 'Wiki', id: string, idStr: string, name: string, users: Array<{ __typename?: 'User', id: string, fullName: string, profilePicture: string }> } }> };

export type DashboardHeaderQueryVariables = Exact<{ [key: string]: never; }>;


export type DashboardHeaderQuery = { __typename?: 'Query', me?: Maybe<{ __typename?: 'User', id: string, fullName: string }> };

export type OrganizationSwitcherQueryVariables = Exact<{ [key: string]: never; }>;


export type OrganizationSwitcherQuery = { __typename?: 'Query', organization: { __typename?: 'Organization', name: string, id: string, profilePicture: string, spaces: Array<{ __typename?: 'Space', id: string, name: string }> }, organizations: Array<{ __typename?: 'Organization', id: string, name: string, memberCount: number, profilePicture: string }> };

export type HomeQueryVariables = Exact<{ [key: string]: never; }>;


export type HomeQuery = { __typename?: 'Query', activeSession: { __typename?: 'Session', id: string, groupId?: Maybe<string> } };

export type JournalQueryVariables = Exact<{
  date: Scalars['String'];
}>;


export type JournalQuery = { __typename?: 'Query', journal: { __typename?: 'JournalEntry', file: { __typename?: 'File', id: string } } };

export type JournalEntrySelector_JournalCountQueryVariables = Exact<{
  days: Array<Scalars['String']> | Scalars['String'];
}>;


export type JournalEntrySelector_JournalCountQuery = { __typename?: 'Query', journalCountForDays: Array<{ __typename?: 'CountResult', day: string, count: number }> };

export type JournalEntryFragment = { __typename?: 'JournalEntry', id: string, createdDate?: Maybe<any>, title: string, file: { __typename?: 'File', id: string, content?: Maybe<string> } };

export type JournalEntriesQueryVariables = Exact<{
  date: Scalars['String'];
}>;


export type JournalEntriesQuery = { __typename?: 'Query', journalEntries: Array<(
    { __typename?: 'JournalEntry' }
    & JournalEntryFragment
  )> };

export type JournalEntries_CreateJournalEntryMutationVariables = Exact<{
  title: Scalars['String'];
  date: Scalars['String'];
}>;


export type JournalEntries_CreateJournalEntryMutation = { __typename?: 'Mutation', createJournalEntry?: Maybe<any> };

export type JournalEntries_RenameEntryMutationVariables = Exact<{
  id: Scalars['ID'];
  title: Scalars['String'];
}>;


export type JournalEntries_RenameEntryMutation = { __typename?: 'Mutation', renameJournalEntry: { __typename?: 'JournalEntry', id: string, title: string } };

export type JournalEntries_DeleteEntryMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type JournalEntries_DeleteEntryMutation = { __typename?: 'Mutation', deleteJournalEntry?: Maybe<any> };

export type NoteItemLabelsQueryVariables = Exact<{ [key: string]: never; }>;


export type NoteItemLabelsQuery = { __typename?: 'Query', noteLabels: Array<Maybe<(
    { __typename?: 'NoteLabel' }
    & Notes_NoteLabelFragment
  )>> };

export type NoteItemLabels_ToggleLabelMutationVariables = Exact<{
  noteId: Scalars['ID'];
  labelId: Scalars['ID'];
}>;


export type NoteItemLabels_ToggleLabelMutation = { __typename?: 'Mutation', toggleNoteLabel: { __typename?: 'Note', id: string, labels: Array<(
      { __typename?: 'NoteLabel' }
      & Notes_NoteLabelFragment
    )> } };

export type NoteModal_RenameNoteMutationVariables = Exact<{
  id: Scalars['ID'];
  title: Scalars['String'];
}>;


export type NoteModal_RenameNoteMutation = { __typename?: 'Mutation', renameNote: { __typename?: 'Note', id: string, title: string } };

export type Notes_NoteLabelFragment = { __typename?: 'NoteLabel', id: string, name: string, color: string };

export type Notes_NoteFragment = { __typename?: 'Note', id: string, title: string, createdDate: any, file: { __typename?: 'File', id: string, content?: Maybe<string> }, labels: Array<{ __typename?: 'NoteLabel', id: string, name: string, color: string }> };

export type Notes_NoteLabelsQueryVariables = Exact<{ [key: string]: never; }>;


export type Notes_NoteLabelsQuery = { __typename?: 'Query', noteLabels: Array<Maybe<(
    { __typename?: 'NoteLabel' }
    & Notes_NoteLabelFragment
  )>> };

export type Notes_NotesQueryVariables = Exact<{
  offset: Scalars['Int'];
  take: Scalars['Int'];
  label?: Maybe<Scalars['ID']>;
}>;


export type Notes_NotesQuery = { __typename?: 'Query', notes: Array<(
    { __typename?: 'Note' }
    & Notes_NoteFragment
  )> };

export type Notes_CreateLabelMutationVariables = Exact<{
  name: Scalars['String'];
  color: Scalars['String'];
}>;


export type Notes_CreateLabelMutation = { __typename?: 'Mutation', createNoteLabel: { __typename?: 'Query', noteLabels: Array<Maybe<(
      { __typename?: 'NoteLabel' }
      & Notes_NoteLabelFragment
    )>> } };

export type Notes_CreateNoteMutationVariables = Exact<{
  title: Scalars['String'];
  content: Scalars['String'];
  labels: Array<Scalars['ID']> | Scalars['ID'];
  offset: Scalars['Int'];
  take: Scalars['Int'];
  label?: Maybe<Scalars['ID']>;
}>;


export type Notes_CreateNoteMutation = { __typename?: 'Mutation', createNote: { __typename?: 'CreateNoteResult', note?: Maybe<{ __typename?: 'Note', id: string }>, query?: Maybe<{ __typename?: 'Query', notes: Array<(
        { __typename?: 'Note' }
        & Notes_NoteFragment
      )> }> } };

export type Notes_DeleteNoteMutationVariables = Exact<{
  noteId: Scalars['ID'];
}>;


export type Notes_DeleteNoteMutation = { __typename?: 'Mutation', deleteNote?: Maybe<any> };

export type OrganizationMemberSearchQueryVariables = Exact<{
  searchText?: Maybe<Scalars['String']>;
  skipGroupId?: Maybe<Scalars['ID']>;
}>;


export type OrganizationMemberSearchQuery = { __typename?: 'Query', organization: { __typename?: 'Organization', id: string, members: Array<{ __typename?: 'OrganizationMember', user: { __typename?: 'User', id: string, fullName: string, email: string } }> } };

export type OrganizationTeamSearchQueryVariables = Exact<{
  searchText?: Maybe<Scalars['String']>;
}>;


export type OrganizationTeamSearchQuery = { __typename?: 'Query', organization: { __typename?: 'Organization', id: string, teams: Array<{ __typename?: 'Team', id: string, name: string }> } };

export type People_SelectOrganizationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type People_SelectOrganizationMutation = { __typename?: 'Mutation', selectOrganization: { __typename?: 'Query', organization: { __typename?: 'Organization', id: string, role: string, teams: Array<{ __typename?: 'Team', id: string, name: string }>, spaces: Array<{ __typename?: 'Space', id: string, name: string }> } } };

export type People_SelectSpaceMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type People_SelectSpaceMutation = { __typename?: 'Mutation', selectSpace: { __typename?: 'Query', activeSpace?: Maybe<{ __typename?: 'Space', id: string, name: string }> } };

export type Organization_OrganizationQueryVariables = Exact<{ [key: string]: never; }>;


export type Organization_OrganizationQuery = { __typename?: 'Query', organization: { __typename?: 'Organization', id: string, name: string, role: string, profilePicture: string, members: Array<{ __typename?: 'OrganizationMember', id: string, role: string, user: { __typename?: 'User', id: string, fullName: string, email: string, profilePicture: string } }> } };

export type Organization_RenameOrganizationMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type Organization_RenameOrganizationMutation = { __typename?: 'Mutation', renameOrganization: { __typename?: 'Organization', id: string, name: string } };

export type Organization_RemoveMemberMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type Organization_RemoveMemberMutation = { __typename?: 'Mutation', removeMemberFromOrganization: { __typename?: 'Organization', id: string, memberCount: number, members: Array<{ __typename?: 'OrganizationMember', id: string, role: string, user: { __typename?: 'User', id: string, fullName: string, email: string } }> } };

export type Organization_AddMemberMutationVariables = Exact<{
  userEmail: Scalars['String'];
}>;


export type Organization_AddMemberMutation = { __typename?: 'Mutation', addMemberToOrganization: { __typename?: 'Organization', id: string, memberCount: number, members: Array<{ __typename?: 'OrganizationMember', id: string, role: string, user: { __typename?: 'User', fullName: string, email: string } }> } };

export type Organization_CreateOrganizationMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type Organization_CreateOrganizationMutation = { __typename?: 'Mutation', createOrganization: Array<{ __typename?: 'Organization', id: string, name: string }> };

export type Organization_UploadProfilePictureMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type Organization_UploadProfilePictureMutation = { __typename?: 'Mutation', uploadOrganizationProfilePicture: string };

export type Organizations_OrganizationsQueryVariables = Exact<{ [key: string]: never; }>;


export type Organizations_OrganizationsQuery = { __typename?: 'Query', organizations: Array<{ __typename?: 'Organization', id: string, name: string, role: string, profilePicture: string }>, organization: { __typename?: 'Organization', id: string, name: string } };

export type Spaces_SpacesQueryVariables = Exact<{ [key: string]: never; }>;


export type Spaces_SpacesQuery = { __typename?: 'Query', organization: { __typename?: 'Organization', id: string, role: string, spaces: Array<{ __typename?: 'Space', id: string, name: string }> } };

export type Spaces_OrgTeamsQueryVariables = Exact<{ [key: string]: never; }>;


export type Spaces_OrgTeamsQuery = { __typename?: 'Query', organization: { __typename?: 'Organization', teams: Array<{ __typename?: 'Team', id: string, name: string }> } };

export type Spaces_CreateSpaceMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type Spaces_CreateSpaceMutation = { __typename?: 'Mutation', createSpace: { __typename?: 'Organization', id: string, spaces: Array<{ __typename?: 'Space', id: string, name: string }> } };

export type Spaces_SpaceQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type Spaces_SpaceQuery = { __typename?: 'Query', space: { __typename?: 'Space', id: string, name: string, teams: Array<{ __typename?: 'Team', id: string, name: string }> } };

export type Spaces_RenameSpaceMutationVariables = Exact<{
  id: Scalars['ID'];
  newName: Scalars['String'];
}>;


export type Spaces_RenameSpaceMutation = { __typename?: 'Mutation', renameSpace: { __typename?: 'Space', id: string, name: string } };

export type Spaces_AddTeamMutationVariables = Exact<{
  id: Scalars['ID'];
  teamId: Scalars['ID'];
}>;


export type Spaces_AddTeamMutation = { __typename?: 'Mutation', addTeamToSpace: { __typename?: 'Space', id: string, teams: Array<{ __typename?: 'Team', id: string, name: string }> } };

export type Spaces_RemoveTeamMutationVariables = Exact<{
  id: Scalars['ID'];
  teamId: Scalars['ID'];
}>;


export type Spaces_RemoveTeamMutation = { __typename?: 'Mutation', removeTeamFromSpace: { __typename?: 'Space', id: string, teams: Array<{ __typename?: 'Team', id: string, name: string }> } };

export type Spaces_RemoveSpaceMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type Spaces_RemoveSpaceMutation = { __typename?: 'Mutation', removeSpace: { __typename?: 'Organization', id: string, spaces: Array<{ __typename?: 'Space', id: string, name: string }> } };

export type Teams_TeamMemberFragment = { __typename?: 'TeamMember', id: string, user: { __typename?: 'User', fullName: string, email: string } };

export type Organization_TeamsQueryVariables = Exact<{ [key: string]: never; }>;


export type Organization_TeamsQuery = { __typename?: 'Query', organization: { __typename?: 'Organization', id: string, role: string, teams: Array<{ __typename?: 'Team', id: string, name: string }> } };

export type Teams_CreateTeamMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type Teams_CreateTeamMutation = { __typename?: 'Mutation', createTeam: { __typename?: 'Organization', id: string, teams: Array<{ __typename?: 'Team', id: string, name: string }> } };

export type Teams_TeamQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type Teams_TeamQuery = { __typename?: 'Query', team: { __typename?: 'Team', id: string, name: string, members: Array<(
      { __typename?: 'TeamMember' }
      & Teams_TeamMemberFragment
    )> } };

export type Teams_OrgMembersQueryVariables = Exact<{ [key: string]: never; }>;


export type Teams_OrgMembersQuery = { __typename?: 'Query', organization: { __typename?: 'Organization', id: string, members: Array<{ __typename?: 'OrganizationMember', id: string, user: { __typename?: 'User', id: string, fullName: string } }> } };

export type Teams_RenameTeamMutationVariables = Exact<{
  id: Scalars['ID'];
  newName: Scalars['String'];
}>;


export type Teams_RenameTeamMutation = { __typename?: 'Mutation', renameTeam: { __typename?: 'Team', id: string, name: string } };

export type Teams_AddMemberMutationVariables = Exact<{
  id: Scalars['ID'];
  memberId: Scalars['ID'];
}>;


export type Teams_AddMemberMutation = { __typename?: 'Mutation', addMemberToTeam: { __typename?: 'Team', id: string, members: Array<(
      { __typename?: 'TeamMember' }
      & Teams_TeamMemberFragment
    )> } };

export type Teams_RemoveMemberMutationVariables = Exact<{
  id: Scalars['ID'];
  memberId: Scalars['ID'];
}>;


export type Teams_RemoveMemberMutation = { __typename?: 'Mutation', removeMemberFromTeam: { __typename?: 'Team', id: string, members: Array<(
      { __typename?: 'TeamMember' }
      & Teams_TeamMemberFragment
    )> } };

export type Teams_RemoveTeamMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type Teams_RemoveTeamMutation = { __typename?: 'Mutation', removeTeam: { __typename?: 'Organization', id: string, teams: Array<{ __typename?: 'Team', id: string, name: string }> } };

export type BookmarkButton_CreateBookmarkMutationVariables = Exact<{
  url: Scalars['String'];
  title: Scalars['String'];
}>;


export type BookmarkButton_CreateBookmarkMutation = { __typename?: 'Mutation', createBookmark?: Maybe<{ __typename?: 'Bookmark', title: string, url: string }> };

export type EmojiSelectorQueryVariables = Exact<{ [key: string]: never; }>;


export type EmojiSelectorQuery = { __typename?: 'Query', me?: Maybe<{ __typename?: 'User', id: string, skinTonePreference: number }>, emoticons: Array<{ __typename?: 'Emoticon', id: string, code: string, name: string, icon: string, category: string }> };

export type EmojiSelector_UpdateSkinTonePreferenceMutationVariables = Exact<{
  tone: Scalars['Int'];
}>;


export type EmojiSelector_UpdateSkinTonePreferenceMutation = { __typename?: 'Mutation', updateSkinTonePreference: { __typename?: 'Query', emoticons: Array<{ __typename?: 'Emoticon', id: string, code: string, name: string, icon: string, category: string }> } };

export type FileEditorQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FileEditorQuery = { __typename?: 'Query', file?: Maybe<{ __typename?: 'File', id: string, content?: Maybe<string> }> };

export type ListingPageQueryVariables = Exact<{ [key: string]: never; }>;


export type ListingPageQuery = { __typename?: 'Query', organization: { __typename?: 'Organization', name: string, id: string } };

export type PretzelTextEditor_UploadTempFileMutationVariables = Exact<{
  fileName: Scalars['String'];
  fileType: Scalars['String'];
  fileSize: Scalars['Int'];
}>;


export type PretzelTextEditor_UploadTempFileMutation = { __typename?: 'Mutation', uploadTempFile?: Maybe<{ __typename?: 'FileUploadOutput', uploadId: string, postData: string }> };

export type TaskSelectorQueryVariables = Exact<{ [key: string]: never; }>;


export type TaskSelectorQuery = { __typename?: 'Query', allTasks: Array<{ __typename?: 'AllTaskResult', boardIdStr: string, task: { __typename?: 'Task', id: string, title: string, description: string, boardId: string, idNumber: number, state: { __typename?: 'TaskState', id: string, color: string }, assignees: Array<{ __typename?: 'User', id: string, fullName: string, profilePicture: string }>, breadcrumbs: Array<{ __typename?: 'TaskBreadcrumb', id: string, title: string, idNumber: number, state: { __typename?: 'TaskState', id: string, color: string } }> } }> };

export type UserSelectorQueryVariables = Exact<{ [key: string]: never; }>;


export type UserSelectorQuery = { __typename?: 'Query', organization: { __typename?: 'Organization', id: string, members: Array<{ __typename?: 'OrganizationMember', user: { __typename?: 'User', fullName: string, id: string, profilePicture: string } }> } };

export type ProfilePictureQueryVariables = Exact<{ [key: string]: never; }>;


export type ProfilePictureQuery = { __typename?: 'Query', activeSession: { __typename?: 'Session', id: string, isActive: boolean } };

export type ProfilePicture_UserQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type ProfilePicture_UserQuery = { __typename?: 'Query', user?: Maybe<{ __typename?: 'User', id: string, isActive: boolean, lastSeen?: Maybe<any> }> };

export type Searchbar_CreateAppSpaceMutationVariables = Exact<{
  type: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
}>;


export type Searchbar_CreateAppSpaceMutation = { __typename?: 'Mutation', createAppSpace: { __typename?: 'Query', appSpaces: Array<(
      { __typename?: 'SessionAppSpace' }
      & AppSpaceTabs_AppSpaceFragment
    )>, activeAppSpace?: Maybe<(
      { __typename?: 'AppSpace' }
      & AppSpace_ActiveAppSpaceFragment
    )> } };

export type SearchbarQueryVariables = Exact<{ [key: string]: never; }>;


export type SearchbarQuery = { __typename?: 'Query', appSpaceSettings: { __typename?: 'AppSpaceSettings', appSpaceLimitPerSession: number } };

export type TaskAssigneeSelectorQueryVariables = Exact<{
  boardId: Scalars['ID'];
}>;


export type TaskAssigneeSelectorQuery = { __typename?: 'Query', taskBoard: { __typename?: 'TaskBoard', users: Array<(
      { __typename?: 'User' }
      & TaskModal_UserFragment
    )> } };

export type TaskAssigneeSelector_ToggleUserMutationVariables = Exact<{
  taskId: Scalars['ID'];
  assigneeId: Scalars['ID'];
}>;


export type TaskAssigneeSelector_ToggleUserMutation = { __typename?: 'Mutation', toggleTaskAssignee: (
    { __typename?: 'Task' }
    & TaskModal_TaskFragment
  ) };

export type TaskBoard_TaskBoardFragment = { __typename?: 'TaskBoard', id: string, idStr: string, name: string, canEdit: boolean, users: Array<(
    { __typename?: 'User' }
    & TaskModal_UserFragment
  )> };

export type TaskBoardQueryVariables = Exact<{
  idStr: Scalars['String'];
}>;


export type TaskBoardQuery = { __typename?: 'Query', activeSession: { __typename?: 'Session', id: string }, taskBoard: (
    { __typename?: 'TaskBoard' }
    & TaskBoard_TaskBoardFragment
  ) };

export type TaskBoard_UpdateTaskBoardNameMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
}>;


export type TaskBoard_UpdateTaskBoardNameMutation = { __typename?: 'Mutation', updateTaskBoardName: { __typename?: 'TaskBoard', id: string, name: string } };

export type TaskBoard_TaskBoardUpdatedSubscriptionVariables = Exact<{
  sessionId: Scalars['ID'];
  boardId: Scalars['ID'];
}>;


export type TaskBoard_TaskBoardUpdatedSubscription = { __typename?: 'Subscription', taskBoardUpdated: (
    { __typename?: 'TaskBoard', states: Array<(
      { __typename?: 'TaskState' }
      & BoardLane_TaskStateFragment
    )> }
    & TaskBoard_TaskBoardFragment
  ) };

export type TaskBoard_TaskStatesUpdatedSubscriptionVariables = Exact<{
  sessionId: Scalars['ID'];
  boardId: Scalars['ID'];
}>;


export type TaskBoard_TaskStatesUpdatedSubscription = { __typename?: 'Subscription', taskStatesUpdated: Array<(
    { __typename?: 'TaskState', tasks: Array<(
      { __typename?: 'Task' }
      & TaskCard_TaskFragment
    )> }
    & BoardLane_TaskStateFragment
  )> };

export type TaskBoard_TaskUpdatedSubscriptionVariables = Exact<{
  sessionId: Scalars['ID'];
  boardId: Scalars['ID'];
}>;


export type TaskBoard_TaskUpdatedSubscription = { __typename?: 'Subscription', taskUpdated: Array<(
    { __typename?: 'Task' }
    & TaskCard_TaskFragment
  )> };

export type TaskBoardBoardViewQueryVariables = Exact<{
  boardId?: Maybe<Scalars['ID']>;
}>;


export type TaskBoardBoardViewQuery = { __typename?: 'Query', taskBoard: { __typename?: 'TaskBoard', id: string, states: Array<(
      { __typename?: 'TaskState' }
      & BoardLane_TaskStateFragment
    )> } };

export type TaskBoardBoardView_CreateTaskStateMutationVariables = Exact<{
  boardId: Scalars['ID'];
  name: Scalars['String'];
  color: Scalars['String'];
}>;


export type TaskBoardBoardView_CreateTaskStateMutation = { __typename?: 'Mutation', createTaskState: { __typename?: 'TaskBoard', id: string, states: Array<(
      { __typename?: 'TaskState' }
      & BoardLane_TaskStateFragment
    )> } };

export type TaskBoardBoardView_ReOrderTaskMutationVariables = Exact<{
  taskId: Scalars['ID'];
  toTask: Scalars['ID'];
}>;


export type TaskBoardBoardView_ReOrderTaskMutation = { __typename?: 'Mutation', reorderTask: Array<{ __typename?: 'Task', id: string, stateOrder: number }> };

export type TaskBoardBoardView_ReOrderStateMutationVariables = Exact<{
  stateId: Scalars['ID'];
  toState: Scalars['ID'];
}>;


export type TaskBoardBoardView_ReOrderStateMutation = { __typename?: 'Mutation', reorderTaskState: Array<{ __typename?: 'TaskState', id: string, order: number }> };

export type ArchivedBoardLaneQueryVariables = Exact<{
  boardId: Scalars['ID'];
}>;


export type ArchivedBoardLaneQuery = { __typename?: 'Query', taskBoard: { __typename?: 'TaskBoard', archivedTasks: Array<(
      { __typename?: 'Task' }
      & TaskCard_TaskFragment
    )> } };

export type BoardLane_TaskStateFragment = { __typename?: 'TaskState', id: string, name: string, color: string, isComplete: boolean, order: number, isArchived: boolean };

export type BoardLaneQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type BoardLaneQuery = { __typename?: 'Query', taskState: (
    { __typename?: 'TaskState', tasks: Array<(
      { __typename?: 'Task' }
      & TaskCard_TaskFragment
    )> }
    & BoardLane_TaskStateFragment
  ) };

export type BoardLane_UpdateTaskStateMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  color: Scalars['String'];
}>;


export type BoardLane_UpdateTaskStateMutation = { __typename?: 'Mutation', updateTaskState?: Maybe<{ __typename?: 'TaskState', id: string, name: string, color: string }> };

export type BoardLane_CreateTaskMutationVariables = Exact<{
  stateId: Scalars['ID'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['ID']>;
}>;


export type BoardLane_CreateTaskMutation = { __typename?: 'Mutation', createTask: { __typename?: 'CreateTaskResult', task: (
      { __typename?: 'Task' }
      & TaskCard_TaskFragment
    ) } };

export type BoardLane_ArchiveTaskMutationVariables = Exact<{
  taskId: Scalars['ID'];
  stateId: Scalars['ID'];
}>;


export type BoardLane_ArchiveTaskMutation = { __typename?: 'Mutation', archiveTask: { __typename?: 'Query', taskState: { __typename?: 'TaskState', tasks: Array<(
        { __typename?: 'Task' }
        & TaskCard_TaskFragment
      )> } } };

export type BoardLane_ToggleCompletedStateMutationVariables = Exact<{
  stateId: Scalars['ID'];
  boardId: Scalars['ID'];
}>;


export type BoardLane_ToggleCompletedStateMutation = { __typename?: 'Mutation', toggleCompletedState: { __typename?: 'Query', taskBoard: { __typename?: 'TaskBoard', id: string, states: Array<(
        { __typename?: 'TaskState', tasks: Array<(
          { __typename?: 'Task' }
          & TaskCard_TaskFragment
        )> }
        & BoardLane_TaskStateFragment
      )> } } };

export type BoardLane_ArchiveTaskStateMutationVariables = Exact<{
  stateId: Scalars['ID'];
  boardId: Scalars['ID'];
}>;


export type BoardLane_ArchiveTaskStateMutation = { __typename?: 'Mutation', archiveTaskState: { __typename?: 'Query', taskBoard: { __typename?: 'TaskBoard', id: string, states: Array<(
        { __typename?: 'TaskState', tasks: Array<(
          { __typename?: 'Task' }
          & TaskCard_TaskFragment
        )> }
        & BoardLane_TaskStateFragment
      )> } } };

export type TaskCard_UserFragment = { __typename?: 'User', id: string, fullName: string, profilePicture: string };

export type TaskCard_TaskFragment = { __typename?: 'Task', id: string, title: string, description: string, commentCount: number, attachmentCount: number, idNumber: number, stateOrder: number, parentOrder: number, createdDate: any, creator: (
    { __typename?: 'User' }
    & TaskCard_UserFragment
  ), assignees: Array<(
    { __typename?: 'User' }
    & TaskCard_UserFragment
  )>, state: { __typename?: 'TaskState', id: string }, breadcrumbs: Array<(
    { __typename?: 'TaskBreadcrumb' }
    & TaskModal_BreadcrumbFragment
  )>, progress: { __typename?: 'TaskProgress', completed: number, total: number } };

export type TaskBoardFiltersSelectorQueryVariables = Exact<{
  boardId: Scalars['ID'];
}>;


export type TaskBoardFiltersSelectorQuery = { __typename?: 'Query', taskBoard: { __typename?: 'TaskBoard', id: string, users: Array<(
      { __typename?: 'User' }
      & TaskModal_UserFragment
    )>, states: Array<(
      { __typename?: 'TaskState' }
      & BoardLane_TaskStateFragment
    )> } };

export type TaskBoardModalQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TaskBoardModalQuery = { __typename?: 'Query', taskBoard: { __typename?: 'TaskBoard', name: string, description: string, teams: Array<{ __typename?: 'Team', id: string, name: string }>, users: Array<{ __typename?: 'User', id: string, fullName: string }> } };

export type TaskBoardModal_CreateTaskBoardMutationVariables = Exact<{
  name: Scalars['String'];
  description: Scalars['String'];
  states: Array<Maybe<TaskStateInput>> | Maybe<TaskStateInput>;
  teams: Array<Scalars['ID']> | Scalars['ID'];
  users: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type TaskBoardModal_CreateTaskBoardMutation = { __typename?: 'Mutation', createTaskBoard: { __typename?: 'CreateTaskBoardResult', query: { __typename?: 'Query', taskBoards: Array<(
        { __typename?: 'TaskBoard' }
        & TaskHome_TaskBoardFragment
      )> }, created: { __typename?: 'TaskBoard', idStr: string } } };

export type TaskBoardModal_UpdateTaskBoardMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  states: Array<Maybe<TaskStateInput>> | Maybe<TaskStateInput>;
  teams: Array<Scalars['ID']> | Scalars['ID'];
  users: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type TaskBoardModal_UpdateTaskBoardMutation = { __typename?: 'Mutation', updateTaskBoard: (
    { __typename?: 'TaskBoard' }
    & TaskHome_TaskBoardFragment
  ) };

export type TaskBoardTreeView_UserFragment = { __typename?: 'User', id: string, fullName: string, profilePicture: string };

export type TaskBoardTreeView_TaskFragment = { __typename?: 'Task', id: string, idNumber: number, title: string, description: string, createdDate: any, parentOrder: number, progress: { __typename?: 'TaskProgress', total: number, completed: number }, state: { __typename?: 'TaskState', id: string, isComplete: boolean, color: string, name: string }, assignees: Array<(
    { __typename?: 'User' }
    & TaskBoardTreeView_UserFragment
  )>, creator: (
    { __typename?: 'User' }
    & TaskBoardTreeView_UserFragment
  ) };

export type TaskBoardTreeViewQueryVariables = Exact<{
  boardId: Scalars['ID'];
}>;


export type TaskBoardTreeViewQuery = { __typename?: 'Query', taskBoard: { __typename?: 'TaskBoard', id: string, states: Array<(
      { __typename?: 'TaskState' }
      & TaskStateSwitch_TaskStateFragment
    )>, rootTasks: Array<(
      { __typename?: 'Task' }
      & TaskBoardTreeView_TaskFragment
    )> } };

export type TaskBoardTreeView_TaskQueryVariables = Exact<{
  taskId: Scalars['ID'];
}>;


export type TaskBoardTreeView_TaskQuery = { __typename?: 'Query', task: { __typename?: 'Task', id: string, progress: { __typename?: 'TaskProgress', total: number, completed: number }, children: Array<(
      { __typename?: 'Task' }
      & TaskBoardTreeView_TaskFragment
    )> } };

export type TaskBoardTreeView_MoveTaskToStateMutationVariables = Exact<{
  fromStateId: Scalars['ID'];
  toStateId: Scalars['ID'];
  taskId: Scalars['ID'];
  toTask?: Maybe<Scalars['ID']>;
}>;


export type TaskBoardTreeView_MoveTaskToStateMutation = { __typename?: 'Mutation', moveTaskToState: { __typename?: 'MoveTaskResult', task: { __typename?: 'Task', id: string, stateOrder: number, progress: { __typename?: 'TaskProgress', total: number, completed: number }, state: { __typename?: 'TaskState', id: string, isComplete: boolean, color: string, name: string } }, updatedTasks: Array<{ __typename?: 'Task', id: string, stateOrder: number }>, query: { __typename?: 'Query', fromState: (
        { __typename?: 'TaskState', tasks: Array<(
          { __typename?: 'Task' }
          & TaskCard_TaskFragment
        )> }
        & BoardLane_TaskStateFragment
      ), toState: (
        { __typename?: 'TaskState', tasks: Array<(
          { __typename?: 'Task' }
          & TaskCard_TaskFragment
        )> }
        & BoardLane_TaskStateFragment
      ) } } };

export type TaskBoardTreeView_CreateTaskMutationVariables = Exact<{
  stateId: Scalars['ID'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['ID']>;
  boardId: Scalars['ID'];
}>;


export type TaskBoardTreeView_CreateTaskMutation = { __typename?: 'Mutation', createTask: { __typename?: 'CreateTaskResult', query: { __typename?: 'Query', taskBoard: { __typename?: 'TaskBoard', id: string, rootTasks: Array<(
          { __typename?: 'Task' }
          & TaskBoardTreeView_TaskFragment
        )> } } } };

export type TaskBoardTreeView_CreateChildTaskMutationVariables = Exact<{
  stateId: Scalars['ID'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['ID']>;
  boardId: Scalars['ID'];
}>;


export type TaskBoardTreeView_CreateChildTaskMutation = { __typename?: 'Mutation', createTask: { __typename?: 'CreateTaskResult', query: { __typename?: 'Query', taskBoard: { __typename?: 'TaskBoard', id: string, rootTasks: Array<(
          { __typename?: 'Task' }
          & TaskBoardTreeView_TaskFragment
        )> }, task: (
        { __typename?: 'Task', children: Array<(
          { __typename?: 'Task' }
          & TaskBoardTreeView_TaskFragment
        )> }
        & TaskBoardTreeView_TaskFragment
      ) } } };

export type TaskBoardTreeView_MoveTaskToTaskMutationVariables = Exact<{
  from: Scalars['ID'];
  to: Scalars['ID'];
  mode: Scalars['String'];
  boardId: Scalars['ID'];
}>;


export type TaskBoardTreeView_MoveTaskToTaskMutation = { __typename?: 'Mutation', moveTaskToTask: { __typename?: 'MoveTaskToTaskResult', updatedTasks: Array<(
      { __typename?: 'Task', children: Array<(
        { __typename?: 'Task' }
        & TaskBoardTreeView_TaskFragment
      )> }
      & TaskBoardTreeView_TaskFragment
    )>, query: { __typename?: 'Query', taskBoard: { __typename?: 'TaskBoard', id: string, rootTasks: Array<(
          { __typename?: 'Task' }
          & TaskBoardTreeView_TaskFragment
        )> } } } };

export type TaskBoardTreeView_ArchiveChildTaskMutationVariables = Exact<{
  taskId: Scalars['ID'];
  parentId: Scalars['ID'];
}>;


export type TaskBoardTreeView_ArchiveChildTaskMutation = { __typename?: 'Mutation', archiveTask: { __typename?: 'Query', task: (
      { __typename?: 'Task', children: Array<(
        { __typename?: 'Task' }
        & TaskBoardTreeView_TaskFragment
      )> }
      & TaskBoardTreeView_TaskFragment
    ) } };

export type TaskBoardTreeView_ArchiveTaskMutationVariables = Exact<{
  taskId: Scalars['ID'];
  boardId: Scalars['ID'];
}>;


export type TaskBoardTreeView_ArchiveTaskMutation = { __typename?: 'Mutation', archiveTask: { __typename?: 'Query', taskBoard: { __typename?: 'TaskBoard', id: string, rootTasks: Array<(
        { __typename?: 'Task' }
        & TaskBoardTreeView_TaskFragment
      )> } } };

export type Task_StatesFragment = { __typename?: 'TaskState', id: string, isComplete: boolean, color: string, name: string };

export type TaskModal_UserFragment = { __typename?: 'User', id: string, fullName: string, profilePicture: string };

export type TaskModal_AttachmentFragment = { __typename?: 'TaskAttachment', id: string, type: string, name: string, size: number, commentId?: Maybe<string> };

export type TaskModal_BreadcrumbFragment = { __typename?: 'TaskBreadcrumb', id: string, title: string, idNumber: number, state: { __typename?: 'TaskState', id: string, color: string } };

export type TaskModal_TaskFragment = { __typename?: 'Task', id: string, idNumber: number, title: string, description: string, createdDate: any, isArchived: boolean, hours?: Maybe<number>, progress: { __typename?: 'TaskProgress', total: number, completed: number }, state: { __typename?: 'TaskState', id: string, isComplete: boolean, color: string, name: string }, assignees: Array<(
    { __typename?: 'User' }
    & TaskModal_UserFragment
  )>, creator: (
    { __typename?: 'User' }
    & TaskModal_UserFragment
  ), breadcrumbs: Array<(
    { __typename?: 'TaskBreadcrumb' }
    & TaskModal_BreadcrumbFragment
  )>, children: Array<(
    { __typename?: 'Task' }
    & SubTask_TaskFragment
  )> };

export type TaskModalQueryVariables = Exact<{
  idNumber: Scalars['Int'];
  boardId: Scalars['ID'];
}>;


export type TaskModalQuery = { __typename?: 'Query', task: (
    { __typename?: 'Task' }
    & TaskModal_TaskFragment
  ), taskBoard: { __typename?: 'TaskBoard', states: Array<(
      { __typename?: 'TaskState' }
      & Task_StatesFragment
    )> }, me?: Maybe<{ __typename?: 'User', id: string, fullName: string, profilePicture: string }>, activeSession: { __typename?: 'Session', id: string } };

export type TaskModal_CreateCommentMutationVariables = Exact<{
  taskId: Scalars['ID'];
  content: Scalars['String'];
}>;


export type TaskModal_CreateCommentMutation = { __typename?: 'Mutation', createTaskComment: { __typename?: 'Task', id: string, comments: Array<(
      { __typename?: 'TaskComment' }
      & TaskComments_CommentFragment
    )> } };

export type TaskModal_UpdateTaskMutationVariables = Exact<{
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  attachments?: Maybe<Array<AttachmentInput> | AttachmentInput>;
  hours?: Maybe<Scalars['Int']>;
}>;


export type TaskModal_UpdateTaskMutation = { __typename?: 'Mutation', updateTask: { __typename?: 'Task', id: string, title: string, description: string, attachmentCount: number, hours?: Maybe<number>, attachments: Array<(
      { __typename?: 'TaskAttachment' }
      & TaskAttachments_AttachmentFragment
    )> } };

export type TaskModal_TaskDetailsUpdatedSubscriptionVariables = Exact<{
  sessionId: Scalars['ID'];
  taskId: Scalars['ID'];
}>;


export type TaskModal_TaskDetailsUpdatedSubscription = { __typename?: 'Subscription', taskDetailsUpdated: (
    { __typename?: 'Task', attachments: Array<(
      { __typename?: 'TaskAttachment' }
      & TaskAttachments_AttachmentFragment
    )>, comments: Array<(
      { __typename?: 'TaskComment' }
      & TaskComments_CommentFragment
    )>, log: Array<(
      { __typename?: 'TaskLog' }
      & TaskLogFragment
    )> }
    & TaskModal_TaskFragment
  ) };

export type TaskAttachments_AttachmentFragment = { __typename?: 'TaskAttachment', id: string, type: string, name: string, size: number, commentId?: Maybe<string>, url: string, canRemove: boolean };

export type TaskAttachmentsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TaskAttachmentsQuery = { __typename?: 'Query', task: { __typename?: 'Task', id: string, attachments: Array<(
      { __typename?: 'TaskAttachment' }
      & TaskAttachments_AttachmentFragment
    )> } };

export type TaskAttachments_RemoveAttachmentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TaskAttachments_RemoveAttachmentMutation = { __typename?: 'Mutation', removeTaskAttachment: { __typename?: 'Task', id: string, attachments: Array<(
      { __typename?: 'TaskAttachment' }
      & TaskAttachments_AttachmentFragment
    )>, comments: Array<{ __typename?: 'TaskComment', id: string, attachments: Array<(
        { __typename?: 'TaskAttachment' }
        & TaskAttachments_AttachmentFragment
      )> }> } };

export type TaskLogFragment = { __typename?: 'TaskLog', log: string, createdDate: any, user: (
    { __typename?: 'User' }
    & TaskModal_UserFragment
  ), task: { __typename?: 'Task', id: string, title: string } };

export type TaskActivityLogQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TaskActivityLogQuery = { __typename?: 'Query', task: { __typename?: 'Task', id: string, log: Array<(
      { __typename?: 'TaskLog' }
      & TaskLogFragment
    )> } };

export type TaskComments_CommentFragment = { __typename?: 'TaskComment', id: string, content: string, createdDate: any, canEdit: boolean, commentor: (
    { __typename?: 'User' }
    & TaskModal_UserFragment
  ), attachments: Array<(
    { __typename?: 'TaskAttachment' }
    & TaskAttachments_AttachmentFragment
  )>, reactions: Array<{ __typename?: 'TaskCommentReaction', id: string, reactors: Array<string>, name: string, icon: string, value: string }> };

export type TaskCommentsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TaskCommentsQuery = { __typename?: 'Query', task: { __typename?: 'Task', id: string, comments: Array<(
      { __typename?: 'TaskComment' }
      & TaskComments_CommentFragment
    )> }, me?: Maybe<{ __typename?: 'User', id: string, fullName: string, profilePicture: string }> };

export type TaskComments_CreateCommentMutationVariables = Exact<{
  taskId: Scalars['ID'];
  content: Scalars['String'];
  attachments?: Maybe<Array<AttachmentInput> | AttachmentInput>;
}>;


export type TaskComments_CreateCommentMutation = { __typename?: 'Mutation', createTaskComment: { __typename?: 'Task', id: string, attachmentCount: number, commentCount: number, comments: Array<(
      { __typename?: 'TaskComment' }
      & TaskComments_CommentFragment
    )>, attachments: Array<(
      { __typename?: 'TaskAttachment' }
      & TaskAttachments_AttachmentFragment
    )> } };

export type TaskComments_ToggleCommentReactionMutationVariables = Exact<{
  commentId: Scalars['ID'];
  value: Scalars['String'];
}>;


export type TaskComments_ToggleCommentReactionMutation = { __typename?: 'Mutation', toggleTaskCommentReaction: { __typename?: 'Task', id: string, comments: Array<{ __typename?: 'TaskComment', reactions: Array<{ __typename?: 'TaskCommentReaction', id: string, reactors: Array<string>, name: string, icon: string, value: string }> }> } };

export type TaskComments_DeleteCommentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TaskComments_DeleteCommentMutation = { __typename?: 'Mutation', deleteTaskComment: { __typename?: 'Task', id: string, commentCount: number, comments: Array<(
      { __typename?: 'TaskComment' }
      & TaskComments_CommentFragment
    )> } };

export type TaskComments_UpdateCommentMutationVariables = Exact<{
  id: Scalars['ID'];
  content: Scalars['String'];
  attachments?: Maybe<Array<AttachmentInput> | AttachmentInput>;
}>;


export type TaskComments_UpdateCommentMutation = { __typename?: 'Mutation', updateTaskComment?: Maybe<{ __typename?: 'UpdateTaskCommentResult', task: { __typename?: 'Task', id: string, attachmentCount: number }, comment: (
      { __typename?: 'TaskComment' }
      & TaskComments_CommentFragment
    ) }> };

export type TaskSubTasksQueryVariables = Exact<{
  id: Scalars['ID'];
  boardId: Scalars['ID'];
}>;


export type TaskSubTasksQuery = { __typename?: 'Query', task: (
    { __typename?: 'Task', progress: { __typename?: 'TaskProgress', total: number, completed: number } }
    & TaskModal_TaskFragment
  ), taskBoard: { __typename?: 'TaskBoard', states: Array<(
      { __typename?: 'TaskState' }
      & Task_StatesFragment
    )> } };

export type TaskSubTasks_CreateSubTaskMutationVariables = Exact<{
  stateId: Scalars['ID'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  parentId: Scalars['ID'];
}>;


export type TaskSubTasks_CreateSubTaskMutation = { __typename?: 'Mutation', createTask: { __typename?: 'CreateTaskResult', task: { __typename?: 'Task', progress: { __typename?: 'TaskProgress', total: number, completed: number } }, query: { __typename?: 'Query', task: { __typename?: 'Task', children: Array<(
          { __typename?: 'Task' }
          & SubTask_TaskFragment
        )> }, taskState: { __typename?: 'TaskState', tasks: Array<(
          { __typename?: 'Task' }
          & TaskCard_TaskFragment
        )> } } } };

export type TaskSubTasks_ReOrderSubTaskMutationMutationVariables = Exact<{
  taskId: Scalars['ID'];
  toTask: Scalars['ID'];
}>;


export type TaskSubTasks_ReOrderSubTaskMutationMutation = { __typename?: 'Mutation', reorderTask: Array<{ __typename?: 'Task', id: string, parentOrder: number }> };

export type SubTask_TaskFragment = { __typename?: 'Task', id: string, title: string, description: string, commentCount: number, attachmentCount: number, idNumber: number, parentOrder: number, assignees: Array<(
    { __typename?: 'User' }
    & TaskCard_UserFragment
  )>, progress: { __typename?: 'TaskProgress', completed: number, total: number }, state: { __typename?: 'TaskState', id: string, color: string, isComplete: boolean } };

export type SubTask_RemoveParentMutationVariables = Exact<{
  taskId: Scalars['ID'];
}>;


export type SubTask_RemoveParentMutation = { __typename?: 'Mutation', removeTaskParent: { __typename?: 'RemoveTaskParentResult', parent: { __typename?: 'Task', id: string, children: Array<(
        { __typename?: 'Task' }
        & SubTask_TaskFragment
      )>, progress: { __typename?: 'TaskProgress', total: number, completed: number } }, child: { __typename?: 'Task', id: string, parentOrder: number, breadcrumbs: Array<(
        { __typename?: 'TaskBreadcrumb' }
        & TaskModal_BreadcrumbFragment
      )> } } };

export type TaskStateSwitch_TaskStateFragment = { __typename?: 'TaskState', id: string, name: string, color: string, isComplete: boolean };

export type TaskHome_TaskBoardFragment = { __typename?: 'TaskBoard', id: string, idStr: string, name: string, description: string, isStarred: boolean, users: Array<{ __typename?: 'User', id: string, fullName: string, profilePicture: string }> };

export type TasksHomeQueryVariables = Exact<{ [key: string]: never; }>;


export type TasksHomeQuery = { __typename?: 'Query', taskBoards: Array<(
    { __typename?: 'TaskBoard' }
    & TaskHome_TaskBoardFragment
  )> };

export type Wiki_WikiFragment = { __typename?: 'Wiki', id: string, pageCount: number, name: string, canEdit: boolean, rootFolder: (
    { __typename?: 'Folder' }
    & WikiFolderFragment
  ), users: Array<(
    { __typename?: 'User' }
    & WikiUserFragment
  )> };

export type WikiQueryVariables = Exact<{
  idStr?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
}>;


export type WikiQuery = { __typename?: 'Query', wiki: (
    { __typename?: 'Wiki' }
    & Wiki_WikiFragment
  ), activeSession: { __typename?: 'Session', id: string } };

export type Wiki_UpdateWikiNameMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
}>;


export type Wiki_UpdateWikiNameMutation = { __typename?: 'Mutation', updateWikiName: { __typename?: 'Wiki', id: string, name: string } };

export type WikiPage_AncestorsQueryVariables = Exact<{
  fileId: Scalars['ID'];
}>;


export type WikiPage_AncestorsQuery = { __typename?: 'Query', file?: Maybe<{ __typename?: 'File', id: string, name: string, ancestors: Array<string> }> };

export type WikiComments_CommentFragment = { __typename?: 'WikiComment', id: string, content: string, createdDate: any, canEdit: boolean, commentor: (
    { __typename?: 'User' }
    & WikiUserFragment
  ), reactions: Array<(
    { __typename?: 'WikiCommentReaction' }
    & WikiCommentReactionFragment
  )>, attachments: Array<(
    { __typename?: 'WikiAttachment' }
    & WikiAttachmentFragment
  )> };

export type WikiAttachmentFragment = { __typename?: 'WikiAttachment', id: string, type: string, name: string, size: number, url: string, canRemove: boolean };

export type WikiUserFragment = { __typename?: 'User', id: string, fullName: string, profilePicture: string };

export type WikiCommentReactionFragment = { __typename?: 'WikiCommentReaction', id: string, value: string, icon: string, reactors: Array<string>, name: string };

export type WikiCommentsQueryVariables = Exact<{
  fileId: Scalars['ID'];
}>;


export type WikiCommentsQuery = { __typename?: 'Query', me?: Maybe<(
    { __typename?: 'User' }
    & WikiUserFragment
  )>, activeSession: { __typename?: 'Session', id: string }, file?: Maybe<{ __typename?: 'File', id: string, wikiComments: Array<(
      { __typename?: 'WikiComment' }
      & WikiComments_CommentFragment
    )> }> };

export type WikiComments_CreateWikiCommentMutationVariables = Exact<{
  content: Scalars['String'];
  wikiId: Scalars['ID'];
  fileId: Scalars['ID'];
  attachments?: Maybe<Array<AttachmentInput> | AttachmentInput>;
}>;


export type WikiComments_CreateWikiCommentMutation = { __typename?: 'Mutation', createWikiPageComment: { __typename?: 'File', id: string, wikiComments: Array<(
      { __typename?: 'WikiComment' }
      & WikiComments_CommentFragment
    )> } };

export type WikiComments_ToggleCommentReactionMutationVariables = Exact<{
  commentId: Scalars['ID'];
  value: Scalars['String'];
}>;


export type WikiComments_ToggleCommentReactionMutation = { __typename?: 'Mutation', toggleWikiPageCommentReaction: { __typename?: 'WikiComment', id: string, reactions: Array<{ __typename?: 'WikiCommentReaction', id: string, reactors: Array<string>, name: string, icon: string, value: string }> } };

export type WikiComments_DeleteCommentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type WikiComments_DeleteCommentMutation = { __typename?: 'Mutation', deleteWikiPageComment: { __typename?: 'File', id: string, wikiCommentCount: number, wikiComments: Array<(
      { __typename?: 'WikiComment' }
      & WikiComments_CommentFragment
    )> } };

export type WikiComments_UpdateCommentMutationVariables = Exact<{
  id: Scalars['ID'];
  content: Scalars['String'];
  attachments?: Maybe<Array<AttachmentInput> | AttachmentInput>;
}>;


export type WikiComments_UpdateCommentMutation = { __typename?: 'Mutation', updateWikiPageComment?: Maybe<{ __typename?: 'UpdateWikiCommentResult', comment: (
      { __typename?: 'WikiComment' }
      & WikiComments_CommentFragment
    ) }> };

export type WikiComments_RemoveAttachmentMutationVariables = Exact<{
  attachmentId: Scalars['ID'];
}>;


export type WikiComments_RemoveAttachmentMutation = { __typename?: 'Mutation', removeWikiPageAttachment?: Maybe<{ __typename?: 'WikiComment', id: string, attachments: Array<(
      { __typename?: 'WikiAttachment' }
      & WikiAttachmentFragment
    )> }> };

export type WikiComments_WikiPageCommentsSubscriptionVariables = Exact<{
  fileId: Scalars['ID'];
  sessionId: Scalars['ID'];
}>;


export type WikiComments_WikiPageCommentsSubscription = { __typename?: 'Subscription', wikiPageCommentsUpdated: { __typename?: 'File', id: string, wikiComments: Array<(
      { __typename?: 'WikiComment' }
      & WikiComments_CommentFragment
    )> } };

export type WikiEditorQueryVariables = Exact<{ [key: string]: never; }>;


export type WikiEditorQuery = { __typename?: 'Query', activeSession: { __typename?: 'Session', id: string }, me?: Maybe<{ __typename?: 'User', id: string }> };

export type WikiEditor_PageContentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type WikiEditor_PageContentQuery = { __typename?: 'Query', wikiPage: { __typename?: 'File', id: string, content?: Maybe<string> } };

export type WikiEditor_UpdateFileContentMutationVariables = Exact<{
  id: Scalars['ID'];
  content: Scalars['String'];
}>;


export type WikiEditor_UpdateFileContentMutation = { __typename?: 'Mutation', updateFileContent: { __typename?: 'File', id: string, content?: Maybe<string> } };

export type WikiEditor_SendEditingActionMutationVariables = Exact<{
  fileId: Scalars['ID'];
}>;


export type WikiEditor_SendEditingActionMutation = { __typename?: 'Mutation', reportUserFileAction?: Maybe<any> };

export type WikiEditor_EditorsSubscriptionVariables = Exact<{
  session: Scalars['ID'];
  fileId: Scalars['ID'];
}>;


export type WikiEditor_EditorsSubscription = { __typename?: 'Subscription', fileEditors: { __typename?: 'File', id: string, editors: Array<{ __typename?: 'User', id: string, fullName: string, profilePicture: string }> } };

export type WikiEditor_ContentSubscriptionVariables = Exact<{
  session: Scalars['ID'];
  fileId: Scalars['ID'];
}>;


export type WikiEditor_ContentSubscription = { __typename?: 'Subscription', fileContent: string };

export type WikiEditorHeaderQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type WikiEditorHeaderQuery = { __typename?: 'Query', wikiPage: { __typename?: 'File', editors: Array<{ __typename?: 'User', id: string, fullName: string, profilePicture: string }> } };

export type WikiFolderFragment = { __typename?: 'Folder', id: string, name: string, parent?: Maybe<{ __typename?: 'Folder', id: string }> };

export type WikiFolderChildrenFragment = { __typename?: 'Folder', id: string, folders: Array<(
    { __typename?: 'Folder' }
    & WikiFolderFragment
  )>, files: Array<(
    { __typename?: 'File' }
    & WikiPageFragment
  )> };

export type WikiFolderQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type WikiFolderQuery = { __typename?: 'Query', folder?: Maybe<(
    { __typename?: 'Folder' }
    & WikiFolderFragment
  )> };

export type WikiFolderChildrenQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type WikiFolderChildrenQuery = { __typename?: 'Query', folder?: Maybe<(
    { __typename?: 'Folder' }
    & WikiFolderChildrenFragment
  )> };

export type WikiFolder_CreateFolderMutationVariables = Exact<{
  parentId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  wikiId: Scalars['ID'];
}>;


export type WikiFolder_CreateFolderMutation = { __typename?: 'Mutation', createFolder: (
    { __typename?: 'Folder' }
    & WikiFolderChildrenFragment
  ) };

export type WikiFolder_CreatePageMutationVariables = Exact<{
  wikiId: Scalars['ID'];
  name: Scalars['String'];
  parentId: Scalars['ID'];
}>;


export type WikiFolder_CreatePageMutation = { __typename?: 'Mutation', createFile: { __typename?: 'CreateFileResult', parent: { __typename?: 'Folder', id: string, files: Array<(
        { __typename?: 'File' }
        & WikiPageFragment
      )> }, file: { __typename?: 'File', id: string, name: string } } };

export type WikiFolder_RenameFolderMutationVariables = Exact<{
  id: Scalars['ID'];
  newName: Scalars['String'];
}>;


export type WikiFolder_RenameFolderMutation = { __typename?: 'Mutation', renameFolder?: Maybe<{ __typename?: 'Folder', id: string, name: string }> };

export type WikiFolder_DeleteFolderMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type WikiFolder_DeleteFolderMutation = { __typename?: 'Mutation', deleteFolder: { __typename?: 'DeleteFolderResult', updatedFolders: Array<(
      { __typename?: 'Folder' }
      & WikiFolderChildrenFragment
    )> } };

export type WikiFolder_MoveFileToFolderMutationVariables = Exact<{
  file: Scalars['ID'];
  toFolder: Scalars['ID'];
}>;


export type WikiFolder_MoveFileToFolderMutation = { __typename?: 'Mutation', moveFileToFolder: { __typename?: 'MoveFileToFolderResult', updatedFolders: Array<(
      { __typename?: 'Folder' }
      & WikiFolderChildrenFragment
    )>, updatedFiles: Array<{ __typename?: 'File', id: string }> } };

export type WikiFolder_MoveFolderToFolderMutationVariables = Exact<{
  folder: Scalars['ID'];
  toFolder: Scalars['ID'];
}>;


export type WikiFolder_MoveFolderToFolderMutation = { __typename?: 'Mutation', moveFolderToFolder: { __typename?: 'MoveFolderToFolderResult', updatedFolders: Array<(
      { __typename?: 'Folder' }
      & WikiFolderChildrenFragment
    )> } };

export type WikiFolder_FolderContentSubscriptionVariables = Exact<{
  folderId: Scalars['ID'];
  sessionId: Scalars['ID'];
}>;


export type WikiFolder_FolderContentSubscription = { __typename?: 'Subscription', folderContent: (
    { __typename?: 'Folder' }
    & WikiFolderChildrenFragment
  ) };

export type WikiPageFragment = { __typename?: 'File', id: string, name: string };

export type WikiPage_RenameFileMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
}>;


export type WikiPage_RenameFileMutation = { __typename?: 'Mutation', renameFile?: Maybe<{ __typename?: 'RenameFileResult', file: { __typename?: 'File', id: string, name: string } }> };

export type WikiPage_DeleteFileMutationVariables = Exact<{
  fileId: Scalars['ID'];
}>;


export type WikiPage_DeleteFileMutation = { __typename?: 'Mutation', deleteFile: { __typename?: 'Folder', id: string, files: Array<(
      { __typename?: 'File' }
      & WikiPageFragment
    )> } };

export type WikiModalQueryVariables = Exact<{
  idStr?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
}>;


export type WikiModalQuery = { __typename?: 'Query', wiki: (
    { __typename?: 'Wiki' }
    & WikiesHome_WikiFragment
  ) };

export type WikiModal_CreateWikiMutationVariables = Exact<{
  name: Scalars['String'];
  description: Scalars['String'];
  teams: Array<Scalars['ID']> | Scalars['ID'];
  users: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type WikiModal_CreateWikiMutation = { __typename?: 'Mutation', createWiki: { __typename?: 'CreateWikiResult', created: { __typename?: 'Wiki', idStr: string }, query: { __typename?: 'Query', wikies: Array<(
        { __typename?: 'Wiki' }
        & WikiesHome_WikiFragment
      )> } } };

export type WikiModal_UpdateWikiMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  teams: Array<Scalars['ID']> | Scalars['ID'];
  users: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type WikiModal_UpdateWikiMutation = { __typename?: 'Mutation', updateWiki: (
    { __typename?: 'Wiki' }
    & WikiesHome_WikiFragment
  ) };

export type WikiesHome_WikiFragment = { __typename?: 'Wiki', id: string, idStr: string, name: string, description: string, users: Array<{ __typename?: 'User', id: string, fullName: string, profilePicture: string }>, teams: Array<{ __typename?: 'Team', id: string, name: string }> };

export type WikiesHomeQueryVariables = Exact<{ [key: string]: never; }>;


export type WikiesHomeQuery = { __typename?: 'Query', wikies: Array<(
    { __typename?: 'Wiki' }
    & WikiesHome_WikiFragment
  )> };

export const SessionTab_ActiveSessionFragmentDoc = gql`
    fragment SessionTab_ActiveSession on Session {
  id
}
    `;
export const SessionTab_AppSessionSessionFragmentDoc = gql`
    fragment SessionTab_AppSessionSession on AppSessionSession {
  id
  isActive
  session {
    id
    name
    createdDate
    isActive
    isClosed
    groupId
  }
}
    `;
export const SessionTabs_ActiveSessionFragmentDoc = gql`
    fragment SessionTabs_ActiveSession on Session {
  id
  name
}
    `;
export const SesssionTabs_OtherSessionFragmentDoc = gql`
    fragment SesssionTabs_OtherSession on Session {
  id
  name
  createdDate
}
    `;
export const Sidebar_ActiveSessionFragmentDoc = gql`
    fragment Sidebar_ActiveSession on Session {
  id
  groupId
}
    `;
export const SidebarChannels_ChannelFragmentDoc = gql`
    fragment SidebarChannels_Channel on Group {
  id
  name
  unreadCount
  type
  members {
    id
    name
    profilePicture
  }
}
    `;
export const SidebarUnreadComments_UnreadFragmentDoc = gql`
    fragment SidebarUnreadComments_Unread on UnreadComment {
  id
  title
  targetPath
  relatedUsers {
    id
    fullName
    profilePicture
  }
  relatedNotificationIds
  type
  unreadCount
}
    `;
export const AppSpace_ActiveAppSpaceFragmentDoc = gql`
    fragment AppSpace_ActiveAppSpace on AppSpace {
  type
  id
  name
  meta
}
    `;
export const AppSpaceTabs_AppSpaceFragmentDoc = gql`
    fragment AppSpaceTabs_AppSpace on SessionAppSpace {
  id
  isActive
  appSpace {
    type
    id
    name
  }
}
    `;
export const NotificationFragmentDoc = gql`
    fragment Notification on Notification {
  id
  title
  message
  relatedUser {
    id
    fullName
    email
    profilePicture
  }
  link
  image
  type
  clickedDate
  closedDate
  createdDate
  isRead
  task {
    title
    description
  }
  taskboard {
    name
    description
  }
}
    `;
export const Bookmarks_BookmarkFragmentDoc = gql`
    fragment Bookmarks_Bookmark on Bookmark {
  id
  title
  url
}
    `;
export const ListEditor_ListPropertyFragmentDoc = gql`
    fragment ListEditor_ListProperty on ListProperty {
  key
  value
}
    `;
export const ListEditor_ListFragmentDoc = gql`
    fragment ListEditor_List on List {
  id
  title
  index
  parentId
  hasChildren
  isEditable
  globalList
  properties {
    ...ListEditor_ListProperty
  }
}
    ${ListEditor_ListPropertyFragmentDoc}`;
export const ListEditor_ListEditorResultFragmentDoc = gql`
    fragment ListEditor_ListEditorResult on ListEditorResult {
  lists {
    ...ListEditor_List
  }
}
    ${ListEditor_ListFragmentDoc}`;
export const SearchResult_WebItemFragmentDoc = gql`
    fragment SearchResult_WebItem on WebSearchResultItem {
  _type
  items {
    _type
    thumbnailUrl
    contentUrl
    id
    text
    url
    description
    displayText
    viewCount
    name
    image {
      thumbnail {
        contentUrl
        width
        height
      }
    }
    duration
  }
  id
  name
  url
  image {
    _type
  }
  description
  thumbnailUrl
  text
  contentUrl
  width
  height
  thumbnail {
    _type
  }
  displayUrl
  snippet
  content
  wordCount
  viewCount
}
    `;
export const SearchResult_SearchResultFragmentDoc = gql`
    fragment SearchResult_SearchResult on SearchResult {
  query
  web {
    webResult {
      ...SearchResult_WebItem
    }
    imagesPreview {
      contentUrl
      thumbnailUrl
    }
    videosPreview {
      contentUrl
      thumbnailUrl
    }
    newsPreview {
      name
      url
      description
      image {
        thumbnail {
          contentUrl
        }
      }
    }
  }
  images {
    id
    _type
    thumbnailUrl
    contentUrl
    webSearchUrl
    name
    width
    height
  }
  videos {
    id
    thumbnailUrl
    name
    viewCount
    contentUrl
  }
  news {
    id
    name
    url
    image {
      thumbnail {
        contentUrl
      }
    }
    datePublished
    description
  }
  type
  offset
  count
  totalCount
}
    ${SearchResult_WebItemFragmentDoc}`;
export const FileFolderTableView_FileFragmentDoc = gql`
    fragment FileFolderTableView_File on File {
  id
  name
  type
  createdDate
  updatedDate
  isConverted
  size
  kind
  origin
  versions {
    id
    name
    type
    kind
    size
    origin
    createdDate
  }
  parent {
    id
    name
  }
}
    `;
export const FolderTreeView_FileFragmentDoc = gql`
    fragment FolderTreeView_File on File {
  id
  name
  type
  createdDate
  updatedDate
  isConverted
  size
  kind
  origin
  versions {
    id
    name
    type
    kind
    size
    origin
    createdDate
  }
  parent {
    id
    name
  }
}
    `;
export const FilePreviewView_FileFragmentDoc = gql`
    fragment FilePreviewView_File on File {
  id
  name
  size
  type
  previewUrl
}
    `;
export const FileFolderTableView_FolderFragmentDoc = gql`
    fragment FileFolderTableView_Folder on Folder {
  id
  name
  createdDate
  files {
    ...FileFolderTableView_File
  }
  folders {
    id
    name
    createdDate
  }
  parent {
    id
    name
  }
  lastAccess
  hasChildren
  hasFiles
}
    ${FileFolderTableView_FileFragmentDoc}`;
export const FolderTreeView_FolderFragmentDoc = gql`
    fragment FolderTreeView_Folder on Folder {
  id
  name
  parent {
    id
  }
  lastAccess
  hasChildren
}
    `;
export const FileListing_FileListingFragmentDoc = gql`
    fragment FileListing_FileListing on FileListing {
  id
  files {
    files {
      ...FileFolderTableView_File
      ...FolderTreeView_File
      ...FilePreviewView_File
    }
    count
  }
  folders {
    id
    folders {
      ...FileFolderTableView_Folder
      ...FolderTreeView_Folder
    }
    count
    expandedFolders
  }
  type
  pageNumber
  pageSize
  count
  orderMode
  listingMode
  limitMode
  query
}
    ${FileFolderTableView_FileFragmentDoc}
${FolderTreeView_FileFragmentDoc}
${FilePreviewView_FileFragmentDoc}
${FileFolderTableView_FolderFragmentDoc}
${FolderTreeView_FolderFragmentDoc}`;
export const MessageAttachments_MessageAttachmentFragmentDoc = gql`
    fragment MessageAttachments_MessageAttachment on MessageAttachment {
  id
  type
  name
  size
  url
}
    `;
export const Message_MessageFragmentDoc = gql`
    fragment Message_Message on Message {
  id
  type
  textContent
  time
  editedAt
  createdDate
  searchTerm
  user {
    id
    fullName
    isPretzel
    profilePicture
  }
  obsolete
  searchResult(type: "web", offset: 0, count: 10) {
    ...SearchResult_SearchResult
  }
  fileListing(
    type: null
    pageSize: 10
    pageNumber: 1
    expandedFolders: null
    orderMode: null
    listingMode: null
    query: null
  ) {
    ...FileListing_FileListing
  }
  links {
    name
    description
    url
  }
  embed {
    contentUrl
    embedHtml
    allowHttpsEmbed
    allowMobileEmbed
  }
  pdfs {
    id
    appSpaceId
    name
    url
    snippet
    language
    thumbnail
    local
    size
  }
  isUnread
  reactions {
    id
    value
    reactors
    icon
    name
  }
  attachments {
    ...MessageAttachments_MessageAttachment
  }
}
    ${SearchResult_SearchResultFragmentDoc}
${FileListing_FileListingFragmentDoc}
${MessageAttachments_MessageAttachmentFragmentDoc}`;
export const UserChatStatus_UserStatusFragmentDoc = gql`
    fragment UserChatStatus_UserStatus on UserChatStatus {
  status
}
    `;
export const MessageCardUserFragmentDoc = gql`
    fragment MessageCardUser on GroupMember {
  id
  fullName: name
  profilePicture
}
    `;
export const JournalEntryFragmentDoc = gql`
    fragment JournalEntry on JournalEntry {
  id
  createdDate
  title
  file {
    id
    content
  }
}
    `;
export const Notes_NoteLabelFragmentDoc = gql`
    fragment Notes_NoteLabel on NoteLabel {
  id
  name
  color
}
    `;
export const Notes_NoteFragmentDoc = gql`
    fragment Notes_Note on Note {
  id
  title
  createdDate
  file {
    id
    content
  }
  labels {
    id
    name
    color
  }
}
    `;
export const Teams_TeamMemberFragmentDoc = gql`
    fragment Teams_TeamMember on TeamMember {
  id
  user {
    fullName
    email
  }
}
    `;
export const TaskModal_UserFragmentDoc = gql`
    fragment TaskModal_User on User {
  id
  fullName
  profilePicture
}
    `;
export const TaskBoard_TaskBoardFragmentDoc = gql`
    fragment TaskBoard_TaskBoard on TaskBoard {
  id
  idStr
  name
  canEdit
  users {
    ...TaskModal_User
  }
}
    ${TaskModal_UserFragmentDoc}`;
export const BoardLane_TaskStateFragmentDoc = gql`
    fragment BoardLane_TaskState on TaskState {
  id
  name
  color
  isComplete
  order
  isArchived
}
    `;
export const TaskCard_UserFragmentDoc = gql`
    fragment TaskCard_User on User {
  id
  fullName
  profilePicture
}
    `;
export const TaskModal_BreadcrumbFragmentDoc = gql`
    fragment TaskModal_Breadcrumb on TaskBreadcrumb {
  id
  title
  idNumber
  state {
    id
    color
  }
}
    `;
export const TaskCard_TaskFragmentDoc = gql`
    fragment TaskCard_Task on Task {
  id
  title
  description
  commentCount
  attachmentCount
  idNumber
  creator {
    ...TaskCard_User
  }
  assignees {
    ...TaskCard_User
  }
  state {
    id
  }
  breadcrumbs {
    ...TaskModal_Breadcrumb
  }
  stateOrder
  parentOrder
  createdDate
  progress {
    completed
    total
  }
}
    ${TaskCard_UserFragmentDoc}
${TaskModal_BreadcrumbFragmentDoc}`;
export const TaskBoardTreeView_UserFragmentDoc = gql`
    fragment TaskBoardTreeView_User on User {
  id
  fullName
  profilePicture
}
    `;
export const TaskBoardTreeView_TaskFragmentDoc = gql`
    fragment TaskBoardTreeView_Task on Task {
  id
  idNumber
  title
  description
  createdDate
  progress {
    total
    completed
  }
  state {
    id
    isComplete
    color
    name
  }
  assignees {
    ...TaskBoardTreeView_User
  }
  creator {
    ...TaskBoardTreeView_User
  }
  parentOrder
}
    ${TaskBoardTreeView_UserFragmentDoc}`;
export const Task_StatesFragmentDoc = gql`
    fragment Task_States on TaskState {
  id
  isComplete
  color
  name
}
    `;
export const TaskModal_AttachmentFragmentDoc = gql`
    fragment TaskModal_Attachment on TaskAttachment {
  id
  type
  name
  size
  commentId
}
    `;
export const SubTask_TaskFragmentDoc = gql`
    fragment SubTask_Task on Task {
  id
  title
  description
  commentCount
  attachmentCount
  idNumber
  assignees {
    ...TaskCard_User
  }
  progress {
    completed
    total
  }
  state {
    id
    color
    isComplete
  }
  parentOrder
}
    ${TaskCard_UserFragmentDoc}`;
export const TaskModal_TaskFragmentDoc = gql`
    fragment TaskModal_Task on Task {
  id
  idNumber
  title
  description
  createdDate
  progress {
    total
    completed
  }
  state {
    id
    isComplete
    color
    name
  }
  assignees {
    ...TaskModal_User
  }
  creator {
    ...TaskModal_User
  }
  breadcrumbs {
    ...TaskModal_Breadcrumb
  }
  isArchived
  children {
    ...SubTask_Task
  }
  hours
}
    ${TaskModal_UserFragmentDoc}
${TaskModal_BreadcrumbFragmentDoc}
${SubTask_TaskFragmentDoc}`;
export const TaskLogFragmentDoc = gql`
    fragment TaskLog on TaskLog {
  log
  user {
    ...TaskModal_User
  }
  task {
    id
    title
  }
  createdDate
}
    ${TaskModal_UserFragmentDoc}`;
export const TaskAttachments_AttachmentFragmentDoc = gql`
    fragment TaskAttachments_Attachment on TaskAttachment {
  id
  type
  name
  size
  commentId
  url
  canRemove
}
    `;
export const TaskComments_CommentFragmentDoc = gql`
    fragment TaskComments_Comment on TaskComment {
  id
  content
  createdDate
  commentor {
    ...TaskModal_User
  }
  attachments {
    ...TaskAttachments_Attachment
  }
  reactions {
    id
    reactors
    name
    icon
    value
  }
  canEdit
}
    ${TaskModal_UserFragmentDoc}
${TaskAttachments_AttachmentFragmentDoc}`;
export const TaskStateSwitch_TaskStateFragmentDoc = gql`
    fragment TaskStateSwitch_TaskState on TaskState {
  id
  name
  color
  isComplete
}
    `;
export const TaskHome_TaskBoardFragmentDoc = gql`
    fragment TaskHome_TaskBoard on TaskBoard {
  id
  idStr
  name
  description
  isStarred
  users {
    id
    fullName
    profilePicture
  }
}
    `;
export const WikiFolderFragmentDoc = gql`
    fragment WikiFolder on Folder {
  id
  name
  parent {
    id
  }
}
    `;
export const WikiUserFragmentDoc = gql`
    fragment WikiUser on User {
  id
  fullName
  profilePicture
}
    `;
export const Wiki_WikiFragmentDoc = gql`
    fragment Wiki_Wiki on Wiki {
  id
  pageCount
  name
  rootFolder {
    ...WikiFolder
  }
  users {
    ...WikiUser
  }
  canEdit
}
    ${WikiFolderFragmentDoc}
${WikiUserFragmentDoc}`;
export const WikiCommentReactionFragmentDoc = gql`
    fragment WikiCommentReaction on WikiCommentReaction {
  id
  value
  icon
  reactors
  name
}
    `;
export const WikiAttachmentFragmentDoc = gql`
    fragment WikiAttachment on WikiAttachment {
  id
  type
  name
  size
  url
  canRemove
}
    `;
export const WikiComments_CommentFragmentDoc = gql`
    fragment WikiComments_Comment on WikiComment {
  id
  content
  commentor {
    ...WikiUser
  }
  reactions {
    ...WikiCommentReaction
  }
  attachments {
    ...WikiAttachment
  }
  createdDate
  canEdit
}
    ${WikiUserFragmentDoc}
${WikiCommentReactionFragmentDoc}
${WikiAttachmentFragmentDoc}`;
export const WikiPageFragmentDoc = gql`
    fragment WikiPage on File {
  id
  name
}
    `;
export const WikiFolderChildrenFragmentDoc = gql`
    fragment WikiFolderChildren on Folder {
  id
  folders {
    ...WikiFolder
  }
  files {
    ...WikiPage
  }
}
    ${WikiFolderFragmentDoc}
${WikiPageFragmentDoc}`;
export const WikiesHome_WikiFragmentDoc = gql`
    fragment WikiesHome_Wiki on Wiki {
  id
  idStr
  name
  description
  users {
    id
    fullName
    profilePicture
  }
  teams {
    id
    name
  }
}
    `;
export const Sessions_SelectSessionDocument = gql`
    mutation Sessions_SelectSession($id: ID!) {
  selectSession(id: $id) {
    sessions {
      ...SessionTab_AppSessionSession
    }
    appSpaces {
      ...AppSpaceTabs_AppSpace
    }
    groups {
      ...SidebarChannels_Channel
    }
    activeSession {
      ...SessionTab_ActiveSession
      ...Sidebar_ActiveSession
    }
    organization {
      id
      name
    }
    potentialGroupMembers {
      id
      fullName
      profilePicture
    }
    activeAppSpace {
      type
      id
      name
    }
  }
}
    ${SessionTab_AppSessionSessionFragmentDoc}
${AppSpaceTabs_AppSpaceFragmentDoc}
${SidebarChannels_ChannelFragmentDoc}
${SessionTab_ActiveSessionFragmentDoc}
${Sidebar_ActiveSessionFragmentDoc}`;
export type Sessions_SelectSessionMutationFn = Apollo.MutationFunction<Sessions_SelectSessionMutation, Sessions_SelectSessionMutationVariables>;

/**
 * __useSessions_SelectSessionMutation__
 *
 * To run a mutation, you first call `useSessions_SelectSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSessions_SelectSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sessionsSelectSessionMutation, { data, loading, error }] = useSessions_SelectSessionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSessions_SelectSessionMutation(baseOptions?: Apollo.MutationHookOptions<Sessions_SelectSessionMutation, Sessions_SelectSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Sessions_SelectSessionMutation, Sessions_SelectSessionMutationVariables>(Sessions_SelectSessionDocument, options);
      }
export type Sessions_SelectSessionMutationHookResult = ReturnType<typeof useSessions_SelectSessionMutation>;
export type Sessions_SelectSessionMutationResult = Apollo.MutationResult<Sessions_SelectSessionMutation>;
export type Sessions_SelectSessionMutationOptions = Apollo.BaseMutationOptions<Sessions_SelectSessionMutation, Sessions_SelectSessionMutationVariables>;
export const Sessions_CloseSessionDocument = gql`
    mutation Sessions_CloseSession($id: ID!) {
  closeSession(id: $id) {
    sessions {
      ...SessionTab_AppSessionSession
    }
    activeSession {
      ...SessionTab_ActiveSession
      ...Sidebar_ActiveSession
    }
    organization {
      id
      name
    }
    appSpaces {
      ...AppSpaceTabs_AppSpace
    }
    activeAppSpace {
      type
      id
      name
    }
  }
}
    ${SessionTab_AppSessionSessionFragmentDoc}
${SessionTab_ActiveSessionFragmentDoc}
${Sidebar_ActiveSessionFragmentDoc}
${AppSpaceTabs_AppSpaceFragmentDoc}`;
export type Sessions_CloseSessionMutationFn = Apollo.MutationFunction<Sessions_CloseSessionMutation, Sessions_CloseSessionMutationVariables>;

/**
 * __useSessions_CloseSessionMutation__
 *
 * To run a mutation, you first call `useSessions_CloseSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSessions_CloseSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sessionsCloseSessionMutation, { data, loading, error }] = useSessions_CloseSessionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSessions_CloseSessionMutation(baseOptions?: Apollo.MutationHookOptions<Sessions_CloseSessionMutation, Sessions_CloseSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Sessions_CloseSessionMutation, Sessions_CloseSessionMutationVariables>(Sessions_CloseSessionDocument, options);
      }
export type Sessions_CloseSessionMutationHookResult = ReturnType<typeof useSessions_CloseSessionMutation>;
export type Sessions_CloseSessionMutationResult = Apollo.MutationResult<Sessions_CloseSessionMutation>;
export type Sessions_CloseSessionMutationOptions = Apollo.BaseMutationOptions<Sessions_CloseSessionMutation, Sessions_CloseSessionMutationVariables>;
export const Sessions_RenameSessionDocument = gql`
    mutation Sessions_RenameSession($sessionId: ID!, $newName: String!) {
  renameSession(sessionId: $sessionId, newName: $newName) {
    id
    name
  }
}
    `;
export type Sessions_RenameSessionMutationFn = Apollo.MutationFunction<Sessions_RenameSessionMutation, Sessions_RenameSessionMutationVariables>;

/**
 * __useSessions_RenameSessionMutation__
 *
 * To run a mutation, you first call `useSessions_RenameSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSessions_RenameSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sessionsRenameSessionMutation, { data, loading, error }] = useSessions_RenameSessionMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      newName: // value for 'newName'
 *   },
 * });
 */
export function useSessions_RenameSessionMutation(baseOptions?: Apollo.MutationHookOptions<Sessions_RenameSessionMutation, Sessions_RenameSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Sessions_RenameSessionMutation, Sessions_RenameSessionMutationVariables>(Sessions_RenameSessionDocument, options);
      }
export type Sessions_RenameSessionMutationHookResult = ReturnType<typeof useSessions_RenameSessionMutation>;
export type Sessions_RenameSessionMutationResult = Apollo.MutationResult<Sessions_RenameSessionMutation>;
export type Sessions_RenameSessionMutationOptions = Apollo.BaseMutationOptions<Sessions_RenameSessionMutation, Sessions_RenameSessionMutationVariables>;
export const Sessions_CreateSessionDocument = gql`
    mutation Sessions_CreateSession($name: String) {
  createSession(name: $name) {
    sessions {
      ...SessionTab_AppSessionSession
    }
    activeSession {
      ...SessionTabs_ActiveSession
      ...Sidebar_ActiveSession
    }
    organization {
      id
      name
    }
    appSpaces {
      ...AppSpaceTabs_AppSpace
    }
    activeAppSpace {
      type
      id
      name
    }
  }
}
    ${SessionTab_AppSessionSessionFragmentDoc}
${SessionTabs_ActiveSessionFragmentDoc}
${Sidebar_ActiveSessionFragmentDoc}
${AppSpaceTabs_AppSpaceFragmentDoc}`;
export type Sessions_CreateSessionMutationFn = Apollo.MutationFunction<Sessions_CreateSessionMutation, Sessions_CreateSessionMutationVariables>;

/**
 * __useSessions_CreateSessionMutation__
 *
 * To run a mutation, you first call `useSessions_CreateSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSessions_CreateSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sessionsCreateSessionMutation, { data, loading, error }] = useSessions_CreateSessionMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useSessions_CreateSessionMutation(baseOptions?: Apollo.MutationHookOptions<Sessions_CreateSessionMutation, Sessions_CreateSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Sessions_CreateSessionMutation, Sessions_CreateSessionMutationVariables>(Sessions_CreateSessionDocument, options);
      }
export type Sessions_CreateSessionMutationHookResult = ReturnType<typeof useSessions_CreateSessionMutation>;
export type Sessions_CreateSessionMutationResult = Apollo.MutationResult<Sessions_CreateSessionMutation>;
export type Sessions_CreateSessionMutationOptions = Apollo.BaseMutationOptions<Sessions_CreateSessionMutation, Sessions_CreateSessionMutationVariables>;
export const Sessions_ReopenSessionDocument = gql`
    mutation Sessions_ReopenSession($sessionId: ID!) {
  reopenSession(sessionId: $sessionId) {
    sessions {
      ...SessionTab_AppSessionSession
    }
    activeSession {
      ...SessionTabs_ActiveSession
      ...Sidebar_ActiveSession
    }
    organization {
      id
      name
    }
    appSpaces {
      ...AppSpaceTabs_AppSpace
    }
    activeAppSpace {
      type
      id
      name
    }
  }
}
    ${SessionTab_AppSessionSessionFragmentDoc}
${SessionTabs_ActiveSessionFragmentDoc}
${Sidebar_ActiveSessionFragmentDoc}
${AppSpaceTabs_AppSpaceFragmentDoc}`;
export type Sessions_ReopenSessionMutationFn = Apollo.MutationFunction<Sessions_ReopenSessionMutation, Sessions_ReopenSessionMutationVariables>;

/**
 * __useSessions_ReopenSessionMutation__
 *
 * To run a mutation, you first call `useSessions_ReopenSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSessions_ReopenSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sessionsReopenSessionMutation, { data, loading, error }] = useSessions_ReopenSessionMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useSessions_ReopenSessionMutation(baseOptions?: Apollo.MutationHookOptions<Sessions_ReopenSessionMutation, Sessions_ReopenSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Sessions_ReopenSessionMutation, Sessions_ReopenSessionMutationVariables>(Sessions_ReopenSessionDocument, options);
      }
export type Sessions_ReopenSessionMutationHookResult = ReturnType<typeof useSessions_ReopenSessionMutation>;
export type Sessions_ReopenSessionMutationResult = Apollo.MutationResult<Sessions_ReopenSessionMutation>;
export type Sessions_ReopenSessionMutationOptions = Apollo.BaseMutationOptions<Sessions_ReopenSessionMutation, Sessions_ReopenSessionMutationVariables>;
export const ProfileDocument = gql`
    query Profile($id: ID!) {
  profile(id: $id) {
    id
    email
    name
    phone
    role
    isCurrentUser
    profilePicture
    lastSeen
    groups {
      id
      type
      members {
        id
        name
        profilePicture
      }
      name
      description
    }
  }
}
    `;

/**
 * __useProfileQuery__
 *
 * To run a query within a React component, call `useProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProfileQuery(baseOptions: Apollo.QueryHookOptions<ProfileQuery, ProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfileQuery, ProfileQueryVariables>(ProfileDocument, options);
      }
export function useProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfileQuery, ProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfileQuery, ProfileQueryVariables>(ProfileDocument, options);
        }
export type ProfileQueryHookResult = ReturnType<typeof useProfileQuery>;
export type ProfileLazyQueryHookResult = ReturnType<typeof useProfileLazyQuery>;
export type ProfileQueryResult = Apollo.QueryResult<ProfileQuery, ProfileQueryVariables>;
export const Profile_DmsDocument = gql`
    query Profile_DMS {
  groups {
    ...SidebarChannels_Channel
  }
}
    ${SidebarChannels_ChannelFragmentDoc}`;

/**
 * __useProfile_DmsQuery__
 *
 * To run a query within a React component, call `useProfile_DmsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfile_DmsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfile_DmsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfile_DmsQuery(baseOptions?: Apollo.QueryHookOptions<Profile_DmsQuery, Profile_DmsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Profile_DmsQuery, Profile_DmsQueryVariables>(Profile_DmsDocument, options);
      }
export function useProfile_DmsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Profile_DmsQuery, Profile_DmsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Profile_DmsQuery, Profile_DmsQueryVariables>(Profile_DmsDocument, options);
        }
export type Profile_DmsQueryHookResult = ReturnType<typeof useProfile_DmsQuery>;
export type Profile_DmsLazyQueryHookResult = ReturnType<typeof useProfile_DmsLazyQuery>;
export type Profile_DmsQueryResult = Apollo.QueryResult<Profile_DmsQuery, Profile_DmsQueryVariables>;
export const UpdateProfileDocument = gql`
    mutation UpdateProfile($name: String, $phone: String, $role: String) {
  updateProfile(name: $name, phone: $phone, role: $role)
}
    `;
export type UpdateProfileMutationFn = Apollo.MutationFunction<UpdateProfileMutation, UpdateProfileMutationVariables>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      name: // value for 'name'
 *      phone: // value for 'phone'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useUpdateProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument, options);
      }
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<UpdateProfileMutation, UpdateProfileMutationVariables>;
export const UploadProfilePictureDocument = gql`
    mutation UploadProfilePicture {
  uploadProfilePicture
}
    `;
export type UploadProfilePictureMutationFn = Apollo.MutationFunction<UploadProfilePictureMutation, UploadProfilePictureMutationVariables>;

/**
 * __useUploadProfilePictureMutation__
 *
 * To run a mutation, you first call `useUploadProfilePictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadProfilePictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadProfilePictureMutation, { data, loading, error }] = useUploadProfilePictureMutation({
 *   variables: {
 *   },
 * });
 */
export function useUploadProfilePictureMutation(baseOptions?: Apollo.MutationHookOptions<UploadProfilePictureMutation, UploadProfilePictureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadProfilePictureMutation, UploadProfilePictureMutationVariables>(UploadProfilePictureDocument, options);
      }
export type UploadProfilePictureMutationHookResult = ReturnType<typeof useUploadProfilePictureMutation>;
export type UploadProfilePictureMutationResult = Apollo.MutationResult<UploadProfilePictureMutation>;
export type UploadProfilePictureMutationOptions = Apollo.BaseMutationOptions<UploadProfilePictureMutation, UploadProfilePictureMutationVariables>;
export const RootDocument = gql`
    query Root {
  activeAppSpace {
    id
  }
  organization {
    id
  }
  activeSession {
    id
    groupId
  }
}
    `;

/**
 * __useRootQuery__
 *
 * To run a query within a React component, call `useRootQuery` and pass it any options that fit your needs.
 * When your component renders, `useRootQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRootQuery({
 *   variables: {
 *   },
 * });
 */
export function useRootQuery(baseOptions?: Apollo.QueryHookOptions<RootQuery, RootQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RootQuery, RootQueryVariables>(RootDocument, options);
      }
export function useRootLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RootQuery, RootQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RootQuery, RootQueryVariables>(RootDocument, options);
        }
export type RootQueryHookResult = ReturnType<typeof useRootQuery>;
export type RootLazyQueryHookResult = ReturnType<typeof useRootLazyQuery>;
export type RootQueryResult = Apollo.QueryResult<RootQuery, RootQueryVariables>;
export const Root_RegisterForNotificationDocument = gql`
    mutation Root_RegisterForNotification($sub: String!) {
  registerForNotifications(subObj: $sub)
}
    `;
export type Root_RegisterForNotificationMutationFn = Apollo.MutationFunction<Root_RegisterForNotificationMutation, Root_RegisterForNotificationMutationVariables>;

/**
 * __useRoot_RegisterForNotificationMutation__
 *
 * To run a mutation, you first call `useRoot_RegisterForNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoot_RegisterForNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rootRegisterForNotificationMutation, { data, loading, error }] = useRoot_RegisterForNotificationMutation({
 *   variables: {
 *      sub: // value for 'sub'
 *   },
 * });
 */
export function useRoot_RegisterForNotificationMutation(baseOptions?: Apollo.MutationHookOptions<Root_RegisterForNotificationMutation, Root_RegisterForNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Root_RegisterForNotificationMutation, Root_RegisterForNotificationMutationVariables>(Root_RegisterForNotificationDocument, options);
      }
export type Root_RegisterForNotificationMutationHookResult = ReturnType<typeof useRoot_RegisterForNotificationMutation>;
export type Root_RegisterForNotificationMutationResult = Apollo.MutationResult<Root_RegisterForNotificationMutation>;
export type Root_RegisterForNotificationMutationOptions = Apollo.BaseMutationOptions<Root_RegisterForNotificationMutation, Root_RegisterForNotificationMutationVariables>;
export const Root_MarkNotificationClickedDocument = gql`
    mutation Root_MarkNotificationClicked($ids: [ID!]!) {
  markNotificationClicked(ids: $ids) {
    activeSession {
      id
    }
  }
}
    `;
export type Root_MarkNotificationClickedMutationFn = Apollo.MutationFunction<Root_MarkNotificationClickedMutation, Root_MarkNotificationClickedMutationVariables>;

/**
 * __useRoot_MarkNotificationClickedMutation__
 *
 * To run a mutation, you first call `useRoot_MarkNotificationClickedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoot_MarkNotificationClickedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rootMarkNotificationClickedMutation, { data, loading, error }] = useRoot_MarkNotificationClickedMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRoot_MarkNotificationClickedMutation(baseOptions?: Apollo.MutationHookOptions<Root_MarkNotificationClickedMutation, Root_MarkNotificationClickedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Root_MarkNotificationClickedMutation, Root_MarkNotificationClickedMutationVariables>(Root_MarkNotificationClickedDocument, options);
      }
export type Root_MarkNotificationClickedMutationHookResult = ReturnType<typeof useRoot_MarkNotificationClickedMutation>;
export type Root_MarkNotificationClickedMutationResult = Apollo.MutationResult<Root_MarkNotificationClickedMutation>;
export type Root_MarkNotificationClickedMutationOptions = Apollo.BaseMutationOptions<Root_MarkNotificationClickedMutation, Root_MarkNotificationClickedMutationVariables>;
export const SessionTabsDocument = gql`
    query SessionTabs {
  sessions {
    ...SessionTab_AppSessionSession
  }
  activeSession {
    ...SessionTabs_ActiveSession
  }
  sessionSettings {
    maximumSessionCount
  }
}
    ${SessionTab_AppSessionSessionFragmentDoc}
${SessionTabs_ActiveSessionFragmentDoc}`;

/**
 * __useSessionTabsQuery__
 *
 * To run a query within a React component, call `useSessionTabsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionTabsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionTabsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSessionTabsQuery(baseOptions?: Apollo.QueryHookOptions<SessionTabsQuery, SessionTabsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SessionTabsQuery, SessionTabsQueryVariables>(SessionTabsDocument, options);
      }
export function useSessionTabsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SessionTabsQuery, SessionTabsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SessionTabsQuery, SessionTabsQueryVariables>(SessionTabsDocument, options);
        }
export type SessionTabsQueryHookResult = ReturnType<typeof useSessionTabsQuery>;
export type SessionTabsLazyQueryHookResult = ReturnType<typeof useSessionTabsLazyQuery>;
export type SessionTabsQueryResult = Apollo.QueryResult<SessionTabsQuery, SessionTabsQueryVariables>;
export const SessionTabs_OtherSessionsDocument = gql`
    query SessionTabs_OtherSessions {
  otherSessions {
    ...SesssionTabs_OtherSession
  }
}
    ${SesssionTabs_OtherSessionFragmentDoc}`;

/**
 * __useSessionTabs_OtherSessionsQuery__
 *
 * To run a query within a React component, call `useSessionTabs_OtherSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionTabs_OtherSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionTabs_OtherSessionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSessionTabs_OtherSessionsQuery(baseOptions?: Apollo.QueryHookOptions<SessionTabs_OtherSessionsQuery, SessionTabs_OtherSessionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SessionTabs_OtherSessionsQuery, SessionTabs_OtherSessionsQueryVariables>(SessionTabs_OtherSessionsDocument, options);
      }
export function useSessionTabs_OtherSessionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SessionTabs_OtherSessionsQuery, SessionTabs_OtherSessionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SessionTabs_OtherSessionsQuery, SessionTabs_OtherSessionsQueryVariables>(SessionTabs_OtherSessionsDocument, options);
        }
export type SessionTabs_OtherSessionsQueryHookResult = ReturnType<typeof useSessionTabs_OtherSessionsQuery>;
export type SessionTabs_OtherSessionsLazyQueryHookResult = ReturnType<typeof useSessionTabs_OtherSessionsLazyQuery>;
export type SessionTabs_OtherSessionsQueryResult = Apollo.QueryResult<SessionTabs_OtherSessionsQuery, SessionTabs_OtherSessionsQueryVariables>;
export const Sidebar_UerInfoDocument = gql`
    query Sidebar_UerInfo {
  me {
    id
    fullName
    isGuest
    profilePicture
  }
  activeSession {
    id
  }
  activeSpace {
    id
    name
  }
  organization {
    name
    id
    profilePicture
    spaces {
      id
      name
    }
  }
  organizations {
    id
    name
    memberCount
    profilePicture
  }
}
    `;

/**
 * __useSidebar_UerInfoQuery__
 *
 * To run a query within a React component, call `useSidebar_UerInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useSidebar_UerInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSidebar_UerInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useSidebar_UerInfoQuery(baseOptions?: Apollo.QueryHookOptions<Sidebar_UerInfoQuery, Sidebar_UerInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Sidebar_UerInfoQuery, Sidebar_UerInfoQueryVariables>(Sidebar_UerInfoDocument, options);
      }
export function useSidebar_UerInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Sidebar_UerInfoQuery, Sidebar_UerInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Sidebar_UerInfoQuery, Sidebar_UerInfoQueryVariables>(Sidebar_UerInfoDocument, options);
        }
export type Sidebar_UerInfoQueryHookResult = ReturnType<typeof useSidebar_UerInfoQuery>;
export type Sidebar_UerInfoLazyQueryHookResult = ReturnType<typeof useSidebar_UerInfoLazyQuery>;
export type Sidebar_UerInfoQueryResult = Apollo.QueryResult<Sidebar_UerInfoQuery, Sidebar_UerInfoQueryVariables>;
export const Sidebar_SignOutDocument = gql`
    mutation Sidebar_SignOut {
  signOut
}
    `;
export type Sidebar_SignOutMutationFn = Apollo.MutationFunction<Sidebar_SignOutMutation, Sidebar_SignOutMutationVariables>;

/**
 * __useSidebar_SignOutMutation__
 *
 * To run a mutation, you first call `useSidebar_SignOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSidebar_SignOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sidebarSignOutMutation, { data, loading, error }] = useSidebar_SignOutMutation({
 *   variables: {
 *   },
 * });
 */
export function useSidebar_SignOutMutation(baseOptions?: Apollo.MutationHookOptions<Sidebar_SignOutMutation, Sidebar_SignOutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Sidebar_SignOutMutation, Sidebar_SignOutMutationVariables>(Sidebar_SignOutDocument, options);
      }
export type Sidebar_SignOutMutationHookResult = ReturnType<typeof useSidebar_SignOutMutation>;
export type Sidebar_SignOutMutationResult = Apollo.MutationResult<Sidebar_SignOutMutation>;
export type Sidebar_SignOutMutationOptions = Apollo.BaseMutationOptions<Sidebar_SignOutMutation, Sidebar_SignOutMutationVariables>;
export const Sidebar_GroupUpdatedDocument = gql`
    subscription Sidebar_GroupUpdated($session: ID!) {
  groupUpdated(session: $session) {
    ...SidebarChannels_Channel
    description
  }
}
    ${SidebarChannels_ChannelFragmentDoc}`;

/**
 * __useSidebar_GroupUpdatedSubscription__
 *
 * To run a query within a React component, call `useSidebar_GroupUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSidebar_GroupUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSidebar_GroupUpdatedSubscription({
 *   variables: {
 *      session: // value for 'session'
 *   },
 * });
 */
export function useSidebar_GroupUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<Sidebar_GroupUpdatedSubscription, Sidebar_GroupUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Sidebar_GroupUpdatedSubscription, Sidebar_GroupUpdatedSubscriptionVariables>(Sidebar_GroupUpdatedDocument, options);
      }
export type Sidebar_GroupUpdatedSubscriptionHookResult = ReturnType<typeof useSidebar_GroupUpdatedSubscription>;
export type Sidebar_GroupUpdatedSubscriptionResult = Apollo.SubscriptionResult<Sidebar_GroupUpdatedSubscription>;
export const SidebarChannelsDocument = gql`
    query SidebarChannels {
  activeSession {
    ...Sidebar_ActiveSession
  }
  me {
    id
  }
  groups {
    ...SidebarChannels_Channel
  }
  potentialGroupMembers {
    id
    fullName
    profilePicture
  }
}
    ${Sidebar_ActiveSessionFragmentDoc}
${SidebarChannels_ChannelFragmentDoc}`;

/**
 * __useSidebarChannelsQuery__
 *
 * To run a query within a React component, call `useSidebarChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSidebarChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSidebarChannelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSidebarChannelsQuery(baseOptions?: Apollo.QueryHookOptions<SidebarChannelsQuery, SidebarChannelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SidebarChannelsQuery, SidebarChannelsQueryVariables>(SidebarChannelsDocument, options);
      }
export function useSidebarChannelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SidebarChannelsQuery, SidebarChannelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SidebarChannelsQuery, SidebarChannelsQueryVariables>(SidebarChannelsDocument, options);
        }
export type SidebarChannelsQueryHookResult = ReturnType<typeof useSidebarChannelsQuery>;
export type SidebarChannelsLazyQueryHookResult = ReturnType<typeof useSidebarChannelsLazyQuery>;
export type SidebarChannelsQueryResult = Apollo.QueryResult<SidebarChannelsQuery, SidebarChannelsQueryVariables>;
export const SidebarChannels_CreateChannelDocument = gql`
    mutation SidebarChannels_CreateChannel($name: String, $members: [ID!]!, $type: String!, $description: String) {
  createGroup(
    name: $name
    type: $type
    members: $members
    description: $description
  ) {
    groups {
      ...SidebarChannels_Channel
    }
    sessions {
      ...SessionTab_AppSessionSession
    }
    activeSession {
      ...SessionTabs_ActiveSession
      ...Sidebar_ActiveSession
    }
    potentialGroupMembers {
      id
      fullName
      profilePicture
    }
  }
}
    ${SidebarChannels_ChannelFragmentDoc}
${SessionTab_AppSessionSessionFragmentDoc}
${SessionTabs_ActiveSessionFragmentDoc}
${Sidebar_ActiveSessionFragmentDoc}`;
export type SidebarChannels_CreateChannelMutationFn = Apollo.MutationFunction<SidebarChannels_CreateChannelMutation, SidebarChannels_CreateChannelMutationVariables>;

/**
 * __useSidebarChannels_CreateChannelMutation__
 *
 * To run a mutation, you first call `useSidebarChannels_CreateChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSidebarChannels_CreateChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sidebarChannelsCreateChannelMutation, { data, loading, error }] = useSidebarChannels_CreateChannelMutation({
 *   variables: {
 *      name: // value for 'name'
 *      members: // value for 'members'
 *      type: // value for 'type'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useSidebarChannels_CreateChannelMutation(baseOptions?: Apollo.MutationHookOptions<SidebarChannels_CreateChannelMutation, SidebarChannels_CreateChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SidebarChannels_CreateChannelMutation, SidebarChannels_CreateChannelMutationVariables>(SidebarChannels_CreateChannelDocument, options);
      }
export type SidebarChannels_CreateChannelMutationHookResult = ReturnType<typeof useSidebarChannels_CreateChannelMutation>;
export type SidebarChannels_CreateChannelMutationResult = Apollo.MutationResult<SidebarChannels_CreateChannelMutation>;
export type SidebarChannels_CreateChannelMutationOptions = Apollo.BaseMutationOptions<SidebarChannels_CreateChannelMutation, SidebarChannels_CreateChannelMutationVariables>;
export const SidebarChannels_SelectChannelDocument = gql`
    mutation SidebarChannels_SelectChannel($id: ID!) {
  selectGroup(id: $id) {
    sessions {
      ...SessionTab_AppSessionSession
    }
    activeSession {
      ...SessionTabs_ActiveSession
      ...Sidebar_ActiveSession
    }
  }
}
    ${SessionTab_AppSessionSessionFragmentDoc}
${SessionTabs_ActiveSessionFragmentDoc}
${Sidebar_ActiveSessionFragmentDoc}`;
export type SidebarChannels_SelectChannelMutationFn = Apollo.MutationFunction<SidebarChannels_SelectChannelMutation, SidebarChannels_SelectChannelMutationVariables>;

/**
 * __useSidebarChannels_SelectChannelMutation__
 *
 * To run a mutation, you first call `useSidebarChannels_SelectChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSidebarChannels_SelectChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sidebarChannelsSelectChannelMutation, { data, loading, error }] = useSidebarChannels_SelectChannelMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSidebarChannels_SelectChannelMutation(baseOptions?: Apollo.MutationHookOptions<SidebarChannels_SelectChannelMutation, SidebarChannels_SelectChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SidebarChannels_SelectChannelMutation, SidebarChannels_SelectChannelMutationVariables>(SidebarChannels_SelectChannelDocument, options);
      }
export type SidebarChannels_SelectChannelMutationHookResult = ReturnType<typeof useSidebarChannels_SelectChannelMutation>;
export type SidebarChannels_SelectChannelMutationResult = Apollo.MutationResult<SidebarChannels_SelectChannelMutation>;
export type SidebarChannels_SelectChannelMutationOptions = Apollo.BaseMutationOptions<SidebarChannels_SelectChannelMutation, SidebarChannels_SelectChannelMutationVariables>;
export const SidebarUnreadCommentsDocument = gql`
    query SidebarUnreadComments {
  activeSession {
    id
  }
  unreadComments {
    ...SidebarUnreadComments_Unread
  }
}
    ${SidebarUnreadComments_UnreadFragmentDoc}`;

/**
 * __useSidebarUnreadCommentsQuery__
 *
 * To run a query within a React component, call `useSidebarUnreadCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSidebarUnreadCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSidebarUnreadCommentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSidebarUnreadCommentsQuery(baseOptions?: Apollo.QueryHookOptions<SidebarUnreadCommentsQuery, SidebarUnreadCommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SidebarUnreadCommentsQuery, SidebarUnreadCommentsQueryVariables>(SidebarUnreadCommentsDocument, options);
      }
export function useSidebarUnreadCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SidebarUnreadCommentsQuery, SidebarUnreadCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SidebarUnreadCommentsQuery, SidebarUnreadCommentsQueryVariables>(SidebarUnreadCommentsDocument, options);
        }
export type SidebarUnreadCommentsQueryHookResult = ReturnType<typeof useSidebarUnreadCommentsQuery>;
export type SidebarUnreadCommentsLazyQueryHookResult = ReturnType<typeof useSidebarUnreadCommentsLazyQuery>;
export type SidebarUnreadCommentsQueryResult = Apollo.QueryResult<SidebarUnreadCommentsQuery, SidebarUnreadCommentsQueryVariables>;
export const SidebarUnreadComments_MarkNotificationClickedDocument = gql`
    mutation SidebarUnreadComments_MarkNotificationClicked($ids: [ID!]!) {
  markNotificationClicked(ids: $ids) {
    unreadComments {
      ...SidebarUnreadComments_Unread
    }
  }
}
    ${SidebarUnreadComments_UnreadFragmentDoc}`;
export type SidebarUnreadComments_MarkNotificationClickedMutationFn = Apollo.MutationFunction<SidebarUnreadComments_MarkNotificationClickedMutation, SidebarUnreadComments_MarkNotificationClickedMutationVariables>;

/**
 * __useSidebarUnreadComments_MarkNotificationClickedMutation__
 *
 * To run a mutation, you first call `useSidebarUnreadComments_MarkNotificationClickedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSidebarUnreadComments_MarkNotificationClickedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sidebarUnreadCommentsMarkNotificationClickedMutation, { data, loading, error }] = useSidebarUnreadComments_MarkNotificationClickedMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useSidebarUnreadComments_MarkNotificationClickedMutation(baseOptions?: Apollo.MutationHookOptions<SidebarUnreadComments_MarkNotificationClickedMutation, SidebarUnreadComments_MarkNotificationClickedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SidebarUnreadComments_MarkNotificationClickedMutation, SidebarUnreadComments_MarkNotificationClickedMutationVariables>(SidebarUnreadComments_MarkNotificationClickedDocument, options);
      }
export type SidebarUnreadComments_MarkNotificationClickedMutationHookResult = ReturnType<typeof useSidebarUnreadComments_MarkNotificationClickedMutation>;
export type SidebarUnreadComments_MarkNotificationClickedMutationResult = Apollo.MutationResult<SidebarUnreadComments_MarkNotificationClickedMutation>;
export type SidebarUnreadComments_MarkNotificationClickedMutationOptions = Apollo.BaseMutationOptions<SidebarUnreadComments_MarkNotificationClickedMutation, SidebarUnreadComments_MarkNotificationClickedMutationVariables>;
export const SidebarUnreadComments_UnreadCommentsDocument = gql`
    subscription SidebarUnreadComments_UnreadComments($session: ID!) {
  unreadComments(session: $session) {
    unreadComments {
      ...SidebarUnreadComments_Unread
    }
  }
}
    ${SidebarUnreadComments_UnreadFragmentDoc}`;

/**
 * __useSidebarUnreadComments_UnreadCommentsSubscription__
 *
 * To run a query within a React component, call `useSidebarUnreadComments_UnreadCommentsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSidebarUnreadComments_UnreadCommentsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSidebarUnreadComments_UnreadCommentsSubscription({
 *   variables: {
 *      session: // value for 'session'
 *   },
 * });
 */
export function useSidebarUnreadComments_UnreadCommentsSubscription(baseOptions: Apollo.SubscriptionHookOptions<SidebarUnreadComments_UnreadCommentsSubscription, SidebarUnreadComments_UnreadCommentsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SidebarUnreadComments_UnreadCommentsSubscription, SidebarUnreadComments_UnreadCommentsSubscriptionVariables>(SidebarUnreadComments_UnreadCommentsDocument, options);
      }
export type SidebarUnreadComments_UnreadCommentsSubscriptionHookResult = ReturnType<typeof useSidebarUnreadComments_UnreadCommentsSubscription>;
export type SidebarUnreadComments_UnreadCommentsSubscriptionResult = Apollo.SubscriptionResult<SidebarUnreadComments_UnreadCommentsSubscription>;
export const AppSpaceDocument = gql`
    query AppSpace {
  activeAppSpace {
    ...AppSpace_ActiveAppSpace
    file {
      id
      content
      type
      kind
      readOnly
    }
  }
  appSpaces {
    id
    isActive
    appSpace {
      id
      name
    }
  }
}
    ${AppSpace_ActiveAppSpaceFragmentDoc}`;

/**
 * __useAppSpaceQuery__
 *
 * To run a query within a React component, call `useAppSpaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppSpaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppSpaceQuery({
 *   variables: {
 *   },
 * });
 */
export function useAppSpaceQuery(baseOptions?: Apollo.QueryHookOptions<AppSpaceQuery, AppSpaceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppSpaceQuery, AppSpaceQueryVariables>(AppSpaceDocument, options);
      }
export function useAppSpaceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppSpaceQuery, AppSpaceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppSpaceQuery, AppSpaceQueryVariables>(AppSpaceDocument, options);
        }
export type AppSpaceQueryHookResult = ReturnType<typeof useAppSpaceQuery>;
export type AppSpaceLazyQueryHookResult = ReturnType<typeof useAppSpaceLazyQuery>;
export type AppSpaceQueryResult = Apollo.QueryResult<AppSpaceQuery, AppSpaceQueryVariables>;
export const AppSpaces_UpdateFileContentDocument = gql`
    mutation AppSpaces_UpdateFileContent($id: ID!, $content: String!) {
  updateFileContent(id: $id, content: $content) {
    id
  }
}
    `;
export type AppSpaces_UpdateFileContentMutationFn = Apollo.MutationFunction<AppSpaces_UpdateFileContentMutation, AppSpaces_UpdateFileContentMutationVariables>;

/**
 * __useAppSpaces_UpdateFileContentMutation__
 *
 * To run a mutation, you first call `useAppSpaces_UpdateFileContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppSpaces_UpdateFileContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appSpacesUpdateFileContentMutation, { data, loading, error }] = useAppSpaces_UpdateFileContentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useAppSpaces_UpdateFileContentMutation(baseOptions?: Apollo.MutationHookOptions<AppSpaces_UpdateFileContentMutation, AppSpaces_UpdateFileContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AppSpaces_UpdateFileContentMutation, AppSpaces_UpdateFileContentMutationVariables>(AppSpaces_UpdateFileContentDocument, options);
      }
export type AppSpaces_UpdateFileContentMutationHookResult = ReturnType<typeof useAppSpaces_UpdateFileContentMutation>;
export type AppSpaces_UpdateFileContentMutationResult = Apollo.MutationResult<AppSpaces_UpdateFileContentMutation>;
export type AppSpaces_UpdateFileContentMutationOptions = Apollo.BaseMutationOptions<AppSpaces_UpdateFileContentMutation, AppSpaces_UpdateFileContentMutationVariables>;
export const AppSpaces_RenameAppSpaceDocument = gql`
    mutation AppSpaces_RenameAppSpace($id: ID!, $newName: String!) {
  renameAppSpace(id: $id, newName: $newName) {
    id
    name
  }
}
    `;
export type AppSpaces_RenameAppSpaceMutationFn = Apollo.MutationFunction<AppSpaces_RenameAppSpaceMutation, AppSpaces_RenameAppSpaceMutationVariables>;

/**
 * __useAppSpaces_RenameAppSpaceMutation__
 *
 * To run a mutation, you first call `useAppSpaces_RenameAppSpaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppSpaces_RenameAppSpaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appSpacesRenameAppSpaceMutation, { data, loading, error }] = useAppSpaces_RenameAppSpaceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      newName: // value for 'newName'
 *   },
 * });
 */
export function useAppSpaces_RenameAppSpaceMutation(baseOptions?: Apollo.MutationHookOptions<AppSpaces_RenameAppSpaceMutation, AppSpaces_RenameAppSpaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AppSpaces_RenameAppSpaceMutation, AppSpaces_RenameAppSpaceMutationVariables>(AppSpaces_RenameAppSpaceDocument, options);
      }
export type AppSpaces_RenameAppSpaceMutationHookResult = ReturnType<typeof useAppSpaces_RenameAppSpaceMutation>;
export type AppSpaces_RenameAppSpaceMutationResult = Apollo.MutationResult<AppSpaces_RenameAppSpaceMutation>;
export type AppSpaces_RenameAppSpaceMutationOptions = Apollo.BaseMutationOptions<AppSpaces_RenameAppSpaceMutation, AppSpaces_RenameAppSpaceMutationVariables>;
export const AppSpaces_SelectAppSpaceDocument = gql`
    mutation AppSpaces_SelectAppSpace($id: ID!) {
  selectAppSpace(id: $id) {
    appSpaces {
      ...AppSpaceTabs_AppSpace
    }
    activeAppSpace {
      type
      id
      name
    }
  }
}
    ${AppSpaceTabs_AppSpaceFragmentDoc}`;
export type AppSpaces_SelectAppSpaceMutationFn = Apollo.MutationFunction<AppSpaces_SelectAppSpaceMutation, AppSpaces_SelectAppSpaceMutationVariables>;

/**
 * __useAppSpaces_SelectAppSpaceMutation__
 *
 * To run a mutation, you first call `useAppSpaces_SelectAppSpaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppSpaces_SelectAppSpaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appSpacesSelectAppSpaceMutation, { data, loading, error }] = useAppSpaces_SelectAppSpaceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAppSpaces_SelectAppSpaceMutation(baseOptions?: Apollo.MutationHookOptions<AppSpaces_SelectAppSpaceMutation, AppSpaces_SelectAppSpaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AppSpaces_SelectAppSpaceMutation, AppSpaces_SelectAppSpaceMutationVariables>(AppSpaces_SelectAppSpaceDocument, options);
      }
export type AppSpaces_SelectAppSpaceMutationHookResult = ReturnType<typeof useAppSpaces_SelectAppSpaceMutation>;
export type AppSpaces_SelectAppSpaceMutationResult = Apollo.MutationResult<AppSpaces_SelectAppSpaceMutation>;
export type AppSpaces_SelectAppSpaceMutationOptions = Apollo.BaseMutationOptions<AppSpaces_SelectAppSpaceMutation, AppSpaces_SelectAppSpaceMutationVariables>;
export const AppSpaces_CreateAppSpaceDocument = gql`
    mutation AppSpaces_CreateAppSpace($type: String!, $name: String, $content: String, $folderId: String) {
  createAppSpace(type: $type, name: $name, content: $content, folderId: $folderId) {
    appSpaces {
      ...AppSpaceTabs_AppSpace
    }
    activeAppSpace {
      type
      id
      name
    }
  }
}
    ${AppSpaceTabs_AppSpaceFragmentDoc}`;
export type AppSpaces_CreateAppSpaceMutationFn = Apollo.MutationFunction<AppSpaces_CreateAppSpaceMutation, AppSpaces_CreateAppSpaceMutationVariables>;

/**
 * __useAppSpaces_CreateAppSpaceMutation__
 *
 * To run a mutation, you first call `useAppSpaces_CreateAppSpaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppSpaces_CreateAppSpaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appSpacesCreateAppSpaceMutation, { data, loading, error }] = useAppSpaces_CreateAppSpaceMutation({
 *   variables: {
 *      type: // value for 'type'
 *      name: // value for 'name'
 *      content: // value for 'content'
 *      folderId: // value for 'folderId'
 *   },
 * });
 */
export function useAppSpaces_CreateAppSpaceMutation(baseOptions?: Apollo.MutationHookOptions<AppSpaces_CreateAppSpaceMutation, AppSpaces_CreateAppSpaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AppSpaces_CreateAppSpaceMutation, AppSpaces_CreateAppSpaceMutationVariables>(AppSpaces_CreateAppSpaceDocument, options);
      }
export type AppSpaces_CreateAppSpaceMutationHookResult = ReturnType<typeof useAppSpaces_CreateAppSpaceMutation>;
export type AppSpaces_CreateAppSpaceMutationResult = Apollo.MutationResult<AppSpaces_CreateAppSpaceMutation>;
export type AppSpaces_CreateAppSpaceMutationOptions = Apollo.BaseMutationOptions<AppSpaces_CreateAppSpaceMutation, AppSpaces_CreateAppSpaceMutationVariables>;
export const AppSpaces_CloseAppSpaceDocument = gql`
    mutation AppSpaces_CloseAppSpace($appSpaceId: ID!) {
  closeAppSpace(appSpaceId: $appSpaceId) {
    appSpaces {
      ...AppSpaceTabs_AppSpace
    }
    activeAppSpace {
      type
      id
      name
    }
  }
}
    ${AppSpaceTabs_AppSpaceFragmentDoc}`;
export type AppSpaces_CloseAppSpaceMutationFn = Apollo.MutationFunction<AppSpaces_CloseAppSpaceMutation, AppSpaces_CloseAppSpaceMutationVariables>;

/**
 * __useAppSpaces_CloseAppSpaceMutation__
 *
 * To run a mutation, you first call `useAppSpaces_CloseAppSpaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppSpaces_CloseAppSpaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appSpacesCloseAppSpaceMutation, { data, loading, error }] = useAppSpaces_CloseAppSpaceMutation({
 *   variables: {
 *      appSpaceId: // value for 'appSpaceId'
 *   },
 * });
 */
export function useAppSpaces_CloseAppSpaceMutation(baseOptions?: Apollo.MutationHookOptions<AppSpaces_CloseAppSpaceMutation, AppSpaces_CloseAppSpaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AppSpaces_CloseAppSpaceMutation, AppSpaces_CloseAppSpaceMutationVariables>(AppSpaces_CloseAppSpaceDocument, options);
      }
export type AppSpaces_CloseAppSpaceMutationHookResult = ReturnType<typeof useAppSpaces_CloseAppSpaceMutation>;
export type AppSpaces_CloseAppSpaceMutationResult = Apollo.MutationResult<AppSpaces_CloseAppSpaceMutation>;
export type AppSpaces_CloseAppSpaceMutationOptions = Apollo.BaseMutationOptions<AppSpaces_CloseAppSpaceMutation, AppSpaces_CloseAppSpaceMutationVariables>;
export const AppSpaces_UpgradeDocument = gql`
    mutation AppSpaces_Upgrade($id: String!) {
  upgradeDocument(id: $id, upgrade: true) {
    id
    kind
  }
}
    `;
export type AppSpaces_UpgradeMutationFn = Apollo.MutationFunction<AppSpaces_UpgradeMutation, AppSpaces_UpgradeMutationVariables>;

/**
 * __useAppSpaces_UpgradeMutation__
 *
 * To run a mutation, you first call `useAppSpaces_UpgradeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppSpaces_UpgradeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appSpacesUpgradeMutation, { data, loading, error }] = useAppSpaces_UpgradeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAppSpaces_UpgradeMutation(baseOptions?: Apollo.MutationHookOptions<AppSpaces_UpgradeMutation, AppSpaces_UpgradeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AppSpaces_UpgradeMutation, AppSpaces_UpgradeMutationVariables>(AppSpaces_UpgradeDocument, options);
      }
export type AppSpaces_UpgradeMutationHookResult = ReturnType<typeof useAppSpaces_UpgradeMutation>;
export type AppSpaces_UpgradeMutationResult = Apollo.MutationResult<AppSpaces_UpgradeMutation>;
export type AppSpaces_UpgradeMutationOptions = Apollo.BaseMutationOptions<AppSpaces_UpgradeMutation, AppSpaces_UpgradeMutationVariables>;
export const AppSpaces_DowngradeDocument = gql`
    mutation AppSpaces_Downgrade($id: String!) {
  upgradeDocument(id: $id, upgrade: false) {
    id
    kind
  }
}
    `;
export type AppSpaces_DowngradeMutationFn = Apollo.MutationFunction<AppSpaces_DowngradeMutation, AppSpaces_DowngradeMutationVariables>;

/**
 * __useAppSpaces_DowngradeMutation__
 *
 * To run a mutation, you first call `useAppSpaces_DowngradeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppSpaces_DowngradeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appSpacesDowngradeMutation, { data, loading, error }] = useAppSpaces_DowngradeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAppSpaces_DowngradeMutation(baseOptions?: Apollo.MutationHookOptions<AppSpaces_DowngradeMutation, AppSpaces_DowngradeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AppSpaces_DowngradeMutation, AppSpaces_DowngradeMutationVariables>(AppSpaces_DowngradeDocument, options);
      }
export type AppSpaces_DowngradeMutationHookResult = ReturnType<typeof useAppSpaces_DowngradeMutation>;
export type AppSpaces_DowngradeMutationResult = Apollo.MutationResult<AppSpaces_DowngradeMutation>;
export type AppSpaces_DowngradeMutationOptions = Apollo.BaseMutationOptions<AppSpaces_DowngradeMutation, AppSpaces_DowngradeMutationVariables>;
export const AppSpaces_ExportFileDocument = gql`
    mutation AppSpaces_ExportFile($id: String!, $content: String!, $targetType: String!) {
  exportFile(id: $id, content: $content, targetType: $targetType) {
    appSpaces {
      ...AppSpaceTabs_AppSpace
    }
    activeAppSpace {
      ...AppSpace_ActiveAppSpace
    }
  }
}
    ${AppSpaceTabs_AppSpaceFragmentDoc}
${AppSpace_ActiveAppSpaceFragmentDoc}`;
export type AppSpaces_ExportFileMutationFn = Apollo.MutationFunction<AppSpaces_ExportFileMutation, AppSpaces_ExportFileMutationVariables>;

/**
 * __useAppSpaces_ExportFileMutation__
 *
 * To run a mutation, you first call `useAppSpaces_ExportFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppSpaces_ExportFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appSpacesExportFileMutation, { data, loading, error }] = useAppSpaces_ExportFileMutation({
 *   variables: {
 *      id: // value for 'id'
 *      content: // value for 'content'
 *      targetType: // value for 'targetType'
 *   },
 * });
 */
export function useAppSpaces_ExportFileMutation(baseOptions?: Apollo.MutationHookOptions<AppSpaces_ExportFileMutation, AppSpaces_ExportFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AppSpaces_ExportFileMutation, AppSpaces_ExportFileMutationVariables>(AppSpaces_ExportFileDocument, options);
      }
export type AppSpaces_ExportFileMutationHookResult = ReturnType<typeof useAppSpaces_ExportFileMutation>;
export type AppSpaces_ExportFileMutationResult = Apollo.MutationResult<AppSpaces_ExportFileMutation>;
export type AppSpaces_ExportFileMutationOptions = Apollo.BaseMutationOptions<AppSpaces_ExportFileMutation, AppSpaces_ExportFileMutationVariables>;
export const AppSpaces_SetReadOnlyDocument = gql`
    mutation AppSpaces_SetReadOnly($id: String!, $readOnly: Boolean!, $html: String) {
  updateReadOnly(id: $id, readOnly: $readOnly, html: $html) {
    id
    name
    type
  }
}
    `;
export type AppSpaces_SetReadOnlyMutationFn = Apollo.MutationFunction<AppSpaces_SetReadOnlyMutation, AppSpaces_SetReadOnlyMutationVariables>;

/**
 * __useAppSpaces_SetReadOnlyMutation__
 *
 * To run a mutation, you first call `useAppSpaces_SetReadOnlyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppSpaces_SetReadOnlyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appSpacesSetReadOnlyMutation, { data, loading, error }] = useAppSpaces_SetReadOnlyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      readOnly: // value for 'readOnly'
 *      html: // value for 'html'
 *   },
 * });
 */
export function useAppSpaces_SetReadOnlyMutation(baseOptions?: Apollo.MutationHookOptions<AppSpaces_SetReadOnlyMutation, AppSpaces_SetReadOnlyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AppSpaces_SetReadOnlyMutation, AppSpaces_SetReadOnlyMutationVariables>(AppSpaces_SetReadOnlyDocument, options);
      }
export type AppSpaces_SetReadOnlyMutationHookResult = ReturnType<typeof useAppSpaces_SetReadOnlyMutation>;
export type AppSpaces_SetReadOnlyMutationResult = Apollo.MutationResult<AppSpaces_SetReadOnlyMutation>;
export type AppSpaces_SetReadOnlyMutationOptions = Apollo.BaseMutationOptions<AppSpaces_SetReadOnlyMutation, AppSpaces_SetReadOnlyMutationVariables>;
export const AppSpaces_FinishUploadExportedAppSpaceDocument = gql`
    mutation AppSpaces_FinishUploadExportedAppSpace($uploadId: String!, $masterId: String!) {
  finishUploadExportedFile(uploadId: $uploadId, masterId: $masterId) {
    appSpaces {
      ...AppSpaceTabs_AppSpace
    }
    activeAppSpace {
      ...AppSpace_ActiveAppSpace
    }
  }
}
    ${AppSpaceTabs_AppSpaceFragmentDoc}
${AppSpace_ActiveAppSpaceFragmentDoc}`;
export type AppSpaces_FinishUploadExportedAppSpaceMutationFn = Apollo.MutationFunction<AppSpaces_FinishUploadExportedAppSpaceMutation, AppSpaces_FinishUploadExportedAppSpaceMutationVariables>;

/**
 * __useAppSpaces_FinishUploadExportedAppSpaceMutation__
 *
 * To run a mutation, you first call `useAppSpaces_FinishUploadExportedAppSpaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppSpaces_FinishUploadExportedAppSpaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appSpacesFinishUploadExportedAppSpaceMutation, { data, loading, error }] = useAppSpaces_FinishUploadExportedAppSpaceMutation({
 *   variables: {
 *      uploadId: // value for 'uploadId'
 *      masterId: // value for 'masterId'
 *   },
 * });
 */
export function useAppSpaces_FinishUploadExportedAppSpaceMutation(baseOptions?: Apollo.MutationHookOptions<AppSpaces_FinishUploadExportedAppSpaceMutation, AppSpaces_FinishUploadExportedAppSpaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AppSpaces_FinishUploadExportedAppSpaceMutation, AppSpaces_FinishUploadExportedAppSpaceMutationVariables>(AppSpaces_FinishUploadExportedAppSpaceDocument, options);
      }
export type AppSpaces_FinishUploadExportedAppSpaceMutationHookResult = ReturnType<typeof useAppSpaces_FinishUploadExportedAppSpaceMutation>;
export type AppSpaces_FinishUploadExportedAppSpaceMutationResult = Apollo.MutationResult<AppSpaces_FinishUploadExportedAppSpaceMutation>;
export type AppSpaces_FinishUploadExportedAppSpaceMutationOptions = Apollo.BaseMutationOptions<AppSpaces_FinishUploadExportedAppSpaceMutation, AppSpaces_FinishUploadExportedAppSpaceMutationVariables>;
export const AppSpaces_DeleteAppSpaceDocument = gql`
    mutation AppSpaces_DeleteAppSpace($id: ID!) {
  deleteAppSpace(id: $id) {
    appSpaces {
      ...AppSpaceTabs_AppSpace
    }
  }
}
    ${AppSpaceTabs_AppSpaceFragmentDoc}`;
export type AppSpaces_DeleteAppSpaceMutationFn = Apollo.MutationFunction<AppSpaces_DeleteAppSpaceMutation, AppSpaces_DeleteAppSpaceMutationVariables>;

/**
 * __useAppSpaces_DeleteAppSpaceMutation__
 *
 * To run a mutation, you first call `useAppSpaces_DeleteAppSpaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppSpaces_DeleteAppSpaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appSpacesDeleteAppSpaceMutation, { data, loading, error }] = useAppSpaces_DeleteAppSpaceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAppSpaces_DeleteAppSpaceMutation(baseOptions?: Apollo.MutationHookOptions<AppSpaces_DeleteAppSpaceMutation, AppSpaces_DeleteAppSpaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AppSpaces_DeleteAppSpaceMutation, AppSpaces_DeleteAppSpaceMutationVariables>(AppSpaces_DeleteAppSpaceDocument, options);
      }
export type AppSpaces_DeleteAppSpaceMutationHookResult = ReturnType<typeof useAppSpaces_DeleteAppSpaceMutation>;
export type AppSpaces_DeleteAppSpaceMutationResult = Apollo.MutationResult<AppSpaces_DeleteAppSpaceMutation>;
export type AppSpaces_DeleteAppSpaceMutationOptions = Apollo.BaseMutationOptions<AppSpaces_DeleteAppSpaceMutation, AppSpaces_DeleteAppSpaceMutationVariables>;
export const AppSpaces_OpenAppSpaceDocument = gql`
    mutation AppSpaces_OpenAppSpace($type: String!, $meta: String!, $name: String!) {
  openAppSpace(type: $type, meta: $meta, name: $name) {
    appSpaces {
      ...AppSpaceTabs_AppSpace
    }
    activeAppSpace {
      type
      id
      name
    }
  }
}
    ${AppSpaceTabs_AppSpaceFragmentDoc}`;
export type AppSpaces_OpenAppSpaceMutationFn = Apollo.MutationFunction<AppSpaces_OpenAppSpaceMutation, AppSpaces_OpenAppSpaceMutationVariables>;

/**
 * __useAppSpaces_OpenAppSpaceMutation__
 *
 * To run a mutation, you first call `useAppSpaces_OpenAppSpaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppSpaces_OpenAppSpaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appSpacesOpenAppSpaceMutation, { data, loading, error }] = useAppSpaces_OpenAppSpaceMutation({
 *   variables: {
 *      type: // value for 'type'
 *      meta: // value for 'meta'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAppSpaces_OpenAppSpaceMutation(baseOptions?: Apollo.MutationHookOptions<AppSpaces_OpenAppSpaceMutation, AppSpaces_OpenAppSpaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AppSpaces_OpenAppSpaceMutation, AppSpaces_OpenAppSpaceMutationVariables>(AppSpaces_OpenAppSpaceDocument, options);
      }
export type AppSpaces_OpenAppSpaceMutationHookResult = ReturnType<typeof useAppSpaces_OpenAppSpaceMutation>;
export type AppSpaces_OpenAppSpaceMutationResult = Apollo.MutationResult<AppSpaces_OpenAppSpaceMutation>;
export type AppSpaces_OpenAppSpaceMutationOptions = Apollo.BaseMutationOptions<AppSpaces_OpenAppSpaceMutation, AppSpaces_OpenAppSpaceMutationVariables>;
export const AppSpaceTabsDocument = gql`
    query AppSpaceTabs {
  appSpaces {
    ...AppSpaceTabs_AppSpace
  }
  activeAppSpace {
    type
    id
    name
  }
}
    ${AppSpaceTabs_AppSpaceFragmentDoc}`;

/**
 * __useAppSpaceTabsQuery__
 *
 * To run a query within a React component, call `useAppSpaceTabsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppSpaceTabsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppSpaceTabsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAppSpaceTabsQuery(baseOptions?: Apollo.QueryHookOptions<AppSpaceTabsQuery, AppSpaceTabsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppSpaceTabsQuery, AppSpaceTabsQueryVariables>(AppSpaceTabsDocument, options);
      }
export function useAppSpaceTabsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppSpaceTabsQuery, AppSpaceTabsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppSpaceTabsQuery, AppSpaceTabsQueryVariables>(AppSpaceTabsDocument, options);
        }
export type AppSpaceTabsQueryHookResult = ReturnType<typeof useAppSpaceTabsQuery>;
export type AppSpaceTabsLazyQueryHookResult = ReturnType<typeof useAppSpaceTabsLazyQuery>;
export type AppSpaceTabsQueryResult = Apollo.QueryResult<AppSpaceTabsQuery, AppSpaceTabsQueryVariables>;
export const EditorHandlerDocument = gql`
    query EditorHandler {
  activeAppSpace {
    id
    name
  }
}
    `;

/**
 * __useEditorHandlerQuery__
 *
 * To run a query within a React component, call `useEditorHandlerQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorHandlerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorHandlerQuery({
 *   variables: {
 *   },
 * });
 */
export function useEditorHandlerQuery(baseOptions?: Apollo.QueryHookOptions<EditorHandlerQuery, EditorHandlerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditorHandlerQuery, EditorHandlerQueryVariables>(EditorHandlerDocument, options);
      }
export function useEditorHandlerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditorHandlerQuery, EditorHandlerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditorHandlerQuery, EditorHandlerQueryVariables>(EditorHandlerDocument, options);
        }
export type EditorHandlerQueryHookResult = ReturnType<typeof useEditorHandlerQuery>;
export type EditorHandlerLazyQueryHookResult = ReturnType<typeof useEditorHandlerLazyQuery>;
export type EditorHandlerQueryResult = Apollo.QueryResult<EditorHandlerQuery, EditorHandlerQueryVariables>;
export const NotificationViewDocument = gql`
    query NotificationView($skip: Int!, $take: Int!) {
  notifications(skip: $skip, take: $take) {
    ...Notification
  }
  unreadNotifications
}
    ${NotificationFragmentDoc}`;

/**
 * __useNotificationViewQuery__
 *
 * To run a query within a React component, call `useNotificationViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationViewQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useNotificationViewQuery(baseOptions: Apollo.QueryHookOptions<NotificationViewQuery, NotificationViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationViewQuery, NotificationViewQueryVariables>(NotificationViewDocument, options);
      }
export function useNotificationViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationViewQuery, NotificationViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationViewQuery, NotificationViewQueryVariables>(NotificationViewDocument, options);
        }
export type NotificationViewQueryHookResult = ReturnType<typeof useNotificationViewQuery>;
export type NotificationViewLazyQueryHookResult = ReturnType<typeof useNotificationViewLazyQuery>;
export type NotificationViewQueryResult = Apollo.QueryResult<NotificationViewQuery, NotificationViewQueryVariables>;
export const NotificationView_ClickNotificationDocument = gql`
    mutation NotificationView_ClickNotification($id: ID!) {
  clickNotification(id: $id) {
    notification {
      id
      isRead
    }
    query {
      unreadNotifications
    }
  }
}
    `;
export type NotificationView_ClickNotificationMutationFn = Apollo.MutationFunction<NotificationView_ClickNotificationMutation, NotificationView_ClickNotificationMutationVariables>;

/**
 * __useNotificationView_ClickNotificationMutation__
 *
 * To run a mutation, you first call `useNotificationView_ClickNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationView_ClickNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationViewClickNotificationMutation, { data, loading, error }] = useNotificationView_ClickNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNotificationView_ClickNotificationMutation(baseOptions?: Apollo.MutationHookOptions<NotificationView_ClickNotificationMutation, NotificationView_ClickNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NotificationView_ClickNotificationMutation, NotificationView_ClickNotificationMutationVariables>(NotificationView_ClickNotificationDocument, options);
      }
export type NotificationView_ClickNotificationMutationHookResult = ReturnType<typeof useNotificationView_ClickNotificationMutation>;
export type NotificationView_ClickNotificationMutationResult = Apollo.MutationResult<NotificationView_ClickNotificationMutation>;
export type NotificationView_ClickNotificationMutationOptions = Apollo.BaseMutationOptions<NotificationView_ClickNotificationMutation, NotificationView_ClickNotificationMutationVariables>;
export const NotificationView_NotificationsDocument = gql`
    subscription NotificationView_Notifications {
  notifications {
    ...Notification
  }
}
    ${NotificationFragmentDoc}`;

/**
 * __useNotificationView_NotificationsSubscription__
 *
 * To run a query within a React component, call `useNotificationView_NotificationsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNotificationView_NotificationsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationView_NotificationsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNotificationView_NotificationsSubscription(baseOptions?: Apollo.SubscriptionHookOptions<NotificationView_NotificationsSubscription, NotificationView_NotificationsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<NotificationView_NotificationsSubscription, NotificationView_NotificationsSubscriptionVariables>(NotificationView_NotificationsDocument, options);
      }
export type NotificationView_NotificationsSubscriptionHookResult = ReturnType<typeof useNotificationView_NotificationsSubscription>;
export type NotificationView_NotificationsSubscriptionResult = Apollo.SubscriptionResult<NotificationView_NotificationsSubscription>;
export const DesignerCompDocument = gql`
    query DesignerComp {
  activeAppSpace {
    type
    id
    name
    file {
      id
      name
    }
  }
}
    `;

/**
 * __useDesignerCompQuery__
 *
 * To run a query within a React component, call `useDesignerCompQuery` and pass it any options that fit your needs.
 * When your component renders, `useDesignerCompQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDesignerCompQuery({
 *   variables: {
 *   },
 * });
 */
export function useDesignerCompQuery(baseOptions?: Apollo.QueryHookOptions<DesignerCompQuery, DesignerCompQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DesignerCompQuery, DesignerCompQueryVariables>(DesignerCompDocument, options);
      }
export function useDesignerCompLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DesignerCompQuery, DesignerCompQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DesignerCompQuery, DesignerCompQueryVariables>(DesignerCompDocument, options);
        }
export type DesignerCompQueryHookResult = ReturnType<typeof useDesignerCompQuery>;
export type DesignerCompLazyQueryHookResult = ReturnType<typeof useDesignerCompLazyQuery>;
export type DesignerCompQueryResult = Apollo.QueryResult<DesignerCompQuery, DesignerCompQueryVariables>;
export const TextDocuementDocument = gql`
    query TextDocuement {
  activeAppSpace {
    id
    name
  }
}
    `;

/**
 * __useTextDocuementQuery__
 *
 * To run a query within a React component, call `useTextDocuementQuery` and pass it any options that fit your needs.
 * When your component renders, `useTextDocuementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTextDocuementQuery({
 *   variables: {
 *   },
 * });
 */
export function useTextDocuementQuery(baseOptions?: Apollo.QueryHookOptions<TextDocuementQuery, TextDocuementQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TextDocuementQuery, TextDocuementQueryVariables>(TextDocuementDocument, options);
      }
export function useTextDocuementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TextDocuementQuery, TextDocuementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TextDocuementQuery, TextDocuementQueryVariables>(TextDocuementDocument, options);
        }
export type TextDocuementQueryHookResult = ReturnType<typeof useTextDocuementQuery>;
export type TextDocuementLazyQueryHookResult = ReturnType<typeof useTextDocuementLazyQuery>;
export type TextDocuementQueryResult = Apollo.QueryResult<TextDocuementQuery, TextDocuementQueryVariables>;
export const TextDocument_UploadImageDocument = gql`
    mutation TextDocument_UploadImage($filename: String, $fileType: String!) {
  uploadImage(filename: $filename, fileType: $fileType) {
    postData
    getData
  }
}
    `;
export type TextDocument_UploadImageMutationFn = Apollo.MutationFunction<TextDocument_UploadImageMutation, TextDocument_UploadImageMutationVariables>;

/**
 * __useTextDocument_UploadImageMutation__
 *
 * To run a mutation, you first call `useTextDocument_UploadImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTextDocument_UploadImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [textDocumentUploadImageMutation, { data, loading, error }] = useTextDocument_UploadImageMutation({
 *   variables: {
 *      filename: // value for 'filename'
 *      fileType: // value for 'fileType'
 *   },
 * });
 */
export function useTextDocument_UploadImageMutation(baseOptions?: Apollo.MutationHookOptions<TextDocument_UploadImageMutation, TextDocument_UploadImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TextDocument_UploadImageMutation, TextDocument_UploadImageMutationVariables>(TextDocument_UploadImageDocument, options);
      }
export type TextDocument_UploadImageMutationHookResult = ReturnType<typeof useTextDocument_UploadImageMutation>;
export type TextDocument_UploadImageMutationResult = Apollo.MutationResult<TextDocument_UploadImageMutation>;
export type TextDocument_UploadImageMutationOptions = Apollo.BaseMutationOptions<TextDocument_UploadImageMutation, TextDocument_UploadImageMutationVariables>;
export const Register_RegisterDocument = gql`
    mutation Register_Register($fullName: String, $email: String, $password: String, $guest: Boolean!) {
  signUp(fullName: $fullName, email: $email, password: $password, guest: $guest)
}
    `;
export type Register_RegisterMutationFn = Apollo.MutationFunction<Register_RegisterMutation, Register_RegisterMutationVariables>;

/**
 * __useRegister_RegisterMutation__
 *
 * To run a mutation, you first call `useRegister_RegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegister_RegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerRegisterMutation, { data, loading, error }] = useRegister_RegisterMutation({
 *   variables: {
 *      fullName: // value for 'fullName'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      guest: // value for 'guest'
 *   },
 * });
 */
export function useRegister_RegisterMutation(baseOptions?: Apollo.MutationHookOptions<Register_RegisterMutation, Register_RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Register_RegisterMutation, Register_RegisterMutationVariables>(Register_RegisterDocument, options);
      }
export type Register_RegisterMutationHookResult = ReturnType<typeof useRegister_RegisterMutation>;
export type Register_RegisterMutationResult = Apollo.MutationResult<Register_RegisterMutation>;
export type Register_RegisterMutationOptions = Apollo.BaseMutationOptions<Register_RegisterMutation, Register_RegisterMutationVariables>;
export const SignInDocument = gql`
    mutation SignIn($email: String!, $password: String!) {
  signin(email: $email, password: $password)
}
    `;
export type SignInMutationFn = Apollo.MutationFunction<SignInMutation, SignInMutationVariables>;

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSignInMutation(baseOptions?: Apollo.MutationHookOptions<SignInMutation, SignInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignInMutation, SignInMutationVariables>(SignInDocument, options);
      }
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>;
export type SignInMutationResult = Apollo.MutationResult<SignInMutation>;
export type SignInMutationOptions = Apollo.BaseMutationOptions<SignInMutation, SignInMutationVariables>;
export const Bookmarks_BookmarksDocument = gql`
    query Bookmarks_Bookmarks($message: ID!) {
  message(id: $message) {
    bookmarks {
      ...Bookmarks_Bookmark
    }
  }
}
    ${Bookmarks_BookmarkFragmentDoc}`;

/**
 * __useBookmarks_BookmarksQuery__
 *
 * To run a query within a React component, call `useBookmarks_BookmarksQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookmarks_BookmarksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookmarks_BookmarksQuery({
 *   variables: {
 *      message: // value for 'message'
 *   },
 * });
 */
export function useBookmarks_BookmarksQuery(baseOptions: Apollo.QueryHookOptions<Bookmarks_BookmarksQuery, Bookmarks_BookmarksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Bookmarks_BookmarksQuery, Bookmarks_BookmarksQueryVariables>(Bookmarks_BookmarksDocument, options);
      }
export function useBookmarks_BookmarksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Bookmarks_BookmarksQuery, Bookmarks_BookmarksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Bookmarks_BookmarksQuery, Bookmarks_BookmarksQueryVariables>(Bookmarks_BookmarksDocument, options);
        }
export type Bookmarks_BookmarksQueryHookResult = ReturnType<typeof useBookmarks_BookmarksQuery>;
export type Bookmarks_BookmarksLazyQueryHookResult = ReturnType<typeof useBookmarks_BookmarksLazyQuery>;
export type Bookmarks_BookmarksQueryResult = Apollo.QueryResult<Bookmarks_BookmarksQuery, Bookmarks_BookmarksQueryVariables>;
export const Bookmarks_UpdateBookmarkDocument = gql`
    mutation Bookmarks_UpdateBookmark($bookmarkId: ID!, $url: String!, $title: String!) {
  updateBookmark(bookmarkId: $bookmarkId, url: $url, title: $title) {
    ...Bookmarks_Bookmark
  }
}
    ${Bookmarks_BookmarkFragmentDoc}`;
export type Bookmarks_UpdateBookmarkMutationFn = Apollo.MutationFunction<Bookmarks_UpdateBookmarkMutation, Bookmarks_UpdateBookmarkMutationVariables>;

/**
 * __useBookmarks_UpdateBookmarkMutation__
 *
 * To run a mutation, you first call `useBookmarks_UpdateBookmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookmarks_UpdateBookmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookmarksUpdateBookmarkMutation, { data, loading, error }] = useBookmarks_UpdateBookmarkMutation({
 *   variables: {
 *      bookmarkId: // value for 'bookmarkId'
 *      url: // value for 'url'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useBookmarks_UpdateBookmarkMutation(baseOptions?: Apollo.MutationHookOptions<Bookmarks_UpdateBookmarkMutation, Bookmarks_UpdateBookmarkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Bookmarks_UpdateBookmarkMutation, Bookmarks_UpdateBookmarkMutationVariables>(Bookmarks_UpdateBookmarkDocument, options);
      }
export type Bookmarks_UpdateBookmarkMutationHookResult = ReturnType<typeof useBookmarks_UpdateBookmarkMutation>;
export type Bookmarks_UpdateBookmarkMutationResult = Apollo.MutationResult<Bookmarks_UpdateBookmarkMutation>;
export type Bookmarks_UpdateBookmarkMutationOptions = Apollo.BaseMutationOptions<Bookmarks_UpdateBookmarkMutation, Bookmarks_UpdateBookmarkMutationVariables>;
export const Bookmarks_DeleteBookmarkDocument = gql`
    mutation Bookmarks_DeleteBookmark($bookmarkId: ID!) {
  deleteBookmark(bookmarkId: $bookmarkId)
}
    `;
export type Bookmarks_DeleteBookmarkMutationFn = Apollo.MutationFunction<Bookmarks_DeleteBookmarkMutation, Bookmarks_DeleteBookmarkMutationVariables>;

/**
 * __useBookmarks_DeleteBookmarkMutation__
 *
 * To run a mutation, you first call `useBookmarks_DeleteBookmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookmarks_DeleteBookmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookmarksDeleteBookmarkMutation, { data, loading, error }] = useBookmarks_DeleteBookmarkMutation({
 *   variables: {
 *      bookmarkId: // value for 'bookmarkId'
 *   },
 * });
 */
export function useBookmarks_DeleteBookmarkMutation(baseOptions?: Apollo.MutationHookOptions<Bookmarks_DeleteBookmarkMutation, Bookmarks_DeleteBookmarkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Bookmarks_DeleteBookmarkMutation, Bookmarks_DeleteBookmarkMutationVariables>(Bookmarks_DeleteBookmarkDocument, options);
      }
export type Bookmarks_DeleteBookmarkMutationHookResult = ReturnType<typeof useBookmarks_DeleteBookmarkMutation>;
export type Bookmarks_DeleteBookmarkMutationResult = Apollo.MutationResult<Bookmarks_DeleteBookmarkMutation>;
export type Bookmarks_DeleteBookmarkMutationOptions = Apollo.BaseMutationOptions<Bookmarks_DeleteBookmarkMutation, Bookmarks_DeleteBookmarkMutationVariables>;
export const Chat_ChatDocument = gql`
    mutation Chat_Chat($message: String!, $type: String, $silent: Boolean, $richtext: Boolean, $attachments: [AttachmentInput!]) {
  chat(
    message: $message
    type: $type
    silent: $silent
    richtext: $richtext
    attachments: $attachments
  ) {
    ...Message_Message
  }
}
    ${Message_MessageFragmentDoc}`;
export type Chat_ChatMutationFn = Apollo.MutationFunction<Chat_ChatMutation, Chat_ChatMutationVariables>;

/**
 * __useChat_ChatMutation__
 *
 * To run a mutation, you first call `useChat_ChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChat_ChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chatChatMutation, { data, loading, error }] = useChat_ChatMutation({
 *   variables: {
 *      message: // value for 'message'
 *      type: // value for 'type'
 *      silent: // value for 'silent'
 *      richtext: // value for 'richtext'
 *      attachments: // value for 'attachments'
 *   },
 * });
 */
export function useChat_ChatMutation(baseOptions?: Apollo.MutationHookOptions<Chat_ChatMutation, Chat_ChatMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Chat_ChatMutation, Chat_ChatMutationVariables>(Chat_ChatDocument, options);
      }
export type Chat_ChatMutationHookResult = ReturnType<typeof useChat_ChatMutation>;
export type Chat_ChatMutationResult = Apollo.MutationResult<Chat_ChatMutation>;
export type Chat_ChatMutationOptions = Apollo.BaseMutationOptions<Chat_ChatMutation, Chat_ChatMutationVariables>;
export const Chat_MessagesDocument = gql`
    query Chat_Messages($offset: Int!) {
  activeSession {
    id
    chatHistory(offset: $offset) {
      ...Message_Message
    }
  }
}
    ${Message_MessageFragmentDoc}`;

/**
 * __useChat_MessagesQuery__
 *
 * To run a query within a React component, call `useChat_MessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useChat_MessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChat_MessagesQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useChat_MessagesQuery(baseOptions: Apollo.QueryHookOptions<Chat_MessagesQuery, Chat_MessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Chat_MessagesQuery, Chat_MessagesQueryVariables>(Chat_MessagesDocument, options);
      }
export function useChat_MessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Chat_MessagesQuery, Chat_MessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Chat_MessagesQuery, Chat_MessagesQueryVariables>(Chat_MessagesDocument, options);
        }
export type Chat_MessagesQueryHookResult = ReturnType<typeof useChat_MessagesQuery>;
export type Chat_MessagesLazyQueryHookResult = ReturnType<typeof useChat_MessagesLazyQuery>;
export type Chat_MessagesQueryResult = Apollo.QueryResult<Chat_MessagesQuery, Chat_MessagesQueryVariables>;
export const Chat_GroupDocument = gql`
    query Chat_Group($id: ID!) {
  group(id: $id) {
    id
    unreadCount
  }
}
    `;

/**
 * __useChat_GroupQuery__
 *
 * To run a query within a React component, call `useChat_GroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useChat_GroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChat_GroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useChat_GroupQuery(baseOptions: Apollo.QueryHookOptions<Chat_GroupQuery, Chat_GroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Chat_GroupQuery, Chat_GroupQueryVariables>(Chat_GroupDocument, options);
      }
export function useChat_GroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Chat_GroupQuery, Chat_GroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Chat_GroupQuery, Chat_GroupQueryVariables>(Chat_GroupDocument, options);
        }
export type Chat_GroupQueryHookResult = ReturnType<typeof useChat_GroupQuery>;
export type Chat_GroupLazyQueryHookResult = ReturnType<typeof useChat_GroupLazyQuery>;
export type Chat_GroupQueryResult = Apollo.QueryResult<Chat_GroupQuery, Chat_GroupQueryVariables>;
export const ChatDocument = gql`
    query Chat {
  me {
    id
    fullName
    isGuest
    isPretzel
    profilePicture
  }
  activeAppSpace {
    id
  }
  activeSession {
    id
    groupId
  }
  sessions {
    id
    session {
      id
      groupId
    }
  }
}
    `;

/**
 * __useChatQuery__
 *
 * To run a query within a React component, call `useChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatQuery({
 *   variables: {
 *   },
 * });
 */
export function useChatQuery(baseOptions?: Apollo.QueryHookOptions<ChatQuery, ChatQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChatQuery, ChatQueryVariables>(ChatDocument, options);
      }
export function useChatLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChatQuery, ChatQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChatQuery, ChatQueryVariables>(ChatDocument, options);
        }
export type ChatQueryHookResult = ReturnType<typeof useChatQuery>;
export type ChatLazyQueryHookResult = ReturnType<typeof useChatLazyQuery>;
export type ChatQueryResult = Apollo.QueryResult<ChatQuery, ChatQueryVariables>;
export const Chat_ReportTypeActionDocument = gql`
    mutation Chat_ReportTypeAction($group: ID!, $values: [String]!) {
  reportUserAction(type: "typing", group: $group, values: $values) {
    id
  }
}
    `;
export type Chat_ReportTypeActionMutationFn = Apollo.MutationFunction<Chat_ReportTypeActionMutation, Chat_ReportTypeActionMutationVariables>;

/**
 * __useChat_ReportTypeActionMutation__
 *
 * To run a mutation, you first call `useChat_ReportTypeActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChat_ReportTypeActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chatReportTypeActionMutation, { data, loading, error }] = useChat_ReportTypeActionMutation({
 *   variables: {
 *      group: // value for 'group'
 *      values: // value for 'values'
 *   },
 * });
 */
export function useChat_ReportTypeActionMutation(baseOptions?: Apollo.MutationHookOptions<Chat_ReportTypeActionMutation, Chat_ReportTypeActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Chat_ReportTypeActionMutation, Chat_ReportTypeActionMutationVariables>(Chat_ReportTypeActionDocument, options);
      }
export type Chat_ReportTypeActionMutationHookResult = ReturnType<typeof useChat_ReportTypeActionMutation>;
export type Chat_ReportTypeActionMutationResult = Apollo.MutationResult<Chat_ReportTypeActionMutation>;
export type Chat_ReportTypeActionMutationOptions = Apollo.BaseMutationOptions<Chat_ReportTypeActionMutation, Chat_ReportTypeActionMutationVariables>;
export const Chat_ReportReadActionDocument = gql`
    mutation Chat_ReportReadAction($group: ID!, $values: [String]!) {
  reportUserAction(type: "read", group: $group, values: $values) {
    id
    unreadCount
  }
}
    `;
export type Chat_ReportReadActionMutationFn = Apollo.MutationFunction<Chat_ReportReadActionMutation, Chat_ReportReadActionMutationVariables>;

/**
 * __useChat_ReportReadActionMutation__
 *
 * To run a mutation, you first call `useChat_ReportReadActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChat_ReportReadActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chatReportReadActionMutation, { data, loading, error }] = useChat_ReportReadActionMutation({
 *   variables: {
 *      group: // value for 'group'
 *      values: // value for 'values'
 *   },
 * });
 */
export function useChat_ReportReadActionMutation(baseOptions?: Apollo.MutationHookOptions<Chat_ReportReadActionMutation, Chat_ReportReadActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Chat_ReportReadActionMutation, Chat_ReportReadActionMutationVariables>(Chat_ReportReadActionDocument, options);
      }
export type Chat_ReportReadActionMutationHookResult = ReturnType<typeof useChat_ReportReadActionMutation>;
export type Chat_ReportReadActionMutationResult = Apollo.MutationResult<Chat_ReportReadActionMutation>;
export type Chat_ReportReadActionMutationOptions = Apollo.BaseMutationOptions<Chat_ReportReadActionMutation, Chat_ReportReadActionMutationVariables>;
export const Chat_FinishImageUploadDocument = gql`
    mutation Chat_FinishImageUpload($uploadId: String!, $name: String!, $size: Int!) {
  finishUploadImage(uploadId: $uploadId, name: $name, size: $size) {
    id
  }
}
    `;
export type Chat_FinishImageUploadMutationFn = Apollo.MutationFunction<Chat_FinishImageUploadMutation, Chat_FinishImageUploadMutationVariables>;

/**
 * __useChat_FinishImageUploadMutation__
 *
 * To run a mutation, you first call `useChat_FinishImageUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChat_FinishImageUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chatFinishImageUploadMutation, { data, loading, error }] = useChat_FinishImageUploadMutation({
 *   variables: {
 *      uploadId: // value for 'uploadId'
 *      name: // value for 'name'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useChat_FinishImageUploadMutation(baseOptions?: Apollo.MutationHookOptions<Chat_FinishImageUploadMutation, Chat_FinishImageUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Chat_FinishImageUploadMutation, Chat_FinishImageUploadMutationVariables>(Chat_FinishImageUploadDocument, options);
      }
export type Chat_FinishImageUploadMutationHookResult = ReturnType<typeof useChat_FinishImageUploadMutation>;
export type Chat_FinishImageUploadMutationResult = Apollo.MutationResult<Chat_FinishImageUploadMutation>;
export type Chat_FinishImageUploadMutationOptions = Apollo.BaseMutationOptions<Chat_FinishImageUploadMutation, Chat_FinishImageUploadMutationVariables>;
export const Chat_OnMessageReceivedDocument = gql`
    subscription Chat_OnMessageReceived($session: ID!) {
  messageReceived(session: $session) {
    ...Message_Message
  }
}
    ${Message_MessageFragmentDoc}`;

/**
 * __useChat_OnMessageReceivedSubscription__
 *
 * To run a query within a React component, call `useChat_OnMessageReceivedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useChat_OnMessageReceivedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChat_OnMessageReceivedSubscription({
 *   variables: {
 *      session: // value for 'session'
 *   },
 * });
 */
export function useChat_OnMessageReceivedSubscription(baseOptions: Apollo.SubscriptionHookOptions<Chat_OnMessageReceivedSubscription, Chat_OnMessageReceivedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Chat_OnMessageReceivedSubscription, Chat_OnMessageReceivedSubscriptionVariables>(Chat_OnMessageReceivedDocument, options);
      }
export type Chat_OnMessageReceivedSubscriptionHookResult = ReturnType<typeof useChat_OnMessageReceivedSubscription>;
export type Chat_OnMessageReceivedSubscriptionResult = Apollo.SubscriptionResult<Chat_OnMessageReceivedSubscription>;
export const Chat_OnMessageUpdatedDocument = gql`
    subscription Chat_OnMessageUpdated($session: ID!) {
  messageUpdated(session: $session) {
    id
    reactions {
      value
      reactors
      icon
    }
    attachments {
      ...MessageAttachments_MessageAttachment
    }
  }
}
    ${MessageAttachments_MessageAttachmentFragmentDoc}`;

/**
 * __useChat_OnMessageUpdatedSubscription__
 *
 * To run a query within a React component, call `useChat_OnMessageUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useChat_OnMessageUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChat_OnMessageUpdatedSubscription({
 *   variables: {
 *      session: // value for 'session'
 *   },
 * });
 */
export function useChat_OnMessageUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<Chat_OnMessageUpdatedSubscription, Chat_OnMessageUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Chat_OnMessageUpdatedSubscription, Chat_OnMessageUpdatedSubscriptionVariables>(Chat_OnMessageUpdatedDocument, options);
      }
export type Chat_OnMessageUpdatedSubscriptionHookResult = ReturnType<typeof useChat_OnMessageUpdatedSubscription>;
export type Chat_OnMessageUpdatedSubscriptionResult = Apollo.SubscriptionResult<Chat_OnMessageUpdatedSubscription>;
export const ChatHeadingDocument = gql`
    query ChatHeading($session: ID!) {
  session(id: $session) {
    id
    group {
      name
      description
      type
      id
      unreadCount
      members {
        id
        email
        name
        role
        profilePicture
      }
      canEdit
      otherMember {
        id
        email
        name
        profilePicture
      }
    }
  }
}
    `;

/**
 * __useChatHeadingQuery__
 *
 * To run a query within a React component, call `useChatHeadingQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatHeadingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatHeadingQuery({
 *   variables: {
 *      session: // value for 'session'
 *   },
 * });
 */
export function useChatHeadingQuery(baseOptions: Apollo.QueryHookOptions<ChatHeadingQuery, ChatHeadingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChatHeadingQuery, ChatHeadingQueryVariables>(ChatHeadingDocument, options);
      }
export function useChatHeadingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChatHeadingQuery, ChatHeadingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChatHeadingQuery, ChatHeadingQueryVariables>(ChatHeadingDocument, options);
        }
export type ChatHeadingQueryHookResult = ReturnType<typeof useChatHeadingQuery>;
export type ChatHeadingLazyQueryHookResult = ReturnType<typeof useChatHeadingLazyQuery>;
export type ChatHeadingQueryResult = Apollo.QueryResult<ChatHeadingQuery, ChatHeadingQueryVariables>;
export const ChatHeading_AddGroupMembersDocument = gql`
    mutation ChatHeading_AddGroupMembers($group: ID!, $members: [ID!]!) {
  addGroupMembers(group: $group, members: $members) {
    id
    name
    members {
      id
      email
      name
      role
      profilePicture
    }
  }
}
    `;
export type ChatHeading_AddGroupMembersMutationFn = Apollo.MutationFunction<ChatHeading_AddGroupMembersMutation, ChatHeading_AddGroupMembersMutationVariables>;

/**
 * __useChatHeading_AddGroupMembersMutation__
 *
 * To run a mutation, you first call `useChatHeading_AddGroupMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChatHeading_AddGroupMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chatHeadingAddGroupMembersMutation, { data, loading, error }] = useChatHeading_AddGroupMembersMutation({
 *   variables: {
 *      group: // value for 'group'
 *      members: // value for 'members'
 *   },
 * });
 */
export function useChatHeading_AddGroupMembersMutation(baseOptions?: Apollo.MutationHookOptions<ChatHeading_AddGroupMembersMutation, ChatHeading_AddGroupMembersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChatHeading_AddGroupMembersMutation, ChatHeading_AddGroupMembersMutationVariables>(ChatHeading_AddGroupMembersDocument, options);
      }
export type ChatHeading_AddGroupMembersMutationHookResult = ReturnType<typeof useChatHeading_AddGroupMembersMutation>;
export type ChatHeading_AddGroupMembersMutationResult = Apollo.MutationResult<ChatHeading_AddGroupMembersMutation>;
export type ChatHeading_AddGroupMembersMutationOptions = Apollo.BaseMutationOptions<ChatHeading_AddGroupMembersMutation, ChatHeading_AddGroupMembersMutationVariables>;
export const ChatHeading_UpdateGroupDocument = gql`
    mutation ChatHeading_UpdateGroup($id: ID!, $name: String!, $description: String) {
  updateGroup(id: $id, name: $name, description: $description) {
    id
    name
    description
  }
}
    `;
export type ChatHeading_UpdateGroupMutationFn = Apollo.MutationFunction<ChatHeading_UpdateGroupMutation, ChatHeading_UpdateGroupMutationVariables>;

/**
 * __useChatHeading_UpdateGroupMutation__
 *
 * To run a mutation, you first call `useChatHeading_UpdateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChatHeading_UpdateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chatHeadingUpdateGroupMutation, { data, loading, error }] = useChatHeading_UpdateGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useChatHeading_UpdateGroupMutation(baseOptions?: Apollo.MutationHookOptions<ChatHeading_UpdateGroupMutation, ChatHeading_UpdateGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChatHeading_UpdateGroupMutation, ChatHeading_UpdateGroupMutationVariables>(ChatHeading_UpdateGroupDocument, options);
      }
export type ChatHeading_UpdateGroupMutationHookResult = ReturnType<typeof useChatHeading_UpdateGroupMutation>;
export type ChatHeading_UpdateGroupMutationResult = Apollo.MutationResult<ChatHeading_UpdateGroupMutation>;
export type ChatHeading_UpdateGroupMutationOptions = Apollo.BaseMutationOptions<ChatHeading_UpdateGroupMutation, ChatHeading_UpdateGroupMutationVariables>;
export const ChatHeading_LeaveGrupDocument = gql`
    mutation ChatHeading_LeaveGrup($id: ID!) {
  leaveGroup(id: $id) {
    groups {
      ...SidebarChannels_Channel
    }
    potentialGroupMembers {
      id
      fullName
      profilePicture
    }
    sessions {
      ...SessionTab_AppSessionSession
    }
    activeSession {
      ...SessionTabs_ActiveSession
      ...Sidebar_ActiveSession
    }
  }
}
    ${SidebarChannels_ChannelFragmentDoc}
${SessionTab_AppSessionSessionFragmentDoc}
${SessionTabs_ActiveSessionFragmentDoc}
${Sidebar_ActiveSessionFragmentDoc}`;
export type ChatHeading_LeaveGrupMutationFn = Apollo.MutationFunction<ChatHeading_LeaveGrupMutation, ChatHeading_LeaveGrupMutationVariables>;

/**
 * __useChatHeading_LeaveGrupMutation__
 *
 * To run a mutation, you first call `useChatHeading_LeaveGrupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChatHeading_LeaveGrupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chatHeadingLeaveGrupMutation, { data, loading, error }] = useChatHeading_LeaveGrupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useChatHeading_LeaveGrupMutation(baseOptions?: Apollo.MutationHookOptions<ChatHeading_LeaveGrupMutation, ChatHeading_LeaveGrupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChatHeading_LeaveGrupMutation, ChatHeading_LeaveGrupMutationVariables>(ChatHeading_LeaveGrupDocument, options);
      }
export type ChatHeading_LeaveGrupMutationHookResult = ReturnType<typeof useChatHeading_LeaveGrupMutation>;
export type ChatHeading_LeaveGrupMutationResult = Apollo.MutationResult<ChatHeading_LeaveGrupMutation>;
export type ChatHeading_LeaveGrupMutationOptions = Apollo.BaseMutationOptions<ChatHeading_LeaveGrupMutation, ChatHeading_LeaveGrupMutationVariables>;
export const FileListingDocument = gql`
    query FileListing($messageId: ID!, $type: String!, $pageSize: Int!, $pageNumber: Int!, $expandedFolders: [String!]!, $orderMode: String!, $listingMode: String!, $query: String!, $limitMode: String!) {
  message(id: $messageId) {
    id
    fileListing(
      type: $type
      pageSize: $pageSize
      pageNumber: $pageNumber
      expandedFolders: $expandedFolders
      orderMode: $orderMode
      listingMode: $listingMode
      query: $query
      limitMode: $limitMode
    ) {
      ...FileListing_FileListing
    }
  }
  activeSession {
    id
  }
}
    ${FileListing_FileListingFragmentDoc}`;

/**
 * __useFileListingQuery__
 *
 * To run a query within a React component, call `useFileListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useFileListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFileListingQuery({
 *   variables: {
 *      messageId: // value for 'messageId'
 *      type: // value for 'type'
 *      pageSize: // value for 'pageSize'
 *      pageNumber: // value for 'pageNumber'
 *      expandedFolders: // value for 'expandedFolders'
 *      orderMode: // value for 'orderMode'
 *      listingMode: // value for 'listingMode'
 *      query: // value for 'query'
 *      limitMode: // value for 'limitMode'
 *   },
 * });
 */
export function useFileListingQuery(baseOptions: Apollo.QueryHookOptions<FileListingQuery, FileListingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FileListingQuery, FileListingQueryVariables>(FileListingDocument, options);
      }
export function useFileListingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FileListingQuery, FileListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FileListingQuery, FileListingQueryVariables>(FileListingDocument, options);
        }
export type FileListingQueryHookResult = ReturnType<typeof useFileListingQuery>;
export type FileListingLazyQueryHookResult = ReturnType<typeof useFileListingLazyQuery>;
export type FileListingQueryResult = Apollo.QueryResult<FileListingQuery, FileListingQueryVariables>;
export const FileListing_DownloadFileDocument = gql`
    query FileListing_DownloadFile($id: ID!) {
  downloadFile(id: $id) {
    url
  }
}
    `;

/**
 * __useFileListing_DownloadFileQuery__
 *
 * To run a query within a React component, call `useFileListing_DownloadFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useFileListing_DownloadFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFileListing_DownloadFileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFileListing_DownloadFileQuery(baseOptions: Apollo.QueryHookOptions<FileListing_DownloadFileQuery, FileListing_DownloadFileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FileListing_DownloadFileQuery, FileListing_DownloadFileQueryVariables>(FileListing_DownloadFileDocument, options);
      }
export function useFileListing_DownloadFileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FileListing_DownloadFileQuery, FileListing_DownloadFileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FileListing_DownloadFileQuery, FileListing_DownloadFileQueryVariables>(FileListing_DownloadFileDocument, options);
        }
export type FileListing_DownloadFileQueryHookResult = ReturnType<typeof useFileListing_DownloadFileQuery>;
export type FileListing_DownloadFileLazyQueryHookResult = ReturnType<typeof useFileListing_DownloadFileLazyQuery>;
export type FileListing_DownloadFileQueryResult = Apollo.QueryResult<FileListing_DownloadFileQuery, FileListing_DownloadFileQueryVariables>;
export const FileListing_SessionsDocument = gql`
    query FileListing_Sessions {
  allSessions {
    id
    name
    createdDate
    isClosed
    isActive
  }
}
    `;

/**
 * __useFileListing_SessionsQuery__
 *
 * To run a query within a React component, call `useFileListing_SessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFileListing_SessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFileListing_SessionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFileListing_SessionsQuery(baseOptions?: Apollo.QueryHookOptions<FileListing_SessionsQuery, FileListing_SessionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FileListing_SessionsQuery, FileListing_SessionsQueryVariables>(FileListing_SessionsDocument, options);
      }
export function useFileListing_SessionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FileListing_SessionsQuery, FileListing_SessionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FileListing_SessionsQuery, FileListing_SessionsQueryVariables>(FileListing_SessionsDocument, options);
        }
export type FileListing_SessionsQueryHookResult = ReturnType<typeof useFileListing_SessionsQuery>;
export type FileListing_SessionsLazyQueryHookResult = ReturnType<typeof useFileListing_SessionsLazyQuery>;
export type FileListing_SessionsQueryResult = Apollo.QueryResult<FileListing_SessionsQuery, FileListing_SessionsQueryVariables>;
export const FileListing_MoveFolderDocument = gql`
    mutation FileListing_MoveFolder($folder: ID!, $toFolder: ID!) {
  moveFolderToFolder(folder: $folder, toFolder: $toFolder) {
    updatedFolders {
      id
    }
  }
}
    `;
export type FileListing_MoveFolderMutationFn = Apollo.MutationFunction<FileListing_MoveFolderMutation, FileListing_MoveFolderMutationVariables>;

/**
 * __useFileListing_MoveFolderMutation__
 *
 * To run a mutation, you first call `useFileListing_MoveFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFileListing_MoveFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fileListingMoveFolderMutation, { data, loading, error }] = useFileListing_MoveFolderMutation({
 *   variables: {
 *      folder: // value for 'folder'
 *      toFolder: // value for 'toFolder'
 *   },
 * });
 */
export function useFileListing_MoveFolderMutation(baseOptions?: Apollo.MutationHookOptions<FileListing_MoveFolderMutation, FileListing_MoveFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FileListing_MoveFolderMutation, FileListing_MoveFolderMutationVariables>(FileListing_MoveFolderDocument, options);
      }
export type FileListing_MoveFolderMutationHookResult = ReturnType<typeof useFileListing_MoveFolderMutation>;
export type FileListing_MoveFolderMutationResult = Apollo.MutationResult<FileListing_MoveFolderMutation>;
export type FileListing_MoveFolderMutationOptions = Apollo.BaseMutationOptions<FileListing_MoveFolderMutation, FileListing_MoveFolderMutationVariables>;
export const FileListing_MoveFileToFolderDocument = gql`
    mutation FileListing_MoveFileToFolder($file: ID!, $toFolder: ID!) {
  moveFileToFolder(file: $file, toFolder: $toFolder) {
    updatedFolders {
      id
    }
  }
}
    `;
export type FileListing_MoveFileToFolderMutationFn = Apollo.MutationFunction<FileListing_MoveFileToFolderMutation, FileListing_MoveFileToFolderMutationVariables>;

/**
 * __useFileListing_MoveFileToFolderMutation__
 *
 * To run a mutation, you first call `useFileListing_MoveFileToFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFileListing_MoveFileToFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fileListingMoveFileToFolderMutation, { data, loading, error }] = useFileListing_MoveFileToFolderMutation({
 *   variables: {
 *      file: // value for 'file'
 *      toFolder: // value for 'toFolder'
 *   },
 * });
 */
export function useFileListing_MoveFileToFolderMutation(baseOptions?: Apollo.MutationHookOptions<FileListing_MoveFileToFolderMutation, FileListing_MoveFileToFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FileListing_MoveFileToFolderMutation, FileListing_MoveFileToFolderMutationVariables>(FileListing_MoveFileToFolderDocument, options);
      }
export type FileListing_MoveFileToFolderMutationHookResult = ReturnType<typeof useFileListing_MoveFileToFolderMutation>;
export type FileListing_MoveFileToFolderMutationResult = Apollo.MutationResult<FileListing_MoveFileToFolderMutation>;
export type FileListing_MoveFileToFolderMutationOptions = Apollo.BaseMutationOptions<FileListing_MoveFileToFolderMutation, FileListing_MoveFileToFolderMutationVariables>;
export const FileListing_RenameFolderDocument = gql`
    mutation FileListing_RenameFolder($id: ID!, $newName: String!) {
  renameFolder(id: $id, newName: $newName) {
    id
    name
  }
}
    `;
export type FileListing_RenameFolderMutationFn = Apollo.MutationFunction<FileListing_RenameFolderMutation, FileListing_RenameFolderMutationVariables>;

/**
 * __useFileListing_RenameFolderMutation__
 *
 * To run a mutation, you first call `useFileListing_RenameFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFileListing_RenameFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fileListingRenameFolderMutation, { data, loading, error }] = useFileListing_RenameFolderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      newName: // value for 'newName'
 *   },
 * });
 */
export function useFileListing_RenameFolderMutation(baseOptions?: Apollo.MutationHookOptions<FileListing_RenameFolderMutation, FileListing_RenameFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FileListing_RenameFolderMutation, FileListing_RenameFolderMutationVariables>(FileListing_RenameFolderDocument, options);
      }
export type FileListing_RenameFolderMutationHookResult = ReturnType<typeof useFileListing_RenameFolderMutation>;
export type FileListing_RenameFolderMutationResult = Apollo.MutationResult<FileListing_RenameFolderMutation>;
export type FileListing_RenameFolderMutationOptions = Apollo.BaseMutationOptions<FileListing_RenameFolderMutation, FileListing_RenameFolderMutationVariables>;
export const FileListing_DeleteFolderDocument = gql`
    mutation FileListing_DeleteFolder($id: ID!) {
  deleteFolder(id: $id) {
    query {
      appSpaces {
        ...AppSpaceTabs_AppSpace
      }
    }
  }
}
    ${AppSpaceTabs_AppSpaceFragmentDoc}`;
export type FileListing_DeleteFolderMutationFn = Apollo.MutationFunction<FileListing_DeleteFolderMutation, FileListing_DeleteFolderMutationVariables>;

/**
 * __useFileListing_DeleteFolderMutation__
 *
 * To run a mutation, you first call `useFileListing_DeleteFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFileListing_DeleteFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fileListingDeleteFolderMutation, { data, loading, error }] = useFileListing_DeleteFolderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFileListing_DeleteFolderMutation(baseOptions?: Apollo.MutationHookOptions<FileListing_DeleteFolderMutation, FileListing_DeleteFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FileListing_DeleteFolderMutation, FileListing_DeleteFolderMutationVariables>(FileListing_DeleteFolderDocument, options);
      }
export type FileListing_DeleteFolderMutationHookResult = ReturnType<typeof useFileListing_DeleteFolderMutation>;
export type FileListing_DeleteFolderMutationResult = Apollo.MutationResult<FileListing_DeleteFolderMutation>;
export type FileListing_DeleteFolderMutationOptions = Apollo.BaseMutationOptions<FileListing_DeleteFolderMutation, FileListing_DeleteFolderMutationVariables>;
export const FileListing_NewFolderDocument = gql`
    mutation FileListing_NewFolder($parentId: ID, $name: String!) {
  createFolder(parentId: $parentId, name: $name) {
    ...FileFolderTableView_Folder
  }
}
    ${FileFolderTableView_FolderFragmentDoc}`;
export type FileListing_NewFolderMutationFn = Apollo.MutationFunction<FileListing_NewFolderMutation, FileListing_NewFolderMutationVariables>;

/**
 * __useFileListing_NewFolderMutation__
 *
 * To run a mutation, you first call `useFileListing_NewFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFileListing_NewFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fileListingNewFolderMutation, { data, loading, error }] = useFileListing_NewFolderMutation({
 *   variables: {
 *      parentId: // value for 'parentId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useFileListing_NewFolderMutation(baseOptions?: Apollo.MutationHookOptions<FileListing_NewFolderMutation, FileListing_NewFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FileListing_NewFolderMutation, FileListing_NewFolderMutationVariables>(FileListing_NewFolderDocument, options);
      }
export type FileListing_NewFolderMutationHookResult = ReturnType<typeof useFileListing_NewFolderMutation>;
export type FileListing_NewFolderMutationResult = Apollo.MutationResult<FileListing_NewFolderMutation>;
export type FileListing_NewFolderMutationOptions = Apollo.BaseMutationOptions<FileListing_NewFolderMutation, FileListing_NewFolderMutationVariables>;
export const FileListing_UploadFileDocument = gql`
    mutation FileListing_UploadFile($fileName: String!, $fileType: String!, $fileSize: Int!, $folder: String) {
  uploadFile(
    fileName: $fileName
    fileType: $fileType
    folder: $folder
    fileSize: $fileSize
  ) {
    uploadId
    postData
  }
}
    `;
export type FileListing_UploadFileMutationFn = Apollo.MutationFunction<FileListing_UploadFileMutation, FileListing_UploadFileMutationVariables>;

/**
 * __useFileListing_UploadFileMutation__
 *
 * To run a mutation, you first call `useFileListing_UploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFileListing_UploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fileListingUploadFileMutation, { data, loading, error }] = useFileListing_UploadFileMutation({
 *   variables: {
 *      fileName: // value for 'fileName'
 *      fileType: // value for 'fileType'
 *      fileSize: // value for 'fileSize'
 *      folder: // value for 'folder'
 *   },
 * });
 */
export function useFileListing_UploadFileMutation(baseOptions?: Apollo.MutationHookOptions<FileListing_UploadFileMutation, FileListing_UploadFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FileListing_UploadFileMutation, FileListing_UploadFileMutationVariables>(FileListing_UploadFileDocument, options);
      }
export type FileListing_UploadFileMutationHookResult = ReturnType<typeof useFileListing_UploadFileMutation>;
export type FileListing_UploadFileMutationResult = Apollo.MutationResult<FileListing_UploadFileMutation>;
export type FileListing_UploadFileMutationOptions = Apollo.BaseMutationOptions<FileListing_UploadFileMutation, FileListing_UploadFileMutationVariables>;
export const FileListing_FinishUploadFileDocument = gql`
    mutation FileListing_FinishUploadFile($uploadId: String!) {
  finishFileUpload(uploadId: $uploadId) {
    appSpaces {
      ...AppSpaceTabs_AppSpace
    }
    activeAppSpace {
      ...AppSpace_ActiveAppSpace
    }
  }
}
    ${AppSpaceTabs_AppSpaceFragmentDoc}
${AppSpace_ActiveAppSpaceFragmentDoc}`;
export type FileListing_FinishUploadFileMutationFn = Apollo.MutationFunction<FileListing_FinishUploadFileMutation, FileListing_FinishUploadFileMutationVariables>;

/**
 * __useFileListing_FinishUploadFileMutation__
 *
 * To run a mutation, you first call `useFileListing_FinishUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFileListing_FinishUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fileListingFinishUploadFileMutation, { data, loading, error }] = useFileListing_FinishUploadFileMutation({
 *   variables: {
 *      uploadId: // value for 'uploadId'
 *   },
 * });
 */
export function useFileListing_FinishUploadFileMutation(baseOptions?: Apollo.MutationHookOptions<FileListing_FinishUploadFileMutation, FileListing_FinishUploadFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FileListing_FinishUploadFileMutation, FileListing_FinishUploadFileMutationVariables>(FileListing_FinishUploadFileDocument, options);
      }
export type FileListing_FinishUploadFileMutationHookResult = ReturnType<typeof useFileListing_FinishUploadFileMutation>;
export type FileListing_FinishUploadFileMutationResult = Apollo.MutationResult<FileListing_FinishUploadFileMutation>;
export type FileListing_FinishUploadFileMutationOptions = Apollo.BaseMutationOptions<FileListing_FinishUploadFileMutation, FileListing_FinishUploadFileMutationVariables>;
export const FileListing_OpenFileDocument = gql`
    mutation FileListing_OpenFile($fileId: ID!) {
  openFile(fileId: $fileId) {
    appSpaces {
      ...AppSpaceTabs_AppSpace
    }
    activeAppSpace {
      ...AppSpace_ActiveAppSpace
    }
  }
}
    ${AppSpaceTabs_AppSpaceFragmentDoc}
${AppSpace_ActiveAppSpaceFragmentDoc}`;
export type FileListing_OpenFileMutationFn = Apollo.MutationFunction<FileListing_OpenFileMutation, FileListing_OpenFileMutationVariables>;

/**
 * __useFileListing_OpenFileMutation__
 *
 * To run a mutation, you first call `useFileListing_OpenFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFileListing_OpenFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fileListingOpenFileMutation, { data, loading, error }] = useFileListing_OpenFileMutation({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useFileListing_OpenFileMutation(baseOptions?: Apollo.MutationHookOptions<FileListing_OpenFileMutation, FileListing_OpenFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FileListing_OpenFileMutation, FileListing_OpenFileMutationVariables>(FileListing_OpenFileDocument, options);
      }
export type FileListing_OpenFileMutationHookResult = ReturnType<typeof useFileListing_OpenFileMutation>;
export type FileListing_OpenFileMutationResult = Apollo.MutationResult<FileListing_OpenFileMutation>;
export type FileListing_OpenFileMutationOptions = Apollo.BaseMutationOptions<FileListing_OpenFileMutation, FileListing_OpenFileMutationVariables>;
export const FileListing_RenameFileDocument = gql`
    mutation FileListing_RenameFile($fileId: ID!, $newName: String!) {
  renameFile(fileId: $fileId, newName: $newName) {
    file {
      id
      name
    }
    appSpaces {
      id
      name
    }
  }
}
    `;
export type FileListing_RenameFileMutationFn = Apollo.MutationFunction<FileListing_RenameFileMutation, FileListing_RenameFileMutationVariables>;

/**
 * __useFileListing_RenameFileMutation__
 *
 * To run a mutation, you first call `useFileListing_RenameFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFileListing_RenameFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fileListingRenameFileMutation, { data, loading, error }] = useFileListing_RenameFileMutation({
 *   variables: {
 *      fileId: // value for 'fileId'
 *      newName: // value for 'newName'
 *   },
 * });
 */
export function useFileListing_RenameFileMutation(baseOptions?: Apollo.MutationHookOptions<FileListing_RenameFileMutation, FileListing_RenameFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FileListing_RenameFileMutation, FileListing_RenameFileMutationVariables>(FileListing_RenameFileDocument, options);
      }
export type FileListing_RenameFileMutationHookResult = ReturnType<typeof useFileListing_RenameFileMutation>;
export type FileListing_RenameFileMutationResult = Apollo.MutationResult<FileListing_RenameFileMutation>;
export type FileListing_RenameFileMutationOptions = Apollo.BaseMutationOptions<FileListing_RenameFileMutation, FileListing_RenameFileMutationVariables>;
export const FileListing_DeleteFileDocument = gql`
    mutation FileListing_DeleteFile($fileId: ID!) {
  deleteFile(fileId: $fileId) {
    ...FileFolderTableView_Folder
    ...FolderTreeView_Folder
  }
}
    ${FileFolderTableView_FolderFragmentDoc}
${FolderTreeView_FolderFragmentDoc}`;
export type FileListing_DeleteFileMutationFn = Apollo.MutationFunction<FileListing_DeleteFileMutation, FileListing_DeleteFileMutationVariables>;

/**
 * __useFileListing_DeleteFileMutation__
 *
 * To run a mutation, you first call `useFileListing_DeleteFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFileListing_DeleteFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fileListingDeleteFileMutation, { data, loading, error }] = useFileListing_DeleteFileMutation({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useFileListing_DeleteFileMutation(baseOptions?: Apollo.MutationHookOptions<FileListing_DeleteFileMutation, FileListing_DeleteFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FileListing_DeleteFileMutation, FileListing_DeleteFileMutationVariables>(FileListing_DeleteFileDocument, options);
      }
export type FileListing_DeleteFileMutationHookResult = ReturnType<typeof useFileListing_DeleteFileMutation>;
export type FileListing_DeleteFileMutationResult = Apollo.MutationResult<FileListing_DeleteFileMutation>;
export type FileListing_DeleteFileMutationOptions = Apollo.BaseMutationOptions<FileListing_DeleteFileMutation, FileListing_DeleteFileMutationVariables>;
export const FolderTreeView_NewFolderDocument = gql`
    mutation FolderTreeView_NewFolder($parentId: ID, $name: String!) {
  createFolder(parentId: $parentId, name: $name) {
    ...FolderTreeView_Folder
  }
}
    ${FolderTreeView_FolderFragmentDoc}`;
export type FolderTreeView_NewFolderMutationFn = Apollo.MutationFunction<FolderTreeView_NewFolderMutation, FolderTreeView_NewFolderMutationVariables>;

/**
 * __useFolderTreeView_NewFolderMutation__
 *
 * To run a mutation, you first call `useFolderTreeView_NewFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFolderTreeView_NewFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [folderTreeViewNewFolderMutation, { data, loading, error }] = useFolderTreeView_NewFolderMutation({
 *   variables: {
 *      parentId: // value for 'parentId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useFolderTreeView_NewFolderMutation(baseOptions?: Apollo.MutationHookOptions<FolderTreeView_NewFolderMutation, FolderTreeView_NewFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FolderTreeView_NewFolderMutation, FolderTreeView_NewFolderMutationVariables>(FolderTreeView_NewFolderDocument, options);
      }
export type FolderTreeView_NewFolderMutationHookResult = ReturnType<typeof useFolderTreeView_NewFolderMutation>;
export type FolderTreeView_NewFolderMutationResult = Apollo.MutationResult<FolderTreeView_NewFolderMutation>;
export type FolderTreeView_NewFolderMutationOptions = Apollo.BaseMutationOptions<FolderTreeView_NewFolderMutation, FolderTreeView_NewFolderMutationVariables>;
export const SessionOptionsMenu_DeleteSessionDocument = gql`
    mutation SessionOptionsMenu_DeleteSession($sessionId: ID!) {
  deleteSession(sessionId: $sessionId) {
    sessions {
      ...SessionTab_AppSessionSession
    }
    activeSession {
      ...SessionTabs_ActiveSession
      ...Sidebar_ActiveSession
    }
  }
}
    ${SessionTab_AppSessionSessionFragmentDoc}
${SessionTabs_ActiveSessionFragmentDoc}
${Sidebar_ActiveSessionFragmentDoc}`;
export type SessionOptionsMenu_DeleteSessionMutationFn = Apollo.MutationFunction<SessionOptionsMenu_DeleteSessionMutation, SessionOptionsMenu_DeleteSessionMutationVariables>;

/**
 * __useSessionOptionsMenu_DeleteSessionMutation__
 *
 * To run a mutation, you first call `useSessionOptionsMenu_DeleteSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSessionOptionsMenu_DeleteSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sessionOptionsMenuDeleteSessionMutation, { data, loading, error }] = useSessionOptionsMenu_DeleteSessionMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useSessionOptionsMenu_DeleteSessionMutation(baseOptions?: Apollo.MutationHookOptions<SessionOptionsMenu_DeleteSessionMutation, SessionOptionsMenu_DeleteSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SessionOptionsMenu_DeleteSessionMutation, SessionOptionsMenu_DeleteSessionMutationVariables>(SessionOptionsMenu_DeleteSessionDocument, options);
      }
export type SessionOptionsMenu_DeleteSessionMutationHookResult = ReturnType<typeof useSessionOptionsMenu_DeleteSessionMutation>;
export type SessionOptionsMenu_DeleteSessionMutationResult = Apollo.MutationResult<SessionOptionsMenu_DeleteSessionMutation>;
export type SessionOptionsMenu_DeleteSessionMutationOptions = Apollo.BaseMutationOptions<SessionOptionsMenu_DeleteSessionMutation, SessionOptionsMenu_DeleteSessionMutationVariables>;
export const ImageResultDocument = gql`
    query ImageResult($messageId: ID!, $count: Int!) {
  message(id: $messageId) {
    images(count: $count) {
      id
      _type
      thumbnailUrl
      contentUrl
      webSearchUrl
      hostPageUrl
      contentSize
      name
      width
      height
    }
  }
}
    `;

/**
 * __useImageResultQuery__
 *
 * To run a query within a React component, call `useImageResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useImageResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImageResultQuery({
 *   variables: {
 *      messageId: // value for 'messageId'
 *      count: // value for 'count'
 *   },
 * });
 */
export function useImageResultQuery(baseOptions: Apollo.QueryHookOptions<ImageResultQuery, ImageResultQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ImageResultQuery, ImageResultQueryVariables>(ImageResultDocument, options);
      }
export function useImageResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ImageResultQuery, ImageResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ImageResultQuery, ImageResultQueryVariables>(ImageResultDocument, options);
        }
export type ImageResultQueryHookResult = ReturnType<typeof useImageResultQuery>;
export type ImageResultLazyQueryHookResult = ReturnType<typeof useImageResultLazyQuery>;
export type ImageResultQueryResult = Apollo.QueryResult<ImageResultQuery, ImageResultQueryVariables>;
export const InlineContent_EditMessageDocument = gql`
    mutation InlineContent_EditMessage($id: ID!, $content: String!) {
  editMessage(id: $id, content: $content) {
    id
    editedAt
    textContent
  }
}
    `;
export type InlineContent_EditMessageMutationFn = Apollo.MutationFunction<InlineContent_EditMessageMutation, InlineContent_EditMessageMutationVariables>;

/**
 * __useInlineContent_EditMessageMutation__
 *
 * To run a mutation, you first call `useInlineContent_EditMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInlineContent_EditMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inlineContentEditMessageMutation, { data, loading, error }] = useInlineContent_EditMessageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useInlineContent_EditMessageMutation(baseOptions?: Apollo.MutationHookOptions<InlineContent_EditMessageMutation, InlineContent_EditMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InlineContent_EditMessageMutation, InlineContent_EditMessageMutationVariables>(InlineContent_EditMessageDocument, options);
      }
export type InlineContent_EditMessageMutationHookResult = ReturnType<typeof useInlineContent_EditMessageMutation>;
export type InlineContent_EditMessageMutationResult = Apollo.MutationResult<InlineContent_EditMessageMutation>;
export type InlineContent_EditMessageMutationOptions = Apollo.BaseMutationOptions<InlineContent_EditMessageMutation, InlineContent_EditMessageMutationVariables>;
export const InsertButtonDocument = gql`
    query InsertButton {
  activeAppSpace {
    id
    type
  }
}
    `;

/**
 * __useInsertButtonQuery__
 *
 * To run a query within a React component, call `useInsertButtonQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsertButtonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsertButtonQuery({
 *   variables: {
 *   },
 * });
 */
export function useInsertButtonQuery(baseOptions?: Apollo.QueryHookOptions<InsertButtonQuery, InsertButtonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InsertButtonQuery, InsertButtonQueryVariables>(InsertButtonDocument, options);
      }
export function useInsertButtonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsertButtonQuery, InsertButtonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InsertButtonQuery, InsertButtonQueryVariables>(InsertButtonDocument, options);
        }
export type InsertButtonQueryHookResult = ReturnType<typeof useInsertButtonQuery>;
export type InsertButtonLazyQueryHookResult = ReturnType<typeof useInsertButtonLazyQuery>;
export type InsertButtonQueryResult = Apollo.QueryResult<InsertButtonQuery, InsertButtonQueryVariables>;
export const ListDocument = gql`
    query List($expandedLists: [String!]!) {
  list(expandedLists: $expandedLists) {
    ...ListEditor_ListEditorResult
  }
}
    ${ListEditor_ListEditorResultFragmentDoc}`;

/**
 * __useListQuery__
 *
 * To run a query within a React component, call `useListQuery` and pass it any options that fit your needs.
 * When your component renders, `useListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListQuery({
 *   variables: {
 *      expandedLists: // value for 'expandedLists'
 *   },
 * });
 */
export function useListQuery(baseOptions: Apollo.QueryHookOptions<ListQuery, ListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListQuery, ListQueryVariables>(ListDocument, options);
      }
export function useListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListQuery, ListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListQuery, ListQueryVariables>(ListDocument, options);
        }
export type ListQueryHookResult = ReturnType<typeof useListQuery>;
export type ListLazyQueryHookResult = ReturnType<typeof useListLazyQuery>;
export type ListQueryResult = Apollo.QueryResult<ListQuery, ListQueryVariables>;
export const List_GetGlobalListDocument = gql`
    query List_GetGlobalList($name: String!) {
  getGlobalList(name: $name) {
    ...ListEditor_List
    children {
      id
      title
      index
    }
  }
}
    ${ListEditor_ListFragmentDoc}`;

/**
 * __useList_GetGlobalListQuery__
 *
 * To run a query within a React component, call `useList_GetGlobalListQuery` and pass it any options that fit your needs.
 * When your component renders, `useList_GetGlobalListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useList_GetGlobalListQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useList_GetGlobalListQuery(baseOptions: Apollo.QueryHookOptions<List_GetGlobalListQuery, List_GetGlobalListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<List_GetGlobalListQuery, List_GetGlobalListQueryVariables>(List_GetGlobalListDocument, options);
      }
export function useList_GetGlobalListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<List_GetGlobalListQuery, List_GetGlobalListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<List_GetGlobalListQuery, List_GetGlobalListQueryVariables>(List_GetGlobalListDocument, options);
        }
export type List_GetGlobalListQueryHookResult = ReturnType<typeof useList_GetGlobalListQuery>;
export type List_GetGlobalListLazyQueryHookResult = ReturnType<typeof useList_GetGlobalListLazyQuery>;
export type List_GetGlobalListQueryResult = Apollo.QueryResult<List_GetGlobalListQuery, List_GetGlobalListQueryVariables>;
export const ListEditor_CreateListDocument = gql`
    mutation ListEditor_CreateList($parentId: String!, $name: String!) {
  createList(parentId: $parentId, name: $name) {
    id
  }
}
    `;
export type ListEditor_CreateListMutationFn = Apollo.MutationFunction<ListEditor_CreateListMutation, ListEditor_CreateListMutationVariables>;

/**
 * __useListEditor_CreateListMutation__
 *
 * To run a mutation, you first call `useListEditor_CreateListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useListEditor_CreateListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [listEditorCreateListMutation, { data, loading, error }] = useListEditor_CreateListMutation({
 *   variables: {
 *      parentId: // value for 'parentId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useListEditor_CreateListMutation(baseOptions?: Apollo.MutationHookOptions<ListEditor_CreateListMutation, ListEditor_CreateListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ListEditor_CreateListMutation, ListEditor_CreateListMutationVariables>(ListEditor_CreateListDocument, options);
      }
export type ListEditor_CreateListMutationHookResult = ReturnType<typeof useListEditor_CreateListMutation>;
export type ListEditor_CreateListMutationResult = Apollo.MutationResult<ListEditor_CreateListMutation>;
export type ListEditor_CreateListMutationOptions = Apollo.BaseMutationOptions<ListEditor_CreateListMutation, ListEditor_CreateListMutationVariables>;
export const ListEditor_UpdateListTitleDocument = gql`
    mutation ListEditor_UpdateListTitle($id: String!, $title: String!) {
  updateListTitle(id: $id, title: $title)
}
    `;
export type ListEditor_UpdateListTitleMutationFn = Apollo.MutationFunction<ListEditor_UpdateListTitleMutation, ListEditor_UpdateListTitleMutationVariables>;

/**
 * __useListEditor_UpdateListTitleMutation__
 *
 * To run a mutation, you first call `useListEditor_UpdateListTitleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useListEditor_UpdateListTitleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [listEditorUpdateListTitleMutation, { data, loading, error }] = useListEditor_UpdateListTitleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useListEditor_UpdateListTitleMutation(baseOptions?: Apollo.MutationHookOptions<ListEditor_UpdateListTitleMutation, ListEditor_UpdateListTitleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ListEditor_UpdateListTitleMutation, ListEditor_UpdateListTitleMutationVariables>(ListEditor_UpdateListTitleDocument, options);
      }
export type ListEditor_UpdateListTitleMutationHookResult = ReturnType<typeof useListEditor_UpdateListTitleMutation>;
export type ListEditor_UpdateListTitleMutationResult = Apollo.MutationResult<ListEditor_UpdateListTitleMutation>;
export type ListEditor_UpdateListTitleMutationOptions = Apollo.BaseMutationOptions<ListEditor_UpdateListTitleMutation, ListEditor_UpdateListTitleMutationVariables>;
export const ListEditor_DeleteListDocument = gql`
    mutation ListEditor_DeleteList($id: String!) {
  deleteList(id: $id)
}
    `;
export type ListEditor_DeleteListMutationFn = Apollo.MutationFunction<ListEditor_DeleteListMutation, ListEditor_DeleteListMutationVariables>;

/**
 * __useListEditor_DeleteListMutation__
 *
 * To run a mutation, you first call `useListEditor_DeleteListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useListEditor_DeleteListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [listEditorDeleteListMutation, { data, loading, error }] = useListEditor_DeleteListMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useListEditor_DeleteListMutation(baseOptions?: Apollo.MutationHookOptions<ListEditor_DeleteListMutation, ListEditor_DeleteListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ListEditor_DeleteListMutation, ListEditor_DeleteListMutationVariables>(ListEditor_DeleteListDocument, options);
      }
export type ListEditor_DeleteListMutationHookResult = ReturnType<typeof useListEditor_DeleteListMutation>;
export type ListEditor_DeleteListMutationResult = Apollo.MutationResult<ListEditor_DeleteListMutation>;
export type ListEditor_DeleteListMutationOptions = Apollo.BaseMutationOptions<ListEditor_DeleteListMutation, ListEditor_DeleteListMutationVariables>;
export const ListEditor_IndentListDocument = gql`
    mutation ListEditor_IndentList($childId: String!, $parentId: String!) {
  indentList(childId: $childId, parentId: $parentId)
}
    `;
export type ListEditor_IndentListMutationFn = Apollo.MutationFunction<ListEditor_IndentListMutation, ListEditor_IndentListMutationVariables>;

/**
 * __useListEditor_IndentListMutation__
 *
 * To run a mutation, you first call `useListEditor_IndentListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useListEditor_IndentListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [listEditorIndentListMutation, { data, loading, error }] = useListEditor_IndentListMutation({
 *   variables: {
 *      childId: // value for 'childId'
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useListEditor_IndentListMutation(baseOptions?: Apollo.MutationHookOptions<ListEditor_IndentListMutation, ListEditor_IndentListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ListEditor_IndentListMutation, ListEditor_IndentListMutationVariables>(ListEditor_IndentListDocument, options);
      }
export type ListEditor_IndentListMutationHookResult = ReturnType<typeof useListEditor_IndentListMutation>;
export type ListEditor_IndentListMutationResult = Apollo.MutationResult<ListEditor_IndentListMutation>;
export type ListEditor_IndentListMutationOptions = Apollo.BaseMutationOptions<ListEditor_IndentListMutation, ListEditor_IndentListMutationVariables>;
export const ListEditor_UnindentListDocument = gql`
    mutation ListEditor_UnindentList($childId: String!, $parentId: String!) {
  unindentList(childId: $childId, parentId: $parentId)
}
    `;
export type ListEditor_UnindentListMutationFn = Apollo.MutationFunction<ListEditor_UnindentListMutation, ListEditor_UnindentListMutationVariables>;

/**
 * __useListEditor_UnindentListMutation__
 *
 * To run a mutation, you first call `useListEditor_UnindentListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useListEditor_UnindentListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [listEditorUnindentListMutation, { data, loading, error }] = useListEditor_UnindentListMutation({
 *   variables: {
 *      childId: // value for 'childId'
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useListEditor_UnindentListMutation(baseOptions?: Apollo.MutationHookOptions<ListEditor_UnindentListMutation, ListEditor_UnindentListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ListEditor_UnindentListMutation, ListEditor_UnindentListMutationVariables>(ListEditor_UnindentListDocument, options);
      }
export type ListEditor_UnindentListMutationHookResult = ReturnType<typeof useListEditor_UnindentListMutation>;
export type ListEditor_UnindentListMutationResult = Apollo.MutationResult<ListEditor_UnindentListMutation>;
export type ListEditor_UnindentListMutationOptions = Apollo.BaseMutationOptions<ListEditor_UnindentListMutation, ListEditor_UnindentListMutationVariables>;
export const ListsResult_AllDocument = gql`
    query ListsResult_All {
  allLists {
    ...ListEditor_List
    children {
      id
    }
  }
}
    ${ListEditor_ListFragmentDoc}`;

/**
 * __useListsResult_AllQuery__
 *
 * To run a query within a React component, call `useListsResult_AllQuery` and pass it any options that fit your needs.
 * When your component renders, `useListsResult_AllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListsResult_AllQuery({
 *   variables: {
 *   },
 * });
 */
export function useListsResult_AllQuery(baseOptions?: Apollo.QueryHookOptions<ListsResult_AllQuery, ListsResult_AllQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListsResult_AllQuery, ListsResult_AllQueryVariables>(ListsResult_AllDocument, options);
      }
export function useListsResult_AllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListsResult_AllQuery, ListsResult_AllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListsResult_AllQuery, ListsResult_AllQueryVariables>(ListsResult_AllDocument, options);
        }
export type ListsResult_AllQueryHookResult = ReturnType<typeof useListsResult_AllQuery>;
export type ListsResult_AllLazyQueryHookResult = ReturnType<typeof useListsResult_AllLazyQuery>;
export type ListsResult_AllQueryResult = Apollo.QueryResult<ListsResult_AllQuery, ListsResult_AllQueryVariables>;
export const Message_ToggleReactionDocument = gql`
    mutation Message_ToggleReaction($messageId: ID!, $value: String!) {
  toggleMessageReaction(messageId: $messageId, value: $value) {
    id
    reactions {
      id
      value
      reactors
      icon
      name
    }
  }
}
    `;
export type Message_ToggleReactionMutationFn = Apollo.MutationFunction<Message_ToggleReactionMutation, Message_ToggleReactionMutationVariables>;

/**
 * __useMessage_ToggleReactionMutation__
 *
 * To run a mutation, you first call `useMessage_ToggleReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMessage_ToggleReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [messageToggleReactionMutation, { data, loading, error }] = useMessage_ToggleReactionMutation({
 *   variables: {
 *      messageId: // value for 'messageId'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useMessage_ToggleReactionMutation(baseOptions?: Apollo.MutationHookOptions<Message_ToggleReactionMutation, Message_ToggleReactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Message_ToggleReactionMutation, Message_ToggleReactionMutationVariables>(Message_ToggleReactionDocument, options);
      }
export type Message_ToggleReactionMutationHookResult = ReturnType<typeof useMessage_ToggleReactionMutation>;
export type Message_ToggleReactionMutationResult = Apollo.MutationResult<Message_ToggleReactionMutation>;
export type Message_ToggleReactionMutationOptions = Apollo.BaseMutationOptions<Message_ToggleReactionMutation, Message_ToggleReactionMutationVariables>;
export const MessageOptions_DeleteMessageDocument = gql`
    mutation MessageOptions_DeleteMessage($id: String!) {
  deleteMessage(id: $id)
}
    `;
export type MessageOptions_DeleteMessageMutationFn = Apollo.MutationFunction<MessageOptions_DeleteMessageMutation, MessageOptions_DeleteMessageMutationVariables>;

/**
 * __useMessageOptions_DeleteMessageMutation__
 *
 * To run a mutation, you first call `useMessageOptions_DeleteMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMessageOptions_DeleteMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [messageOptionsDeleteMessageMutation, { data, loading, error }] = useMessageOptions_DeleteMessageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMessageOptions_DeleteMessageMutation(baseOptions?: Apollo.MutationHookOptions<MessageOptions_DeleteMessageMutation, MessageOptions_DeleteMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MessageOptions_DeleteMessageMutation, MessageOptions_DeleteMessageMutationVariables>(MessageOptions_DeleteMessageDocument, options);
      }
export type MessageOptions_DeleteMessageMutationHookResult = ReturnType<typeof useMessageOptions_DeleteMessageMutation>;
export type MessageOptions_DeleteMessageMutationResult = Apollo.MutationResult<MessageOptions_DeleteMessageMutation>;
export type MessageOptions_DeleteMessageMutationOptions = Apollo.BaseMutationOptions<MessageOptions_DeleteMessageMutation, MessageOptions_DeleteMessageMutationVariables>;
export const NewsResultDocument = gql`
    query NewsResult($messageId: ID!, $count: Int!) {
  message(id: $messageId) {
    news(count: $count) {
      name
      url
      description
      image {
        thumbnail {
          contentUrl
        }
      }
    }
  }
}
    `;

/**
 * __useNewsResultQuery__
 *
 * To run a query within a React component, call `useNewsResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsResultQuery({
 *   variables: {
 *      messageId: // value for 'messageId'
 *      count: // value for 'count'
 *   },
 * });
 */
export function useNewsResultQuery(baseOptions: Apollo.QueryHookOptions<NewsResultQuery, NewsResultQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewsResultQuery, NewsResultQueryVariables>(NewsResultDocument, options);
      }
export function useNewsResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewsResultQuery, NewsResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewsResultQuery, NewsResultQueryVariables>(NewsResultDocument, options);
        }
export type NewsResultQueryHookResult = ReturnType<typeof useNewsResultQuery>;
export type NewsResultLazyQueryHookResult = ReturnType<typeof useNewsResultLazyQuery>;
export type NewsResultQueryResult = Apollo.QueryResult<NewsResultQuery, NewsResultQueryVariables>;
export const PdfResult_SearchDocument = gql`
    query PDFResult_Search($id: ID!) {
  message(id: $id) {
    pdfs {
      id
      appSpaceId
      name
      url
      snippet
      language
      thumbnail
      local
      size
    }
  }
}
    `;

/**
 * __usePdfResult_SearchQuery__
 *
 * To run a query within a React component, call `usePdfResult_SearchQuery` and pass it any options that fit your needs.
 * When your component renders, `usePdfResult_SearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePdfResult_SearchQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePdfResult_SearchQuery(baseOptions: Apollo.QueryHookOptions<PdfResult_SearchQuery, PdfResult_SearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PdfResult_SearchQuery, PdfResult_SearchQueryVariables>(PdfResult_SearchDocument, options);
      }
export function usePdfResult_SearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PdfResult_SearchQuery, PdfResult_SearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PdfResult_SearchQuery, PdfResult_SearchQueryVariables>(PdfResult_SearchDocument, options);
        }
export type PdfResult_SearchQueryHookResult = ReturnType<typeof usePdfResult_SearchQuery>;
export type PdfResult_SearchLazyQueryHookResult = ReturnType<typeof usePdfResult_SearchLazyQuery>;
export type PdfResult_SearchQueryResult = Apollo.QueryResult<PdfResult_SearchQuery, PdfResult_SearchQueryVariables>;
export const PdfResult_GrabFileDocument = gql`
    mutation PDFResult_GrabFile($name: String!, $type: String!, $url: String!) {
  grabFileAsAppSpace(name: $name, type: $type, url: $url) {
    appSpaces {
      ...AppSpaceTabs_AppSpace
    }
  }
}
    ${AppSpaceTabs_AppSpaceFragmentDoc}`;
export type PdfResult_GrabFileMutationFn = Apollo.MutationFunction<PdfResult_GrabFileMutation, PdfResult_GrabFileMutationVariables>;

/**
 * __usePdfResult_GrabFileMutation__
 *
 * To run a mutation, you first call `usePdfResult_GrabFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePdfResult_GrabFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pdfResultGrabFileMutation, { data, loading, error }] = usePdfResult_GrabFileMutation({
 *   variables: {
 *      name: // value for 'name'
 *      type: // value for 'type'
 *      url: // value for 'url'
 *   },
 * });
 */
export function usePdfResult_GrabFileMutation(baseOptions?: Apollo.MutationHookOptions<PdfResult_GrabFileMutation, PdfResult_GrabFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PdfResult_GrabFileMutation, PdfResult_GrabFileMutationVariables>(PdfResult_GrabFileDocument, options);
      }
export type PdfResult_GrabFileMutationHookResult = ReturnType<typeof usePdfResult_GrabFileMutation>;
export type PdfResult_GrabFileMutationResult = Apollo.MutationResult<PdfResult_GrabFileMutation>;
export type PdfResult_GrabFileMutationOptions = Apollo.BaseMutationOptions<PdfResult_GrabFileMutation, PdfResult_GrabFileMutationVariables>;
export const SaveButton_CreateAppSpaceFromImageDocument = gql`
    mutation SaveButton_CreateAppSpaceFromImage($url: String!, $name: String) {
  createAppSpaceFromImage(url: $url, name: $name) {
    appSpaces {
      ...AppSpaceTabs_AppSpace
    }
    activeAppSpace {
      ...AppSpace_ActiveAppSpace
    }
  }
}
    ${AppSpaceTabs_AppSpaceFragmentDoc}
${AppSpace_ActiveAppSpaceFragmentDoc}`;
export type SaveButton_CreateAppSpaceFromImageMutationFn = Apollo.MutationFunction<SaveButton_CreateAppSpaceFromImageMutation, SaveButton_CreateAppSpaceFromImageMutationVariables>;

/**
 * __useSaveButton_CreateAppSpaceFromImageMutation__
 *
 * To run a mutation, you first call `useSaveButton_CreateAppSpaceFromImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveButton_CreateAppSpaceFromImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveButtonCreateAppSpaceFromImageMutation, { data, loading, error }] = useSaveButton_CreateAppSpaceFromImageMutation({
 *   variables: {
 *      url: // value for 'url'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useSaveButton_CreateAppSpaceFromImageMutation(baseOptions?: Apollo.MutationHookOptions<SaveButton_CreateAppSpaceFromImageMutation, SaveButton_CreateAppSpaceFromImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveButton_CreateAppSpaceFromImageMutation, SaveButton_CreateAppSpaceFromImageMutationVariables>(SaveButton_CreateAppSpaceFromImageDocument, options);
      }
export type SaveButton_CreateAppSpaceFromImageMutationHookResult = ReturnType<typeof useSaveButton_CreateAppSpaceFromImageMutation>;
export type SaveButton_CreateAppSpaceFromImageMutationResult = Apollo.MutationResult<SaveButton_CreateAppSpaceFromImageMutation>;
export type SaveButton_CreateAppSpaceFromImageMutationOptions = Apollo.BaseMutationOptions<SaveButton_CreateAppSpaceFromImageMutation, SaveButton_CreateAppSpaceFromImageMutationVariables>;
export const SaveButton_CreateAppSpaceFromUrlDocument = gql`
    mutation SaveButton_CreateAppSpaceFromUrl($url: String!, $name: String) {
  createAppSpaceFromUrl(url: $url, name: $name) {
    appSpaces {
      ...AppSpaceTabs_AppSpace
    }
    activeAppSpace {
      ...AppSpace_ActiveAppSpace
    }
  }
}
    ${AppSpaceTabs_AppSpaceFragmentDoc}
${AppSpace_ActiveAppSpaceFragmentDoc}`;
export type SaveButton_CreateAppSpaceFromUrlMutationFn = Apollo.MutationFunction<SaveButton_CreateAppSpaceFromUrlMutation, SaveButton_CreateAppSpaceFromUrlMutationVariables>;

/**
 * __useSaveButton_CreateAppSpaceFromUrlMutation__
 *
 * To run a mutation, you first call `useSaveButton_CreateAppSpaceFromUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveButton_CreateAppSpaceFromUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveButtonCreateAppSpaceFromUrlMutation, { data, loading, error }] = useSaveButton_CreateAppSpaceFromUrlMutation({
 *   variables: {
 *      url: // value for 'url'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useSaveButton_CreateAppSpaceFromUrlMutation(baseOptions?: Apollo.MutationHookOptions<SaveButton_CreateAppSpaceFromUrlMutation, SaveButton_CreateAppSpaceFromUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveButton_CreateAppSpaceFromUrlMutation, SaveButton_CreateAppSpaceFromUrlMutationVariables>(SaveButton_CreateAppSpaceFromUrlDocument, options);
      }
export type SaveButton_CreateAppSpaceFromUrlMutationHookResult = ReturnType<typeof useSaveButton_CreateAppSpaceFromUrlMutation>;
export type SaveButton_CreateAppSpaceFromUrlMutationResult = Apollo.MutationResult<SaveButton_CreateAppSpaceFromUrlMutation>;
export type SaveButton_CreateAppSpaceFromUrlMutationOptions = Apollo.BaseMutationOptions<SaveButton_CreateAppSpaceFromUrlMutation, SaveButton_CreateAppSpaceFromUrlMutationVariables>;
export const SaveButton_RenameAppSpaceDocument = gql`
    mutation SaveButton_RenameAppSpace($id: ID!, $newName: String!) {
  renameAppSpace(id: $id, newName: $newName) {
    id
    name
  }
}
    `;
export type SaveButton_RenameAppSpaceMutationFn = Apollo.MutationFunction<SaveButton_RenameAppSpaceMutation, SaveButton_RenameAppSpaceMutationVariables>;

/**
 * __useSaveButton_RenameAppSpaceMutation__
 *
 * To run a mutation, you first call `useSaveButton_RenameAppSpaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveButton_RenameAppSpaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveButtonRenameAppSpaceMutation, { data, loading, error }] = useSaveButton_RenameAppSpaceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      newName: // value for 'newName'
 *   },
 * });
 */
export function useSaveButton_RenameAppSpaceMutation(baseOptions?: Apollo.MutationHookOptions<SaveButton_RenameAppSpaceMutation, SaveButton_RenameAppSpaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveButton_RenameAppSpaceMutation, SaveButton_RenameAppSpaceMutationVariables>(SaveButton_RenameAppSpaceDocument, options);
      }
export type SaveButton_RenameAppSpaceMutationHookResult = ReturnType<typeof useSaveButton_RenameAppSpaceMutation>;
export type SaveButton_RenameAppSpaceMutationResult = Apollo.MutationResult<SaveButton_RenameAppSpaceMutation>;
export type SaveButton_RenameAppSpaceMutationOptions = Apollo.BaseMutationOptions<SaveButton_RenameAppSpaceMutation, SaveButton_RenameAppSpaceMutationVariables>;
export const SaveButton_CreateAppSpaceFromContentDocument = gql`
    mutation SaveButton_CreateAppSpaceFromContent($from: String!, $name: String) {
  createAppSpaceFromContent(from: $from, name: $name) {
    appSpaces {
      ...AppSpaceTabs_AppSpace
    }
    activeAppSpace {
      ...AppSpace_ActiveAppSpace
    }
  }
}
    ${AppSpaceTabs_AppSpaceFragmentDoc}
${AppSpace_ActiveAppSpaceFragmentDoc}`;
export type SaveButton_CreateAppSpaceFromContentMutationFn = Apollo.MutationFunction<SaveButton_CreateAppSpaceFromContentMutation, SaveButton_CreateAppSpaceFromContentMutationVariables>;

/**
 * __useSaveButton_CreateAppSpaceFromContentMutation__
 *
 * To run a mutation, you first call `useSaveButton_CreateAppSpaceFromContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveButton_CreateAppSpaceFromContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveButtonCreateAppSpaceFromContentMutation, { data, loading, error }] = useSaveButton_CreateAppSpaceFromContentMutation({
 *   variables: {
 *      from: // value for 'from'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useSaveButton_CreateAppSpaceFromContentMutation(baseOptions?: Apollo.MutationHookOptions<SaveButton_CreateAppSpaceFromContentMutation, SaveButton_CreateAppSpaceFromContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveButton_CreateAppSpaceFromContentMutation, SaveButton_CreateAppSpaceFromContentMutationVariables>(SaveButton_CreateAppSpaceFromContentDocument, options);
      }
export type SaveButton_CreateAppSpaceFromContentMutationHookResult = ReturnType<typeof useSaveButton_CreateAppSpaceFromContentMutation>;
export type SaveButton_CreateAppSpaceFromContentMutationResult = Apollo.MutationResult<SaveButton_CreateAppSpaceFromContentMutation>;
export type SaveButton_CreateAppSpaceFromContentMutationOptions = Apollo.BaseMutationOptions<SaveButton_CreateAppSpaceFromContentMutation, SaveButton_CreateAppSpaceFromContentMutationVariables>;
export const SearchFileListingDocument = gql`
    query SearchFileListing {
  activeAppSpace {
    id
  }
}
    `;

/**
 * __useSearchFileListingQuery__
 *
 * To run a query within a React component, call `useSearchFileListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchFileListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchFileListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useSearchFileListingQuery(baseOptions?: Apollo.QueryHookOptions<SearchFileListingQuery, SearchFileListingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchFileListingQuery, SearchFileListingQueryVariables>(SearchFileListingDocument, options);
      }
export function useSearchFileListingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchFileListingQuery, SearchFileListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchFileListingQuery, SearchFileListingQueryVariables>(SearchFileListingDocument, options);
        }
export type SearchFileListingQueryHookResult = ReturnType<typeof useSearchFileListingQuery>;
export type SearchFileListingLazyQueryHookResult = ReturnType<typeof useSearchFileListingLazyQuery>;
export type SearchFileListingQueryResult = Apollo.QueryResult<SearchFileListingQuery, SearchFileListingQueryVariables>;
export const SearchResultDocument = gql`
    query SearchResult($messageId: ID!, $count: Int!, $offset: Int!, $type: String!, $query: String) {
  message(id: $messageId) {
    searchResult(count: $count, offset: $offset, type: $type, query: $query) {
      ...SearchResult_SearchResult
    }
  }
  activeAppSpace {
    id
  }
}
    ${SearchResult_SearchResultFragmentDoc}`;

/**
 * __useSearchResultQuery__
 *
 * To run a query within a React component, call `useSearchResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchResultQuery({
 *   variables: {
 *      messageId: // value for 'messageId'
 *      count: // value for 'count'
 *      offset: // value for 'offset'
 *      type: // value for 'type'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchResultQuery(baseOptions: Apollo.QueryHookOptions<SearchResultQuery, SearchResultQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchResultQuery, SearchResultQueryVariables>(SearchResultDocument, options);
      }
export function useSearchResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchResultQuery, SearchResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchResultQuery, SearchResultQueryVariables>(SearchResultDocument, options);
        }
export type SearchResultQueryHookResult = ReturnType<typeof useSearchResultQuery>;
export type SearchResultLazyQueryHookResult = ReturnType<typeof useSearchResultLazyQuery>;
export type SearchResultQueryResult = Apollo.QueryResult<SearchResultQuery, SearchResultQueryVariables>;
export const WikipediaResult_WikiDocument = gql`
    query WikipediaResult_Wiki($messageId: ID!, $query: String!) {
  message(id: $messageId) {
    wiki(query: $query) {
      sections {
        title
        content {
          paragraphs {
            sentences
          }
        }
      }
      data {
        title
        tables
      }
      title
      url
      image {
        thumbnail
        url
        alt
      }
      pageId
      category
    }
  }
  activeAppSpace {
    id
  }
}
    `;

/**
 * __useWikipediaResult_WikiQuery__
 *
 * To run a query within a React component, call `useWikipediaResult_WikiQuery` and pass it any options that fit your needs.
 * When your component renders, `useWikipediaResult_WikiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWikipediaResult_WikiQuery({
 *   variables: {
 *      messageId: // value for 'messageId'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useWikipediaResult_WikiQuery(baseOptions: Apollo.QueryHookOptions<WikipediaResult_WikiQuery, WikipediaResult_WikiQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WikipediaResult_WikiQuery, WikipediaResult_WikiQueryVariables>(WikipediaResult_WikiDocument, options);
      }
export function useWikipediaResult_WikiLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WikipediaResult_WikiQuery, WikipediaResult_WikiQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WikipediaResult_WikiQuery, WikipediaResult_WikiQueryVariables>(WikipediaResult_WikiDocument, options);
        }
export type WikipediaResult_WikiQueryHookResult = ReturnType<typeof useWikipediaResult_WikiQuery>;
export type WikipediaResult_WikiLazyQueryHookResult = ReturnType<typeof useWikipediaResult_WikiLazyQuery>;
export type WikipediaResult_WikiQueryResult = Apollo.QueryResult<WikipediaResult_WikiQuery, WikipediaResult_WikiQueryVariables>;
export const ImagesDocument = gql`
    query Images {
  activeAppSpace {
    id
  }
}
    `;

/**
 * __useImagesQuery__
 *
 * To run a query within a React component, call `useImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useImagesQuery(baseOptions?: Apollo.QueryHookOptions<ImagesQuery, ImagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ImagesQuery, ImagesQueryVariables>(ImagesDocument, options);
      }
export function useImagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ImagesQuery, ImagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ImagesQuery, ImagesQueryVariables>(ImagesDocument, options);
        }
export type ImagesQueryHookResult = ReturnType<typeof useImagesQuery>;
export type ImagesLazyQueryHookResult = ReturnType<typeof useImagesLazyQuery>;
export type ImagesQueryResult = Apollo.QueryResult<ImagesQuery, ImagesQueryVariables>;
export const VideosDocument = gql`
    query Videos {
  activeAppSpace {
    id
  }
}
    `;

/**
 * __useVideosQuery__
 *
 * To run a query within a React component, call `useVideosQuery` and pass it any options that fit your needs.
 * When your component renders, `useVideosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVideosQuery({
 *   variables: {
 *   },
 * });
 */
export function useVideosQuery(baseOptions?: Apollo.QueryHookOptions<VideosQuery, VideosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VideosQuery, VideosQueryVariables>(VideosDocument, options);
      }
export function useVideosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VideosQuery, VideosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VideosQuery, VideosQueryVariables>(VideosDocument, options);
        }
export type VideosQueryHookResult = ReturnType<typeof useVideosQuery>;
export type VideosLazyQueryHookResult = ReturnType<typeof useVideosLazyQuery>;
export type VideosQueryResult = Apollo.QueryResult<VideosQuery, VideosQueryVariables>;
export const UserChatStatusDocument = gql`
    query UserChatStatus {
  activeAppSpace {
    id
  }
  activeSession {
    id
  }
}
    `;

/**
 * __useUserChatStatusQuery__
 *
 * To run a query within a React component, call `useUserChatStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserChatStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserChatStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserChatStatusQuery(baseOptions?: Apollo.QueryHookOptions<UserChatStatusQuery, UserChatStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserChatStatusQuery, UserChatStatusQueryVariables>(UserChatStatusDocument, options);
      }
export function useUserChatStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserChatStatusQuery, UserChatStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserChatStatusQuery, UserChatStatusQueryVariables>(UserChatStatusDocument, options);
        }
export type UserChatStatusQueryHookResult = ReturnType<typeof useUserChatStatusQuery>;
export type UserChatStatusLazyQueryHookResult = ReturnType<typeof useUserChatStatusLazyQuery>;
export type UserChatStatusQueryResult = Apollo.QueryResult<UserChatStatusQuery, UserChatStatusQueryVariables>;
export const UserChatStatus_OnUserChatStatusChangedDocument = gql`
    subscription UserChatStatus_OnUserChatStatusChanged($session: ID!) {
  userChatStatusChanged(session: $session) {
    ...UserChatStatus_UserStatus
  }
}
    ${UserChatStatus_UserStatusFragmentDoc}`;

/**
 * __useUserChatStatus_OnUserChatStatusChangedSubscription__
 *
 * To run a query within a React component, call `useUserChatStatus_OnUserChatStatusChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUserChatStatus_OnUserChatStatusChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserChatStatus_OnUserChatStatusChangedSubscription({
 *   variables: {
 *      session: // value for 'session'
 *   },
 * });
 */
export function useUserChatStatus_OnUserChatStatusChangedSubscription(baseOptions: Apollo.SubscriptionHookOptions<UserChatStatus_OnUserChatStatusChangedSubscription, UserChatStatus_OnUserChatStatusChangedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<UserChatStatus_OnUserChatStatusChangedSubscription, UserChatStatus_OnUserChatStatusChangedSubscriptionVariables>(UserChatStatus_OnUserChatStatusChangedDocument, options);
      }
export type UserChatStatus_OnUserChatStatusChangedSubscriptionHookResult = ReturnType<typeof useUserChatStatus_OnUserChatStatusChangedSubscription>;
export type UserChatStatus_OnUserChatStatusChangedSubscriptionResult = Apollo.SubscriptionResult<UserChatStatus_OnUserChatStatusChangedSubscription>;
export const VideoResultDocument = gql`
    query VideoResult($messageId: ID!, $count: Int!) {
  message(id: $messageId) {
    videos(count: $count) {
      id
      thumbnailUrl
      description
      text
      viewCount
      contentUrl
      embedHtml
    }
  }
}
    `;

/**
 * __useVideoResultQuery__
 *
 * To run a query within a React component, call `useVideoResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useVideoResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVideoResultQuery({
 *   variables: {
 *      messageId: // value for 'messageId'
 *      count: // value for 'count'
 *   },
 * });
 */
export function useVideoResultQuery(baseOptions: Apollo.QueryHookOptions<VideoResultQuery, VideoResultQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VideoResultQuery, VideoResultQueryVariables>(VideoResultDocument, options);
      }
export function useVideoResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VideoResultQuery, VideoResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VideoResultQuery, VideoResultQueryVariables>(VideoResultDocument, options);
        }
export type VideoResultQueryHookResult = ReturnType<typeof useVideoResultQuery>;
export type VideoResultLazyQueryHookResult = ReturnType<typeof useVideoResultLazyQuery>;
export type VideoResultQueryResult = Apollo.QueryResult<VideoResultQuery, VideoResultQueryVariables>;
export const MessageCardDocument = gql`
    query MessageCard {
  unreadMessages {
    count
    message {
      id
      type
      textContent
      user {
        profilePicture
        fullName
      }
      createdDate
    }
    group {
      id
      name
      type
      otherMember {
        ...MessageCardUser
      }
      members {
        ...MessageCardUser
      }
    }
  }
}
    ${MessageCardUserFragmentDoc}`;

/**
 * __useMessageCardQuery__
 *
 * To run a query within a React component, call `useMessageCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageCardQuery({
 *   variables: {
 *   },
 * });
 */
export function useMessageCardQuery(baseOptions?: Apollo.QueryHookOptions<MessageCardQuery, MessageCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessageCardQuery, MessageCardQueryVariables>(MessageCardDocument, options);
      }
export function useMessageCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessageCardQuery, MessageCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessageCardQuery, MessageCardQueryVariables>(MessageCardDocument, options);
        }
export type MessageCardQueryHookResult = ReturnType<typeof useMessageCardQuery>;
export type MessageCardLazyQueryHookResult = ReturnType<typeof useMessageCardLazyQuery>;
export type MessageCardQueryResult = Apollo.QueryResult<MessageCardQuery, MessageCardQueryVariables>;
export const TasksCardDocument = gql`
    query TasksCard {
  recentTasksToDo {
    task {
      id
      title
      description
      idNumber
      assignees {
        id
        profilePicture
        fullName
      }
      state {
        id
        name
        color
      }
      createdDate
    }
    board {
      id
      idStr
    }
  }
}
    `;

/**
 * __useTasksCardQuery__
 *
 * To run a query within a React component, call `useTasksCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useTasksCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTasksCardQuery({
 *   variables: {
 *   },
 * });
 */
export function useTasksCardQuery(baseOptions?: Apollo.QueryHookOptions<TasksCardQuery, TasksCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TasksCardQuery, TasksCardQueryVariables>(TasksCardDocument, options);
      }
export function useTasksCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TasksCardQuery, TasksCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TasksCardQuery, TasksCardQueryVariables>(TasksCardDocument, options);
        }
export type TasksCardQueryHookResult = ReturnType<typeof useTasksCardQuery>;
export type TasksCardLazyQueryHookResult = ReturnType<typeof useTasksCardLazyQuery>;
export type TasksCardQueryResult = Apollo.QueryResult<TasksCardQuery, TasksCardQueryVariables>;
export const TasksCard_MarkTaskCompleteDocument = gql`
    mutation TasksCard_MarkTaskComplete($taskId: ID!) {
  markTaskComplete(taskId: $taskId)
}
    `;
export type TasksCard_MarkTaskCompleteMutationFn = Apollo.MutationFunction<TasksCard_MarkTaskCompleteMutation, TasksCard_MarkTaskCompleteMutationVariables>;

/**
 * __useTasksCard_MarkTaskCompleteMutation__
 *
 * To run a mutation, you first call `useTasksCard_MarkTaskCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTasksCard_MarkTaskCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tasksCardMarkTaskCompleteMutation, { data, loading, error }] = useTasksCard_MarkTaskCompleteMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTasksCard_MarkTaskCompleteMutation(baseOptions?: Apollo.MutationHookOptions<TasksCard_MarkTaskCompleteMutation, TasksCard_MarkTaskCompleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TasksCard_MarkTaskCompleteMutation, TasksCard_MarkTaskCompleteMutationVariables>(TasksCard_MarkTaskCompleteDocument, options);
      }
export type TasksCard_MarkTaskCompleteMutationHookResult = ReturnType<typeof useTasksCard_MarkTaskCompleteMutation>;
export type TasksCard_MarkTaskCompleteMutationResult = Apollo.MutationResult<TasksCard_MarkTaskCompleteMutation>;
export type TasksCard_MarkTaskCompleteMutationOptions = Apollo.BaseMutationOptions<TasksCard_MarkTaskCompleteMutation, TasksCard_MarkTaskCompleteMutationVariables>;
export const WikisCardDocument = gql`
    query WikisCard {
  recentWikiPages {
    wikiPage {
      id
      name
      content
      createdDate
    }
    wiki {
      id
      idStr
      name
      users {
        id
        fullName
        profilePicture
      }
    }
  }
}
    `;

/**
 * __useWikisCardQuery__
 *
 * To run a query within a React component, call `useWikisCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useWikisCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWikisCardQuery({
 *   variables: {
 *   },
 * });
 */
export function useWikisCardQuery(baseOptions?: Apollo.QueryHookOptions<WikisCardQuery, WikisCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WikisCardQuery, WikisCardQueryVariables>(WikisCardDocument, options);
      }
export function useWikisCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WikisCardQuery, WikisCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WikisCardQuery, WikisCardQueryVariables>(WikisCardDocument, options);
        }
export type WikisCardQueryHookResult = ReturnType<typeof useWikisCardQuery>;
export type WikisCardLazyQueryHookResult = ReturnType<typeof useWikisCardLazyQuery>;
export type WikisCardQueryResult = Apollo.QueryResult<WikisCardQuery, WikisCardQueryVariables>;
export const DashboardHeaderDocument = gql`
    query DashboardHeader {
  me {
    id
    fullName
  }
}
    `;

/**
 * __useDashboardHeaderQuery__
 *
 * To run a query within a React component, call `useDashboardHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardHeaderQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardHeaderQuery(baseOptions?: Apollo.QueryHookOptions<DashboardHeaderQuery, DashboardHeaderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardHeaderQuery, DashboardHeaderQueryVariables>(DashboardHeaderDocument, options);
      }
export function useDashboardHeaderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardHeaderQuery, DashboardHeaderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardHeaderQuery, DashboardHeaderQueryVariables>(DashboardHeaderDocument, options);
        }
export type DashboardHeaderQueryHookResult = ReturnType<typeof useDashboardHeaderQuery>;
export type DashboardHeaderLazyQueryHookResult = ReturnType<typeof useDashboardHeaderLazyQuery>;
export type DashboardHeaderQueryResult = Apollo.QueryResult<DashboardHeaderQuery, DashboardHeaderQueryVariables>;
export const OrganizationSwitcherDocument = gql`
    query OrganizationSwitcher {
  organization {
    name
    id
    profilePicture
    spaces {
      id
      name
    }
  }
  organizations {
    id
    name
    memberCount
    profilePicture
  }
}
    `;

/**
 * __useOrganizationSwitcherQuery__
 *
 * To run a query within a React component, call `useOrganizationSwitcherQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationSwitcherQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationSwitcherQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationSwitcherQuery(baseOptions?: Apollo.QueryHookOptions<OrganizationSwitcherQuery, OrganizationSwitcherQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationSwitcherQuery, OrganizationSwitcherQueryVariables>(OrganizationSwitcherDocument, options);
      }
export function useOrganizationSwitcherLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationSwitcherQuery, OrganizationSwitcherQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationSwitcherQuery, OrganizationSwitcherQueryVariables>(OrganizationSwitcherDocument, options);
        }
export type OrganizationSwitcherQueryHookResult = ReturnType<typeof useOrganizationSwitcherQuery>;
export type OrganizationSwitcherLazyQueryHookResult = ReturnType<typeof useOrganizationSwitcherLazyQuery>;
export type OrganizationSwitcherQueryResult = Apollo.QueryResult<OrganizationSwitcherQuery, OrganizationSwitcherQueryVariables>;
export const HomeDocument = gql`
    query Home {
  activeSession {
    id
    groupId
  }
}
    `;

/**
 * __useHomeQuery__
 *
 * To run a query within a React component, call `useHomeQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomeQuery(baseOptions?: Apollo.QueryHookOptions<HomeQuery, HomeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomeQuery, HomeQueryVariables>(HomeDocument, options);
      }
export function useHomeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeQuery, HomeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomeQuery, HomeQueryVariables>(HomeDocument, options);
        }
export type HomeQueryHookResult = ReturnType<typeof useHomeQuery>;
export type HomeLazyQueryHookResult = ReturnType<typeof useHomeLazyQuery>;
export type HomeQueryResult = Apollo.QueryResult<HomeQuery, HomeQueryVariables>;
export const JournalDocument = gql`
    query Journal($date: String!) {
  journal(date: $date) {
    file {
      id
    }
  }
}
    `;

/**
 * __useJournalQuery__
 *
 * To run a query within a React component, call `useJournalQuery` and pass it any options that fit your needs.
 * When your component renders, `useJournalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJournalQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useJournalQuery(baseOptions: Apollo.QueryHookOptions<JournalQuery, JournalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JournalQuery, JournalQueryVariables>(JournalDocument, options);
      }
export function useJournalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JournalQuery, JournalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JournalQuery, JournalQueryVariables>(JournalDocument, options);
        }
export type JournalQueryHookResult = ReturnType<typeof useJournalQuery>;
export type JournalLazyQueryHookResult = ReturnType<typeof useJournalLazyQuery>;
export type JournalQueryResult = Apollo.QueryResult<JournalQuery, JournalQueryVariables>;
export const JournalEntrySelector_JournalCountDocument = gql`
    query JournalEntrySelector_JournalCount($days: [String!]!) {
  journalCountForDays(days: $days) {
    day
    count
  }
}
    `;

/**
 * __useJournalEntrySelector_JournalCountQuery__
 *
 * To run a query within a React component, call `useJournalEntrySelector_JournalCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useJournalEntrySelector_JournalCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJournalEntrySelector_JournalCountQuery({
 *   variables: {
 *      days: // value for 'days'
 *   },
 * });
 */
export function useJournalEntrySelector_JournalCountQuery(baseOptions: Apollo.QueryHookOptions<JournalEntrySelector_JournalCountQuery, JournalEntrySelector_JournalCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JournalEntrySelector_JournalCountQuery, JournalEntrySelector_JournalCountQueryVariables>(JournalEntrySelector_JournalCountDocument, options);
      }
export function useJournalEntrySelector_JournalCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JournalEntrySelector_JournalCountQuery, JournalEntrySelector_JournalCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JournalEntrySelector_JournalCountQuery, JournalEntrySelector_JournalCountQueryVariables>(JournalEntrySelector_JournalCountDocument, options);
        }
export type JournalEntrySelector_JournalCountQueryHookResult = ReturnType<typeof useJournalEntrySelector_JournalCountQuery>;
export type JournalEntrySelector_JournalCountLazyQueryHookResult = ReturnType<typeof useJournalEntrySelector_JournalCountLazyQuery>;
export type JournalEntrySelector_JournalCountQueryResult = Apollo.QueryResult<JournalEntrySelector_JournalCountQuery, JournalEntrySelector_JournalCountQueryVariables>;
export const JournalEntriesDocument = gql`
    query JournalEntries($date: String!) {
  journalEntries(date: $date) {
    ...JournalEntry
  }
}
    ${JournalEntryFragmentDoc}`;

/**
 * __useJournalEntriesQuery__
 *
 * To run a query within a React component, call `useJournalEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useJournalEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJournalEntriesQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useJournalEntriesQuery(baseOptions: Apollo.QueryHookOptions<JournalEntriesQuery, JournalEntriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JournalEntriesQuery, JournalEntriesQueryVariables>(JournalEntriesDocument, options);
      }
export function useJournalEntriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JournalEntriesQuery, JournalEntriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JournalEntriesQuery, JournalEntriesQueryVariables>(JournalEntriesDocument, options);
        }
export type JournalEntriesQueryHookResult = ReturnType<typeof useJournalEntriesQuery>;
export type JournalEntriesLazyQueryHookResult = ReturnType<typeof useJournalEntriesLazyQuery>;
export type JournalEntriesQueryResult = Apollo.QueryResult<JournalEntriesQuery, JournalEntriesQueryVariables>;
export const JournalEntries_CreateJournalEntryDocument = gql`
    mutation JournalEntries_CreateJournalEntry($title: String!, $date: String!) {
  createJournalEntry(title: $title, date: $date)
}
    `;
export type JournalEntries_CreateJournalEntryMutationFn = Apollo.MutationFunction<JournalEntries_CreateJournalEntryMutation, JournalEntries_CreateJournalEntryMutationVariables>;

/**
 * __useJournalEntries_CreateJournalEntryMutation__
 *
 * To run a mutation, you first call `useJournalEntries_CreateJournalEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJournalEntries_CreateJournalEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [journalEntriesCreateJournalEntryMutation, { data, loading, error }] = useJournalEntries_CreateJournalEntryMutation({
 *   variables: {
 *      title: // value for 'title'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useJournalEntries_CreateJournalEntryMutation(baseOptions?: Apollo.MutationHookOptions<JournalEntries_CreateJournalEntryMutation, JournalEntries_CreateJournalEntryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JournalEntries_CreateJournalEntryMutation, JournalEntries_CreateJournalEntryMutationVariables>(JournalEntries_CreateJournalEntryDocument, options);
      }
export type JournalEntries_CreateJournalEntryMutationHookResult = ReturnType<typeof useJournalEntries_CreateJournalEntryMutation>;
export type JournalEntries_CreateJournalEntryMutationResult = Apollo.MutationResult<JournalEntries_CreateJournalEntryMutation>;
export type JournalEntries_CreateJournalEntryMutationOptions = Apollo.BaseMutationOptions<JournalEntries_CreateJournalEntryMutation, JournalEntries_CreateJournalEntryMutationVariables>;
export const JournalEntries_RenameEntryDocument = gql`
    mutation JournalEntries_RenameEntry($id: ID!, $title: String!) {
  renameJournalEntry(id: $id, title: $title) {
    id
    title
  }
}
    `;
export type JournalEntries_RenameEntryMutationFn = Apollo.MutationFunction<JournalEntries_RenameEntryMutation, JournalEntries_RenameEntryMutationVariables>;

/**
 * __useJournalEntries_RenameEntryMutation__
 *
 * To run a mutation, you first call `useJournalEntries_RenameEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJournalEntries_RenameEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [journalEntriesRenameEntryMutation, { data, loading, error }] = useJournalEntries_RenameEntryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useJournalEntries_RenameEntryMutation(baseOptions?: Apollo.MutationHookOptions<JournalEntries_RenameEntryMutation, JournalEntries_RenameEntryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JournalEntries_RenameEntryMutation, JournalEntries_RenameEntryMutationVariables>(JournalEntries_RenameEntryDocument, options);
      }
export type JournalEntries_RenameEntryMutationHookResult = ReturnType<typeof useJournalEntries_RenameEntryMutation>;
export type JournalEntries_RenameEntryMutationResult = Apollo.MutationResult<JournalEntries_RenameEntryMutation>;
export type JournalEntries_RenameEntryMutationOptions = Apollo.BaseMutationOptions<JournalEntries_RenameEntryMutation, JournalEntries_RenameEntryMutationVariables>;
export const JournalEntries_DeleteEntryDocument = gql`
    mutation JournalEntries_DeleteEntry($id: ID!) {
  deleteJournalEntry(id: $id)
}
    `;
export type JournalEntries_DeleteEntryMutationFn = Apollo.MutationFunction<JournalEntries_DeleteEntryMutation, JournalEntries_DeleteEntryMutationVariables>;

/**
 * __useJournalEntries_DeleteEntryMutation__
 *
 * To run a mutation, you first call `useJournalEntries_DeleteEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJournalEntries_DeleteEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [journalEntriesDeleteEntryMutation, { data, loading, error }] = useJournalEntries_DeleteEntryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJournalEntries_DeleteEntryMutation(baseOptions?: Apollo.MutationHookOptions<JournalEntries_DeleteEntryMutation, JournalEntries_DeleteEntryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JournalEntries_DeleteEntryMutation, JournalEntries_DeleteEntryMutationVariables>(JournalEntries_DeleteEntryDocument, options);
      }
export type JournalEntries_DeleteEntryMutationHookResult = ReturnType<typeof useJournalEntries_DeleteEntryMutation>;
export type JournalEntries_DeleteEntryMutationResult = Apollo.MutationResult<JournalEntries_DeleteEntryMutation>;
export type JournalEntries_DeleteEntryMutationOptions = Apollo.BaseMutationOptions<JournalEntries_DeleteEntryMutation, JournalEntries_DeleteEntryMutationVariables>;
export const NoteItemLabelsDocument = gql`
    query NoteItemLabels {
  noteLabels {
    ...Notes_NoteLabel
  }
}
    ${Notes_NoteLabelFragmentDoc}`;

/**
 * __useNoteItemLabelsQuery__
 *
 * To run a query within a React component, call `useNoteItemLabelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteItemLabelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteItemLabelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useNoteItemLabelsQuery(baseOptions?: Apollo.QueryHookOptions<NoteItemLabelsQuery, NoteItemLabelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NoteItemLabelsQuery, NoteItemLabelsQueryVariables>(NoteItemLabelsDocument, options);
      }
export function useNoteItemLabelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NoteItemLabelsQuery, NoteItemLabelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NoteItemLabelsQuery, NoteItemLabelsQueryVariables>(NoteItemLabelsDocument, options);
        }
export type NoteItemLabelsQueryHookResult = ReturnType<typeof useNoteItemLabelsQuery>;
export type NoteItemLabelsLazyQueryHookResult = ReturnType<typeof useNoteItemLabelsLazyQuery>;
export type NoteItemLabelsQueryResult = Apollo.QueryResult<NoteItemLabelsQuery, NoteItemLabelsQueryVariables>;
export const NoteItemLabels_ToggleLabelDocument = gql`
    mutation NoteItemLabels_ToggleLabel($noteId: ID!, $labelId: ID!) {
  toggleNoteLabel(noteId: $noteId, labelId: $labelId) {
    id
    labels {
      ...Notes_NoteLabel
    }
  }
}
    ${Notes_NoteLabelFragmentDoc}`;
export type NoteItemLabels_ToggleLabelMutationFn = Apollo.MutationFunction<NoteItemLabels_ToggleLabelMutation, NoteItemLabels_ToggleLabelMutationVariables>;

/**
 * __useNoteItemLabels_ToggleLabelMutation__
 *
 * To run a mutation, you first call `useNoteItemLabels_ToggleLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNoteItemLabels_ToggleLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [noteItemLabelsToggleLabelMutation, { data, loading, error }] = useNoteItemLabels_ToggleLabelMutation({
 *   variables: {
 *      noteId: // value for 'noteId'
 *      labelId: // value for 'labelId'
 *   },
 * });
 */
export function useNoteItemLabels_ToggleLabelMutation(baseOptions?: Apollo.MutationHookOptions<NoteItemLabels_ToggleLabelMutation, NoteItemLabels_ToggleLabelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NoteItemLabels_ToggleLabelMutation, NoteItemLabels_ToggleLabelMutationVariables>(NoteItemLabels_ToggleLabelDocument, options);
      }
export type NoteItemLabels_ToggleLabelMutationHookResult = ReturnType<typeof useNoteItemLabels_ToggleLabelMutation>;
export type NoteItemLabels_ToggleLabelMutationResult = Apollo.MutationResult<NoteItemLabels_ToggleLabelMutation>;
export type NoteItemLabels_ToggleLabelMutationOptions = Apollo.BaseMutationOptions<NoteItemLabels_ToggleLabelMutation, NoteItemLabels_ToggleLabelMutationVariables>;
export const NoteModal_RenameNoteDocument = gql`
    mutation NoteModal_RenameNote($id: ID!, $title: String!) {
  renameNote(id: $id, title: $title) {
    id
    title
  }
}
    `;
export type NoteModal_RenameNoteMutationFn = Apollo.MutationFunction<NoteModal_RenameNoteMutation, NoteModal_RenameNoteMutationVariables>;

/**
 * __useNoteModal_RenameNoteMutation__
 *
 * To run a mutation, you first call `useNoteModal_RenameNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNoteModal_RenameNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [noteModalRenameNoteMutation, { data, loading, error }] = useNoteModal_RenameNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useNoteModal_RenameNoteMutation(baseOptions?: Apollo.MutationHookOptions<NoteModal_RenameNoteMutation, NoteModal_RenameNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NoteModal_RenameNoteMutation, NoteModal_RenameNoteMutationVariables>(NoteModal_RenameNoteDocument, options);
      }
export type NoteModal_RenameNoteMutationHookResult = ReturnType<typeof useNoteModal_RenameNoteMutation>;
export type NoteModal_RenameNoteMutationResult = Apollo.MutationResult<NoteModal_RenameNoteMutation>;
export type NoteModal_RenameNoteMutationOptions = Apollo.BaseMutationOptions<NoteModal_RenameNoteMutation, NoteModal_RenameNoteMutationVariables>;
export const Notes_NoteLabelsDocument = gql`
    query Notes_NoteLabels {
  noteLabels {
    ...Notes_NoteLabel
  }
}
    ${Notes_NoteLabelFragmentDoc}`;

/**
 * __useNotes_NoteLabelsQuery__
 *
 * To run a query within a React component, call `useNotes_NoteLabelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotes_NoteLabelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotes_NoteLabelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotes_NoteLabelsQuery(baseOptions?: Apollo.QueryHookOptions<Notes_NoteLabelsQuery, Notes_NoteLabelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Notes_NoteLabelsQuery, Notes_NoteLabelsQueryVariables>(Notes_NoteLabelsDocument, options);
      }
export function useNotes_NoteLabelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Notes_NoteLabelsQuery, Notes_NoteLabelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Notes_NoteLabelsQuery, Notes_NoteLabelsQueryVariables>(Notes_NoteLabelsDocument, options);
        }
export type Notes_NoteLabelsQueryHookResult = ReturnType<typeof useNotes_NoteLabelsQuery>;
export type Notes_NoteLabelsLazyQueryHookResult = ReturnType<typeof useNotes_NoteLabelsLazyQuery>;
export type Notes_NoteLabelsQueryResult = Apollo.QueryResult<Notes_NoteLabelsQuery, Notes_NoteLabelsQueryVariables>;
export const Notes_NotesDocument = gql`
    query Notes_Notes($offset: Int!, $take: Int!, $label: ID) {
  notes(offset: $offset, take: $take, label: $label) {
    ...Notes_Note
  }
}
    ${Notes_NoteFragmentDoc}`;

/**
 * __useNotes_NotesQuery__
 *
 * To run a query within a React component, call `useNotes_NotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotes_NotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotes_NotesQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      take: // value for 'take'
 *      label: // value for 'label'
 *   },
 * });
 */
export function useNotes_NotesQuery(baseOptions: Apollo.QueryHookOptions<Notes_NotesQuery, Notes_NotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Notes_NotesQuery, Notes_NotesQueryVariables>(Notes_NotesDocument, options);
      }
export function useNotes_NotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Notes_NotesQuery, Notes_NotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Notes_NotesQuery, Notes_NotesQueryVariables>(Notes_NotesDocument, options);
        }
export type Notes_NotesQueryHookResult = ReturnType<typeof useNotes_NotesQuery>;
export type Notes_NotesLazyQueryHookResult = ReturnType<typeof useNotes_NotesLazyQuery>;
export type Notes_NotesQueryResult = Apollo.QueryResult<Notes_NotesQuery, Notes_NotesQueryVariables>;
export const Notes_CreateLabelDocument = gql`
    mutation Notes_CreateLabel($name: String!, $color: String!) {
  createNoteLabel(name: $name, color: $color) {
    noteLabels {
      ...Notes_NoteLabel
    }
  }
}
    ${Notes_NoteLabelFragmentDoc}`;
export type Notes_CreateLabelMutationFn = Apollo.MutationFunction<Notes_CreateLabelMutation, Notes_CreateLabelMutationVariables>;

/**
 * __useNotes_CreateLabelMutation__
 *
 * To run a mutation, you first call `useNotes_CreateLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotes_CreateLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notesCreateLabelMutation, { data, loading, error }] = useNotes_CreateLabelMutation({
 *   variables: {
 *      name: // value for 'name'
 *      color: // value for 'color'
 *   },
 * });
 */
export function useNotes_CreateLabelMutation(baseOptions?: Apollo.MutationHookOptions<Notes_CreateLabelMutation, Notes_CreateLabelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Notes_CreateLabelMutation, Notes_CreateLabelMutationVariables>(Notes_CreateLabelDocument, options);
      }
export type Notes_CreateLabelMutationHookResult = ReturnType<typeof useNotes_CreateLabelMutation>;
export type Notes_CreateLabelMutationResult = Apollo.MutationResult<Notes_CreateLabelMutation>;
export type Notes_CreateLabelMutationOptions = Apollo.BaseMutationOptions<Notes_CreateLabelMutation, Notes_CreateLabelMutationVariables>;
export const Notes_CreateNoteDocument = gql`
    mutation Notes_CreateNote($title: String!, $content: String!, $labels: [ID!]!, $offset: Int!, $take: Int!, $label: ID) {
  createNote(title: $title, content: $content, labels: $labels) {
    note {
      id
    }
    query {
      notes(offset: $offset, take: $take, label: $label) {
        ...Notes_Note
      }
    }
  }
}
    ${Notes_NoteFragmentDoc}`;
export type Notes_CreateNoteMutationFn = Apollo.MutationFunction<Notes_CreateNoteMutation, Notes_CreateNoteMutationVariables>;

/**
 * __useNotes_CreateNoteMutation__
 *
 * To run a mutation, you first call `useNotes_CreateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotes_CreateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notesCreateNoteMutation, { data, loading, error }] = useNotes_CreateNoteMutation({
 *   variables: {
 *      title: // value for 'title'
 *      content: // value for 'content'
 *      labels: // value for 'labels'
 *      offset: // value for 'offset'
 *      take: // value for 'take'
 *      label: // value for 'label'
 *   },
 * });
 */
export function useNotes_CreateNoteMutation(baseOptions?: Apollo.MutationHookOptions<Notes_CreateNoteMutation, Notes_CreateNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Notes_CreateNoteMutation, Notes_CreateNoteMutationVariables>(Notes_CreateNoteDocument, options);
      }
export type Notes_CreateNoteMutationHookResult = ReturnType<typeof useNotes_CreateNoteMutation>;
export type Notes_CreateNoteMutationResult = Apollo.MutationResult<Notes_CreateNoteMutation>;
export type Notes_CreateNoteMutationOptions = Apollo.BaseMutationOptions<Notes_CreateNoteMutation, Notes_CreateNoteMutationVariables>;
export const Notes_DeleteNoteDocument = gql`
    mutation Notes_DeleteNote($noteId: ID!) {
  deleteNote(noteId: $noteId)
}
    `;
export type Notes_DeleteNoteMutationFn = Apollo.MutationFunction<Notes_DeleteNoteMutation, Notes_DeleteNoteMutationVariables>;

/**
 * __useNotes_DeleteNoteMutation__
 *
 * To run a mutation, you first call `useNotes_DeleteNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotes_DeleteNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notesDeleteNoteMutation, { data, loading, error }] = useNotes_DeleteNoteMutation({
 *   variables: {
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useNotes_DeleteNoteMutation(baseOptions?: Apollo.MutationHookOptions<Notes_DeleteNoteMutation, Notes_DeleteNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Notes_DeleteNoteMutation, Notes_DeleteNoteMutationVariables>(Notes_DeleteNoteDocument, options);
      }
export type Notes_DeleteNoteMutationHookResult = ReturnType<typeof useNotes_DeleteNoteMutation>;
export type Notes_DeleteNoteMutationResult = Apollo.MutationResult<Notes_DeleteNoteMutation>;
export type Notes_DeleteNoteMutationOptions = Apollo.BaseMutationOptions<Notes_DeleteNoteMutation, Notes_DeleteNoteMutationVariables>;
export const OrganizationMemberSearchDocument = gql`
    query OrganizationMemberSearch($searchText: String, $skipGroupId: ID) {
  organization {
    id
    members(searchText: $searchText, skipGroupId: $skipGroupId) {
      user {
        id
        fullName
        email
      }
    }
  }
}
    `;

/**
 * __useOrganizationMemberSearchQuery__
 *
 * To run a query within a React component, call `useOrganizationMemberSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationMemberSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationMemberSearchQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      skipGroupId: // value for 'skipGroupId'
 *   },
 * });
 */
export function useOrganizationMemberSearchQuery(baseOptions?: Apollo.QueryHookOptions<OrganizationMemberSearchQuery, OrganizationMemberSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationMemberSearchQuery, OrganizationMemberSearchQueryVariables>(OrganizationMemberSearchDocument, options);
      }
export function useOrganizationMemberSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationMemberSearchQuery, OrganizationMemberSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationMemberSearchQuery, OrganizationMemberSearchQueryVariables>(OrganizationMemberSearchDocument, options);
        }
export type OrganizationMemberSearchQueryHookResult = ReturnType<typeof useOrganizationMemberSearchQuery>;
export type OrganizationMemberSearchLazyQueryHookResult = ReturnType<typeof useOrganizationMemberSearchLazyQuery>;
export type OrganizationMemberSearchQueryResult = Apollo.QueryResult<OrganizationMemberSearchQuery, OrganizationMemberSearchQueryVariables>;
export const OrganizationTeamSearchDocument = gql`
    query OrganizationTeamSearch($searchText: String) {
  organization {
    id
    teams(searchText: $searchText) {
      id
      name
    }
  }
}
    `;

/**
 * __useOrganizationTeamSearchQuery__
 *
 * To run a query within a React component, call `useOrganizationTeamSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationTeamSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationTeamSearchQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useOrganizationTeamSearchQuery(baseOptions?: Apollo.QueryHookOptions<OrganizationTeamSearchQuery, OrganizationTeamSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationTeamSearchQuery, OrganizationTeamSearchQueryVariables>(OrganizationTeamSearchDocument, options);
      }
export function useOrganizationTeamSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationTeamSearchQuery, OrganizationTeamSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationTeamSearchQuery, OrganizationTeamSearchQueryVariables>(OrganizationTeamSearchDocument, options);
        }
export type OrganizationTeamSearchQueryHookResult = ReturnType<typeof useOrganizationTeamSearchQuery>;
export type OrganizationTeamSearchLazyQueryHookResult = ReturnType<typeof useOrganizationTeamSearchLazyQuery>;
export type OrganizationTeamSearchQueryResult = Apollo.QueryResult<OrganizationTeamSearchQuery, OrganizationTeamSearchQueryVariables>;
export const People_SelectOrganizationDocument = gql`
    mutation People_SelectOrganization($id: ID!) {
  selectOrganization(id: $id) {
    organization {
      id
      role
      teams {
        id
        name
      }
      spaces {
        id
        name
      }
    }
  }
}
    `;
export type People_SelectOrganizationMutationFn = Apollo.MutationFunction<People_SelectOrganizationMutation, People_SelectOrganizationMutationVariables>;

/**
 * __usePeople_SelectOrganizationMutation__
 *
 * To run a mutation, you first call `usePeople_SelectOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePeople_SelectOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [peopleSelectOrganizationMutation, { data, loading, error }] = usePeople_SelectOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePeople_SelectOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<People_SelectOrganizationMutation, People_SelectOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<People_SelectOrganizationMutation, People_SelectOrganizationMutationVariables>(People_SelectOrganizationDocument, options);
      }
export type People_SelectOrganizationMutationHookResult = ReturnType<typeof usePeople_SelectOrganizationMutation>;
export type People_SelectOrganizationMutationResult = Apollo.MutationResult<People_SelectOrganizationMutation>;
export type People_SelectOrganizationMutationOptions = Apollo.BaseMutationOptions<People_SelectOrganizationMutation, People_SelectOrganizationMutationVariables>;
export const People_SelectSpaceDocument = gql`
    mutation People_SelectSpace($id: ID!) {
  selectSpace(id: $id) {
    activeSpace {
      id
      name
    }
  }
}
    `;
export type People_SelectSpaceMutationFn = Apollo.MutationFunction<People_SelectSpaceMutation, People_SelectSpaceMutationVariables>;

/**
 * __usePeople_SelectSpaceMutation__
 *
 * To run a mutation, you first call `usePeople_SelectSpaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePeople_SelectSpaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [peopleSelectSpaceMutation, { data, loading, error }] = usePeople_SelectSpaceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePeople_SelectSpaceMutation(baseOptions?: Apollo.MutationHookOptions<People_SelectSpaceMutation, People_SelectSpaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<People_SelectSpaceMutation, People_SelectSpaceMutationVariables>(People_SelectSpaceDocument, options);
      }
export type People_SelectSpaceMutationHookResult = ReturnType<typeof usePeople_SelectSpaceMutation>;
export type People_SelectSpaceMutationResult = Apollo.MutationResult<People_SelectSpaceMutation>;
export type People_SelectSpaceMutationOptions = Apollo.BaseMutationOptions<People_SelectSpaceMutation, People_SelectSpaceMutationVariables>;
export const Organization_OrganizationDocument = gql`
    query Organization_Organization {
  organization {
    id
    name
    role
    members {
      id
      role
      user {
        id
        fullName
        email
        profilePicture
      }
    }
    profilePicture
  }
}
    `;

/**
 * __useOrganization_OrganizationQuery__
 *
 * To run a query within a React component, call `useOrganization_OrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganization_OrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganization_OrganizationQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganization_OrganizationQuery(baseOptions?: Apollo.QueryHookOptions<Organization_OrganizationQuery, Organization_OrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Organization_OrganizationQuery, Organization_OrganizationQueryVariables>(Organization_OrganizationDocument, options);
      }
export function useOrganization_OrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Organization_OrganizationQuery, Organization_OrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Organization_OrganizationQuery, Organization_OrganizationQueryVariables>(Organization_OrganizationDocument, options);
        }
export type Organization_OrganizationQueryHookResult = ReturnType<typeof useOrganization_OrganizationQuery>;
export type Organization_OrganizationLazyQueryHookResult = ReturnType<typeof useOrganization_OrganizationLazyQuery>;
export type Organization_OrganizationQueryResult = Apollo.QueryResult<Organization_OrganizationQuery, Organization_OrganizationQueryVariables>;
export const Organization_RenameOrganizationDocument = gql`
    mutation Organization_RenameOrganization($name: String!) {
  renameOrganization(name: $name) {
    id
    name
  }
}
    `;
export type Organization_RenameOrganizationMutationFn = Apollo.MutationFunction<Organization_RenameOrganizationMutation, Organization_RenameOrganizationMutationVariables>;

/**
 * __useOrganization_RenameOrganizationMutation__
 *
 * To run a mutation, you first call `useOrganization_RenameOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganization_RenameOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationRenameOrganizationMutation, { data, loading, error }] = useOrganization_RenameOrganizationMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useOrganization_RenameOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<Organization_RenameOrganizationMutation, Organization_RenameOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Organization_RenameOrganizationMutation, Organization_RenameOrganizationMutationVariables>(Organization_RenameOrganizationDocument, options);
      }
export type Organization_RenameOrganizationMutationHookResult = ReturnType<typeof useOrganization_RenameOrganizationMutation>;
export type Organization_RenameOrganizationMutationResult = Apollo.MutationResult<Organization_RenameOrganizationMutation>;
export type Organization_RenameOrganizationMutationOptions = Apollo.BaseMutationOptions<Organization_RenameOrganizationMutation, Organization_RenameOrganizationMutationVariables>;
export const Organization_RemoveMemberDocument = gql`
    mutation Organization_RemoveMember($id: ID!) {
  removeMemberFromOrganization(id: $id) {
    id
    members {
      id
      role
      user {
        id
        fullName
        email
      }
    }
    memberCount
  }
}
    `;
export type Organization_RemoveMemberMutationFn = Apollo.MutationFunction<Organization_RemoveMemberMutation, Organization_RemoveMemberMutationVariables>;

/**
 * __useOrganization_RemoveMemberMutation__
 *
 * To run a mutation, you first call `useOrganization_RemoveMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganization_RemoveMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationRemoveMemberMutation, { data, loading, error }] = useOrganization_RemoveMemberMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganization_RemoveMemberMutation(baseOptions?: Apollo.MutationHookOptions<Organization_RemoveMemberMutation, Organization_RemoveMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Organization_RemoveMemberMutation, Organization_RemoveMemberMutationVariables>(Organization_RemoveMemberDocument, options);
      }
export type Organization_RemoveMemberMutationHookResult = ReturnType<typeof useOrganization_RemoveMemberMutation>;
export type Organization_RemoveMemberMutationResult = Apollo.MutationResult<Organization_RemoveMemberMutation>;
export type Organization_RemoveMemberMutationOptions = Apollo.BaseMutationOptions<Organization_RemoveMemberMutation, Organization_RemoveMemberMutationVariables>;
export const Organization_AddMemberDocument = gql`
    mutation Organization_AddMember($userEmail: String!) {
  addMemberToOrganization(userEmail: $userEmail) {
    id
    members {
      id
      role
      user {
        fullName
        email
      }
    }
    memberCount
  }
}
    `;
export type Organization_AddMemberMutationFn = Apollo.MutationFunction<Organization_AddMemberMutation, Organization_AddMemberMutationVariables>;

/**
 * __useOrganization_AddMemberMutation__
 *
 * To run a mutation, you first call `useOrganization_AddMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganization_AddMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationAddMemberMutation, { data, loading, error }] = useOrganization_AddMemberMutation({
 *   variables: {
 *      userEmail: // value for 'userEmail'
 *   },
 * });
 */
export function useOrganization_AddMemberMutation(baseOptions?: Apollo.MutationHookOptions<Organization_AddMemberMutation, Organization_AddMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Organization_AddMemberMutation, Organization_AddMemberMutationVariables>(Organization_AddMemberDocument, options);
      }
export type Organization_AddMemberMutationHookResult = ReturnType<typeof useOrganization_AddMemberMutation>;
export type Organization_AddMemberMutationResult = Apollo.MutationResult<Organization_AddMemberMutation>;
export type Organization_AddMemberMutationOptions = Apollo.BaseMutationOptions<Organization_AddMemberMutation, Organization_AddMemberMutationVariables>;
export const Organization_CreateOrganizationDocument = gql`
    mutation Organization_CreateOrganization($name: String!) {
  createOrganization(name: $name) {
    id
    name
  }
}
    `;
export type Organization_CreateOrganizationMutationFn = Apollo.MutationFunction<Organization_CreateOrganizationMutation, Organization_CreateOrganizationMutationVariables>;

/**
 * __useOrganization_CreateOrganizationMutation__
 *
 * To run a mutation, you first call `useOrganization_CreateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganization_CreateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationCreateOrganizationMutation, { data, loading, error }] = useOrganization_CreateOrganizationMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useOrganization_CreateOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<Organization_CreateOrganizationMutation, Organization_CreateOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Organization_CreateOrganizationMutation, Organization_CreateOrganizationMutationVariables>(Organization_CreateOrganizationDocument, options);
      }
export type Organization_CreateOrganizationMutationHookResult = ReturnType<typeof useOrganization_CreateOrganizationMutation>;
export type Organization_CreateOrganizationMutationResult = Apollo.MutationResult<Organization_CreateOrganizationMutation>;
export type Organization_CreateOrganizationMutationOptions = Apollo.BaseMutationOptions<Organization_CreateOrganizationMutation, Organization_CreateOrganizationMutationVariables>;
export const Organization_UploadProfilePictureDocument = gql`
    mutation Organization_UploadProfilePicture($id: ID!) {
  uploadOrganizationProfilePicture(id: $id)
}
    `;
export type Organization_UploadProfilePictureMutationFn = Apollo.MutationFunction<Organization_UploadProfilePictureMutation, Organization_UploadProfilePictureMutationVariables>;

/**
 * __useOrganization_UploadProfilePictureMutation__
 *
 * To run a mutation, you first call `useOrganization_UploadProfilePictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganization_UploadProfilePictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationUploadProfilePictureMutation, { data, loading, error }] = useOrganization_UploadProfilePictureMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganization_UploadProfilePictureMutation(baseOptions?: Apollo.MutationHookOptions<Organization_UploadProfilePictureMutation, Organization_UploadProfilePictureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Organization_UploadProfilePictureMutation, Organization_UploadProfilePictureMutationVariables>(Organization_UploadProfilePictureDocument, options);
      }
export type Organization_UploadProfilePictureMutationHookResult = ReturnType<typeof useOrganization_UploadProfilePictureMutation>;
export type Organization_UploadProfilePictureMutationResult = Apollo.MutationResult<Organization_UploadProfilePictureMutation>;
export type Organization_UploadProfilePictureMutationOptions = Apollo.BaseMutationOptions<Organization_UploadProfilePictureMutation, Organization_UploadProfilePictureMutationVariables>;
export const Organizations_OrganizationsDocument = gql`
    query Organizations_Organizations {
  organizations {
    id
    name
    role
    profilePicture
  }
  organization {
    id
    name
  }
}
    `;

/**
 * __useOrganizations_OrganizationsQuery__
 *
 * To run a query within a React component, call `useOrganizations_OrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizations_OrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizations_OrganizationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizations_OrganizationsQuery(baseOptions?: Apollo.QueryHookOptions<Organizations_OrganizationsQuery, Organizations_OrganizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Organizations_OrganizationsQuery, Organizations_OrganizationsQueryVariables>(Organizations_OrganizationsDocument, options);
      }
export function useOrganizations_OrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Organizations_OrganizationsQuery, Organizations_OrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Organizations_OrganizationsQuery, Organizations_OrganizationsQueryVariables>(Organizations_OrganizationsDocument, options);
        }
export type Organizations_OrganizationsQueryHookResult = ReturnType<typeof useOrganizations_OrganizationsQuery>;
export type Organizations_OrganizationsLazyQueryHookResult = ReturnType<typeof useOrganizations_OrganizationsLazyQuery>;
export type Organizations_OrganizationsQueryResult = Apollo.QueryResult<Organizations_OrganizationsQuery, Organizations_OrganizationsQueryVariables>;
export const Spaces_SpacesDocument = gql`
    query Spaces_Spaces {
  organization {
    id
    role
    spaces {
      id
      name
    }
  }
}
    `;

/**
 * __useSpaces_SpacesQuery__
 *
 * To run a query within a React component, call `useSpaces_SpacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpaces_SpacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpaces_SpacesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSpaces_SpacesQuery(baseOptions?: Apollo.QueryHookOptions<Spaces_SpacesQuery, Spaces_SpacesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Spaces_SpacesQuery, Spaces_SpacesQueryVariables>(Spaces_SpacesDocument, options);
      }
export function useSpaces_SpacesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Spaces_SpacesQuery, Spaces_SpacesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Spaces_SpacesQuery, Spaces_SpacesQueryVariables>(Spaces_SpacesDocument, options);
        }
export type Spaces_SpacesQueryHookResult = ReturnType<typeof useSpaces_SpacesQuery>;
export type Spaces_SpacesLazyQueryHookResult = ReturnType<typeof useSpaces_SpacesLazyQuery>;
export type Spaces_SpacesQueryResult = Apollo.QueryResult<Spaces_SpacesQuery, Spaces_SpacesQueryVariables>;
export const Spaces_OrgTeamsDocument = gql`
    query Spaces_OrgTeams {
  organization {
    teams {
      id
      name
    }
  }
}
    `;

/**
 * __useSpaces_OrgTeamsQuery__
 *
 * To run a query within a React component, call `useSpaces_OrgTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpaces_OrgTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpaces_OrgTeamsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSpaces_OrgTeamsQuery(baseOptions?: Apollo.QueryHookOptions<Spaces_OrgTeamsQuery, Spaces_OrgTeamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Spaces_OrgTeamsQuery, Spaces_OrgTeamsQueryVariables>(Spaces_OrgTeamsDocument, options);
      }
export function useSpaces_OrgTeamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Spaces_OrgTeamsQuery, Spaces_OrgTeamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Spaces_OrgTeamsQuery, Spaces_OrgTeamsQueryVariables>(Spaces_OrgTeamsDocument, options);
        }
export type Spaces_OrgTeamsQueryHookResult = ReturnType<typeof useSpaces_OrgTeamsQuery>;
export type Spaces_OrgTeamsLazyQueryHookResult = ReturnType<typeof useSpaces_OrgTeamsLazyQuery>;
export type Spaces_OrgTeamsQueryResult = Apollo.QueryResult<Spaces_OrgTeamsQuery, Spaces_OrgTeamsQueryVariables>;
export const Spaces_CreateSpaceDocument = gql`
    mutation Spaces_CreateSpace($name: String!) {
  createSpace(name: $name) {
    id
    spaces {
      id
      name
    }
  }
}
    `;
export type Spaces_CreateSpaceMutationFn = Apollo.MutationFunction<Spaces_CreateSpaceMutation, Spaces_CreateSpaceMutationVariables>;

/**
 * __useSpaces_CreateSpaceMutation__
 *
 * To run a mutation, you first call `useSpaces_CreateSpaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpaces_CreateSpaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spacesCreateSpaceMutation, { data, loading, error }] = useSpaces_CreateSpaceMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useSpaces_CreateSpaceMutation(baseOptions?: Apollo.MutationHookOptions<Spaces_CreateSpaceMutation, Spaces_CreateSpaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Spaces_CreateSpaceMutation, Spaces_CreateSpaceMutationVariables>(Spaces_CreateSpaceDocument, options);
      }
export type Spaces_CreateSpaceMutationHookResult = ReturnType<typeof useSpaces_CreateSpaceMutation>;
export type Spaces_CreateSpaceMutationResult = Apollo.MutationResult<Spaces_CreateSpaceMutation>;
export type Spaces_CreateSpaceMutationOptions = Apollo.BaseMutationOptions<Spaces_CreateSpaceMutation, Spaces_CreateSpaceMutationVariables>;
export const Spaces_SpaceDocument = gql`
    query Spaces_Space($id: ID!) {
  space(id: $id) {
    id
    name
    teams {
      id
      name
    }
  }
}
    `;

/**
 * __useSpaces_SpaceQuery__
 *
 * To run a query within a React component, call `useSpaces_SpaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpaces_SpaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpaces_SpaceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSpaces_SpaceQuery(baseOptions: Apollo.QueryHookOptions<Spaces_SpaceQuery, Spaces_SpaceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Spaces_SpaceQuery, Spaces_SpaceQueryVariables>(Spaces_SpaceDocument, options);
      }
export function useSpaces_SpaceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Spaces_SpaceQuery, Spaces_SpaceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Spaces_SpaceQuery, Spaces_SpaceQueryVariables>(Spaces_SpaceDocument, options);
        }
export type Spaces_SpaceQueryHookResult = ReturnType<typeof useSpaces_SpaceQuery>;
export type Spaces_SpaceLazyQueryHookResult = ReturnType<typeof useSpaces_SpaceLazyQuery>;
export type Spaces_SpaceQueryResult = Apollo.QueryResult<Spaces_SpaceQuery, Spaces_SpaceQueryVariables>;
export const Spaces_RenameSpaceDocument = gql`
    mutation Spaces_RenameSpace($id: ID!, $newName: String!) {
  renameSpace(id: $id, newName: $newName) {
    id
    name
  }
}
    `;
export type Spaces_RenameSpaceMutationFn = Apollo.MutationFunction<Spaces_RenameSpaceMutation, Spaces_RenameSpaceMutationVariables>;

/**
 * __useSpaces_RenameSpaceMutation__
 *
 * To run a mutation, you first call `useSpaces_RenameSpaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpaces_RenameSpaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spacesRenameSpaceMutation, { data, loading, error }] = useSpaces_RenameSpaceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      newName: // value for 'newName'
 *   },
 * });
 */
export function useSpaces_RenameSpaceMutation(baseOptions?: Apollo.MutationHookOptions<Spaces_RenameSpaceMutation, Spaces_RenameSpaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Spaces_RenameSpaceMutation, Spaces_RenameSpaceMutationVariables>(Spaces_RenameSpaceDocument, options);
      }
export type Spaces_RenameSpaceMutationHookResult = ReturnType<typeof useSpaces_RenameSpaceMutation>;
export type Spaces_RenameSpaceMutationResult = Apollo.MutationResult<Spaces_RenameSpaceMutation>;
export type Spaces_RenameSpaceMutationOptions = Apollo.BaseMutationOptions<Spaces_RenameSpaceMutation, Spaces_RenameSpaceMutationVariables>;
export const Spaces_AddTeamDocument = gql`
    mutation Spaces_AddTeam($id: ID!, $teamId: ID!) {
  addTeamToSpace(id: $id, teamId: $teamId) {
    id
    teams {
      id
      name
    }
  }
}
    `;
export type Spaces_AddTeamMutationFn = Apollo.MutationFunction<Spaces_AddTeamMutation, Spaces_AddTeamMutationVariables>;

/**
 * __useSpaces_AddTeamMutation__
 *
 * To run a mutation, you first call `useSpaces_AddTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpaces_AddTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spacesAddTeamMutation, { data, loading, error }] = useSpaces_AddTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useSpaces_AddTeamMutation(baseOptions?: Apollo.MutationHookOptions<Spaces_AddTeamMutation, Spaces_AddTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Spaces_AddTeamMutation, Spaces_AddTeamMutationVariables>(Spaces_AddTeamDocument, options);
      }
export type Spaces_AddTeamMutationHookResult = ReturnType<typeof useSpaces_AddTeamMutation>;
export type Spaces_AddTeamMutationResult = Apollo.MutationResult<Spaces_AddTeamMutation>;
export type Spaces_AddTeamMutationOptions = Apollo.BaseMutationOptions<Spaces_AddTeamMutation, Spaces_AddTeamMutationVariables>;
export const Spaces_RemoveTeamDocument = gql`
    mutation Spaces_RemoveTeam($id: ID!, $teamId: ID!) {
  removeTeamFromSpace(id: $id, teamId: $teamId) {
    id
    teams {
      id
      name
    }
  }
}
    `;
export type Spaces_RemoveTeamMutationFn = Apollo.MutationFunction<Spaces_RemoveTeamMutation, Spaces_RemoveTeamMutationVariables>;

/**
 * __useSpaces_RemoveTeamMutation__
 *
 * To run a mutation, you first call `useSpaces_RemoveTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpaces_RemoveTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spacesRemoveTeamMutation, { data, loading, error }] = useSpaces_RemoveTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useSpaces_RemoveTeamMutation(baseOptions?: Apollo.MutationHookOptions<Spaces_RemoveTeamMutation, Spaces_RemoveTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Spaces_RemoveTeamMutation, Spaces_RemoveTeamMutationVariables>(Spaces_RemoveTeamDocument, options);
      }
export type Spaces_RemoveTeamMutationHookResult = ReturnType<typeof useSpaces_RemoveTeamMutation>;
export type Spaces_RemoveTeamMutationResult = Apollo.MutationResult<Spaces_RemoveTeamMutation>;
export type Spaces_RemoveTeamMutationOptions = Apollo.BaseMutationOptions<Spaces_RemoveTeamMutation, Spaces_RemoveTeamMutationVariables>;
export const Spaces_RemoveSpaceDocument = gql`
    mutation Spaces_RemoveSpace($id: ID!) {
  removeSpace(id: $id) {
    id
    spaces {
      id
      name
    }
  }
}
    `;
export type Spaces_RemoveSpaceMutationFn = Apollo.MutationFunction<Spaces_RemoveSpaceMutation, Spaces_RemoveSpaceMutationVariables>;

/**
 * __useSpaces_RemoveSpaceMutation__
 *
 * To run a mutation, you first call `useSpaces_RemoveSpaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpaces_RemoveSpaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spacesRemoveSpaceMutation, { data, loading, error }] = useSpaces_RemoveSpaceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSpaces_RemoveSpaceMutation(baseOptions?: Apollo.MutationHookOptions<Spaces_RemoveSpaceMutation, Spaces_RemoveSpaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Spaces_RemoveSpaceMutation, Spaces_RemoveSpaceMutationVariables>(Spaces_RemoveSpaceDocument, options);
      }
export type Spaces_RemoveSpaceMutationHookResult = ReturnType<typeof useSpaces_RemoveSpaceMutation>;
export type Spaces_RemoveSpaceMutationResult = Apollo.MutationResult<Spaces_RemoveSpaceMutation>;
export type Spaces_RemoveSpaceMutationOptions = Apollo.BaseMutationOptions<Spaces_RemoveSpaceMutation, Spaces_RemoveSpaceMutationVariables>;
export const Organization_TeamsDocument = gql`
    query Organization_Teams {
  organization {
    id
    role
    teams {
      id
      name
    }
  }
}
    `;

/**
 * __useOrganization_TeamsQuery__
 *
 * To run a query within a React component, call `useOrganization_TeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganization_TeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganization_TeamsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganization_TeamsQuery(baseOptions?: Apollo.QueryHookOptions<Organization_TeamsQuery, Organization_TeamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Organization_TeamsQuery, Organization_TeamsQueryVariables>(Organization_TeamsDocument, options);
      }
export function useOrganization_TeamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Organization_TeamsQuery, Organization_TeamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Organization_TeamsQuery, Organization_TeamsQueryVariables>(Organization_TeamsDocument, options);
        }
export type Organization_TeamsQueryHookResult = ReturnType<typeof useOrganization_TeamsQuery>;
export type Organization_TeamsLazyQueryHookResult = ReturnType<typeof useOrganization_TeamsLazyQuery>;
export type Organization_TeamsQueryResult = Apollo.QueryResult<Organization_TeamsQuery, Organization_TeamsQueryVariables>;
export const Teams_CreateTeamDocument = gql`
    mutation Teams_CreateTeam($name: String!) {
  createTeam(name: $name) {
    id
    teams {
      id
      name
    }
  }
}
    `;
export type Teams_CreateTeamMutationFn = Apollo.MutationFunction<Teams_CreateTeamMutation, Teams_CreateTeamMutationVariables>;

/**
 * __useTeams_CreateTeamMutation__
 *
 * To run a mutation, you first call `useTeams_CreateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeams_CreateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teamsCreateTeamMutation, { data, loading, error }] = useTeams_CreateTeamMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useTeams_CreateTeamMutation(baseOptions?: Apollo.MutationHookOptions<Teams_CreateTeamMutation, Teams_CreateTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Teams_CreateTeamMutation, Teams_CreateTeamMutationVariables>(Teams_CreateTeamDocument, options);
      }
export type Teams_CreateTeamMutationHookResult = ReturnType<typeof useTeams_CreateTeamMutation>;
export type Teams_CreateTeamMutationResult = Apollo.MutationResult<Teams_CreateTeamMutation>;
export type Teams_CreateTeamMutationOptions = Apollo.BaseMutationOptions<Teams_CreateTeamMutation, Teams_CreateTeamMutationVariables>;
export const Teams_TeamDocument = gql`
    query Teams_Team($id: ID!) {
  team(id: $id) {
    id
    name
    members {
      ...Teams_TeamMember
    }
  }
}
    ${Teams_TeamMemberFragmentDoc}`;

/**
 * __useTeams_TeamQuery__
 *
 * To run a query within a React component, call `useTeams_TeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeams_TeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeams_TeamQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTeams_TeamQuery(baseOptions: Apollo.QueryHookOptions<Teams_TeamQuery, Teams_TeamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Teams_TeamQuery, Teams_TeamQueryVariables>(Teams_TeamDocument, options);
      }
export function useTeams_TeamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Teams_TeamQuery, Teams_TeamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Teams_TeamQuery, Teams_TeamQueryVariables>(Teams_TeamDocument, options);
        }
export type Teams_TeamQueryHookResult = ReturnType<typeof useTeams_TeamQuery>;
export type Teams_TeamLazyQueryHookResult = ReturnType<typeof useTeams_TeamLazyQuery>;
export type Teams_TeamQueryResult = Apollo.QueryResult<Teams_TeamQuery, Teams_TeamQueryVariables>;
export const Teams_OrgMembersDocument = gql`
    query Teams_OrgMembers {
  organization {
    id
    members {
      id
      user {
        id
        fullName
      }
    }
  }
}
    `;

/**
 * __useTeams_OrgMembersQuery__
 *
 * To run a query within a React component, call `useTeams_OrgMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeams_OrgMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeams_OrgMembersQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeams_OrgMembersQuery(baseOptions?: Apollo.QueryHookOptions<Teams_OrgMembersQuery, Teams_OrgMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Teams_OrgMembersQuery, Teams_OrgMembersQueryVariables>(Teams_OrgMembersDocument, options);
      }
export function useTeams_OrgMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Teams_OrgMembersQuery, Teams_OrgMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Teams_OrgMembersQuery, Teams_OrgMembersQueryVariables>(Teams_OrgMembersDocument, options);
        }
export type Teams_OrgMembersQueryHookResult = ReturnType<typeof useTeams_OrgMembersQuery>;
export type Teams_OrgMembersLazyQueryHookResult = ReturnType<typeof useTeams_OrgMembersLazyQuery>;
export type Teams_OrgMembersQueryResult = Apollo.QueryResult<Teams_OrgMembersQuery, Teams_OrgMembersQueryVariables>;
export const Teams_RenameTeamDocument = gql`
    mutation Teams_RenameTeam($id: ID!, $newName: String!) {
  renameTeam(id: $id, newName: $newName) {
    id
    name
  }
}
    `;
export type Teams_RenameTeamMutationFn = Apollo.MutationFunction<Teams_RenameTeamMutation, Teams_RenameTeamMutationVariables>;

/**
 * __useTeams_RenameTeamMutation__
 *
 * To run a mutation, you first call `useTeams_RenameTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeams_RenameTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teamsRenameTeamMutation, { data, loading, error }] = useTeams_RenameTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *      newName: // value for 'newName'
 *   },
 * });
 */
export function useTeams_RenameTeamMutation(baseOptions?: Apollo.MutationHookOptions<Teams_RenameTeamMutation, Teams_RenameTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Teams_RenameTeamMutation, Teams_RenameTeamMutationVariables>(Teams_RenameTeamDocument, options);
      }
export type Teams_RenameTeamMutationHookResult = ReturnType<typeof useTeams_RenameTeamMutation>;
export type Teams_RenameTeamMutationResult = Apollo.MutationResult<Teams_RenameTeamMutation>;
export type Teams_RenameTeamMutationOptions = Apollo.BaseMutationOptions<Teams_RenameTeamMutation, Teams_RenameTeamMutationVariables>;
export const Teams_AddMemberDocument = gql`
    mutation Teams_AddMember($id: ID!, $memberId: ID!) {
  addMemberToTeam(id: $id, memberId: $memberId) {
    id
    members {
      ...Teams_TeamMember
    }
  }
}
    ${Teams_TeamMemberFragmentDoc}`;
export type Teams_AddMemberMutationFn = Apollo.MutationFunction<Teams_AddMemberMutation, Teams_AddMemberMutationVariables>;

/**
 * __useTeams_AddMemberMutation__
 *
 * To run a mutation, you first call `useTeams_AddMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeams_AddMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teamsAddMemberMutation, { data, loading, error }] = useTeams_AddMemberMutation({
 *   variables: {
 *      id: // value for 'id'
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useTeams_AddMemberMutation(baseOptions?: Apollo.MutationHookOptions<Teams_AddMemberMutation, Teams_AddMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Teams_AddMemberMutation, Teams_AddMemberMutationVariables>(Teams_AddMemberDocument, options);
      }
export type Teams_AddMemberMutationHookResult = ReturnType<typeof useTeams_AddMemberMutation>;
export type Teams_AddMemberMutationResult = Apollo.MutationResult<Teams_AddMemberMutation>;
export type Teams_AddMemberMutationOptions = Apollo.BaseMutationOptions<Teams_AddMemberMutation, Teams_AddMemberMutationVariables>;
export const Teams_RemoveMemberDocument = gql`
    mutation Teams_RemoveMember($id: ID!, $memberId: ID!) {
  removeMemberFromTeam(id: $id, memberId: $memberId) {
    id
    members {
      ...Teams_TeamMember
    }
  }
}
    ${Teams_TeamMemberFragmentDoc}`;
export type Teams_RemoveMemberMutationFn = Apollo.MutationFunction<Teams_RemoveMemberMutation, Teams_RemoveMemberMutationVariables>;

/**
 * __useTeams_RemoveMemberMutation__
 *
 * To run a mutation, you first call `useTeams_RemoveMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeams_RemoveMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teamsRemoveMemberMutation, { data, loading, error }] = useTeams_RemoveMemberMutation({
 *   variables: {
 *      id: // value for 'id'
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useTeams_RemoveMemberMutation(baseOptions?: Apollo.MutationHookOptions<Teams_RemoveMemberMutation, Teams_RemoveMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Teams_RemoveMemberMutation, Teams_RemoveMemberMutationVariables>(Teams_RemoveMemberDocument, options);
      }
export type Teams_RemoveMemberMutationHookResult = ReturnType<typeof useTeams_RemoveMemberMutation>;
export type Teams_RemoveMemberMutationResult = Apollo.MutationResult<Teams_RemoveMemberMutation>;
export type Teams_RemoveMemberMutationOptions = Apollo.BaseMutationOptions<Teams_RemoveMemberMutation, Teams_RemoveMemberMutationVariables>;
export const Teams_RemoveTeamDocument = gql`
    mutation Teams_RemoveTeam($id: ID!) {
  removeTeam(id: $id) {
    id
    teams {
      id
      name
    }
  }
}
    `;
export type Teams_RemoveTeamMutationFn = Apollo.MutationFunction<Teams_RemoveTeamMutation, Teams_RemoveTeamMutationVariables>;

/**
 * __useTeams_RemoveTeamMutation__
 *
 * To run a mutation, you first call `useTeams_RemoveTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeams_RemoveTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teamsRemoveTeamMutation, { data, loading, error }] = useTeams_RemoveTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTeams_RemoveTeamMutation(baseOptions?: Apollo.MutationHookOptions<Teams_RemoveTeamMutation, Teams_RemoveTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Teams_RemoveTeamMutation, Teams_RemoveTeamMutationVariables>(Teams_RemoveTeamDocument, options);
      }
export type Teams_RemoveTeamMutationHookResult = ReturnType<typeof useTeams_RemoveTeamMutation>;
export type Teams_RemoveTeamMutationResult = Apollo.MutationResult<Teams_RemoveTeamMutation>;
export type Teams_RemoveTeamMutationOptions = Apollo.BaseMutationOptions<Teams_RemoveTeamMutation, Teams_RemoveTeamMutationVariables>;
export const BookmarkButton_CreateBookmarkDocument = gql`
    mutation BookmarkButton_CreateBookmark($url: String!, $title: String!) {
  createBookmark(url: $url, title: $title) {
    title
    url
  }
}
    `;
export type BookmarkButton_CreateBookmarkMutationFn = Apollo.MutationFunction<BookmarkButton_CreateBookmarkMutation, BookmarkButton_CreateBookmarkMutationVariables>;

/**
 * __useBookmarkButton_CreateBookmarkMutation__
 *
 * To run a mutation, you first call `useBookmarkButton_CreateBookmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookmarkButton_CreateBookmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookmarkButtonCreateBookmarkMutation, { data, loading, error }] = useBookmarkButton_CreateBookmarkMutation({
 *   variables: {
 *      url: // value for 'url'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useBookmarkButton_CreateBookmarkMutation(baseOptions?: Apollo.MutationHookOptions<BookmarkButton_CreateBookmarkMutation, BookmarkButton_CreateBookmarkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BookmarkButton_CreateBookmarkMutation, BookmarkButton_CreateBookmarkMutationVariables>(BookmarkButton_CreateBookmarkDocument, options);
      }
export type BookmarkButton_CreateBookmarkMutationHookResult = ReturnType<typeof useBookmarkButton_CreateBookmarkMutation>;
export type BookmarkButton_CreateBookmarkMutationResult = Apollo.MutationResult<BookmarkButton_CreateBookmarkMutation>;
export type BookmarkButton_CreateBookmarkMutationOptions = Apollo.BaseMutationOptions<BookmarkButton_CreateBookmarkMutation, BookmarkButton_CreateBookmarkMutationVariables>;
export const EmojiSelectorDocument = gql`
    query EmojiSelector {
  me {
    id
    skinTonePreference
  }
  emoticons {
    id
    code
    name
    icon
    category
  }
}
    `;

/**
 * __useEmojiSelectorQuery__
 *
 * To run a query within a React component, call `useEmojiSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmojiSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmojiSelectorQuery({
 *   variables: {
 *   },
 * });
 */
export function useEmojiSelectorQuery(baseOptions?: Apollo.QueryHookOptions<EmojiSelectorQuery, EmojiSelectorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmojiSelectorQuery, EmojiSelectorQueryVariables>(EmojiSelectorDocument, options);
      }
export function useEmojiSelectorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmojiSelectorQuery, EmojiSelectorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmojiSelectorQuery, EmojiSelectorQueryVariables>(EmojiSelectorDocument, options);
        }
export type EmojiSelectorQueryHookResult = ReturnType<typeof useEmojiSelectorQuery>;
export type EmojiSelectorLazyQueryHookResult = ReturnType<typeof useEmojiSelectorLazyQuery>;
export type EmojiSelectorQueryResult = Apollo.QueryResult<EmojiSelectorQuery, EmojiSelectorQueryVariables>;
export const EmojiSelector_UpdateSkinTonePreferenceDocument = gql`
    mutation EmojiSelector_UpdateSkinTonePreference($tone: Int!) {
  updateSkinTonePreference(tone: $tone) {
    emoticons {
      id
      code
      name
      icon
      category
    }
  }
}
    `;
export type EmojiSelector_UpdateSkinTonePreferenceMutationFn = Apollo.MutationFunction<EmojiSelector_UpdateSkinTonePreferenceMutation, EmojiSelector_UpdateSkinTonePreferenceMutationVariables>;

/**
 * __useEmojiSelector_UpdateSkinTonePreferenceMutation__
 *
 * To run a mutation, you first call `useEmojiSelector_UpdateSkinTonePreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmojiSelector_UpdateSkinTonePreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emojiSelectorUpdateSkinTonePreferenceMutation, { data, loading, error }] = useEmojiSelector_UpdateSkinTonePreferenceMutation({
 *   variables: {
 *      tone: // value for 'tone'
 *   },
 * });
 */
export function useEmojiSelector_UpdateSkinTonePreferenceMutation(baseOptions?: Apollo.MutationHookOptions<EmojiSelector_UpdateSkinTonePreferenceMutation, EmojiSelector_UpdateSkinTonePreferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EmojiSelector_UpdateSkinTonePreferenceMutation, EmojiSelector_UpdateSkinTonePreferenceMutationVariables>(EmojiSelector_UpdateSkinTonePreferenceDocument, options);
      }
export type EmojiSelector_UpdateSkinTonePreferenceMutationHookResult = ReturnType<typeof useEmojiSelector_UpdateSkinTonePreferenceMutation>;
export type EmojiSelector_UpdateSkinTonePreferenceMutationResult = Apollo.MutationResult<EmojiSelector_UpdateSkinTonePreferenceMutation>;
export type EmojiSelector_UpdateSkinTonePreferenceMutationOptions = Apollo.BaseMutationOptions<EmojiSelector_UpdateSkinTonePreferenceMutation, EmojiSelector_UpdateSkinTonePreferenceMutationVariables>;
export const FileEditorDocument = gql`
    query FileEditor($id: ID!) {
  file(id: $id) {
    id
    content
  }
}
    `;

/**
 * __useFileEditorQuery__
 *
 * To run a query within a React component, call `useFileEditorQuery` and pass it any options that fit your needs.
 * When your component renders, `useFileEditorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFileEditorQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFileEditorQuery(baseOptions: Apollo.QueryHookOptions<FileEditorQuery, FileEditorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FileEditorQuery, FileEditorQueryVariables>(FileEditorDocument, options);
      }
export function useFileEditorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FileEditorQuery, FileEditorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FileEditorQuery, FileEditorQueryVariables>(FileEditorDocument, options);
        }
export type FileEditorQueryHookResult = ReturnType<typeof useFileEditorQuery>;
export type FileEditorLazyQueryHookResult = ReturnType<typeof useFileEditorLazyQuery>;
export type FileEditorQueryResult = Apollo.QueryResult<FileEditorQuery, FileEditorQueryVariables>;
export const ListingPageDocument = gql`
    query ListingPage {
  organization {
    name
    id
  }
}
    `;

/**
 * __useListingPageQuery__
 *
 * To run a query within a React component, call `useListingPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useListingPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListingPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useListingPageQuery(baseOptions?: Apollo.QueryHookOptions<ListingPageQuery, ListingPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListingPageQuery, ListingPageQueryVariables>(ListingPageDocument, options);
      }
export function useListingPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListingPageQuery, ListingPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListingPageQuery, ListingPageQueryVariables>(ListingPageDocument, options);
        }
export type ListingPageQueryHookResult = ReturnType<typeof useListingPageQuery>;
export type ListingPageLazyQueryHookResult = ReturnType<typeof useListingPageLazyQuery>;
export type ListingPageQueryResult = Apollo.QueryResult<ListingPageQuery, ListingPageQueryVariables>;
export const PretzelTextEditor_UploadTempFileDocument = gql`
    mutation PretzelTextEditor_UploadTempFile($fileName: String!, $fileType: String!, $fileSize: Int!) {
  uploadTempFile(fileName: $fileName, fileType: $fileType, fileSize: $fileSize) {
    uploadId
    postData
  }
}
    `;
export type PretzelTextEditor_UploadTempFileMutationFn = Apollo.MutationFunction<PretzelTextEditor_UploadTempFileMutation, PretzelTextEditor_UploadTempFileMutationVariables>;

/**
 * __usePretzelTextEditor_UploadTempFileMutation__
 *
 * To run a mutation, you first call `usePretzelTextEditor_UploadTempFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePretzelTextEditor_UploadTempFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pretzelTextEditorUploadTempFileMutation, { data, loading, error }] = usePretzelTextEditor_UploadTempFileMutation({
 *   variables: {
 *      fileName: // value for 'fileName'
 *      fileType: // value for 'fileType'
 *      fileSize: // value for 'fileSize'
 *   },
 * });
 */
export function usePretzelTextEditor_UploadTempFileMutation(baseOptions?: Apollo.MutationHookOptions<PretzelTextEditor_UploadTempFileMutation, PretzelTextEditor_UploadTempFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PretzelTextEditor_UploadTempFileMutation, PretzelTextEditor_UploadTempFileMutationVariables>(PretzelTextEditor_UploadTempFileDocument, options);
      }
export type PretzelTextEditor_UploadTempFileMutationHookResult = ReturnType<typeof usePretzelTextEditor_UploadTempFileMutation>;
export type PretzelTextEditor_UploadTempFileMutationResult = Apollo.MutationResult<PretzelTextEditor_UploadTempFileMutation>;
export type PretzelTextEditor_UploadTempFileMutationOptions = Apollo.BaseMutationOptions<PretzelTextEditor_UploadTempFileMutation, PretzelTextEditor_UploadTempFileMutationVariables>;
export const TaskSelectorDocument = gql`
    query TaskSelector {
  allTasks {
    boardIdStr
    task {
      id
      title
      description
      boardId
      idNumber
      state {
        id
        color
      }
      assignees {
        id
        fullName
        profilePicture
      }
      breadcrumbs {
        id
        title
        idNumber
        state {
          id
          color
        }
      }
    }
  }
}
    `;

/**
 * __useTaskSelectorQuery__
 *
 * To run a query within a React component, call `useTaskSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskSelectorQuery({
 *   variables: {
 *   },
 * });
 */
export function useTaskSelectorQuery(baseOptions?: Apollo.QueryHookOptions<TaskSelectorQuery, TaskSelectorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskSelectorQuery, TaskSelectorQueryVariables>(TaskSelectorDocument, options);
      }
export function useTaskSelectorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskSelectorQuery, TaskSelectorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskSelectorQuery, TaskSelectorQueryVariables>(TaskSelectorDocument, options);
        }
export type TaskSelectorQueryHookResult = ReturnType<typeof useTaskSelectorQuery>;
export type TaskSelectorLazyQueryHookResult = ReturnType<typeof useTaskSelectorLazyQuery>;
export type TaskSelectorQueryResult = Apollo.QueryResult<TaskSelectorQuery, TaskSelectorQueryVariables>;
export const UserSelectorDocument = gql`
    query UserSelector {
  organization {
    id
    members(includePretzel: true) {
      user {
        fullName
        id
        profilePicture
      }
    }
  }
}
    `;

/**
 * __useUserSelectorQuery__
 *
 * To run a query within a React component, call `useUserSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSelectorQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserSelectorQuery(baseOptions?: Apollo.QueryHookOptions<UserSelectorQuery, UserSelectorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserSelectorQuery, UserSelectorQueryVariables>(UserSelectorDocument, options);
      }
export function useUserSelectorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserSelectorQuery, UserSelectorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserSelectorQuery, UserSelectorQueryVariables>(UserSelectorDocument, options);
        }
export type UserSelectorQueryHookResult = ReturnType<typeof useUserSelectorQuery>;
export type UserSelectorLazyQueryHookResult = ReturnType<typeof useUserSelectorLazyQuery>;
export type UserSelectorQueryResult = Apollo.QueryResult<UserSelectorQuery, UserSelectorQueryVariables>;
export const ProfilePictureDocument = gql`
    query ProfilePicture {
  activeSession {
    id
    isActive
  }
}
    `;

/**
 * __useProfilePictureQuery__
 *
 * To run a query within a React component, call `useProfilePictureQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfilePictureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfilePictureQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfilePictureQuery(baseOptions?: Apollo.QueryHookOptions<ProfilePictureQuery, ProfilePictureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfilePictureQuery, ProfilePictureQueryVariables>(ProfilePictureDocument, options);
      }
export function useProfilePictureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfilePictureQuery, ProfilePictureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfilePictureQuery, ProfilePictureQueryVariables>(ProfilePictureDocument, options);
        }
export type ProfilePictureQueryHookResult = ReturnType<typeof useProfilePictureQuery>;
export type ProfilePictureLazyQueryHookResult = ReturnType<typeof useProfilePictureLazyQuery>;
export type ProfilePictureQueryResult = Apollo.QueryResult<ProfilePictureQuery, ProfilePictureQueryVariables>;
export const ProfilePicture_UserDocument = gql`
    query ProfilePicture_User($userId: ID!) {
  user(id: $userId) {
    id
    isActive
    lastSeen
  }
}
    `;

/**
 * __useProfilePicture_UserQuery__
 *
 * To run a query within a React component, call `useProfilePicture_UserQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfilePicture_UserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfilePicture_UserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useProfilePicture_UserQuery(baseOptions: Apollo.QueryHookOptions<ProfilePicture_UserQuery, ProfilePicture_UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfilePicture_UserQuery, ProfilePicture_UserQueryVariables>(ProfilePicture_UserDocument, options);
      }
export function useProfilePicture_UserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfilePicture_UserQuery, ProfilePicture_UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfilePicture_UserQuery, ProfilePicture_UserQueryVariables>(ProfilePicture_UserDocument, options);
        }
export type ProfilePicture_UserQueryHookResult = ReturnType<typeof useProfilePicture_UserQuery>;
export type ProfilePicture_UserLazyQueryHookResult = ReturnType<typeof useProfilePicture_UserLazyQuery>;
export type ProfilePicture_UserQueryResult = Apollo.QueryResult<ProfilePicture_UserQuery, ProfilePicture_UserQueryVariables>;
export const Searchbar_CreateAppSpaceDocument = gql`
    mutation Searchbar_CreateAppSpace($type: String!, $name: String, $content: String) {
  createAppSpace(type: $type, name: $name, content: $content) {
    appSpaces {
      ...AppSpaceTabs_AppSpace
    }
    activeAppSpace {
      ...AppSpace_ActiveAppSpace
    }
  }
}
    ${AppSpaceTabs_AppSpaceFragmentDoc}
${AppSpace_ActiveAppSpaceFragmentDoc}`;
export type Searchbar_CreateAppSpaceMutationFn = Apollo.MutationFunction<Searchbar_CreateAppSpaceMutation, Searchbar_CreateAppSpaceMutationVariables>;

/**
 * __useSearchbar_CreateAppSpaceMutation__
 *
 * To run a mutation, you first call `useSearchbar_CreateAppSpaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSearchbar_CreateAppSpaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [searchbarCreateAppSpaceMutation, { data, loading, error }] = useSearchbar_CreateAppSpaceMutation({
 *   variables: {
 *      type: // value for 'type'
 *      name: // value for 'name'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useSearchbar_CreateAppSpaceMutation(baseOptions?: Apollo.MutationHookOptions<Searchbar_CreateAppSpaceMutation, Searchbar_CreateAppSpaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Searchbar_CreateAppSpaceMutation, Searchbar_CreateAppSpaceMutationVariables>(Searchbar_CreateAppSpaceDocument, options);
      }
export type Searchbar_CreateAppSpaceMutationHookResult = ReturnType<typeof useSearchbar_CreateAppSpaceMutation>;
export type Searchbar_CreateAppSpaceMutationResult = Apollo.MutationResult<Searchbar_CreateAppSpaceMutation>;
export type Searchbar_CreateAppSpaceMutationOptions = Apollo.BaseMutationOptions<Searchbar_CreateAppSpaceMutation, Searchbar_CreateAppSpaceMutationVariables>;
export const SearchbarDocument = gql`
    query Searchbar {
  appSpaceSettings {
    appSpaceLimitPerSession
  }
}
    `;

/**
 * __useSearchbarQuery__
 *
 * To run a query within a React component, call `useSearchbarQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchbarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchbarQuery({
 *   variables: {
 *   },
 * });
 */
export function useSearchbarQuery(baseOptions?: Apollo.QueryHookOptions<SearchbarQuery, SearchbarQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchbarQuery, SearchbarQueryVariables>(SearchbarDocument, options);
      }
export function useSearchbarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchbarQuery, SearchbarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchbarQuery, SearchbarQueryVariables>(SearchbarDocument, options);
        }
export type SearchbarQueryHookResult = ReturnType<typeof useSearchbarQuery>;
export type SearchbarLazyQueryHookResult = ReturnType<typeof useSearchbarLazyQuery>;
export type SearchbarQueryResult = Apollo.QueryResult<SearchbarQuery, SearchbarQueryVariables>;
export const TaskAssigneeSelectorDocument = gql`
    query TaskAssigneeSelector($boardId: ID!) {
  taskBoard(id: $boardId) {
    users {
      ...TaskModal_User
    }
  }
}
    ${TaskModal_UserFragmentDoc}`;

/**
 * __useTaskAssigneeSelectorQuery__
 *
 * To run a query within a React component, call `useTaskAssigneeSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskAssigneeSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskAssigneeSelectorQuery({
 *   variables: {
 *      boardId: // value for 'boardId'
 *   },
 * });
 */
export function useTaskAssigneeSelectorQuery(baseOptions: Apollo.QueryHookOptions<TaskAssigneeSelectorQuery, TaskAssigneeSelectorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskAssigneeSelectorQuery, TaskAssigneeSelectorQueryVariables>(TaskAssigneeSelectorDocument, options);
      }
export function useTaskAssigneeSelectorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskAssigneeSelectorQuery, TaskAssigneeSelectorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskAssigneeSelectorQuery, TaskAssigneeSelectorQueryVariables>(TaskAssigneeSelectorDocument, options);
        }
export type TaskAssigneeSelectorQueryHookResult = ReturnType<typeof useTaskAssigneeSelectorQuery>;
export type TaskAssigneeSelectorLazyQueryHookResult = ReturnType<typeof useTaskAssigneeSelectorLazyQuery>;
export type TaskAssigneeSelectorQueryResult = Apollo.QueryResult<TaskAssigneeSelectorQuery, TaskAssigneeSelectorQueryVariables>;
export const TaskAssigneeSelector_ToggleUserDocument = gql`
    mutation TaskAssigneeSelector_ToggleUser($taskId: ID!, $assigneeId: ID!) {
  toggleTaskAssignee(taskId: $taskId, assigneeId: $assigneeId) {
    ...TaskModal_Task
  }
}
    ${TaskModal_TaskFragmentDoc}`;
export type TaskAssigneeSelector_ToggleUserMutationFn = Apollo.MutationFunction<TaskAssigneeSelector_ToggleUserMutation, TaskAssigneeSelector_ToggleUserMutationVariables>;

/**
 * __useTaskAssigneeSelector_ToggleUserMutation__
 *
 * To run a mutation, you first call `useTaskAssigneeSelector_ToggleUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskAssigneeSelector_ToggleUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskAssigneeSelectorToggleUserMutation, { data, loading, error }] = useTaskAssigneeSelector_ToggleUserMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      assigneeId: // value for 'assigneeId'
 *   },
 * });
 */
export function useTaskAssigneeSelector_ToggleUserMutation(baseOptions?: Apollo.MutationHookOptions<TaskAssigneeSelector_ToggleUserMutation, TaskAssigneeSelector_ToggleUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TaskAssigneeSelector_ToggleUserMutation, TaskAssigneeSelector_ToggleUserMutationVariables>(TaskAssigneeSelector_ToggleUserDocument, options);
      }
export type TaskAssigneeSelector_ToggleUserMutationHookResult = ReturnType<typeof useTaskAssigneeSelector_ToggleUserMutation>;
export type TaskAssigneeSelector_ToggleUserMutationResult = Apollo.MutationResult<TaskAssigneeSelector_ToggleUserMutation>;
export type TaskAssigneeSelector_ToggleUserMutationOptions = Apollo.BaseMutationOptions<TaskAssigneeSelector_ToggleUserMutation, TaskAssigneeSelector_ToggleUserMutationVariables>;
export const TaskBoardDocument = gql`
    query TaskBoard($idStr: String!) {
  activeSession {
    id
  }
  taskBoard(idStr: $idStr) {
    ...TaskBoard_TaskBoard
  }
}
    ${TaskBoard_TaskBoardFragmentDoc}`;

/**
 * __useTaskBoardQuery__
 *
 * To run a query within a React component, call `useTaskBoardQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskBoardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskBoardQuery({
 *   variables: {
 *      idStr: // value for 'idStr'
 *   },
 * });
 */
export function useTaskBoardQuery(baseOptions: Apollo.QueryHookOptions<TaskBoardQuery, TaskBoardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskBoardQuery, TaskBoardQueryVariables>(TaskBoardDocument, options);
      }
export function useTaskBoardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskBoardQuery, TaskBoardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskBoardQuery, TaskBoardQueryVariables>(TaskBoardDocument, options);
        }
export type TaskBoardQueryHookResult = ReturnType<typeof useTaskBoardQuery>;
export type TaskBoardLazyQueryHookResult = ReturnType<typeof useTaskBoardLazyQuery>;
export type TaskBoardQueryResult = Apollo.QueryResult<TaskBoardQuery, TaskBoardQueryVariables>;
export const TaskBoard_UpdateTaskBoardNameDocument = gql`
    mutation TaskBoard_UpdateTaskBoardName($id: ID!, $name: String!) {
  updateTaskBoardName(id: $id, name: $name) {
    id
    name
  }
}
    `;
export type TaskBoard_UpdateTaskBoardNameMutationFn = Apollo.MutationFunction<TaskBoard_UpdateTaskBoardNameMutation, TaskBoard_UpdateTaskBoardNameMutationVariables>;

/**
 * __useTaskBoard_UpdateTaskBoardNameMutation__
 *
 * To run a mutation, you first call `useTaskBoard_UpdateTaskBoardNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskBoard_UpdateTaskBoardNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskBoardUpdateTaskBoardNameMutation, { data, loading, error }] = useTaskBoard_UpdateTaskBoardNameMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useTaskBoard_UpdateTaskBoardNameMutation(baseOptions?: Apollo.MutationHookOptions<TaskBoard_UpdateTaskBoardNameMutation, TaskBoard_UpdateTaskBoardNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TaskBoard_UpdateTaskBoardNameMutation, TaskBoard_UpdateTaskBoardNameMutationVariables>(TaskBoard_UpdateTaskBoardNameDocument, options);
      }
export type TaskBoard_UpdateTaskBoardNameMutationHookResult = ReturnType<typeof useTaskBoard_UpdateTaskBoardNameMutation>;
export type TaskBoard_UpdateTaskBoardNameMutationResult = Apollo.MutationResult<TaskBoard_UpdateTaskBoardNameMutation>;
export type TaskBoard_UpdateTaskBoardNameMutationOptions = Apollo.BaseMutationOptions<TaskBoard_UpdateTaskBoardNameMutation, TaskBoard_UpdateTaskBoardNameMutationVariables>;
export const TaskBoard_TaskBoardUpdatedDocument = gql`
    subscription TaskBoard_TaskBoardUpdated($sessionId: ID!, $boardId: ID!) {
  taskBoardUpdated(sessionId: $sessionId, boardId: $boardId) {
    ...TaskBoard_TaskBoard
    states {
      ...BoardLane_TaskState
    }
  }
}
    ${TaskBoard_TaskBoardFragmentDoc}
${BoardLane_TaskStateFragmentDoc}`;

/**
 * __useTaskBoard_TaskBoardUpdatedSubscription__
 *
 * To run a query within a React component, call `useTaskBoard_TaskBoardUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTaskBoard_TaskBoardUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskBoard_TaskBoardUpdatedSubscription({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      boardId: // value for 'boardId'
 *   },
 * });
 */
export function useTaskBoard_TaskBoardUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<TaskBoard_TaskBoardUpdatedSubscription, TaskBoard_TaskBoardUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<TaskBoard_TaskBoardUpdatedSubscription, TaskBoard_TaskBoardUpdatedSubscriptionVariables>(TaskBoard_TaskBoardUpdatedDocument, options);
      }
export type TaskBoard_TaskBoardUpdatedSubscriptionHookResult = ReturnType<typeof useTaskBoard_TaskBoardUpdatedSubscription>;
export type TaskBoard_TaskBoardUpdatedSubscriptionResult = Apollo.SubscriptionResult<TaskBoard_TaskBoardUpdatedSubscription>;
export const TaskBoard_TaskStatesUpdatedDocument = gql`
    subscription TaskBoard_TaskStatesUpdated($sessionId: ID!, $boardId: ID!) {
  taskStatesUpdated(sessionId: $sessionId, boardId: $boardId) {
    ...BoardLane_TaskState
    tasks {
      ...TaskCard_Task
    }
  }
}
    ${BoardLane_TaskStateFragmentDoc}
${TaskCard_TaskFragmentDoc}`;

/**
 * __useTaskBoard_TaskStatesUpdatedSubscription__
 *
 * To run a query within a React component, call `useTaskBoard_TaskStatesUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTaskBoard_TaskStatesUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskBoard_TaskStatesUpdatedSubscription({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      boardId: // value for 'boardId'
 *   },
 * });
 */
export function useTaskBoard_TaskStatesUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<TaskBoard_TaskStatesUpdatedSubscription, TaskBoard_TaskStatesUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<TaskBoard_TaskStatesUpdatedSubscription, TaskBoard_TaskStatesUpdatedSubscriptionVariables>(TaskBoard_TaskStatesUpdatedDocument, options);
      }
export type TaskBoard_TaskStatesUpdatedSubscriptionHookResult = ReturnType<typeof useTaskBoard_TaskStatesUpdatedSubscription>;
export type TaskBoard_TaskStatesUpdatedSubscriptionResult = Apollo.SubscriptionResult<TaskBoard_TaskStatesUpdatedSubscription>;
export const TaskBoard_TaskUpdatedDocument = gql`
    subscription TaskBoard_TaskUpdated($sessionId: ID!, $boardId: ID!) {
  taskUpdated(sessionId: $sessionId, boardId: $boardId) {
    ...TaskCard_Task
  }
}
    ${TaskCard_TaskFragmentDoc}`;

/**
 * __useTaskBoard_TaskUpdatedSubscription__
 *
 * To run a query within a React component, call `useTaskBoard_TaskUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTaskBoard_TaskUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskBoard_TaskUpdatedSubscription({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      boardId: // value for 'boardId'
 *   },
 * });
 */
export function useTaskBoard_TaskUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<TaskBoard_TaskUpdatedSubscription, TaskBoard_TaskUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<TaskBoard_TaskUpdatedSubscription, TaskBoard_TaskUpdatedSubscriptionVariables>(TaskBoard_TaskUpdatedDocument, options);
      }
export type TaskBoard_TaskUpdatedSubscriptionHookResult = ReturnType<typeof useTaskBoard_TaskUpdatedSubscription>;
export type TaskBoard_TaskUpdatedSubscriptionResult = Apollo.SubscriptionResult<TaskBoard_TaskUpdatedSubscription>;
export const TaskBoardBoardViewDocument = gql`
    query TaskBoardBoardView($boardId: ID) {
  taskBoard(id: $boardId) {
    id
    states {
      ...BoardLane_TaskState
    }
  }
}
    ${BoardLane_TaskStateFragmentDoc}`;

/**
 * __useTaskBoardBoardViewQuery__
 *
 * To run a query within a React component, call `useTaskBoardBoardViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskBoardBoardViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskBoardBoardViewQuery({
 *   variables: {
 *      boardId: // value for 'boardId'
 *   },
 * });
 */
export function useTaskBoardBoardViewQuery(baseOptions?: Apollo.QueryHookOptions<TaskBoardBoardViewQuery, TaskBoardBoardViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskBoardBoardViewQuery, TaskBoardBoardViewQueryVariables>(TaskBoardBoardViewDocument, options);
      }
export function useTaskBoardBoardViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskBoardBoardViewQuery, TaskBoardBoardViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskBoardBoardViewQuery, TaskBoardBoardViewQueryVariables>(TaskBoardBoardViewDocument, options);
        }
export type TaskBoardBoardViewQueryHookResult = ReturnType<typeof useTaskBoardBoardViewQuery>;
export type TaskBoardBoardViewLazyQueryHookResult = ReturnType<typeof useTaskBoardBoardViewLazyQuery>;
export type TaskBoardBoardViewQueryResult = Apollo.QueryResult<TaskBoardBoardViewQuery, TaskBoardBoardViewQueryVariables>;
export const TaskBoardBoardView_CreateTaskStateDocument = gql`
    mutation TaskBoardBoardView_CreateTaskState($boardId: ID!, $name: String!, $color: String!) {
  createTaskState(boardId: $boardId, name: $name, color: $color) {
    id
    states {
      ...BoardLane_TaskState
    }
  }
}
    ${BoardLane_TaskStateFragmentDoc}`;
export type TaskBoardBoardView_CreateTaskStateMutationFn = Apollo.MutationFunction<TaskBoardBoardView_CreateTaskStateMutation, TaskBoardBoardView_CreateTaskStateMutationVariables>;

/**
 * __useTaskBoardBoardView_CreateTaskStateMutation__
 *
 * To run a mutation, you first call `useTaskBoardBoardView_CreateTaskStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskBoardBoardView_CreateTaskStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskBoardBoardViewCreateTaskStateMutation, { data, loading, error }] = useTaskBoardBoardView_CreateTaskStateMutation({
 *   variables: {
 *      boardId: // value for 'boardId'
 *      name: // value for 'name'
 *      color: // value for 'color'
 *   },
 * });
 */
export function useTaskBoardBoardView_CreateTaskStateMutation(baseOptions?: Apollo.MutationHookOptions<TaskBoardBoardView_CreateTaskStateMutation, TaskBoardBoardView_CreateTaskStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TaskBoardBoardView_CreateTaskStateMutation, TaskBoardBoardView_CreateTaskStateMutationVariables>(TaskBoardBoardView_CreateTaskStateDocument, options);
      }
export type TaskBoardBoardView_CreateTaskStateMutationHookResult = ReturnType<typeof useTaskBoardBoardView_CreateTaskStateMutation>;
export type TaskBoardBoardView_CreateTaskStateMutationResult = Apollo.MutationResult<TaskBoardBoardView_CreateTaskStateMutation>;
export type TaskBoardBoardView_CreateTaskStateMutationOptions = Apollo.BaseMutationOptions<TaskBoardBoardView_CreateTaskStateMutation, TaskBoardBoardView_CreateTaskStateMutationVariables>;
export const TaskBoardBoardView_ReOrderTaskDocument = gql`
    mutation TaskBoardBoardView_ReOrderTask($taskId: ID!, $toTask: ID!) {
  reorderTask(taskId: $taskId, toTask: $toTask, withinParent: false) {
    id
    stateOrder
  }
}
    `;
export type TaskBoardBoardView_ReOrderTaskMutationFn = Apollo.MutationFunction<TaskBoardBoardView_ReOrderTaskMutation, TaskBoardBoardView_ReOrderTaskMutationVariables>;

/**
 * __useTaskBoardBoardView_ReOrderTaskMutation__
 *
 * To run a mutation, you first call `useTaskBoardBoardView_ReOrderTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskBoardBoardView_ReOrderTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskBoardBoardViewReOrderTaskMutation, { data, loading, error }] = useTaskBoardBoardView_ReOrderTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      toTask: // value for 'toTask'
 *   },
 * });
 */
export function useTaskBoardBoardView_ReOrderTaskMutation(baseOptions?: Apollo.MutationHookOptions<TaskBoardBoardView_ReOrderTaskMutation, TaskBoardBoardView_ReOrderTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TaskBoardBoardView_ReOrderTaskMutation, TaskBoardBoardView_ReOrderTaskMutationVariables>(TaskBoardBoardView_ReOrderTaskDocument, options);
      }
export type TaskBoardBoardView_ReOrderTaskMutationHookResult = ReturnType<typeof useTaskBoardBoardView_ReOrderTaskMutation>;
export type TaskBoardBoardView_ReOrderTaskMutationResult = Apollo.MutationResult<TaskBoardBoardView_ReOrderTaskMutation>;
export type TaskBoardBoardView_ReOrderTaskMutationOptions = Apollo.BaseMutationOptions<TaskBoardBoardView_ReOrderTaskMutation, TaskBoardBoardView_ReOrderTaskMutationVariables>;
export const TaskBoardBoardView_ReOrderStateDocument = gql`
    mutation TaskBoardBoardView_ReOrderState($stateId: ID!, $toState: ID!) {
  reorderTaskState(stateId: $stateId, toState: $toState) {
    id
    order
  }
}
    `;
export type TaskBoardBoardView_ReOrderStateMutationFn = Apollo.MutationFunction<TaskBoardBoardView_ReOrderStateMutation, TaskBoardBoardView_ReOrderStateMutationVariables>;

/**
 * __useTaskBoardBoardView_ReOrderStateMutation__
 *
 * To run a mutation, you first call `useTaskBoardBoardView_ReOrderStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskBoardBoardView_ReOrderStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskBoardBoardViewReOrderStateMutation, { data, loading, error }] = useTaskBoardBoardView_ReOrderStateMutation({
 *   variables: {
 *      stateId: // value for 'stateId'
 *      toState: // value for 'toState'
 *   },
 * });
 */
export function useTaskBoardBoardView_ReOrderStateMutation(baseOptions?: Apollo.MutationHookOptions<TaskBoardBoardView_ReOrderStateMutation, TaskBoardBoardView_ReOrderStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TaskBoardBoardView_ReOrderStateMutation, TaskBoardBoardView_ReOrderStateMutationVariables>(TaskBoardBoardView_ReOrderStateDocument, options);
      }
export type TaskBoardBoardView_ReOrderStateMutationHookResult = ReturnType<typeof useTaskBoardBoardView_ReOrderStateMutation>;
export type TaskBoardBoardView_ReOrderStateMutationResult = Apollo.MutationResult<TaskBoardBoardView_ReOrderStateMutation>;
export type TaskBoardBoardView_ReOrderStateMutationOptions = Apollo.BaseMutationOptions<TaskBoardBoardView_ReOrderStateMutation, TaskBoardBoardView_ReOrderStateMutationVariables>;
export const ArchivedBoardLaneDocument = gql`
    query ArchivedBoardLane($boardId: ID!) {
  taskBoard(id: $boardId) {
    archivedTasks {
      ...TaskCard_Task
    }
  }
}
    ${TaskCard_TaskFragmentDoc}`;

/**
 * __useArchivedBoardLaneQuery__
 *
 * To run a query within a React component, call `useArchivedBoardLaneQuery` and pass it any options that fit your needs.
 * When your component renders, `useArchivedBoardLaneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArchivedBoardLaneQuery({
 *   variables: {
 *      boardId: // value for 'boardId'
 *   },
 * });
 */
export function useArchivedBoardLaneQuery(baseOptions: Apollo.QueryHookOptions<ArchivedBoardLaneQuery, ArchivedBoardLaneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArchivedBoardLaneQuery, ArchivedBoardLaneQueryVariables>(ArchivedBoardLaneDocument, options);
      }
export function useArchivedBoardLaneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArchivedBoardLaneQuery, ArchivedBoardLaneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArchivedBoardLaneQuery, ArchivedBoardLaneQueryVariables>(ArchivedBoardLaneDocument, options);
        }
export type ArchivedBoardLaneQueryHookResult = ReturnType<typeof useArchivedBoardLaneQuery>;
export type ArchivedBoardLaneLazyQueryHookResult = ReturnType<typeof useArchivedBoardLaneLazyQuery>;
export type ArchivedBoardLaneQueryResult = Apollo.QueryResult<ArchivedBoardLaneQuery, ArchivedBoardLaneQueryVariables>;
export const BoardLaneDocument = gql`
    query BoardLane($id: ID!) {
  taskState(id: $id) {
    ...BoardLane_TaskState
    tasks {
      ...TaskCard_Task
    }
  }
}
    ${BoardLane_TaskStateFragmentDoc}
${TaskCard_TaskFragmentDoc}`;

/**
 * __useBoardLaneQuery__
 *
 * To run a query within a React component, call `useBoardLaneQuery` and pass it any options that fit your needs.
 * When your component renders, `useBoardLaneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBoardLaneQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBoardLaneQuery(baseOptions: Apollo.QueryHookOptions<BoardLaneQuery, BoardLaneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BoardLaneQuery, BoardLaneQueryVariables>(BoardLaneDocument, options);
      }
export function useBoardLaneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BoardLaneQuery, BoardLaneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BoardLaneQuery, BoardLaneQueryVariables>(BoardLaneDocument, options);
        }
export type BoardLaneQueryHookResult = ReturnType<typeof useBoardLaneQuery>;
export type BoardLaneLazyQueryHookResult = ReturnType<typeof useBoardLaneLazyQuery>;
export type BoardLaneQueryResult = Apollo.QueryResult<BoardLaneQuery, BoardLaneQueryVariables>;
export const BoardLane_UpdateTaskStateDocument = gql`
    mutation BoardLane_UpdateTaskState($id: ID!, $name: String!, $color: String!) {
  updateTaskState(stateId: $id, name: $name, color: $color) {
    id
    name
    color
  }
}
    `;
export type BoardLane_UpdateTaskStateMutationFn = Apollo.MutationFunction<BoardLane_UpdateTaskStateMutation, BoardLane_UpdateTaskStateMutationVariables>;

/**
 * __useBoardLane_UpdateTaskStateMutation__
 *
 * To run a mutation, you first call `useBoardLane_UpdateTaskStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBoardLane_UpdateTaskStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [boardLaneUpdateTaskStateMutation, { data, loading, error }] = useBoardLane_UpdateTaskStateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      color: // value for 'color'
 *   },
 * });
 */
export function useBoardLane_UpdateTaskStateMutation(baseOptions?: Apollo.MutationHookOptions<BoardLane_UpdateTaskStateMutation, BoardLane_UpdateTaskStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BoardLane_UpdateTaskStateMutation, BoardLane_UpdateTaskStateMutationVariables>(BoardLane_UpdateTaskStateDocument, options);
      }
export type BoardLane_UpdateTaskStateMutationHookResult = ReturnType<typeof useBoardLane_UpdateTaskStateMutation>;
export type BoardLane_UpdateTaskStateMutationResult = Apollo.MutationResult<BoardLane_UpdateTaskStateMutation>;
export type BoardLane_UpdateTaskStateMutationOptions = Apollo.BaseMutationOptions<BoardLane_UpdateTaskStateMutation, BoardLane_UpdateTaskStateMutationVariables>;
export const BoardLane_CreateTaskDocument = gql`
    mutation BoardLane_CreateTask($stateId: ID!, $title: String!, $description: String, $parentId: ID) {
  createTask(
    stateId: $stateId
    title: $title
    description: $description
    parentId: $parentId
  ) {
    task {
      ...TaskCard_Task
    }
  }
}
    ${TaskCard_TaskFragmentDoc}`;
export type BoardLane_CreateTaskMutationFn = Apollo.MutationFunction<BoardLane_CreateTaskMutation, BoardLane_CreateTaskMutationVariables>;

/**
 * __useBoardLane_CreateTaskMutation__
 *
 * To run a mutation, you first call `useBoardLane_CreateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBoardLane_CreateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [boardLaneCreateTaskMutation, { data, loading, error }] = useBoardLane_CreateTaskMutation({
 *   variables: {
 *      stateId: // value for 'stateId'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useBoardLane_CreateTaskMutation(baseOptions?: Apollo.MutationHookOptions<BoardLane_CreateTaskMutation, BoardLane_CreateTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BoardLane_CreateTaskMutation, BoardLane_CreateTaskMutationVariables>(BoardLane_CreateTaskDocument, options);
      }
export type BoardLane_CreateTaskMutationHookResult = ReturnType<typeof useBoardLane_CreateTaskMutation>;
export type BoardLane_CreateTaskMutationResult = Apollo.MutationResult<BoardLane_CreateTaskMutation>;
export type BoardLane_CreateTaskMutationOptions = Apollo.BaseMutationOptions<BoardLane_CreateTaskMutation, BoardLane_CreateTaskMutationVariables>;
export const BoardLane_ArchiveTaskDocument = gql`
    mutation BoardLane_ArchiveTask($taskId: ID!, $stateId: ID!) {
  archiveTask(taskId: $taskId) {
    taskState(id: $stateId) {
      tasks {
        ...TaskCard_Task
      }
    }
  }
}
    ${TaskCard_TaskFragmentDoc}`;
export type BoardLane_ArchiveTaskMutationFn = Apollo.MutationFunction<BoardLane_ArchiveTaskMutation, BoardLane_ArchiveTaskMutationVariables>;

/**
 * __useBoardLane_ArchiveTaskMutation__
 *
 * To run a mutation, you first call `useBoardLane_ArchiveTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBoardLane_ArchiveTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [boardLaneArchiveTaskMutation, { data, loading, error }] = useBoardLane_ArchiveTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      stateId: // value for 'stateId'
 *   },
 * });
 */
export function useBoardLane_ArchiveTaskMutation(baseOptions?: Apollo.MutationHookOptions<BoardLane_ArchiveTaskMutation, BoardLane_ArchiveTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BoardLane_ArchiveTaskMutation, BoardLane_ArchiveTaskMutationVariables>(BoardLane_ArchiveTaskDocument, options);
      }
export type BoardLane_ArchiveTaskMutationHookResult = ReturnType<typeof useBoardLane_ArchiveTaskMutation>;
export type BoardLane_ArchiveTaskMutationResult = Apollo.MutationResult<BoardLane_ArchiveTaskMutation>;
export type BoardLane_ArchiveTaskMutationOptions = Apollo.BaseMutationOptions<BoardLane_ArchiveTaskMutation, BoardLane_ArchiveTaskMutationVariables>;
export const BoardLane_ToggleCompletedStateDocument = gql`
    mutation BoardLane_ToggleCompletedState($stateId: ID!, $boardId: ID!) {
  toggleCompletedState(stateId: $stateId) {
    taskBoard(id: $boardId) {
      id
      states {
        ...BoardLane_TaskState
        tasks {
          ...TaskCard_Task
        }
      }
    }
  }
}
    ${BoardLane_TaskStateFragmentDoc}
${TaskCard_TaskFragmentDoc}`;
export type BoardLane_ToggleCompletedStateMutationFn = Apollo.MutationFunction<BoardLane_ToggleCompletedStateMutation, BoardLane_ToggleCompletedStateMutationVariables>;

/**
 * __useBoardLane_ToggleCompletedStateMutation__
 *
 * To run a mutation, you first call `useBoardLane_ToggleCompletedStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBoardLane_ToggleCompletedStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [boardLaneToggleCompletedStateMutation, { data, loading, error }] = useBoardLane_ToggleCompletedStateMutation({
 *   variables: {
 *      stateId: // value for 'stateId'
 *      boardId: // value for 'boardId'
 *   },
 * });
 */
export function useBoardLane_ToggleCompletedStateMutation(baseOptions?: Apollo.MutationHookOptions<BoardLane_ToggleCompletedStateMutation, BoardLane_ToggleCompletedStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BoardLane_ToggleCompletedStateMutation, BoardLane_ToggleCompletedStateMutationVariables>(BoardLane_ToggleCompletedStateDocument, options);
      }
export type BoardLane_ToggleCompletedStateMutationHookResult = ReturnType<typeof useBoardLane_ToggleCompletedStateMutation>;
export type BoardLane_ToggleCompletedStateMutationResult = Apollo.MutationResult<BoardLane_ToggleCompletedStateMutation>;
export type BoardLane_ToggleCompletedStateMutationOptions = Apollo.BaseMutationOptions<BoardLane_ToggleCompletedStateMutation, BoardLane_ToggleCompletedStateMutationVariables>;
export const BoardLane_ArchiveTaskStateDocument = gql`
    mutation BoardLane_ArchiveTaskState($stateId: ID!, $boardId: ID!) {
  archiveTaskState(stateId: $stateId) {
    taskBoard(id: $boardId) {
      id
      states {
        ...BoardLane_TaskState
        tasks {
          ...TaskCard_Task
        }
      }
    }
  }
}
    ${BoardLane_TaskStateFragmentDoc}
${TaskCard_TaskFragmentDoc}`;
export type BoardLane_ArchiveTaskStateMutationFn = Apollo.MutationFunction<BoardLane_ArchiveTaskStateMutation, BoardLane_ArchiveTaskStateMutationVariables>;

/**
 * __useBoardLane_ArchiveTaskStateMutation__
 *
 * To run a mutation, you first call `useBoardLane_ArchiveTaskStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBoardLane_ArchiveTaskStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [boardLaneArchiveTaskStateMutation, { data, loading, error }] = useBoardLane_ArchiveTaskStateMutation({
 *   variables: {
 *      stateId: // value for 'stateId'
 *      boardId: // value for 'boardId'
 *   },
 * });
 */
export function useBoardLane_ArchiveTaskStateMutation(baseOptions?: Apollo.MutationHookOptions<BoardLane_ArchiveTaskStateMutation, BoardLane_ArchiveTaskStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BoardLane_ArchiveTaskStateMutation, BoardLane_ArchiveTaskStateMutationVariables>(BoardLane_ArchiveTaskStateDocument, options);
      }
export type BoardLane_ArchiveTaskStateMutationHookResult = ReturnType<typeof useBoardLane_ArchiveTaskStateMutation>;
export type BoardLane_ArchiveTaskStateMutationResult = Apollo.MutationResult<BoardLane_ArchiveTaskStateMutation>;
export type BoardLane_ArchiveTaskStateMutationOptions = Apollo.BaseMutationOptions<BoardLane_ArchiveTaskStateMutation, BoardLane_ArchiveTaskStateMutationVariables>;
export const TaskBoardFiltersSelectorDocument = gql`
    query TaskBoardFiltersSelector($boardId: ID!) {
  taskBoard(id: $boardId) {
    id
    users {
      ...TaskModal_User
    }
    states {
      ...BoardLane_TaskState
    }
  }
}
    ${TaskModal_UserFragmentDoc}
${BoardLane_TaskStateFragmentDoc}`;

/**
 * __useTaskBoardFiltersSelectorQuery__
 *
 * To run a query within a React component, call `useTaskBoardFiltersSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskBoardFiltersSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskBoardFiltersSelectorQuery({
 *   variables: {
 *      boardId: // value for 'boardId'
 *   },
 * });
 */
export function useTaskBoardFiltersSelectorQuery(baseOptions: Apollo.QueryHookOptions<TaskBoardFiltersSelectorQuery, TaskBoardFiltersSelectorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskBoardFiltersSelectorQuery, TaskBoardFiltersSelectorQueryVariables>(TaskBoardFiltersSelectorDocument, options);
      }
export function useTaskBoardFiltersSelectorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskBoardFiltersSelectorQuery, TaskBoardFiltersSelectorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskBoardFiltersSelectorQuery, TaskBoardFiltersSelectorQueryVariables>(TaskBoardFiltersSelectorDocument, options);
        }
export type TaskBoardFiltersSelectorQueryHookResult = ReturnType<typeof useTaskBoardFiltersSelectorQuery>;
export type TaskBoardFiltersSelectorLazyQueryHookResult = ReturnType<typeof useTaskBoardFiltersSelectorLazyQuery>;
export type TaskBoardFiltersSelectorQueryResult = Apollo.QueryResult<TaskBoardFiltersSelectorQuery, TaskBoardFiltersSelectorQueryVariables>;
export const TaskBoardModalDocument = gql`
    query TaskBoardModal($id: ID!) {
  taskBoard(id: $id) {
    name
    description
    teams {
      id
      name
    }
    users {
      id
      fullName
    }
  }
}
    `;

/**
 * __useTaskBoardModalQuery__
 *
 * To run a query within a React component, call `useTaskBoardModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskBoardModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskBoardModalQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTaskBoardModalQuery(baseOptions: Apollo.QueryHookOptions<TaskBoardModalQuery, TaskBoardModalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskBoardModalQuery, TaskBoardModalQueryVariables>(TaskBoardModalDocument, options);
      }
export function useTaskBoardModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskBoardModalQuery, TaskBoardModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskBoardModalQuery, TaskBoardModalQueryVariables>(TaskBoardModalDocument, options);
        }
export type TaskBoardModalQueryHookResult = ReturnType<typeof useTaskBoardModalQuery>;
export type TaskBoardModalLazyQueryHookResult = ReturnType<typeof useTaskBoardModalLazyQuery>;
export type TaskBoardModalQueryResult = Apollo.QueryResult<TaskBoardModalQuery, TaskBoardModalQueryVariables>;
export const TaskBoardModal_CreateTaskBoardDocument = gql`
    mutation TaskBoardModal_CreateTaskBoard($name: String!, $description: String!, $states: [TaskStateInput]!, $teams: [ID!]!, $users: [ID!]!) {
  createTaskBoard(
    name: $name
    description: $description
    states: $states
    teams: $teams
    users: $users
  ) {
    query {
      taskBoards {
        ...TaskHome_TaskBoard
      }
    }
    created {
      idStr
    }
  }
}
    ${TaskHome_TaskBoardFragmentDoc}`;
export type TaskBoardModal_CreateTaskBoardMutationFn = Apollo.MutationFunction<TaskBoardModal_CreateTaskBoardMutation, TaskBoardModal_CreateTaskBoardMutationVariables>;

/**
 * __useTaskBoardModal_CreateTaskBoardMutation__
 *
 * To run a mutation, you first call `useTaskBoardModal_CreateTaskBoardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskBoardModal_CreateTaskBoardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskBoardModalCreateTaskBoardMutation, { data, loading, error }] = useTaskBoardModal_CreateTaskBoardMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      states: // value for 'states'
 *      teams: // value for 'teams'
 *      users: // value for 'users'
 *   },
 * });
 */
export function useTaskBoardModal_CreateTaskBoardMutation(baseOptions?: Apollo.MutationHookOptions<TaskBoardModal_CreateTaskBoardMutation, TaskBoardModal_CreateTaskBoardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TaskBoardModal_CreateTaskBoardMutation, TaskBoardModal_CreateTaskBoardMutationVariables>(TaskBoardModal_CreateTaskBoardDocument, options);
      }
export type TaskBoardModal_CreateTaskBoardMutationHookResult = ReturnType<typeof useTaskBoardModal_CreateTaskBoardMutation>;
export type TaskBoardModal_CreateTaskBoardMutationResult = Apollo.MutationResult<TaskBoardModal_CreateTaskBoardMutation>;
export type TaskBoardModal_CreateTaskBoardMutationOptions = Apollo.BaseMutationOptions<TaskBoardModal_CreateTaskBoardMutation, TaskBoardModal_CreateTaskBoardMutationVariables>;
export const TaskBoardModal_UpdateTaskBoardDocument = gql`
    mutation TaskBoardModal_UpdateTaskBoard($id: ID!, $name: String!, $description: String!, $states: [TaskStateInput]!, $teams: [ID!]!, $users: [ID!]!) {
  updateTaskBoard(
    id: $id
    name: $name
    description: $description
    states: $states
    teams: $teams
    users: $users
  ) {
    ...TaskHome_TaskBoard
  }
}
    ${TaskHome_TaskBoardFragmentDoc}`;
export type TaskBoardModal_UpdateTaskBoardMutationFn = Apollo.MutationFunction<TaskBoardModal_UpdateTaskBoardMutation, TaskBoardModal_UpdateTaskBoardMutationVariables>;

/**
 * __useTaskBoardModal_UpdateTaskBoardMutation__
 *
 * To run a mutation, you first call `useTaskBoardModal_UpdateTaskBoardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskBoardModal_UpdateTaskBoardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskBoardModalUpdateTaskBoardMutation, { data, loading, error }] = useTaskBoardModal_UpdateTaskBoardMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      states: // value for 'states'
 *      teams: // value for 'teams'
 *      users: // value for 'users'
 *   },
 * });
 */
export function useTaskBoardModal_UpdateTaskBoardMutation(baseOptions?: Apollo.MutationHookOptions<TaskBoardModal_UpdateTaskBoardMutation, TaskBoardModal_UpdateTaskBoardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TaskBoardModal_UpdateTaskBoardMutation, TaskBoardModal_UpdateTaskBoardMutationVariables>(TaskBoardModal_UpdateTaskBoardDocument, options);
      }
export type TaskBoardModal_UpdateTaskBoardMutationHookResult = ReturnType<typeof useTaskBoardModal_UpdateTaskBoardMutation>;
export type TaskBoardModal_UpdateTaskBoardMutationResult = Apollo.MutationResult<TaskBoardModal_UpdateTaskBoardMutation>;
export type TaskBoardModal_UpdateTaskBoardMutationOptions = Apollo.BaseMutationOptions<TaskBoardModal_UpdateTaskBoardMutation, TaskBoardModal_UpdateTaskBoardMutationVariables>;
export const TaskBoardTreeViewDocument = gql`
    query TaskBoardTreeView($boardId: ID!) {
  taskBoard(id: $boardId) {
    id
    states {
      ...TaskStateSwitch_TaskState
    }
    rootTasks {
      ...TaskBoardTreeView_Task
    }
  }
}
    ${TaskStateSwitch_TaskStateFragmentDoc}
${TaskBoardTreeView_TaskFragmentDoc}`;

/**
 * __useTaskBoardTreeViewQuery__
 *
 * To run a query within a React component, call `useTaskBoardTreeViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskBoardTreeViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskBoardTreeViewQuery({
 *   variables: {
 *      boardId: // value for 'boardId'
 *   },
 * });
 */
export function useTaskBoardTreeViewQuery(baseOptions: Apollo.QueryHookOptions<TaskBoardTreeViewQuery, TaskBoardTreeViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskBoardTreeViewQuery, TaskBoardTreeViewQueryVariables>(TaskBoardTreeViewDocument, options);
      }
export function useTaskBoardTreeViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskBoardTreeViewQuery, TaskBoardTreeViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskBoardTreeViewQuery, TaskBoardTreeViewQueryVariables>(TaskBoardTreeViewDocument, options);
        }
export type TaskBoardTreeViewQueryHookResult = ReturnType<typeof useTaskBoardTreeViewQuery>;
export type TaskBoardTreeViewLazyQueryHookResult = ReturnType<typeof useTaskBoardTreeViewLazyQuery>;
export type TaskBoardTreeViewQueryResult = Apollo.QueryResult<TaskBoardTreeViewQuery, TaskBoardTreeViewQueryVariables>;
export const TaskBoardTreeView_TaskDocument = gql`
    query TaskBoardTreeView_Task($taskId: ID!) {
  task(id: $taskId) {
    id
    progress {
      total
      completed
    }
    children {
      ...TaskBoardTreeView_Task
    }
  }
}
    ${TaskBoardTreeView_TaskFragmentDoc}`;

/**
 * __useTaskBoardTreeView_TaskQuery__
 *
 * To run a query within a React component, call `useTaskBoardTreeView_TaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskBoardTreeView_TaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskBoardTreeView_TaskQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTaskBoardTreeView_TaskQuery(baseOptions: Apollo.QueryHookOptions<TaskBoardTreeView_TaskQuery, TaskBoardTreeView_TaskQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskBoardTreeView_TaskQuery, TaskBoardTreeView_TaskQueryVariables>(TaskBoardTreeView_TaskDocument, options);
      }
export function useTaskBoardTreeView_TaskLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskBoardTreeView_TaskQuery, TaskBoardTreeView_TaskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskBoardTreeView_TaskQuery, TaskBoardTreeView_TaskQueryVariables>(TaskBoardTreeView_TaskDocument, options);
        }
export type TaskBoardTreeView_TaskQueryHookResult = ReturnType<typeof useTaskBoardTreeView_TaskQuery>;
export type TaskBoardTreeView_TaskLazyQueryHookResult = ReturnType<typeof useTaskBoardTreeView_TaskLazyQuery>;
export type TaskBoardTreeView_TaskQueryResult = Apollo.QueryResult<TaskBoardTreeView_TaskQuery, TaskBoardTreeView_TaskQueryVariables>;
export const TaskBoardTreeView_MoveTaskToStateDocument = gql`
    mutation TaskBoardTreeView_MoveTaskToState($fromStateId: ID!, $toStateId: ID!, $taskId: ID!, $toTask: ID) {
  moveTaskToState(taskId: $taskId, stateId: $toStateId, toTask: $toTask) {
    task {
      id
      stateOrder
      progress {
        total
        completed
      }
      state {
        id
        isComplete
        color
        name
      }
    }
    updatedTasks {
      id
      stateOrder
    }
    query {
      fromState: taskState(id: $fromStateId) {
        ...BoardLane_TaskState
        tasks {
          ...TaskCard_Task
        }
      }
      toState: taskState(id: $toStateId) {
        ...BoardLane_TaskState
        tasks {
          ...TaskCard_Task
        }
      }
    }
  }
}
    ${BoardLane_TaskStateFragmentDoc}
${TaskCard_TaskFragmentDoc}`;
export type TaskBoardTreeView_MoveTaskToStateMutationFn = Apollo.MutationFunction<TaskBoardTreeView_MoveTaskToStateMutation, TaskBoardTreeView_MoveTaskToStateMutationVariables>;

/**
 * __useTaskBoardTreeView_MoveTaskToStateMutation__
 *
 * To run a mutation, you first call `useTaskBoardTreeView_MoveTaskToStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskBoardTreeView_MoveTaskToStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskBoardTreeViewMoveTaskToStateMutation, { data, loading, error }] = useTaskBoardTreeView_MoveTaskToStateMutation({
 *   variables: {
 *      fromStateId: // value for 'fromStateId'
 *      toStateId: // value for 'toStateId'
 *      taskId: // value for 'taskId'
 *      toTask: // value for 'toTask'
 *   },
 * });
 */
export function useTaskBoardTreeView_MoveTaskToStateMutation(baseOptions?: Apollo.MutationHookOptions<TaskBoardTreeView_MoveTaskToStateMutation, TaskBoardTreeView_MoveTaskToStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TaskBoardTreeView_MoveTaskToStateMutation, TaskBoardTreeView_MoveTaskToStateMutationVariables>(TaskBoardTreeView_MoveTaskToStateDocument, options);
      }
export type TaskBoardTreeView_MoveTaskToStateMutationHookResult = ReturnType<typeof useTaskBoardTreeView_MoveTaskToStateMutation>;
export type TaskBoardTreeView_MoveTaskToStateMutationResult = Apollo.MutationResult<TaskBoardTreeView_MoveTaskToStateMutation>;
export type TaskBoardTreeView_MoveTaskToStateMutationOptions = Apollo.BaseMutationOptions<TaskBoardTreeView_MoveTaskToStateMutation, TaskBoardTreeView_MoveTaskToStateMutationVariables>;
export const TaskBoardTreeView_CreateTaskDocument = gql`
    mutation TaskBoardTreeView_CreateTask($stateId: ID!, $title: String!, $description: String, $parentId: ID, $boardId: ID!) {
  createTask(
    stateId: $stateId
    title: $title
    description: $description
    parentId: $parentId
  ) {
    query {
      taskBoard(id: $boardId) {
        id
        rootTasks {
          ...TaskBoardTreeView_Task
        }
      }
    }
  }
}
    ${TaskBoardTreeView_TaskFragmentDoc}`;
export type TaskBoardTreeView_CreateTaskMutationFn = Apollo.MutationFunction<TaskBoardTreeView_CreateTaskMutation, TaskBoardTreeView_CreateTaskMutationVariables>;

/**
 * __useTaskBoardTreeView_CreateTaskMutation__
 *
 * To run a mutation, you first call `useTaskBoardTreeView_CreateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskBoardTreeView_CreateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskBoardTreeViewCreateTaskMutation, { data, loading, error }] = useTaskBoardTreeView_CreateTaskMutation({
 *   variables: {
 *      stateId: // value for 'stateId'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      parentId: // value for 'parentId'
 *      boardId: // value for 'boardId'
 *   },
 * });
 */
export function useTaskBoardTreeView_CreateTaskMutation(baseOptions?: Apollo.MutationHookOptions<TaskBoardTreeView_CreateTaskMutation, TaskBoardTreeView_CreateTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TaskBoardTreeView_CreateTaskMutation, TaskBoardTreeView_CreateTaskMutationVariables>(TaskBoardTreeView_CreateTaskDocument, options);
      }
export type TaskBoardTreeView_CreateTaskMutationHookResult = ReturnType<typeof useTaskBoardTreeView_CreateTaskMutation>;
export type TaskBoardTreeView_CreateTaskMutationResult = Apollo.MutationResult<TaskBoardTreeView_CreateTaskMutation>;
export type TaskBoardTreeView_CreateTaskMutationOptions = Apollo.BaseMutationOptions<TaskBoardTreeView_CreateTaskMutation, TaskBoardTreeView_CreateTaskMutationVariables>;
export const TaskBoardTreeView_CreateChildTaskDocument = gql`
    mutation TaskBoardTreeView_CreateChildTask($stateId: ID!, $title: String!, $description: String, $parentId: ID, $boardId: ID!) {
  createTask(
    stateId: $stateId
    title: $title
    description: $description
    parentId: $parentId
  ) {
    query {
      taskBoard(id: $boardId) {
        id
        rootTasks {
          ...TaskBoardTreeView_Task
        }
      }
      task(id: $parentId) {
        ...TaskBoardTreeView_Task
        children {
          ...TaskBoardTreeView_Task
        }
      }
    }
  }
}
    ${TaskBoardTreeView_TaskFragmentDoc}`;
export type TaskBoardTreeView_CreateChildTaskMutationFn = Apollo.MutationFunction<TaskBoardTreeView_CreateChildTaskMutation, TaskBoardTreeView_CreateChildTaskMutationVariables>;

/**
 * __useTaskBoardTreeView_CreateChildTaskMutation__
 *
 * To run a mutation, you first call `useTaskBoardTreeView_CreateChildTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskBoardTreeView_CreateChildTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskBoardTreeViewCreateChildTaskMutation, { data, loading, error }] = useTaskBoardTreeView_CreateChildTaskMutation({
 *   variables: {
 *      stateId: // value for 'stateId'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      parentId: // value for 'parentId'
 *      boardId: // value for 'boardId'
 *   },
 * });
 */
export function useTaskBoardTreeView_CreateChildTaskMutation(baseOptions?: Apollo.MutationHookOptions<TaskBoardTreeView_CreateChildTaskMutation, TaskBoardTreeView_CreateChildTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TaskBoardTreeView_CreateChildTaskMutation, TaskBoardTreeView_CreateChildTaskMutationVariables>(TaskBoardTreeView_CreateChildTaskDocument, options);
      }
export type TaskBoardTreeView_CreateChildTaskMutationHookResult = ReturnType<typeof useTaskBoardTreeView_CreateChildTaskMutation>;
export type TaskBoardTreeView_CreateChildTaskMutationResult = Apollo.MutationResult<TaskBoardTreeView_CreateChildTaskMutation>;
export type TaskBoardTreeView_CreateChildTaskMutationOptions = Apollo.BaseMutationOptions<TaskBoardTreeView_CreateChildTaskMutation, TaskBoardTreeView_CreateChildTaskMutationVariables>;
export const TaskBoardTreeView_MoveTaskToTaskDocument = gql`
    mutation TaskBoardTreeView_MoveTaskToTask($from: ID!, $to: ID!, $mode: String!, $boardId: ID!) {
  moveTaskToTask(from: $from, to: $to, mode: $mode) {
    updatedTasks {
      ...TaskBoardTreeView_Task
      children {
        ...TaskBoardTreeView_Task
      }
    }
    query {
      taskBoard(id: $boardId) {
        id
        rootTasks {
          ...TaskBoardTreeView_Task
        }
      }
    }
  }
}
    ${TaskBoardTreeView_TaskFragmentDoc}`;
export type TaskBoardTreeView_MoveTaskToTaskMutationFn = Apollo.MutationFunction<TaskBoardTreeView_MoveTaskToTaskMutation, TaskBoardTreeView_MoveTaskToTaskMutationVariables>;

/**
 * __useTaskBoardTreeView_MoveTaskToTaskMutation__
 *
 * To run a mutation, you first call `useTaskBoardTreeView_MoveTaskToTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskBoardTreeView_MoveTaskToTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskBoardTreeViewMoveTaskToTaskMutation, { data, loading, error }] = useTaskBoardTreeView_MoveTaskToTaskMutation({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      mode: // value for 'mode'
 *      boardId: // value for 'boardId'
 *   },
 * });
 */
export function useTaskBoardTreeView_MoveTaskToTaskMutation(baseOptions?: Apollo.MutationHookOptions<TaskBoardTreeView_MoveTaskToTaskMutation, TaskBoardTreeView_MoveTaskToTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TaskBoardTreeView_MoveTaskToTaskMutation, TaskBoardTreeView_MoveTaskToTaskMutationVariables>(TaskBoardTreeView_MoveTaskToTaskDocument, options);
      }
export type TaskBoardTreeView_MoveTaskToTaskMutationHookResult = ReturnType<typeof useTaskBoardTreeView_MoveTaskToTaskMutation>;
export type TaskBoardTreeView_MoveTaskToTaskMutationResult = Apollo.MutationResult<TaskBoardTreeView_MoveTaskToTaskMutation>;
export type TaskBoardTreeView_MoveTaskToTaskMutationOptions = Apollo.BaseMutationOptions<TaskBoardTreeView_MoveTaskToTaskMutation, TaskBoardTreeView_MoveTaskToTaskMutationVariables>;
export const TaskBoardTreeView_ArchiveChildTaskDocument = gql`
    mutation TaskBoardTreeView_ArchiveChildTask($taskId: ID!, $parentId: ID!) {
  archiveTask(taskId: $taskId) {
    task(id: $parentId) {
      ...TaskBoardTreeView_Task
      children {
        ...TaskBoardTreeView_Task
      }
    }
  }
}
    ${TaskBoardTreeView_TaskFragmentDoc}`;
export type TaskBoardTreeView_ArchiveChildTaskMutationFn = Apollo.MutationFunction<TaskBoardTreeView_ArchiveChildTaskMutation, TaskBoardTreeView_ArchiveChildTaskMutationVariables>;

/**
 * __useTaskBoardTreeView_ArchiveChildTaskMutation__
 *
 * To run a mutation, you first call `useTaskBoardTreeView_ArchiveChildTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskBoardTreeView_ArchiveChildTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskBoardTreeViewArchiveChildTaskMutation, { data, loading, error }] = useTaskBoardTreeView_ArchiveChildTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useTaskBoardTreeView_ArchiveChildTaskMutation(baseOptions?: Apollo.MutationHookOptions<TaskBoardTreeView_ArchiveChildTaskMutation, TaskBoardTreeView_ArchiveChildTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TaskBoardTreeView_ArchiveChildTaskMutation, TaskBoardTreeView_ArchiveChildTaskMutationVariables>(TaskBoardTreeView_ArchiveChildTaskDocument, options);
      }
export type TaskBoardTreeView_ArchiveChildTaskMutationHookResult = ReturnType<typeof useTaskBoardTreeView_ArchiveChildTaskMutation>;
export type TaskBoardTreeView_ArchiveChildTaskMutationResult = Apollo.MutationResult<TaskBoardTreeView_ArchiveChildTaskMutation>;
export type TaskBoardTreeView_ArchiveChildTaskMutationOptions = Apollo.BaseMutationOptions<TaskBoardTreeView_ArchiveChildTaskMutation, TaskBoardTreeView_ArchiveChildTaskMutationVariables>;
export const TaskBoardTreeView_ArchiveTaskDocument = gql`
    mutation TaskBoardTreeView_ArchiveTask($taskId: ID!, $boardId: ID!) {
  archiveTask(taskId: $taskId) {
    taskBoard(id: $boardId) {
      id
      rootTasks {
        ...TaskBoardTreeView_Task
      }
    }
  }
}
    ${TaskBoardTreeView_TaskFragmentDoc}`;
export type TaskBoardTreeView_ArchiveTaskMutationFn = Apollo.MutationFunction<TaskBoardTreeView_ArchiveTaskMutation, TaskBoardTreeView_ArchiveTaskMutationVariables>;

/**
 * __useTaskBoardTreeView_ArchiveTaskMutation__
 *
 * To run a mutation, you first call `useTaskBoardTreeView_ArchiveTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskBoardTreeView_ArchiveTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskBoardTreeViewArchiveTaskMutation, { data, loading, error }] = useTaskBoardTreeView_ArchiveTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      boardId: // value for 'boardId'
 *   },
 * });
 */
export function useTaskBoardTreeView_ArchiveTaskMutation(baseOptions?: Apollo.MutationHookOptions<TaskBoardTreeView_ArchiveTaskMutation, TaskBoardTreeView_ArchiveTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TaskBoardTreeView_ArchiveTaskMutation, TaskBoardTreeView_ArchiveTaskMutationVariables>(TaskBoardTreeView_ArchiveTaskDocument, options);
      }
export type TaskBoardTreeView_ArchiveTaskMutationHookResult = ReturnType<typeof useTaskBoardTreeView_ArchiveTaskMutation>;
export type TaskBoardTreeView_ArchiveTaskMutationResult = Apollo.MutationResult<TaskBoardTreeView_ArchiveTaskMutation>;
export type TaskBoardTreeView_ArchiveTaskMutationOptions = Apollo.BaseMutationOptions<TaskBoardTreeView_ArchiveTaskMutation, TaskBoardTreeView_ArchiveTaskMutationVariables>;
export const TaskModalDocument = gql`
    query TaskModal($idNumber: Int!, $boardId: ID!) {
  task(idNumber: $idNumber, boardId: $boardId) {
    ...TaskModal_Task
  }
  taskBoard(id: $boardId) {
    states {
      ...Task_States
    }
  }
  me {
    id
    fullName
    profilePicture
  }
  activeSession {
    id
  }
}
    ${TaskModal_TaskFragmentDoc}
${Task_StatesFragmentDoc}`;

/**
 * __useTaskModalQuery__
 *
 * To run a query within a React component, call `useTaskModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskModalQuery({
 *   variables: {
 *      idNumber: // value for 'idNumber'
 *      boardId: // value for 'boardId'
 *   },
 * });
 */
export function useTaskModalQuery(baseOptions: Apollo.QueryHookOptions<TaskModalQuery, TaskModalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskModalQuery, TaskModalQueryVariables>(TaskModalDocument, options);
      }
export function useTaskModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskModalQuery, TaskModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskModalQuery, TaskModalQueryVariables>(TaskModalDocument, options);
        }
export type TaskModalQueryHookResult = ReturnType<typeof useTaskModalQuery>;
export type TaskModalLazyQueryHookResult = ReturnType<typeof useTaskModalLazyQuery>;
export type TaskModalQueryResult = Apollo.QueryResult<TaskModalQuery, TaskModalQueryVariables>;
export const TaskModal_CreateCommentDocument = gql`
    mutation TaskModal_CreateComment($taskId: ID!, $content: String!) {
  createTaskComment(taskId: $taskId, content: $content) {
    id
    comments {
      ...TaskComments_Comment
    }
  }
}
    ${TaskComments_CommentFragmentDoc}`;
export type TaskModal_CreateCommentMutationFn = Apollo.MutationFunction<TaskModal_CreateCommentMutation, TaskModal_CreateCommentMutationVariables>;

/**
 * __useTaskModal_CreateCommentMutation__
 *
 * To run a mutation, you first call `useTaskModal_CreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskModal_CreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskModalCreateCommentMutation, { data, loading, error }] = useTaskModal_CreateCommentMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useTaskModal_CreateCommentMutation(baseOptions?: Apollo.MutationHookOptions<TaskModal_CreateCommentMutation, TaskModal_CreateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TaskModal_CreateCommentMutation, TaskModal_CreateCommentMutationVariables>(TaskModal_CreateCommentDocument, options);
      }
export type TaskModal_CreateCommentMutationHookResult = ReturnType<typeof useTaskModal_CreateCommentMutation>;
export type TaskModal_CreateCommentMutationResult = Apollo.MutationResult<TaskModal_CreateCommentMutation>;
export type TaskModal_CreateCommentMutationOptions = Apollo.BaseMutationOptions<TaskModal_CreateCommentMutation, TaskModal_CreateCommentMutationVariables>;
export const TaskModal_UpdateTaskDocument = gql`
    mutation TaskModal_UpdateTask($id: ID!, $title: String, $description: String, $attachments: [AttachmentInput!], $hours: Int) {
  updateTask(
    id: $id
    title: $title
    description: $description
    attachments: $attachments
    hours: $hours
  ) {
    id
    title
    description
    attachmentCount
    attachments {
      ...TaskAttachments_Attachment
    }
    hours
  }
}
    ${TaskAttachments_AttachmentFragmentDoc}`;
export type TaskModal_UpdateTaskMutationFn = Apollo.MutationFunction<TaskModal_UpdateTaskMutation, TaskModal_UpdateTaskMutationVariables>;

/**
 * __useTaskModal_UpdateTaskMutation__
 *
 * To run a mutation, you first call `useTaskModal_UpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskModal_UpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskModalUpdateTaskMutation, { data, loading, error }] = useTaskModal_UpdateTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      attachments: // value for 'attachments'
 *      hours: // value for 'hours'
 *   },
 * });
 */
export function useTaskModal_UpdateTaskMutation(baseOptions?: Apollo.MutationHookOptions<TaskModal_UpdateTaskMutation, TaskModal_UpdateTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TaskModal_UpdateTaskMutation, TaskModal_UpdateTaskMutationVariables>(TaskModal_UpdateTaskDocument, options);
      }
export type TaskModal_UpdateTaskMutationHookResult = ReturnType<typeof useTaskModal_UpdateTaskMutation>;
export type TaskModal_UpdateTaskMutationResult = Apollo.MutationResult<TaskModal_UpdateTaskMutation>;
export type TaskModal_UpdateTaskMutationOptions = Apollo.BaseMutationOptions<TaskModal_UpdateTaskMutation, TaskModal_UpdateTaskMutationVariables>;
export const TaskModal_TaskDetailsUpdatedDocument = gql`
    subscription TaskModal_TaskDetailsUpdated($sessionId: ID!, $taskId: ID!) {
  taskDetailsUpdated(sessionId: $sessionId, taskId: $taskId) {
    ...TaskModal_Task
    attachments {
      ...TaskAttachments_Attachment
    }
    comments {
      ...TaskComments_Comment
    }
    log {
      ...TaskLog
    }
  }
}
    ${TaskModal_TaskFragmentDoc}
${TaskAttachments_AttachmentFragmentDoc}
${TaskComments_CommentFragmentDoc}
${TaskLogFragmentDoc}`;

/**
 * __useTaskModal_TaskDetailsUpdatedSubscription__
 *
 * To run a query within a React component, call `useTaskModal_TaskDetailsUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTaskModal_TaskDetailsUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskModal_TaskDetailsUpdatedSubscription({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTaskModal_TaskDetailsUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<TaskModal_TaskDetailsUpdatedSubscription, TaskModal_TaskDetailsUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<TaskModal_TaskDetailsUpdatedSubscription, TaskModal_TaskDetailsUpdatedSubscriptionVariables>(TaskModal_TaskDetailsUpdatedDocument, options);
      }
export type TaskModal_TaskDetailsUpdatedSubscriptionHookResult = ReturnType<typeof useTaskModal_TaskDetailsUpdatedSubscription>;
export type TaskModal_TaskDetailsUpdatedSubscriptionResult = Apollo.SubscriptionResult<TaskModal_TaskDetailsUpdatedSubscription>;
export const TaskAttachmentsDocument = gql`
    query TaskAttachments($id: ID!) {
  task(id: $id) {
    id
    attachments {
      ...TaskAttachments_Attachment
    }
  }
}
    ${TaskAttachments_AttachmentFragmentDoc}`;

/**
 * __useTaskAttachmentsQuery__
 *
 * To run a query within a React component, call `useTaskAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskAttachmentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTaskAttachmentsQuery(baseOptions: Apollo.QueryHookOptions<TaskAttachmentsQuery, TaskAttachmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskAttachmentsQuery, TaskAttachmentsQueryVariables>(TaskAttachmentsDocument, options);
      }
export function useTaskAttachmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskAttachmentsQuery, TaskAttachmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskAttachmentsQuery, TaskAttachmentsQueryVariables>(TaskAttachmentsDocument, options);
        }
export type TaskAttachmentsQueryHookResult = ReturnType<typeof useTaskAttachmentsQuery>;
export type TaskAttachmentsLazyQueryHookResult = ReturnType<typeof useTaskAttachmentsLazyQuery>;
export type TaskAttachmentsQueryResult = Apollo.QueryResult<TaskAttachmentsQuery, TaskAttachmentsQueryVariables>;
export const TaskAttachments_RemoveAttachmentDocument = gql`
    mutation TaskAttachments_RemoveAttachment($id: ID!) {
  removeTaskAttachment(attachmentId: $id) {
    id
    attachments {
      ...TaskAttachments_Attachment
    }
    comments {
      id
      attachments {
        ...TaskAttachments_Attachment
      }
    }
  }
}
    ${TaskAttachments_AttachmentFragmentDoc}`;
export type TaskAttachments_RemoveAttachmentMutationFn = Apollo.MutationFunction<TaskAttachments_RemoveAttachmentMutation, TaskAttachments_RemoveAttachmentMutationVariables>;

/**
 * __useTaskAttachments_RemoveAttachmentMutation__
 *
 * To run a mutation, you first call `useTaskAttachments_RemoveAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskAttachments_RemoveAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskAttachmentsRemoveAttachmentMutation, { data, loading, error }] = useTaskAttachments_RemoveAttachmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTaskAttachments_RemoveAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<TaskAttachments_RemoveAttachmentMutation, TaskAttachments_RemoveAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TaskAttachments_RemoveAttachmentMutation, TaskAttachments_RemoveAttachmentMutationVariables>(TaskAttachments_RemoveAttachmentDocument, options);
      }
export type TaskAttachments_RemoveAttachmentMutationHookResult = ReturnType<typeof useTaskAttachments_RemoveAttachmentMutation>;
export type TaskAttachments_RemoveAttachmentMutationResult = Apollo.MutationResult<TaskAttachments_RemoveAttachmentMutation>;
export type TaskAttachments_RemoveAttachmentMutationOptions = Apollo.BaseMutationOptions<TaskAttachments_RemoveAttachmentMutation, TaskAttachments_RemoveAttachmentMutationVariables>;
export const TaskActivityLogDocument = gql`
    query TaskActivityLog($id: ID!) {
  task(id: $id) {
    id
    log {
      ...TaskLog
    }
  }
}
    ${TaskLogFragmentDoc}`;

/**
 * __useTaskActivityLogQuery__
 *
 * To run a query within a React component, call `useTaskActivityLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskActivityLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskActivityLogQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTaskActivityLogQuery(baseOptions: Apollo.QueryHookOptions<TaskActivityLogQuery, TaskActivityLogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskActivityLogQuery, TaskActivityLogQueryVariables>(TaskActivityLogDocument, options);
      }
export function useTaskActivityLogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskActivityLogQuery, TaskActivityLogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskActivityLogQuery, TaskActivityLogQueryVariables>(TaskActivityLogDocument, options);
        }
export type TaskActivityLogQueryHookResult = ReturnType<typeof useTaskActivityLogQuery>;
export type TaskActivityLogLazyQueryHookResult = ReturnType<typeof useTaskActivityLogLazyQuery>;
export type TaskActivityLogQueryResult = Apollo.QueryResult<TaskActivityLogQuery, TaskActivityLogQueryVariables>;
export const TaskCommentsDocument = gql`
    query TaskComments($id: ID!) {
  task(id: $id) {
    id
    comments {
      ...TaskComments_Comment
    }
  }
  me {
    id
    fullName
    profilePicture
  }
}
    ${TaskComments_CommentFragmentDoc}`;

/**
 * __useTaskCommentsQuery__
 *
 * To run a query within a React component, call `useTaskCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskCommentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTaskCommentsQuery(baseOptions: Apollo.QueryHookOptions<TaskCommentsQuery, TaskCommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskCommentsQuery, TaskCommentsQueryVariables>(TaskCommentsDocument, options);
      }
export function useTaskCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskCommentsQuery, TaskCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskCommentsQuery, TaskCommentsQueryVariables>(TaskCommentsDocument, options);
        }
export type TaskCommentsQueryHookResult = ReturnType<typeof useTaskCommentsQuery>;
export type TaskCommentsLazyQueryHookResult = ReturnType<typeof useTaskCommentsLazyQuery>;
export type TaskCommentsQueryResult = Apollo.QueryResult<TaskCommentsQuery, TaskCommentsQueryVariables>;
export const TaskComments_CreateCommentDocument = gql`
    mutation TaskComments_CreateComment($taskId: ID!, $content: String!, $attachments: [AttachmentInput!]) {
  createTaskComment(taskId: $taskId, content: $content, attachments: $attachments) {
    id
    comments {
      ...TaskComments_Comment
    }
    attachmentCount
    commentCount
    attachments {
      ...TaskAttachments_Attachment
    }
  }
}
    ${TaskComments_CommentFragmentDoc}
${TaskAttachments_AttachmentFragmentDoc}`;
export type TaskComments_CreateCommentMutationFn = Apollo.MutationFunction<TaskComments_CreateCommentMutation, TaskComments_CreateCommentMutationVariables>;

/**
 * __useTaskComments_CreateCommentMutation__
 *
 * To run a mutation, you first call `useTaskComments_CreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskComments_CreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskCommentsCreateCommentMutation, { data, loading, error }] = useTaskComments_CreateCommentMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      content: // value for 'content'
 *      attachments: // value for 'attachments'
 *   },
 * });
 */
export function useTaskComments_CreateCommentMutation(baseOptions?: Apollo.MutationHookOptions<TaskComments_CreateCommentMutation, TaskComments_CreateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TaskComments_CreateCommentMutation, TaskComments_CreateCommentMutationVariables>(TaskComments_CreateCommentDocument, options);
      }
export type TaskComments_CreateCommentMutationHookResult = ReturnType<typeof useTaskComments_CreateCommentMutation>;
export type TaskComments_CreateCommentMutationResult = Apollo.MutationResult<TaskComments_CreateCommentMutation>;
export type TaskComments_CreateCommentMutationOptions = Apollo.BaseMutationOptions<TaskComments_CreateCommentMutation, TaskComments_CreateCommentMutationVariables>;
export const TaskComments_ToggleCommentReactionDocument = gql`
    mutation TaskComments_ToggleCommentReaction($commentId: ID!, $value: String!) {
  toggleTaskCommentReaction(commentId: $commentId, value: $value) {
    id
    comments {
      reactions {
        id
        reactors
        name
        icon
        value
      }
    }
  }
}
    `;
export type TaskComments_ToggleCommentReactionMutationFn = Apollo.MutationFunction<TaskComments_ToggleCommentReactionMutation, TaskComments_ToggleCommentReactionMutationVariables>;

/**
 * __useTaskComments_ToggleCommentReactionMutation__
 *
 * To run a mutation, you first call `useTaskComments_ToggleCommentReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskComments_ToggleCommentReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskCommentsToggleCommentReactionMutation, { data, loading, error }] = useTaskComments_ToggleCommentReactionMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useTaskComments_ToggleCommentReactionMutation(baseOptions?: Apollo.MutationHookOptions<TaskComments_ToggleCommentReactionMutation, TaskComments_ToggleCommentReactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TaskComments_ToggleCommentReactionMutation, TaskComments_ToggleCommentReactionMutationVariables>(TaskComments_ToggleCommentReactionDocument, options);
      }
export type TaskComments_ToggleCommentReactionMutationHookResult = ReturnType<typeof useTaskComments_ToggleCommentReactionMutation>;
export type TaskComments_ToggleCommentReactionMutationResult = Apollo.MutationResult<TaskComments_ToggleCommentReactionMutation>;
export type TaskComments_ToggleCommentReactionMutationOptions = Apollo.BaseMutationOptions<TaskComments_ToggleCommentReactionMutation, TaskComments_ToggleCommentReactionMutationVariables>;
export const TaskComments_DeleteCommentDocument = gql`
    mutation TaskComments_DeleteComment($id: ID!) {
  deleteTaskComment(id: $id) {
    id
    commentCount
    comments {
      ...TaskComments_Comment
    }
  }
}
    ${TaskComments_CommentFragmentDoc}`;
export type TaskComments_DeleteCommentMutationFn = Apollo.MutationFunction<TaskComments_DeleteCommentMutation, TaskComments_DeleteCommentMutationVariables>;

/**
 * __useTaskComments_DeleteCommentMutation__
 *
 * To run a mutation, you first call `useTaskComments_DeleteCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskComments_DeleteCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskCommentsDeleteCommentMutation, { data, loading, error }] = useTaskComments_DeleteCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTaskComments_DeleteCommentMutation(baseOptions?: Apollo.MutationHookOptions<TaskComments_DeleteCommentMutation, TaskComments_DeleteCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TaskComments_DeleteCommentMutation, TaskComments_DeleteCommentMutationVariables>(TaskComments_DeleteCommentDocument, options);
      }
export type TaskComments_DeleteCommentMutationHookResult = ReturnType<typeof useTaskComments_DeleteCommentMutation>;
export type TaskComments_DeleteCommentMutationResult = Apollo.MutationResult<TaskComments_DeleteCommentMutation>;
export type TaskComments_DeleteCommentMutationOptions = Apollo.BaseMutationOptions<TaskComments_DeleteCommentMutation, TaskComments_DeleteCommentMutationVariables>;
export const TaskComments_UpdateCommentDocument = gql`
    mutation TaskComments_UpdateComment($id: ID!, $content: String!, $attachments: [AttachmentInput!]) {
  updateTaskComment(id: $id, content: $content, attachments: $attachments) {
    task {
      id
      attachmentCount
    }
    comment {
      ...TaskComments_Comment
    }
  }
}
    ${TaskComments_CommentFragmentDoc}`;
export type TaskComments_UpdateCommentMutationFn = Apollo.MutationFunction<TaskComments_UpdateCommentMutation, TaskComments_UpdateCommentMutationVariables>;

/**
 * __useTaskComments_UpdateCommentMutation__
 *
 * To run a mutation, you first call `useTaskComments_UpdateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskComments_UpdateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskCommentsUpdateCommentMutation, { data, loading, error }] = useTaskComments_UpdateCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      content: // value for 'content'
 *      attachments: // value for 'attachments'
 *   },
 * });
 */
export function useTaskComments_UpdateCommentMutation(baseOptions?: Apollo.MutationHookOptions<TaskComments_UpdateCommentMutation, TaskComments_UpdateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TaskComments_UpdateCommentMutation, TaskComments_UpdateCommentMutationVariables>(TaskComments_UpdateCommentDocument, options);
      }
export type TaskComments_UpdateCommentMutationHookResult = ReturnType<typeof useTaskComments_UpdateCommentMutation>;
export type TaskComments_UpdateCommentMutationResult = Apollo.MutationResult<TaskComments_UpdateCommentMutation>;
export type TaskComments_UpdateCommentMutationOptions = Apollo.BaseMutationOptions<TaskComments_UpdateCommentMutation, TaskComments_UpdateCommentMutationVariables>;
export const TaskSubTasksDocument = gql`
    query TaskSubTasks($id: ID!, $boardId: ID!) {
  task(id: $id) {
    ...TaskModal_Task
    progress {
      total
      completed
    }
  }
  taskBoard(id: $boardId) {
    states {
      ...Task_States
    }
  }
}
    ${TaskModal_TaskFragmentDoc}
${Task_StatesFragmentDoc}`;

/**
 * __useTaskSubTasksQuery__
 *
 * To run a query within a React component, call `useTaskSubTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskSubTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskSubTasksQuery({
 *   variables: {
 *      id: // value for 'id'
 *      boardId: // value for 'boardId'
 *   },
 * });
 */
export function useTaskSubTasksQuery(baseOptions: Apollo.QueryHookOptions<TaskSubTasksQuery, TaskSubTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskSubTasksQuery, TaskSubTasksQueryVariables>(TaskSubTasksDocument, options);
      }
export function useTaskSubTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskSubTasksQuery, TaskSubTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskSubTasksQuery, TaskSubTasksQueryVariables>(TaskSubTasksDocument, options);
        }
export type TaskSubTasksQueryHookResult = ReturnType<typeof useTaskSubTasksQuery>;
export type TaskSubTasksLazyQueryHookResult = ReturnType<typeof useTaskSubTasksLazyQuery>;
export type TaskSubTasksQueryResult = Apollo.QueryResult<TaskSubTasksQuery, TaskSubTasksQueryVariables>;
export const TaskSubTasks_CreateSubTaskDocument = gql`
    mutation TaskSubTasks_CreateSubTask($stateId: ID!, $title: String!, $description: String, $parentId: ID!) {
  createTask(
    stateId: $stateId
    title: $title
    description: $description
    parentId: $parentId
  ) {
    task {
      progress {
        total
        completed
      }
    }
    query {
      task(id: $parentId) {
        children {
          ...SubTask_Task
        }
      }
      taskState(id: $stateId) {
        tasks {
          ...TaskCard_Task
        }
      }
    }
  }
}
    ${SubTask_TaskFragmentDoc}
${TaskCard_TaskFragmentDoc}`;
export type TaskSubTasks_CreateSubTaskMutationFn = Apollo.MutationFunction<TaskSubTasks_CreateSubTaskMutation, TaskSubTasks_CreateSubTaskMutationVariables>;

/**
 * __useTaskSubTasks_CreateSubTaskMutation__
 *
 * To run a mutation, you first call `useTaskSubTasks_CreateSubTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskSubTasks_CreateSubTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskSubTasksCreateSubTaskMutation, { data, loading, error }] = useTaskSubTasks_CreateSubTaskMutation({
 *   variables: {
 *      stateId: // value for 'stateId'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useTaskSubTasks_CreateSubTaskMutation(baseOptions?: Apollo.MutationHookOptions<TaskSubTasks_CreateSubTaskMutation, TaskSubTasks_CreateSubTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TaskSubTasks_CreateSubTaskMutation, TaskSubTasks_CreateSubTaskMutationVariables>(TaskSubTasks_CreateSubTaskDocument, options);
      }
export type TaskSubTasks_CreateSubTaskMutationHookResult = ReturnType<typeof useTaskSubTasks_CreateSubTaskMutation>;
export type TaskSubTasks_CreateSubTaskMutationResult = Apollo.MutationResult<TaskSubTasks_CreateSubTaskMutation>;
export type TaskSubTasks_CreateSubTaskMutationOptions = Apollo.BaseMutationOptions<TaskSubTasks_CreateSubTaskMutation, TaskSubTasks_CreateSubTaskMutationVariables>;
export const TaskSubTasks_ReOrderSubTaskMutationDocument = gql`
    mutation TaskSubTasks_ReOrderSubTaskMutation($taskId: ID!, $toTask: ID!) {
  reorderTask(taskId: $taskId, toTask: $toTask, withinParent: true) {
    id
    parentOrder
  }
}
    `;
export type TaskSubTasks_ReOrderSubTaskMutationMutationFn = Apollo.MutationFunction<TaskSubTasks_ReOrderSubTaskMutationMutation, TaskSubTasks_ReOrderSubTaskMutationMutationVariables>;

/**
 * __useTaskSubTasks_ReOrderSubTaskMutationMutation__
 *
 * To run a mutation, you first call `useTaskSubTasks_ReOrderSubTaskMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskSubTasks_ReOrderSubTaskMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskSubTasksReOrderSubTaskMutationMutation, { data, loading, error }] = useTaskSubTasks_ReOrderSubTaskMutationMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      toTask: // value for 'toTask'
 *   },
 * });
 */
export function useTaskSubTasks_ReOrderSubTaskMutationMutation(baseOptions?: Apollo.MutationHookOptions<TaskSubTasks_ReOrderSubTaskMutationMutation, TaskSubTasks_ReOrderSubTaskMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TaskSubTasks_ReOrderSubTaskMutationMutation, TaskSubTasks_ReOrderSubTaskMutationMutationVariables>(TaskSubTasks_ReOrderSubTaskMutationDocument, options);
      }
export type TaskSubTasks_ReOrderSubTaskMutationMutationHookResult = ReturnType<typeof useTaskSubTasks_ReOrderSubTaskMutationMutation>;
export type TaskSubTasks_ReOrderSubTaskMutationMutationResult = Apollo.MutationResult<TaskSubTasks_ReOrderSubTaskMutationMutation>;
export type TaskSubTasks_ReOrderSubTaskMutationMutationOptions = Apollo.BaseMutationOptions<TaskSubTasks_ReOrderSubTaskMutationMutation, TaskSubTasks_ReOrderSubTaskMutationMutationVariables>;
export const SubTask_RemoveParentDocument = gql`
    mutation SubTask_RemoveParent($taskId: ID!) {
  removeTaskParent(taskId: $taskId) {
    parent {
      id
      children {
        ...SubTask_Task
      }
      progress {
        total
        completed
      }
    }
    child {
      id
      parentOrder
      breadcrumbs {
        ...TaskModal_Breadcrumb
      }
    }
  }
}
    ${SubTask_TaskFragmentDoc}
${TaskModal_BreadcrumbFragmentDoc}`;
export type SubTask_RemoveParentMutationFn = Apollo.MutationFunction<SubTask_RemoveParentMutation, SubTask_RemoveParentMutationVariables>;

/**
 * __useSubTask_RemoveParentMutation__
 *
 * To run a mutation, you first call `useSubTask_RemoveParentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubTask_RemoveParentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subTaskRemoveParentMutation, { data, loading, error }] = useSubTask_RemoveParentMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useSubTask_RemoveParentMutation(baseOptions?: Apollo.MutationHookOptions<SubTask_RemoveParentMutation, SubTask_RemoveParentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubTask_RemoveParentMutation, SubTask_RemoveParentMutationVariables>(SubTask_RemoveParentDocument, options);
      }
export type SubTask_RemoveParentMutationHookResult = ReturnType<typeof useSubTask_RemoveParentMutation>;
export type SubTask_RemoveParentMutationResult = Apollo.MutationResult<SubTask_RemoveParentMutation>;
export type SubTask_RemoveParentMutationOptions = Apollo.BaseMutationOptions<SubTask_RemoveParentMutation, SubTask_RemoveParentMutationVariables>;
export const TasksHomeDocument = gql`
    query TasksHome {
  taskBoards {
    ...TaskHome_TaskBoard
  }
}
    ${TaskHome_TaskBoardFragmentDoc}`;

/**
 * __useTasksHomeQuery__
 *
 * To run a query within a React component, call `useTasksHomeQuery` and pass it any options that fit your needs.
 * When your component renders, `useTasksHomeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTasksHomeQuery({
 *   variables: {
 *   },
 * });
 */
export function useTasksHomeQuery(baseOptions?: Apollo.QueryHookOptions<TasksHomeQuery, TasksHomeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TasksHomeQuery, TasksHomeQueryVariables>(TasksHomeDocument, options);
      }
export function useTasksHomeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TasksHomeQuery, TasksHomeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TasksHomeQuery, TasksHomeQueryVariables>(TasksHomeDocument, options);
        }
export type TasksHomeQueryHookResult = ReturnType<typeof useTasksHomeQuery>;
export type TasksHomeLazyQueryHookResult = ReturnType<typeof useTasksHomeLazyQuery>;
export type TasksHomeQueryResult = Apollo.QueryResult<TasksHomeQuery, TasksHomeQueryVariables>;
export const WikiDocument = gql`
    query Wiki($idStr: String, $id: ID) {
  wiki(idStr: $idStr, id: $id) {
    ...Wiki_Wiki
  }
  activeSession {
    id
  }
}
    ${Wiki_WikiFragmentDoc}`;

/**
 * __useWikiQuery__
 *
 * To run a query within a React component, call `useWikiQuery` and pass it any options that fit your needs.
 * When your component renders, `useWikiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWikiQuery({
 *   variables: {
 *      idStr: // value for 'idStr'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWikiQuery(baseOptions?: Apollo.QueryHookOptions<WikiQuery, WikiQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WikiQuery, WikiQueryVariables>(WikiDocument, options);
      }
export function useWikiLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WikiQuery, WikiQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WikiQuery, WikiQueryVariables>(WikiDocument, options);
        }
export type WikiQueryHookResult = ReturnType<typeof useWikiQuery>;
export type WikiLazyQueryHookResult = ReturnType<typeof useWikiLazyQuery>;
export type WikiQueryResult = Apollo.QueryResult<WikiQuery, WikiQueryVariables>;
export const Wiki_UpdateWikiNameDocument = gql`
    mutation Wiki_UpdateWikiName($id: ID!, $name: String!) {
  updateWikiName(id: $id, name: $name) {
    id
    name
  }
}
    `;
export type Wiki_UpdateWikiNameMutationFn = Apollo.MutationFunction<Wiki_UpdateWikiNameMutation, Wiki_UpdateWikiNameMutationVariables>;

/**
 * __useWiki_UpdateWikiNameMutation__
 *
 * To run a mutation, you first call `useWiki_UpdateWikiNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWiki_UpdateWikiNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wikiUpdateWikiNameMutation, { data, loading, error }] = useWiki_UpdateWikiNameMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useWiki_UpdateWikiNameMutation(baseOptions?: Apollo.MutationHookOptions<Wiki_UpdateWikiNameMutation, Wiki_UpdateWikiNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Wiki_UpdateWikiNameMutation, Wiki_UpdateWikiNameMutationVariables>(Wiki_UpdateWikiNameDocument, options);
      }
export type Wiki_UpdateWikiNameMutationHookResult = ReturnType<typeof useWiki_UpdateWikiNameMutation>;
export type Wiki_UpdateWikiNameMutationResult = Apollo.MutationResult<Wiki_UpdateWikiNameMutation>;
export type Wiki_UpdateWikiNameMutationOptions = Apollo.BaseMutationOptions<Wiki_UpdateWikiNameMutation, Wiki_UpdateWikiNameMutationVariables>;
export const WikiPage_AncestorsDocument = gql`
    query WikiPage_Ancestors($fileId: ID!) {
  file(id: $fileId) {
    id
    name
    ancestors
  }
}
    `;

/**
 * __useWikiPage_AncestorsQuery__
 *
 * To run a query within a React component, call `useWikiPage_AncestorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWikiPage_AncestorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWikiPage_AncestorsQuery({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useWikiPage_AncestorsQuery(baseOptions: Apollo.QueryHookOptions<WikiPage_AncestorsQuery, WikiPage_AncestorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WikiPage_AncestorsQuery, WikiPage_AncestorsQueryVariables>(WikiPage_AncestorsDocument, options);
      }
export function useWikiPage_AncestorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WikiPage_AncestorsQuery, WikiPage_AncestorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WikiPage_AncestorsQuery, WikiPage_AncestorsQueryVariables>(WikiPage_AncestorsDocument, options);
        }
export type WikiPage_AncestorsQueryHookResult = ReturnType<typeof useWikiPage_AncestorsQuery>;
export type WikiPage_AncestorsLazyQueryHookResult = ReturnType<typeof useWikiPage_AncestorsLazyQuery>;
export type WikiPage_AncestorsQueryResult = Apollo.QueryResult<WikiPage_AncestorsQuery, WikiPage_AncestorsQueryVariables>;
export const WikiCommentsDocument = gql`
    query WikiComments($fileId: ID!) {
  me {
    ...WikiUser
  }
  activeSession {
    id
  }
  file(id: $fileId) {
    id
    wikiComments {
      ...WikiComments_Comment
    }
  }
}
    ${WikiUserFragmentDoc}
${WikiComments_CommentFragmentDoc}`;

/**
 * __useWikiCommentsQuery__
 *
 * To run a query within a React component, call `useWikiCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWikiCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWikiCommentsQuery({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useWikiCommentsQuery(baseOptions: Apollo.QueryHookOptions<WikiCommentsQuery, WikiCommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WikiCommentsQuery, WikiCommentsQueryVariables>(WikiCommentsDocument, options);
      }
export function useWikiCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WikiCommentsQuery, WikiCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WikiCommentsQuery, WikiCommentsQueryVariables>(WikiCommentsDocument, options);
        }
export type WikiCommentsQueryHookResult = ReturnType<typeof useWikiCommentsQuery>;
export type WikiCommentsLazyQueryHookResult = ReturnType<typeof useWikiCommentsLazyQuery>;
export type WikiCommentsQueryResult = Apollo.QueryResult<WikiCommentsQuery, WikiCommentsQueryVariables>;
export const WikiComments_CreateWikiCommentDocument = gql`
    mutation WikiComments_CreateWikiComment($content: String!, $wikiId: ID!, $fileId: ID!, $attachments: [AttachmentInput!]) {
  createWikiPageComment(
    wikiId: $wikiId
    fileId: $fileId
    content: $content
    attachments: $attachments
  ) {
    id
    wikiComments {
      ...WikiComments_Comment
    }
  }
}
    ${WikiComments_CommentFragmentDoc}`;
export type WikiComments_CreateWikiCommentMutationFn = Apollo.MutationFunction<WikiComments_CreateWikiCommentMutation, WikiComments_CreateWikiCommentMutationVariables>;

/**
 * __useWikiComments_CreateWikiCommentMutation__
 *
 * To run a mutation, you first call `useWikiComments_CreateWikiCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWikiComments_CreateWikiCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wikiCommentsCreateWikiCommentMutation, { data, loading, error }] = useWikiComments_CreateWikiCommentMutation({
 *   variables: {
 *      content: // value for 'content'
 *      wikiId: // value for 'wikiId'
 *      fileId: // value for 'fileId'
 *      attachments: // value for 'attachments'
 *   },
 * });
 */
export function useWikiComments_CreateWikiCommentMutation(baseOptions?: Apollo.MutationHookOptions<WikiComments_CreateWikiCommentMutation, WikiComments_CreateWikiCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WikiComments_CreateWikiCommentMutation, WikiComments_CreateWikiCommentMutationVariables>(WikiComments_CreateWikiCommentDocument, options);
      }
export type WikiComments_CreateWikiCommentMutationHookResult = ReturnType<typeof useWikiComments_CreateWikiCommentMutation>;
export type WikiComments_CreateWikiCommentMutationResult = Apollo.MutationResult<WikiComments_CreateWikiCommentMutation>;
export type WikiComments_CreateWikiCommentMutationOptions = Apollo.BaseMutationOptions<WikiComments_CreateWikiCommentMutation, WikiComments_CreateWikiCommentMutationVariables>;
export const WikiComments_ToggleCommentReactionDocument = gql`
    mutation WikiComments_ToggleCommentReaction($commentId: ID!, $value: String!) {
  toggleWikiPageCommentReaction(commentId: $commentId, value: $value) {
    id
    reactions {
      id
      reactors
      name
      icon
      value
    }
  }
}
    `;
export type WikiComments_ToggleCommentReactionMutationFn = Apollo.MutationFunction<WikiComments_ToggleCommentReactionMutation, WikiComments_ToggleCommentReactionMutationVariables>;

/**
 * __useWikiComments_ToggleCommentReactionMutation__
 *
 * To run a mutation, you first call `useWikiComments_ToggleCommentReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWikiComments_ToggleCommentReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wikiCommentsToggleCommentReactionMutation, { data, loading, error }] = useWikiComments_ToggleCommentReactionMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useWikiComments_ToggleCommentReactionMutation(baseOptions?: Apollo.MutationHookOptions<WikiComments_ToggleCommentReactionMutation, WikiComments_ToggleCommentReactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WikiComments_ToggleCommentReactionMutation, WikiComments_ToggleCommentReactionMutationVariables>(WikiComments_ToggleCommentReactionDocument, options);
      }
export type WikiComments_ToggleCommentReactionMutationHookResult = ReturnType<typeof useWikiComments_ToggleCommentReactionMutation>;
export type WikiComments_ToggleCommentReactionMutationResult = Apollo.MutationResult<WikiComments_ToggleCommentReactionMutation>;
export type WikiComments_ToggleCommentReactionMutationOptions = Apollo.BaseMutationOptions<WikiComments_ToggleCommentReactionMutation, WikiComments_ToggleCommentReactionMutationVariables>;
export const WikiComments_DeleteCommentDocument = gql`
    mutation WikiComments_DeleteComment($id: ID!) {
  deleteWikiPageComment(id: $id) {
    id
    wikiCommentCount
    wikiComments {
      ...WikiComments_Comment
    }
  }
}
    ${WikiComments_CommentFragmentDoc}`;
export type WikiComments_DeleteCommentMutationFn = Apollo.MutationFunction<WikiComments_DeleteCommentMutation, WikiComments_DeleteCommentMutationVariables>;

/**
 * __useWikiComments_DeleteCommentMutation__
 *
 * To run a mutation, you first call `useWikiComments_DeleteCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWikiComments_DeleteCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wikiCommentsDeleteCommentMutation, { data, loading, error }] = useWikiComments_DeleteCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWikiComments_DeleteCommentMutation(baseOptions?: Apollo.MutationHookOptions<WikiComments_DeleteCommentMutation, WikiComments_DeleteCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WikiComments_DeleteCommentMutation, WikiComments_DeleteCommentMutationVariables>(WikiComments_DeleteCommentDocument, options);
      }
export type WikiComments_DeleteCommentMutationHookResult = ReturnType<typeof useWikiComments_DeleteCommentMutation>;
export type WikiComments_DeleteCommentMutationResult = Apollo.MutationResult<WikiComments_DeleteCommentMutation>;
export type WikiComments_DeleteCommentMutationOptions = Apollo.BaseMutationOptions<WikiComments_DeleteCommentMutation, WikiComments_DeleteCommentMutationVariables>;
export const WikiComments_UpdateCommentDocument = gql`
    mutation WikiComments_UpdateComment($id: ID!, $content: String!, $attachments: [AttachmentInput!]) {
  updateWikiPageComment(id: $id, content: $content, attachments: $attachments) {
    comment {
      ...WikiComments_Comment
    }
  }
}
    ${WikiComments_CommentFragmentDoc}`;
export type WikiComments_UpdateCommentMutationFn = Apollo.MutationFunction<WikiComments_UpdateCommentMutation, WikiComments_UpdateCommentMutationVariables>;

/**
 * __useWikiComments_UpdateCommentMutation__
 *
 * To run a mutation, you first call `useWikiComments_UpdateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWikiComments_UpdateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wikiCommentsUpdateCommentMutation, { data, loading, error }] = useWikiComments_UpdateCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      content: // value for 'content'
 *      attachments: // value for 'attachments'
 *   },
 * });
 */
export function useWikiComments_UpdateCommentMutation(baseOptions?: Apollo.MutationHookOptions<WikiComments_UpdateCommentMutation, WikiComments_UpdateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WikiComments_UpdateCommentMutation, WikiComments_UpdateCommentMutationVariables>(WikiComments_UpdateCommentDocument, options);
      }
export type WikiComments_UpdateCommentMutationHookResult = ReturnType<typeof useWikiComments_UpdateCommentMutation>;
export type WikiComments_UpdateCommentMutationResult = Apollo.MutationResult<WikiComments_UpdateCommentMutation>;
export type WikiComments_UpdateCommentMutationOptions = Apollo.BaseMutationOptions<WikiComments_UpdateCommentMutation, WikiComments_UpdateCommentMutationVariables>;
export const WikiComments_RemoveAttachmentDocument = gql`
    mutation WikiComments_RemoveAttachment($attachmentId: ID!) {
  removeWikiPageAttachment(attachmentId: $attachmentId) {
    id
    attachments {
      ...WikiAttachment
    }
  }
}
    ${WikiAttachmentFragmentDoc}`;
export type WikiComments_RemoveAttachmentMutationFn = Apollo.MutationFunction<WikiComments_RemoveAttachmentMutation, WikiComments_RemoveAttachmentMutationVariables>;

/**
 * __useWikiComments_RemoveAttachmentMutation__
 *
 * To run a mutation, you first call `useWikiComments_RemoveAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWikiComments_RemoveAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wikiCommentsRemoveAttachmentMutation, { data, loading, error }] = useWikiComments_RemoveAttachmentMutation({
 *   variables: {
 *      attachmentId: // value for 'attachmentId'
 *   },
 * });
 */
export function useWikiComments_RemoveAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<WikiComments_RemoveAttachmentMutation, WikiComments_RemoveAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WikiComments_RemoveAttachmentMutation, WikiComments_RemoveAttachmentMutationVariables>(WikiComments_RemoveAttachmentDocument, options);
      }
export type WikiComments_RemoveAttachmentMutationHookResult = ReturnType<typeof useWikiComments_RemoveAttachmentMutation>;
export type WikiComments_RemoveAttachmentMutationResult = Apollo.MutationResult<WikiComments_RemoveAttachmentMutation>;
export type WikiComments_RemoveAttachmentMutationOptions = Apollo.BaseMutationOptions<WikiComments_RemoveAttachmentMutation, WikiComments_RemoveAttachmentMutationVariables>;
export const WikiComments_WikiPageCommentsDocument = gql`
    subscription WikiComments_WikiPageComments($fileId: ID!, $sessionId: ID!) {
  wikiPageCommentsUpdated(fileId: $fileId, sessionId: $sessionId) {
    id
    wikiComments {
      ...WikiComments_Comment
    }
  }
}
    ${WikiComments_CommentFragmentDoc}`;

/**
 * __useWikiComments_WikiPageCommentsSubscription__
 *
 * To run a query within a React component, call `useWikiComments_WikiPageCommentsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWikiComments_WikiPageCommentsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWikiComments_WikiPageCommentsSubscription({
 *   variables: {
 *      fileId: // value for 'fileId'
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useWikiComments_WikiPageCommentsSubscription(baseOptions: Apollo.SubscriptionHookOptions<WikiComments_WikiPageCommentsSubscription, WikiComments_WikiPageCommentsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<WikiComments_WikiPageCommentsSubscription, WikiComments_WikiPageCommentsSubscriptionVariables>(WikiComments_WikiPageCommentsDocument, options);
      }
export type WikiComments_WikiPageCommentsSubscriptionHookResult = ReturnType<typeof useWikiComments_WikiPageCommentsSubscription>;
export type WikiComments_WikiPageCommentsSubscriptionResult = Apollo.SubscriptionResult<WikiComments_WikiPageCommentsSubscription>;
export const WikiEditorDocument = gql`
    query WikiEditor {
  activeSession {
    id
  }
  me {
    id
  }
}
    `;

/**
 * __useWikiEditorQuery__
 *
 * To run a query within a React component, call `useWikiEditorQuery` and pass it any options that fit your needs.
 * When your component renders, `useWikiEditorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWikiEditorQuery({
 *   variables: {
 *   },
 * });
 */
export function useWikiEditorQuery(baseOptions?: Apollo.QueryHookOptions<WikiEditorQuery, WikiEditorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WikiEditorQuery, WikiEditorQueryVariables>(WikiEditorDocument, options);
      }
export function useWikiEditorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WikiEditorQuery, WikiEditorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WikiEditorQuery, WikiEditorQueryVariables>(WikiEditorDocument, options);
        }
export type WikiEditorQueryHookResult = ReturnType<typeof useWikiEditorQuery>;
export type WikiEditorLazyQueryHookResult = ReturnType<typeof useWikiEditorLazyQuery>;
export type WikiEditorQueryResult = Apollo.QueryResult<WikiEditorQuery, WikiEditorQueryVariables>;
export const WikiEditor_PageContentDocument = gql`
    query WikiEditor_PageContent($id: ID!) {
  wikiPage(id: $id) {
    id
    content
  }
}
    `;

/**
 * __useWikiEditor_PageContentQuery__
 *
 * To run a query within a React component, call `useWikiEditor_PageContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useWikiEditor_PageContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWikiEditor_PageContentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWikiEditor_PageContentQuery(baseOptions: Apollo.QueryHookOptions<WikiEditor_PageContentQuery, WikiEditor_PageContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WikiEditor_PageContentQuery, WikiEditor_PageContentQueryVariables>(WikiEditor_PageContentDocument, options);
      }
export function useWikiEditor_PageContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WikiEditor_PageContentQuery, WikiEditor_PageContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WikiEditor_PageContentQuery, WikiEditor_PageContentQueryVariables>(WikiEditor_PageContentDocument, options);
        }
export type WikiEditor_PageContentQueryHookResult = ReturnType<typeof useWikiEditor_PageContentQuery>;
export type WikiEditor_PageContentLazyQueryHookResult = ReturnType<typeof useWikiEditor_PageContentLazyQuery>;
export type WikiEditor_PageContentQueryResult = Apollo.QueryResult<WikiEditor_PageContentQuery, WikiEditor_PageContentQueryVariables>;
export const WikiEditor_UpdateFileContentDocument = gql`
    mutation WikiEditor_UpdateFileContent($id: ID!, $content: String!) {
  updateFileContent(id: $id, content: $content) {
    id
    content
  }
}
    `;
export type WikiEditor_UpdateFileContentMutationFn = Apollo.MutationFunction<WikiEditor_UpdateFileContentMutation, WikiEditor_UpdateFileContentMutationVariables>;

/**
 * __useWikiEditor_UpdateFileContentMutation__
 *
 * To run a mutation, you first call `useWikiEditor_UpdateFileContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWikiEditor_UpdateFileContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wikiEditorUpdateFileContentMutation, { data, loading, error }] = useWikiEditor_UpdateFileContentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useWikiEditor_UpdateFileContentMutation(baseOptions?: Apollo.MutationHookOptions<WikiEditor_UpdateFileContentMutation, WikiEditor_UpdateFileContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WikiEditor_UpdateFileContentMutation, WikiEditor_UpdateFileContentMutationVariables>(WikiEditor_UpdateFileContentDocument, options);
      }
export type WikiEditor_UpdateFileContentMutationHookResult = ReturnType<typeof useWikiEditor_UpdateFileContentMutation>;
export type WikiEditor_UpdateFileContentMutationResult = Apollo.MutationResult<WikiEditor_UpdateFileContentMutation>;
export type WikiEditor_UpdateFileContentMutationOptions = Apollo.BaseMutationOptions<WikiEditor_UpdateFileContentMutation, WikiEditor_UpdateFileContentMutationVariables>;
export const WikiEditor_SendEditingActionDocument = gql`
    mutation WikiEditor_SendEditingAction($fileId: ID!) {
  reportUserFileAction(fileId: $fileId, type: "editing")
}
    `;
export type WikiEditor_SendEditingActionMutationFn = Apollo.MutationFunction<WikiEditor_SendEditingActionMutation, WikiEditor_SendEditingActionMutationVariables>;

/**
 * __useWikiEditor_SendEditingActionMutation__
 *
 * To run a mutation, you first call `useWikiEditor_SendEditingActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWikiEditor_SendEditingActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wikiEditorSendEditingActionMutation, { data, loading, error }] = useWikiEditor_SendEditingActionMutation({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useWikiEditor_SendEditingActionMutation(baseOptions?: Apollo.MutationHookOptions<WikiEditor_SendEditingActionMutation, WikiEditor_SendEditingActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WikiEditor_SendEditingActionMutation, WikiEditor_SendEditingActionMutationVariables>(WikiEditor_SendEditingActionDocument, options);
      }
export type WikiEditor_SendEditingActionMutationHookResult = ReturnType<typeof useWikiEditor_SendEditingActionMutation>;
export type WikiEditor_SendEditingActionMutationResult = Apollo.MutationResult<WikiEditor_SendEditingActionMutation>;
export type WikiEditor_SendEditingActionMutationOptions = Apollo.BaseMutationOptions<WikiEditor_SendEditingActionMutation, WikiEditor_SendEditingActionMutationVariables>;
export const WikiEditor_EditorsDocument = gql`
    subscription WikiEditor_Editors($session: ID!, $fileId: ID!) {
  fileEditors(session: $session, fileId: $fileId) {
    id
    editors {
      id
      fullName
      profilePicture
    }
  }
}
    `;

/**
 * __useWikiEditor_EditorsSubscription__
 *
 * To run a query within a React component, call `useWikiEditor_EditorsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWikiEditor_EditorsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWikiEditor_EditorsSubscription({
 *   variables: {
 *      session: // value for 'session'
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useWikiEditor_EditorsSubscription(baseOptions: Apollo.SubscriptionHookOptions<WikiEditor_EditorsSubscription, WikiEditor_EditorsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<WikiEditor_EditorsSubscription, WikiEditor_EditorsSubscriptionVariables>(WikiEditor_EditorsDocument, options);
      }
export type WikiEditor_EditorsSubscriptionHookResult = ReturnType<typeof useWikiEditor_EditorsSubscription>;
export type WikiEditor_EditorsSubscriptionResult = Apollo.SubscriptionResult<WikiEditor_EditorsSubscription>;
export const WikiEditor_ContentDocument = gql`
    subscription WikiEditor_Content($session: ID!, $fileId: ID!) {
  fileContent(session: $session, fileId: $fileId)
}
    `;

/**
 * __useWikiEditor_ContentSubscription__
 *
 * To run a query within a React component, call `useWikiEditor_ContentSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWikiEditor_ContentSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWikiEditor_ContentSubscription({
 *   variables: {
 *      session: // value for 'session'
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useWikiEditor_ContentSubscription(baseOptions: Apollo.SubscriptionHookOptions<WikiEditor_ContentSubscription, WikiEditor_ContentSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<WikiEditor_ContentSubscription, WikiEditor_ContentSubscriptionVariables>(WikiEditor_ContentDocument, options);
      }
export type WikiEditor_ContentSubscriptionHookResult = ReturnType<typeof useWikiEditor_ContentSubscription>;
export type WikiEditor_ContentSubscriptionResult = Apollo.SubscriptionResult<WikiEditor_ContentSubscription>;
export const WikiEditorHeaderDocument = gql`
    query WikiEditorHeader($id: ID!) {
  wikiPage(id: $id) {
    editors {
      id
      fullName
      profilePicture
    }
  }
}
    `;

/**
 * __useWikiEditorHeaderQuery__
 *
 * To run a query within a React component, call `useWikiEditorHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useWikiEditorHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWikiEditorHeaderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWikiEditorHeaderQuery(baseOptions: Apollo.QueryHookOptions<WikiEditorHeaderQuery, WikiEditorHeaderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WikiEditorHeaderQuery, WikiEditorHeaderQueryVariables>(WikiEditorHeaderDocument, options);
      }
export function useWikiEditorHeaderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WikiEditorHeaderQuery, WikiEditorHeaderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WikiEditorHeaderQuery, WikiEditorHeaderQueryVariables>(WikiEditorHeaderDocument, options);
        }
export type WikiEditorHeaderQueryHookResult = ReturnType<typeof useWikiEditorHeaderQuery>;
export type WikiEditorHeaderLazyQueryHookResult = ReturnType<typeof useWikiEditorHeaderLazyQuery>;
export type WikiEditorHeaderQueryResult = Apollo.QueryResult<WikiEditorHeaderQuery, WikiEditorHeaderQueryVariables>;
export const WikiFolderDocument = gql`
    query WikiFolder($id: ID!) {
  folder(id: $id) {
    ...WikiFolder
  }
}
    ${WikiFolderFragmentDoc}`;

/**
 * __useWikiFolderQuery__
 *
 * To run a query within a React component, call `useWikiFolderQuery` and pass it any options that fit your needs.
 * When your component renders, `useWikiFolderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWikiFolderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWikiFolderQuery(baseOptions: Apollo.QueryHookOptions<WikiFolderQuery, WikiFolderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WikiFolderQuery, WikiFolderQueryVariables>(WikiFolderDocument, options);
      }
export function useWikiFolderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WikiFolderQuery, WikiFolderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WikiFolderQuery, WikiFolderQueryVariables>(WikiFolderDocument, options);
        }
export type WikiFolderQueryHookResult = ReturnType<typeof useWikiFolderQuery>;
export type WikiFolderLazyQueryHookResult = ReturnType<typeof useWikiFolderLazyQuery>;
export type WikiFolderQueryResult = Apollo.QueryResult<WikiFolderQuery, WikiFolderQueryVariables>;
export const WikiFolderChildrenDocument = gql`
    query WikiFolderChildren($id: ID!) {
  folder(id: $id) {
    ...WikiFolderChildren
  }
}
    ${WikiFolderChildrenFragmentDoc}`;

/**
 * __useWikiFolderChildrenQuery__
 *
 * To run a query within a React component, call `useWikiFolderChildrenQuery` and pass it any options that fit your needs.
 * When your component renders, `useWikiFolderChildrenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWikiFolderChildrenQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWikiFolderChildrenQuery(baseOptions: Apollo.QueryHookOptions<WikiFolderChildrenQuery, WikiFolderChildrenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WikiFolderChildrenQuery, WikiFolderChildrenQueryVariables>(WikiFolderChildrenDocument, options);
      }
export function useWikiFolderChildrenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WikiFolderChildrenQuery, WikiFolderChildrenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WikiFolderChildrenQuery, WikiFolderChildrenQueryVariables>(WikiFolderChildrenDocument, options);
        }
export type WikiFolderChildrenQueryHookResult = ReturnType<typeof useWikiFolderChildrenQuery>;
export type WikiFolderChildrenLazyQueryHookResult = ReturnType<typeof useWikiFolderChildrenLazyQuery>;
export type WikiFolderChildrenQueryResult = Apollo.QueryResult<WikiFolderChildrenQuery, WikiFolderChildrenQueryVariables>;
export const WikiFolder_CreateFolderDocument = gql`
    mutation WikiFolder_CreateFolder($parentId: ID, $name: String!, $wikiId: ID!) {
  createFolder(parentId: $parentId, name: $name, wikiId: $wikiId, type: "wiki") {
    ...WikiFolderChildren
  }
}
    ${WikiFolderChildrenFragmentDoc}`;
export type WikiFolder_CreateFolderMutationFn = Apollo.MutationFunction<WikiFolder_CreateFolderMutation, WikiFolder_CreateFolderMutationVariables>;

/**
 * __useWikiFolder_CreateFolderMutation__
 *
 * To run a mutation, you first call `useWikiFolder_CreateFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWikiFolder_CreateFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wikiFolderCreateFolderMutation, { data, loading, error }] = useWikiFolder_CreateFolderMutation({
 *   variables: {
 *      parentId: // value for 'parentId'
 *      name: // value for 'name'
 *      wikiId: // value for 'wikiId'
 *   },
 * });
 */
export function useWikiFolder_CreateFolderMutation(baseOptions?: Apollo.MutationHookOptions<WikiFolder_CreateFolderMutation, WikiFolder_CreateFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WikiFolder_CreateFolderMutation, WikiFolder_CreateFolderMutationVariables>(WikiFolder_CreateFolderDocument, options);
      }
export type WikiFolder_CreateFolderMutationHookResult = ReturnType<typeof useWikiFolder_CreateFolderMutation>;
export type WikiFolder_CreateFolderMutationResult = Apollo.MutationResult<WikiFolder_CreateFolderMutation>;
export type WikiFolder_CreateFolderMutationOptions = Apollo.BaseMutationOptions<WikiFolder_CreateFolderMutation, WikiFolder_CreateFolderMutationVariables>;
export const WikiFolder_CreatePageDocument = gql`
    mutation WikiFolder_CreatePage($wikiId: ID!, $name: String!, $parentId: ID!) {
  createFile(wikiId: $wikiId, name: $name, parentId: $parentId, type: "wiki") {
    parent {
      id
      files {
        ...WikiPage
      }
    }
    file {
      id
      name
    }
  }
}
    ${WikiPageFragmentDoc}`;
export type WikiFolder_CreatePageMutationFn = Apollo.MutationFunction<WikiFolder_CreatePageMutation, WikiFolder_CreatePageMutationVariables>;

/**
 * __useWikiFolder_CreatePageMutation__
 *
 * To run a mutation, you first call `useWikiFolder_CreatePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWikiFolder_CreatePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wikiFolderCreatePageMutation, { data, loading, error }] = useWikiFolder_CreatePageMutation({
 *   variables: {
 *      wikiId: // value for 'wikiId'
 *      name: // value for 'name'
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useWikiFolder_CreatePageMutation(baseOptions?: Apollo.MutationHookOptions<WikiFolder_CreatePageMutation, WikiFolder_CreatePageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WikiFolder_CreatePageMutation, WikiFolder_CreatePageMutationVariables>(WikiFolder_CreatePageDocument, options);
      }
export type WikiFolder_CreatePageMutationHookResult = ReturnType<typeof useWikiFolder_CreatePageMutation>;
export type WikiFolder_CreatePageMutationResult = Apollo.MutationResult<WikiFolder_CreatePageMutation>;
export type WikiFolder_CreatePageMutationOptions = Apollo.BaseMutationOptions<WikiFolder_CreatePageMutation, WikiFolder_CreatePageMutationVariables>;
export const WikiFolder_RenameFolderDocument = gql`
    mutation WikiFolder_RenameFolder($id: ID!, $newName: String!) {
  renameFolder(id: $id, newName: $newName) {
    id
    name
  }
}
    `;
export type WikiFolder_RenameFolderMutationFn = Apollo.MutationFunction<WikiFolder_RenameFolderMutation, WikiFolder_RenameFolderMutationVariables>;

/**
 * __useWikiFolder_RenameFolderMutation__
 *
 * To run a mutation, you first call `useWikiFolder_RenameFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWikiFolder_RenameFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wikiFolderRenameFolderMutation, { data, loading, error }] = useWikiFolder_RenameFolderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      newName: // value for 'newName'
 *   },
 * });
 */
export function useWikiFolder_RenameFolderMutation(baseOptions?: Apollo.MutationHookOptions<WikiFolder_RenameFolderMutation, WikiFolder_RenameFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WikiFolder_RenameFolderMutation, WikiFolder_RenameFolderMutationVariables>(WikiFolder_RenameFolderDocument, options);
      }
export type WikiFolder_RenameFolderMutationHookResult = ReturnType<typeof useWikiFolder_RenameFolderMutation>;
export type WikiFolder_RenameFolderMutationResult = Apollo.MutationResult<WikiFolder_RenameFolderMutation>;
export type WikiFolder_RenameFolderMutationOptions = Apollo.BaseMutationOptions<WikiFolder_RenameFolderMutation, WikiFolder_RenameFolderMutationVariables>;
export const WikiFolder_DeleteFolderDocument = gql`
    mutation WikiFolder_DeleteFolder($id: ID!) {
  deleteFolder(id: $id) {
    updatedFolders {
      ...WikiFolderChildren
    }
  }
}
    ${WikiFolderChildrenFragmentDoc}`;
export type WikiFolder_DeleteFolderMutationFn = Apollo.MutationFunction<WikiFolder_DeleteFolderMutation, WikiFolder_DeleteFolderMutationVariables>;

/**
 * __useWikiFolder_DeleteFolderMutation__
 *
 * To run a mutation, you first call `useWikiFolder_DeleteFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWikiFolder_DeleteFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wikiFolderDeleteFolderMutation, { data, loading, error }] = useWikiFolder_DeleteFolderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWikiFolder_DeleteFolderMutation(baseOptions?: Apollo.MutationHookOptions<WikiFolder_DeleteFolderMutation, WikiFolder_DeleteFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WikiFolder_DeleteFolderMutation, WikiFolder_DeleteFolderMutationVariables>(WikiFolder_DeleteFolderDocument, options);
      }
export type WikiFolder_DeleteFolderMutationHookResult = ReturnType<typeof useWikiFolder_DeleteFolderMutation>;
export type WikiFolder_DeleteFolderMutationResult = Apollo.MutationResult<WikiFolder_DeleteFolderMutation>;
export type WikiFolder_DeleteFolderMutationOptions = Apollo.BaseMutationOptions<WikiFolder_DeleteFolderMutation, WikiFolder_DeleteFolderMutationVariables>;
export const WikiFolder_MoveFileToFolderDocument = gql`
    mutation WikiFolder_MoveFileToFolder($file: ID!, $toFolder: ID!) {
  moveFileToFolder(file: $file, toFolder: $toFolder) {
    updatedFolders {
      ...WikiFolderChildren
    }
    updatedFiles {
      id
    }
  }
}
    ${WikiFolderChildrenFragmentDoc}`;
export type WikiFolder_MoveFileToFolderMutationFn = Apollo.MutationFunction<WikiFolder_MoveFileToFolderMutation, WikiFolder_MoveFileToFolderMutationVariables>;

/**
 * __useWikiFolder_MoveFileToFolderMutation__
 *
 * To run a mutation, you first call `useWikiFolder_MoveFileToFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWikiFolder_MoveFileToFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wikiFolderMoveFileToFolderMutation, { data, loading, error }] = useWikiFolder_MoveFileToFolderMutation({
 *   variables: {
 *      file: // value for 'file'
 *      toFolder: // value for 'toFolder'
 *   },
 * });
 */
export function useWikiFolder_MoveFileToFolderMutation(baseOptions?: Apollo.MutationHookOptions<WikiFolder_MoveFileToFolderMutation, WikiFolder_MoveFileToFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WikiFolder_MoveFileToFolderMutation, WikiFolder_MoveFileToFolderMutationVariables>(WikiFolder_MoveFileToFolderDocument, options);
      }
export type WikiFolder_MoveFileToFolderMutationHookResult = ReturnType<typeof useWikiFolder_MoveFileToFolderMutation>;
export type WikiFolder_MoveFileToFolderMutationResult = Apollo.MutationResult<WikiFolder_MoveFileToFolderMutation>;
export type WikiFolder_MoveFileToFolderMutationOptions = Apollo.BaseMutationOptions<WikiFolder_MoveFileToFolderMutation, WikiFolder_MoveFileToFolderMutationVariables>;
export const WikiFolder_MoveFolderToFolderDocument = gql`
    mutation WikiFolder_MoveFolderToFolder($folder: ID!, $toFolder: ID!) {
  moveFolderToFolder(folder: $folder, toFolder: $toFolder) {
    updatedFolders {
      ...WikiFolderChildren
    }
  }
}
    ${WikiFolderChildrenFragmentDoc}`;
export type WikiFolder_MoveFolderToFolderMutationFn = Apollo.MutationFunction<WikiFolder_MoveFolderToFolderMutation, WikiFolder_MoveFolderToFolderMutationVariables>;

/**
 * __useWikiFolder_MoveFolderToFolderMutation__
 *
 * To run a mutation, you first call `useWikiFolder_MoveFolderToFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWikiFolder_MoveFolderToFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wikiFolderMoveFolderToFolderMutation, { data, loading, error }] = useWikiFolder_MoveFolderToFolderMutation({
 *   variables: {
 *      folder: // value for 'folder'
 *      toFolder: // value for 'toFolder'
 *   },
 * });
 */
export function useWikiFolder_MoveFolderToFolderMutation(baseOptions?: Apollo.MutationHookOptions<WikiFolder_MoveFolderToFolderMutation, WikiFolder_MoveFolderToFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WikiFolder_MoveFolderToFolderMutation, WikiFolder_MoveFolderToFolderMutationVariables>(WikiFolder_MoveFolderToFolderDocument, options);
      }
export type WikiFolder_MoveFolderToFolderMutationHookResult = ReturnType<typeof useWikiFolder_MoveFolderToFolderMutation>;
export type WikiFolder_MoveFolderToFolderMutationResult = Apollo.MutationResult<WikiFolder_MoveFolderToFolderMutation>;
export type WikiFolder_MoveFolderToFolderMutationOptions = Apollo.BaseMutationOptions<WikiFolder_MoveFolderToFolderMutation, WikiFolder_MoveFolderToFolderMutationVariables>;
export const WikiFolder_FolderContentDocument = gql`
    subscription WikiFolder_FolderContent($folderId: ID!, $sessionId: ID!) {
  folderContent(session: $sessionId, folderId: $folderId) {
    ...WikiFolderChildren
  }
}
    ${WikiFolderChildrenFragmentDoc}`;

/**
 * __useWikiFolder_FolderContentSubscription__
 *
 * To run a query within a React component, call `useWikiFolder_FolderContentSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWikiFolder_FolderContentSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWikiFolder_FolderContentSubscription({
 *   variables: {
 *      folderId: // value for 'folderId'
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useWikiFolder_FolderContentSubscription(baseOptions: Apollo.SubscriptionHookOptions<WikiFolder_FolderContentSubscription, WikiFolder_FolderContentSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<WikiFolder_FolderContentSubscription, WikiFolder_FolderContentSubscriptionVariables>(WikiFolder_FolderContentDocument, options);
      }
export type WikiFolder_FolderContentSubscriptionHookResult = ReturnType<typeof useWikiFolder_FolderContentSubscription>;
export type WikiFolder_FolderContentSubscriptionResult = Apollo.SubscriptionResult<WikiFolder_FolderContentSubscription>;
export const WikiPage_RenameFileDocument = gql`
    mutation WikiPage_RenameFile($id: ID!, $name: String!) {
  renameFile(fileId: $id, newName: $name) {
    file {
      id
      name
    }
  }
}
    `;
export type WikiPage_RenameFileMutationFn = Apollo.MutationFunction<WikiPage_RenameFileMutation, WikiPage_RenameFileMutationVariables>;

/**
 * __useWikiPage_RenameFileMutation__
 *
 * To run a mutation, you first call `useWikiPage_RenameFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWikiPage_RenameFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wikiPageRenameFileMutation, { data, loading, error }] = useWikiPage_RenameFileMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useWikiPage_RenameFileMutation(baseOptions?: Apollo.MutationHookOptions<WikiPage_RenameFileMutation, WikiPage_RenameFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WikiPage_RenameFileMutation, WikiPage_RenameFileMutationVariables>(WikiPage_RenameFileDocument, options);
      }
export type WikiPage_RenameFileMutationHookResult = ReturnType<typeof useWikiPage_RenameFileMutation>;
export type WikiPage_RenameFileMutationResult = Apollo.MutationResult<WikiPage_RenameFileMutation>;
export type WikiPage_RenameFileMutationOptions = Apollo.BaseMutationOptions<WikiPage_RenameFileMutation, WikiPage_RenameFileMutationVariables>;
export const WikiPage_DeleteFileDocument = gql`
    mutation WikiPage_DeleteFile($fileId: ID!) {
  deleteFile(fileId: $fileId) {
    id
    files {
      ...WikiPage
    }
  }
}
    ${WikiPageFragmentDoc}`;
export type WikiPage_DeleteFileMutationFn = Apollo.MutationFunction<WikiPage_DeleteFileMutation, WikiPage_DeleteFileMutationVariables>;

/**
 * __useWikiPage_DeleteFileMutation__
 *
 * To run a mutation, you first call `useWikiPage_DeleteFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWikiPage_DeleteFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wikiPageDeleteFileMutation, { data, loading, error }] = useWikiPage_DeleteFileMutation({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useWikiPage_DeleteFileMutation(baseOptions?: Apollo.MutationHookOptions<WikiPage_DeleteFileMutation, WikiPage_DeleteFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WikiPage_DeleteFileMutation, WikiPage_DeleteFileMutationVariables>(WikiPage_DeleteFileDocument, options);
      }
export type WikiPage_DeleteFileMutationHookResult = ReturnType<typeof useWikiPage_DeleteFileMutation>;
export type WikiPage_DeleteFileMutationResult = Apollo.MutationResult<WikiPage_DeleteFileMutation>;
export type WikiPage_DeleteFileMutationOptions = Apollo.BaseMutationOptions<WikiPage_DeleteFileMutation, WikiPage_DeleteFileMutationVariables>;
export const WikiModalDocument = gql`
    query WikiModal($idStr: String, $id: ID) {
  wiki(idStr: $idStr, id: $id) {
    ...WikiesHome_Wiki
  }
}
    ${WikiesHome_WikiFragmentDoc}`;

/**
 * __useWikiModalQuery__
 *
 * To run a query within a React component, call `useWikiModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useWikiModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWikiModalQuery({
 *   variables: {
 *      idStr: // value for 'idStr'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWikiModalQuery(baseOptions?: Apollo.QueryHookOptions<WikiModalQuery, WikiModalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WikiModalQuery, WikiModalQueryVariables>(WikiModalDocument, options);
      }
export function useWikiModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WikiModalQuery, WikiModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WikiModalQuery, WikiModalQueryVariables>(WikiModalDocument, options);
        }
export type WikiModalQueryHookResult = ReturnType<typeof useWikiModalQuery>;
export type WikiModalLazyQueryHookResult = ReturnType<typeof useWikiModalLazyQuery>;
export type WikiModalQueryResult = Apollo.QueryResult<WikiModalQuery, WikiModalQueryVariables>;
export const WikiModal_CreateWikiDocument = gql`
    mutation WikiModal_CreateWiki($name: String!, $description: String!, $teams: [ID!]!, $users: [ID!]!) {
  createWiki(name: $name, description: $description, teams: $teams, users: $users) {
    created {
      idStr
    }
    query {
      wikies {
        ...WikiesHome_Wiki
      }
    }
  }
}
    ${WikiesHome_WikiFragmentDoc}`;
export type WikiModal_CreateWikiMutationFn = Apollo.MutationFunction<WikiModal_CreateWikiMutation, WikiModal_CreateWikiMutationVariables>;

/**
 * __useWikiModal_CreateWikiMutation__
 *
 * To run a mutation, you first call `useWikiModal_CreateWikiMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWikiModal_CreateWikiMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wikiModalCreateWikiMutation, { data, loading, error }] = useWikiModal_CreateWikiMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      teams: // value for 'teams'
 *      users: // value for 'users'
 *   },
 * });
 */
export function useWikiModal_CreateWikiMutation(baseOptions?: Apollo.MutationHookOptions<WikiModal_CreateWikiMutation, WikiModal_CreateWikiMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WikiModal_CreateWikiMutation, WikiModal_CreateWikiMutationVariables>(WikiModal_CreateWikiDocument, options);
      }
export type WikiModal_CreateWikiMutationHookResult = ReturnType<typeof useWikiModal_CreateWikiMutation>;
export type WikiModal_CreateWikiMutationResult = Apollo.MutationResult<WikiModal_CreateWikiMutation>;
export type WikiModal_CreateWikiMutationOptions = Apollo.BaseMutationOptions<WikiModal_CreateWikiMutation, WikiModal_CreateWikiMutationVariables>;
export const WikiModal_UpdateWikiDocument = gql`
    mutation WikiModal_UpdateWiki($id: ID!, $name: String!, $description: String!, $teams: [ID!]!, $users: [ID!]!) {
  updateWiki(
    id: $id
    name: $name
    description: $description
    teams: $teams
    users: $users
  ) {
    ...WikiesHome_Wiki
  }
}
    ${WikiesHome_WikiFragmentDoc}`;
export type WikiModal_UpdateWikiMutationFn = Apollo.MutationFunction<WikiModal_UpdateWikiMutation, WikiModal_UpdateWikiMutationVariables>;

/**
 * __useWikiModal_UpdateWikiMutation__
 *
 * To run a mutation, you first call `useWikiModal_UpdateWikiMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWikiModal_UpdateWikiMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wikiModalUpdateWikiMutation, { data, loading, error }] = useWikiModal_UpdateWikiMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      teams: // value for 'teams'
 *      users: // value for 'users'
 *   },
 * });
 */
export function useWikiModal_UpdateWikiMutation(baseOptions?: Apollo.MutationHookOptions<WikiModal_UpdateWikiMutation, WikiModal_UpdateWikiMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WikiModal_UpdateWikiMutation, WikiModal_UpdateWikiMutationVariables>(WikiModal_UpdateWikiDocument, options);
      }
export type WikiModal_UpdateWikiMutationHookResult = ReturnType<typeof useWikiModal_UpdateWikiMutation>;
export type WikiModal_UpdateWikiMutationResult = Apollo.MutationResult<WikiModal_UpdateWikiMutation>;
export type WikiModal_UpdateWikiMutationOptions = Apollo.BaseMutationOptions<WikiModal_UpdateWikiMutation, WikiModal_UpdateWikiMutationVariables>;
export const WikiesHomeDocument = gql`
    query WikiesHome {
  wikies {
    ...WikiesHome_Wiki
  }
}
    ${WikiesHome_WikiFragmentDoc}`;

/**
 * __useWikiesHomeQuery__
 *
 * To run a query within a React component, call `useWikiesHomeQuery` and pass it any options that fit your needs.
 * When your component renders, `useWikiesHomeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWikiesHomeQuery({
 *   variables: {
 *   },
 * });
 */
export function useWikiesHomeQuery(baseOptions?: Apollo.QueryHookOptions<WikiesHomeQuery, WikiesHomeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WikiesHomeQuery, WikiesHomeQueryVariables>(WikiesHomeDocument, options);
      }
export function useWikiesHomeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WikiesHomeQuery, WikiesHomeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WikiesHomeQuery, WikiesHomeQueryVariables>(WikiesHomeDocument, options);
        }
export type WikiesHomeQueryHookResult = ReturnType<typeof useWikiesHomeQuery>;
export type WikiesHomeLazyQueryHookResult = ReturnType<typeof useWikiesHomeLazyQuery>;
export type WikiesHomeQueryResult = Apollo.QueryResult<WikiesHomeQuery, WikiesHomeQueryVariables>;