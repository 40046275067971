import React from "react"
import cn from "clsx"
import styles from "./SuspensePreloader.module.scss"
import spinner from "../../styles/components/spinner.module.scss"

const SuspenseFallback: React.FC = () => (
  <div className={styles.fallback}>
    <div className={styles.spinnerContainer}>
      <div className={cn(spinner.spinner)}></div>
    </div>
  </div>
)

export default SuspenseFallback
