import file from "images/filetypes/file.png"
import pdf from "images/filetypes/application-pdf.png"
import doc from "images/filetypes/doc.png"
import playable from "images/filetypes/playable.png"
import presentation from "images/filetypes/presentation.png"
import sheet from "images/filetypes/spreadsheet.png"
import zip from "images/filetypes/zip.png"

export default function fileTypeToIcon(type: string): string {
  if (["application/pdf", "application/epub+zip"].includes(type)) {
    return pdf
  }

  if (type.startsWith("video/") || type.startsWith("audio/")) {
    return playable
  }

  if (
    [
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/vnd.ms-powerpoint"
    ].includes(type)
  ) {
    return presentation
  }

  if (
    ["application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"].includes(type)
  ) {
    return doc
  }

  if (
    ["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"].includes(type)
  ) {
    return sheet
  }

  if (type === "application/zip") {
    return zip
  }

  return file
}
