import React from "react"
import styles from "./SessionTab.module.scss"
import spinner from "modules/shared/styles/components/spinner.module.scss"
import Pretzel from "images/pret.png"
import cn from "clsx"
import { getSessionLocation } from "modules/app/helpers/sessionLocationHelpers"

export function SetSessionTabIcon(type: string) {
  switch (type) {
    case "chat":
      return <i className={cn(["material-icons", styles.sessionIcon])}>chat</i>
    case "tasks":
      return <i className={cn(["material-icons", styles.sessionIcon])}>dashboard</i>
    case "wikis":
      return <i className={cn(["material-icons", styles.sessionIcon])}>auto_stories</i>
    default:
      return <img src={Pretzel} alt="Pretzel" className={styles.sessionIcon} />
  }
}

export default function GetSessionTabIcon(session: {
  id: string
  name: string
  groupId?: string | null | undefined
}): JSX.Element {
  const sessionLocation = getSessionLocation(session.id)

  if (session.groupId) {
    return <i className={cn(["material-icons", styles.sessionIcon])}>chat</i>
  } else {
    if (sessionLocation) {
      if (sessionLocation.pathname.startsWith("/tasks")) {
        return <i className={cn(["material-icons", styles.sessionIcon])}>dashboard</i>
      }

      if (sessionLocation.pathname.startsWith("/wikis")) {
        return <i className={cn(["material-icons", styles.sessionIcon])}>auto_stories</i>
      }

      if (sessionLocation.pathname.startsWith("/")) {
        return <img src={Pretzel} alt="Pretzel" className={styles.sessionIcon} />
      }
    }

    return <img src={Pretzel} alt="Pretzel" className={styles.sessionIcon} />

    //TODO: icon preload spinner
    return <div className={cn([spinner.spinner, spinner.small])} style={{ display: "inline-block" }}></div>
  }
}
