/* eslint-disable @typescript-eslint/no-explicit-any */
import { useSidebarChannels_SelectChannelMutation, useRoot_MarkNotificationClickedMutation } from "generated/graphql"
import { useEffect } from "react"
import { encodeQueryString, handleGraphQlError, parseQueryString } from "utils/common"
import { useHistory } from "react-router-dom"

export function useManageQueryParams(orgId?: string, groupId?: string): void {
  const history = useHistory()

  const [selectGroup] = useSidebarChannels_SelectChannelMutation({
    onError: handleGraphQlError("Unable to select group")
  })

  const [markNotificationClicked] = useRoot_MarkNotificationClickedMutation({
    onError: handleGraphQlError("Unable to mark notification click")
  })

  useEffect(() => {
    const search = parseQueryString(history.location.search) as any

    if (!search.groupId) {
      return
    }

    if (groupId !== search.groupId) {
      selectGroup({
        variables: {
          id: search.groupId
        },
        update: () => {
          delete search.groupId
          history.replace({
            ...history.location,
            search: encodeQueryString(search)
          })
        }
      })
    } else {
      delete search.groupId
      history.replace({
        ...history.location,
        search: encodeQueryString(search)
      })
    }
  }, [groupId, history.location.search])

  useEffect(() => {
    const search = parseQueryString(history.location.search) as any

    if (!search.notiId) {
      return
    }

    markNotificationClicked({
      variables: {
        ids: [search.notiId]
      }
    })

    delete search.notiId
    history.replace({
      ...history.location,
      search: encodeQueryString(search)
    })
  }, [history.location.search])
}
