import React, { useContext } from "react"
import styles from "./DataPanel.module.scss"
import cn from "clsx"
import { SearchResult_SearchResultFragment as SearchResult } from "generated/graphql"

import ImageItem from "./components/imageItem"
import shimmer from "modules/shared/styles/components/shimmer.module.scss"
import button from "modules/shared/styles/components/button.module.scss"
import _ from "lodash"
import { SearchResultContext } from "modules/chat/components/searchResult/SearchResult"

import { NewsList, NewsRef } from "modules/chat/components/searchResult/components/newsList"

const NewsPanel: React.FC<{
  searchResult: SearchResult
  waitForData: boolean
  searchTerm: string
}> = ({ searchResult, waitForData, searchTerm }) => {
  const { setSelectedType } = useContext(SearchResultContext)

  if ((searchResult?.web?.newsPreview || []).length < 4) {
    return null
  }

  if (waitForData) {
    return (
      <div className={cn(styles.dataPanelPreloader)}>
        <div className={cn(styles.title, shimmer.shimmer)}></div>
        <div className={cn(styles.sub, shimmer.shimmer)}></div>
        <div className={styles.tabs}>
          <div className={cn(styles.tabItem, shimmer.shimmer)}></div>
          <div className={cn(styles.tabItem, shimmer.shimmer)}></div>
          <div className={cn(styles.tabItem, shimmer.shimmer)}></div>
          <div className={cn(styles.tabItem, shimmer.shimmer)}></div>
        </div>
        <div className={cn(styles.description, shimmer.shimmer)}></div>
        <div className={styles.tabs}>
          <div className={cn(styles.tabItem, shimmer.shimmer)}></div>
          <div className={cn(styles.tabItem, shimmer.shimmer)}></div>
          <div className={cn(styles.tabItem, shimmer.shimmer)}></div>
          <div className={cn(styles.tabItem, shimmer.shimmer)}></div>
        </div>
        <div className={cn(styles.description, shimmer.shimmer)}></div>
      </div>
    )
  }

  return (
    <div className={cn(styles.dataPanel, styles.NEWS)}>
      <div className={styles.header}>
        <div className={styles.wikiHeader}>
          <div className={styles.icon}>
            <span className="material-icons">receipt_long</span>
          </div>
          <div className={styles.wikiMeta}>
            <span className={styles.title}>{_.startCase(searchTerm)}</span>
            <span className={styles.slug}>
              <span>News</span>
            </span>
          </div>
          <div className={styles.buttonsContainer}>
            <div className={cn([styles.buttonsGroup, button.green])}>
              <button className={cn(styles.sideButton)} onClick={() => setSelectedType("images")}>
                <i className="material-icons">apps</i>
                <span>Show more</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.newsContainer}>
        {!waitForData && (
          <NewsList
            news={(searchResult.web?.newsPreview || []).map(
              (news) =>
                new NewsRef(
                  news?.name || "",
                  news?.url || "",
                  news?.image?.thumbnail?.contentUrl || "",
                  news?.description || ""
                )
            )}
          />
        )}
      </div>
    </div>
  )
}

const ImagesPanel: React.FC<{
  searchResult: SearchResult
  waitForData: boolean
  searchTerm: string
}> = ({ searchResult, waitForData, searchTerm }) => {
  const { setSelectedType } = useContext(SearchResultContext)

  if (waitForData) {
    return (
      <div className={cn(styles.dataPanelPreloader)}>
        <div className={cn(styles.title, shimmer.shimmer)}></div>
        <div className={cn(styles.sub, shimmer.shimmer)}></div>
        <div className={styles.gridContent}>
          <div className={cn(shimmer.shimmer)}></div>
          <div className={cn(shimmer.shimmer)}></div>
          <div className={cn(shimmer.shimmer)}></div>
          <div className={cn(shimmer.shimmer)}></div>
        </div>
      </div>
    )
  }

  return (
    <div className={cn(styles.dataPanel, styles.IMAGES)}>
      <div className={styles.header}>
        <div className={styles.wikiHeader}>
          <div className={styles.icon}>
            <span className="material-icons">collections</span>
          </div>
          <div className={styles.wikiMeta}>
            <span className={styles.title}>{_.startCase(searchTerm)}</span>
            <span className={styles.slug}>
              <span>Images</span>
            </span>
          </div>
          <div className={styles.buttonsContainer}>
            <div className={cn([styles.buttonsGroup, button.green])}>
              <button className={cn(styles.sideButton)} onClick={() => setSelectedType("images")}>
                <i className="material-icons">apps</i>
                <span>Show more</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.imageGrid}>
        {!waitForData && (
          <>
            {searchResult?.web?.imagesPreview?.map((img) => (
              <ImageItem
                key={img?.thumbnailUrl}
                thumbnail={img?.thumbnailUrl || ""}
                src={img?.contentUrl || ""}
                alt=""
                searchTerm={searchTerm}
              />
            ))}
          </>
        )}

        {waitForData && (
          <>
            {_.times(9, (i) => (
              <div key={i} className={cn(shimmer.shimmer, styles.imagePlaceholder)}></div>
            ))}
          </>
        )}
      </div>
    </div>
  )
}

const VideosPanel: React.FC<{
  searchResult: SearchResult
  waitForData: boolean
  searchTerm: string
}> = ({ searchResult, waitForData, searchTerm }) => {
  const { setSelectedType } = useContext(SearchResultContext)

  if ((searchResult?.web?.videosPreview || []).length < 4) {
    return null
  }

  if (waitForData) {
    return (
      <div className={cn(styles.dataPanelPreloader)}>
        <div className={cn(styles.title, shimmer.shimmer)}></div>
        <div className={cn(styles.sub, shimmer.shimmer)}></div>
        <div className={styles.gridContent}>
          <div className={cn(shimmer.shimmer)}></div>
          <div className={cn(shimmer.shimmer)}></div>
          <div className={cn(shimmer.shimmer)}></div>
          <div className={cn(shimmer.shimmer)}></div>
        </div>
      </div>
    )
  }

  return (
    <div className={cn(styles.dataPanel, styles.IMAGES)}>
      <div className={styles.header}>
        <div className={styles.wikiHeader}>
          <div className={styles.icon}>
            <span className="material-icons">movie</span>
          </div>
          <div className={styles.wikiMeta}>
            <span className={styles.title}>{_.startCase(searchTerm)}</span>
            <span className={styles.slug}>
              <span>Videos</span>
            </span>
          </div>

          <div className={styles.buttonsContainer}>
            <div className={cn([styles.buttonsGroup, button.green])}>
              <button className={cn(styles.sideButton)} onClick={() => setSelectedType("videos")}>
                <i className="material-icons">apps</i>
                <span>Show more</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.imageGrid}>
        {!waitForData && (
          <>
            {searchResult?.web?.videosPreview?.map((video) => (
              <ImageItem
                key={video?.thumbnailUrl}
                thumbnail={video?.thumbnailUrl || ""}
                src={video?.contentUrl || ""}
                alt=""
                videoMode={true}
                searchTerm={searchTerm}
              />
            ))}
          </>
        )}

        {waitForData && (
          <>
            {_.times(9, (i) => (
              <div key={i} className={cn(shimmer.shimmer, styles.imagePlaceholder)}></div>
            ))}
          </>
        )}
      </div>
    </div>
  )
}

const DataPanel: React.FC<{
  type: string
  searchResult: SearchResult
  waitForData: boolean
  searchTerm: string
}> = ({ type, searchResult, waitForData, searchTerm }) => {
  switch (type) {
    case "IMAGES":
      return <ImagesPanel waitForData={waitForData} searchResult={searchResult} searchTerm={searchTerm} />
    case "VIDEOS":
      return <VideosPanel waitForData={waitForData} searchResult={searchResult} searchTerm={searchTerm} />
    case "NEWS":
      return <NewsPanel waitForData={waitForData} searchResult={searchResult} searchTerm={searchTerm} />
    default:
      return null
  }
}

export default DataPanel
