/* eslint-disable */
import React, { useContext, useEffect, useRef, useState } from "react"
import { useHistory } from "react-router-dom"
import styles from "../../Sidebar.module.scss"
import Modal from "react-modal"
import cn from "clsx"
import {
  OrganizationMemberSearchDocument,
  useSidebarChannelsQuery,
  useSidebarChannels_CreateChannelMutation,
  useSidebarChannels_SelectChannelMutation,
  useOrganization_OrganizationQuery
} from "generated/graphql"
import { useOnDemandQuery } from "utils/hooks"
import { routes, topics } from "consts"
import Tooltip from "modules/shared/components/tooltip"
import Select from "react-select/async"
import { SidebarContext } from "../../Sidebar"
import { handleGraphQlError, modalStyles, textEllipsis } from "utils/common"
import modal from "modules/shared/styles/components/modal.module.scss"
import button from "modules/shared/styles/components/button.module.scss"
import input from "modules/shared/styles/components/input.module.scss"
// import ProfilePicture from "modules/shared/components/profilePicture"
import { take, concat, orderBy } from "lodash"
import { isMobile } from "react-device-detect"
import pubsub from "pubsub-js"
import { newTab, setActiveTab, viewTabs } from "modules/app/components/sessionTabs/SessionTabs"

type MemberEntity = {
  id: string
  name: string
  profilePicture: string
}

const CreateDMModal: React.FC<{ setCreating: (val: boolean) => void }> = ({ setCreating }) => {
  const [createDM] = useSidebarChannels_CreateChannelMutation({
    onError: handleGraphQlError("Unable to create DM")
  })
  const searchMembers = useOnDemandQuery(OrganizationMemberSearchDocument)
  const history = useHistory()
  const [newChannelDescription, setNewChannelDescription] = useState("")
  const bottomRef = useRef<HTMLDivElement>(null)
  function closeModal() {
    setSelectedMembers([])
    setCreating(false)
  }

  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({
        behavior: "smooth"
      })
    }
  }, [bottomRef])

  const [selectedMembers, setSelectedMembers] = useState<string[]>([])
  return (
    <Modal
      isOpen
      onRequestClose={() => setCreating(false)}
      style={{
        content: {
          ...modalStyles.content,
          overflow: "initial"
        },
        overlay: modalStyles.overlay
      }}
      contentLabel="Add new channel"
    >
      <div className={modal.modal}>
        <div className={styles.modalHeader}>
          <i className="material-icons-outlined">question_answer</i>
          <h2 className={styles.title}>Send a direct message to someone</h2>
        </div>

        <br />
        {/* Hide the description for 1-1 chat */}
        {selectedMembers?.length !== 1 && (
          <div className={styles.sidebarModalInput}>
            <textarea
              className={cn([input.input, input.big, input.textarea])}
              placeholder="Description"
              rows={5}
              value={newChannelDescription}
              onChange={(e) => setNewChannelDescription(e.target.value)}
            />
          </div>
        )}

        <div className={modal.popupSubsection}>
          <label>Members</label>
          <div></div>
        </div>

        <Select
          loadOptions={async (val) => {
            const result = await searchMembers({
              searchText: val
            })

            return result.data.organization.members.map((m: any) => ({
              name: m.user.fullName,
              id: m.user.id,
              email: m.user.email
            }))
          }}
          isMulti
          placeholder="Search members..."
          value={selectedMembers}
          onChange={(v) => setSelectedMembers(v as any)}
          getOptionLabel={(a) => a.name}
          getOptionValue={(a) => a.id}
        />
        <button
          className={cn([button.button, button.primary, button.noAnimation, button.big, styles.sidebarModalButton])}
          style={{ opacity: !selectedMembers?.length ? 0.75 : 1 }}
          disabled={!selectedMembers?.length}
          onClick={async () => {
            await createDM({
              variables: {
                members: selectedMembers.map((a) => (a as any).id),
                type: "dm",
                description: newChannelDescription
              }
            })
            closeModal()
            if (!window.location.href.includes("/chat")) history.push(routes.chat)
          }}
        >
          <i className="material-icons-outlined">send</i>
          <span>Send message</span>
        </button>
        <div ref={bottomRef}></div>
        <div className={modal.closeButton} onClick={() => closeModal()}>
          <i className="material-icons">close</i>
        </div>
      </div>
    </Modal>
  )
}

const DMItem: React.FC<{
  unread: number
  members: MemberEntity[]
  name: string
  click: () => void
  active: boolean
}> = ({ unread, members, name, click, active }) => {
  const { collapsed } = useContext(SidebarContext)

  if (!members.length) {
    return null
  }

  // const member = members[0]

  return (
    <Tooltip
      title={name + (unread > 0 ? ` (${unread})` : "")}
      disabled={!collapsed}
      position="right"
      distance={-65}
      delay={50}
      trigger="mouseenter"
      size="big"
      animation="shift"
    >
      <div
        className={cn([styles.item, unread > 0 ? styles.unreadCollapsed : null, active ? styles.activeItem : null])}
        onClick={click}
      >
        {/* {members.length >= 2 && (
          <div className={cn([styles.icon, styles.multiple])}>
            <ProfilePicture
              unclickable={true}
              circle={true}
              size={"small"}
              userName={member.name}
              userId={member.id}
              src={member.profilePicture}
            />
            <div className={styles.groupCount}>
              <span>{members.length}</span>
            </div>
          </div>
        )} */}
        {/* {members.length === 1 && (
          <div className={styles.icon}>
            <ProfilePicture
              unclickable={true}
              size={"small"}
              circle={true}
              userName={member.name}
              userId={member.id}
              src={member.profilePicture}
              statusMode={"outline"}
            />
          </div>
        )} */}
        <span className={styles.name}>{textEllipsis(name, 25)}</span>
        {unread > 0 && (
          <div className={styles.badge}>
            <span>{unread.toString()}</span>
          </div>
        )}
      </div>
    </Tooltip>
  )
}

const SidebarDMs: React.FC<{ setDirectUnreads: any }> = ({ setDirectUnreads }) => {
  const { collapsed, setCollapsed } = useContext(SidebarContext)
  const { data } = useSidebarChannelsQuery({
    onError: handleGraphQlError("Unable to get message")
  })
  const [selectDM] = useSidebarChannels_SelectChannelMutation({
    onError: handleGraphQlError("Unable to select message")
  })

  const [createDM] = useSidebarChannels_CreateChannelMutation({
    onError: handleGraphQlError("Unable to create DM")
  })
  const { data: org } = useOrganization_OrganizationQuery({
    onError: handleGraphQlError("Unable to get organizations")
  })

  const [creating, setCreating] = useState(false)
  const history = useHistory()
  const [creatingGroup, setCreatingGroup] = useState(false)
  const [showAllDms, setShowAllDms] = useState(false)
  const organization = org?.organization

  useEffect(() => {
    if (data) {
      const token = pubsub.subscribe(topics.DM_OPEN, async (_: string, id: string) => {
        const dms = data.groups.filter((g) => g.type === "1-1")
        const dm = dms.filter((dm) => dm.members.filter((m) => m.id === id).length > 0)

        if (dm.length) {
          await selectDM({
            variables: {
              id: dm[0].id
            }
          })
        } else {
          await createDM({
            variables: {
              type: "1-1",
              members: [id]
            }
          })
        }
        if (!window.location.href.includes("/chat")) history.push(routes.chat)
      })

      return () => {
        pubsub.unsubscribe(token)
      }
    }
  }, [data])

  if (!data) {
    return null
  }

  const dms = data.groups.filter((g) => g.type === "dm" || g.type === "1-1")
  const members = take(data.potentialGroupMembers, 5)

  const existingDms = dms.map((dm) => ({
    id: dm.id,
    unread: dm.unreadCount,
    members: dm.members.filter((x) => x.id !== data.me?.id),
    name: dm.name,
    onClick: () => {
      // if (isMobile) setCollapsed(true)
      setTimeout(() => setDirectUnreads(false), 4000)

      if (dm.id === data.activeSession.groupId || creatingGroup) {
        if (!window.location.href.includes("/chat")) history.push(routes.chat)
        return
      }
      const ready = dm.name
      const containsSession = viewTabs.some((tab: { session: { name: string } }) => tab.session.name === ready)
      if (!containsSession) {
        newTab({
          name: dm.name,
          id: "",
          icon: "chat",
          groupId: dm.id,
          location: routes.chat,
          type: "direct:" + dm.id,
          active: true,
          forceOpen: true
        })
      } else {
        const existing = viewTabs.find((tab: { session: { name: string } }) => tab.session.name === ready)
        if (existing) setActiveTab(existing.session.id)
      }
      if (!window.location.href.includes("chat")) history.push(routes.chat)
      setCreatingGroup(true)
      selectDM({ variables: { id: dm.id } })
      setCreatingGroup(false)
    },
    active: data.activeSession.groupId === dm.id
  }))

  const potentialMembers = members.map((member) => ({
    id: member.id,
    unread: 0,
    members: [{ id: member.id, name: member.fullName, profilePicture: member.profilePicture }],
    name: member.fullName,
    onClick: async () => {
      if (creatingGroup) {
        return
      }

      setCreatingGroup(true)
      await createDM({
        variables: {
          type: "1-1",
          members: [member.id]
        }
      })
      setCreatingGroup(false)
      if (isMobile) {
        setCollapsed(true)
      }

      if (!window.location.href.includes("/chat")) history.push(routes.chat)
    },
    active: false
  }))

  const allDms = orderBy(concat(existingDms, potentialMembers), ["unread", "name"], ["desc", "asc"])
  const orgDMs = allDms
    .filter((dm) => organization?.members.find((m) => m.user.id === dm.members[0].id))
    .map((dm) => organization?.members.find((m) => m.user.id === dm.members[0].id)?.user.fullName)

  return (
    <div className={styles.section}>
      <div className={styles.heading}>
        <span className={styles.title}>Direct</span>
        <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
          <div onClick={() => setShowAllDms(!showAllDms)} className={styles.plusIcon}>
            {showAllDms ? <i className="material-icons">inventory</i> : <i className="material-icons">domain</i>}
          </div>
          <div onClick={() => setCreating(true)} className={styles.plusIcon}>
            <i className="material-icons">add</i>
          </div>
        </div>
      </div>

      {creating && <CreateDMModal setCreating={setCreating} />}

      <div className={styles.selectChats}>
        {allDms.map((dm) => {
          if (!orgDMs.includes(dm.name) && !showAllDms) return
          if (dm.name.includes(",")) return
          if (dm.unread > 0) setDirectUnreads(true)

          return (
            <DMItem
              key={dm.id}
              unread={dm.unread}
              members={dm.members}
              name={dm.name}
              click={dm.onClick}
              active={dm.active}
            />
          )
        })}
        {(collapsed || !dms.length) && (
          <Tooltip
            title={"Start Conversation"}
            disabled={!collapsed}
            position="right"
            distance={-65}
            delay={50}
            trigger="mouseenter"
            size="big"
          >
            <div className={cn([styles.item, styles.plusButton])} onClick={() => setCreating(true)}>
              <div className={styles.icon}>
                <i style={{ fontSize: "20px" }} className="material-icons">
                  add
                </i>
              </div>
              <span className={styles.name}>Start Conversation</span>
            </div>
          </Tooltip>
        )}
      </div>
      <br />
      <div className={styles.heading}>
        <span className={styles.title}>Groups</span>
      </div>
      <br />
      <div className={styles.items}>
        {allDms.map((dm) => {
          if (dm.name.includes(",")) {
            return (
              <DMItem
                key={dm.id}
                unread={dm.unread}
                members={dm.members}
                name={dm.name}
                click={dm.onClick}
                active={dm.active}
              />
            )
          }
        })}
      </div>
    </div>
  )
}
Modal.setAppElement("#root")

export default SidebarDMs
