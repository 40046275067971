import React, { useState } from "react"
import styles from "./LazyImage.module.scss"

const LazyImage: React.FC<{ src: string; alt: string; top?: boolean }> = ({ src, alt, top }) => {
  const [loaded, setLoaded] = useState(false)
  const [error, setError] = useState(false)

  const onLoad = () => {
    setLoaded(true)
  }
  const onError = () => {
    setError(true)
    setLoaded(true)
  }

  return (
    <div className={styles.root}>
      {!error && (
        <React.Fragment>
          <div className={styles.placeholder}></div>
          <img
            style={{ objectPosition: top ? "top" : undefined }}
            className={loaded ? styles.loaded : ""}
            onLoad={onLoad}
            onError={onError}
            src={src}
            alt={alt}
          />
        </React.Fragment>
      )}

      {error && (
        <div className={styles.error}>
          <i className="material-icons">broken_image</i>
        </div>
      )}
    </div>
  )
}

export default LazyImage
