/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react"

const GroupSelector: React.FC<{
  defaultGroup: string
  groups: { name: string; item: any[] }[]
  content: (group: any, setSelectedMode: (s: string) => void, otherGroups: string[]) => JSX.Element
}> = ({ defaultGroup, groups, content }) => {
  const [selectedGroup, setSelectedGroup] = useState(defaultGroup)
  const group = groups.find((x) => x.name === selectedGroup)
  const otherGroups = groups.map((x) => x.name).filter((x) => x !== selectedGroup)

  if (!group) {
    return null
  }

  return <>{content(group, setSelectedGroup, otherGroups)}</>
}

export default GroupSelector
