import React, { useRef, useState } from "react"
import cn from "clsx"
import input from "modules/shared/styles/components/input.module.scss"
import styles from "./SearchControl.module.scss"
import { isEnter } from "utils/common"
import { clearUsedFolders, setFilterByBranch, setFolderCycle } from "../folderTreeView/FolderTreeView"

export let searchQuery = ""
export let clearSearch: () => void
const SearchControl: React.FC<{ mode: string; query?: string | null; setQuery: (s: string) => void }> = ({
  query,
  mode,
  setQuery
}) => {
  const searchInput = useRef<HTMLInputElement>(null)
  const [localQuery, setLocalQuery] = useState(query)

  clearSearch = function () {
    setQuery("")
    clearUsedFolders()
    setLocalQuery("")
    searchQuery = ""
  }

  return (
    <>
      <div className={styles.searchInputContainer}>
        <input
          ref={searchInput}
          value={localQuery || ""}
          onChange={(ev) => {
            setLocalQuery(ev.target.value)
            searchQuery = ev.target.value

            if (mode !== "web") {
              setQuery(ev.target.value)
            }
            setFilterByBranch("root")
            setFolderCycle(1)

            if (ev.target.value.trim() === "") {
              setQuery("")
              clearUsedFolders()
            }
          }}
          onKeyPress={(e) => {
            if (isEnter(e)) {
              setQuery(localQuery || "")
              e.preventDefault()
            }
          }}
          className={cn([input.input, input.small])}
          placeholder="Search query..."
        ></input>
        <i className={cn(["material-icons", styles.searchIcon])}>search</i>
        {mode !== "web" && (
          <span className={cn([styles.tap, localQuery !== query ? styles.changed : null])}>TAP ENTER</span>
        )}
        <i
          onClick={() => {
            setQuery("")
            clearUsedFolders()
            setLocalQuery("")
            searchQuery = ""
          }}
          className={cn(["material-icons", styles.closeBtn])}
        >
          close
        </i>
      </div>
    </>
  )
}

export default SearchControl
