/* eslint-disable */
import React, { useContext } from "react"
import Popup from "reactjs-popup"
import styles from "../../styles/components/popupPanel.module.scss"
import PopupContext from "./PopupContext"

const PopupButton: React.FC<{
  trigger: JSX.Element
  content: (close: () => void) => JSX.Element
}> = ({ content, trigger }) => {
  const { setIsActive } = useContext(PopupContext)

  return (
    <Popup
      trigger={<div className={styles.root}>{trigger}</div>}
      position="bottom center"
      offsetY={10}
      onOpen={() => {
        if (setIsActive) setIsActive(true)
      }}
      onClose={() => {
        if (setIsActive) setIsActive(false)
      }}
      arrowStyle={{ color: "#fff" }}
      contentStyle={{ width: 300, backgroundColor: "#fff" }}
    >
      {
        ((close: () => void) => {
          return content(close)
        }) as any
      }
    </Popup>
  )
}

export default PopupButton
