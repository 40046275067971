import React, { useEffect, useState } from "react"
import { useUserChatStatus_OnUserChatStatusChangedSubscription, useUserChatStatusQuery } from "generated/graphql"
import styles from "./UserChatStatus.module.scss"
import { handleGraphQlError } from "utils/common"
import cn from "clsx"

const UserStatus: React.FC = () => {
  const [status, setStatus] = useState<string>("")
  const { data: chatData } = useUserChatStatusQuery({
    onError: handleGraphQlError("Unable to get chat status")
  })
  const { data } = useUserChatStatus_OnUserChatStatusChangedSubscription({
    variables: {
      session: chatData?.activeSession.id || ""
    },
    skip: !chatData?.activeSession.id
  })

  useEffect(() => {
    setStatus(data?.userChatStatusChanged?.status || "")
  }, [data?.userChatStatusChanged])

  if (!status) {
    return null
  }

  return (
    <div className={cn(styles.root, chatData?.activeAppSpace ? styles.mobileAppSpaceOpen : null)}>
      <div className={styles.bubbles}>
        <div></div>
        <div className={styles.two}></div>
        <div className={styles.three}></div>
      </div>
      <p className={styles.msg}>{status}</p>
    </div>
  )
}

export default UserStatus
