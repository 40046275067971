/* eslint-disable */
import React, { useContext, useEffect, useRef, useState } from "react"
import styles from "./SessionTab.module.scss"
import { ReactComponent as TimesIcon } from "images/icons/times.svg"
import {
  useSessionTabsQuery,
  useSessions_CloseSessionMutation,
  useSessions_SelectSessionMutation,
  useSessions_RenameSessionMutation,
  useSessions_CreateSessionMutation,
  useSidebarChannels_SelectChannelMutation
} from "generated/graphql"
import { handleGraphQlError } from "utils/common"
import { routes } from "consts"
import { useHistory } from "react-router-dom"
import getSessionTabIcon, { SetSessionTabIcon } from "./getSessionTabIcon"
import cn from "clsx"
import SplitterContext from "modules/shared/components/splitterContext"
import { newTab, updateTab, viewTabs, writeTabs } from "../sessionTabs/SessionTabs"

const SessionTab: React.FC<{
  session: {
    id: string
    name: string
    icon?: string | null
    type?: string | undefined
    location?: string | null
    groupId?: string | null
    showTooltip?: boolean
  }
  active: string
  setActive: (id: string) => void
  only: boolean
  adjustMinWidth: boolean
  toggleTooltip: any
}> = ({ session, only, active, setActive, adjustMinWidth, toggleTooltip }) => {
  const history = useHistory()
  const [isRenaming, setIsRenaming] = useState(false)
  const [sessionName, setSessionName] = useState("")
  const sessionNameRef = useRef<any>()
  const tabHoverRef = useRef<HTMLDivElement>(null)
  const [selectSession, { loading }] = useSessions_SelectSessionMutation({})
  const [closeSession] = useSessions_CloseSessionMutation({})
  const [renameSession] = useSessions_RenameSessionMutation({
    onError: handleGraphQlError("Unable to rename session")
  })
  const [selectChannel] = useSidebarChannels_SelectChannelMutation({
    onError: handleGraphQlError("Unable to select channel")
  })
  const [createSession] = useSessions_CreateSessionMutation({
    onError: handleGraphQlError("Unable to create session")
  })
  const { data: backendTabs } = useSessionTabsQuery({})

  if (!localStorage.cachedURL) localStorage.cachedURL = ""
  if (active === session.id && localStorage.cachedURL) {
    if (!session.name.includes("|") && window.location.href === window.location.origin + "/") {
      if (window.location.href === window.location.origin + "/") history.push(localStorage.cachedURL)
    } else document.title = "Pretzel - " + session.name
  }

  const { setSessionLoadingState } = useContext(SplitterContext)

  const input = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (sessionNameRef && sessionNameRef.current) {
      toggleTooltip(session.id, sessionNameRef.current.offsetWidth < sessionNameRef.current.scrollWidth)
    }
  }, [sessionNameRef, active])

  useEffect(() => setSessionLoadingState(loading), [loading])
  if (!active) return null

  useEffect(() => {
    // Upon return back to Pretzel, select chat, if active.
    // if (active === session.id) {
    //   if (session.groupId && !window.location.href.includes("/chat")) history.push(routes.chat)
    //   else if (window.location.href.includes("/tx")) {
    //     return
    //   } else if (!window.location.href.includes("/chat")) history.push(routes.home)
    // }
  }, [])

  function handleCloseSession(id: string, type: any, groupId: any) {
    /**
     * DO NOT CHANGE ORDER OF IF STATEMENTS.
     * LAST TAB, close it and create a new one.
     * Force close All BACKEND tabs.
     */
    if (only) {
      const useId = Date.now().toString() + "-"
      writeTabs([
        {
          __typename: "AppSessionSession",
          id: useId,
          isActive: false,
          session: {
            __typename: "Session",
            id: useId,
            name: "",
            icon: "default",
            type: "",
            location: routes.home,
            createdDate: "",
            isActive: false,
            isClosed: false,
            groupId: null
          }
        }
      ])
      // backendTabs!.sessions.forEach((tab: { session: { id: string } }) =>
      // closeSession({ variables: { id: tab.session.id } })
      // )
      setTimeout(() => {
        const useId = Date.now().toString()
        newTab({ name: "", id: useId, type: "", icon: "default", location: routes.home, active: true, forceOpen: true })
        createSession({}).then((res: any) => {
          const backendSession = res?.data?.createSession.activeSession.id
          updateTab(useId, "type", "home:" + backendSession)
          // if (backendSession) selectSession({ variables: { id: backendSession } })
        })
      }, 30)
      return
    }

    // if (only) {
    //   writeTabs([])
    //   const closePromises = backendTabs!.sessions.map((tab: { session: { id: string } }) =>
    //     closeSession({ variables: { id: tab.session.id } })
    //   )
    //   const useId = Date.now().toString()
    //   Promise.all(closePromises)
    //     .then(() => {
    //       newTab({
    //         name: "",
    //         id: useId,
    //         type: "",
    //         icon: "default",
    //         location: routes.home,
    //         active: true,
    //         forceOpen: true
    //       })
    //       return createSession({})
    //     })
    //     .then((res: any) => {
    //       const backendSession = res?.data?.createSession.activeSession.id
    //       updateTab(useId, "type", "home:" + backendSession)
    //       if (backendSession) selectSession({ variables: { id: backendSession } })
    //     })
    //     .catch((err: any) => {
    //       console.error(err)
    //     })
    //   return
    // }

    // Send Client CHANNEL/DM tab closure to BACKEND
    if (groupId) {
      let index = backendTabs!.sessions.findIndex((tab: any) => tab.session.groupId === groupId)
      index && closeSession({ variables: { id: backendTabs!.sessions[index].session.id } })
    }
    // Send Client Pretzel tab closure to BACKEND
    if (type?.includes("pretzel:") || type?.includes("home:")) {
      closeSession({ variables: { id: type?.split(":")[1] } })
    }
    // Process SESSION delete on BACKEND
    if (id.includes("-")) closeSession({ variables: { id } })

    // Remove tab from CLIENT SIDE (Wikis, Tasks, Orgs, Apps) FALLBACK
    let removeTab = viewTabs.filter((tab: { session: { id: string } }) => tab.session.id !== id)
    writeTabs(removeTab)

    /**
     * Select the LEFT TAB, upon closing the current tab.
     */
    let index = viewTabs.findIndex((tab: { session: { id: string } }) => tab.session.id === id)
    let lefty = viewTabs[index - 1]

    // NO LEFTY, select first tab
    if (!lefty) {
      const first = viewTabs[1]
      setActive(first.session.id)

      // If first TAB is a CHANNEL/DM, select it.
      if (first.session.groupId) {
        if (!window.location.href.includes("/chat")) history.push(routes.chat)
        selectChannel({ variables: { id: first.session.groupId } })
        return
      }
      // If first TAB is a Pretzel session, select it.
      if (first.session.type?.includes("pretzel:")) {
        if (!window.location.href.includes("/chat")) history.push(routes.chat)
        selectSession({ variables: { id: first.session.type.split(":")[1] } })
        return
      }
      // If first TAB is a HOME session, select it.
      if (first.session.type?.includes("home:")) {
        history.push(routes.home)
        selectSession({ variables: { id: first.session.type.split(":")[1] } })
        return
      }
      if (first.session.id.includes("-")) {
        selectSession({ variables: { id: first.session.id } })
        return
      }
      history.push(first.session.location)
      return
    }

    setActive(lefty.session.id)

    // Select LEFTY CLIENT Pretzel session
    if (lefty.session.type?.includes("pretzel:")) {
      if (!window.location.href.includes("/chat")) history.push(routes.chat)
      selectSession({ variables: { id: lefty.session.type.split(":")[1] } })
      return
    }
    // Select LEFTY CLIENT HOME session
    if (lefty.session.type?.includes("home:")) {
      history.push(routes.home)
      selectSession({ variables: { id: lefty.session.type.split(":")[1] } })
      return
    }
    // Select LEFTY CHANNEL/DM
    if (lefty.session.groupId) {
      if (!window.location.href.includes("/chat")) history.push(routes.chat)
      selectChannel({ variables: { id: lefty.session.groupId } })
      return
    }
    // Select LEFTY BACKEND SESSION (Fallback)
    if (lefty.session.id.includes("-")) {
      selectSession({ variables: { id: lefty.session.id } })
      return
    }
    // Select LEFTY CLIENT SIDE (Wikis, Tasks, Orgs, Apps) FALLBACK
    if (lefty.session.location) history.push(lefty.session.location)
  }

  const handleDoubleClick = (id: string) => {
    if (active === id && !isRenaming) {
      setIsRenaming(true)
      setSessionName(session.name)
      setTimeout(() => {
        if (input.current) input.current?.focus()
      }, 10)
    }
  }

  const updateSessionName = (name: string, id: string) => {
    if (sessionName && sessionName !== session.name) {
      if (!id.includes("-")) {
        alert("Received: " + sessionName)
        document.title = `Pretzel - ${sessionName}`
      } else {
        renameSession({ variables: { newName: sessionName, sessionId: session.id } })
        document.title = `Pretzel - ${sessionName}`
      }
    }
  }

  let shortName = session.name
  if (
    shortName.includes("|") &&
    (shortName.includes("th") || shortName.includes("st") || shortName.includes("nd") || shortName.includes("rd"))
  ) {
    if (shortName.split("|")[1]) shortName = `Session ${shortName.split("|")[1]}`
    else shortName = session.name
  }

  return (
    <div
      ref={tabHoverRef}
      onMouseOver={() => tabHoverRef.current && (tabHoverRef.current.style.backgroundColor = "rgba(0, 0, 0, 0.075)")}
      onMouseLeave={() => tabHoverRef.current && (tabHoverRef.current.style.background = "transparent")}
      className={
        session.id === active
          ? cn([styles.sessionActive, adjustMinWidth && styles.adjustMinWidth])
          : cn([styles.session, adjustMinWidth && styles.adjustMinWidth])
      }
      onClick={() => {
        // pubsub.publish(topics.APPSPACE_RELOAD)
        setActive(session.id)
        // Session is a CHANNEL/DM, select it
        if (session.groupId) {
          if (!window.location.href.includes("/chat")) history.push(routes.chat)
          selectChannel({ variables: { id: session.groupId } })
          return
        }
        // BACKEND selectSession & CLIENT Session Handler
        if (session.type === null) {
          if (session.location) history.push(session.location)
          if (session.id.includes("-")) selectSession({ variables: { id: session.id } })
          return
        }
        // FILES/PRETZEL Session handler (allowing multiple Pretzel sessions)
        if (session.type?.includes("pretzel:") || session.type?.includes("home:")) {
          if (session.type?.includes("pretzel:")) history.push(routes.chat)
          if (session.type?.includes("home:")) history.push(routes.home)
          selectSession({ variables: { id: session.type.split(":")[1] } })
          return
        }
      }}
      onDoubleClick={() => handleDoubleClick(session.id)}
      key={session.id}
      onMouseDown={(e) => {
        if (e.button === 1) {
          e.stopPropagation()
          return handleCloseSession(session.id, session.type, session.groupId)
        }
      }}
    >
      {session.icon ? SetSessionTabIcon(session.icon) : getSessionTabIcon(session)}
      {isRenaming && (
        <input
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              updateSessionName(sessionName, session.id)
              setIsRenaming(false)
            }
          }}
          ref={input}
          className={styles.input}
          maxLength={50}
          onBlur={() => {
            updateSessionName(sessionName, session.id)
            setIsRenaming(false)
          }}
          value={sessionName}
          onChange={(e) => setSessionName(e.target.value)}
        />
      )}
      {!isRenaming && (
        <p
          className={cn([
            adjustMinWidth && session.name.length >= 9 && styles.maxCharacterLength,
            styles.sessionName,
            styles.sessionNameTruncate
          ])}
          ref={sessionNameRef}
        >
          {shortName}
        </p>
      )}
      <div
        className={styles.sessionClose}
        onClick={(e) => {
          e.stopPropagation()
          return handleCloseSession(session.id, session.type, session.groupId)
        }}
      >
        <TimesIcon />
      </div>
    </div>
  )
}

export default SessionTab
