import React, { useContext, useEffect, useState } from "react"
import { MessageAttachment, Message_MessageFragment } from "generated/graphql"
import styles from "./MessageAttachments.module.scss"
import fileTypeToIcon from "modules/shared/components/attachment/fileTypeToIcon"
import { humanizeFileSize } from "utils/common"
import ChatContext from "modules/chat/components/chat/ChatContext"
import { PopupModal } from "modules/shared/components/popupModal/PopupModal"

const MessageAttachments: React.FC<{ message: Message_MessageFragment; isLast: boolean }> = ({ message, isLast }) => {
  const { completeLoadingCallback } = useContext(ChatContext)
  const [counter, setCounter] = useState(0)

  useEffect(() => {
    if (message.attachments) {
      if (isLast && counter === message.attachments.length) {
        const callbackTimeout = setTimeout(() => {
          completeLoadingCallback(message.id)
        }, 200)
        return () => {
          clearTimeout(callbackTimeout)
        }
      }
    }
  }, [counter])

  const renderContent = (attachment: MessageAttachment) => {
    if (attachment.type.startsWith("image/")) {
      return (
        <>
          <div className={styles.header}>
            <span>{attachment.name}</span>
          </div>
          <img
            className={styles.img}
            loading={"lazy"}
            onClick={() => PopupModal(attachment.url, attachment.type)}
            onLoad={() => setCounter((c) => c + 1)}
            src={attachment.url}
            alt={attachment.name}
          />
        </>
      )
    }
    if (attachment.type.startsWith("video/")) {
      return (
        <>
          <div className={styles.header}>
            <span>{attachment.name}</span>
          </div>
          <video
            className={styles.img}
            controls
            preload="metadata"
            onLoadStart={() => setCounter((c) => c + 1)}
            src={attachment.url}
            title={attachment.name}
          />
        </>
      )
    }
    // if (attachment.type.startsWith("application/pdf")) {
    //   return (
    //     <>
    //       <div className={styles.header}>
    //         <span>{attachment.name}</span>
    //       </div>
    //       <div className={styles.img}>
    //         <iframe
    //           style={{ width: "100%", height: "50vh" }}
    //           onLoad={() => setCounter((c) => c + 1)}
    //           src={attachment.url}
    //           title={attachment.name}
    //         />
    //       </div>
    //     </>
    //   )
    // }
    return (
      <a href={attachment.url} target="_blank" rel="noreferrer">
        <div className={styles.unknown}>
          <img
            onLoad={() => setCounter((c) => c + 1)}
            src={fileTypeToIcon(attachment.type)}
            alt={attachment.name}
            loading="lazy"
          />
          <div className={styles.text}>
            <span className={styles.name}>{attachment.name}</span>
            <span className={styles.size}>{humanizeFileSize(attachment.size)}</span>
          </div>

          <div className={styles.download}>
            <i className="material-icons-outlined">download</i>
          </div>
        </div>
      </a>
    )
  }

  if (!message.attachments) {
    return null
  }

  return (
    <div className={styles.root}>
      {message.attachments?.map((attachment) => (
        <div className={styles.item} key={attachment.id}>
          {renderContent(attachment)}
        </div>
      ))}
    </div>
  )
}

export default MessageAttachments
