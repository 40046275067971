import React, { useEffect, useMemo, useRef, useState } from "react"
import TextEditorPopup from "./../TextEditorPopup"
import { useEmojiSelectorQuery } from "generated/graphql"
import styles from "./EmojiSelector.module.scss"
import spinner from "modules/shared/styles/components/spinner.module.scss"
import cn from "clsx"
import pubsub from "pubsub-js"

const EmojiSelector: React.FC = () => {
  const [searchText, setSearchText] = useState<string>("")
  const [focused, setFocused] = useState(0)
  const emojisContainer = useRef<HTMLDivElement>(null)

  const { data } = useEmojiSelectorQuery()

  const filtered = useMemo(
    () => (data ? data.emoticons.filter((x) => x.code.includes(searchText)) : []),
    [data, searchText]
  )

  useEffect(() => {
    if (emojisContainer.current) {
      emojisContainer.current.children[focused].scrollIntoView({
        behavior: "smooth"
      })
    }
  }, [focused])

  return (
    <TextEditorPopup
      filtered={filtered}
      searchText={searchText}
      setSearchText={setSearchText}
      topic={"emojiSelector"}
      focused={focused}
      setFocused={setFocused}
    >
      {(hide: () => void) => (
        <div className={styles.root}>
          {searchText && (
            <div className={styles.top}>
              <span>
                Emojis for <b>{searchText}</b>
              </span>
            </div>
          )}
          <div className={styles.middle}>
            {!data && (
              <div className={styles.loading}>
                <div className={cn([spinner.spinner, spinner.small])}></div>
                <span>Loading reactions...</span>
              </div>
            )}

            {data && (
              <div className={styles.emojis} ref={emojisContainer}>
                {filtered.map((e, i) => (
                  <div
                    className={cn([styles.emoji, i === focused ? styles.focused : null])}
                    key={i}
                    onClick={() => {
                      pubsub.publish("userSelector.select", {
                        id: e.id,
                        code: e.code,
                        name: e.name,
                        icon: e.icon
                      })
                      hide()
                    }}
                  >
                    <img src={e.icon} alt={e.code} />
                    <div className={styles.text}>
                      <span className={styles.code}>{e.code}</span>
                      <span className={styles.name}>{e.name}</span>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className={styles.bottom}>
            <div className={styles.instruct}>
              <kbd>&#8593;</kbd>
              <kbd>&#8595;</kbd>
              <span>to select</span>
            </div>
            <div className={styles.instruct}>
              <kbd>
                <i className="material-icons">subdirectory_arrow_left</i>
              </kbd>
              <span>to mention</span>
            </div>
            <div className={styles.instruct}>
              <kbd>Esc</kbd>
              <span>to dismiss</span>
            </div>
          </div>
        </div>
      )}
    </TextEditorPopup>
  )
}

export default EmojiSelector
