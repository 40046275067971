import SplitterContext from "modules/shared/components/splitterContext"
import React, { useContext } from "react"
import styles from "./PinnedVideo.module.scss"
import cn from "clsx"

const PinnedVideo: React.FC = () => {
  const { pinnedEmbedHtml, setPinnedEmbedHtml, dragging } = useContext(SplitterContext)

  return (
    <div className={cn(styles.root, dragging ? styles.dragging : "")}>
      <div className={styles.iframeContainer} dangerouslySetInnerHTML={{ __html: pinnedEmbedHtml }}></div>

      <div className={styles.closeButton} onClick={() => setPinnedEmbedHtml("")}>
        <span className="material-icons">close</span>
        <span className={styles.label}>Unpin Video</span>
      </div>
    </div>
  )
}

export default PinnedVideo
