/* eslint-disable */
import React, { useState, useContext, createContext, useEffect, useRef } from "react"

import cn from "clsx"
import input from "modules/shared/styles/components/input.module.scss"
import styles from "../TableResult.module.scss"
import dataTable from "modules/shared/styles/components/dataTable.module.scss"
import dataPanel from "modules/shared/components/dataPanel/DataPanel.module.scss"

import { Message_MessageFragment, useChat_ChatMutation } from "generated/graphql"
import { TableContainer, Table } from "../models"

import SaveButton from "../../saveButton"
import InsertButton from "../../insertButton"

import TabGroup from "modules/shared/components/tabGroup"
import _ from "lodash"

const Context = createContext<{
  container: TableContainer

  tables: Table[]
  activeTable: Table
  setActiveTable: (table: Table) => void

  searchText: string
  doSearch: (query: string) => void

  showAllRows: boolean
  setShowAllRows: (a: boolean) => void

  setFilterValue: (header: string, value: string) => void
  setExpandable: (val: boolean) => void

  filteredData: string[][]
  setFilteredData: (data: string[][]) => void

  appSpaceMode?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}>({} as any)

const TopHeader: React.FC<{ message: Message_MessageFragment }> = ({ message }) => {
  const searchInput = useRef<HTMLInputElement>(null)
  const { activeTable, container, tables, filteredData, doSearch, searchText, appSpaceMode } = useContext(Context)
  const getContent = () => ({ ...activeTable, filteredData })
  const [searchToggle, setSearchToggle] = useState(false)
  const [chat] = useChat_ChatMutation()

  useEffect(() => {
    searchInput.current?.focus()
  }, [searchToggle, searchText])

  const exportToChatLane = () => {
    chat({
      variables: {
        message: "",
        silent: true,
        type: "appSpacePreview"
      }
    })
  }

  return (
    <div className={dataTable.thead}>
      <div className={cn(styles.headerContainer, styles.DATABASE)}>
        <div className={styles.headerContent}>
          <div className={styles.tableHeader}>
            <div className={styles.meta}>
              {!searchToggle && (
                <>
                  <span className={styles.title}>{container.title || "Multi Table View"}</span>
                  <div className={styles.wikiRow}>
                    {container.source && <span className={styles.description}>{container.source.name || ""}</span>}
                    {!container.source && (
                      <span className={styles.description}>
                        Showing {filteredData.length} rows out of {activeTable.data.length}
                      </span>
                    )}
                  </div>
                </>
              )}
              {searchToggle && (
                <div className={styles.searchInput}>
                  <input
                    onChange={(e) => doSearch(e.target.value)}
                    className={cn(input.input, input.small)}
                    placeholder="Search"
                    value={searchText}
                    ref={searchInput}
                  />
                  <i className={cn("material-icons", styles.searchIcon)}>search</i>
                </div>
              )}
            </div>
            <div className={styles.buttonsContainer}>
              <div className={styles.buttonsGroup}>
                {appSpaceMode && (
                  <button className={cn(styles.sideButton, styles.DATABASE)} onClick={exportToChatLane}>
                    <i className="material-icons">arrow_back</i>
                    <span>Add to Chat Lane</span>
                  </button>
                )}
                <button
                  className={cn(styles.sideButton, styles.DATABASE)}
                  onClick={() => setSearchToggle(!searchToggle)}
                >
                  <i className="material-icons">search</i>
                  <span>Search</span>
                </button>
                {!appSpaceMode && (
                  <>
                    <SaveButton
                      type="content"
                      from={`tableFromMessage:withHeaders||${message.id}||${tables.indexOf(activeTable)}`}
                      name={container.title || "Table"}
                    >
                      <button className={cn(styles.sideButton, styles.DATABASE)}>
                        <i className="material-icons">add</i>
                        <span>Save Table</span>
                      </button>
                    </SaveButton>
                    <SaveButton type="content" from={`DB||${message.id}`} name={container.title || "Table"}>
                      <button className={cn(styles.sideButton, styles.DATABASE)}>
                        <i className="material-icons">arrow_forward</i>
                        <span>Save as DB</span>
                      </button>
                    </SaveButton>
                    <InsertButton type="table" getContent={() => getContent()}>
                      <button className={cn(styles.sideButton, styles.DATABASE)}>
                        <i className="material-icons">arrow_forward</i>
                        <span>Insert into</span>
                      </button>
                    </InsertButton>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Tabs: React.FC = () => {
  const { setActiveTable, activeTable, container } = useContext(Context)

  return (
    <TabGroup
      theme="database"
      activeTab={activeTable.title}
      tabs={container.tables.map((t) => t.title)}
      setActiveTab={(tab: string) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setActiveTable(container.tables.find((t) => t.title === tab) as any)
      }}
    />
  )
}

const TableComp: React.FC<{ message: Message_MessageFragment }> = ({ message }) => {
  const ctx = useContext(Context)
  const { filteredData, setFilteredData, searchText } = useContext(Context)
  const table = ctx.activeTable
  const filters = ctx.activeTable.filters || {}
  const filerValues = table.filterValues || {}

  function getFilter(header: string) {
    if (!filters[header]) {
      return null
    }

    const filter = filters[header]

    if (filter === "dropdown") {
      const ind = table.headers.indexOf(header)
      const uniquValues = _.uniq(table.data.map((t) => t[ind]))
      return (
        <select
          onChange={(e) => {
            ctx.setFilterValue(header, e.target.value)
          }}
          value={filerValues[header]}
          className={cn(input.input, input.small)}
        >
          <option value="">All</option>
          {uniquValues.map((val, i) => (
            <option value={val} key={i}>
              {val}
            </option>
          ))}
        </select>
      )
    } else if (filter === "text") {
      return (
        <input
          placeholder={`Filter by ${header}`}
          value={filerValues[header]}
          onChange={(e) => ctx.setFilterValue(header, e.target.value)}
          className={cn(input.input, input.small)}
        ></input>
      )
    }

    return null
  }

  function renderHeader(header: string, i: number) {
    return (
      <th key={i} className={cn([dataTable.DATABASE])} style={{ padding: "3px" }}>
        <span className={styles.columnTitle}>{header}</span>
      </th>
    )
  }

  function renderFilter(header: string, i: number) {
    return (
      <th key={i} className={cn([dataTable.DATABASE])} style={{ padding: "3px", paddingBottom: 10 }}>
        {getFilter(header)}
      </th>
    )
  }

  function filterData(): string[][] {
    let allData = table.data
    const filerKeys = Object.keys(filerValues)
    if (filerKeys.length > 0) {
      allData = allData.filter((d) => {
        // return only if no unmatching filers
        return !d.some((dt, i) => {
          // go through filters
          return filerKeys.some((fk) => {
            const filerValue = filerValues[fk]
            if (!filerValue) {
              return false
            }
            const index = table.headers.indexOf(fk)
            const filter = table.filters[fk]
            const str = dt.toString()
            // only if ther header index matches
            if (i === index) {
              if (filter === "dropdown") {
                return filerValue !== str
              }
              return str.toLowerCase().indexOf(filerValue.toLowerCase()) < 0
            }
            return false
          })
        })
      })
    }

    if (ctx.searchText) {
      allData = allData.filter((d) =>
        d.some((dt) => dt.toString().toLowerCase().indexOf(ctx.searchText.toLowerCase()) > -1)
      )
    }

    ctx.setExpandable(allData.length > 15)
    if (allData.length > 15) {
      allData = _.take(allData, ctx.showAllRows ? allData.length : 15)
    }

    return allData
  }

  useEffect(() => {
    const data = filterData()
    setFilteredData(data)
  }, [filters, table, ctx.showAllRows, searchText])

  function renderData() {
    return (
      <tbody className={styles.tbody}>
        {filteredData.map((row, ri) => {
          return (
            <tr key={ri}>
              {row.map((col, ci) => (
                <td key={ci}>{col}</td>
              ))}
            </tr>
          )
        })}
      </tbody>
    )
  }

  return (
    <div className={styles.contentContainer}>
      <table className={cn(dataTable.dataTable, styles.table)}>
        <thead>
          <tr>{table?.headers.map(renderHeader)}</tr>
          <tr>{table?.headers.map(renderFilter)}</tr>
        </thead>
        {renderData()}
      </table>
    </div>
  )
}

const MultiTableView: React.FC<{
  container: TableContainer
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  message: any
  oninit?: () => void
  appSpaceMode?: boolean
}> = ({ container, message, oninit, appSpaceMode }) => {
  const [activeTable, setActiveTable] = useState(container.tables[0])
  const [query, setQuery] = useState("")
  const [allRows, setAllRows] = useState(appSpaceMode ? true : false)
  const [expandable, setExpandable] = useState(false)
  const [filteredData, setFilteredData] = useState<string[][]>([])

  useEffect(() => {
    if (oninit) {
      oninit()
    }
  }, [])

  return (
    <Context.Provider
      value={{
        container,
        tables: container.tables,
        activeTable,
        setActiveTable,
        searchText: query,
        doSearch: setQuery,
        showAllRows: allRows,
        setShowAllRows: setAllRows,
        setFilterValue: (header, value) => {
          setActiveTable({
            ...activeTable,
            filterValues: {
              ...(activeTable.filterValues || {}),
              [header]: value
            }
          })
        },
        setExpandable,
        filteredData,
        setFilteredData,
        appSpaceMode
      }}
    >
      <div className={appSpaceMode ? undefined : styles.root}>
        <div className={appSpaceMode ? undefined : cn(styles.dataTable, dataTable.dataTable, dataTable.messageResult)}>
          <TopHeader message={message} />
          <Tabs />
          <TableComp message={message} />
          {expandable && !appSpaceMode && (
            <div
              className={cn(dataPanel.readmoreContainer, styles.readMoreSpacing, dataPanel.DATABASE)}
              onClick={() => setAllRows(!allRows)}
            >
              {!allRows && <i className="material-icons">expand_more</i>}
              {allRows && <i className="material-icons">expand_less</i>}
              {allRows ? "SHOW LESS" : "SHOW MORE"}
            </div>
          )}
        </div>
      </div>
    </Context.Provider>
  )
}

export default MultiTableView
