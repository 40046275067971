/* eslint-disable */
import React, { useState, useContext, useEffect, useRef } from "react"
import cn from "clsx"
import { DateTime } from "luxon"
import pubsub from "pubsub-js"

import { groupSessionsByAge, handleGraphQlError, sortByCreatedDate } from "utils/common"
import { KeyCodes, topics } from "consts"
import {
  useSessions_ReopenSessionMutation,
  useSessions_RenameSessionMutation,
  useSessions_SelectSessionMutation
} from "generated/graphql"

import styles from "../fileFolderTableView/FileFolderTableView.module.scss"
import input from "modules/shared/styles/components/input.module.scss"

import { FileListingContext } from "../fileListing/FileListing"
import SessionOptionsMenu from "../optionsButtons/sessionOptionsMenu"
import ExpandableRecentsList from "modules/shared/components/expandableRecentsList"

export type Session = {
  id: string
  name: string
  createdDate: Date
  isClosed: boolean
  isActive: boolean
}

type SessionsGroup = {
  name: string
  defaultExpanded: boolean
  items: Session[]
}

const SessionsListing: React.FC<{
  sessions: Session[] | undefined
  query: string
}> = ({ sessions, query }) => {
  const filtered = query ? sessions?.filter((x) => x.name.toLowerCase().includes(query?.toLocaleLowerCase())) : sessions
  const sorted = sortByCreatedDate(filtered || [])
  const sessionGroups = groupSessionsByAge(sorted || [])

  const [reopenSession] = useSessions_ReopenSessionMutation({
    onError: handleGraphQlError("Unable to reopen session")
  })
  const { reloadSessionData } = useContext(FileListingContext)
  const [selectSession] = useSessions_SelectSessionMutation({
    onError: handleGraphQlError("Unable to select session")
  })

  useEffect(() => {
    const token = pubsub.subscribe(topics.SESSIONS_UPDATED, (_: string) => {
      reloadSessionData()
    })

    return () => {
      pubsub.unsubscribe(token)
    }
  }, [])

  async function handleOpenSession(session: Session) {
    if (session.isClosed) {
      reopenSession({ variables: { sessionId: session.id } })
      pubsub.publish(topics.SESSIONS_UPDATED)
    } else if (!session.isActive) {
      selectSession({
        variables: {
          id: session.id
        }
      })
    }
  }

  return (
    <>
      <table className={styles.filesTable}>
        <thead>
          <tr className={styles.filesTableHeader}>
            <th>Session Name</th>
            <th>Created</th>
            <th>State</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {sessionGroups.map((group, i) => (
            <SessionGroup
              handleOpenSession={handleOpenSession}
              nonClosedSessions={sessions || []}
              key={i}
              group={group}
            />
          ))}
        </tbody>
      </table>

      {/* {pages > 1 && <SearchPagination compact={true} currentPage={page} goto={(page) => setPage(page)} total={pages} />} */}
    </>
  )
}

const SessionGroup: React.FC<{
  group: SessionsGroup
  nonClosedSessions: Session[]
  handleOpenSession: (session: Session) => void
}> = ({ group, nonClosedSessions, handleOpenSession }) => {
  const [expanded, setExpanded] = useState(group.defaultExpanded)

  return (
    <>
      {group.name !== "Today" && (
        <tr className={cn(styles.filetr, styles.sessionList)} onClick={() => setExpanded(!expanded)}>
          <td className={styles.filename}>
            <i
              onClick={() => setExpanded(!expanded)}
              className={cn(styles.plusIcon, expanded ? "fa fa-minus-square" : "fa fa-plus-square")}
            />
            <i className="fa fa-calendar"></i>
            <span className={styles.sessionName} style={{ fontWeight: "bold" }}>
              {group.name}
            </span>
          </td>
          <td></td>
          <td></td>
        </tr>
      )}

      {(expanded || group.name === "Today") && (
        <ExpandableRecentsList
          items={group.items}
          itemTypeName={"sessions"}
          itemRenderer={(item, i) => (
            <SessionItem
              key={i}
              session={item}
              i={i}
              nonClosedSessions={nonClosedSessions}
              handleOpenSession={handleOpenSession}
              indented={group.name !== "Today"}
            />
          )}
          showMoreLessLabel={(text, toggle) => (
            <tr onClick={toggle}>
              <td
                className={styles.loadAllButton}
                style={{ paddingBottom: 5, marginLeft: group.name === "Today" ? 0 : 20 }}
              >
                <span>{text}</span>
              </td>
            </tr>
          )}
        />
      )}
    </>
  )
}

const SessionItem: React.FC<{
  session: Session
  handleOpenSession: (session: Session) => void
  nonClosedSessions: Session[]
  i: number
  indented: boolean
}> = ({ session, handleOpenSession, nonClosedSessions, i, indented }) => {
  const [renaming, setRenaming] = useState(false)
  const [currentRenamingName, setCurrentRenamingName] = useState("")
  const renameInput = useRef<HTMLInputElement>(null)
  const [renameSession] = useSessions_RenameSessionMutation({
    onError: handleGraphQlError("Unable to rename session")
  })

  const endRenaming = () => {
    renameSession({ variables: { newName: currentRenamingName, sessionId: session.id } }).then(() => {
      pubsub.publish(topics.SESSIONS_UPDATED)
      setRenaming(false)
    })
  }

  const startRenaming = (name: string) => {
    setCurrentRenamingName(name)
    setRenaming(true)
  }

  useEffect(() => {
    if (renaming) renameInput.current?.focus()
  }, [renaming])

  return (
    <tr
      className={cn(styles.filetr, styles.sessionList)}
      key={session.id}
      onClick={!renaming ? () => handleOpenSession(session) : undefined}
    >
      <td className={styles.filename} style={{ paddingLeft: indented ? 22 : 0 }}>
        {session.isActive && <i className="fa fa-check-circle"></i>}
        <i className="fa fa-th-large"></i>
        {!renaming && (
          <span className={styles.sessionName} style={{ fontWeight: session.isActive ? "bold" : "normal" }}>
            {session.name}
          </span>
        )}
        {renaming && (
          <input
            onBlur={() => endRenaming()}
            ref={renameInput}
            value={currentRenamingName}
            className={cn(input.input, input.small, styles.renameInput)}
            type="text"
            onChange={(e) => setCurrentRenamingName(e.target.value)}
            onKeyDown={(e: { keyCode: number }) => {
              if (e.keyCode === KeyCodes.Enter) endRenaming()
            }}
          ></input>
        )}
      </td>
      <td>{DateTime.fromJSDate(new Date(session.createdDate)).toRelative()}</td>
      <td className={"hide-m"}>{session.isActive ? "Active" : "Open"}</td>
      <td style={{ width: 40 }} className={cn(styles.folderIconButtonGroup, styles.hoverGroup)}>
        <SessionOptionsMenu session={session} openable openSession={handleOpenSession} startRename={startRenaming} />
      </td>
    </tr>
  )
}

export default SessionsListing
