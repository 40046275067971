import { createContext } from "react"

const ChatContext = createContext<{
  preDeleteMessageId: string
  fileListingExists: boolean
  isGroup: boolean
  userId: string | undefined

  setFileListingExists: (b: boolean) => void
  deleteMessageLocal: (id: string) => void
  setPreDeleteMessageId: (id: string) => void
  completeLoadingCallback: (id: string) => void
  setFileSearchQuery: (q: string) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}>({} as any)

export default ChatContext
