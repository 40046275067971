/* eslint-disable */
import React, { useRef } from "react"
import popupPanel from "modules/shared/styles/components/popupPanel.module.scss"
import { useBookmarkButton_CreateBookmarkMutation } from "generated/graphql"
import PopupButton from "modules/shared/components/popupButton"
import "reactjs-popup/dist/index.css"
import cn from "clsx"
import input from "modules/shared/styles/components/input.module.scss"
import button from "modules/shared/styles/components/button.module.scss"
import spinner from "modules/shared/styles/components/spinner.module.scss"
import { handleGraphQlError } from "utils/common"

const BookmarkButton: React.FC<{
  children: React.ReactNode
  title: string
  url: string
}> = ({ children, title, url }) => {
  const [createBookmark, { loading, error }] = useBookmarkButton_CreateBookmarkMutation({
    onError: handleGraphQlError("Unable to create bookmark")
  })

  const title_txt = useRef<HTMLInputElement>(null)
  const url_txt = useRef<HTMLInputElement>(null)

  const bookmarkCreateHandle = (close: () => void) => {
    createBookmark({
      variables: {
        url: url_txt.current?.value || "",
        title: title_txt.current?.value || ""
      }
    }).then((v) => {
      close()
    })
  }

  return (
    <PopupButton
      trigger={<div className={popupPanel.root}>{children}</div>}
      content={(close: () => void) => (
        <div className={popupPanel.popupRoot}>
          <div className={popupPanel.popupTitle}>
            <i className="fa fa-bookmark"></i>
            <p>Create Bookmark</p>

            <span onClick={close} className={cn("material-icons", popupPanel.popupClose)}>
              close
            </span>
          </div>

          <div className={popupPanel.popupForm}>
            <label>Bookmark Title</label>
            <input
              type="text"
              placeholder="Page Title"
              defaultValue={title}
              ref={title_txt}
              required
              className={cn(input.input, input.block)}
            />

            <label>Bookmark URL</label>
            <input
              type="text"
              placeholder="Page URL"
              defaultValue={url}
              ref={url_txt}
              required
              className={cn(input.input, input.block)}
            />

            {!loading && !error && (
              <button
                className={cn(button.button, button.primary, button.big, button.lowAnimation)}
                onClick={() => bookmarkCreateHandle(close)}
              >
                Save Bookmark
              </button>
            )}

            {loading && !error && (
              <button
                className={cn([
                  button.button,
                  button.primary,
                  button.big,
                  button.lowAnimation,
                  loading ? button.loadingButton : ""
                ])}
              >
                <div className={cn(spinner.spinner, spinner.small)}></div>
              </button>
            )}
          </div>
        </div>
      )}
    />
  )
}

export default BookmarkButton
