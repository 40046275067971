/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeVar, ReactiveVar } from "@apollo/client"

export function getFromLocalStorage<T>(key: string): T | null {
  const value = localStorage.getItem(key)
  if (value) {
    try {
      return JSON.parse(value)
    } catch {
      return value as any
    }
  }
  return null
}

export function setToLocalStorage<T>(key: string, value: T): T {
  localStorage.setItem(key, JSON.stringify(value))
  return value
}

export function removeFromLocalStorage(key: string): void {
  localStorage.removeItem(key)
}

export function createVariableFromLocalStorage<T, B = Record<string, unknown>>(
  key: string,
  def: T
): ReactiveVar<T> & { key: string } & B {
  const variable = makeVar<T>(getFromLocalStorage<T>(key) || def) as any
  variable.key = key
  return variable
}
