/* eslint-disable */
import React, { useState } from "react"
import styles from "./Tabs.module.scss"
import SessionTabs, { currentTab } from "../sessionTabs/SessionTabs"
import AppSpaceTabs from "modules/appSpace/components/appSpaceTabs"
import { useRootQuery } from "generated/graphql"
import { currentSession, handleGraphQlError } from "utils/common"
import { viewTabs } from "modules/chat/components/viewFiles/ViewFiles"

const Tabs: React.FC<{ setFinishPreview: any, finishPreview: string }> = ({ finishPreview, setFinishPreview }) => {
  const [, setAppSpaceTabs] = useState<boolean>(false)
  const activateAppSpaceTabs = (activateStatus: boolean) => {
    setAppSpaceTabs(activateStatus)
  }

  const { data } = useRootQuery({
    onError: handleGraphQlError("Unable to get data")
  })

  function shouldDisplayAppSpace() {
    if (finishPreview === currentTab?.id) return false
    if (viewTabs.length > 0) return true

    // No appSpaces open, don't show
    if (data?.activeAppSpace === null) return false
    // User is in channel/dm, don't show
    if (currentTab && currentTab.groupId) return false
    if (
      currentSession("tasks") ||
      currentSession("apps") ||
      currentSession("people") ||
      currentSession("notes") ||
      currentSession("dashboard")
    ) return false

    // If none of the conditions above are met, show app space in Pretzel chat sessions
    if (currentSession("/chat") || currentSession("/mail") || currentSession("/wikis")) return true
    else return false
  }

  return (
    <div className={styles.root}>
      <SessionTabs hasAppSpaceTabs={shouldDisplayAppSpace()} />
      <AppSpaceTabs setFinishPreview={setFinishPreview} hideAppSpaceTabs={shouldDisplayAppSpace()} activateAppSpaceTabs={activateAppSpaceTabs} />
    </div>
  )
}

export default Tabs
