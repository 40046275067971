/* eslint-disable */
import React, { useState, useEffect, useRef, useContext, ChangeEvent } from "react"
import styles from "./Searchbar.module.scss"
import {
  useList_GetGlobalListQuery,
  useSearchbarQuery,
  useChat_ChatMutation,
  useAppSpaceQuery,
  useSearchbar_CreateAppSpaceMutation
} from "generated/graphql"
import cn from "clsx"
import SplitterContext from "../splitterContext"
import { publish } from "pubsub-js"
import { topics } from "consts"
import defaultAppSpaceContent from "modules/appSpace/components/AppSpace/utils/defaultAppSpaceContent"
import { handleGraphQlError, isEnter } from "utils/common"
import { debounce } from "lodash"
import { useCallback } from "react"
import { isProd } from "utils/env"

const Searchbar: React.FC<{
  onSearch: (query: string, type?: string, silent?: boolean) => unknown
  extracted: string
  fullSize: boolean
  commands?: boolean
  handleTyping?: (typing: boolean) => unknown
  onFilePaste?: (file: File) => unknown
  searchText: string
  setSearchText: (e: ChangeEvent<HTMLInputElement> | string) => void
  switchEditorType?: () => void
}> = ({
  onSearch,
  commands,
  extracted,
  fullSize,
  handleTyping,
  onFilePaste,
  searchText,
  setSearchText,
  switchEditorType
}) => {
  const { data: demoCommands, loading } = useList_GetGlobalListQuery({
    variables: { name: "Demo Commands" },
    onError: handleGraphQlError("Unable to get commands")
  })

  if (typeof Storage !== "undefined") {
    if (!localStorage.PRETZEL_COMMAND_HISTORY) localStorage.PRETZEL_COMMAND_HISTORY = "[]"
  }

  const { data: queryData } = useSearchbarQuery({
    onError: handleGraphQlError("Unable to get context")
  })

  const [focused, setFocused] = useState(false)
  const [historyIndex, setHistoryIndex] = useState(0)
  const { mobileChatPane } = useContext(SplitterContext)

  const [createAppSpace] = useSearchbar_CreateAppSpaceMutation({
    onError: handleGraphQlError("Unable to create apps space")
  })

  const [callChat] = useChat_ChatMutation({
    onError: handleGraphQlError("Unable to send the message")
  })
  const { data: openApps } = useAppSpaceQuery({
    onError: handleGraphQlError("Unable to get appspace data")
  })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const timeout = useRef<NodeJS.Timeout>(0 as any)

  const handleKeyboard = useCallback(
    debounce(() => {
      if (handleTyping) {
        if (timeout.current) {
          clearTimeout(timeout.current)
        }
        timeout.current = setTimeout(() => {
          handleTyping(false)
        }, 1000)
        handleTyping(true)
      }
    }, 300),
    [handleTyping]
  )

  useEffect(() => {
    setSearchText(extracted)
  }, [extracted])

  const textInput = useRef<HTMLInputElement>(null)
  const [createMenu, setCreateMenu] = useState(false)

  if (!queryData) {
    return null
  }

  const createNewAppSpace = async (doctype: string) => {
    setCreateMenu(false)
    setSearchText("")

    await createAppSpace({
      variables: {
        type: doctype,
        name: searchText,
        content: defaultAppSpaceContent(searchText, doctype)
      }
    })
    publish(topics.UPDATE_FILES)
  }

  const search = (q: string, type?: string, silent?: boolean) => {
    if (searchText.trim() !== "") onSearch(q, type, silent)
    setCreateMenu(false)
    setSearchText("")

    const history = JSON.parse(localStorage.PRETZEL_COMMAND_HISTORY)
    history && history.push(searchText)
    localStorage.PRETZEL_COMMAND_HISTORY = JSON.stringify(history)
  }

  const handleSearchClick = () => {
    search(searchText)
  }

  const customSearch = (type: string) => {
    search(searchText, type, true)
  }

  const toggleCreateMenu = () => {
    setCreateMenu(!createMenu)
  }

  const cmdHistory = (direction: "up" | "down") => {
    const history = JSON.parse(localStorage.PRETZEL_COMMAND_HISTORY)
    if (history && history.length > 0) {
      if (direction === "up") {
        if (historyIndex < history.length) {
          setHistoryIndex(historyIndex + 1)
          setSearchText(history.reverse()[historyIndex])
        }
      }
      if (direction === "down") {
        if (historyIndex > 0) {
          setHistoryIndex(historyIndex - 1)
          setSearchText(history.reverse()[historyIndex - 1])
        }
      }
    }
  }

  const handlePaste = (e: React.ClipboardEvent) => {
    if (!onFilePaste) {
      return
    }
    const file = e.clipboardData.files.length ? e.clipboardData.files[0] : null
    if (!file) {
      return
    }
    if (file) {
      e.preventDefault()
      e.stopPropagation()
    }
    onFilePaste(file)
  }

  const helpTextVisible = () => searchText.length > 0 && searchText.length < 25

  const sendCommand = (cmd: string) => {
    callChat({
      variables: {
        message: cmd,
        silent: false,
        type: undefined,
        richtext: false,
        attachments: []
      }
    })
  }

  function demos() {
    if (!fullSize && (openApps?.activeAppSpace?.type === "doc.msword" || openApps?.activeAppSpace?.type === "doc"))
      return (
        <div className={styles.demoButtons}>
          <span onClick={() => sendCommand("files")}>Files</span>
          <div className={styles.verticalLine}></div>

          <span onClick={() => sendCommand("summarise")}>Summarise</span>
          <span onClick={() => sendCommand("extract glossary")}>Extract a Glossary</span>
          <span onClick={() => sendCommand("create flashcards")}>Create flashcards</span>
          <span
            onClick={() => {
              setSearchText("find links on ")
              textInput.current?.focus()
            }}
          >
            Find links on...
          </span>
        </div>
      )
    if (!fullSize && openApps?.activeAppSpace?.type === "sheet")
      return (
        <div className={styles.demoButtons}>
          <span onClick={() => sendCommand("files")}>Files</span>
          <div className={styles.verticalLine}></div>

          <span onClick={() => sendCommand("plot")}>Plot</span>
          <span onClick={() => sendCommand("convert to db")}>Convert to DB</span>
          <span onClick={() => sendCommand("chart sales vs quarters")}>Chart Sales vs Quarters</span>
        </div>
      )
    if (!fullSize && openApps?.activeAppSpace?.type === "image")
      return (
        <div className={styles.demoButtons}>
          <span onClick={() => sendCommand("files")}>Files</span>
          <div className={styles.verticalLine}></div>

          <span onClick={() => sendCommand("ocr")}>Extract Text (OCR)</span>
          <span
            onClick={() => {
              setSearchText("find images on ")
              textInput.current?.focus()
            }}
          >
            Find more images on...
          </span>
        </div>
      )
  }

  return (
    <>
      <div className={styles.searchBar}>
        <form className={styles.inputWrapper}>
          <input
            ref={textInput}
            name="query"
            autoFocus={true}
            className={styles.searchInput}
            onKeyDown={handleKeyboard}
            onChange={(e) => {
              setSearchText(e)
              setFocused(e.target.value === "")
            }}
            placeholder="Chat, Search or Name a new file"
            onKeyDownCapture={(e) => {
              if (e.key === "ArrowUp") {
                e.preventDefault()
                e.stopPropagation()
                cmdHistory("up")
              }
              if (e.key === "ArrowDown") {
                e.preventDefault()
                e.stopPropagation()
                cmdHistory("down")
              }
            }}
            onKeyPress={(e) => {
              setCreateMenu(false)

              if (isEnter(e)) {
                if (e.ctrlKey || e.shiftKey) {
                  // switch to rich text editor
                  if (switchEditorType) {
                    switchEditorType()
                    return
                  }
                }

                handleSearchClick()
                e.preventDefault()
              }
            }}
            onFocus={() => {
              setFocused(true)
            }}
            onBlur={() => {
              setTimeout(() => {
                setFocused(false)
              }, 200)
            }}
            type="text"
            autoComplete="off"
            value={searchText}
            onPaste={handlePaste}
          />
        </form>

        <span className={cn(styles.placeholderText, searchText.length === 0 ? styles.shown : "")}>
          <span>Web/file search</span>
          <span>|</span>
          <span>Command</span>
          <span>|</span>
          <span>Chat with Pretzel</span>
          <span>|</span>
          <span>Type a new file name &amp; click +</span>
        </span>

        <div className={styles.searchButtonGroup}>
          {/* <button className={styles.iconOnlySearchButton} onClick={handleSearchClick} title="Search or Chat">
            <i className="fa fa-search"></i>
          </button> */}
          <button className={styles.searchButton} onClick={toggleCreateMenu} title="Create new file">
            <span className="material-icons">add</span>
          </button>
        </div>
        {!mobileChatPane && (
          <div className={cn(styles.searchButtonsGroup, "hide-m")}>
            <div className={cn(styles.searchButton, helpTextVisible() ? styles.show : "")}>TAP ENTER OR...</div>

            <div
              className={cn(styles.searchButton, helpTextVisible() ? styles.show : "", styles.clickable)}
              onClick={() => customSearch("searchResult")}
            >
              WEB <i className="fa fa-search"></i>
            </div>
            <div
              className={cn(styles.searchButton, helpTextVisible() ? styles.show : "", styles.clickable)}
              onClick={() => {
                customSearch("fileListing")
              }}
            >
              FILES <i className="fa fa-search"></i>
            </div>
            <div
              className={cn(styles.searchButton, helpTextVisible() ? styles.show : "", styles.clickable)}
              onClick={handleSearchClick}
            >
              COMMAND / CHAT
            </div>
          </div>
        )}
        <div className={cn(styles.createMenu, createMenu ? styles.show : "", styles.upper)}>
          <div className={styles.menuItem} onClick={() => createNewAppSpace("doc")}>
            <i className="fa fa-file-text-o"></i>
            <span>Text Document</span>
          </div>
          <div className={styles.menuItem} onClick={() => createNewAppSpace("sheet")}>
            <i className="fa fa-file-excel-o"></i>
            <span>Spreadsheet</span>
          </div>
          {!isProd() && (
            <div className={styles.menuItem} onClick={() => createNewAppSpace("tms")}>
              <i className="fa fa-sitemap"></i>
              <span>Task Board</span>
            </div>
          )}
        </div>

        {focused === false && commands && !loading && (
          <div className={cn([styles.demoCommands, focused ? styles.focused : null])}>
            {(demoCommands?.getGlobalList?.children || [])
              .slice()
              .sort((a, b) => a.index - b.index)
              .map((x) => x.title)
              .map((title, i) => (
                <div
                  key={i}
                  className={styles.command}
                  onClick={() => {
                    setSearchText(title)
                    if (textInput.current) {
                      textInput.current.focus()
                    }
                  }}
                >
                  <i className="material-icons">search</i>
                  <span>{title}</span>
                </div>
              ))}
          </div>
        )}
      </div>
      {demos()}
    </>
  )
}

export default Searchbar
