/* eslint-disable */
import { DateTime } from "luxon"
import _ from "lodash"
import { ApolloError } from "@apollo/client"
import { showErrorNotification } from "./notification"
import { isProd } from "./env"
import { v4 as genUuid } from "uuid"
import { getFromLocalStorage, setToLocalStorage } from "./localStorage"

/* eslint-disable @typescript-eslint/ban-types */
export function coalesce<T>(getter: () => T, value: T): T {
  try {
    const res = getter()
    return res === undefined ? value : res || value
  } catch (e) {
    return value
  }
}

export function debounce<T extends (...args: any[]) => void>(fn: T, time: number): T {
  let id = 0 as any as NodeJS.Timeout

  return ((...args: any[]) => {
    if (id) {
      clearTimeout(id)
    }

    id = setTimeout(() => {
      fn(...args)
    }, time)
  }) as any
}

export function runWhen(predicate: () => boolean, callback: () => void, checkInterval = 100, maxTryCount = 0): void {
  if (predicate()) {
    callback()
  } else {
    let tryCount = 0
    const interval = setInterval(() => {
      tryCount++
      if (predicate()) {
        clearInterval(interval)
        callback()
      } else {
        if (tryCount >= maxTryCount && maxTryCount) {
          clearInterval(interval)
        }
      }
    }, checkInterval)
  }
}

/**
 * parses the given query string
 * @param search query string to parse
 */
export function parseQueryString(search: string): any {
  return Object.fromEntries(new URLSearchParams(search.startsWith("?") ? search.substr(1) : search))
}

export function encodeQueryString(obj: any): string {
  return new URLSearchParams(obj).toString()
}

/**
 * converts 2500 to 2.5k
 * @param num number to format
 */
export function formatNumberToK(num: number): string {
  if (!num) return "0"
  if (num < 0) return "0"
  if (num < 1e3) return num.toString()
  if (num >= 1e3 && num < 1e6) return +(num / 1e3).toFixed(1) + "K"
  if (num >= 1e6 && num < 1e9) return +(num / 1e6).toFixed(1) + "M"
  if (num >= 1e9 && num < 1e12) return +(num / 1e9).toFixed(1) + "B"
  if (num >= 1e12) return +(num / 1e12).toFixed(1) + "T"

  return num.toString()
}

/**
 * ellipsis of text
 * @param text text to ellipsis
 * @param len max length supported
 */
export function textEllipsis(text: string, len: number): string {
  if (!text) return ""
  return text.length > len ? text.substr(0, len) + "..." : text
}

/**
 * Concatenates names of several people and a verb
 * @param names Names of users
 * @param verb Task (eg: typing)
 * @param limit max user count (eg: and 5 others)
 * @returns John and Doe are typing
 */
export function concatNames(names: string[], verb: string, limit: number): string {
  if (names.length === 0) {
    return ""
  }

  return (
    (names.length > limit ? names[0] + " and " + (names.length - 1).toString() + " others" : names.join(", and ")) +
    (names.length > 1 ? " are " : " is ") +
    verb
  )
}

export function stringCleanup(str: string): string {
  return str.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "")
}

export function humanizeFileSize(bytes: number): string {
  let suffix = " Bytes"
  let num = bytes

  if (bytes > 1e9) {
    suffix = " GB"
    num = bytes / 1e9
  } else if (bytes > 1e6) {
    suffix = " MB"
    num = bytes / 1e6
  } else if (bytes > 1e3) {
    suffix = " KB"
    num = bytes / 1e3
  }

  return (Math.round((num + Number.EPSILON) * 100) / 100).toString() + suffix
}

export function pages_count(all: number, page_size: number): number {
  if (all / page_size > Math.round(all / page_size)) {
    return Math.round(all / page_size) + 1
  } else {
    return Math.round(all / page_size)
  }
}

export function paginate(arr: any[], page_size: number, page: number): any[] {
  return arr.slice((page - 1) * page_size, page * page_size)
}

export function nth(d: number): string {
  if (d > 3 && d < 21) return "th"
  switch (d % 10) {
    case 1:
      return "st"
    case 2:
      return "nd"
    case 3:
      return "rd"
    default:
      return "th"
  }
}

export function getDate(): string {
  const date = new Date()
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const seconds = date.getSeconds()
  const milliseconds = date.getMilliseconds()
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`
}
export function getFormattedDate(): string {
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const date = new Date();
  const day = days[date.getDay()];
  const month = months[date.getMonth()];
  const dayOfMonth = date.getDate();
  const ordinal = dayOfMonth % 10 === 1 ? 'st' : dayOfMonth % 10 === 2 ? 'nd' : 'th';
  return `${day} ${dayOfMonth}${ordinal} ${month}`;
}

export function formatRelativeDate(date: Date): string {
  const days = Math.ceil(Math.abs(Date.now() - date.getTime()) / (1000 * 60 * 60 * 24))

  if (days === 1) {
    return "Yesterday"
  } else {
    return DateTime.fromJSDate(date).toFormat("EEEE, MMMM d") + nth(date.getDate())
  }
}

export function appSpaceTypeToKind(t: string): string {
  switch (t.toLowerCase()) {
    case "sheet":
      return "SHEET"
    case "doc":
      return "TEXT"
    case "pdf":
      return "PDF"
    case "doc.msword":
      return "MSWORD"
    case "image":
      return "IMAGE"
    default:
      return "UNKNOWN"
  }
}

export function dateTimeToDate(d: DateTime): DateTime {
  return DateTime.fromISO(d.toISO().substring(0, 10))
}

export function groupSessionsByAge(collection: any[]): any[] {
  const groups: any[] = []
  const available = {
    Today: false,
    "This Week": false,
    "Last Week": false,
    "This Month": false,
    "Last Month": false,
    Older: false
  }

  const _groups = _.groupBy(collection, (m) => {
    const date = DateTime.fromJSDate(new Date(m.createdDate))
    const days = dateTimeToDate(DateTime.now()).diff(dateTimeToDate(date)).as("days")
    const months = dateTimeToDate(DateTime.now()).diff(dateTimeToDate(date)).as("months")

    if (days === 0) {
      return "Today"
    }

    if (days > 0 && days < 7) {
      return "This Week"
    }

    if (days >= 7 && days < 14) {
      return "Last Week"
    }

    if (months < 0) {
      return "This month"
    }

    if (months === 1) {
      available["Last Month"] = true
      return "Last month"
    }
    return "Older"
  })

  groups.push(
    ...Object.keys(_groups).map((n) => ({
      name: n,
      defaultExpanded: n === "Today" || n === "This Week" ? true : false,
      items: _groups[n]
    }))
  )

  return groups
}

export function sortByCreatedDate(items?: { createdDate: any }[]): any[] {
  if (items) {
    return items?.slice().sort((x, y) => {
      return Date.parse(y.createdDate) - Date.parse(x.createdDate)
    })
  } else {
    return []
  }
}

/**
 * Checks if appspace is openable
 * @param appSpace appspace to check
 * @returns true if openable
 */
export function openable(appSpace: { type: string }): boolean {
  return appSpace.type !== "other"
}

export function sortByUpdatedDate(items?: { updatedDate: any }[]): any[] {
  if (items) {
    return items?.slice().sort((x, y) => {
      return Date.parse(y.updatedDate) - Date.parse(x.updatedDate)
    })
  } else {
    return []
  }
}

/**
 * Tabularizes an array of elems
 * @param tabs array of elements
 * @param i current index
 * @param size size of the focus area
 * @returns a new array of tabs
 */
export function tabularize<T>(tabs: T[], i: number, size: number): T[] {
  if (tabs.length < size) {
    return tabs
  }

  const start = Math.floor(i / size) * size
  const sliced = tabs.slice(start, size + start)

  if (tabs.length < start + size) {
    return tabs.slice(tabs.length - size, tabs.length)
  } else {
    return sliced
  }
}

/**
 * Checks if email is valid
 * @param email Input email to check
 * @returns true if email is valid
 */
export function isEmail(email: string): boolean {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const handleGraphQlError =
  (message: string) =>
  (err: ApolloError): void => {
    if (!isProd()) {
      console.error(JSON.stringify(err, null, 2))
    }
    const code = [err.graphQLErrors[0]?.extensions][0]?.code

    if (code === "PRETZEL_ERROR") {
      showErrorNotification(message, err.message)
    } else if (code === "INTERNAL_SERVER_ERROR") {
      showErrorNotification(message, "Something unexpected happened!. Please try again while we try to fix it!")
    }
  }

export const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  },
  overlay: {
    backgroundColor: "rgba(0,0,0,0.5)"
  }
}

export function contrast(hexcolor: string, invert?: boolean): string {
  const yiq = getContrast(hexcolor)
  return (invert ? yiq < 128 : yiq >= 128) ? "black" : "white"
}

export function getContrast(hexcolor: string, invert?: boolean): number {
  hexcolor = hexcolor.replace("#", "")
  const r = parseInt(hexcolor.substr(0, 2), 16)
  const g = parseInt(hexcolor.substr(2, 2), 16)
  const b = parseInt(hexcolor.substr(4, 2), 16)
  const yiq = (r * 299 + g * 587 + b * 114) / 1000
  return yiq
}

export function LightenDarkenColor(col: string, amt: number): string {
  const c = parseInt(col, 16)
  return (((c & 0x0000ff) + amt) | ((((c >> 8) & 0x00ff) + amt) << 8) | (((c >> 16) + amt) << 16)).toString(16)
}

export function hashCode(str: string): number {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  return hash
}

export function intToRGB(i: number): string {
  const c = (i & 0x00ffffff).toString(16).toUpperCase()

  return "00000".substring(0, 6 - c.length) + c
}

export function onlyAlpha(str: string, alpha: string[]): string {
  return str
    .split("")
    .filter((letter) => alpha.includes(letter))
    .join("")
}

export function isAlpha(str: string, alpha: string[]): boolean {
  str.split("").forEach((letter) => {
    if (!alpha.includes(letter)) {
      return false
    }
  })

  return true
}

function getNameAlpha(): string[] {
  const alpha = "abcdefghijklmnopqrstuvwxyz".split("")
  alpha.push(...alpha.map((x) => x.toUpperCase()))
  alpha.push(" ")
  return alpha
}

function getPhoneAlpha(): string[] {
  const alpha = "+1234567890()".split("")
  return alpha
}

export function fixName(name: string): string {
  return onlyAlpha(name, getNameAlpha())
}

export function isName(name: string): boolean {
  return isAlpha(name, getNameAlpha())
}

export function fixPhone(phone: string): string {
  return onlyAlpha(phone, getPhoneAlpha())
}

export function isPhone(phone: string): boolean {
  return isAlpha(phone, getPhoneAlpha())
}

export function textContains(inText: string, target: string) {
  return inText.toLowerCase().indexOf(target.toLowerCase()) > -1
}

function matchKey(e: { which?: number; code?: number | string; key: string }, key: string, code: number) {
  return e.key === key || e.code === key || e.code?.toString() === code.toString() || e.which === code
}

export const isEnter = (e: any) => matchKey(e, "Enter", 13)
export const isEscape = (e: any) => matchKey(e, "Escape", 27)
export const isBackspace = (e: any) => matchKey(e, "Backspace", 8)
export const isTab = (e: any) => matchKey(e, "Tab", 9)

export function refToFunc<T>(ref: React.MutableRefObject<T>): (newVal?: T) => T {
  return (newVal?: T) => {
    if (newVal !== undefined) {
      ref.current = newVal
    }
    return ref.current
  }
}

export function replaceAt(str: string, startIndex: number, endIndex: number, replacement: string) {
  return str.substr(0, startIndex) + replacement + str.substr(endIndex + 1)
}

export async function uploadFileToS3(
  pd: any,
  blob: File | Blob,
  onProgress?: (progress: number) => unknown
): Promise<void> {
  const formData = new FormData()
  const postData = JSON.parse(pd) as any
  Object.keys(postData.fields).forEach((key) => {
    formData.append(key, postData.fields[key])
  })

  await new Promise<void>((resolve, reject) => {
    formData.append("file", blob)
    const xhr = new XMLHttpRequest()
    xhr.open("POST", postData.url, true)
    xhr.upload.addEventListener("progress", (e) => {
      if (onProgress) {
        onProgress(Math.ceil((e.loaded / e.total) * 100))
      }
    })
    xhr.onload = function () {
      this.status === 204 ? resolve() : reject(this.responseText)
    }
    xhr.send(formData)
  })
}

export function uuid(): string {
  return genUuid()
}

export function changeUrlWithoutRerender(url: string) {
  window.history.pushState({}, "", url)
}

export function currentSession(url: string) {
  return window.location.href.includes(url)
}

function getDateNumber() {
  return new Date().getDate()
}

if (localStorage.sessionNumber) {
  const test = localStorage.sessionNumber.split(":")[0]
  if (test != getDateNumber()) localStorage.sessionNumber = getDateNumber() + ":1"
}

if (typeof (Storage) !== "undefined") {
  if (!localStorage.RECENT_TASK_BOARD) setToLocalStorage("RECENT_TASK_BOARD", null)
  if (!localStorage.RECENT_WIKI) setToLocalStorage("RECENT_WIKI", null)
  if (!localStorage.initFileListing) localStorage.initFileListing = ""
  if (!localStorage.taskBoardId) localStorage.taskBoardId = ""
  if (!localStorage.sessionNumber) localStorage.sessionNumber = getDateNumber() + ":1" 
}

if (String(getFromLocalStorage("RECENT_TASK_BOARD")).replaceAll('"', "").includes("undefined")) {
  setToLocalStorage("RECENT_TASK_BOARD", null)
  window.location.href = "/tasks"
}
export function recentTaskBoard() {
  if (getFromLocalStorage("RECENT_TASK_BOARD") === null) {
    return `/tasks`
  }
  else return String(getFromLocalStorage("RECENT_TASK_BOARD")).replaceAll('"', "")
}

export function recentWiki() {
  if (getFromLocalStorage("RECENT_WIKI") === null) {
    return `/wikis`
  }
  else return String(getFromLocalStorage("RECENT_WIKI")).replaceAll('"', "")
}

export function columnify(arr: any[], colCount: number): any[][] {
  const cols: any[][] = []

  for (let i = 0; i < arr.length; i++) {
    const element = arr[i]
    let col = ((i + 1) % colCount) - 1

    if (col === -1) {
      col = colCount - 1
    }

    if (cols[col]) {
      cols[col].push(element)
    } else {
      cols[col] = [element]
    }
  }

  return cols
}

const MAX_SANITIZED_CONTRAST = 200

export function sanitizeColor(c: string): string {
  const contrast = getContrast(c)
  let color = c

  if (contrast > MAX_SANITIZED_CONTRAST) {
    color = "#324c81"
  }

  return color
}

export interface IUserOnlineStatus {
  online: boolean
  caption: string
}

export function userOnlineStatus(n: number): { online: boolean; caption: string } {
  const date = DateTime.fromJSDate(new Date(n))
  const mts = DateTime.now().diff(date, "minutes").minutes

  if (mts < 2) {
    return {
      online: true,
      caption: "Active"
    }
  }

  return {
    online: true,
    caption: `Last seen ${date.toRelative()?.toString()}` || "Offline"
  }
}
